.x-list-selected {
    background-color: #e6eef9;
}
.HelpOnClick-RGBblue .HelpOnClick-ToolbarButton.HelpOnClick-WithText .HelpOnClick-Caption {
  display: table-cell;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 19px;
  vertical-align: middle;
}
.HelpOnClick-RGBblue .HelpOnClick-ToolbarButton .HelpOnClick-IconContainer {
  display: table-cell;
  vertical-align: middle;
}
.HelpOnClick-RGBblue .HelpOnClick-ToolbarButton .HelpOnClick-Icon {
  height: 24px;
  width: 24px;
  display: block;
  margin: auto;
}
.HelpOnClick-RGBblue .HelpOnClick-ToolbarButton .HelpOnClick-Icon img {
  height: 24px;
  width: 24px;
}
.HelpOnClick-RGBblue .HelpOnClick-ToolbarButton .HelpOnClick-Icon.HelpOnClick-fa {
  width: 24px;
  height: 24px;
}
.HelpOnClick-Icon-chat:before {
  content: "\f086";
  margin-top: -2px;
}
.HelpOnClick-Icon-email:before {
  content: "\f0e0";
  font-size: 18px;
  margin-top: -1px;
}
.HelpOnClick-Icon-call:before {
  content: "\f095";
}
.HelpOnClick-Icon-social-facebook:before {
  content: "\f09a";
  font-size: inherit;
}
.HelpOnClick-Icon-social-twitter:before {
  content: "\f099";
  font-size: inherit;
}

.HelpOnClick-RGBblue .HelpOnClick-ToolbarButton .HelpOnClick-Icon.HelpOnClick-DefaultIcon.HelpOnClick-Icon-menu:before {
  content: "\f0c9";
}
.HelpOnClick-RGBblue.HelpOnClick-Vertical.HelpOnClick-VerticalTop .dragresize-tm,
.HelpOnClick-RGBblue.HelpOnClick-Vertical.HelpOnClick-VerticalLeft .dragresize-ml {
  display: none !important;
}
.HelpOnClick-RGBblue.HelpOnClick-Vertical.HelpOnClick-VerticalTop .dragresize-bm {
  display: block !important;
  cursor: n-resize;
  height: 5px;
  left: 0;
  margin-left: 0;
  position: absolute;
  bottom: -3px;
  width: 100%;
}
.HelpOnClick-RGBblue.HelpOnClick-Vertical.HelpOnClick-VerticalLeft .dragresize-mr {
  cursor: w-resize;
  display: block !important;
  left: auto;
  right: -3px;
  margin-top: 0;
  top: 0;
}
.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar-wrapper {
  background: #0475a8;
  background: -moz-linear-gradient(left,#058dcb 0%,#047fb7 5%,#046b99 95%,#03648f 100%);
  background: -webkit-gradient(linear,left top,right top,color-stop(0%,#058dcb),color-stop(5%,#047fb7),color-stop(95%,#046b99),color-stop(100%,#03648f));
  background: -webkit-linear-gradient(left,#058dcb 0%,#047fb7 5%,#046b99 95%,#03648f 100%);
  background: -o-linear-gradient(left,#058dcb 0%,#047fb7 5%,#046b99 95%,#03648f 100%);
  background: -ms-linear-gradient(left,#058dcb 0%,#047fb7 5%,#046b99 95%,#03648f 100%);
  background: linear-gradient(to right,#058dcb 0%,#047fb7 5%,#046b99 95%,#03648f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#047fb7,endColorstr=#046b99,GradientType=1);
  height: auto;
  border: 1px solid #035d85;
  margin-bottom: auto;
}
.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar-wrapper.HelpOnClick-NoGradient {
  background: #0475a8;
}
.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar-wrapper li {
  float: none;
}
.HelpOnClick-RGBblue.HelpOnClick-Vertical.HelpOnClick-VerticalLeft .HelpOnClick-NoGradient .HelpOnClick-toolbar {
  background: #0475a8;
}
.HelpOnClick-RGBblue.HelpOnClick-Vertical.HelpOnClick-VerticalLeft .HelpOnClick-toolbar {
  background: #0475a8;
  background: -moz-linear-gradient(left,#03648f 0%,#046b99 5%,#047fb7 95%,#058dcb 100%);
  background: -webkit-gradient(linear,left top,right top,color-stop(0%,#03648f),color-stop(5%,#046b99),color-stop(95%,#047fb7),color-stop(100%,#058dcb));
  background: -webkit-linear-gradient(left,#03648f 0%,#046b99 5%,#047fb7 95%,#058dcb 100%);
  background: -o-linear-gradient(left,#03648f 0%,#046b99 5%,#047fb7 95%,#058dcb 100%);
  background: -ms-linear-gradient(left,#03648f 0%,#046b99 5%,#047fb7 95%,#058dcb 100%);
  background: linear-gradient(to right,#03648f 0%,#046b99 5%,#047fb7 95%,#058dcb 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#046b99,endColorstr=#047fb7,GradientType=1);
}
.HelpOnClick-RGBblue.HelpOnClick-Vertical.HelpOnClick-VerticalLeft .HelpOnClick-toolbar.HelpOnClick-NoGradient {
  background: #0475a8;
}
.HelpOnClick-RGBblue.HelpOnClick-Vertical.HelpOnClick-VerticalTop .HelpOnClick-toolbar {
  margin-top: 0;
}
.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar li {
  width: 100%;
  height: 54px;
  position: relative;
  border-left: none;
  border-right: none;
  border-top: 1px solid #035d85;
  border-bottom: 1px solid #0598da;
}
.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar li a {
  padding: 2px 0;
}
.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar li.HelpOnClick-Button-First {
  border-top: none;
}
.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar li.HelpOnClick-Button-Last {
  border-bottom: none;
}
.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-ToolbarButton.HelpOnClick-WithText .HelpOnClick-Caption {
  display: none;
}
.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar-menu .HelpOnClick-ToolbarButton.HelpOnClick-WithText .HelpOnClick-Caption {
  display: table-cell;
}
.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar .HelpOnClick-ToolbarButton .HelpOnClick-Icon,
.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar .HelpOnClick-ToolbarButton.HelpOnClick-rtl .HelpOnClick-Icon {
  margin-left: auto;
  margin-right: auto;
}
.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar .HelpOnClick-ToolbarButton .HelpOnClick-Order-Icon {
  display: inline-table;
}
.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar .HelpOnClick-ToolbarButton.HelpOnClick-NoIcon .HelpOnClick-Order-Icon {
  display: none;
}
.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar .HelpOnClick-ToolbarButton .HelpOnClick-Order-Caption {
  display: none;
}
.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar .HelpOnClick-ToolbarButton.HelpOnClick-WithText .HelpOnClick-Order-Caption {
  display: none;
}
.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar .HelpOnClick-toolbar-menu .HelpOnClick-ToolbarButton.HelpOnClick-WithText .HelpOnClick-Order-Caption {
  display: inline-table;
}
.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar .HelpOnClick-Order-Icon {
  width: 100%;
}
.HelpOnClick-RGBblue .HelpOnClick-InvitationImage {
  cursor: pointer;
}
.HelpOnClick-RGBblue .HelpOnClick-InvitationImage .HelpOnClick-InvitationImage-CloseButton {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuM4zml1AAAADeSURBVDhPrZTBDYMwDAAZgwF45MkIDMEgbMJ4bMASfbi+CCMTkpBHkO6BOV9UqnYQkZQpMyvxch83yqb8lNXNSuDgsnPPvbAdxyHLssh5nl/RFQeXHb3uqAkxNo6jTgYJIdSiMYaDy46PIvAe4mkIRiH6iBns6oU7VcUk2uLo5ON0W2iJgQ9Woy0x8DEjG/WUYvC4cRSjtRi8Bhddg10/ctcvpRhjXnvGrnWaYtdCi6MTkVnp9dObTdw7/DnsPPdijHJaJWbEKK6PQSry4Ctm4ODeMfCCwTvNzXMkrgx/IWsKX5W5c5wAAAAASUVORK5CYII=") !important;
}
.HelpOnClick-RGBblue .HelpOnClick-Invitation {
  height: 150px;
  min-width: 365px;
  border: 1px solid #035d85;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background-color: #eeeeee;
  overflow: hidden;
}
.HelpOnClick-RGBblue .HelpOnClick-Invitation .HelpOnClick-Invitation-Left {
  width: 150px;
  height: 150px;
  float: left;
  overflow: hidden;
}
.HelpOnClick-RGBblue .HelpOnClick-Invitation .HelpOnClick-Invitation-Left img {
  height: 150px;
  width: 150px;
  background-color: #fff;
}
.HelpOnClick-RGBblue .HelpOnClick-Invitation .HelpOnClick-Invitation-Right .HelpOnClick-ForBtnMiddle {
  padding: 0 30px 20px 30px;
  text-align: center;
}

.HelpOnClick-RGBblue .HelpOnClick-Invitation .HelpOnClick-Invitation-Right .HelpOnClick-CloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
}
.HelpOnClick-RGBblue .HelpOnClick-Invitation .HelpOnClick-Invitation-Right .HelpOnClick-CloseButton a {
  display: block;
  width: 16px;
  height: 16px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAMBJREFUeNqkU9ENhCAMBSdxBG8H2AMmO/bgywl0A92Ea72SlFrxcjZpKuG9+igPW0oxT2LgC+/9dEcAzKg2gI0AZYH67pARs1E9wuIR6M8Lw6acc1TIvPkLMOuhAD+QxDYDV6KQE3G+ChgQQYEDIWeFHJsjCKmyiekdbZAIAqRfyGoDivkvH1wMTB3slZFO0+7djmak07SVmTRNqpHQntuNkeTtNEbaocTetIWSWI1kUEFN59zE11pKjH36nD8CDACsIpgfdd3pqwAAAABJRU5ErkJggg==") !important;
}
.HelpOnClick-RGBblue .HelpOnClick-Invitation .HelpOnClick-Invitation-Right .HelpOnClick-InvitationMessage-Wrapper {
  height: 95px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 5px;
}
.HelpOnClick-RGBblue .HelpOnClick-Invitation .HelpOnClick-Invitation-Right .HelpOnClick-InvitationMessage {
  display: table;
  width: 100%;
  height: 95px;
  font-size: 16px;
}
.HelpOnClick-RGBblue .HelpOnClick-Invitation .HelpOnClick-Invitation-Right .HelpOnClick-InvitationMessage > div {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 7px 20px 0 18px;
  line-height: 22px;
}
.HelpOnClick-RGBblue .HelpOnClick-Clear {
  clear: both;
}
.HelpOnClick-RGBblue .HelpOnClick-NoBorder {
  border: 0 !important;
}
.HelpOnClick-RGBblue .HelpOnClick-input,
.HelpOnClick-RGBblue .HelpOnClick-input input,
.HelpOnClick-RGBblue .HelpOnClick-select .HelpOnClick-view,
.HelpOnClick-RGBblue .HelpOnClick-textarea,
.HelpOnClick-RGBblue .HelpOnClick-input select {
  background: #f4f4f4;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.HelpOnClick-RGBblue .HelpOnClick-input input,
.HelpOnClick-RGBblue .HelpOnClick-input select {
  line-height: 20px;
  height: 32px;
  padding: 6px 10px;
  width: 100%;
  box-sizing: border-box;
}
.HelpOnClick-RGBblue .HelpOnClick-textarea textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.HelpOnClick-RGBblue .HelpOnClick-input,
.HelpOnClick-RGBblue .HelpOnClick-textarea {
  border: 1px solid #7e7c7c;
}

.HelpOnClick-RGBblue .HelpOnClick-Body {
  -moz-user-select: initial;
  -webkit-user-select: initial;
  user-select: initial;
}
.HelpOnClick-RGBblue .HelpOnClick-BodyRounded {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.HelpOnClick-RGBblue .HelpOnClick-BodyRounded.HelpOnClick-NoHead {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.HelpOnClick-RGBblue .HelpOnClick-BodyRounded.HelpOnClick-HasFooter .HelpOnClick-BodyBottom {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.HelpOnClick-RGBblue .HelpOnClick-BodyBottom {
  background: #eee;
  padding: 9px;
  border-top: 1px solid #c3c3c3;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.HelpOnClick-RGBblue .HelpOnClick-Footer {
  background: #eee;
}

.HelpOnClick-RGBblue .HelpOnClick-loadingMask,
.HelpOnClick-RGBblue .HelpOnClick-loadingMask div {
  position: absolute;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.HelpOnClick-RGBblue .HelpOnClick-loadingMask div {
  background: #fff;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.HelpOnClick-RGBblue .HelpOnClick-error {
  color: #ff0000;
}
.HelpOnClick-RGBblue .HelpOnClick-Tooltip {
  position: absolute;
  display: none;
  z-index: 14;
  min-width: 100px;
  overflow: hidden;
  border-radius: 0;
}
.HelpOnClick-RGBblue .HelpOnClick-Tooltip.HelpOnClick-Tooltip-Rounded {
  border-radius: 5px;
}
.HelpOnClick-RGBblue .HelpOnClick-Tooltip .HelpOnClick-Tooltip-Container {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 14px;
  height: 14px;
  text-align: center;
  padding: 13px 15px;
  background: transparent;
}
.HelpOnClick-RGBblue .HelpOnClick-Tooltip .HelpOnClick-Tooltip-Container.HelpOnClick-Tooltip-Gradient {
  background: linear-gradient(to bottom,white -200%,transparent);
}
.HelpOnClick-RGBblue .HelpOnClick-Tip {
  display: none;
  position: absolute;
  z-index: 14;
}
.HelpOnClick-RGBblue .HelpOnClick-Tip,
.HelpOnClick-RGBblue .HelpOnClick-Tip div {
  width: 32px;
  height: 32px;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
}
.HelpOnClick-RGBblue .HelpOnClick-Tip div:after {
  content: '';
  position: absolute;
  left: 50%;
  margin-left: -15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: inherit;
}
.HelpOnClick-RGBblue .HelpOnClick-Tip .HelpOnClick-horizontal-left-top:after,
.HelpOnClick-RGBblue .HelpOnClick-Tip .HelpOnClick-horizontal-center-top:after,
.HelpOnClick-RGBblue .HelpOnClick-Tip .HelpOnClick-horizontal-right-top:after {
  border-width: 0 15px 17px 15px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}
.HelpOnClick-RGBblue .HelpOnClick-Tip .HelpOnClick-horizontal-left-bottom:after,
.HelpOnClick-RGBblue .HelpOnClick-Tip .HelpOnClick-horizontal-center-bottom:after,
.HelpOnClick-RGBblue .HelpOnClick-Tip .HelpOnClick-horizontal-right-bottom:after {
  bottom: 0;
  border-width: 17px 15px 0 15px;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}
.HelpOnClick-RGBblue .HelpOnClick-Tip .HelpOnClick-vertical-left-top:after,
.HelpOnClick-RGBblue .HelpOnClick-Tip .HelpOnClick-vertical-left-center:after,
.HelpOnClick-RGBblue .HelpOnClick-Tip .HelpOnClick-vertical-left-bottom:after {
  top: 25%;
  border-width: 8px 16px 8px 0;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-top-color: transparent;
}
.HelpOnClick-RGBblue .HelpOnClick-Tip .HelpOnClick-vertical-right-top:after,
.HelpOnClick-RGBblue .HelpOnClick-Tip .HelpOnClick-vertical-right-center:after,
.HelpOnClick-RGBblue .HelpOnClick-Tip .HelpOnClick-vertical-right-bottom:after {
  top: 25%;
  left: 30px;
  border-width: 8px 0 8px 16px;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
}
.HelpOnClick-RGBblue .HelpOnClick-DialogTip {
  z-index: 11;
}
.HelpOnClick-RGBblue .HelpOnClick-DialogTip div {
  position: absolute;
  top: 0;
  left: 0;
  background: none !important;
  width: 0;
  height: 0;
}
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-left-top,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-center-top,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-right-top {
  margin-top: 10px;
}
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-left-top:first-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-center-top:first-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-right-top:first-child {
  top: -2px;
  left: -1px;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  border-bottom: 17px solid #035d85;
}
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-left-top:last-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-center-top:last-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-right-top:last-child {
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #0475a8;
}
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-left-bottom,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-center-bottom,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-right-bottom {
  margin-top: 6px;
}
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-left-bottom:first-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-center-bottom:first-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-right-bottom:first-child {
  left: -1px;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  border-top: 17px solid #035d85;
}
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-left-bottom:last-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-center-bottom:last-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-right-bottom:last-child {
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #0475a8;
}
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-left-top,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-left-center,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-left-bottom {
  margin-left: 10px;
}
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-left-top:first-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-left-center:first-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-left-bottom:first-child {
  top: -1px;
  left: -1px;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-right: 17px solid #035d85;
}
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-left-top:last-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-left-center:last-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-left-bottom:last-child {
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-right: 16px solid #0475a8;
}
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-right-top,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-right-center,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-right-bottom {
  margin-left: 6px;
}
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-right-top:first-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-right-center:first-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-right-bottom:first-child {
  top: -1px;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 17px solid #035d85;
}
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-right-top:last-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-right-center:last-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-right-bottom:last-child {
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 16px solid #0475a8;
}
.HelpOnClick-fa {
  display: inline-block;
  font: normal normal normal 20px/1 FontAwesome !important;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.HelpOnClick-fa:before {
  display: inline-block;
  font-size: 20px;
  width: auto;
  margin: 0;
  line-height: 28px;
}
.HelpOnClick-fa-lg {
  font-size: 1.3333333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.HelpOnClick-fa-2x {
  font-size: 2em;
}
.HelpOnClick-fa-3x {
  font-size: 3em;
}
.HelpOnClick-fa-4x {
  font-size: 4em;
}
.HelpOnClick-fa-5x {
  font-size: 5em;
}
.HelpOnClick-fa-fw {
  width: 1.2857142857143em;
  text-align: center;
}
.HelpOnClick-fa-ul {
  padding-left: 0;
  margin-left: 2.1428571428571em;
  list-style-type: none;
}
.HelpOnClick-fa-ul > li {
  position: relative;
}
.HelpOnClick-fa-li {
  position: absolute;
  left: -2.1428571428571em;
  width: 2.1428571428571em;
  top: 0.14285714285714em;
  text-align: center;
}
.HelpOnClick-fa-li.HelpOnClick-fa-lg {
  left: -1.8571428571429em;
}
.HelpOnClick-fa-border {
  padding: .2em .25em .15em;
  border: solid .08em #eee;
  border-radius: .1em;
}

.HelpOnClick-fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.HelpOnClick-fa-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.HelpOnClick-fa-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.HelpOnClick-fa-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.HelpOnClick-fa-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0,mirror=1);
  -webkit-transform: scale(-1,1);
  -ms-transform: scale(-1,1);
  transform: scale(-1,1);
}
.HelpOnClick-fa-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2,mirror=1);
  -webkit-transform: scale(1,-1);
  -ms-transform: scale(1,-1);
  transform: scale(1,-1);
}
:root .HelpOnClick-fa-rotate-90,
:root .HelpOnClick-fa-rotate-180,
:root .HelpOnClick-fa-rotate-270,
:root .HelpOnClick-fa-flip-horizontal,
:root .HelpOnClick-fa-flip-vertical {
  filter: none;
}
.HelpOnClick-fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.HelpOnClick-fa-stack-1x,
.HelpOnClick-fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.HelpOnClick-fa-stack-1x {
  line-height: inherit;
}
.HelpOnClick-fa-stack-2x {
  font-size: 2em;
}
.HelpOnClick-fa-inverse {
  color: #fff;
}
.HelpOnClick-fa-glass:before {
  content: "\f000";
}
.HelpOnClick-fa-music:before {
  content: "\f001";
}
.HelpOnClick-fa-search:before {
  content: "\f002";
}
.HelpOnClick-fa-envelope-o:before {
  content: "\f003";
}
.HelpOnClick-fa-heart:before {
  content: "\f004";
}
.HelpOnClick-fa-star:before {
  content: "\f005";
}
.HelpOnClick-fa-star-o:before {
  content: "\f006";
}
.HelpOnClick-fa-user:before {
  content: "\f007";
}
.HelpOnClick-fa-film:before {
  content: "\f008";
}
.HelpOnClick-fa-th-large:before {
  content: "\f009";
}
.HelpOnClick-fa-th:before {
  content: "\f00a";
}
.HelpOnClick-fa-th-list:before {
  content: "\f00b";
}
.HelpOnClick-fa-check:before {
  content: "\f00c";
}
.HelpOnClick-fa-remove:before,
.HelpOnClick-fa-close:before,
.HelpOnClick-fa-times:before {
  content: "\f00d";
}
.HelpOnClick-fa-search-plus:before {
  content: "\f00e";
}
.HelpOnClick-fa-search-minus:before {
  content: "\f010";
}
.HelpOnClick-fa-power-off:before {
  content: "\f011";
}
.HelpOnClick-fa-signal:before {
  content: "\f012";
}
.HelpOnClick-fa-gear:before,
.HelpOnClick-fa-cog:before {
  content: "\f013";
}
.HelpOnClick-fa-trash-o:before {
  content: "\f014";
}
.HelpOnClick-fa-home:before {
  content: "\f015";
}
.HelpOnClick-fa-file-o:before {
  content: "\f016";
}
.HelpOnClick-fa-clock-o:before {
  content: "\f017";
}
.HelpOnClick-fa-road:before {
  content: "\f018";
}
.HelpOnClick-fa-download:before {
  content: "\f019";
}
.HelpOnClick-fa-arrow-circle-o-down:before {
  content: "\f01a";
}
.HelpOnClick-fa-arrow-circle-o-up:before {
  content: "\f01b";
}
.HelpOnClick-fa-inbox:before {
  content: "\f01c";
}
.HelpOnClick-fa-play-circle-o:before {
  content: "\f01d";
}
.HelpOnClick-fa-rotate-right:before,
.HelpOnClick-fa-repeat:before {
  content: "\f01e";
}
.HelpOnClick-fa-refresh:before {
  content: "\f021";
}
.HelpOnClick-fa-list-alt:before {
  content: "\f022";
}
.HelpOnClick-fa-lock:before {
  content: "\f023";
}
.HelpOnClick-fa-flag:before {
  content: "\f024";
}
.HelpOnClick-fa-headphones:before {
  content: "\f025";
}
.HelpOnClick-fa-volume-off:before {
  content: "\f026";
}
.HelpOnClick-fa-volume-down:before {
  content: "\f027";
}
.HelpOnClick-fa-volume-up:before {
  content: "\f028";
}
.HelpOnClick-fa-qrcode:before {
  content: "\f029";
}
.HelpOnClick-fa-barcode:before {
  content: "\f02a";
}
.HelpOnClick-fa-tag:before {
  content: "\f02b";
}
.HelpOnClick-fa-tags:before {
  content: "\f02c";
}
.HelpOnClick-fa-book:before {
  content: "\f02d";
}
.HelpOnClick-fa-bookmark:before {
  content: "\f02e";
}
.HelpOnClick-fa-print:before {
  content: "\f02f";
}
.HelpOnClick-fa-camera:before {
  content: "\f030";
}
.HelpOnClick-fa-font:before {
  content: "\f031";
}
.HelpOnClick-fa-bold:before {
  content: "\f032";
}
.HelpOnClick-fa-italic:before {
  content: "\f033";
}
.HelpOnClick-fa-text-height:before {
  content: "\f034";
}
.HelpOnClick-fa-text-width:before {
  content: "\f035";
}
.HelpOnClick-fa-align-left:before {
  content: "\f036";
}
.HelpOnClick-fa-align-center:before {
  content: "\f037";
}
.HelpOnClick-fa-align-right:before {
  content: "\f038";
}
.HelpOnClick-fa-align-justify:before {
  content: "\f039";
}
.HelpOnClick-fa-list:before {
  content: "\f03a";
}
.HelpOnClick-fa-dedent:before,
.HelpOnClick-fa-outdent:before {
  content: "\f03b";
}
.HelpOnClick-fa-indent:before {
  content: "\f03c";
}
.HelpOnClick-fa-video-camera:before {
  content: "\f03d";
}
.HelpOnClick-fa-photo:before,
.HelpOnClick-fa-image:before,
.HelpOnClick-fa-picture-o:before {
  content: "\f03e";
}
.HelpOnClick-fa-pencil:before {
  content: "\f040";
}
.HelpOnClick-fa-map-marker:before {
  content: "\f041";
}
.HelpOnClick-fa-adjust:before {
  content: "\f042";
}
.HelpOnClick-fa-tint:before {
  content: "\f043";
}
.HelpOnClick-fa-edit:before,
.HelpOnClick-fa-pencil-square-o:before {
  content: "\f044";
}
.HelpOnClick-fa-share-square-o:before {
  content: "\f045";
}
.HelpOnClick-fa-check-square-o:before {
  content: "\f046";
}
.HelpOnClick-fa-arrows:before {
  content: "\f047";
}
.HelpOnClick-fa-step-backward:before {
  content: "\f048";
}
.HelpOnClick-fa-fast-backward:before {
  content: "\f049";
}
.HelpOnClick-fa-backward:before {
  content: "\f04a";
}
.HelpOnClick-fa-play:before {
  content: "\f04b";
}
.HelpOnClick-fa-pause:before {
  content: "\f04c";
}
.HelpOnClick-fa-stop:before {
  content: "\f04d";
}
.HelpOnClick-fa-forward:before {
  content: "\f04e";
}
.HelpOnClick-fa-fast-forward:before {
  content: "\f050";
}
.HelpOnClick-fa-step-forward:before {
  content: "\f051";
}
.HelpOnClick-fa-eject:before {
  content: "\f052";
}
.HelpOnClick-fa-chevron-left:before {
  content: "\f053";
}
.HelpOnClick-fa-chevron-right:before {
  content: "\f054";
}
.HelpOnClick-fa-plus-circle:before {
  content: "\f055";
}
.HelpOnClick-fa-minus-circle:before {
  content: "\f056";
}
.HelpOnClick-fa-times-circle:before {
  content: "\f057";
}
.HelpOnClick-fa-check-circle:before {
  content: "\f058";
}
.HelpOnClick-fa-question-circle:before {
  content: "\f059";
}
.HelpOnClick-fa-info-circle:before {
  content: "\f05a";
}
.HelpOnClick-fa-crosshairs:before {
  content: "\f05b";
}
.HelpOnClick-fa-times-circle-o:before {
  content: "\f05c";
}
.HelpOnClick-fa-check-circle-o:before {
  content: "\f05d";
}
.HelpOnClick-fa-ban:before {
  content: "\f05e";
}
.HelpOnClick-fa-arrow-left:before {
  content: "\f060";
}
.HelpOnClick-fa-arrow-right:before {
  content: "\f061";
}
.HelpOnClick-fa-arrow-up:before {
  content: "\f062";
}
.HelpOnClick-fa-arrow-down:before {
  content: "\f063";
}
.HelpOnClick-fa-mail-forward:before,
.HelpOnClick-fa-share:before {
  content: "\f064";
}
.HelpOnClick-fa-expand:before {
  content: "\f065";
}
.HelpOnClick-fa-compress:before {
  content: "\f066";
}
.HelpOnClick-fa-plus:before {
  content: "\f067";
}
.HelpOnClick-fa-minus:before {
  content: "\f068";
}
.HelpOnClick-fa-asterisk:before {
  content: "\f069";
}
.HelpOnClick-fa-exclamation-circle:before {
  content: "\f06a";
}
.HelpOnClick-fa-gift:before {
  content: "\f06b";
}
.HelpOnClick-fa-leaf:before {
  content: "\f06c";
}
.HelpOnClick-fa-fire:before {
  content: "\f06d";
}
.HelpOnClick-fa-eye:before {
  content: "\f06e";
}
.HelpOnClick-fa-eye-slash:before {
  content: "\f070";
}
.HelpOnClick-fa-warning:before,
.HelpOnClick-fa-exclamation-triangle:before {
  content: "\f071";
}
.HelpOnClick-fa-plane:before {
  content: "\f072";
}
.HelpOnClick-fa-calendar:before {
  content: "\f073";
}
.HelpOnClick-fa-random:before {
  content: "\f074";
}
.HelpOnClick-fa-comment:before {
  content: "\f075";
}
.HelpOnClick-fa-magnet:before {
  content: "\f076";
}
.HelpOnClick-fa-chevron-up:before {
  content: "\f077";
}
.HelpOnClick-fa-chevron-down:before {
  content: "\f078";
}
.HelpOnClick-fa-retweet:before {
  content: "\f079";
}
.HelpOnClick-fa-shopping-cart:before {
  content: "\f07a";
}
.HelpOnClick-fa-folder:before {
  content: "\f07b";
}
.HelpOnClick-fa-folder-open:before {
  content: "\f07c";
}
.HelpOnClick-fa-arrows-v:before {
  content: "\f07d";
}
.HelpOnClick-fa-arrows-h:before {
  content: "\f07e";
}
.HelpOnClick-fa-bar-chart-o:before,
.HelpOnClick-fa-bar-chart:before {
  content: "\f080";
}
.HelpOnClick-fa-twitter-square:before {
  content: "\f081";
}
.HelpOnClick-fa-facebook-square:before {
  content: "\f082";
}
.HelpOnClick-fa-camera-retro:before {
  content: "\f083";
}
.HelpOnClick-fa-key:before {
  content: "\f084";
}
.HelpOnClick-fa-gears:before,
.HelpOnClick-fa-cogs:before {
  content: "\f085";
}
.HelpOnClick-fa-comments:before {
  content: "\f086";
}
.HelpOnClick-fa-thumbs-o-up:before {
  content: "\f087";
}
.HelpOnClick-fa-thumbs-o-down:before {
  content: "\f088";
}
.HelpOnClick-fa-star-half:before {
  content: "\f089";
}
.HelpOnClick-fa-heart-o:before {
  content: "\f08a";
}
.HelpOnClick-fa-sign-out:before {
  content: "\f08b";
}
.HelpOnClick-fa-linkedin-square:before {
  content: "\f08c";
}
.HelpOnClick-fa-thumb-tack:before {
  content: "\f08d";
}
.HelpOnClick-fa-external-link:before {
  content: "\f08e";
}
.HelpOnClick-fa-sign-in:before {
  content: "\f090";
}
.HelpOnClick-fa-trophy:before {
  content: "\f091";
}
.HelpOnClick-fa-github-square:before {
  content: "\f092";
}
.HelpOnClick-fa-upload:before {
  content: "\f093";
}
.HelpOnClick-fa-lemon-o:before {
  content: "\f094";
}
.HelpOnClick-fa-phone:before {
  content: "\f095";
}
.HelpOnClick-fa-square-o:before {
  content: "\f096";
}
.HelpOnClick-fa-bookmark-o:before {
  content: "\f097";
}
.HelpOnClick-fa-phone-square:before {
  content: "\f098";
}
.HelpOnClick-fa-twitter:before {
  content: "\f099";
}
.HelpOnClick-fa-facebook:before {
  content: "\f09a";
}
.HelpOnClick-fa-github:before {
  content: "\f09b";
}
.HelpOnClick-fa-unlock:before {
  content: "\f09c";
}
.HelpOnClick-fa-credit-card:before {
  content: "\f09d";
}
.HelpOnClick-fa-rss:before {
  content: "\f09e";
}
.HelpOnClick-fa-hdd-o:before {
  content: "\f0a0";
}
.HelpOnClick-fa-bullhorn:before {
  content: "\f0a1";
}
.HelpOnClick-fa-bell:before {
  content: "\f0f3";
}
.HelpOnClick-fa-certificate:before {
  content: "\f0a3";
}
.HelpOnClick-fa-hand-o-right:before {
  content: "\f0a4";
}
.HelpOnClick-fa-hand-o-left:before {
  content: "\f0a5";
}
.HelpOnClick-fa-hand-o-up:before {
  content: "\f0a6";
}
.HelpOnClick-fa-hand-o-down:before {
  content: "\f0a7";
}
.HelpOnClick-fa-arrow-circle-left:before {
  content: "\f0a8";
}
.HelpOnClick-fa-arrow-circle-right:before {
  content: "\f0a9";
}
.HelpOnClick-fa-arrow-circle-up:before {
  content: "\f0aa";
}
.HelpOnClick-fa-arrow-circle-down:before {
  content: "\f0ab";
}
.HelpOnClick-fa-globe:before {
  content: "\f0ac";
}
.HelpOnClick-fa-wrench:before {
  content: "\f0ad";
}
.HelpOnClick-fa-tasks:before {
  content: "\f0ae";
}
.HelpOnClick-fa-filter:before {
  content: "\f0b0";
}
.HelpOnClick-fa-briefcase:before {
  content: "\f0b1";
}
.HelpOnClick-fa-arrows-alt:before {
  content: "\f0b2";
}
.HelpOnClick-fa-group:before,
.HelpOnClick-fa-users:before {
  content: "\f0c0";
}
.HelpOnClick-fa-chain:before,
.HelpOnClick-fa-link:before {
  content: "\f0c1";
}
.HelpOnClick-fa-cloud:before {
  content: "\f0c2";
}
.HelpOnClick-fa-flask:before {
  content: "\f0c3";
}
.HelpOnClick-fa-cut:before,
.HelpOnClick-fa-scissors:before {
  content: "\f0c4";
}
.HelpOnClick-fa-copy:before,
.HelpOnClick-fa-files-o:before {
  content: "\f0c5";
}
.HelpOnClick-fa-paperclip:before {
  content: "\f0c6";
}
.HelpOnClick-fa-save:before,
.HelpOnClick-fa-floppy-o:before {
  content: "\f0c7";
}
.HelpOnClick-fa-square:before {
  content: "\f0c8";
}
.HelpOnClick-fa-navicon:before,
.HelpOnClick-fa-reorder:before,
.HelpOnClick-fa-bars:before {
  content: "\f0c9";
}
.HelpOnClick-fa-list-ul:before {
  content: "\f0ca";
}
.HelpOnClick-fa-list-ol:before {
  content: "\f0cb";
}
.HelpOnClick-fa-strikethrough:before {
  content: "\f0cc";
}
.HelpOnClick-fa-underline:before {
  content: "\f0cd";
}
.HelpOnClick-fa-table:before {
  content: "\f0ce";
}
.HelpOnClick-fa-magic:before {
  content: "\f0d0";
}
.HelpOnClick-fa-truck:before {
  content: "\f0d1";
}
.HelpOnClick-fa-pinterest:before {
  content: "\f0d2";
}
.HelpOnClick-fa-pinterest-square:before {
  content: "\f0d3";
}
.HelpOnClick-fa-google-plus-square:before {
  content: "\f0d4";
}
.HelpOnClick-fa-google-plus:before {
  content: "\f0d5";
}
.HelpOnClick-fa-money:before {
  content: "\f0d6";
}
.HelpOnClick-fa-caret-down:before {
  content: "\f0d7";
}
.HelpOnClick-fa-caret-up:before {
  content: "\f0d8";
}
.HelpOnClick-fa-caret-left:before {
  content: "\f0d9";
}
.HelpOnClick-fa-caret-right:before {
  content: "\f0da";
}
.HelpOnClick-fa-columns:before {
  content: "\f0db";
}
.HelpOnClick-fa-unsorted:before,
.HelpOnClick-fa-sort:before {
  content: "\f0dc";
}
.HelpOnClick-fa-sort-down:before,
.HelpOnClick-fa-sort-desc:before {
  content: "\f0dd";
}
.HelpOnClick-fa-sort-up:before,
.HelpOnClick-fa-sort-asc:before {
  content: "\f0de";
}
.HelpOnClick-fa-envelope:before {
  content: "\f0e0";
}
.HelpOnClick-fa-linkedin:before {
  content: "\f0e1";
}
.HelpOnClick-fa-rotate-left:before,
.HelpOnClick-fa-undo:before {
  content: "\f0e2";
}
.HelpOnClick-fa-legal:before,
.HelpOnClick-fa-gavel:before {
  content: "\f0e3";
}
.HelpOnClick-fa-dashboard:before,
.HelpOnClick-fa-tachometer:before {
  content: "\f0e4";
}
.HelpOnClick-fa-comment-o:before {
  content: "\f0e5";
}
.HelpOnClick-fa-comments-o:before {
  content: "\f0e6";
}
.HelpOnClick-fa-flash:before,
.HelpOnClick-fa-bolt:before {
  content: "\f0e7";
}
.HelpOnClick-fa-sitemap:before {
  content: "\f0e8";
}
.HelpOnClick-fa-umbrella:before {
  content: "\f0e9";
}
.HelpOnClick-fa-paste:before,
.HelpOnClick-fa-clipboard:before {
  content: "\f0ea";
}
.HelpOnClick-fa-lightbulb-o:before {
  content: "\f0eb";
}
.HelpOnClick-fa-exchange:before {
  content: "\f0ec";
}
.HelpOnClick-fa-cloud-download:before {
  content: "\f0ed";
}
.HelpOnClick-fa-cloud-upload:before {
  content: "\f0ee";
}
.HelpOnClick-fa-user-md:before {
  content: "\f0f0";
}
.HelpOnClick-fa-stethoscope:before {
  content: "\f0f1";
}
.HelpOnClick-fa-suitcase:before {
  content: "\f0f2";
}
.HelpOnClick-fa-bell-o:before {
  content: "\f0a2";
}
.HelpOnClick-fa-coffee:before {
  content: "\f0f4";
}
.HelpOnClick-fa-cutlery:before {
  content: "\f0f5";
}
.HelpOnClick-fa-file-text-o:before {
  content: "\f0f6";
}
.HelpOnClick-fa-building-o:before {
  content: "\f0f7";
}
.HelpOnClick-fa-hospital-o:before {
  content: "\f0f8";
}
.HelpOnClick-fa-ambulance:before {
  content: "\f0f9";
}
.HelpOnClick-fa-medkit:before {
  content: "\f0fa";
}
.HelpOnClick-fa-fighter-jet:before {
  content: "\f0fb";
}
.HelpOnClick-fa-beer:before {
  content: "\f0fc";
}
.HelpOnClick-fa-h-square:before {
  content: "\f0fd";
}
.HelpOnClick-fa-plus-square:before {
  content: "\f0fe";
}
.HelpOnClick-fa-angle-double-left:before {
  content: "\f100";
}
.HelpOnClick-fa-angle-double-right:before {
  content: "\f101";
}
.HelpOnClick-fa-angle-double-up:before {
  content: "\f102";
}
.HelpOnClick-fa-angle-double-down:before {
  content: "\f103";
}
.HelpOnClick-fa-angle-left:before {
  content: "\f104";
}
.HelpOnClick-fa-angle-right:before {
  content: "\f105";
}
.HelpOnClick-fa-angle-up:before {
  content: "\f106";
}
.HelpOnClick-fa-angle-down:before {
  content: "\f107";
}
.HelpOnClick-fa-desktop:before {
  content: "\f108";
}
.HelpOnClick-fa-laptop:before {
  content: "\f109";
}
.HelpOnClick-fa-tablet:before {
  content: "\f10a";
}
.HelpOnClick-fa-mobile-phone:before,
.HelpOnClick-fa-mobile:before {
  content: "\f10b";
}
.HelpOnClick-fa-circle-o:before {
  content: "\f10c";
}
.HelpOnClick-fa-quote-left:before {
  content: "\f10d";
}
.HelpOnClick-fa-quote-right:before {
  content: "\f10e";
}
.HelpOnClick-fa-spinner:before {
  content: "\f110";
}
.HelpOnClick-fa-circle:before {
  content: "\f111";
}
.HelpOnClick-fa-mail-reply:before,
.HelpOnClick-fa-reply:before {
  content: "\f112";
}
.HelpOnClick-fa-github-alt:before {
  content: "\f113";
}
.HelpOnClick-fa-folder-o:before {
  content: "\f114";
}
.HelpOnClick-fa-folder-open-o:before {
  content: "\f115";
}
.HelpOnClick-fa-smile-o:before {
  content: "\f118";
}
.HelpOnClick-fa-frown-o:before {
  content: "\f119";
}
.HelpOnClick-fa-meh-o:before {
  content: "\f11a";
}
.HelpOnClick-fa-gamepad:before {
  content: "\f11b";
}
.HelpOnClick-fa-keyboard-o:before {
  content: "\f11c";
}
.HelpOnClick-fa-flag-o:before {
  content: "\f11d";
}
.HelpOnClick-fa-flag-checkered:before {
  content: "\f11e";
}
.HelpOnClick-fa-terminal:before {
  content: "\f120";
}
.HelpOnClick-fa-code:before {
  content: "\f121";
}
.HelpOnClick-fa-mail-reply-all:before,
.HelpOnClick-fa-reply-all:before {
  content: "\f122";
}
.HelpOnClick-fa-star-half-empty:before,
.HelpOnClick-fa-star-half-full:before,
.HelpOnClick-fa-star-half-o:before {
  content: "\f123";
}
.HelpOnClick-fa-location-arrow:before {
  content: "\f124";
}
.HelpOnClick-fa-crop:before {
  content: "\f125";
}
.HelpOnClick-fa-code-fork:before {
  content: "\f126";
}
.HelpOnClick-fa-unlink:before,
.HelpOnClick-fa-chain-broken:before {
  content: "\f127";
}
.HelpOnClick-fa-question:before {
  content: "\f128";
}
.HelpOnClick-fa-info:before {
  content: "\f129";
}
.HelpOnClick-fa-exclamation:before {
  content: "\f12a";
}
.HelpOnClick-fa-superscript:before {
  content: "\f12b";
}
.HelpOnClick-fa-subscript:before {
  content: "\f12c";
}
.HelpOnClick-fa-eraser:before {
  content: "\f12d";
}
.HelpOnClick-fa-puzzle-piece:before {
  content: "\f12e";
}
.HelpOnClick-fa-microphone:before {
  content: "\f130";
}
.HelpOnClick-fa-microphone-slash:before {
  content: "\f131";
}
.HelpOnClick-fa-shield:before {
  content: "\f132";
}
.HelpOnClick-fa-calendar-o:before {
  content: "\f133";
}
.HelpOnClick-fa-fire-extinguisher:before {
  content: "\f134";
}
.HelpOnClick-fa-rocket:before {
  content: "\f135";
}
.HelpOnClick-fa-maxcdn:before {
  content: "\f136";
}
.HelpOnClick-fa-chevron-circle-left:before {
  content: "\f137";
}
.HelpOnClick-fa-chevron-circle-right:before {
  content: "\f138";
}
.HelpOnClick-fa-chevron-circle-up:before {
  content: "\f139";
}
.HelpOnClick-fa-chevron-circle-down:before {
  content: "\f13a";
}
.HelpOnClick-fa-html5:before {
  content: "\f13b";
}
.HelpOnClick-fa-css3:before {
  content: "\f13c";
}
.HelpOnClick-fa-anchor:before {
  content: "\f13d";
}
.HelpOnClick-fa-unlock-alt:before {
  content: "\f13e";
}
.HelpOnClick-fa-bullseye:before {
  content: "\f140";
}
.HelpOnClick-fa-ellipsis-h:before {
  content: "\f141";
}
.HelpOnClick-fa-ellipsis-v:before {
  content: "\f142";
}
.HelpOnClick-fa-rss-square:before {
  content: "\f143";
}
.HelpOnClick-fa-play-circle:before {
  content: "\f144";
}
.HelpOnClick-fa-ticket:before {
  content: "\f145";
}
.HelpOnClick-fa-minus-square:before {
  content: "\f146";
}
.HelpOnClick-fa-minus-square-o:before {
  content: "\f147";
}
.HelpOnClick-fa-level-up:before {
  content: "\f148";
}
.HelpOnClick-fa-level-down:before {
  content: "\f149";
}
.HelpOnClick-fa-check-square:before {
  content: "\f14a";
}
.HelpOnClick-fa-pencil-square:before {
  content: "\f14b";
}
.HelpOnClick-fa-external-link-square:before {
  content: "\f14c";
}
.HelpOnClick-fa-share-square:before {
  content: "\f14d";
}
.HelpOnClick-fa-compass:before {
  content: "\f14e";
}
.HelpOnClick-fa-toggle-down:before,
.HelpOnClick-fa-caret-square-o-down:before {
  content: "\f150";
}
.HelpOnClick-fa-toggle-up:before,
.HelpOnClick-fa-caret-square-o-up:before {
  content: "\f151";
}
.HelpOnClick-fa-toggle-right:before,
.HelpOnClick-fa-caret-square-o-right:before {
  content: "\f152";
}
.HelpOnClick-fa-euro:before,
.HelpOnClick-fa-eur:before {
  content: "\f153";
}
.HelpOnClick-fa-gbp:before {
  content: "\f154";
}
.HelpOnClick-fa-dollar:before,
.HelpOnClick-fa-usd:before {
  content: "\f155";
}
.HelpOnClick-fa-rupee:before,
.HelpOnClick-fa-inr:before {
  content: "\f156";
}
.HelpOnClick-fa-cny:before,
.HelpOnClick-fa-rmb:before,
.HelpOnClick-fa-yen:before,
.HelpOnClick-fa-jpy:before {
  content: "\f157";
}
.HelpOnClick-fa-ruble:before,
.HelpOnClick-fa-rouble:before,
.HelpOnClick-fa-rub:before {
  content: "\f158";
}
.HelpOnClick-fa-won:before,
.HelpOnClick-fa-krw:before {
  content: "\f159";
}
.HelpOnClick-fa-bitcoin:before,
.HelpOnClick-fa-btc:before {
  content: "\f15a";
}
.HelpOnClick-fa-file:before {
  content: "\f15b";
}
.HelpOnClick-fa-file-text:before {
  content: "\f15c";
}
.HelpOnClick-fa-sort-alpha-asc:before {
  content: "\f15d";
}
.HelpOnClick-fa-sort-alpha-desc:before {
  content: "\f15e";
}
.HelpOnClick-fa-sort-amount-asc:before {
  content: "\f160";
}
.HelpOnClick-fa-sort-amount-desc:before {
  content: "\f161";
}
.HelpOnClick-fa-sort-numeric-asc:before {
  content: "\f162";
}
.HelpOnClick-fa-sort-numeric-desc:before {
  content: "\f163";
}
.HelpOnClick-fa-thumbs-up:before {
  content: "\f164";
}
.HelpOnClick-fa-thumbs-down:before {
  content: "\f165";
}
.HelpOnClick-fa-youtube-square:before {
  content: "\f166";
}
.HelpOnClick-fa-youtube:before {
  content: "\f167";
}
.HelpOnClick-fa-xing:before {
  content: "\f168";
}
.HelpOnClick-fa-xing-square:before {
  content: "\f169";
}
.HelpOnClick-fa-youtube-play:before {
  content: "\f16a";
}
.HelpOnClick-fa-dropbox:before {
  content: "\f16b";
}
.HelpOnClick-fa-stack-overflow:before {
  content: "\f16c";
}
.HelpOnClick-fa-instagram:before {
  content: "\f16d";
}
.HelpOnClick-fa-flickr:before {
  content: "\f16e";
}
.HelpOnClick-fa-adn:before {
  content: "\f170";
}
.HelpOnClick-fa-bitbucket:before {
  content: "\f171";
}
.HelpOnClick-fa-bitbucket-square:before {
  content: "\f172";
}
.HelpOnClick-fa-tumblr:before {
  content: "\f173";
}
.HelpOnClick-fa-tumblr-square:before {
  content: "\f174";
}
.HelpOnClick-fa-long-arrow-down:before {
  content: "\f175";
}
.HelpOnClick-fa-long-arrow-up:before {
  content: "\f176";
}
.HelpOnClick-fa-long-arrow-left:before {
  content: "\f177";
}
.HelpOnClick-fa-long-arrow-right:before {
  content: "\f178";
}
.HelpOnClick-fa-apple:before {
  content: "\f179";
}
.HelpOnClick-fa-windows:before {
  content: "\f17a";
}
.HelpOnClick-fa-android:before {
  content: "\f17b";
}
.HelpOnClick-fa-linux:before {
  content: "\f17c";
}
.HelpOnClick-fa-dribbble:before {
  content: "\f17d";
}
.HelpOnClick-fa-skype:before {
  content: "\f17e";
}
.HelpOnClick-fa-foursquare:before {
  content: "\f180";
}
.HelpOnClick-fa-trello:before {
  content: "\f181";
}
.HelpOnClick-fa-female:before {
  content: "\f182";
}
.HelpOnClick-fa-male:before {
  content: "\f183";
}
.HelpOnClick-fa-gittip:before {
  content: "\f184";
}
.HelpOnClick-fa-sun-o:before {
  content: "\f185";
}
.HelpOnClick-fa-moon-o:before {
  content: "\f186";
}
.HelpOnClick-fa-archive:before {
  content: "\f187";
}
.HelpOnClick-fa-bug:before {
  content: "\f188";
}
.HelpOnClick-fa-vk:before {
  content: "\f189";
}
.HelpOnClick-fa-weibo:before {
  content: "\f18a";
}
.HelpOnClick-fa-renren:before {
  content: "\f18b";
}
.HelpOnClick-fa-pagelines:before {
  content: "\f18c";
}
.HelpOnClick-fa-stack-exchange:before {
  content: "\f18d";
}
.HelpOnClick-fa-arrow-circle-o-right:before {
  content: "\f18e";
}
.HelpOnClick-fa-arrow-circle-o-left:before {
  content: "\f190";
}
.HelpOnClick-fa-toggle-left:before,
.HelpOnClick-fa-caret-square-o-left:before {
  content: "\f191";
}
.HelpOnClick-fa-dot-circle-o:before {
  content: "\f192";
}
.HelpOnClick-fa-wheelchair:before {
  content: "\f193";
}
.HelpOnClick-fa-vimeo-square:before {
  content: "\f194";
}
.HelpOnClick-fa-turkish-lira:before,
.HelpOnClick-fa-try:before {
  content: "\f195";
}
.HelpOnClick-fa-plus-square-o:before {
  content: "\f196";
}
.HelpOnClick-fa-space-shuttle:before {
  content: "\f197";
}
.HelpOnClick-fa-slack:before {
  content: "\f198";
}
.HelpOnClick-fa-envelope-square:before {
  content: "\f199";
}
.HelpOnClick-fa-wordpress:before {
  content: "\f19a";
}
.HelpOnClick-fa-openid:before {
  content: "\f19b";
}
.HelpOnClick-fa-institution:before,
.HelpOnClick-fa-bank:before,
.HelpOnClick-fa-university:before {
  content: "\f19c";
}
.HelpOnClick-fa-mortar-board:before,
.HelpOnClick-fa-graduation-cap:before {
  content: "\f19d";
}
.HelpOnClick-fa-yahoo:before {
  content: "\f19e";
}
.HelpOnClick-fa-google:before {
  content: "\f1a0";
}
.HelpOnClick-fa-reddit:before {
  content: "\f1a1";
}
.HelpOnClick-fa-reddit-square:before {
  content: "\f1a2";
}
.HelpOnClick-fa-stumbleupon-circle:before {
  content: "\f1a3";
}
.HelpOnClick-fa-stumbleupon:before {
  content: "\f1a4";
}
.HelpOnClick-fa-delicious:before {
  content: "\f1a5";
}
.HelpOnClick-fa-digg:before {
  content: "\f1a6";
}
.HelpOnClick-fa-pied-piper:before {
  content: "\f1a7";
}
.HelpOnClick-fa-pied-piper-alt:before {
  content: "\f1a8";
}
.HelpOnClick-fa-drupal:before {
  content: "\f1a9";
}
.HelpOnClick-fa-joomla:before {
  content: "\f1aa";
}
.HelpOnClick-fa-language:before {
  content: "\f1ab";
}
.HelpOnClick-fa-fax:before {
  content: "\f1ac";
}
.HelpOnClick-fa-building:before {
  content: "\f1ad";
}
.HelpOnClick-fa-child:before {
  content: "\f1ae";
}
.HelpOnClick-fa-paw:before {
  content: "\f1b0";
}
.HelpOnClick-fa-spoon:before {
  content: "\f1b1";
}
.HelpOnClick-fa-cube:before {
  content: "\f1b2";
}
.HelpOnClick-fa-cubes:before {
  content: "\f1b3";
}
.HelpOnClick-fa-behance:before {
  content: "\f1b4";
}
.HelpOnClick-fa-behance-square:before {
  content: "\f1b5";
}
.HelpOnClick-fa-steam:before {
  content: "\f1b6";
}
.HelpOnClick-fa-steam-square:before {
  content: "\f1b7";
}
.HelpOnClick-fa-recycle:before {
  content: "\f1b8";
}
.HelpOnClick-fa-automobile:before,
.HelpOnClick-fa-car:before {
  content: "\f1b9";
}
.HelpOnClick-fa-cab:before,
.HelpOnClick-fa-taxi:before {
  content: "\f1ba";
}
.HelpOnClick-fa-tree:before {
  content: "\f1bb";
}
.HelpOnClick-fa-spotify:before {
  content: "\f1bc";
}
.HelpOnClick-fa-deviantart:before {
  content: "\f1bd";
}
.HelpOnClick-fa-soundcloud:before {
  content: "\f1be";
}
.HelpOnClick-fa-database:before {
  content: "\f1c0";
}
.HelpOnClick-fa-file-pdf-o:before {
  content: "\f1c1";
}
.HelpOnClick-fa-file-word-o:before {
  content: "\f1c2";
}
.HelpOnClick-fa-file-excel-o:before {
  content: "\f1c3";
}
.HelpOnClick-fa-file-powerpoint-o:before {
  content: "\f1c4";
}
.HelpOnClick-fa-file-photo-o:before,
.HelpOnClick-fa-file-picture-o:before,
.HelpOnClick-fa-file-image-o:before {
  content: "\f1c5";
}
.HelpOnClick-fa-file-zip-o:before,
.HelpOnClick-fa-file-archive-o:before {
  content: "\f1c6";
}
.HelpOnClick-fa-file-sound-o:before,
.HelpOnClick-fa-file-audio-o:before {
  content: "\f1c7";
}
.HelpOnClick-fa-file-movie-o:before,
.HelpOnClick-fa-file-video-o:before {
  content: "\f1c8";
}
.HelpOnClick-fa-file-code-o:before {
  content: "\f1c9";
}
.HelpOnClick-fa-vine:before {
  content: "\f1ca";
}
.HelpOnClick-fa-codepen:before {
  content: "\f1cb";
}
.HelpOnClick-fa-jsfiddle:before {
  content: "\f1cc";
}
.HelpOnClick-fa-life-bouy:before,
.HelpOnClick-fa-life-buoy:before,
.HelpOnClick-fa-life-saver:before,
.HelpOnClick-fa-support:before,
.HelpOnClick-fa-life-ring:before {
  content: "\f1cd";
}
.HelpOnClick-fa-circle-o-notch:before {
  content: "\f1ce";
}
.HelpOnClick-fa-ra:before,
.HelpOnClick-fa-rebel:before {
  content: "\f1d0";
}
.HelpOnClick-fa-ge:before,
.HelpOnClick-fa-empire:before {
  content: "\f1d1";
}
.HelpOnClick-fa-git-square:before {
  content: "\f1d2";
}
.HelpOnClick-fa-git:before {
  content: "\f1d3";
}
.HelpOnClick-fa-hacker-news:before {
  content: "\f1d4";
}
.HelpOnClick-fa-tencent-weibo:before {
  content: "\f1d5";
}
.HelpOnClick-fa-qq:before {
  content: "\f1d6";
}
.HelpOnClick-fa-wechat:before,
.HelpOnClick-fa-weixin:before {
  content: "\f1d7";
}
.HelpOnClick-fa-send:before,
.HelpOnClick-fa-paper-plane:before {
  content: "\f1d8";
}
.HelpOnClick-fa-send-o:before,
.HelpOnClick-fa-paper-plane-o:before {
  content: "\f1d9";
}
.HelpOnClick-fa-history:before {
  content: "\f1da";
}
.HelpOnClick-fa-circle-thin:before {
  content: "\f1db";
}
.HelpOnClick-fa-header:before {
  content: "\f1dc";
}
.HelpOnClick-fa-paragraph:before {
  content: "\f1dd";
}
.HelpOnClick-fa-sliders:before {
  content: "\f1de";
}
.HelpOnClick-fa-share-alt:before {
  content: "\f1e0";
}
.HelpOnClick-fa-share-alt-square:before {
  content: "\f1e1";
}
.HelpOnClick-fa-bomb:before {
  content: "\f1e2";
}
.HelpOnClick-fa-soccer-ball-o:before,
.HelpOnClick-fa-futbol-o:before {
  content: "\f1e3";
}
.HelpOnClick-fa-tty:before {
  content: "\f1e4";
}
.HelpOnClick-fa-binoculars:before {
  content: "\f1e5";
}
.HelpOnClick-fa-plug:before {
  content: "\f1e6";
}
.HelpOnClick-fa-slideshare:before {
  content: "\f1e7";
}
.HelpOnClick-fa-twitch:before {
  content: "\f1e8";
}
.HelpOnClick-fa-yelp:before {
  content: "\f1e9";
}
.HelpOnClick-fa-newspaper-o:before {
  content: "\f1ea";
}
.HelpOnClick-fa-wifi:before {
  content: "\f1eb";
}
.HelpOnClick-fa-calculator:before {
  content: "\f1ec";
}
.HelpOnClick-fa-paypal:before {
  content: "\f1ed";
}
.HelpOnClick-fa-google-wallet:before {
  content: "\f1ee";
}
.HelpOnClick-fa-cc-visa:before {
  content: "\f1f0";
}
.HelpOnClick-fa-cc-mastercard:before {
  content: "\f1f1";
}
.HelpOnClick-fa-cc-discover:before {
  content: "\f1f2";
}
.HelpOnClick-fa-cc-amex:before {
  content: "\f1f3";
}
.HelpOnClick-fa-cc-paypal:before {
  content: "\f1f4";
}
.HelpOnClick-fa-cc-stripe:before {
  content: "\f1f5";
}
.HelpOnClick-fa-bell-slash:before {
  content: "\f1f6";
}
.HelpOnClick-fa-bell-slash-o:before {
  content: "\f1f7";
}
.HelpOnClick-fa-trash:before {
  content: "\f1f8";
}
.HelpOnClick-fa-copyright:before {
  content: "\f1f9";
}
.HelpOnClick-fa-at:before {
  content: "\f1fa";
}
.HelpOnClick-fa-eyedropper:before {
  content: "\f1fb";
}
.HelpOnClick-fa-paint-brush:before {
  content: "\f1fc";
}
.HelpOnClick-fa-birthday-cake:before {
  content: "\f1fd";
}
.HelpOnClick-fa-area-chart:before {
  content: "\f1fe";
}
.HelpOnClick-fa-pie-chart:before {
  content: "\f200";
}
.HelpOnClick-fa-line-chart:before {
  content: "\f201";
}
.HelpOnClick-fa-lastfm:before {
  content: "\f202";
}
.HelpOnClick-fa-lastfm-square:before {
  content: "\f203";
}
.HelpOnClick-fa-toggle-off:before {
  content: "\f204";
}
.HelpOnClick-fa-toggle-on:before {
  content: "\f205";
}
.HelpOnClick-fa-bicycle:before {
  content: "\f206";
}
.HelpOnClick-fa-bus:before {
  content: "\f207";
}
.HelpOnClick-fa-ioxhost:before {
  content: "\f208";
}
.HelpOnClick-fa-angellist:before {
  content: "\f209";
}
.HelpOnClick-fa-cc:before {
  content: "\f20a";
}
.HelpOnClick-fa-shekel:before,
.HelpOnClick-fa-sheqel:before,
.HelpOnClick-fa-ils:before {
  content: "\f20b";
}
.HelpOnClick-fa-meanpath:before {
  content: "\f20c";
}
.HelpOnClick-fa-chat:before {
  content: "\f086";
} 
.HelpOnClick-fa-email:before {
  content: "\f0e0"; 
}
.HelpOnClick-fa-call:before {
  content: "\f095";
}

.HelpOnClick-FontIconsLibrary .HelpOnClick-fa {
  padding: 5px;
}
.HelpOnClick-FontIconsLibrary .HelpOnClick-fa:before {
  width: auto;
  margin: 5px;
}
.HelpOnClick-FontIconsLibrary .HelpOnClick-fa:hover {
  background: #eee;
}
