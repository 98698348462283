@import '_mixins';

$icon_themes: (
	default: (
		underline-image:url("assets/images/Underline_icons/line_steelblue.png"),
		italic-image:url("assets/images/Italic_icons/Italic_steelblue.png"),
		bold-image:url("assets/images/bold_Icons/bold_steelblue2.png"),
		emoji-image:url("assets/images/smily_icons/smiling-steelblue.png"),
		attachment-image:url("assets/images/attachment_icon/attachmant_steelblue.png"),	
		footstep-image:url("assets/images/footstep_icon/footstep_steelblue.png"),
		history-image:url("assets/images/history_Icon/history_steelblue.png"),
		info-image:url("assets/images/info_icon/info_steelblue.png"),
		search-image:url("assets/images/search_icon/search_steelblue.png"),	
		list-icon:url("assets/images/list_icon/listicon_steelblue.png"),		
		response-image:url("assets/images/response_chat/steelBlue.png"),
		
			),
	steelBlue: (
		underline-image:url("assets/images/Underline_icons/line_steelblue.png"),
		italic-image:url("assets/images/Italic_icons/Italic_steelblue.png"),
		bold-image:url("assets/images/bold_Icons/bold_steelblue2.png"),
		link-image:url("assets/images/link_icons/link_steelblue.png"),
		emoji-image:url("assets/images/smily_icons/smiling-steelblue.png"),
		attachment-image:url("assets/images/attachment_icon/attachmant_steelblue.png"),
		footstep-image:url("assets/images/footstep_icon/footstep_steelblue.png"),
		history-image:url("assets/images/history_Icon/history_steelblue.png"),
		info-image:url("assets/images/info_icon/info_steelblue.png"),
		search-image:url("assets/images/search_icon/search_steelblue.png"),	
		list-icon:url("assets/images/list_icon/listicon_steelblue.png"),
		response-image:url("assets/images/response_chat/steelBlue.png"),

			),
	lightSeaGreen: (
		underline-image:url("assets/images/Underline_icons/line_seagreen.png"),
		italic-image:url("assets/images/Italic_icons/Italic_seagreen.png"),
		bold-image:url("assets/images/bold_Icons/bold_seagreen2.png"),
		link-image:url("assets/images/link_icons/link_seagreen.png"),
		emoji-image:url("assets/images/smily_icons/smiling-seagreen.png"),
		attachment-image:url("assets/images/attachment_icon/attachmant_seagreen.png"),
		footstep-image:url("assets/images/footstep_icon/footstep_seagreen.png"),
		history-image:url("assets/images/history_Icon/history_seagreen.png"),
		info-image:url("assets/images/info_icon/info_seagreen.png"),
		search-image:url("assets/images/search_icon/search_seagreen.png"),
		list-icon:url("assets/images/list_icon/listicon_seagreen.png"),
		response-image:url("assets/images/response_chat/lightSeaGreen.png"),	
			),
	deepPink: (
		underline-image:url("assets/images/Underline_icons/line_pink.png"),
		italic-image:url("assets/images/Italic_icons/Italic_pink.png"),
		bold-image:url("assets/images/bold_Icons/bold_pink2.png"),
		link-image:url("assets/images/link_icons/link_pink.png"),
		emoji-image:url("assets/images/smily_icons/smiling-pink.png"),
		attachment-image:url("assets/images/attachment_icon/attachmant_pink.png"),
		footstep-image:url("assets/images/footstep_icon/footstep_pink.png"),
		history-image:url("assets/images/history_Icon/history_pink.png"),
		info-image:url("assets/images/info_icon/info_pink.png"),
		search-image:url("assets/images/search_icon/search_pink.png"),
		list-icon:url("assets/images/list_icon/listicon_pink.png"),	
		response-image:url("assets/images/response_chat/deepPink.png"),
			),
	brown: (
		underline-image:url("assets/images/Underline_icons/line_brown.png"),
		italic-image:url("assets/images/Italic_icons/Italic_brown.png"),
		bold-image:url("assets/images/bold_Icons/bold_brown2.png"),
		link-image:url("assets/images/link_icons/link_brown.png"),
		emoji-image:url("assets/images/smily_icons/smiling-brown.png"),
		attachment-image:url("assets/images/attachment_icon/attachmant_brown.png"),
		footstep-image:url("assets/images/footstep_icon/footstep_brown.png"),
		history-image:url("assets/images/history_Icon/history_brown.png"),
		info-image:url("assets/images/info_icon/info_brown.png"),
		search-image:url("assets/images/search_icon/search_brown.png"),	
		list-icon:url("assets/images/list_icon/listicon_brown.png"),
		response-image:url("assets/images/response_chat/brown.png"),
			),
	navyBlue: (
		underline-image:url("assets/images/Underline_icons/line_navyblue.png"),
		italic-image:url("assets/images/Italic_icons/Italic_navyblue.png"),
		bold-image:url("assets/images/bold_Icons/bold_navyblue2.png"),
		link-image:url("assets/images/link_icons/link_navyblue.png"),
		emoji-image:url("assets/images/smily_icons/smiling-navyblue.png"),
		attachment-image:url("assets/images/attachment_icon/attachmant_navyblue.png"),
		footstep-image:url("assets/images/footstep_icon/footstep_navyblue.png"),
		history-image:url("assets/images/history_Icon/history_navyblue.png"),
		info-image:url("assets/images/info_icon/info_navyblue.png"),
		search-image:url("assets/images/search_icon/search_navyblue.png"),
		list-icon:url("assets/images/list_icon/listicon_navyblue.png"),	
		response-image:url("assets/images/response_chat/navyBlue.png"),
			),
	mustardYellow: (
		underline-image:url("assets/images/Underline_icons/line_yellow.png"),
		italic-image:url("assets/images/Italic_icons/Italic_yellow.png"),
		bold-image:url("assets/images/bold_Icons/bold_yellow2.png"),
		link-image:url("assets/images/link_icons/link_yellow.png"),
		emoji-image:url("assets/images/smily_icons/smiling-yellow.png"),
		attachment-image:url("assets/images/attachment_icon/attachmant_yellow.png"),
		footstep-image:url("assets/images/footstep_icon/footstep_yellow.png"),
		history-image:url("assets/images/history_Icon/history_yellow.png"),
		info-image:url("assets/images/info_icon/info_yellow.png"),
		search-image:url("assets/images/search_icon/search_yellow.png"),
		list-icon:url("assets/images/list_icon/listicon_yellow.png"),
		response-image:url("assets/images/response_chat/mustardYellow.png"),	
			),
	violet: (
		underline-image:url("assets/images/Underline_icons/line_voilet.png"),
		italic-image:url("assets/images/Italic_icons/Italic_voilet.png"),
		bold-image:url("assets/images/bold_Icons/bold_voilet2.png"),
		link-image:url("assets/images/link_icons/link_voilet.png"),
		emoji-image:url("assets/images/smily_icons/smiling-voilet.png"),
		attachment-image:url("assets/images/attachment_icon/attachmant_voilet.png"),
		footstep-image:url("assets/images/footstep_icon/footstep_voilet.png"),
		history-image:url("assets/images/history_Icon/history_voilet.png"),
		info-image:url("assets/images/info_icon/info_voilet.png"),
		search-image:url("assets/images/search_icon/search_voilet.png"),
		list-icon:url("assets/images/list_icon/listicon_voilet.png"),
		response-image:url("assets/images/response_chat/violet.png"),	
			),
	blue: (
		underline-image:url("assets/images/Underline_icons/line_blue.png"),
		italic-image:url("assets/images/Italic_icons/Italic_blue.png"),
		bold-image:url("assets/images/bold_Icons/bold_blue2.png"),
		link-image:url("assets/images/link_icons/link_blue.png"),
		emoji-image:url("assets/images/smily_icons/smiling-blue.png"),
		attachment-image:url("assets/images/attachment_icon/attachmant_blue.png"),
		footstep-image:url("assets/images/footstep_icon/footstep_blue.png"),
		history-image:url("assets/images/history_Icon/history_blue.png"),
		info-image:url("assets/images/info_icon/info_blue.png"),
		search-image:url("assets/images/search_icon/search_blue.png"),
		list-icon:url("assets/images/list_icon/listicon_blue.png"),
		response-image:url("assets/images/response_chat/blue.png"),	
			),
	darkGreen: (
		underline-image:url("assets/images/Underline_icons/line_darkgreen.png"),
		italic-image:url("assets/images/Italic_icons/Italic_darkgreen.png"),
		bold-image:url("assets/images/bold_Icons/bold_darkgreen2.png"),
		link-image:url("assets/images/link_icons/link_darkgreen.png"),
		emoji-image:url("assets/images/smily_icons/smiling-darkgreen.png"),
		attachment-image:url("assets/images/attachment_icon/attachmant_darkgreen.png"),
		footstep-image:url("assets/images/footstep_icon/footstep_darkgreen.png"),
		history-image:url("assets/images/history_Icon/history_darkgreen.png"),
		info-image:url("assets/images/info_icon/info_darkgreen.png"),
		search-image:url("assets/images/search_icon/search_darkgreen.png"),	
		list-icon:url("assets/images/list_icon/listicon_darkgreen.png"),
		response-image:url("assets/images/response_chat/darkGreen.png"),
			),
	green: (
		underline-image:url("assets/images/Underline_icons/line_green.png"),
		italic-image:url("assets/images/Italic_icons/Italic_green.png"),
		bold-image:url("assets/images/bold_Icons/bold_green2.png"),
		link-image:url("assets/images/link_icons/link_green.png"),
		emoji-image:url("assets/images/smily_icons/smiling-green.png"),
		attachment-image:url("assets/images/attachment_icon/attachmant_green.png"),
		footstep-image:url("assets/images/footstep_icon/footstep_green.png"),
		history-image:url("assets/images/history_Icon/history_green.png"),
		info-image:url("assets/images/info_icon/info_green.png"),
		search-image:url("assets/images/search_icon/search_green.png"),
		list-icon:url("assets/images/list_icon/listicon_green.png"),
		response-image:url("assets/images/response_chat/green.png"),	
			),
	grey: (
		underline-image:url("assets/images/Underline_icons/line_grey.png"),
		italic-image:url("assets/images/Italic_icons/Italic_grey.png"),
		bold-image:url("assets/images/bold_Icons/bold_grey2.png"),
		link-image:url("assets/images/link_icons/link_grey.png"),
		emoji-image:url("assets/images/smily_icons/smiling-grey.png"),
		attachment-image:url("assets/images/attachment_icon/attachmant_grey.png"),
		footstep-image:url("assets/images/footstep_icon/footstep_grey.png"),
		history-image:url("assets/images/history_Icon/history_grey.png"),
		info-image:url("assets/images/info_icon/info_grey.png"),
		search-image:url("assets/images/search_icon/search_grey.png"),	
		list-icon:url("assets/images/list_icon/listicon_grey.png"),
		response-image:url("assets/images/response_chat/grey.png"),
			),
	deepSkyBlue: (
		underline-image:url("assets/images/Underline_icons/line_skyblue.png"),
		italic-image:url("assets/images/Italic_icons/Italic_skyblue.png"),
		bold-image:url("assets/images/bold_Icons/bold_skyblue2.png"),
		link-image:url("assets/images/link_icons/link_skyblue.png"),
		emoji-image:url("assets/images/smily_icons/smiling-skyblue.png"),
		attachment-image:url("assets/images/attachment_icon/attachmant_skyblue.png"),
		footstep-image:url("assets/images/footstep_icon/footstep_skyblue.png"),
		history-image:url("assets/images/history_Icon/history_skyblue.png"),
		info-image:url("assets/images/info_icon/info_skyblue.png"),
		search-image:url("assets/images/search_icon/search_skyblue.png"),	
		list-icon:url("assets/images/list_icon/listicon_skyblue.png"),
		response-image:url("assets/images/response_chat/deepSkyBlue.png"),
			)
) !default;


@mixin icon_theme($name, $underline-image, $italic-image , $bold-image , $link-image , $emoji-image , $attachment-image , $footstep-image , $history-image , $info-image ,$search-image,$list-icon,$response-image ) {
	.#{$name} {

			.editorInline {
				>div {
					img {
						display: none;
					}
					background-repeat: no-repeat;
					background-position: 50%;
					background-color: transparent;
					border: none;
					&:first-child {
						background-image: $bold-image !important;
					}
					&:nth-child(2) {
						background-image: $italic-image !important;
					}
					&:nth-child(3) {
						background-image: $underline-image !important;
					}
				}
			}

			

			.editorEmoji {
				img {
					display: none;
				}
				background-repeat: no-repeat;
    			background-position: 50%;
    			background-color: transparent;
    			border: none;
				background-image: $emoji-image !important;
				
				}
				.overview{
				ul{
					list-style-image: $list-icon !important ;
					li{
						@include padding-direction(left, 8px);
						
					}
				}
			}
				

				.editorAttachImg{
					margin-left:5px;
					background-repeat: no-repeat;
					background-position: 50%;
					background-color: transparent;
					border: none;
					background-image: $attachment-image !important;
				}

				.innercontent_wrapper .box {

					.searchText {
						input {
							@include padding-direction(right, 27px);
							background-repeat: no-repeat;
							background-position: 97%;
							background-image: $search-image !important;
						}
					}
				}

				.textEditorLink {

					img {
						display: none;
					}
					background-repeat: no-repeat;
					background-position: 50%;
					background-color: transparent;
					border: none;
					background-image: $link-image !important;
				}

				.editorLink {
					img {
						display: none;
					}
					background-repeat: no-repeat;
					background-position: 50%;
					background-color: transparent;
					border: none;
					background-image: $link-image !important;
						
				}

				.chatInfoWrapper {
					
					.nav.nav-tabs {
						a.nav-item {
							 &:nth-child(1) {
								 background-image: url("assets/images/infotab.png");
								 &.active {
									 background-image: $info-image !important;
								 }
							 }
							 &:nth-child(2) {
								 background-image: url("assets/images/footstepstab.png");
								 &.active {
									 background-image: $footstep-image !important;
								 }
							 }
							 &:nth-child(3) {
								 background-image: url("assets/images/historytab.png");
								 &.active {
									 background-image: $history-image !important;
								 }
							 }
							 &:nth-child(4) {
								 border-top-right-radius: 10px;
								 background-image: url("assets/images/responsetab.png");
								 &.active {
									 //background-image: url("assets/images/responsetab_selected.png");
									 background-image: $response-image !important;
									
								 }
							 }
							 &.active {
								 color: #3ba8c5;
								background-color: #fff;
							 }
						}
					}
					.tab-content {
						position: relative;
				
						p.hide_btn{
							color: #3ba8c5;
							font-family: "Segoe UI Bold";
							font-size: 10px;
							font-weight: 700;
							text-transform: uppercase;
							position: absolute;
							width: 100%;
							margin: 0;
							top: -7px;
							span {
								width: 70px;
								display: inline-block;
								background-color: #fff;
								cursor: pointer;
								img {
									margin-right: 8px;
									margin-top: -2px;
								}
							}
						}
				
					}
					.tabContent {
						text-align: left;
						margin-top: 24px;
						padding-top: 35px;
						border-top: 1px solid #dedede;
						h6 {
							color: #000000;
							font-family: "Helvetica Neue Cyr Roman";
							font-size: 22px;
							font-weight: 400;
							text-align: left;
							margin: 0 20px 20px;
						}
						color: #000000;
						font-family: "Segoe UI Semi Bold";
						font-size: 14px;
						font-weight: 400;
						.data {
							p {
								margin-bottom: 10px;
								margin-top: 0;
							}
							.label {
								padding-right: 0;
								p {
									margin-left: 6px;
									font-family: "Segoe UI Semi Bold";
									font-size: 14px;
								}
								
							}
							.value {
								p{
									margin-right: 6px;
									font-family: "Segoe UI";
									font-size: 14px;
								}
							}
				
							.webInfo {
								padding: 25px 20px;
								background-color: #ebf6f9;
								margin-bottom: 20px;
								margin-top: 15px;
								>p {
									font-family: "Segoe UI Semi Bold";
									margin-bottom: 20px;
									margin-top: 0;
									font-size: 14px;
								}
								.row{
									&:last-child {
										p {
											margin-bottom: 0;
										}
									}
								}
								.label {
									p {
										margin-left: 0;
									}
								}
							}
				
							.notes {
								padding: 25px 20px;
								background-color: #ebf6f9;
								border-radius: 0 0 10px 10px;
								margin-top: 12px;
								h6 {
									color: #293037;
									font-family: "Segoe UI Bold";
									font-size: 14px;
									font-weight: 700;
									text-transform: uppercase;
									margin-left: 0;
									margin-right: 0;
								}
								button {
									background-color: transparent;
									&:hover {
										color: inherit;
									}
								}
								.notesData {
									margin-bottom: 27px;
									p {
										width: 100%;
									}
								}
							}
							.rowLayout {
								.label {
									p {
									}
									
								}
								.value {
									p{
										margin: 0 6px;
									}
								}
								.link {
									color: #3ba8c5;
									img {
										margin-left: 8px;
									}
								}
								.disabled {
									color: #adadad;
								}
							}
							.withImage {
								img {
										margin-left: 8px;
										margin-top: -3px;
									}
							}
						}
						.footstepsWrapper {
							padding-bottom: 35px;
							.link {
									color: #3ba8c5;
									img {
										margin-left: 8px;
									}
								}
						}
						.historyWrapper {
							padding-bottom: 40px;
							>div {
								>div.label,  >div.value{
									cursor: pointer;
								}
							}
							.label {
								p {
									font-size: 12px;
				
									span {
										color: #adadad;
									}
								}
							}
							.value {
								p{
									color: #3ba8c5;
									font-size: 14px;
								}
							}
							.transcriptDetails {
								padding-top: 25px;
								padding-bottom: 0;
								max-height: 60vh;
								margin-bottom: 20px;
								
								p{
									font-size: 14px;
								}
								.value {
									p{
										color: #000000;
									}
								}
								>img {
									margin-top: -15px;
									margin-right: -9px;
									cursor: pointer;
								}
								.transcript {
									h6{
										color: #293037;
										font-family: "Segoe UI Bold";
										font-size: 14px;
										font-weight: 700;
										text-transform: uppercase;
										margin: 20px 6px 15px 22px;
									}
									>div {
										p{
											margin-left: 6px;
											&:first-child {
												font-family: "Segoe UI Semi Bold";
												margin-bottom: 0;
												span {
													color: #adadad;
												}
											}
										}
									}
								}
								.rcs-custom-scroll .rcs-inner-container > div {
									padding: 0;
								}
							}
						}
						&.responseWrapper{
							.panelWrapper {
								border-top-right-radius: 0;
								border-top-left-radius: 0;
							}
							.searchForm {
								padding: 0 20px;
								.searchText input {
									padding-right: 27px;
									background-repeat: no-repeat;
									background-position: 97%;
									background-image: $search-image !important;
									&::placeholder {
										color: #adadad;
										font-family: "Segoe UI";
										font-size: 14px;
										font-weight: 400;
									}
								}
								p {
									color: #adadad;
									font-family: "Segoe UI";
									font-size: 14px;
									font-weight: 400;
									span{
										color: #010101;
										font-family: "Segoe UI Semi Bold";
										font-weight: 600;
									}
								}
							}
							.ruleComponent {
								padding: 15px 20px;
								>div {
									&:first-child {
										max-width: max-content;
									}
								}
				
								.rule_data {
									p {
										&.ques {
											font-family: "Segoe UI Semi Bold";
											font-weight: 600;
										}
									}
								}
							}
						}
					}
				}
			}
    	}


@each $icon_theme, $color in $icon_themes {
	$underlineImage: map-get($color, underline-image);
	$italicimage:map-get($color, italic-image);
	$boldImage:map-get($color, bold-image);
	$linkImage:map-get($color, link-image);
	$emojiImage:map-get($color, emoji-image);
	$attachmentImage:map-get($color, attachment-image);
	$footstepImage:map-get($color, footstep-image);
	$historyImage:map-get($color, history-image);
	$infoImage:map-get($color, info-image);
	$searchImage:map-get($color, search-image);
	$listIcon:map-get($color, list-icon);
	$responseImage:map-get($color, response-image);
	
	

  	@include icon_theme($icon_theme,$underlineImage , $italicimage , $boldImage , $linkImage , $emojiImage , $attachmentImage , $footstepImage , $historyImage , $infoImage , $searchImage, $listIcon,$responseImage);
}
        
