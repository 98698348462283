  .input-container {
    display: flex;
    align-items: center; /* Adjust alignment */
    height: 14vh;
    max-width: 100%; /* Prevent overflow */
  }
  
  
  .styled-input {
    width: 300px;
    height: 30px;
    border-radius: 25px; /* Half-circle border-radius */
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 16px;
    background-color: #f9f9f9;
    color: #333;
    cursor: not-allowed; /* Change cursor to indicate it's disabled */
  }
  .switch {
	text-align: right;
	position: absolute;
	display: inline-block;
	width: 44px;
	height: 22px;
  }
  
  .switch input {
	opacity: 0;
	width: 0;
	height: 0;
  }
  
  .slider {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #ccc;
	border-radius: 34px;
  }
  
  .slider:before {
	position: absolute;
	content: "";
	height: 15px;
	width: 15px;
	left: 0px;
	bottom: 4px;
	background-color: white;
	border-radius: 50%;
	transition: .4s;
  }
  
  input:checked + .slider {
	background-color: #0dc22b;
  }
  
  input:focus + .slider {
	box-shadow: 0 0 1px #0dc22b;
  }
  
  input:checked + .slider:before {
	transform: translateX(26px);
  }
  
  .cardbox {
	padding: 26px;
	font-size: small;
  }
  
  .checkboxalign {
	float: right;
	top: -19px;
	margin-right: 10px;
  }
  
  .text-wrapper {
	white-space: normal;
	margin-left: -23px;
  }
  
  .pagebox {
	margin-bottom: 10px;
  }
  
  .checkbox-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	border-bottom: 1px solid #e0e0e0; /* Light border for separation */
  }
  .modal-dialog .modal-content{
	width: auto;
  }
  .subcheckboxalign {
	float: right;
	top: -34px;
	margin-right: 10px;
  }

  .message {
    padding: 4px;
    margin: 5px 0;
    border-radius: 8px;
}
.message.user {
    //background-color: #e5eeea;
    text-align: left;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}
.message.ai {
    //background-color: #e7e4e4;
    text-align: left;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}

@keyframes typing {
  0% { transform: translateY(0px); opacity: 0.3; }
  50% { transform: translateY(-5px); opacity: 1; }
  100% { transform: translateY(0px); opacity: 0.3; }
}

.typing-indicator {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.typing-indicator .dot {
  width: 8px;
  height: 8px;
  margin-right: 4px;
  //background-color: gray;
  border-radius: 50%;
  animation: typing 1.5s infinite ease-in-out;
}

.typing-indicator .dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator .dot:nth-child(3) {
  animation-delay: 0.4s;
}

.hover-link {
  position: relative;
  display: inline-block;  
  color: blue;
}

.edit-icon {
  visibility: hidden;opacity: 0;
  transition: visibility 0s, opacity 0.3s ease-in-out;
}

.hover-link:hover .edit-icon {
  visibility: visible;opacity: 1;
}

#questionArea {
  overflow: auto;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #fff; /* For Firefox */
}

/* Custom scrollbar for Webkit browsers (Chrome, Safari, Edge) */
#questionArea::-webkit-scrollbar {
  width: 10px;
}

#questionArea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

#questionArea::-webkit-scrollbar-thumb {
  background: #fff; 
  border-radius: 5px;
}

#questionArea::-webkit-scrollbar-thumb:hover {
  background: #fff; 
}

#questionArea {
  scrollbar-width: none; 
}

#questionArea::-webkit-scrollbar {
  display: none; 
}

#questionArea:hover {
  scrollbar-width: thin;
}

#questionArea:hover::-webkit-scrollbar {
  display: block;
}