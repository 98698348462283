@media (max-width: 1025px) { 
  .optDeptWrapper {
    .operatorSection {
      .operatorsWrapper {
        .box {
          width:47%;
          margin-bottom: 10px;
        }
      }
    }
    &.section_Dept  {
      .panelHeader {
        padding-right: 0;
        >div {
          padding-left: 0;
        }
      }
      .panelContent {
        padding-right: 0;
        >div {
          padding-left: 0;
        }
        p {
          font-size: 13px;
          word-break: break-all;
        }
        .editWrapper {
          p{
            font-size: 11px;
          }
        }
      }
    }
  }
}

@media (max-width: 769px) {
 .innerContentWrapper{
    margin-right: 0;
  }   
.logo_wrapper{
  padding-left: 0;
}
.notification{
    margin: 0 20px;
}
 .faq {
    margin-left: 20px;
}
.profileStatus {
    // margin-right: 20px;
}
.profileStatus .status p {
    font-size: 13px;
  }
  .profileStatus .status p.text-active{
    font-size: 12px;
  }
.checkout{
  margin-left: 0;
  padding: 22px 18px 22px 18px;
}
#sidebarCollapse{
  display: block;
}
.header_title {
    height: 65px;
    padding-left: 26px;
}
#sidebar >ul.sideMenu{
  margin-left: 15px;
  min-height: 552px;
}
#sidebar {
    position: fixed;
    top: 61px;
    left: -318px;
    height: 100vh;
    min-width: 318px;
    width: 318px;
    z-index: 999;
    transition: all 0.3s;
    overflow-y: auto;
    min-height: 100vh;
}
#sidebar .subMenu{
  min-height: 475px;
}
#sidebar.open,#sidebar.active {
    min-width: 318px;
    max-width: 318px;
    &.operator_menu {
      width: fit-content;
      min-width: 245px;
      max-width: 245px;
      height: max-content;
    }
}

#sidebar.open {
  left: 0;
}
#sidebar .submenu_wrapper.active {
    margin-left: auto;
}
  .settingFormWrapper{
    margin-right: 0;
  }
  .settingFormWrapper .form-label, .customDrpDwn .css-10nd86i, .image_upload p, .profileImg .img_delete p:last-child{
    font-size: 12px;
  }
  .profileImg .img_delete p:first-child {
    padding: 0 9px 0 10px;
  }
  .profileImg .img_delete p:last-child{
    margin-left: -8px;
  }
  .chatResponseWrapper {
    .response_wrapper {
      label {
        text-align: left;
      }
    }
  }
  
 
}

@media(max-width: 767px) {
  .invitationRulesWrapper .editRulesSection .customFormGroup.drpDwnGrp .customDrpDwn:first-child {
    margin-right: 0;
    margin-bottom: 10px;
  }
} 

@media (max-width: 576px){

  .innercontent_wrapper {
    .box{
      padding: 30px 20px;
    } 
  }

    #sidebarCollapse {
      width: 30px;
      height: 30px;
      padding: 1px 3px;
      border: none;
  }
  .logo_wrapper {
    padding-left: 0;
    margin-left: 10px;
    padding-right: 0;
  }
  .header{
    margin-bottom: 3px;
  }
    .innerForm .form-group >div {
      padding-left: 15px;
    }
     .settings_wrapper .box {
      padding: 20px;
    }
    .menu-right .row{
      margin-right: 0;
    }
    .notification {
      margin: 0 10px;
    }
    .faq {
      margin-left: 10px;
    }
    .profileStatus {
      margin-right: 5px;
      margin-top: 13px;
    }
    .profileStatus .avatar{
      margin-right: 0;
    }
    .formBtns{
      padding-top: 20px;
      margin-top: 20px;
      padding-right: 15px;
    }
    .formBtns .pullRight {
      float: none;
    }

    .formBtns Button.btn_clr {
      margin-left: 0;
    }
    .formBtns Button {
      width: 90px;
      height: 34px;
    }
    .green_circle, .red_circle{
      width: 35px;
      height: 35px;
      border-width: 1px;
    }
    .profileStatus .status{
      float: none;
    }
    .profileStatus .status p{
      font-size: 12px;
    }
    .cart {
      margin-top: -8px;
    }
    .profileStatus .status .status-img {
      right: -10px;
      bottom: 7px;
    }

    .optDeptWrapper .operatorSection .operatorsWrapper .box {
      width:94%;
      margin-bottom: 10px;
    }

    .innercontent_wrapper .traffic_wrapper .dropDownWrapper {
      float: left;
      margin-bottom: 15px;
      .customDrpDwn >div {
        &:nth-child(2) {
          width: 75px;
        }
        &:nth-child(3) {
          width: 127px;
        }
      }
    }
    .traffic_wrapper {
      .formBtns {
          Button {
            &.btn_clr {
              width: 79px;
              margin-left: 10px;
            }
          }

        }
    } 
}

@media (max-width: 600px) {
  .header_title h4 {
    font-size: 1.1rem;
  }
  .optDeptWrapper {
    .chatRedirect {
      >div {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        label {
          margin-top: 7px;
          padding-right: 25px;
        }
      }
    }
    &.section_Dept .panelContent .editWrapper p span {
      margin: 0 12px 0 7px;
    }
    .panelWrapper {
      .panelHeader {
        p {
          font-size: 12px
        }
      }
    }
  }
  .textEditorWrapper .sendImgWrappger {
        right: 6px;
  }
  .innercontent_wrapper .chatWrapper.with-header .box{
      .headerTitle {
        padding: 24px 0 24px 15px;
      }
      .contentWrapper {
        padding: 20px 0px 30px 0px;

      }
      .rcs-custom-scroll .rcs-inner-container > div {
        margin-right: 0!important;
      }
     .chatSection {
        padding-right: 0;
      }
      .editorWrapper {
        margin-right: 0;
        width: 100%;
      }
      >div:nth-child(2) {
        padding-right: 0;
      }
  } 
}