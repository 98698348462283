.innercontent_wrapper {
	.traffic_wrapper {
		.rcs-custom-scroll .rcs-inner-container > div {
			padding: 0;
		}
		.box {
			padding: 40px 30px;
		}
		.headerTitle {
			h4 {
				color: #000000;
				font-family: "Helvetica Neue Cyr Roman";
				font-size: 22px;
			}
		}
		.panelWrapper{
			.panelHeader {
				padding: 0 30px;
				p {
					color: #ffffff;
					font-family: "Helvetica Neue Cyr Roman";
					font-size: 14px;
					text-align: left;
					margin: 15px 0 13px;
				}
			}
			.scrollWrapper {
				>div {
					width: 100%;
				}
			}
			.scriptPanel {
				.panelContent {
					padding-bottom:0;
					padding-right: 0;
					padding-top: 0;
					div.parent_toggle {
						padding-right: 11px;
						padding-left: 11px;
					}

					
					.child_wrapper {
						.link_row {
							padding: 0 30px;
						}
					}
				}
				.action {
					&.traffic_row.odd {
						.child_wrapper {
							background-color: #fff;
						}
					}
				}
				p{
					color: #000000;
					font-size: 14px;

					.link_img {
						margin-left: 5px;
					}
				}
				// .collapse_wrapper {
				// 	p {
				// 		&:first-child {
				// 		    border-radius: 4px;
				// 		    border: 1px solid #3ba8c5;
				// 		    background-image: url("assets/images/traffic_expand.png");
				// 		    background-repeat: no-repeat;
				// 		    background-position: center;
				// 		}
						
				// 	}
					
				// }
				.with-image {
					p{
						&:first-child {
						    width: 16px;
						    height: 16px;
						    padding: 0;
						    float: left;
						    margin: 18px 0 0 0;
						}
						&:last-child {
							margin-left: 18px;
						}
						img {
							margin-right: 10px;
							margin-top: -3px;
							&.child-img {
								margin-left: 23px;
							}
						}
						a {
							color: #3ba8c5;
							font-size: 14px;
							font-weight: 400;
						}
					}
					.link {
						>div {
							&:first-child {
								flex: 0 0 15%;
								max-width: 15%;
							}
							&:last-child {
								flex: 0 0 85%;
								max-width: 85%;
							}
						}
					}
				}
				.collapsed .collapse_wrapper p:first-child {
					background-image: url("assets/images/traffic_collapse.png");
				}
				.dropdown-toggle::after {
					display: none;
				}
			}

		} 
		.formBtns {
			margin: 0;
			padding: 10px 30px;

		}
		
		.optheader{

			color:#ffffff !important;
			font-family: 'Helvetica Neue Cyr Roman' !important;
			font-size: 14px !important;

		}

		.dropDownWrapper {
			margin-bottom: 30px;
			float: right;
			.customDrpDwn {
				span {
					margin-top:6px;
					color: #000000;
					font-family: "Segoe UI Semi Bold";
					font-size: 14px;
					font-weight: 400;
				}
				span, >div {
					float: left;

				}
				>div {
					margin-left: 10px;
					&:nth-child(2) {
						width: 88px;
					}
					&:nth-child(3) {
						width: 198px;
					}
				}
			}
			
		}
	}
}
