@import '_mixins';

.custom_btn{
	height: 29px;
	border-radius: 3px;
	border: 1px solid #3ba8c5;
	background-color: #ffffff;
	@include padding(0px 18px);
	color: #3ba8c5;
	font-family: 'Segoe UI Bold';
	font-size: 13px;

	&.btn-primary:hover {
		background-color: #3ba8c5;
		border-color: #3ba8c5;
	}
}
.scheduleformBtns{
	padding-top: 6px; 
	padding-right: 0;
}
.invitationRow{
	.leftSection{
		.invitationimg{
			height: 148px;
			width: 115px;
		}
	}
	
}

.responsetitle{
	overflow-wrap: break-word;
    overflow: hidden;
	display: -webkit-box;
	 
	-webkit-line-clamp: 3;
	/* autoprefixer: off */

-webkit-box-orient: vertical;

/* autoprefixer: on */
    
}
// .customname {
// border-Color: #FF0000;
// border-Width:2px
// } 
.previewsave .widget-preview .widget-preview-title{
	padding: 15px 0 0 18px; 
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    text-align: left;
}

.widgetContent .widget_iconpreview{

	width: 280px;
	background-Size: 260px;
	background-Repeat: no-repeat;
	height: 80px !important;
}

.previewsave .widget-preview .widget-preview-text{
	width: 146px;
	overflow: hidden;
	white-space: nowrap;
	padding: 1px 0 0;
	text-align: center;
	color: #a8a8a8;
	font-size: 10px;
	margin-left: -6px; 

}
.previewsave .widget-preview .widgeticon-preview-title{
	padding: 15px 0 0 18px; 
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    text-align: left;
} 
.previewsave .widget-preview .widgeticon-preview-text{
	width: 146px;
	overflow: hidden;
	white-space: nowrap;
	padding: 1px 0 0;
	text-align: center;
	color: #a8a8a8;
	font-size: 10px;
	margin-left: -6px; 

}
@media (max-width: 1300px) {
	 
}
@media (max-width: 1200px) {
	 
}
@media (max-width: 1030px) {
	 
	
}
@media (max-width: 576px){
	 
}
@media (max-width: 375px){
	 
}
#icon_buttons ul{ 
		padding: 0;
		margin: 0;
		list-style: none; 
		display: inline-block ;
		  height: 50px;
		  width: max-content;  	   
}
#icon_buttons ul span {
	text-decoration: none;
	color: #fff;
	display: table-cell;
	font-weight: 700;
	font-size: 14px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis; 
	vertical-align: middle;
	padding-left: 5px !important;
	padding-bottom: 4px;
}
#icon_buttons ul span img {
	width:115px;
	//height:60px;
}
#icon_buttons ul li div {
	display: inline-table;
vertical-align: middle;
height: 100%;
}

#icon_buttons li {
  display: inline-block; 
	vertical-align: middle;
	height: 100%;  
	height: 100%; 
	min-width: 30px;
	min-height: 30px;
	margin-bottom: 0; 
}
#icon_buttons ul li:first-child{
   border-left: 0;
}
#icon_buttons ul li:last-child{
   border-right: 0;
}

#div_buttons ul {
	padding: 0;
	margin: 0;
	list-style: none; 
	display: inline-block ;
	  background: rgb(4, 129, 176);
	  height: 50px;
	  width: max-content;
	  border-top-left-radius: 5px;
	  border-top-right-radius: 5px; 
	  margin-block-start: 1em;
	  margin-block-end: 1em;
	  margin-inline-start: 0px;
	  margin-inline-end: 0px;
	  padding-inline-start: 0px;
		  margin-bottom: 0px;
  
  }
  
  #div_buttons ul span {
	  text-decoration: none;
	  color: #fff;
	  display: table-cell;
	  font-weight: 700;
	  font-size: 14px;
	  white-space: nowrap;
	  overflow: hidden;
	  text-overflow: ellipsis;
	  padding-left: 8px;
	  padding-right: 15px;
	  vertical-align: middle;
  }
  #div_buttons ul li div {
	  display: inline-table;
  vertical-align: middle;
  height: 100%;
  }
  
  #div_buttons li {
	display: inline-block; 
	  vertical-align: middle;
	  height: 100%; 
	  border-bottom: none;
	  border-top: none;
	  border-right: 1px solid;
	  border-left: 1px solid;
	   border-right-color: rgb(24, 149, 196);
	  border-left-color: rgb(68, 193, 240);
	  height: 100%; 
	  min-width: 30px;
	  min-height: 30px;
	  margin-bottom: 0; 
  }
  #div_buttons ul li:first-child{
	 border-left: 0;
  }
  #div_buttons ul li:last-child{
	 border-right: 0;
  }
  #maindiv {
  width: 100%;
  overflow: auto;
  margin-top: 18px;
  }
  

.helptourstartbtn{
background-color: rgb(232, 151, 18);
border: 0px none;
border-radius: 4px;
color: rgb(255, 255, 255);
cursor: pointer;
font-size: 16px;
line-height: 1;
padding: 8px;
-moz-appearance: none;
}
.mailer .modal-dialog .getcodetextarea{
	width:100%;
}

.contentlogin{
	border-bottom-right-radius:20px;
	border-bottom-left-radius:20px;
}
.forgotnew {
	color: #3ba8c5;
	cursor: pointer; 
}
.forgotnew::hover {
	color: #000000; 
}
.pullLeft {
	float: left;
}

.main_content {
	@include margin-direction(left, 90px);
	&.menu_active {
		@include margin-direction(left, 285px);
	}
}
.operatorPanel {
	.main_content {
		&.menu_active {
			@include margin-direction(left, 214px);
		}
	}
}

.form-group {
	position: relative;
	.form-control {
		border-radius: 5px;
		border: 1px solid #dedede;
		&.has-error {
			border-color: #c94040;
		}
	}
	.error-block {
		float: left;
		font-size: 12px;
		position: absolute;
		left: 15px;
		color: #c94040;
	}
}


.form-control:focus, .btn-primary:focus, .btn-primary.focus {
	box-shadow: none;
}

.custom_radionBtn {
	background-color: transparent;
	border: none;
	display: block;
    position: relative;
    padding-left: 35px;
    padding-right: 35px;
    margin-bottom: 12px;
    color: #000000;
	font-family: 'Segoe UI';
	font-size: 13px;
	font-weight: 400;
	line-height: 14px;
	input:checked ~ .customRadioOption {
		background-color: #aac3a1;
		&:after {
			display: block;
		}
	}

	&.btn-primary:hover, &.btn-primary:not(:disabled):not(.disabled):focus, &.btn-primary:not(:disabled):not(.disabled).active:focus,&.btn-primary:not(:disabled):not(.disabled):active, &.btn-primary:not(:disabled):not(.disabled).active {
			color: initial;
			background-color: transparent;
			border-color: transparent;
			box-shadow: none;
			z-index: 0;
		}

	.customRadioOption {
	    position: absolute;
	    top: 0;
	    left: 0;
	    height: 27px;
	    width: 27px;
	    background-color: #e7e7e7;
	    border-radius: 50%;
		border: 1px solid #dedede;

		
		&:after {
			content: "";
  			position: absolute;
  			display: none;
			top: 5px;
		    left: 5px;
		    border-radius: 50%;
		    width: 15px;
			height: 15px;
			background-color: #ffffff;
			background-image: linear-gradient(-45deg, #e2e7e7 0%, #ffffff 100%);
		}
	}
}

.editorWrapper {
	border-radius: 5px;
	background-color: #f6f6f6;
	border: 1px solid transparent;
	
	.editorToolBar {
		background-color: #f6f6f6;
		@include margin(0);
		height: 34px;
		position: relative;

		.editorInline {
			>div {
				img {
					display: none;
				}
				background-repeat: no-repeat;
    			background-position: 50%;
    			background-color: transparent;
    			border: none;
				&:first-child {
					background-image: url("assets/images/boldIcon.png");
				}
				&:nth-child(2) {
					background-image: url("assets/images/italicIcon.png");
				}
				&:nth-child(3) {
					background-image: url("assets/images/underlineIcon.png");
				}
			}
		}

		.editorLink {
			img {
				display: none;
			}
			background-repeat: no-repeat;
			background-position: 50%;
			background-color: transparent;
			border: none;
			background-image: url("assets/images/linkIcon.png");
				
		}

		.textEditorLink {

			img {
				display: none;
			}
			background-repeat: no-repeat;
			background-position: 50%;
			background-color: transparent;
			border: none;
			background-image: url("assets/images/linkIcon.png");

			.rdw-option-wrapper{
				//new added
				background:none !important;
			}
			.rdw-link-modal{
				height:auto;
			}
		}

		.rdw-emoji-wrapper {
			margin-left: 0px;
			//margin-left: 30px;
		}

		.editorEmoji {
				img {
					display: none;
				}
				background-repeat: no-repeat;
    			background-position: 50%;
    			background-color: transparent;
    			border: none;
				background-image: url("assets/images/emojiIcon.png");
				
		}

		.editorSpellCheck {
			position: absolute;
			left: 168px;
			top: 2px;
			img {
				padding: 5px;
			    min-width: 25px;
			    border-radius: 2px;
			    margin: 0 4px;
			    display: flex;
			    justify-content: center;
			    align-items: center;
			    cursor: pointer;
			}
		}


	}
	.customEditor {
		height: 84px;
		border-radius: 5px;
		border: 1px solid #dedede;
		background-color: #ffffff;
		overflow-y: auto;
		.DraftEditor-root {
			@include padding(12px);
			.public-DraftStyleDefault-block {
				margin: 0;
				span {
					color: #000000;
					font-family: 'Segoe UI';
					font-size: 14px;
					line-height: 20px;
				}
			}
		}
	}
	
	 
}

.innercontent_wrapper .with-header{
	.box {
		.header-no-border {
			.headerTitle {
				border-bottom: none;
			}
		}
		.headerTitle {
		    @include padding(24px 26px);
		    border-bottom: 4px solid #3ba8c5;
		}
		.contentWrapper {
		    @include padding(34px 26px);

		    .sub-header {
		    	color: #293037;
				font-family: "Segoe UI Bold";
				font-size: 13px;
				font-weight: 700;
				text-transform: uppercase;
				text-align: left;
				@include margin-direction(bottom,20px);
		    }
		}
	}
}

.custom_textArea {
	textarea {
		width: 100%;
		//height: 180px;
		border-radius: 5px;
		border: 1px solid #dedede;
		background-color: #ffffff;
		@include padding(9px 15px);
		color: #000000;
		font-family: 'Segoe UI Semi Bold';
		font-size: 14px;
		font-weight: 400;
	}
}

.customDrpDwn {
	.css-10nd86i {
	    width: 256px;
	    color: #000000;
	    font-family: 'Segoe UI Semi Bold';
	    font-size: 14px;
	    font-weight: 400;
	    border:1px solid #dedede;
	    border-radius: 4px;

	    &:hover,&:active, &:focus {
    		border-color: #4CADC6;
    	}
	    .css-vj8t7z,.css-2o5izw {
	    	box-shadow: none;
	    	border: none;
	    }
	}
	.css-19bqh2r {
		fill: #3ba8c5;
		stroke: #3ba8c5;
	}
	.css-11unzgr {
		max-height: 172px;
 	}
	.css-15k3avv {
		@include margin-direction(top,5px);
		box-shadow: 0 2px 11px rgb(217,217,217);
		.css-11unzgr {
			border: none;
			max-height: 172px;
			svg {
				display: none;
			}
		}
		.css-z5z6cw {
			border: none;
			color: #fff;
			cursor: pointer;
			padding: 5px 12px;
			&:hover {
				background-color: #41A8C3;
				color: #fff;
			}
		}
		.css-wqgs6e {
			background-color: transparent;
			cursor: pointer;
			&:hover {
				background-color: #41A8C3;
				color: #fff;
			}
		}
	}

	.css-1ep9fjw {
    	color: #3ba8c5;
	}
}

.logoIcon.customDrpDwn {
	.css-10nd86i {
	    width: 100%;
	    border: none;

	    &:hover,&:active, &:focus {
    		border-color: transparent;
    	}
    	.css-vj8t7z,.css-2o5izw {
	    	background-color: transparent;
	    }
    	.css-1hwfws3 {
    		height: 69px;
    	}
    	 .css-xp4uvy {
	    	width: 100%;
	    	text-align: center;
	    }
	    .css-1wy0on6 {
	    	span {
	    		display: none;
	    	}
	    }

	}

	.css-15k3avv {
		@include margin-direction(top,5px);
		max-height: 185px;
		overflow-y: auto;
		box-shadow: 0 2px 11px #363a45;
		background-color: #363a45;
		>div {
			margin-left: -17px;
			>div{
				border-bottom: 1px solid #66696f;
				width: 100%;
				text-align: center;
			}
		}
	}

	.css-1ep9fjw {
    	color: #fff;
	}
	.css-19bqh2r {
		fill: #fff;
		stroke: #fff;
	}
}

.innercontent_wrapper .box {

	.searchText {
		input {
			@include padding-direction(right, 27px);
			background-repeat: no-repeat;
			background-position: 97%;
			background-image: url("assets/images/search.png");
		}
	}
}

label.checkbox_custom {
	cursor: pointer;
	height: 16px;
    border-radius: 4px;
    border: 1px solid #3ba8c5;
    box-shadow: none;
    width: 16px;
	background-color: transparent;
	padding:0;
	position: relative;
	&.btn-primary:not(:disabled):not(.disabled):active, 
	&.btn-primary:not(:disabled):not(.disabled).active, .show > &.btn-primary.dropdown-toggle, &.btn-primary:hover {
		background-color: #3ba8c5;
		border-color: #3ba8c5;
	}
	&.active{
		background-color: #3ba8c5;
		img{
			position: absolute;
			left:3px;
			top:4px;
		}
		
	}
	input {
		
	    opacity: 0;
	}
    
}

.modal-dialog {
	.modal-content {
		@include margin(0 auto);
		box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
		border-radius: 20px;
		width: fit-content;
		@include padding(4px 0 40px);
		.modal-header {
			border-bottom: 4px solid #3ba8c5;
			padding-left: 30px;
			padding-right: 15px;
			.modal-title {
				color: #000000;
				font-family: "Helvetica Neue Cyr Roman";
				font-size: 18px;
				font-weight: 400;
				text-transform: capitalize;
			}
			.close {
				padding: 0 15px;
				color: #3ba8c5;
				opacity: 1;
			}
		}
		.modal-body {
			padding-left: 30px;
			padding-right: 30px;
			p{
				color: #000000;
				font-family: "Segoe UI Semi Bold";
				font-size: 14px;
				font-weight: 400;
			}
		}
		.modal-footer {
			border-top: 0;
			padding: 0 1rem;

			button {
				@include padding(0 30px);
				line-height: 24px;
				height: 38px;
				border-radius: 3px;
				font-family: "Segoe UI Bold";
				font-size: 13px;
				font-weight: 700;
				text-transform: uppercase;
				vertical-align: middle;

				&.btn_cancel {
					border: 1px solid #3ba8c5;
					background-color: #ffffff;
					color: #3ba8c5;
				}
				&.btn_save {
					border: none;
					background-color: #3ba8c5;
				}
			}
		}
	}
}

.wrapper {
	.rcs-custom-scroll {
		max-height: inherit;
		.rcs-outer-container {
			max-height: inherit;
			.rcs-inner-container {
				max-height: inherit;
			}
		}
	}
	.rcs-inner-handle {
		border-radius: 4px;
		background-color: #3ba8c5;
		width: 7px;
		border: 1px solid #3ba8c5;
	}
	.rcs-custom-scroll .rcs-inner-container {
		overflow-y : auto;
		>div {
			padding: 0 15px;
		}
	}
}

.scroll_row {
	overflow-x: auto;
	box-shadow: 0 0 5px rgba(13, 16, 21, 0.19);
	border-radius: 10px;
	//@include padding-direction(bottom, 10px);

	.panelWrapper {
		min-width: max-content;
	}
}
.wrapper .operators_Wrapper .rcs-custom-scroll .rcs-inner-container > div {
	@include padding(0)
}
.operators_Wrapper {
	@include padding(27px 0);
	width: 100%;
	height: calc(100vh - 61px);
	h6 {
		@include padding(0 17px);
		color: #f7f5f5;
		font-family: "Segoe UI Bold";
		font-size: 14px;
		font-weight: 700;
		text-transform: uppercase;
		@include margin-direction(bottom, 18px);
		text-align: left;
		line-height: 20px;
		span {
			color: #67b84b;
		}
	}
	.rcs-custom-scroll {
		padding-top: 15px;
	}
	.operator_details {
		@include padding(15px);
		cursor: pointer;
		&.quedOperator {
			position: relative;
			p{
				margin-top: 28px;
			}
			.admin{
				height: 44px;
			}
		}
		&.active {
			background-color: #363a45;
		}
		p {
			@include margin(13px 0 0 0);
			color: #ffffff;
			font-family: "Segoe UI Semi Bold";
			font-size: 14px;
			font-weight: 400;
		}
		.role {
			img{
				border-radius: 50%;
			}
			&.operator img {
				border: 2px solid #3ba8c5;
			}
			&.admin img {
				border: 2px solid #51af31;

				&.quedImg {
					zoom: 1.6;
				    position: absolute;
				    top: 0;
				    left: 15px;
				}
			}
		}
	}
	.optionwrapper {
		position: absolute;
	}
	.operatorsOptions {
		width: 176px;
		height: fit-content;
		border-radius: 0 10px 10px 0;
		background-color: #363a45;
		position: absolute;
		@include padding(24px 45px 30px 20px);
		left: 124px;
		z-index: 9999;
		text-align: left;
		top: 0;
		p {
			color: #6b838d;
			font-family: "Segoe UI";
			font-size: 13px;
			font-weight: 400;
			line-height: 25px;
			@include margin(0);
			img {
				@include margin-direction(right, 11px);
			}
			&.active {
				color: #a8d3de;
			}
		}
	}
	&.currentChats {
		height: fit-content;
		max-height: 250px;
		border-bottom: 1px solid #66696f;
		h6 {
			@include margin-direction(bottom, 26px);
		}
		.currentChatsWrapper {
			max-height: 150px;
			overflow-y: auto;
		}
		.operator_details {
			@include padding(3px 11px 5px 17px);
			height: 30px;
			@include margin-direction(top, 0);
			&:hover {
				background-color: #66696f;
			}
			&.active {
				background-color: #0b91b5;
			}
		}
		p{
			text-align: left;
			@include margin-direction(top, 0);
			span {
				    width: 7px;
				    height: 7px;
				    background-color: #c94040;
				    vertical-align: middle;
				    margin-top: 8px;
				    border-radius: 50%;
			}
			
		}
	}
}
#sidebar.operator_menu {
	min-width: 229px;
	max-width: 229px;
	transition: all 0.3s;

	.submenu_wrapper {
		min-width: 124px;
		max-width: 124px;
	}
	.hideIcon {
		width: 124px;
		display: none;
	}

	.subMenu {
		li {
			&.parent_list{
				@include padding(21px 12px 20px 17px);

				> a > p {
					@include margin(0);
				}
				&.active {
					background-color: #0b91b5;
				}
				.operators_Wrapper {
					height: 100%;
				}
			} 
		}
	}
}

.opacity-none {
	opacity: 0!important;
}
.custom_radioBtn {
	position: relative;
	 p{
		    color: #000000!important;
		    font-family: "Segoe UI"!important;
    font-size: 11px!important;
    font-weight: 700!important;
	}
	.active p {
		color: #fff!important;
	}
	&.disabled {
		.btn {
			cursor: default;
		}
	}
}
.custom_removeBtn{
	margin-left: 50%;
}
.ruleComponent.chat {
	.custom_radioBtn {
	 p{ 
	 	top: 3px;
	 }
	 }
	 .fieldSection {
	
	p {
	    color: #242831;
	    font-family: "Segoe UI Semi Bold";
	    font-size: 14px;
	    font-weight: 400;
	}
} 
}

.DraftEditor-editorContainer {
	height: 95%;
}
span.online_text {
	color: #67b84b;
}
span.mandatory {
    color: #e44e4e;
    margin-left: 3px;
}

.settingFormWrapper .form-label {
	&.field-error{
		color: #e44e4e;
	}
}

.websitesWrapper {
	padding: 30px 15px 30px 30px;
	.websitesDetails {
		margin-bottom: 19px;
		p {
			padding: 0 13px;
		}
		.box {
			padding: 0;
			float: left;
			.addWrapper {
				@include padding(97px 0 99px);
				cursor: pointer;
				img {
					border: 2px solid #3ba8c5;
					border-radius: 50%;
					padding: 21px;
				}
				p{
					color: #3ba8c5;
					font-family: "Segoe UI Bold";
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					@include margin(13px 0 0 0);
				}
			}
			h4 {
				color: #000000;
				font-family: "Helvetica Neue Cyr Roman";
				font-size: 24px;
				font-weight: 400;
				width:100%;
				float: left;
				padding: 0 13px;
				margin-bottom: 0;
			}
			.webLink {
				color: #3ba8c5;
				font-family: "Segoe UI";
				font-size: 15px;
				font-weight: 400;
				width:100%;
				float: left;
			}
			.actionsWrapper {
				width: 100%;
				float: left;
				background-color: #3ba8c5;
				padding: 9px 13px 10px;
				margin-top: 10px;
				p{
					margin: 0;
					padding: 4px 0 3px;
					color: #ffffff;
					font-family: "Helvetica Neue Cyr";
					font-size: 14px;
					font-weight: 500;
					text-transform: uppercase;

					span {
						cursor: pointer;
					}
					&:first-child {
						border-right: 1px solid rgba(#fff, 0.3);
						text-align: right;
						padding-right: 22px;
					}
					&:last-child {
						text-align: left;
						padding-left: 22px;

						img {
							margin-right: 8px;
						}
					}

					img {
						margin-right: 6px;
						margin-top: -3px;
					}

				}
			}
			.overview {
				width: 100%;
				float: left;
				
				text-transform: uppercase;
				margin-bottom: 14px;
				.overviewHeader {
					color: #000000;
					font-size: 18px;
					font-weight: 300;
					margin-top: 20px;
					font-family: "Helvetica Neue Cyr";
					
				}
				.row p{
					font-size: 30px;
					border-right: 1px solid #DFDFDF;
					padding-top: 11px;
					padding-bottom: 12px;
					font-family: "Helvetica Neue Cyr";
					font-weight: 700;
					span {
						color: #363a45;
						font-size: 9px;
					}
					&:last-child {
						border-right: 0;
					}

				}
			}
			
		}
		.activeMode {
			height: 17px;
			margin: 0;
			margin-top: 12px;
			width:100%;
			float: left;
			span{
				width: 17px;
				height: 17px;
				float: right;
				border-radius: 50%;
				&.installed_icon{
					background-color: #51af31;
				}
				&.uninstalled_icon {
					background-color: #c94040;
				}
			}
		}
	}
} 


  
  @media print {
	  
	  p p{
		margin: 0px 15px;
	  }
	div div div  p {
		margin-left: 30px; 
		padding: 0px 15px;
	}
	p>div{
		margin-left: 30px; 
		padding: 0px 15px;
	}
	.infoTranscript .time {
		width: 10%;
	}
	.infoTranscript .name {
		width: 22%;
	}
	.infoTranscript .msg {
		width: 65%;
	}
	.infoTranscript .row .chat_p {
		font-family: "Segoe UI" !important;
		font-weight: 400;
	} 
	.row {
		display: flex;
		flex-wrap: wrap;
		margin-right: -15px;
		margin-left: -15px;
	}
 
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
		 float: left;
	}
	.col-12 {
		 width: 100%;
	}
	.col-11 {
		 width: 91.66666667%;
	}
	.col-10 {
		 width: 83.33333333%;
	}
	.col-9 {
		 width: 75%;
	}
	.col-8 {
		 width: 66.66666667%;
	}
	.col-7 {
		 width: 58.33333333%;
	}
	.col-6 {
		 width: 50%;
	}
	.col-5 {
		 width: 41.66666667%;
	}
	.col-4 {
		 width: 33.33333333%;
	}
	.col-3 {
		 width: 25%;
	}
	.col-2 {
		 width: 16.66666667%;
	}
	.col-1 {
		 width: 8.33333333%;
	}

	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
		position: relative;
		width: 100%;
		padding-right: 15px;
		padding-left: 15px;
	}
 }

