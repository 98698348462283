@import '_mixins';

.innercontent_wrapper {
	.profile_wrapper {

		@include margin-direction(bottom, 18px);
		&:last-child{
			margin-bottom: 0;
		}

		.box {
			@include padding(40px 30px);
			&.settingFormWrapper {
				.logOff {
					background-color: #e9ecef;
					color: #9d9696;
				}
				.loopCount {
					    width: 65px;
					    height: 37px;
				}
			} 
		}
		h4 {
			@include margin-direction(bottom,0);
		}
		form {
			@include margin-direction(top,27px);
		}
		.operator_options {
			@include margin-direction(top,44px);

			.headerTitle {
				@include margin-direction(bottom, 28px);
			}
			button {
				@include margin-direction(top, 15px);
			}
			.invitation_msg {

				textarea {
				   	@include margin-direction(top, 15px);
				   	width: 100%;
				   	height: 84px;
				   	border-radius: 0.25rem;
				   	border: 1px solid #ced4da;
				   	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
				   	color: #000000;
				    font-family: 'Segoe UI Semi Bold';
				    font-size: 14px;
				    font-weight: 400;
				    padding: 5px 8px;
				}

				.btn-group-toggle {
				    display: inline-block;

				    .custom_radionBtn {
				    	@include margin-direction(top, 13px);
				    }
				}
			}
			
		}
		.dept_section {
			@include margin-direction(top, 50px);

			h5 {
				color: #000000;
				font-family: 'Helvetica Neue Cyr Roman';
				font-size: 22px;
				font-weight: 400;
				line-height: 36px;
				@include margin-direction(bottom, 0);
			}
			
			>div {
				@include margin-direction(top,12px);
				h6 {
					color: #293037;
					font-family: 'Segoe UI Bold';
					font-size: 13px;
					font-weight: 700;
					line-height: 30.4px;
					@include margin(0);
				}
				ul{
					list-style: none;
					@include padding-direction(left, 20px);
					@include margin-direction(top, 3px);
					@include margin-direction(bottom, 0);

				}
				li {
					color: #000000;
					font-family: 'Segoe UI Semi Bold';
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
				}
			}
		}

		.customDrpDwn {
			>div,>img,label,input {
				float: left;
			}
			label {
				width: auto;
			}
			>img {
				@include margin(7px 10px 6px 15px);
			}
		}

		.chat_settings {
			>p {
				@include margin(9px 0 7px 11px);
				color: #000000;
				font-family: 'Segoe UI Semi Bold';
				font-size: 14px;
				font-weight: 400;
			}
		}

		.form_control_repeat {
			>input{
				@include margin(0 9px 0 15px);
				float: left;
			}
			button {
				@include margin-direction(top,4px);
			}
		}

		.available_zone {
			.day_wrapper {
				@include margin-direction(bottom, 13px);
				p.day_label{
					color: #000000;
					font-family: 'Segoe UI Semi Bold';
					font-size: 14px;
					font-weight: 400;
					@include margin(8px 4px 8px 0);
					text-align: left;
					width: 73px;
					@include padding-direction(left,0);
				}
				input {
					width: 61px;
					height: 37px;
					@include margin-direction(left,10px);
				}
			}
		}
		.performance-wrapper {
			float: right;
			width: 100%;
			max-width: 424px;
			.performance-content {
				margin-top: 27px;
				width: 100%;
				max-width: 424px;
				height: 127px;
				box-shadow: 0 0 5px rgba(13, 16, 21, 0.19);
				border-radius: 10px;
				background-color: #ffffff;
				margin-left: 0;
				@include padding(30px 0);

				p{
					@include margin(0);
					&.count {
						color: #3ba8c5;
						font-family: 'Helvetica Neue Cyr Medium';
						font-size: 30px;
						font-weight: 700;

						img{
							margin-left: 12px;
		    				margin-top: -6px;
						}
					}
					&.type {
						color: #293037;
						font-family: 'Segoe UI Bold';
						font-size: 13px;
						font-weight: 700;
					}
				}
				.chats {
					border-right: 1px solid #d0d0d0;
				}
			}
		}

	}
}

@media (max-width: 375px){
	.sound-grp {
		.customDrpDwn .css-10nd86i {
			width: 208px;
		}
	}

	.innercontent_wrapper {
		.profile_wrapper {
			.chat_settings > p {
				font-size: 12px;
				text-align: left;
				width: 70%;
				@include margin-direction(top,0);
			}

			.box {
				@include padding(20px 15px);
			}

			.performance-wrapper .performance-content p.count {
				font-size: 26px!important;
			}


			.form_control_repeat {
				>input {
		    		margin: 10px 10px 10px 0;
		    	}
		    	button {
				    margin-top: 0;
				}
		    }
		}
	}
}

@media (max-width: 768px){
	.innercontent_wrapper {
		.profile_wrapper {
			.form_control_repeat {
				>input {
		    		margin: 10px 10px 10px 0;
		    	}
		    	button {
				    margin-top: 14px;
				    padding: 0px 8px;
				}
		    }	

			.performance-wrapper {
				float: left;
				margin-top: 50px;
				.performance-content {
					margin-top: 27px;
					width: 100%;
					max-width: 424px;
					height: 127px;
					box-shadow: 0 0 5px rgba(13, 16, 21, 0.19);
					border-radius: 10px;
					background-color: #ffffff;
					@include padding(30px 0);
					margin-left: 0;

					p{
						@include margin(0);
						&.count {
							color: #3ba8c5;
							font-family: 'Helvetica Neue Cyr Medium';
							font-size: 30px;
							font-weight: 700;

							img{
								margin-left: 12px;
			    				margin-top: -6px;
							}
						}
						&.type {
							color: #293037;
							font-family: 'Segoe UI Bold';
							font-size: 13px;
							font-weight: 700;
						}
					}
					.chats {
						border-right: 1px solid #d0d0d0;
					}
				}
			}
		}
	}
}