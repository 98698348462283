@import '_mixins';

.overview{
	h5{
		color: #293037;
		font-family: 'Segoe UI Bold';
		font-size: 13px;
		font-weight: 700;
		line-height: 30.4px;
	}
	ol {
		list-style: none;
		counter-reset: li;
    	padding-inline-start: 22px;
    	@include margin-direction(top, 22px);
		li{
			counter-increment: li;
			@include margin-direction(bottom, 10px);
			&::before {
				content: counter(li); 
			    display: inline-block; 
			    width: 24px;
				height: 24px;
				border-radius: 50%;
				border: 1px solid #3ba8c5;
			    color: #3ba8c5;
				font-family: 'Segoe UI Bold';
				font-size: 13px;
				float: left;
				@include padding-direction(left, 7px);
			}
			p{
				@include margin-direction(left, 34px);
				@include margin-direction(bottom, 0);

				a:not([href]):not([tabindex]){
					color: #3ba8c5;
				}
			}
		}
	}
	ul{
		list-style-image: url("assets/images/list_icon.png");
		li{
			@include padding-direction(left, 8px);
		}
	}
	p,li{
		color: #000000;
		font-family: 'Segoe UI';
		font-size: 14px;
		line-height: 20px;
	}
	.section {
		@include margin-direction(bottom, 35px);

		&:last-child {
			@include margin-direction(bottom, 0);			
		}
	}
}

.scriptWrapper{
	.panelWrapper {
		.scrollWrapper {
			//height: auto;
			//overflow-y: initial;
			.scrollArea {
				max-height: 43vh;
				margin-top: -63px;
				z-index: 1;
				.rcs-custom-scroll .rcs-inner-container {
					margin-top: 63px;
				}
	    		//overflow-y: auto;
			}
		}
	}
	&.innercontent_wrapper .box {
		@include padding(26px);

		&.editScriptSection {
			@include padding(0);
			.headerTitle {
				@include padding(15px 26px 8px);
				@include border-direction(bottom, 4px, solid, #3ba8c5);
				h4 {
					color: #000000;
					font-family: 'Helvetica Neue Cyr Roman';
					font-size: 22px;
					@include margin(0);
				}

			}
			.contentWrapper {
				@include padding(34px 26px);
				.customDrpDwn .css-10nd86i {
					width: 100%;
				}
				.form-control {
					width: 100%;
				}
			}
			.formBtns {
			    padding-top: 15px;
			    margin-top: 5px;
			    }
		}
	}
}
//checking virtual agent

.vascriptWrapper{
	.panelWrapper {
		.scrollWrapper {
			//height: auto;
			//overflow-y: initial;
			.scrollArea {
				max-height: 43vh;
				//margin-top: -63px;
				z-index: 1;
				.rcs-custom-scroll .rcs-inner-container {
					// margin-top: 63px;
					margin-top: 0px;
				}
	    		//overflow-y: auto;
			}
		}
	}
	&.innercontent_wrapper .box {
		@include padding(26px);

		&.editScriptSection {
			@include padding(0);
			.headerTitle {
				@include padding(15px 26px 8px);
				@include border-direction(bottom, 4px, solid, #3ba8c5);
				h4 {
					color: #000000;
					font-family: 'Helvetica Neue Cyr Roman';
					font-size: 22px;
					@include margin(0);
				}

			}
			.contentWrapper {
				@include padding(34px 26px);
				.customDrpDwn .css-10nd86i {
					width: 100%;
				}
				.form-control {
					width: 100%;
				}
			}
			.formBtns {
			    padding-top: 15px;
			    margin-top: 5px;
			    }
		}
	}
}

.settingFormWrapper.editScriptSection{
	@include margin-direction(top, 26px);
	.custom_radioBtn {
		float: left;
		>p{
			float: right;
			@include margin(8px 26px 0 13px);
			color: #000000;
			font-family: 'Segoe UI Semi Bold';
			font-size: 14px;
		}
	}


}

.preview {
	@include padding(0 3px 0 32px);
	.chatPreview {
		max-width: 306px;
		height: 370px;
		box-shadow: 0 0 38px 2px rgba(0, 0, 0, 0.04);
		background-color: #ffffff;
		@include border-radius(5px);

		.headerTitle {
			background-color: #e2e2e2;
			@include padding(9px 20px);
			@include border-radius(5px 5px 0 0);

			&.active {
				background-color: #3ba8c5;	
			}
			h6 {
				color: #ffffff;
				font-family: 'Segoe UI Bold';
				font-size: 13px;
				font-weight: 700;
				text-align: left;
				@include margin-direction(bottom, 0);
			}
		}
		.chatBox {
			@include padding(0 0 20px 20px);
			height: 305px;

			.msgArea {
				height: 76%;
    			//overflow-y: auto;
    			//@include padding(20px 0 0 0);
    			.rcs-custom-scroll .rcs-inner-container > div {
    				padding-top : 20px!important;
    			}
    			p {
    				@include margin(0 0 7px 0);
    			}
    			p,a {
    				color: #242831;
					font-family: 'Segoe UI';
					font-size: 13px;
					font-weight: 400;

					&.reply {
						color: #3ba8c5;
					}
    			}
			}
			.chatArea {
				.form-group{
					@include margin(0);
				}
				input {
					@include margin(10px 0);
					height: 37px;
					border-radius: 5px;
					border: 1px solid #dedede;
					background-color: #ffffff;
					@include padding(12px);
					font-family: 'Segoe UI';
					font-size: 14px;
					font-weight: 400;
					width: 98%;
				}
			}
			.chatareasend{
				pointer-events: none;
				opacity: 0.5;
			}
			.formBtns {
				@include margin(0);
				@include padding(0);
				border: none;

				.btn_send {
					margin-left: 0;
				}
			}
		}
		.btn_send {
			min-width: 89px;
			width: max-content;
			height: 38px;
			border-radius: 3px;
			border-color: #3ba8c5;
			background-color: #3ba8c5;
			color: #ffffff;
			font-family: 'Segoe UI Bold';
			font-size: 13px;
			font-weight: 700;
			&:hover {
				background-color: #ffffff;
				color: #3ba8c5;
			}
		}
	}
}
.panelWrapper {
	box-shadow: 0 0 5px rgba(13, 16, 21, 0.19);
	@include border-radius(10px);
	background-color: #ffffff;
	max-height: 95vh;
	height: fit-content;
	overflow: hidden;
	@include padding-direction(bottom, 15px);

	.scrollWrapper {
		height: calc(100% - 105px);
		max-height: calc(95vh - 105px);
	}

	.addBtn{
		@include margin(10px 0 15px 15px);
	}

	.panelHeader {
		background-color: #3ba8c5;
		@include border-radius(10px 10px 0 0);
		@include padding-direction(right,15px);

		p{
			color: #ffffff;
			font-family: 'Helvetica Neue Cyr Roman';
			font-size: 14px;
		}
		
	}
	.scriptPanel {
		>div.panelHeading{
			color: #3ba8c5;
			font-family: 'Segoe UI Bold';
			font-size: 14px;
			@include padding(18px);
			z-index: 2;
			p {
				float: left;
				margin: 0;
				color: #3ba8c5;
				font-family: 'Segoe UI Bold';
				font-size: 14px;
				line-height: 27px;
			}
			&.dropdown-toggle::after {
				display: none;
			}
			p:first-child {
				width: 27px;
				height: 27px;
				@include border-radius(50%);
				@include border(1px,solid,#3ba8c5);
				background-color: #3ba8c5;
				background-image: url("assets/images/expand.png");
				background-repeat: no-repeat;
				background-position: center;
				padding: 0;
			}
			&.collapsed {
				p{
					color: #293037;
				}
				p:first-child  {
					@include border(1px,solid,#dedede);
					background-color: #ffffff;
					//background-image: url("assets/images/collapsed.png");
				}
			}
		}
		.action {
			@include padding(15px 15px 15px 0);
			&:nth-child(odd){
				background-color: rgba(59,168,197,0.1);
			}
			&:nth-child(even), &.even{
				background-color: #ffffff;
			}
			.count {
				color: #000000;
				font-family: 'Segoe UI';
				font-size: 14px;
				p {
					margin: 0;
				}
				&.editing, &.disabled {
					opacity: 0.2;
				}
			}

			.data, &.data {
				p{
					color: #242831;
					font-size: 14px;
					margin: 0;
				}
				.quest {
					font-family: 'Segoe UI Semi Bold';
				}
				.expAns {
					font-family: 'Segoe UI';
				}
				.editing, .disabled {
					opacity: 0.2;
				}

				.optionArea{
					.editWrapper {
						float: right;
						>div {
							float: left;
							margin-right: 20px;
							p{
								color: #3ba8c5;
								font-family: 'Helvetica Neue Cyr Medium';
								font-size: 14px;
								letter-spacing: 0.28px;
								img {
									margin-right: 9px;
								}
							}
							&.custom_radioBtn {
								label {
									margin-bottom: 0;
								}
								p {
									&.btnText {
										margin: 15px 0;
										font-family: 'Segoe UI Semi Bold';
										font-size: 11px;
										&.active {
											color: #ffffff;
										}
									}
								}
								
							}
						}
					}
				}

			}
		}
	}
}

.ruleComponent {
		@include padding(13px 27px);
		pointer-events: visible!important;
		cursor: pointer;
		&:active {
		    @include grabbing-cursor;

		}
		&.placeholder {
				opacity: 0;
		}
		&.dragged {
			margin-left: 0px;
			opacity: 0.7;
		}
		&.even{
			background-color: #EBF6F9;
		}
		&.odd{
			background-color: #ffffff;
		}
		>div{
			float: left;
		}
		.rule_data {
			width: 95%;
			@include padding-direction(left, 30px);
			p{
				color: #000000;
				font-family: 'Segoe UI';
				font-size: 14px;
				font-weight: 400;
				@include margin(0);
			}
			ul {
				@include margin-direction(bottom,0);
			}
		}

		.optionArea {
			.editWrapper {
				float:right;
				>div {
					float: left;
				}
				>div:first-child {
					@include margin-direction(right,20px);

				}
				p{
					color: #3ba8c5;
					font-family: 'Helvetica Neue Cyr';
					font-size: 14px;
					font-weight: 500;
					text-transform: uppercase;
					img {
						@include margin-direction(right, 10px);
						@include margin-direction(top, -3px);
					}
				}
			}
		}

	}
.invitationRulesWrapper {
	p.desc{
		@include margin-direction(top, 0px);
		color: #000000;
		font-family: 'Segoe UI';
		font-size: 14px;
		font-weight: 400;
		text-align: justify;
	}

	.panelWrapper {
		height: fit-content;
		.scrollWrapper {
    		height: calc(100% - 55px);
    		max-height: calc(95vh - 55px);
		}
	}



	.editRulesSection {
		.customFormGroup {
		.customDrpDwn .css-10nd86i {
			width: 241px;
		}
			&.drpDwnGrp {
				.customDrpDwn:first-child{
					margin-right: 15px;
				}
			}
			>div,>span{
				float: left;
			}
			>div:nth-child(3){
				width: 78px;
			}
			>span {
				color: #000000;
				font-family: 'Segoe UI';
				font-size: 14px;
				@include margin-direction(top, 6px);
				@include margin-direction(left, 15px);
				&:nth-child(2) {
					@include margin-direction(right, 15px);
				}
			}
			.customInput {
				width: 57px;
				height: 37px;
				float: left;
			}
		}
		.conditions ,.invitation_msg{
			.btn-group-toggle {
				float: left;
				margin-top:3px;
			}
		}
		.conditionsWrapper {
			max-height: 150px;
			overflow-y: auto;
			width: 100%;
			height: fit-content;
			box-shadow: 0 0 5px rgba(13, 16, 21, 0.19);
			border-radius: 10px;
			background-color: #ffffff;
			@include margin(19px 0 9px 0);
			float: left;
			&.conditionComponent {
				@include padding(15px);
				max-height: max-content;
				.btn_save {
					@include margin-direction(right,10px);
				}
				p.conditonPreview{
					@include margin-direction(left,15px);
				}
				.form-control,.customDrpDwn .css-10nd86i {
					max-width: 256px;
					width: 100%;
				}
			}
			.conditionsList {
				
				padding: 0;
				p{
					color: #000000;
					font-family: 'Segoe UI';
					font-size: 14px;
					font-weight: 400;
				}
			}
			.customDrpDwn {
				.css-15k3avv {
					.css-11unzgr {
						max-height: 160px;
					}
				}
			}
		}
		.invitation_msg {
			.btn-group-toggle {
				display: inline-block;
				>label {
					@include margin-direction(bottom,12px);
					text-align: left;

					&:last-child {
						@include margin-direction(bottom,0);
					}
				}
			}
		}
		.relatedDropDown{
			@include margin-direction(top,12px);
			color: #000000;
			font-family: 'Segoe UI';
			font-size: 14px;
			font-weight: 400;
		}
		textarea {
			height: 90px;
			border-radius: 5px;
			border: 1px solid #dedede;
			background-color: #ffffff;
			@include padding(13px);
			color: #000000;
			font-family: 'Segoe UI';
			font-size: 14px;
			font-weight: 400;
			@include margin-direction(top,12px);
		}
	}

	.rules_wrapper {
		@include margin-direction(top,55px);

		h4 {
			color: #000000;
			font-family: 'Helvetica Neue Cyr Roman';
			font-size: 22px;
			font-weight: 400;
			margin-bottom: 20px;
		}
		.box {
			@include padding(27px);
		}
	}
}
.innercontent_wrapper .securityWrapper {
	@include margin-direction(bottom, 18px);

	&:last-child {
		margin-bottom: 0;
	}
	 .box {
		
	    .custom_textArea {
	    	span {
	    		color: #adadad;
				font-family: 'Segoe UI Semi Bold';
				font-size: 14px;
				font-weight: 400;
	    	}
	    	textarea {
	    		width: 100%;
	    		//height: 180px;
				border-radius: 5px;
				border: 1px solid #dedede;
				background-color: #ffffff;
				@include padding(15px);
				color: #000000;
				font-family: 'Segoe UI Semi Bold';
				font-size: 14px;
				font-weight: 400;
	    	}
	    }

	    .country_col {
	    	@include margin-direction(top, 10px);
	    }

	    .country_text {
	    	text-align: left;
	    	@include margin-direction(top,0);

	    	img {
	    		@include margin-direction(top, -4px);
	    	}

	    	span {
	    		@include margin-direction(left, 10px);
	    		color: #000000;
				font-family: 'Segoe UI Semi Bold';
				font-size: 14px;
				font-weight: 400;
	    	}
	    }

		.contentWrapper {

		    .custom_input_text {
		    	width: 100%;
		    }
		}
	}
	.custom_input_companytext{
		width: 100%;
	}
}
.languageWrapper {
	&.innercontent_wrapper .box {
	    padding: 26px;
	}
	.panelWrapper {
		max-height: 77vh;
		.panelHeader{
			@include padding(0 15px);
		}
		.scriptPanel {
			.action {
				@include padding(11px 15px 15px);
			}
		}
	}
	.scrollWrapper {
		height: calc(100% - 57px);
		max-height: calc(77vh - 57px);
		.panelContent {
			p {
				color: #000000;
				font-family: 'Segoe UI';
				font-size: 14px;
				font-weight: 400;
				@include margin(0);
				overflow-wrap: break-word;

				&.base {
					color: #242831;
				}
				
			}
		}
	}
}

.fileTransferWrapper {
	.headerTitle {
		@include margin(40px 0 13px 0);
		h4 {
			color: #000000;
			font-family: 'Helvetica Neue Cyr Roman';
			font-size: 22px;
			font-weight: 400;
		}
	}
	.panelWrapper {
		min-width : 650px;
		box-shadow: none;
	}
	.settingFormWrapper form {
		.customField {
			p {
				max-width: 258px;
				color: #000000;
				font-family: 'Segoe UI Semi Bold';
				font-size: 14px;
				font-weight: 400;
				@include margin(9px 0 8px 0);
				img {
					@include margin(-4px 0 0 10px);
				}
			}
			.progress_wrapper {
				max-width: 258px;
				background-color: #e7e7e7;
				border-radius: 2px;

				.progress {
					background-color: #3ba8c5;
					border-radius: 2px;
					height: 4px;
				}
			}
		}
	}
	.scriptPanel {
		.action {
			&.editing {
				opacity: 0.2;
			}
			.img-wrapper {
				@include padding(8px 7px 9px 8px);
				border-radius: 50%;
				&.download {
					border: 1px solid #7fae72;
				}
				&.upload {
					border: 1px solid #e44e4e;
				}
			}
			
			
		}
		p {
			@include margin(0);
			color: #000000;
			font-family: 'Segoe UI';
			font-size: 14px;
			font-weight: 400;
			&.action_text {
				cursor:pointer;
				color: #3ba8c5;
				font-family: 'Helvetica Neue Cyr Medium';
				font-size: 14px;
				font-weight: 500;

				img {
					@include margin-direction(right, 12px);
				}
			}
				
		}
	}
	.btn_del,.btn_clr {
		width: auto;
		@include padding(8px 29px);
	}

	.headerCheck {
		label.checkbox_custom {
			border-color: #fff;

			&.btn-primary:not(:disabled):not(.disabled):active, 
			&.btn-primary:not(:disabled):not(.disabled).active, .show > &.btn-primary.dropdown-toggle, &.btn-primary:hover  {
				border-color: #fff;
			}
		}
	}
}

.chatInvWrapper {
	@include margin-direction(bottom,30px);
	&:last-child{
		margin-bottom: 0;
	}

	.section.box {
		@include padding(30px);
		.form-control.customInput {
			width: 57px;
			height: 37px;
			border-radius: 5px;
			border: 1px solid #dedede;
			background-color: transparent;
			@include margin(5px 8px 0);
			float: left;
		}
		.custom_label {
			span{
				float: left;
				@include margin-direction(top, 12px);
			}
		}

	}
}
.innercontent_wrapper {
	.optDeptWrapper {
		&.with-header {
			.box {
				.contentWrapper {
					    padding: 26px 26px 5px;
				}
			}
			.operatorsWrapper {
				.box {
					.contentWrapper {
						padding: 14px 15px 5px;
					}
				}
			}
		}
	}
}

.optDeptWrapper {
	.customDrpDwn .css-10nd86i {
		width: 100%;
	}
	&.with-header {
		@include margin-direction(bottom, 30px);

	}
	.box {
		.headerTitle {
			h4 {
				font-family: "Helvetica Neue Cyr Roman";
				font-size: 22px;
				font-weight: 400;
			}
		}
	}	
	.chatRedirect {
		text-align: left;

		label {
			text-align: left;
		}
	}
	.operatorSection {
		background-color: #f9f9f9;
		border-bottom-right-radius: 20px;
		border-bottom-left-radius: 20px;
		.operatorsWrapper {
			display: inline-block;
			margin-bottom: 7px;
			margin-top: 25px;
			.settingFormWrapper .form-control {
				width: 100%;
			}
			.box {
				min-width: 280px;
				float: left;
				width: 30.4%;
				@include padding(0 0 19px 0);
				@include margin(0 10px 20px 10px);
				height: 691px;
				position: relative;

				.btnRow {
					position: absolute;
				    width: 100%;
				    bottom: 19px;
				}
				.col-form-label {
					margin-left: 14px;
				}
				.formBtns {
					@include padding-direction(left,0);
					margin-top: 0;
					padding-top: 20px;
					.btn_clr {
						@include margin-direction(left,0);
					}
				}
			}
			.profileImgWrapper {
				border-bottom: 1px solid #dfdfdf;
				height: 168px;
				position: relative;
				overflow: hidden;
				img {
					&.data_image, &.editImage {
						transform: rotate(-30deg);
					    -webkit-transform: rotate(-30deg);
					    -moz-transform: rotate(-30deg);
					    -ms-transform: rotate(-30deg);
					    -o-transform: rotate(-30deg);

						    margin-top: 23px;
						    height: 187px;
					}
				}
				.img_delete {
					position: absolute;
				    top: 4px;
				    right: 15px;
				    cursor: pointer;
				}
				input {
					position: absolute;
				    top: 0;
				    left: 0;
				    height: 168px;
				    opacity: 0;
				}
				&.noImage {
					position: relative;
					p {
						font-family: "Segoe UI Bold";
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;
						margin: 0;
					    position: absolute;
					    top: 60%;
					    left: 50%;
					    transform: translate(-50%, -50%);
					}
					.defaultImage {
						    width: 120px;
						    height: 120px;
						    border: 2px solid #ceebf3;
						    background-color: #ebf6f9;
						    margin: 24px auto;
						    border-radius: 50%;
						    padding: 10px;

						img{
							border: none;
						}
					}
				}
				&.addImage {
					@include padding(35px 104px 30px);
					img {
						border: none;
						border-radius: 50%;
						padding: 21px;
					}
					p{
						color: #3ba8c5;
						font-family: "Segoe UI Bold";
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;
						@include margin(13px 0 0 0);
					}
				}

			}
			.addWrapper {
				@include padding(294px 104px);
				cursor: pointer;
				img {
					border: 2px solid #3ba8c5;
					border-radius: 50%;
					padding: 21px;
				}
				p{
					color: #3ba8c5;
					font-family: "Segoe UI Bold";
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					@include margin(13px 0 0 0);
				}
			}
			.operatorNick {
				border-bottom: 1px solid #dfdfdf;
				padding: 20px 20px 17px;
				p {
					color: #3ba8c5;
					font-family: "Helvetica Neue Cyr Roman";
					font-size: 22px;
					font-weight: 400;
					@include margin(0);
					text-align: left;

					span {
						float: right;
						width: 17px;
						height: 17px;
						border-radius: 50%;
						margin-left: 20px;
    					margin-top: 8px;
						&.online {
							background-color: #51af31;
						}
						&.offline {
							background-color: #c94040;
						}
					}
				}
			}
			.operatorDetails {
				@include padding(17px 20px);
				p {
					color: #000000;
					font-family: "Segoe UI";
					font-size: 14px;
					font-weight: 400;
					text-align: left;
					@include margin(0 0 12px 0);

					span {
						font-family: "Segoe UI Semi Bold";
						font-weight: 600;
					}

					&.email {
						color: #3ba8c5;
					}
				}
			}
		}
	}
	&.section_Dept {
		.box {
			@include padding(30px);
			.headerTitle {
				margin-bottom: 23px;
			}
			.formBtns {
				margin-top: 0px;
    			border-top: none;
    			margin-bottom: 30px;
			}
		}
		.panelWrapper {
			min-width : max-content;
			box-shadow: none;
		}
		.panelHeader {
			>div:first-child p {
				margin-left: 15px;
			}
		}
		.panelContent {
			p {
				color: #000000;
				font-family: "Segoe UI";
				font-size: 14px;
				font-weight: 400;
				margin: 0px;
				
			}
			form {
			 >div {
			 	float: left;
			 	margin-bottom: 0;
			 	.form-control {
			 		width: 100%;
			 	}
			 	&.customDrpDwn.has-error .css-vj8t7z {
				    border:1px solid #c94040;
				}
			 }
			}
			>div:first-child p {
				margin-left: 15px;
			}
			.editWrapper {
				float: right;
				p{
					color: #3ba8c5;
					font-family: "Helvetica Neue Cyr Medium";
					font-size: 14px;
					font-weight: 500;
					text-transform: uppercase;
					letter-spacing: 0.28px;
					span{
						@include margin(0 15px 0 7px);
					}
					img{
						margin-top: -4px;
					}
				}
			}
		}
	}
}
.chat.data {
				.fieldSection p {
					color: #242831;
					font-family: "Segoe UI Semi Bold";
					font-size: 14px;
					font-weight: 400;
				}
				p.mandatoryData {
					width: 20px;
					margin-right: 10px;
					text-transform: none;
					color: #242831!important;
					font-family: "Segoe UI Semi Bold";
					font-size: 14px;
					font-weight: 400;
				}
				.custom_radioBtn {
					p {
						color: #000000;
						font-family: "Segoe UI";
						font-size: 11px;
						font-weight: 700;
						text-transform: uppercase;
					}
					.active {
						p {
							color: #fff;
						}
					}
				}
			}
			//changed to 0px bcos button cancel was coming down
			.chat .optionArea .editWrapper {
			 >div:nth-child(2) {
			 	margin-right: 0px;
			 }
			}
.widgetSettingsWrapper {
	.section {
		margin-bottom: 30px;

		&:last-child{
			margin-bottom: 0;
		}
	}
	.addSettingsIcon {
		width: 20px;
	}
	.scroll_row {
		margin-bottom: 35px;

		.panelWrapper {
			min-width: 635px;
		}

		.ruleComponent {
			padding: 11px 13px 9px 5px;
			cursor: pointer;
			.optionArea .editWrapper {
				float: none;
				> div {
					&:first-child {
					    margin-right: 0;
					    margin-left: 8px;
					}
				}
				.save_edit p img {
					margin-top: 0;
				}
				.del p img {
					margin-right: 2px; //changed here
					margin-top: -2px;
				}

				p.mandatoryData {
					width: 100%;
					padding-right: 50px
				}
			}
			.css-1ep9fjw {
				padding: 8px 8px 8px 2px;
			}
		}
	}
	.panelWrapper {
		box-shadow: none;
		.panelHeader {
			padding-left: 19px;
		}
		.scriptPanel {
			.editing {
				.form-control {
					width: 100%;
				}
			}
			.chat.data {
				
				.custom_radioBtn {
					p {
						top: -11px;
						}
						}
						}
		} 

	}
	&.innercontent_wrapper {
		.with-header {
			.box {
				.chatPreview .headerTitle{
					padding: 10px 9px;
				}
			}
		}
		.soundSettings .customDrpDwn {
			&> div, &>img {
				float: left;
			}
			&>img {
				margin: 7px 10px 6px 15px;
			}
		}
	}
	.chatSettings {
		.customDrpDwn {
			.css-10nd86i {
				width: auto;
			}
		}
		.form-control {
			width: 100%;
		}
		.paramField {
			position: relative;
			textarea {
				position: absolute;
    			left: 0;
    			top: -20px;
    			z-index: 2;
			}
		}
		textarea {
			width: 100%;
			color: #000000;
		    font-family: 'Segoe UI Semi Bold';
		    font-size: 14px;
		    font-weight: 400;
		    border: 1px solid #dedede;
		    border-radius: 4px;
		}

		.panelWrapper, &:nth-child(2){
			.form-group {
				margin-bottom: 5px;
			}
		}

		&:nth-child(3) .scroll_row {
			margin-top: 30px;
		} 

		.formBtns {
			margin-top: 10px;
		}

		.radioBtnGrp input {
			margin-bottom: 7px;
		}
		span.mandatory {
			color: #e44e4e;
			margin-left: 3px;
		}
	}

	.soundSettings {
		.soundPackageSection {
			border-radius: 10px;
			background-color: #f6f6f6;
			padding: 20px 19px 7px;
			margin-top: 20px;

			.sound-grp {
				>div {
					padding-left: 5px;
				}
			}
		}
	}

	.preview .chatPreview {
		height: 475px;
		.chatBox {
			height: 395px;
			.msgArea {
				height: 97%;
				.form-group {
					margin-bottom: 5px;
				}
			}
		}
		.custom_radioBtn .btnText {
			top: 4px;
			color: #000000;
		    font-family: "Segoe UI";
		    font-size: 11px;
		    font-weight: 700;
		}
		.chatBox {
			padding: 0 0 20px 10px;
			.msgArea p {
				font-family: "Segoe UI Semi Bold";
		    	font-size: 14px;
		    	font-weight: 400;
			}
			
		}
		p {
			color: #242831;
		    font-family: "Segoe UI Semi Bold";
		    font-size: 14px;
		    font-weight: 400;
		}
		.chatArea {
			margin-top: 15px;
		}
		.formBtns {
			padding: 0 15px;
			p {
				float: left;
				text-transform: none;
				margin: 8px 0;

				.mandatory {
					margin-right: 3px;
					margin-left: 0;
				}
			}
			.btn_send p {
				color: #fff;
				margin: -2px 0;
			}
		}
		.checkbox_custom {
			margin-top: 8px;
		}
	}


	.btn-group-toggle {
		display: inline-block;

		label {
			text-align: left;
			&.btn {
				margin-bottom: 13px;
				color: #000000;
			    font-family: 'Segoe UI Semi Bold';
			    font-size: 14px;
			}
		}
		
	}
}

.innercontent_wrapper .websiteContentWrapper {
	.header_title {
		h4 {
			color: #3ba8c5;
			font-family: "Helvetica Neue Cyr Roman";
			font-size: 22px;
			font-weight: 400;
			margin-right: 20px;

			&.websiteName {
				color: #000000;
			}
		}
	}

	.box {
		//padding: 30px 0 19px;
		padding: 28px 0 19px;
		.websiteFormWrapper {
			padding: 0 30px;
		}
		.formBtns {
			padding-left: 30px;
			padding-right: 30px;
			padding-top: 20px;
		}
	}
	.logoWrapper {
		max-width: 230px;
		max-height: 230px;
		margin-right: 25px;
		margin-left: 15px;
		
		img {
			opacity: 0.7;
			border-radius: 50%;
			//border: 2px solid #3ba8c5;
			padding: 21px;
			margin-top: 17px;
		}
		p {
			margin-top: 7px;
			color: #3ba8c5;
			font-family: "Segoe UI Bold";
			font-size: 14px;
			font-weight: 700;
			text-transform: uppercase;
		}
		input {
			position: absolute;
		    top: 60px;
		    left: 70px;
		    height: 110px;
		    opacity: 0;
		    width: 90px;
		    cursor: pointer;
		}
	}
	.websiteFormWrapper {
		.form-control {
			width: 100%;
		}
		.form-group {
			/*margin-bottom: 13px;*/
		}
		.form-label {
			line-height: 1.3;

		    &.forEmail {
		    	padding-bottom: 0;
		    	padding-top: 0;
		    }
		}
	}
	
}


.websiteWidgetGetCode {
	.formBtns Button {
    	height: 50px;
    }
}

.innercontent_wrapper .widgetWebsiteWrapper {
	.box.settingFormWrapper {
		padding: 30px 15px;
	}
	.widgetThumbnailWrapper {
		.addWrapper {
		    padding: 96px 0;
		    cursor: pointer;
		    img {
			    border: 2px solid #3ba8c5;
			    border-radius: 50%;
			    padding: 21px;
			}
			p {
			    color: #3ba8c5;
			    font-family: "Segoe UI Bold";
			    font-size: 14px;
			    font-weight: 700;
			    text-transform: uppercase;
			    margin: 13px 0 0 0;
			}
		}

		.noneWrapper {
		    padding: 96px 0;
		    cursor: pointer;
		    img {
			    border: none;
			    border-radius: 0%;
				padding: 11px;
			}
			
		}

		.noWrapper {
		    padding: 118px 0;
		    cursor: pointer;
		    img {
			    border: none;
			    border-radius: 0%;
				padding: 11px;
			}
			
		}
	}
	.widgetContent {
		padding: 12px 12px 0;
		margin-bottom: 30px;
		.logoWrapper {
			p {
				height: 22px;
				margin: 0;
				span{
					width: 17px;
					height: 17px;
					float: right;
					border-radius: 50%;
					&.installed_icon{
						background-color: #51af31;
					}
					&.uninstalled_icon {
						background-color: #c94040;
					}
				}
			}
		}
		.detailsWrapper {
			p {
				color: #000000;
				font-family: "Segoe UI Semi Bold";
				font-size: 14px;
				font-weight: 400;
				margin: 10px 0;
				span {
					
					color: #7d7d7d;
					&.count {
						margin-left: 6px;
						border-radius: 50%;
						background-color: #3ba8c5;
						padding: 1px 8px 4px 7px;
						color: #ffffff;
						font-family: "Segoe UI Bold";
						font-size: 12px;
						font-weight: 700;
						cursor: pointer;
						&:hover {
							padding: 0px 7px 3px 6px;
							border-color: 1px solid #3ba8c5;
							background-color: #fff;
							color: #3ba8c5;
						}
					}
				}
				&.domain {
					position: relative;
					cursor: pointer;
					span {
						color: #3ba8c5;
					}
					.domainList {
						width: 260px;
						box-shadow: 0 0 5px rgba(13, 16, 21, 0.19);
						border-radius: 3px;
						background-color: #ffffff;
						padding: 20px 14px;
						position: absolute;
						z-index: 5;
						top: 27px;
		    			right: -27px;
		    			text-align: left;
						color: #000000;
						word-wrap: break-word;
						height:200px;
						overflow: hidden;
					}
				}
			}
			.operators {
				position: relative;
			}
			.operatorsList {
				width: 190px;
				box-shadow: 0 0 5px rgba(13, 16, 21, 0.19);
				border-radius: 3px;
				background-color: #ffffff;
				padding: 20px;
				position: absolute;
				z-index: 5;
				top: 27px;
    			right: -27px;
    			text-align: left;
			}
		}
		.tumbnail_footer {
			margin: 0 -12px;
			border-bottom-left-radius: 20px;
			border-bottom-right-radius: 20px;
			.formBtns {
				border-top: 0;
				padding: 11px 0 10px;
				margin-top: 27px;
				Button{
					width: 91px;
					height: 29px;
				}
				.edit_btn {
					margin: 0 19px 0 20px;
				}
				img{
					cursor: pointer;
				}
			}
		}
	}
}

@media (max-width: 1030px) {
	.ruleComponent .optionArea .editWrapper > div:first-child {
		margin-right : 16px;
	}
}

@media (max-width: 768px) {
	.innercontent_wrapper .securityWrapper {
		 .box {
			.headerTitle {
			    @include padding(15px);
			}

		    .custom_textArea {
		    	span {
					font-size: 12px;
		    	}
		    	textarea {
					font-size: 12px;
		    	}
		    }

		    .country_text {
		    	span {
					font-size: 12px;
		    	}
		    }
		}
	}

	.fileTransferWrapper {
		.panelWrapper {
			.panelHeader {
				p {
					font-size: 12px;
					margin: 8px 0;
				}
			}
			.scriptPanel p {
				font-size: 12px
			}
		}
	}

	.chatInvWrapper .section.box {
	    padding: 15px;
	}
	
}
@media (max-width: 576px){
	
	.customDrpDwn .css-10nd86i {
		width: 100%;
		max-width: 256px;
	}
	.panelWrapper .scriptPanel > div.panelHeading p:first-child {
		width: 20px;
	    height: 20px;
	    margin-top: 4px;
	}
	.ruleComponent {
		@include padding(13px 8px);
		&:active {
		    @include grabbing-cursor;
		}
		.rule_data {
		    width: 88%;
		    @include padding-direction(left, 16px);

		    .optionArea {
		    	@include margin-direction(top, 10px);
		    }
		}
	}
	.invitationRulesWrapper {
		.rules_wrapper .box {
    		@include padding(10px);
		}
		
	}
	.editRulesSection {
		.customDrpDwn {
			@include margin-direction(bottom, 10px);
			.css-10nd86i {
				width: 100%;
			}
		}

	}

	.editScriptSection {
		.customDrpDwn .css-10nd86i {
			max-width: none;
		}
	}

	.fileTransferWrapper {
		.settingFormWrapper form .customField p {
			font-size: 12px;
		}
		.formBtns {
			@include padding-direction(left, 0);
			@include padding-direction(right, 0);
		}
		.btn_del,.btn_clr {
			width: auto;
			@include padding(8px 8px);
		}
	}

	.widgetSettingsWrapper {
		.soundPackageSection .customDrpDwn .css-10nd86i {
   			max-width: 253px;
		}
	}
}

@media (max-width: 375px){
	.fileTransferWrapper {
		.btn_clr {
			@include margin-direction(bottom, 5px);
			padding: 8px 24px;
		}
		.btn_del {
			@include margin-direction(left, 0);
		}
	}
	.widgetSettingsWrapper {
		.soundPackageSection .customDrpDwn .css-10nd86i {
   			width: 166px;
		}
		&.innercontent_wrapper .soundSettings .customDrpDwn > img {
			margin-left: 10px;
		}
		.soundSettings .soundPackageSection {
			padding: 11px 8px 7px;
			.sound-grp {
				margin-left: 4px;
			}
		}
	}
}