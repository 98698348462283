@import '_mixins';
@import 'node_modules/react-modal-video/scss/modal-video.scss';

.header {
	@include margin-direction(bottom, 2px);
  	height: 61px;
  	box-shadow: 0 0 5px rgba(13, 16, 21, 0.23);
  	background-color: #ffffff;
  	position: fixed;
    z-index: 1030;

  	.logo-img{
  		@include padding-direction(left, 8px);
    	cursor: pointer;
  	}
  	img,p{
  		float: left;
  	}
}
.custome_company_name {
	margin: 8px;
	font-weight: 300;
	font-size:13px;
	padding-left: 8px;
	cursor: pointer;
	max-width:100%;
	height:auto;
	//padding:6px 0 6px 19px;
}
.expire_account {
	@include padding(19px 0 6px 19px);
	  font-family: 'Helvetica Neue Cyr';
	  color: #8fbfcc;
	  font-size: 13px;
	  font-weight: 300;
	  @include margin(0);
}
.divider {
	  background-image: url("assets/images/divider.png");
	  background-repeat: no-repeat;
	  @include padding(6px 0 6px 19px);
	  font-family: 'Helvetica Neue Cyr';
	  color: #8fbfcc;
	  font-size: 13px;
	  font-weight: 300;
	  @include margin(0);
}
.bg_circle {
	@include circle(44px,rgba(255,255,255,0),3px);
	position: relative;
	z-index: 0;
}
.green_circle {
	@include circle(44px,#51af31,3px);
	position: absolute;
	z-index: 1;
}

.red_circle {
	@include circle(44px,#c94040,3px);
	position: absolute;
	z-index: 1;
}
.faq {
//	@include margin-direction(left, 28px);
}
.notification {
	// @include margin(0 30px);
	@include margin(0 20px);
  	position: relative;
  	display: inline-block;

  	.notification_ellipse {
  		@include border(2px, solid, white);
		  border-radius: 50%;
		  background-color: rgb(201, 64, 64);
		  position: absolute;
		  top: 0px;
		  right: -5px;
		  width: 17px;
		  height: 17px;
		  z-index: 43;

		  p{
			  font-size: 10px;
			  @include margin(0);
			  text-align: center;
			  width: 100%;
			  color: #ffffff;
			  @include margin-direction(top, -2px);
			}
		}
}

.profileStatus {
	@include margin-direction(right, 45px);

	.float {
	  	float: none;
	}

	.avatar {
	  	@include margin-direction(right, 13px);
	}
	.status{
	  float: left;
	  @include padding-direction(right, -2px);

	  p{
		  @include margin(0);
		  font-size: 15px;
		  font-family: 'Helvetica Neue Cyr Roman';
		  font-weight: 400;
		  color: #242831;
		  &.statusDisplay{
		  	font-size: 13px;
			position: relative;
			font-family: 'Helvetica Neue Cyr';
			font-weight: 300;
			&.text-active{
			  color: #67b84b;
			}
		  }
		  &.dropdown-item {
		  	color:#000;
			font-family: 'Segoe UI';
			font-size: 13px;
			@include padding-direction(left, 22px);
		  }
		  
		}
		.status-img {
		  	position: absolute;
		  	right: -12px;
		  	bottom: 6px;
		}
		.dropdown-menu.show {
			width: 121px;
			min-width: 121px;
			height: 100px;
			box-shadow: 0 0 5px rgba(13, 16, 21, 0.19);
			border-radius: 3px;
			background-color: #ffffff;
			@include margin-direction(left, -22px);
		}
	}
} 

.checkout{
	cursor: pointer;
  	background-color: rgb(59, 168, 197);
  	height: 100%;
  	color: #ffffff;
  	@include padding(19px 23px 14px 29px);
  	@include margin-direction(left, 31px);

  	p{
	  	@include margin(0);
	  	@include padding-direction(left, 10px);
	  	font-family: 'Segoe UI Bold';
	  	font-size: 14px;
	  	font-weight: 700;
	}
}

