@import 'assets/styles/_theme';
@import 'assets/styles/_mixins';
@import 'assets/styles/_fonts';
@import 'assets/styles/_normalize';
@import 'assets/styles/_chats';
@import 'assets/styles/_header';
@import 'assets/styles/_common';
@import 'assets/styles/_admin';
@import 'assets/styles/_profile';
@import 'assets/styles/_traffic';
@import 'assets/styles/_widget';
@import 'assets/styles/_responsive';
@import 'assets/styles/_fontIcons';
@import 'assets/styles/_maincharts';
@import "react-alice-carousel/lib/scss/alice-carousel.scss";
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-popups/styles/material.css";
@import 'assets/styles/aibot';
$black: #000;
$grey: #ccc;
$white: #fff;
$yellow: #ff0;
$green: #8bc34a;
$blue: #29d2e4;
$orange: #f87d09;
$dark-pink: #e91e63;
$light-purple: #efedff;
//Primary-color
$tooltip-base-text-color: $white;
$tooltip-base-bg-color:$black ;
$tooltip-base-border-color: $black;
$main-background: $light-purple;
//Types
$tooltip-invert-bg-color: $grey;
$tooltip-success-bg-color: $green;
$tooltip-info-bg-color: $blue;
$tooltip-warning-bg-color: $orange;
$tooltip-danger-bg-color: $dark-pink;


.operatorChat.supervisorFile .chatText::before{
 border-bottom-color: #f1ea4f;
}

.getCodeModel .modal-dialog  {
  .modal-content {
    width:100%;
    .websiteWidgetGetCode .codeWrapper  textarea{
      width:100%;
    }
  }
  
  }

.geoMapPopup .modal-dialog .modal-content {

  width:100%;
}
.react-joyride__overlay {
  position: unset !important;
  background-color: #fff !important;
  .react-joyride__spotlight{
    position: unset !important;
    background-color: #fff !important;
  }
}

.customeicon_wrapper{
  width: 100% !important;
  background: transparent !important;
}
// .customelogoIcon{
//   min-height: 115px !important;
//   .submenudropdwnp{
//     //overflow:auto;
//     padding-bottom: 0px !important;
//     margin-bottom: 0px !important;
//     overflow-y: auto !important;aa
//     height: 66px !important;
//     overflow-x: hidden !important;
//     line-height: 1.5;
//     white-space: unset !important;
//     scrollbar-color: #3ba8c5 transparent;
//     scrollbar-width: thin;
//     margin-top: 4px !important;
//   }
//   .css-xp4uvy{
//     white-space: unset !important;
//   }
//   .css-15k3avv{
//     scrollbar-color: #3ba8c5 transparent;
//     scrollbar-width: thin;
//   }
 
// }
// .customelogoIcon .css-10nd86i .css-1hwfws3 {
//   height: 115px !important;
// }
.foot_Responsesearch{
  width: 100% !important;
}

.fc_profile_img{
  display: inline-block;
  margin: 5px 10px 5px 0;
  width: 45px;
  height: 45px;
  cursor:pointer;
  border: 1px solid
  #bec3c7;
  border-radius: 3px;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  vertical-align: bottom;
}
.visitorTypingContent{
  text-align:left;
  .visitorTyping{
    display: inline;
    margin-left: 5px;  
  }
}
.visitor_field{
  line-height: 5px;
}
.waitingforChat{
  width: 284px;
  padding: 10px 10px 20px 10px;
  border: 1px solid
  #BFBFBF;
  background-color:
  white;
  box-shadow: 10px 10px 5px
  #aaaaaa;
  position: relative;
  left: 25%;
  top: 25%;
  z-index: 999;
  border-radius: 3px;
  font-family: Segoe UI;
}

// .owner_role_cant_changed {
//    label{
//     border-color:#dedede !important;
//     background-color:#e7e7e7 !important;
//    }
//    p{
//     border-color: #dedede !important; 
//      color:#000 !important;
//    }
// }

.transferchat ul {
  list-style :none;
  margin-left:-40px;
}

.transferchat ul li{
  padding:10px;
}

.transferchat ul li:hover{
  background:#ccc;
}
//chat #title response style
.findchatResponses{
  background: #f7f3f3;
  width: 50%;
  height: 130px;
  overflow-y: scroll;
  box-shadow: 0px 1px 1px 1px
  #ccc;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-align: left;
  z-index: 1;
  position: absolute;
  top: -119px;
  left: 117px;
}

.chat-limt {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 14px;
    font-weight: 400;
}

.iscannedEnabled .nav.nav-tabs a.nav-item:nth-child(4) {
  pointer-events: none;
  opacity: 0.3;
}

.findchatResponses ul {
  list-style :none;
  margin-left:-40px;
}

.findchatResponses ul li{
  padding:10px;
}

.findchatResponses ul li:hover{
  background:#ccc;
}

.schedule_zone {
  .day_wrapper {
    @include margin-direction(bottom, 13px);
    p.day_label{
      color: #000000;
      font-family: 'Segoe UI Semi Bold';
      font-size: 14px;
      font-weight: 400;
      @include margin(8px 4px 8px 0);
      text-align: left;
      width: 73px;
      @include padding-direction(left,0);
    }
    input {
      width: 67px;
      height: 37px;
      @include margin-direction(left,10px);
    }
    .custom_radioBtn .btnText{
      position: absolute;
      top: 3px;
      right: 7px;
      color: #000000;
      font-family: 'Segoe UI';
      font-size: 11px;
      font-weight: 700;
      transition: all 0.3s;
    }
  }
}

//reports
.testingImg {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/linkIcon.png");
}

 .opCss {
  font-size:20px !important ;
}

.Sms_operators{
color:
#293037;
font-family: 'Segoe UI Bold' !important;
font-size: 13px !important;
font-weight: 324;
}

.reportUsageborder{
border-bottom: 1px solid #d0d0d0;
}

.reportUsage{
margin-top: 27px;
width: 100%;
box-shadow: 0 0 5px
rgba(13, 16, 21, 0.19);
border-radius: 10px;
background-color:
    #ffffff;
    margin-left: 0;
    padding: 0px 0;
}

//datepicker Css
.DateRangePicker{
  position: absolute !important;
  z-index: 125;
  left: -19px;
  background-color: white;
  }

//@Report Charts Css
.reportschartsborder{
border-right: 1px solid #d0d0d0
}

//@Report Charts Css
.operatorreportsborder{
  color:
#293037;
font-family: 'Segoe UI Bold' !important;
font-size: 11px !important;
font-weight: 500;
  }
//@Report Charts Css
.reportschartstext{
  font-family: 'Helvetica Neue Cyr Medium' !important;
  font-size: 30px !important;
  font-weight: 700;
}
//@Report Charts Css
.reportschartsfontsize{
color:
#293037;
font-family: 'Segoe UI Bold' !important;
font-size: 13px !important;
font-weight: 700;
}
//@Report Charts Css
.reportcharts{
  margin-top: 27px;
  width: 100%;
  height: 127px;
  box-shadow: 0 0 5px
  rgba(13, 16, 21, 0.19);
  border-radius: 10px;
  background-color:
  #ffffff;
  margin-left: 0;
  padding: 0px 0;
}

//pagination archive style

.pie {
  list-style: none;
  font-size:12px;

}
.pie #declined::before {
  content: "\2022";
  color: #ffa500;
  font-weight: bold;
  font-size:20px;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}


.pie {
  list-style: none;
  font-size:12px;

}
.pie #accept::before {
  content: "\2022";
  color: #7fae72;
  font-weight: bold;
  font-size:20px;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}

.pie {
  list-style: none;
  font-size:12px;
  
}

.pie #invitations::before {
  content: "\2022";
  color: #3ba8c5;
  margin:50 px;
  font-weight: bold;
  font-size:20px;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}

.pie {
  list-style: none;
  font-size:12px;

}
.pie #manualinvitations::before {
  content: "\2022";
  color: #7fae72;
  font-weight: bold;
  font-size:20px;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}

.pie {
  list-style: none;
  font-size:12px;

}

.pie #time::before {
  content: "\2022";
  color: #e44e4e;
  font-weight: bold;
  font-size:20px;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}

.archive_pagination .pagination {
  display: flex;
  flex-direction: row;
  list-style: none;
  border:0.5 solid #3ba8c5;
  color: #3ba8c5;
  font-family: Segoe UI Bold;
  font-size: 13px;
  padding-top: 17px;
  border-radius:50%;
  justify-content: center;
  align-items: center;
}

.archive_pagination .pagination li {
  cursor:pointer;
	margin-right:12px; 
}

.archive_pagination .pagination li.active {
	cursor:pointer;
	margin-right:12px;
	border: #3ba8c5  solid;
	border-radius:20px;
	width:25px;
	height:25px;
		border-width:thin;
}	


.disable-bg {
  background-color:#ccc !important;
}
//traffic_pagination

.traffic_pagination .pagination {
  display: flex;
  flex-direction: row;
  list-style: none;
  border:0.5 solid #3ba8c5;
  color: #3ba8c5;
  font-family: Segoe UI Bold;
  font-size: 13px;
  padding-top: 17px;
  border-radius:50%;
}

.traffic_pagination .pagination li {
  cursor:pointer;
	margin-right:12px; 
}
.traffic_pagination .pagination li a{
          border: none;
          outline: none;

          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
}

.traffic_pagination .pagination li.active {
	cursor:pointer;
	margin-right:12px;
	border: #3ba8c5  solid;
	border-radius:20px;
	width:25px;
	height:25px;
		border-width:thin;
}	


// loder css for admin tab
.deleteCstm {
    cursor: pointer;
    padding-top: -21px;
    margin: -5px -75px 12px 0px;
    margin-top: -24px;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    border: 1px solid #dedede;
    background-color:#ffffff;
    color: #c94040;
    margin-left: -54px !important;
    margin-top: -30px !important;
}

.websitePayDollar{
  border: 3px solid #3ba8c5;
  border-radius: 50%;
  padding: 6px !important;
  font-size: 40px !important;
  width: 80px;
}
.payDollar {
  border: 3px solid #ec407a;
  border-radius: 50%;
  padding: 14px;
  font-size: 40px !important;
}
.pointerhand {cursor: pointer;}

.adminloader {
  position: fixed;
  z-Index: 999 ;
  height: 100% ;
  width: 100% ;
  overflow: visible ;
  margin: auto ;
  top: 288px ;
  left: 0 ;
  bottom: 0 ;
  right: 0 ;
  background: rgb(247, 243, 243) none repeat scroll 0% 0% ;
  opacity: 0.6 ;
  margin-Left: 23% ;  
  margin-Top: -227px ;
}
.profileLoader {
  position: fixed;
  z-Index: 999 ;
  height: 100% ;
  width: 100% ;
  overflow: visible ;
  margin: auto ;
  top: 288px ;
  left: 0 ;
  bottom: 0 ;
  right: 0 ;
  background: rgb(247, 243, 243) none repeat scroll 0% 0% ;
  opacity: 0.6 ;
  margin-Left: 8% ;  
  margin-Top: -227px ;
}
.chatSectionLoader{
  position: fixed;
  z-Index: 999 ;
  height: 100% ;
  width: 100% ;
  overflow: visible ;
  margin: auto ;
  top: 288px ;
  left: 0 ;
  bottom: 0 ;
  right: 0 ;
  background: rgb(247, 243, 243) none repeat scroll 0% 0% ;
  opacity: 0.6 ;
  margin-Left: 17% ;  
  margin-Top: -227px ;
}
/* for sounds setting */
.innercontent_wrapper .profile_wrapper .customDrpDwnDisabled .css-1sontr1 {
  width: 230px;
}

/* for attached images */
.fileUploaderDiv
{
  position : relative;
  display: inline-block;
  margin: 5px 10px 5px 0;
  width: 80px;
  height: 80px;
  border: 1px solid #bec3c7;
  border-radius: 10px;
  background-position: 50%;
  cursor:pointer;
}
.fileUploaderDiv:hover {
    background-color:#f6f6f6;
    transform: scale(1.1);
}

.fileUploaderDiv img {
  position: absolute;
  top: 29%;
  left: 32%;
}
.notAllowedDiv{
  background-color:#f6f6f6;
  pointer-events: none;

}
.maxAllowedMsg {
  font-family: 'Segoe UI Semi';
  color:#c94040;
}
.fileUploaderDiv .notAllowed {

  pointer-events: none;
   width:35%;
}
.cannedImages {
  display: inline-block;
  margin: 5px 10px 5px 0;
  width: 80px;
  height: 80px;
  border: 1px solid #bec3c7;
  border-radius: 10px;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  vertical-align: bottom;
}
.cannedImages .img_delete{
  float: right;
  margin-right: 5px;
  cursor:pointer;
}
.cannedImages .img_download {
  float: left;
  margin-left: 6px;
}
.cannedImages .img_download :hover {
  transform: scale(1.2);
}

.cannedImages:hover {
  transform: scale(1.02);
}
.cannedImages .img_delete img:hover {
  transform: scale(1.2);
}
/* for disable button */
.cursorNotAllowed{
  cursor: not-allowed;
}
 .cursorNotAllowed .disabledbutton {
  pointer-events: none;
  opacity: 0.4;
 }
/* for--------login----------*/
.loginWrapper{
  width:135%;
}
.loginWrapper .loginHeader{
  background-color: #3ba8c5;
  border-radius: 10px 10px 0 0;
  padding-right: 15px;
  padding: 8px;
}
.loginWrapper .loginHeader h6{
  font-family: 'Segoe UI Semi Bold';
  font-weight: 400;
  font-size:18px;
}
.loginWrapper .content{
  padding:14px;
  background:#E5F5FC;
  text-align: left;
  color: #000000;
  font-family: 'Segoe UI Semi';
  font-size: 14px;
  font-weight: 400;
  padding-left:30px;
}
.loginWrapper .content .invalidLogin{
   font-size: 14px;
  background:yellow;
  text-align:center;
  color:red;
   font-family: 'Segoe UI Semi';
   margin-left: -18px;
}
.loginWrapper .loginForm{
  padding:28px;
}
.loginWrapper .loginForm .forgot{
  float:left;
  cursor: pointer;
  font-family: 'Segoe UI Semi Bold';
  color:#3ba8c5;
  margin-left: -26px;
}
.loginWrapper .loginForm .forgot:hover{
  color: #000000;;
}

.loginWrapper .loginBtns{
  padding-top:10px !important;
  margin-top: 0px !important;
  border-top: none !important;
  padding-right: 0;
}
.loginWrapper .formBtns Button.restoredpass{
  width:175px !important;
}
.loginWrapper .formBtns Button:hover{
   background-color: #ffff;
   color:#3ba8c5;
   border:1px solid #3ba8c5;
}
.loginWrapper .formBtns Button p{
  text-transform: none;
}
.loginWrapper .form-label {
  text-align: left;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400;
}

.logoutCss .modal-dialog .modal-content{
  width:80%;
  height:240px;
}

/* for--------archiveChat and PopUp--------------start-----------*/

 .exportCss {
  pointer-events: none;
  /* for "disabled" effect */
  opacity: 0.5;
  background: #CCC;
   }

.table_td table td {

        padding:0.1rem;
        border-top:none;
    }
  
.modal-dialog .modal-content .chatInfoModelHd .close {
 
    margin-top: -94px;
}
.modal-header {
  
  }
.modal-dialog .modal-content .chatInfoModelHd .modal-title {
color: #000000;
font-family: "Helvetica Neue Cyr - Roman";
font-size: 18px;
font-weight: 400;
line-height: 36px;

}
.chatInfoModelHd{ 
   padding-bottom:0px;
   display:block;
  }
.modal-dialog .modal-content .modal-header button.chatInfoBtn {
    border: 1px solid #3ba8c5;
    background-color: #ffffff;
    color: #3ba8c5;
    font-family: "Segoe UI";
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
}

.modal-dialog .modal-content .modal-header button.chatInfoBtn:hover {
    color:#ffff;
    background-color: #3ba8c5;
}
.table_td {
  .label {
    width: 32%;
    p {
      padding: 0 15px;
    }
  }
  .value {
    width: 68%;
    p {
      padding: 0 15px;
    }
  }
}
.infoTranscript {
  .name {
    width: 22%; 
      p {
        padding: 0 15px;
      }
    
  }
  .time {
    width: 10%; 
    
  }
  .msg {
    width: 65%; 
      p {
        padding: 0 15px;
      }
    
  }
}
.infoTranscript .row .chat_p{  
    font-family: "Segoe UI"!important;
    font-weight:400;
    }

    .infoTranscript .row .chat_p div>p{  
      padding-left: 0px;
      }

.chatInfoBtn {
    margin-top: 5px;
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.1rem 1.022rem;
    font-size: 1rem;
    line-height: 1.5;
     width: 98px;
    height: 29px;
    border-radius: 3px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.modalBodyTitle {
color: #293037 !important;
font-family: "Segoe UI Bold"!important;
font-size: 14px;
font-weight: 700;
text-transform: uppercase;
}
.modalBodyTitle:nth-child(1){
 margin-top:10px;
}
.table_td table td p 
{
  
  line-height:17px;
}
.table_td table tr td:nth-child(2) p 
{
 font-family: "Segoe UI" !important;
 width: 162%;
}
.dynamic_firstRow .addBtn {margin-left:0px;}

.infoTranscript .row div:nth-child(2) p {font-family: "Segoe UI" !important;}
.infoTranscript .row div:nth-child(3) p {font-family: "Segoe UI" !important;}
 
           /* for---------scroll pop up----start------*/

           .modal-dialog .modal-content .chatInfoScrollWrapper {
              padding: 0;
              .rcs-inner-container {
                &>div {
                   padding: 1rem 30px;
                }
            }
              
          }

          .modal-dialog .modal-content .chatInfoScrollWrapper p {
              color: #000000;
              font-family: "Segoe UI Semi Bold";
              font-size: 14px;
              font-weight: 400;
          }

          .modal-dialog .modal-content .chatInfoScrollWrapper .modalBodyTitle:nth-child(1) {
              margin-top: 0px;
          }
          .modal-dialog .modal-content .chatInfoScrollWrapper .modalBodyTitle {
              color: #293037 !important;
              font-family: "Segoe UI Bold" !important;
              font-size: 14px;
              font-weight: 700;
              text-transform: uppercase;
          }
           /* for---------scroll pop up----end------*/

 /* for---------notification pop up start---------*/

 .__react_component_tooltip.show {
   opacity:1;
 }

 #notificationClickme {
     width:35%;  
 }

  /*for arrow of tooltip*/
#notificationClickme::before {
  left:71%
  }
  
  #notificationClickme::after {
  left:71%
  }
 
//  .notificationPopup p{
//  all: initial;
//  }
 .notificationPopup {
  border-radius:3px !important;
  width: 100%;
  height: 100%;
  }

  @media (max-width: 350px) {
    #notificationClickme {
      width:64% ;  
    }
  }
  @media (max-width: 480px) {
    #notificationClickme {
      width:65% ;  
    }
    #notificationClickme::before {
      left:81%
      }
      
      #notificationClickme::after {
      left:81%
      }
  }
  @media (max-width: 600px) {
    #notificationClickme {
      width:48% ;  
    } 
    #notificationClickme::before {
      left:85%
      }
      
      #notificationClickme::after {
      left:85%
      }
  }
  @media (max-width: 768px) {
    #notificationClickme {
      width:48% ;  
    } 
    #notificationClickme::before {
      left:76%
      }
      
      #notificationClickme::after {
      left:76%
      }
  }
  @media (max-width: 900px) {
    #notificationClickme {
      width:48% ;  
    } 
    #notificationClickme::before {
      left:71%
      }
      
      #notificationClickme::after {
      left:71%
      }
  }
  @media (max-width: 1024px) {
    #notificationClickme {
      width:38% ;  
    } 
    #notificationClickme::before {
      left:75%
      }
      
      #notificationClickme::after {
      left:75%
      }
  }

  .notiInfoScrollWrapper {
   // margin-left:-14px;
  }

  .modal-dialog .modal-content .notiInfoScrollWrapper p {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
}

.notificationPopup .notificationTitle {
    color: #293037 !important;
    font-family: "Segoe UI" !important;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
}

.notificationPopup .closeNoti {
  float: right;
  cursor: pointer;
}

.notificationPopup .notificationContent .subject {
  color: #293037 !important;
  font-family: Segoe UI !important;
  font-size: 13px;
  font-weight: 700;
  float: none;
  }

  .notiInfoScrollWrapper .back_color_va
{
 // width: 386px;
background: #ebf6f9;
margin-bottom: 20px;
text-align: left;
padding: 32px;
padding-top: 26px;
}

.notificationContent .infoTranscript:nth-child(0){
  width: 386px;
}

.notificationContent .infoTranscript:nth-child(1){
  width: 436px;
}

.notiInfoScrollWrapper .back_color_va
{  p {
float:none;
}
}
.notiInfoScrollWrapper .back_color_va .va_text
{
padding-left: 16px;
 p {
     font-family: "Segoe UI"!important;
     float:none;
    }
}
















  .modal-dialog .modal-content .notiInfoScrollWrapper {
              padding: 0;
              .rcs-inner-container {
                &>div {
                   padding: 1rem 30px;
                }
            }
              
          }

        


.notiInfoScrollWrapper button.notiInfoBtn {
    border: 1px solid #3ba8c5;
    color: #3ba8c5;
    font-family: "Segoe UI";
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
}
.notiInfoScrollWrapper button.dismiss{
  width: 98px;
height: 39px;
}
.notiInfoScrollWrapper button.dismiss:hover 
{
  background-color: #EBF6F9 !important;
}

.notiInfoScrollWrapper button.read_more{
  width:119px;
height: 39px;
color:#fff;
background:#3ba8c5;
}

 .notiInfoScrollWrapper .back_color {
 padding-top: 10px;
 } 

 .notiInfoScrollWrapper .back_color,.notiInfoBtn
{
background: #ebf6f9;

}




/*--------------*/


.setup_step ol {
  list-style: none;
  margin-left: -16px;
  }
.setup_step ol li {
    counter-increment: li;
    margin-bottom: 10px;
}
.setup_step ol li a {
  
  color: #3ba8c5 !important;
}
.setup_step ol li::before {
   content: counter(li);
display: inline-block;
width: 24px;
height: 24px;
border-radius: 50%;
border: 1px solid #3ba8c5;
color: #3ba8c5;
font-family: Segoe UI Bold;
font-size: 13px;
float: left;
padding-left: 7px;
  }

 .setup_step ol li p { 
 font-family: "Segoe UI" !important;
font-size: 14px !important;
font-weight: 400 !important;
line-height: 20px !important;
 padding-left: 30px !important;
 } 

/* for---------notification pop up end---------*/


// /* for---------notification pop up start---------*/
// .modal-dialog .modal-content .notiInfoScrollWrapper {
//   padding: 0;
//   .rcs-inner-container {
//     &>div {
//        padding: 1rem 30px;
//     }
// }
  
// }

// .modal-dialog .modal-content .notiInfoScrollWrapper p {
//   color: #000000;
//   font-family: "Segoe UI Semi Bold";
//   font-size: 14px;
//   font-weight: 400;
// }


// .modal-dialog .modal-content .notiInfoScrollWrapper .modalBodyTitle {
//   color: #293037 !important;
//   font-family: "Segoe UI" !important;
//   font-size: 13px;
//   font-weight: 700;
 
//   text-transform: uppercase;
// }

// .notificationPopup .modal-dialog .modal-content {
// border-radius:3px !important;
// width: 419px;
// height: 417px;
// left: 277px;
// top: -70px;

// }

// .notificationPopup .modal-dialog .modal-content::before {
// content: "";
// position: absolute;
// top: -14px;
// left: 220px;
// z-index: 1;
// border: 7px solid transparent;
// border-bottom-color: transparent;
// border-bottom-color: #fff;
// }


// .notiInfoScrollWrapper button.notiInfoBtn {
// border: 1px solid #3ba8c5;
// color: #3ba8c5;
// font-family: "Segoe UI";
// font-size: 13px;
// font-weight: 700;
// text-transform: uppercase;
// }
// .notiInfoScrollWrapper button.dismiss{
// width: 98px;
// height: 39px;
// }
// .notiInfoScrollWrapper button.dismiss:hover 
// {
// background-color: #EBF6F9 !important;
// }

// .notiInfoScrollWrapper button.read_more{
// width:119px;
// height: 39px;
// color:#fff;
// background:#3ba8c5 !important;
// }
// .modal-dialog .modal-content .notiInfoModelHd .close {
// margin-top: -45px;
// }

// .notiInfoModelHd{ 
// border-bottom: none !important;
// padding-bottom:0px;
// display:block;
// }
// .notiInfoScrollWrapper .back_color {
// padding-top: 10px;
// } 

// .notiInfoScrollWrapper .back_color,.notiInfoBtn
// {
// background: #ebf6f9;

// }
// .notiInfoScrollWrapper .back_color_va
// {
// width: 386px;
// margin-top:-3px;
// background: #ebf6f9;
// padding-top: 18px;
// padding-bottom: 18px;
// margin-bottom: 20px;
// }
// .notiInfoScrollWrapper .back_color_va .va_text
// {
// padding-left: 16px;
// p {
// font-family: "Segoe UI"!important;
// }
// }

// .setup_step ol {
// list-style: none;
// margin-left: -16px;
// }
// .setup_step ol li {
// counter-increment: li;
// margin-bottom: 10px;
// }
// .setup_step ol li a {

// color: #3ba8c5 !important;
// }
// .setup_step ol li::before {
// content: counter(li);
// display: inline-block;
// width: 24px;
// height: 24px;
// border-radius: 50%;
// border: 1px solid #3ba8c5;
// color: #3ba8c5;
// font-family: Segoe UI Bold;
// font-size: 13px;
// float: left;
// padding-left: 7px;
// }

// .setup_step ol li p { 
// font-family: "Segoe UI" !important;
// font-size: 14px !important;
// font-weight: 400 !important;
// line-height: 20px !important;
// padding-left: 30px !important;
// } 

// /* for---------notification pop up end---------*/


/* for---------help popup tooltip---------*/

#helpTooltipClick{

  width:40%;
  }
  
  .helpPopuptip {
    padding:10px;
  text-align:justify;
  }

  .helpPopuptip .closeNoti {
    float: right;
    cursor: pointer;
  }

 .helpTipTitle {
    color:#000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 18px;
    font-weight: 400;
  }

  .borderBtm{
    border-bottom: 4px solid #3ba8c5;
    padding: 1px;
    width: 116%;
    margin-top: 10px;
    margin-left: -16px;
    margin-right: -16px;
  }
  .helpTipScrollWrapper{
    padding-top: 20px;
    p{
      color:#000000;
      font-family: "Segoe UI Semi Bold";
      font-size: 14px;
      font-weight: 400;
    }
  }
  .helpTipScrollWrapper .do_not_check {
      float: none;
      display: inline;
  }
  .helpTipScrollWrapper .floatRight {
    float:right;
    }
    .do_not_check .checkbox_custom  {
      margin-bottom: 0px;
  }
  
.helpTipScrollWrapper button.helpBtn:hover {
  color: #ffff;
  // background-color: #3ba8c5 !important;
}

.helpTipScrollWrapper button.helpBtn {
  border: 1px solid #3ba8c5;
  background-color: #ffffff;
  color: #3ba8c5;
  font-family: "Segoe UI";
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.helpTipScrollWrapper button.helpBtn:nth-child(1) {
float:right;
}
.helpTipScrollWrapper  .do_not_check {float:left; display:inline;}
.modal-dialog .modal-content .helpTipScrollWrapper {
            padding: 0;
            .rcs-inner-container {
              &>div {
                 padding: 1rem 30px;
              }
          }
            
        }

        .modal-dialog .modal-content .chatInfoScrollWrapper p {
            color: #000000;
            font-family: "Segoe UI Semi Bold";
            font-size: 14px;
            font-weight: 400;
        }

        .modal-dialog .modal-content .helpTipScrollWrapper .modalBodyTitle:nth-child(1) {
            margin-top: 0px;
        }
    

  
/* for---------help popup---------*/

.helpPopup .modal-dialog .modal-content {
  border-radius:0px !important;
  width: 619px;
  height: 300px;
  left: 355px;
  top: -130px;

  }
  .helpPopup .modal-dialog .modal-content::before {
    content: "";
    position: absolute;
    top: -14px;
    left: 145px;
    z-index: 1;
    border: 7px solid transparent;
    border-bottom-color: transparent;
    border-bottom-color: #fff;
}

.helpScrollWrapper button.helpBtn:hover {
    color: #ffff;
    background-color: #3ba8c5 !important;
}

.helpScrollWrapper button.helpBtn {
    border: 1px solid #3ba8c5;
    background-color: #ffffff;
    color: #3ba8c5;
    font-family: "Segoe UI";
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1rem;
}
.helpScrollWrapper button.helpBtn:nth-child(1) {
float:right;
}
.helpScrollWrapper  .do_not_check {float:left; display:inline;}
.modal-dialog .modal-content .helpScrollWrapper {
              padding: 0;
              .rcs-inner-container {
                &>div {
                   padding: 1rem 30px;
                }
            }
              
          }

          .modal-dialog .modal-content .chatInfoScrollWrapper p {
              color: #000000;
              font-family: "Segoe UI Semi Bold";
              font-size: 14px;
              font-weight: 400;
          }

          .modal-dialog .modal-content .helpScrollWrapper .modalBodyTitle:nth-child(1) {
              margin-top: 0px;
          }
      
.help_dropdown .dropdown-menu.show {
    width: 182px;
    min-width: 121px;
    height: 152px;
    box-shadow: 0 0 5px rgba(13, 16, 21, 0.19);
    border-radius: 3px;
    background-color: #ffffff;
    margin-left: -80px;
    top: 16px !important;
}
/* Image crop start */
.imgCropModel .modal-dialog .modal-content   {
  display:block;
  }
  /* Image crop  end */

/* for---------help popup end---------*/

/* for---------HelpTour Start---------*/

#helptourClickme{
  width:32%;
}

.helpTourAll .closeTour {
  float: right;
  cursor: pointer;
}

.helpTourAll .stepContent  {
  display:flex;
  margin-top: 34px;
  text-align: justify;
  font-family: Segoe UI;
  font-size: 15px;
  }

  .helpTourAll .stepContent span {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 10px;
    padding-top: 10px;
    background: #67b84b;
    border: 1px solid #67b84b;
    color: #fff;
    text-align: center;
    font: 14px Segoe UI;
    margin-top: -1px;
    margin-bottom: -30px;
    padding-top: 5px;
    margin-right: 10px;
    margin-top: 4px;
  
  }

  .helpTourAll{
  height: 165px;
  }

  .helpTourAll .formBtns {
    padding-top: 0px;
    margin-top: 20px;
    border-top: none;

    p{
      float:none;
    }
  }
//   .helpTourAll button {
//     padding: 0 25px;   
//     width: 84px;
//     height: 38px;
//     line-height: 24px;
//     height: 38px;
//     border-radius: 3px;
//     font-family: Segoe UI Bold;
//     font-size: 13px;
//     font-weight: 700;
//     text-transform: uppercase;
//     vertical-align: middle;
// }





.helpTourCommon .modal-dialog .modal-header {
    border-bottom: none !important;
}
 .helpTourCommon .modal-dialog .modal-content {
   border-radius: 0px !important;
   }

  
.helpTourCommon .modal-dialog .modal-content .modal-body .stepContent  {
display:flex;
}
.helpTourCommon .modal-dialog .modal-content .modal-body .stepContent span {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 10px;
  padding-top: 10px;
  background: #67b84b;
  border: 1px solid #67b84b;
  color: #fff;
  text-align: center;
  font: 14px Segoe UI;
  margin-top: -1px;
  margin-bottom: -30px;
  padding-top: 5px;
  margin-right: 10px;
  margin-top: 4px;

}
.helpTourCommon .modal-dialog .modal-content .modal-footer button {
    padding: 0 25px;   
    width: 84px;
    height: 38px;
}
.helpTourStep1 .modal-dialog .modal-content {
    width: 389px;
    height: 175px;
    left: 202px;
    top: -190px;
}

.helpTourStep1 .modal-dialog .modal-content::before {
    content: "";
    position: absolute;
    top: -14px;
    left: 209px;
    z-index: 1;
    border: 7px solid transparent;
    border-bottom-color: transparent;
    border-bottom-color: transparent;
    border-bottom-color: #fff;
}
/*------if buy now button is hidden---*/
.helpTourStepWithoutBuynow1 .modal-dialog .modal-content {
  width: 389px;
  height: 175px;
  left: 300px;
  top: 22px;
  position: absolute;
}

.helpTourStepWithoutBuynow1 .modal-dialog .modal-content::before {
  content: "";
  position: absolute;
  top: -14px;
  left: 350px;
  z-index: 1;
  border: 7px solid transparent;
  border-bottom-color: transparent;
  border-bottom-color: transparent;
  border-bottom-color: #fff;
}
/*------if buy now button is hidden ends---*/

.helpTourStep2 .modal-dialog .modal-content {
     width: 380px;
     height: 195px;
    // left: -190px;
    // top: -20px;
   transition-duration: 1s;
 
}

.helpTourStep2 .modal-dialog .modal-content::before {
    content: "";
    position: absolute;
    top: 20px;
    left: -7px;
    z-index: 1; 
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent; 
    border-right:7px solid #fff;
}

.helpTourStep3 .modal-dialog .modal-content {
    
     width: 380px;
     height: 175px;
    left: -120px;
    top: 180px;
     transition-duration: 1s;
}

.helpTourStep3 .modal-dialog .modal-content::before {
    content: "";
    position: absolute;
    top: 173px;
    left: 40px;
    z-index: 1;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent; 
    border-top: 7px solid #fff;
}

.helpTourStep4 .modal-dialog .modal-content {
    
     width: 360px;
     height: 193px;
    left: 100px;
    top: 100px;
     transition-duration: 1s;
}

.helpTourStep4 .modal-dialog .modal-content::before {
    content: "";
    position: absolute;
    top: -14px;
    left: 50px;
    z-index: 1;
    border: 7px solid transparent;
     border-bottom-color: transparent;
    border-bottom-color: transparent;
    border-bottom-color: #fff;
}
.helpTourStep5 .modal-dialog .modal-content {
    
     width: 375px;
     height: 195px;
    left: 300px;
    top: 15px;
     transition-duration: 1s;
}

.helpTourStep5 .modal-dialog .modal-content::before {
    content: "";
    position: absolute;
    top: 25px;
    left: -7px;
    z-index: 1;
  
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent; 
    border-right:7px solid #fff; 
}
.helpTourStep6 .modal-dialog .modal-content {
    
     width:365px;
     height: 195px;
    left: 370px;
    top: 85px;
     transition-duration: 1s;
}

.helpTourStep6 .modal-dialog .modal-content::before {
    content: "";
    position: absolute;
    top: 25px;
    left: -7px;
    z-index: 1;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent; 
    border-right:7px solid #fff; 
}
.helpTourStep7 .modal-dialog .modal-content {
     width: 365px;
     height: 195px;
     left: 86px;
     top: 224px;
      transition-duration: 1s;
}

.helpTourStep7 .modal-dialog .modal-content::before {
    content: "";
    position: absolute;
    top: 150px;
    left: 363px;
    z-index: 1;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent; 
    border-left:7px solid #fff; 
}
.helpTourStep8 .modal-dialog .modal-content {
    
     width: 400px;
     height: 170px;
     left: 220px;
     top:-7px;
      transition-duration: 1s;
}

.helpTourStep8 .modal-dialog .modal-content::before {
    content: "";
    position: absolute;
    top: 35px;
    left: -7px;
    z-index: 1;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent; 
    border-right:7px solid #fff; 
}
.helpTourStep9 .modal-dialog .modal-content {
    
     width: 375px;
     height: 170px;
     left: -50px;
     top:5px;
      transition-duration: 1s;
}

.helpTourStep9 .modal-dialog .modal-content::before {
    content: "";
    position: absolute;
    top: 35px;
    left: -7px;
    z-index: 1;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent; 
    border-right:7px solid #fff; 
}
.helpTourStep10 .modal-dialog .modal-content {
    
     width: 375px;
     height: 170px;
     left: 100px;
     top: 80px;
      transition-duration: 1s;
}

.helpTourStep10 .modal-dialog .modal-content::before {
    content: "";
    position: absolute;
    top: 35px;
    left: -7px;
    z-index: 1;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent; 
    border-right:7px solid #fff; 
}
.helpTourStep11 .modal-dialog .modal-content {
    
     width: 420px;
     height: 190px;
     left: 268px;
     top:11px;
      transition-duration: 1s;
}

.helpTourStep11 .modal-dialog .modal-content::before {
    content: "";
    position: absolute;
    top: 65px;
    left: -7px;
    z-index: 1;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent; 
    border-right:7px solid #fff; 
}
.helpTourStep12 .modal-dialog .modal-content {
    
     width: 420px;
     height: 170px;
     left: 350px;
     top: 135px;
      transition-duration: 1s;
}

.helpTourStep12 .modal-dialog .modal-content::before {
    content: "";
    position: absolute;
    top: 35px;
    left: -7px;
    z-index: 1;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent; 
    border-right:7px solid #fff; 
}

.helpTourStep13{
  z-index: 10000;
}
.helpTourStep13 .modal-dialog .modal-content {
    
     width: 210px;
     height: 170px;
     left: 360px;
     top: 0px;
      transition-duration: 1s;
}

.helpTourStep13 .modal-dialog .modal-content::before {
    content: "";
    position: absolute;
    top: 35px;
    left: -7px;
    z-index: 1;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent; 
    border-right:7px solid #fff; 
}
.helpTourStep14 .modal-dialog .modal-content {
    
     width: 360px;
     height: 170px;
     left: 390px;
     top: -84px;
      transition-duration: 0.3s;
}

.helpTourStep14 .modal-dialog .modal-content::before {
    content: "";
    position: absolute;
    top: 168px;
    left: 308px;
    z-index: 1;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #fff;
}
.helpTourStep15 .modal-dialog .modal-content {
    
     width: 400px;
     height: 170px;
     left: 375px;
     top: 200px;
      transition-duration: 0.5s;
}

.helpTourStep15 .modal-dialog .modal-content::before {
  content: "";
  position: absolute;
  top: 168px;
  left: 350px;
  z-index: 1;
  border-left: 7px solid
  transparent;
  border-right: 7px solid
  transparent;
  border-top: 7px solid
  #fff;
}
.helpTourStep16 .modal-dialog .modal-content {
    
     width: 400px;
     height: 195px;
     left: 70px;
     top: 200px;
      transition-duration: 0.31s;
}

.helpTourStep16 .modal-dialog .modal-content::before {
  content: "";
  position: absolute;
  top: 193px;
  left: 100px;
  z-index: 1;
  border-left: 7px solid
  transparent;
  border-right: 7px solid
  transparent;
  border-top: 7px solid
  #fff;
}
.helpTourStep17 .modal-dialog .modal-content {
    
     width: 400px;
     height: 175px;
     right: 264px;
     top: -106px;
      transition-duration: 1s;
}
.helpTourStep17 .modal-dialog .modal-content::before {
    content: "";
    position: absolute;
    top: 35px;
    left: -7px;
    z-index: 1;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent; 
    border-right:7px solid #fff; 
}
.helpTourStep18 .modal-dialog .modal-content {
    
     width: 400px;
     height: 195px;
     left: 350px;
     top: 200px;
      transition-duration: 1s;
}

.helpTourStep18 .modal-dialog .modal-content::before {
    content: "";
    position: absolute;
    top: 35px;
    left: -7px;
    z-index: 1;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent; 
    border-right:7px solid #fff; 
}
.helpTourStep19 .modal-dialog .modal-content {
    
     width: 420px;
     height: 175px;
     left: 0px;
     top: -147px;
      transition-duration: 1s;
}
.helpTourStep19 .modal-dialog .modal-content .modal-footer button {
    padding: 0 25px;   
    width: 100px;
    height: 38px;
}


/* for---------HelpTour Start- end---------*/


/*widget thumbnail css*/

@media (min-width: 1250px){

  .mailer .modal-dialog .getcodetextarea{
    width:630px;
  } 


.previewsave .widget-preview .widget-preview-text {
  font-size: 10px;
  margin-left: -6px;
  position: absolute;
  top: 40px;
  left: 1px;
}

.previewsave .widget-preview .widget-preview-title {
  font-size: 11px;
  position: absolute;
  top: 4px;
  left: -4px;
}

}
@media (max-width: 1250px){
    
  .mailer .modal-dialog .getcodetextarea{
    width:630px;
  }

    .previewsave .widget-preview .widget-preview-text {
      font-size: 10px;
      top: 36px;
      position: absolute;
      left: 1px;
  }
    
    .previewsave .widget-preview .widget-preview-title {
      font-size: 11px;
      top: 1px;
      position: absolute;
      left: -4px;
  }


  }


@media (max-width: 1150px){
.previewsave .widget-preview .widget-preview-title {
  font-size: 11px;
  position: absolute;
  top: -2px;
  left: -4px;
}


.previewsave .widget-preview .widget-preview-text {
  font-size: 9px;
  margin-left: -6px;
  position: absolute;
  top: 30px;
  left: -6px;
}

}

@media (max-width: 850px){
  .mailer .modal-dialog .getcodetextarea{
    width:100%;
  }

  .websiteWidgetGetCode .codeWrapper  .formBtns Button.btn_clr{
    margin-top:4px;
  }
  .expire_account{
    display: none !important;
  }
}


@media screen 
  and (min-width: 768px)
  and (max-width: 810px){
.previewsave .widget-preview .widget-preview-title {
    font-size: 8px;
    top: -6px;
    position: absolute;
    left: -4px;
}

.previewsave .widget-preview .widget-preview-text {
    font-size: 7px;
    position: absolute;
    top: 20px;
    left: -22px;
}



}

@media screen 
  and (min-width: 814px)
  and (max-width: 945px){
.preview-title {
  font-size: 9px;
  top: -4px;
  position: absolute;
  left: -4px;
}

.previewsave .widget-preview .widget-preview-text {
  font-size: 7px;
  top: 26px;
  position: absolute;
  left: -15px;
}

  }
 @media screen 
  and (min-width: 708px)
  and (max-width: 767px){

.previewsave .widget-preview .widget-preview-title {
    top:4px;
    position: absolute;
}

.previewsave .widget-preview .widget-preview-text {
    font-size: 8px;
    margin-left: -5px;
    position: absolute;
    top: 38px;
    left: -9px;
 }
}

@media screen 
  and (min-width: 588px)
  and (max-width: 708px){

.previewsave .widget-preview .widget-preview-title {
    top: 0px;
    left: -4px;
    position: absolute;
}

.previewsave .widget-preview .widget-preview-text {
    
    top: 30px;
    position: absolute;
    left: -14px;
}

}
@media (min-width:800px){
.website_drp {margin-left:-55px}
.infoTranscript .row .chat_p{

    }
}
@media screen 
  and (min-width: 300px)
  and (max-width: 325px){
  
  .infoTranscript .row .chat_p{
      margin-left: -15px !important;
      }

    }  
@media (max-width:414px){
  
  .dynamic_checkbx{
    margin-left:-17px;

  }
  .infoTranscript .row .chat_p{
      margin-left: -20px;
      }
  .top_btn {
      margin-left: 8px;
      }   
      .table_td table tr td:nth-child(2) p 
{
 font-family: "Segoe UI" !important;
 width: 100%;
}
}

@media screen 
  and (min-width: 651px)
  and (max-width: 800px){

   .settingFormWrapper .website_drp .form-label {
       padding-left: 56px;
     
       }
   .top_btn {
   margin-left: 90px;

   }

}

@media (min-width:1024px){
  
.top_refBtn{
  margin-left: 51px;
  }
  

}
@media screen 
  and (min-width: 577px)
  and (max-width: 667px)
{
.customDrpDwn .css-10nd86i {
    width: 100%;
	}
	
	}
@media screen 
  and (min-width: 1000px)
  and (max-width: 1025px){

.website_drp {
    margin-left: -30px;
}

.custom_btn{
   padding: 0px 6px;
   font-size:12px;
}
.top_refBtn {
    margin-left: 30px;
}
.archiveDropDwn .css-10nd86i {
     width:200px;
    margin-left: 12px;
           }
.archiveFormWrapper .form-control {
    width:200px !important;
    }

}
/* for--------archiveChat and PopUp--------------end-----------*/


html {
  scroll-behavior: smooth;
  background-color: #f6f6f6;
  //height: 100%;
  //weight: 100%;
  //overflow: hidden;
  //position: relative;
}

// #root {
//   //position: absolute;
//   //top: 0;
//   //left: 0;
//   //right: 0;
//   //bottom: 0;
//   //overflow: auto;
// }

.cursor-pointer {
    cursor: pointer;
}
.App {
  text-align: center;
  background-color: #f6f6f6;
}

.pull-right {
  float: right;
}
.pull-left {
  float: left!important;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.pr-10{
  padding-right: 10px;
}
#sidebarCollapse {
    width: 40px; 
    height: 40px;
    background: #f5f5f5;
    cursor: pointer;
    display: none;
    border: none;
}
#sidebarCollapse span {
    width: 80%;
    height: 2px;
    margin: 0 auto;
    display: block;
    background: #555;
    transition: all 0.8s cubic-bezier(0.810, -0.330, 0.345, 1.375);
    transition-delay: 0.2s;
    transform: none;
    opacity: 1;
    margin: 5px auto;
}


.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    padding-top:61px;
}

#sidebar {
  min-height: calc(100vh - 65px);
  color: #ffffff;
  min-width: 301px;
  max-width: 301px;
  margin-right: 0;
  position: fixed;
  z-index: 999;
}

#sidebar.active {
  min-width: 105px;
  max-width: 105px;
  margin-right: 15px;
}

#sidebar >ul{
  margin-bottom: 0;
}

#sidebar >ul.sideMenu{
  position: relative;
  background-color: rgb(59, 168, 197);
  width: 105px;
  height: calc(100vh - 61px);
  z-index: 3;
}
#sidebar >ul.sideMenu li{
  padding: 20px 0;
  border-bottom: 1px solid rgba(255,255,255,.4);
  cursor: pointer;
}

#sidebar >ul.sideMenu li.active{
  background-color: rgb(11, 145, 180);
}
#sidebar >ul.sideMenu li p{
  margin: 14px 0 0;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Helvetica Neue Cyr Medium';
  letter-spacing: 0.24px;
}

#sidebar .submenu_wrapper {
  background-color: #242831;
  min-width: 195px;
  max-width: 195px;
  transition: all 0.3s;
  z-index: 2;
}
#sidebar .subMenu{
  background-color: #363a45;
  height: fit-content;
  max-height: calc(100vh - 250px);
}
#admin_page{
  overflow-y: auto;
  scrollbar-color: #3ba8c5 transparent;
  scrollbar-width: thin;
}
#sidebar .submenu_wrapper.active  {
    margin-left: -195px;
    transition: none;
}

#sidebar .submenu_wrapper .logoIcon{
  padding: 16px 0;
  border-bottom: 1px solid #66696f;

}
#sidebar .subMenu li.parent_list{
  border-bottom: 1px solid #66696f;
}
#sidebar .subMenu li.parent_list.menu_open{
  border-bottom: none;
}
#sidebar .subMenu li.parent_list >a{
  color: #f7f5f5;
  font-family: 'Segoe UI Bold';
  font-size: 14px;
  font-weight: 700;
}
#sidebar .subMenu li.parent_list >a:hover{
  text-decoration: none;
}
#sidebar .subMenu li.parent_list >a.dropdown-toggle::after{
  display: none;
}
#sidebar .subMenu li.parent_list >a.dropdown-toggle p{
  background-image: url("assets/images/openMenu.png");
  background-repeat: no-repeat;
  background-position: right;
}
#sidebar .subMenu li.parent_list >a.dropdown-toggle.collapsed p{
  background-image: url("assets/images/closeMenu.png");
  
}
#sidebar .subMenu li.parent_list >ul.list_menu {
  @include padding-direction(bottom, 6px);
}
#sidebar.operator_menu .subMenu li.parent_list >ul.list_menu.submenu_operator {
  height: 250px; 
  margin-right: -15px;
  margin-left: -15px;
  .operatorsWrapper {
    padding-bottom: 0;
  }
}

#sidebar .subMenu li.parent_list >ul li p{
  background-image: url("assets/images/menuBullet.png");
  background-repeat: no-repeat;
  background-position: left;
  padding-left: 16px;
  margin-bottom: 0;
  text-align: left;
  margin-left: 22px;
  font-family: 'Segoe UI Semi Bold';
  font-size: 13px;
  font-weight: 400;
  color: #a8d3de;
  line-height: 25px;
}
#sidebar .subMenu li.parent_list >ul li.active{
  background-color: #0b91b5;
}
#sidebar .subMenu li.parent_list >ul li.active p{
  color: #ffffff;
  font-family: 'Segoe UI Semi Bold';
  font-weight: 600;
}
#sidebar .subMenu li.parent_list >a >p{
  margin: 21px 18px 20px 17px;
  text-align: left; 
}

#sidebar .submenu_wrapper .icon_wrapper{
  width: 44px;
  height: 44px;
  // border: 2px solid #ffffff;
  border: 0px solid #ffffff;
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  line-height: 37px;
  margin: 0;
  color : #fff;
}
#sidebar .submenu_wrapper .icon_wrapper .imgweb{
  width: 44px;
  height: 44px;
  // border: 2px solid #ffffff;
  border: 0px solid #ffffff;
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  line-height: 37px;
 // margin: 0px 0px -6px;
 margin: 0px 0px -2px;
  color : #fff;
}
.submenudropdwnp {
  white-space: nowrap;
}
.hideIcon{
  position: fixed;
  bottom: 0;
  left: 105px;
  margin: 0;
  height: 50px;
  padding-left: 22px;
  cursor: pointer;
  width: 195px;
  border-top: 1px solid #363a45;
  /*transition-delay: 0.5s;*/
  transition: all 0.3s;
  background-color: #242831;
  z-index: 4;
}

.hideIcon p{
  font-size: 12px;
  padding-left: 10px;
  margin: 0;
  font-family: 'Helvetica Neue Cyr Medium';
  font-weight: 500;
  color: #a8d3de;
}

.hideIcon.inactive{
  width: 50px;
  height: 50px;
  background-color: #242831;
}


.menu-right >img:nth-child(1){
  margin-right: 31px;
}

.menu-right >div{
  float: left;
}

.innercontent_wrapper {
  background-color: #f6f6f6;
  @include padding-direction(bottom,50px);

  .box{
    box-shadow: 0 0 5px rgba(13, 16, 21, 0.19);
    border-radius: 20px;
    background-color: #ffffff;
    @include padding(50px);
    &.overview {
       @include padding(28px);
    }
  }
}

.settings_wrapper {
  background-color: #f6f6f6;
  padding-bottom: 50px;
}

.header_title{
  height: 97px;
  padding-left: 26px;
}
.header_title h4{
  font-family: 'Helvetica Neue Cyr Roman';
  margin-bottom: 0;
  margin-left: 15px;
  font-size: 22px;
}
.settings_wrapper .box{
  box-shadow: 0 0 5px rgba(13, 16, 21, 0.19);
  border-radius: 20px;
  background-color: #ffffff;
  padding: 30px;
}
.settingFormWrapper p{
  font-family: 'Segoe UI';
  font-size: 13px;
  margin: 16px 0;
}

.settingFormWrapper form p{
  font-family: 'Segoe UI Bold';
  font-weight: 700;
}
.settingFormWrapper,.innerContentWrapper{
  margin: 0 18px;
}
.settingFormWrapper .form-label{
  text-align: left;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400;
}
.settingFormWrapper {
  .form-control{
    width: 256px;
    color: #000000;
    font-family: 'Segoe UI Semi Bold';
    font-size: 14px;
    font-weight: 400;
  }
  .customInputForm {
    .form-control, .customDrpDwn .css-10nd86i {
      width: 320px;
    }
  }
}
.innerForm{
  margin-top: 35px;
}

.innerForm .form-group >div {
  padding-left: 0;
}

.image_upload button{
  width: 138px;
  height: 29px;
  border-radius: 3px;
  border: 1px solid #3ba8c5;
  background-color: #ffffff;
  float: left;
    input {
        width: 138px;
        height: 30px;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }
  }
.image_upload p{
  color: #3ba8c5;
  font-family: 'Segoe UI Bold';
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  margin: -2px 0;
}
.profileImg{
  padding-top: 14px;
}
.profileImg .img_delete p:first-child{
  padding: 0 14px 0 19px;
  color: #e44e4e;
  font-family: 'Segoe UI Bold';
  font-size: 23px;
  font-weight: 400;
  line-height: 25.46px;
  letter-spacing: 0.64px;
  margin-top: -9px;
}
.profileImg .img_delete p:last-child{
  color: #3ba8c5;
  font-family: 'Helvetica Neue Cyr Medium';
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.28px;
  margin-left: -13px;
}
.profileImg .img_delete p:first-child.rotate{
  transform: rotate(45deg);
  /* Safari */
  -webkit-transform: rotate(45deg);

  /* Firefox */
  -moz-transform: rotate(45deg);

  /* IE */
  -ms-transform: rotate(45deg);

  /* Opera */
  -o-transform: rotate(45deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}

.profileImg img, .profileImg p{
  float: left;
}
.custom_radioBtn.col {
  max-width: fit-content;
}
.custom_radioBtn .btn{
  width: 61px;
  height: 27px;
  border-radius: 14px;
  border: 1px solid #dedede;
  border-color: #dedede;
  background-color: #e7e7e7;
  margin-top: 6px;
  margin-bottom: 0;
}
.custom_radioBtn label{
  position: relative;
}
.custom_radioBtn input{
  opacity: 0;
}
.custom_radioBtn .btnText{
  position: absolute;
  top: -12px;
  right: 7px;
  color: #000000;
  font-family: 'Segoe UI';
  font-size: 11px;
  font-weight: 700;
  transition: all 0.3s;
}
.custom_radioBtn .btnText.active{
  color: #ffffff;
  right: 0;
  left: -21px;
}
.custom_radioBtn .btnIcon{
  position: absolute;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #ffffff;
  background-image: linear-gradient(-45deg, #e2e7e7 0%, #ffffff 100%);
  top: 4px;
  left: 6px;
  transition: all 0.3s;
}
.custom_radioBtn .btn-primary:focus, .btn-primary.focus{
  box-shadow: none;
}
.custom_radioBtn .btnIcon.active{
  left: 37px;
}
.custom_radioBtn .btn-primary:not(:disabled):not(.disabled):active, .custom_radioBtn .btn-primary:not(:disabled):not(.disabled).active, .show > .custom_radioBtn .btn-primary.dropdown-toggle{
  background-color: #aac3a1;
  border-color: #dedede;
}

.formBtns{
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #dfdfdf;
  padding-right: 0;
}
.formBtns .pullRight {
  float: right;
}
.formBtns Button{
  width:auto;
  min-width: 90px;
  height: 38px;
  margin-left: 10px;
  border-radius: 3px;
}
.formBtns Button.btn_clr{
  border: 1px solid #3ba8c5;
  background-color: #ffffff;
  color: #3ba8c5;
}

.formBtns Button.btn_save{
  background-color: #3ba8c5;
  border-color: transparent;
}
.formBtns Button p{
    font-family: 'Segoe UI Bold';
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    margin: -2px 0;
}

.overlay {
    display: none;
    position: fixed;
    /* full screen */
    width: 100vw;
    height: 100vh;
    /* transparent black */
    background: rgba(0, 0, 0, 0.7);
    /* middle layer, i.e. appears below the sidebar */
    z-index: 998;
    opacity: 0;
    /* animate the transition */
    transition: all 0.5s ease-in-out;
    top: 0;
}
/* display .overlay when it has the .active class */
.overlay.active {
    display: block;
    opacity: 1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.menuClose.close{
  color: #a8d3de;
  margin-right: 5px;
}

@media (min-width: 574px) and (max-width: 767px) {
  .App .divider {
    display: none!important;
  }
  .header .logo-img {
    width: 65%;
  }
  .App .checkout {
    display: none!important;
  }
  .App .cart {
    display: block!important;
  }
}

@media (max-width: 990px) {
  /*.wrapper {
    display: block;
  }*/
/*
  .submenu_wrapper {
    height: 100vh;
    position: absolute;
    top: 61px;
    left: 96px;
    z-index: 9999;
}*/
.logo_wrapper{
  padding-left: 0;
}
.notification{
    margin: 0 20px;
}
.profileStatus {
    // margin-right: 20px;
}
.profileStatus .status p {
    font-size: 13px;
  }
  .profileStatus .status p.text-active{
    font-size: 12px;
  }
.checkout{
  margin-left: 0;
  padding: 22px 18px 18px 18px;
}
#sidebarCollapse{
  display: block;
}
.header_title {
    height: 65px;
    padding-left: 26px;
}
#sidebar >ul.sideMenu{
  margin-left: 15px;
  min-height: 552px;
  height: 100vh;
}
#sidebar {
    .hideIcon {
      display: none;
      padding-top: 17px;
    }
}
#sidebar .subMenu{
  min-height: fit-content;
  overflow-y: initial;
  margin-bottom: 0;
}
#sidebar.open,#sidebar.active {
    min-width: 318px;
    max-width: 318px;
}
#sidebar.open {
  left: 0;

  .hideIcon {
    display: block;
    transition: all 0.3s;
  }
}
#sidebar .submenu_wrapper.active {
    margin-left: auto;
}
.main_content {
  margin-left: -15px;
  &.menu_active {
    margin-left:0;
  }
}
  .settingFormWrapper{
    margin-right: 0;
  }
  .settingFormWrapper .form-label, .customDrpDwn .css-10nd86i, .image_upload p, .profileImg .img_delete p:last-child{
    font-size: 12px;
  }
  .profileImg .img_delete p:first-child {
    padding: 0 9px 0 10px;
  }
  .profileImg .img_delete p:last-child{
    margin-left: -8px;
  }

  .conditionComponent {
    .row {
      >div{
        &:first-child {
          padding-right: 0;
        }
        &:nth-child(2){
          padding-right: 0;
          padding-left: 0;
        }
        &:nth-child(3){
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
 
}

@media (max-width: 576px){

   .invitationRulesWrapper .editRulesSection .conditions button {
       @include margin-direction(top,10px);
    }

     .invitationRulesWrapper {
      .editRulesSection {
        .customFormGroup {
          .customInput {
            width: 42px; }
              .customDrpDwn .css-10nd86i {
                width: 184px;
              }

          > span {
            @include margin-direction(left,5px);
            &:nth-child(2) {
              @include margin-direction(right,5px);
            }
          }
          > div {
            &:nth-child(3) {
              @include padding(0 0 0 8px);
            }
          }
        }
        }
  }
  
  .innercontent_wrapper {
    .box{
      padding: 30px 20px;
      margin-right: 0;
    } 
  }

  .overview {
    p,li{
      text-align: justify !important;
    }
  }
    #sidebarCollapse {
      width: 30px;
      height: 30px;
      padding: 1px 3px;
      border: none;
  }
  .logo_wrapper {
    padding-left: 0;
    margin-left: 10px;
    padding-right: 0;
  }
  .header{
    margin-bottom: 3px;
  }
    .innerForm .form-group >div {
      padding-left: 15px;
    }
     .settings_wrapper .box {
      padding: 20px;
    }
    .menu-right .row{
      margin-right: 0;
    }
    .notification {
      margin: 0 10px;
    }
    .profileStatus {
      // margin-right: 5px;
      // margin-top: 13px;
      margin-right: 10px;
      margin-top: 11px;
    }
    .profileStatus .avatar{
      margin-right: 0;
    }
    .formBtns{
      padding-top: 20px;
      margin-top: 20px;
      padding-right: 15px;
    }
    .formBtns .pullRight {
      float: none;
    }

    .formBtns Button.btn_clr {
      margin-left: 0;
    }
    .formBtns Button {
      width: 71px;
      height: 34px;
    }
    
    .bg_circle {
      @include circle(35px,rgba(255,255,255,0),1px);
    }
    .green_circle, .red_circle{
      width: 35px;
      height: 35px;
      border-width: 1px;
    }
    .profileStatus .status{
      float: none;
    }
    .profileStatus .status p{
      font-size: 12px;
    }
    .cart {
      margin-top: -8px;
    }
    .profileStatus .status .status-img {
      right: -10px;
      bottom: 7px;
    }
    .conditionComponent {
    .row {
      >div{
        &:first-child {
          padding-right: 15px;
        }
        &:nth-child(2){
          padding-right: 15px;
          padding-left: 15px;
        }
        &:nth-child(3){
          padding-left: 15px;
          padding-right: 0px;
        }
      }
    }
  }
}
@media (max-width: 420px) {
  .custome_company_name {
    width : 100px;
    overflow:hidden;
    display:inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .custome_company_name:hover{
    overflow: visible; 
    white-space: normal; 
}
.innercontent_wrapper .downbanner{
  color:white;
  background-color:red; 
  width:100%; 
  margin-left:5px; 
  padding:0px 0px 0px 0px; 
  border-radius:5px;
  margin-top:10px;
}
}
.innercontent_wrapper .downbanner{
  color:white;
  background-color:red; 
  width:55%; 
  margin-left:-25px; 
  padding:0px 0px 0px 0px; 
  border-radius:5px;
  margin-top:10px;
}
@media (max-width: 400px) {
  .innercontent_wrapper .downbanner{
    color:white;
    background-color:red; 
    width:100%; 
    margin-left:5px; 
    padding:0px 0px 0px 0px; 
    border-radius:5px;
    margin-top:10px;
  }
}
@media (max-width: 350px) {
  .innercontent_wrapper .box,.innercontent_wrapper .traffic_wrapper .box, .scriptWrapper.innercontent_wrapper .box, .settings_wrapper .box {
    padding: 20px 15px;
  }
  .innercontent_wrapper .downbanner{
    color:white;
    background-color:red; 
    width:100%; 
    margin-left:5px; 
    padding:0px 0px 0px 0px; 
    border-radius:5px;
    margin-top:10px;
  }
  .innercontent_wrapper {
    .profile_wrapper{
      .available_zone {
        .custom_radioBtn.col {
          padding-right: 6px;
        }

        .day_wrapper {
          p.day_label{
            font-size: 12px;
            width: 62px;
          }
          input {
            @include margin-direction(left,5px);
          }
        }
      }
    }
  }
  .schedule_zone{
    .day_wrapper {
      p.day_label{
        font-size: 12px;
        width: 62px;
      }
      input {
        @include margin-direction(left,5px);
      }
    }
  }
}

@media (max-width: 375px) {
  .innerForm {
    .customDrpDwn .css-10nd86i, .form-control {
      width: 100%;
    }
  }
  .editRulesSection {
    .customDrpDwn {
      @include padding-direction(right,0);
      width: 100%;
      .css-10nd86i {
        width: 100%!important;
      }
    }
  }
  .settingFormWrapper .form-control {
    width : 100%;
    max-width: 256px;
  }
}
@media (max-width: 780px) {
  .headerbellicon{
   padding-right: 0px !important;
  }
  .headerspeakericon{
   margin-right: 0px !important;
  }
}

@media (min-width: 1024px) {
 .invitationRulesWrapper {
  .editRulesSection {
    .customFormGroup {
      .customDrpDwn .css-10nd86i {
        width: 235px;
      }

      > span {
        @include margin-direction(left,8px);
        &:nth-child(2) {
          @include margin-direction(right,8px);
        }
      }
      > div {
        &:nth-child(3) {
          @include padding(0 0 0 8px);
        }
      }
    }
    }
  }
.innercontent_wrapper {
    .profile_wrapper {
      .customDrpDwn .css-10nd86i {
        width: 230px;
      }
      .settingFormWrapper .form-control {
          width: 230px;
        }

    }
  }
}

[data-tooltip] {
  position: relative;
  //margin-right: -190px;
  &:before,
  &:after {
    opacity: 0;
    position: absolute;
    left: 50%;
    backface-visibility: hidden
  }
  &:before {
    border-style: solid;
    border-top-width: 4px;
    border-right-width: 4px;
    border-bottom-width: 4px;
    border-left-width: 4px;
    border-bottom-width: 0;
    content: '';
    top: -2px;
    width: 0;
    height: 0;
    transform: translate(-50%, calc(-50% - 6px));
    transition: opacity .1s cubic-bezier(.73, .01, 0, 1) 0s, transform .6s cubic-bezier(.73, .01, 0, 1) 0s, -webkit-transform .6s cubic-bezier(.73, .01, 0, 1) 0s;
    z-index: 110000
  }
  &:after {
    content: attr(data-tooltip);
    text-align: center;
    color:#ecae10 !important;
    padding: 10px 10px 9px;
    font-size: 12px;
    border-radius: 8px;
    // color: $tooltip-base-text-color;
    transition: opacity .3s cubic-bezier(.73, .01, 0, 1), transform .3s cubic-bezier(.73, .01, 0, 1), -webkit-transform .3s cubic-bezier(.73, .01, 0, 1);
    pointer-events: none;
    z-index: 999;
    white-space: nowrap;
    bottom: 100%;
    transform: translate(-50%, 12px);
    max-width: 320px;
    text-overflow: ellipsis;
    overflow: hidden
  }
}

[data-tooltip]:after {
  background: $tooltip-base-bg-color;
}

[data-tooltip]:before {
  border-top-color: $tooltip-base-bg-color;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: $tooltip-base-border-color;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: $tooltip-base-border-color;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: $tooltip-base-border-color;
}

[data-tooltip] {
  &:focus,
  &:hover {
    &:before,
    &:after {
      opacity: 1;
    }
  }
  &:focus,
  &:hover {
    &:before {
      transition: opacity .1s cubic-bezier(.73, .01, 0, 1) .1s, transform .6s cubic-bezier(.73, .01, 0, 1) .1s, -webkit-transform .6s cubic-bezier(.73, .01, 0, 1) .1s;
      transform: translate(-50%, calc(-50% - 2px));
    }
    &:after {
      transform: translate(-50%, -6px);
    }
  }
}

[data-tooltip][data-tooltip-conf*=right]:before {
  border-style: solid;
  border-top-width: 4px;
  border-right-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-left-width: 0;
  left: auto;
  right: -6px;
  top: 50%;
  transform: translate(calc(-50% + 7px), -50%)
}

[data-tooltip][data-tooltip-conf*=right]:after {
  top: 50%;
  left: 100%;
  bottom: auto;
  -webkit-transform: translate(-12px, -50%);
  transform: translate(-12px, -50%)
}

[data-tooltip][data-tooltip-conf*=right]:focus:before,
[data-tooltip][data-tooltip-conf*=right]:hover:before {
  -webkit-transform: translate(calc(-50% + 3px), -50%);
  transform: translate(calc(-50% + 3px), -50%)
}

[data-tooltip][data-tooltip-conf*=right]:focus:after,
[data-tooltip][data-tooltip-conf*=right]:hover:after {
  -webkit-transform: translate(7px, -50%);
  transform: translate(7px, -50%)
}

[data-tooltip][data-tooltip-conf*=bottom]:before {
  border-style: solid;
  border-top-width: 4px;
  border-right-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-top-width: 0;
  top: auto;
  bottom: -6px;
  left: 50%;
  -webkit-transform: translate(-50%, calc(-50% + 6px));
  transform: translate(-50%, calc(-50% + 6px))
}

[data-tooltip][data-tooltip-conf*=bottom]:after {
  top: 100%;
  left: 50%;
  bottom: auto;
  -webkit-transform: translate(-50%, -12px);
  transform: translate(-50%, -12px)
}

[data-tooltip][data-tooltip-conf*=bottom]:focus:before,
[data-tooltip][data-tooltip-conf*=bottom]:hover:before {
  -webkit-transform: translate(-50%, calc(-50% + 2px));
  transform: translate(-50%, calc(-50% + 2px))
}

[data-tooltip][data-tooltip-conf*=bottom]:focus:after,
[data-tooltip][data-tooltip-conf*=bottom]:hover:after {
  -webkit-transform: translate(-50%, 6px);
  transform: translate(-50%, 6px)
}

[data-tooltip][data-tooltip-conf*=left]:before {
  border-style: solid;
  border-top-width: 4px;
  border-right-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-right-width: 0;
  left: -2px;
  top: 50%;
  width: 0;
  height: 0;
  -webkit-transform: translate(calc(-50% - 8px), -50%);
  transform: translate(calc(-50% - 8px), -50%)
}

[data-tooltip][data-tooltip-conf*=left]:after {
  top: 50%;
  right: 100%;
  bottom: auto;
  left: auto;
  -webkit-transform: translate(12px, -50%);
  transform: translate(12px, -50%)
}

[data-tooltip][data-tooltip-conf*=left]:focus:after,
[data-tooltip][data-tooltip-conf*=left]:hover:after {
  -webkit-transform: translate(-7px, -50%);
  transform: translate(-7px, -50%)
}

[data-tooltip][data-tooltip-conf*=left]:focus:before,
[data-tooltip][data-tooltip-conf*=left]:hover:before {
  -webkit-transform: translate(calc(-50% - 3px), -50%);
  transform: translate(calc(-50% - 3px), -50%)
}

[data-tooltip][data-tooltip-conf*=multiline]:after {
  word-break: break-word;
  white-space: normal;
  min-width: 180px;
  text-overflow: clip
}

[data-tooltip][data-tooltip-conf*=delay]:before {
  -webkit-transition: opacity .2s cubic-bezier(.73, .01, 0, 1) 0s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) 0s;
  transition: opacity .2s cubic-bezier(.73, .01, 0, 1) 0s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) 0s;
  transition: opacity .2s cubic-bezier(.73, .01, 0, 1) 0s, transform .3s cubic-bezier(.73, .01, 0, 1) 0s;
  transition: opacity .2s cubic-bezier(.73, .01, 0, 1) 0s, transform .3s cubic-bezier(.73, .01, 0, 1) 0s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) 0s
}

[data-tooltip][data-tooltip-conf*=delay]:after {
  -webkit-transition: opacity .3s cubic-bezier(.73, .01, 0, 1) 0s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) 0s;
  transition: opacity .3s cubic-bezier(.73, .01, 0, 1) 0s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) 0s;
  transition: opacity .3s cubic-bezier(.73, .01, 0, 1) 0s, transform .3s cubic-bezier(.73, .01, 0, 1) 0s;
  transition: opacity .3s cubic-bezier(.73, .01, 0, 1) 0s, transform .3s cubic-bezier(.73, .01, 0, 1) 0s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) 0s
}

[data-tooltip][data-tooltip-conf*=delay]:focus:before,
[data-tooltip][data-tooltip-conf*=delay]:hover:before {
  -webkit-transition: opacity .2s cubic-bezier(.73, .01, 0, 1) .5s, -webkit-transform .6s cubic-bezier(.73, .01, 0, 1) .5s;
  transition: opacity .2s cubic-bezier(.73, .01, 0, 1) .5s, -webkit-transform .6s cubic-bezier(.73, .01, 0, 1) .5s;
  transition: opacity .2s cubic-bezier(.73, .01, 0, 1) .5s, transform .6s cubic-bezier(.73, .01, 0, 1) .5s;
  transition: opacity .2s cubic-bezier(.73, .01, 0, 1) .5s, transform .6s cubic-bezier(.73, .01, 0, 1) .5s, -webkit-transform .6s cubic-bezier(.73, .01, 0, 1) .5s
}

[data-tooltip][data-tooltip-conf*=delay]:focus:after,
[data-tooltip][data-tooltip-conf*=delay]:hover:after {
  -webkit-transition: opacity .3s cubic-bezier(.73, .01, 0, 1) .4s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) .4s;
  transition: opacity .3s cubic-bezier(.73, .01, 0, 1) .4s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) .4s;
  transition: opacity .3s cubic-bezier(.73, .01, 0, 1) .4s, transform .3s cubic-bezier(.73, .01, 0, 1) .4s;
  transition: opacity .3s cubic-bezier(.73, .01, 0, 1) .4s, transform .3s cubic-bezier(.73, .01, 0, 1) .4s, -webkit-transform .3s cubic-bezier(.73, .01, 0, 1) .4s
}

[data-tooltip][data-tooltip-conf*=shadow]:after {
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, .1)
}

[data-tooltip][data-tooltip-conf*=no-fading]:after,
[data-tooltip][data-tooltip-conf*=no-fading]:before {
  -webkit-transition: none;
  transition: none
}

[data-tooltip][data-tooltip-conf*=no-arrow]:before {
  display: none
}

[data-tooltip][data-tooltip-conf*=square]:after {
  border-radius: 0
}

[data-tooltip][data-tooltip-conf*=invert]:after {
  color: $tooltip-base-text-color;
  background: $tooltip-invert-bg-color;
}

[data-tooltip][data-tooltip-conf*=invert]:before {
  border-top-color: $tooltip-invert-bg-color;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=invert][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: $tooltip-invert-bg-color;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=invert][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: $tooltip-invert-bg-color;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=invert][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: $tooltip-invert-bg-color;
}

[data-tooltip][data-tooltip-conf*=success]:after {
  background: $tooltip-success-bg-color;
}

[data-tooltip][data-tooltip-conf*=success]:before {
  border-top-color: $tooltip-success-bg-color;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=success][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: $tooltip-success-bg-color;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=success][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: $tooltip-success-bg-color;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=success][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: $tooltip-success-bg-color;
}

[data-tooltip][data-tooltip-conf*=info]:after {
  background: $tooltip-info-bg-color;
}

[data-tooltip][data-tooltip-conf*=info]:before {
  border-top-color: $tooltip-info-bg-color;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=info][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: $tooltip-info-bg-color;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=info][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: $tooltip-info-bg-color;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=info][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: $tooltip-info-bg-color;
}

[data-tooltip][data-tooltip-conf*=warning]:after {
  background: $tooltip-warning-bg-color;
}

[data-tooltip][data-tooltip-conf*=warning]:before {
  border-top-color: $tooltip-warning-bg-color;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=warning][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: $tooltip-warning-bg-color;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=warning][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: $tooltip-warning-bg-color;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=warning][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: $tooltip-warning-bg-color;
}

[data-tooltip][data-tooltip-conf*=danger]:after {
  background: $tooltip-danger-bg-color;
}

[data-tooltip][data-tooltip-conf*=danger]:before {
  border-top-color: $tooltip-danger-bg-color;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=danger][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: $tooltip-danger-bg-color;
  border-bottom-color: transparent;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=danger][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: $tooltip-danger-bg-color;
  border-left-color: transparent
}

[data-tooltip][data-tooltip-conf*=danger][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: $tooltip-danger-bg-color;
}

[data-tooltip][data-tooltip=''] {
  &:after,
  &:before {
    display: none
  }
}

/* Discount Ribbon */
.discount-ribbon {
  position: absolute;
  top: 10px;
  left: -20px;
  background: #ff4d4d;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 20px;
  transform: rotate(-15deg);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.discount-ribbon span {
  font-size: 16px;
  font-weight: bold;
  color: #fff000;
}

/* Price Details */
.price-details {
  text-align: center;
  margin-bottom: 10px;
}

/* Original Price */
.original-price {
  font-size: 17px;
  font-weight: bold;
  color: #888;
  margin-bottom: 5px;
}

/* Final Price with Proper Alignment */
.final-price {
  font-size: 13px;
  font-weight: bold;
  color: #222;
  line-height: 1.2;
}

.per-month {
  font-size: 9px;
  font-weight: normal;
  color: gray;
  margin-top: 5px;
}

.features-list {
  text-align: left;
  font-size: 14px; 
  color: #333;
  font-family: "Segoe UI Bold";  
}

.features-list p {
  margin: 2px 0; 
}


/* Price tag */

.pricingCard {
  width: 100%;
  padding: 20px;
  text-align: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.pricingCard:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
}

.priceTag {
  font-size: 24px;
  font-weight: bold;
  color: #28a745;
}

.pricingDetails {
  font-size: 14px;
  color: #555;
  margin-bottom: 15px;
}

.silver {
  border-top: 5px solid #c0c0c0;
}

.gold {
  border-top: 5px solid #ffd700;
}

.platinum {
  border-top: 5px solid #e5e4e2;
}


.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52vh; 
  cursor: pointer;
}
