@import '_mixins';

$themes: (
	default: (
				primary-color: #3ba8c5,
				secondary-color: #0b91b4,
				primary-color-light: #EBF6F9,
				plus-icon: url('assets/images/plus_icons/plus_blue.png'),
				minus-icon: url('assets/images/minus_icons/minus_blue.png')
				
			),
	steelBlue: (
				primary-color: #3ba8c5,
				secondary-color: #0b91b4,
				primary-color-light: #EBF6F9,
				plus-icon: url('assets/images/plus_icons/plus_steelblue.png'),
				minus-icon: url('assets/images/minus_icons/minus_steelBlue.png')
			),
	lightSeaGreen: (
				primary-color: #4cb6ac,
				secondary-color: #2e968c,
				primary-color-light: #EDF8F7,
				plus-icon: url('assets/images/plus_icons/plus_seagreen.png'),
				minus-icon: url('assets/images/minus_icons/minus_lightSeaGreen.png')
			),
	deepPink: (
				primary-color: #ec407a,
				secondary-color: #d22c64,
				primary-color-light: #FDECF1,
				plus-icon: url('assets/images/plus_icons/plus_pink.png'),
				minus-icon: url('assets/images/minus_icons/minus_deepPink.png')
			),
	brown: (
				primary-color: #6e4c42,
				secondary-color: #573930,
				primary-color-light: #F0EDEC,
				plus-icon: url('assets/images/plus_icons/plus_brown.png'),
				minus-icon: url('assets/images/minus_icons/minus_brown.png')
			),
	navyBlue: (
				primary-color: #234e91,
				secondary-color: #0a3a84,
				primary-color-light: #E8EDF4,
				plus-icon: url('assets/images/plus_icons/plus_navyblue.png'),
				minus-icon: url('assets/images/minus_icons/minus_navyBlue.png')
				
			),
	mustardYellow: (
				primary-color: #e89712,
				secondary-color: #d38608,
				primary-color-light: #FDF4E8,
				plus-icon: url('assets/images/plus_icons/plus_yellow.png'),
				minus-icon: url('assets/images/minus_icons/minus_mustardYellow.png')
			),
	violet: (
				primary-color: #b968c7,
				secondary-color: #ab48bf,
				primary-color-light: #F8F0F9,
				plus-icon: url('assets/images/plus_icons/plus_voilet.png'),
				minus-icon: url('assets/images/minus_icons/minus_violet.png')
	
			),
	blue: (
				primary-color: #039be6,
				secondary-color: #1665c1,
				primary-color-light: #E6F5FC,
				plus-icon: url('assets/images/plus_icons/plus_blue.png'),
				minus-icon: url('assets/images/minus_icons/minus_blue.png')
				
			),
	darkGreen: (
				primary-color: #00887a,
				secondary-color: #00695b,
				primary-color-light: #E6F3F1,
				plus-icon: url('assets/images/plus_icons/plus_darkgreen.png'),
				minus-icon: url('assets/images/minus_icons/minus_darkGreen.png')
			),
	green: (
				primary-color: #4b8f4e,
				secondary-color: #2d7d30,
				primary-color-light: #EDF3ED,
				plus-icon: url('assets/images/plus_icons/plus_green.png'),
				minus-icon: url('assets/images/minus_icons/minus_green.png')
				
			),
	grey: (
				primary-color: #5f7c8c,
				secondary-color: #506a78,
				primary-color-light: #EFF2F3,
				plus-icon: url('assets/images/plus_icons/plus_grey.png'),
				minus-icon: url('assets/images/minus_icons/minus_grey.png')
			),
	deepSkyBlue: (
				primary-color: #64a9e0,
				secondary-color: #5699ce,
				primary-color-light: #EFF6FC,
				plus-icon: url('assets/images/plus_icons/plus_skyblue.png'),
				minus-icon: url('assets/images/minus_icons/minus_deepSkyBlue.png')
			)
) !default;

@mixin theme($name, $primary-color, $secondary-color, $primary-color-light,$plus-icon,$minus-icon) {
	.#{$name} {

		.existing_opcreate {
			background-color: $primary-color !important;
			color: #fff !important;
		}
		.toolBarPreviewWrapper{
			.positionIcons {
				&.selected {
					background-color: $primary-color !important;
					border:  1px solid  $primary-color !important;
				}
			}
		}
		.modal-dialog .modal-content .modal-header button.chatInfoBtn {
			border: 1px solid $primary-color !important; 
			background-color: #ffffff !important;
			color: $primary-color !important; 
		}
		.widgetPosition { 
			p {
				span {
					&.selected {
						color: #ffffff;
						background-color: $primary-color !important;
					}
				}
			}
		}
		.innercontent_wrapper .directLinkWrapper {
			&.widgetIconWrapper {
				.theme {
					span { 
						color: $primary-color !important; 
						&.selected {
							color: #000000 !important;
						}
					}
				}
			}
		}	

		.invitationRulesWrapper .editRulesSection .relatedDropDown { 
			.css-2o5izw {
					&:hover,&:active, &:focus {
						border-color: $primary-color !important;
					}
					box-shadow: $primary-color !important;
				   
			  	} 
			.css-19bqh2r {
				fill: $primary-color !important;
				stroke: $primary-color  !important;
			}
			.css-15k3avv,.css-11unzgr {
				
				.css-z5z6cw {
					background-color: $primary-color;
					&:hover, &:active {
						background-color: $primary-color;
					}
				}
				.css-wqgs6e {
					&:hover, &:active {
						background-color: $primary-color;
					}
				}
			}

			.css-1ep9fjw {
		    	color: $primary-color;
			}
		}
		
		.modal-dialog .modal-content .modal-header button.chatInfoBtn:hover {
			color:#ffff !important;
			background-color: $primary-color !important;
		}
	  .divider {
			color:$primary-color !important;
		}
		.expire_account {
			color:$primary-color !important;
		}

		.archive_pagination ul {
			
			li a {
				color:$primary-color !important;
				border: none;
				outline: none;

				-webkit-box-shadow: none;
				-moz-box-shadow: none;
				box-shadow: none;
			}
			li.active {
				border-color:  $primary-color;
			}
		}

		.dropdown-item:hover{
			background-color:$primary-color !important;
			color:#f7f5f5 !important;
			font-weight: 700 !important;
		  }
		.rcs-custom-scroll .rcs-inner-handle {
			background-color: $primary-color !important;
			border-color:  $primary-color !important;
		}
		.checkout {
			background-color: $primary-color;
		}
		.profileStatus {
			.status {
				p {
					&.dropdown-item {
						color:#000;
					}
				}
			}
		}

		.menuList {
			height: 54px;
			background-color: #fff;
			cursor: pointer;
			&.selected {
				background-color: $primary-color !important;
				p {
					color: #fff;
				}
				.lineWrapper {
					.line1 {
						background-color: #fff;
						border-color:  #fff;
					}

					.line2{
						border-color:  #fff;
						&.mask {
							opacity: 1;
						    background-color: #fff;
						}
					}
				}
			}
			p{
				width: 100%;
				float: left;
				color: #293037;
				font-family: "Segoe UI Semi Bold";
				font-size: 13px;
				font-weight: 700;
			}
			.lineWrapper {
				position: absolute;
				right: 21px;
				.line1 {
	            	width: 35px;
				    height: 20px;
				    border-bottom: 1px solid #dedede;
				    -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
				    position: absolute;

				    &.selectedNext {
				    	background-color: $primary-color !important;
				    	border-color: #fff;
				    }
				}
	    		.line2 {
	        		width: 33px;
				    height: 20px;
				    border-bottom: 1px solid #dedede;
				    -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
				    position: absolute;
				    top: 17px;
				    left: -16px;
				    &.mask {
				    	opacity: 0;
				    	left: -1px;
				    	width: 37px;
					    top: 29px;
					    background-color: #fff;
					    border: 0;
				    	&.selectedNext {
				    		opacity: 1;
				    		background-color:$primary-color !important;
					    }
				    }
				    &.selectedNext {
				    	border-color: #fff;
				    }
				}
			}
			
		}

		.carousel {
			padding: 12px 0px;
			img.carouselBtn {
				position: absolute;
				cursor: pointer;
				top: 90px;
				&.carousel-left {
					left: 30px;
				}
			}
			ul{
				margin-left: 0;
				margin-right: 0;
				transition: all 1s;
				&.widgetMode {
					li {
						opacity: 0.5;
						float: left;
	
						&.selected, &:hover {
							opacity: 1;
							border: 3px solid $primary-color !important;
							h4 {
								background-color: $primary-color !important;
								color: #ffffff;
							}
						}
					}
				}
			}

		.carousel-cell {
			cursor: pointer;
			width: 210px;
			height: 192px;
			box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
			border-radius: 20px;
			background-color: #ffffff;
			
			&.opacity_0 {
				opacity: 0;
				margin-right: 60px;
				transition: opacity 2s;
			}
			&:hover {
				border: 3px solid $primary-color !important;
				h4 {
					background-color: $primary-color !important;
					color: #ffffff;
				}
			}
			h4 {
				color: #000000;
				font-family: "Helvetica Neue Cyr Roman";
				font-size: 22px;
				font-weight: 400;
				padding: 22px 0 18px;
				border-bottom: 4px solid $primary-color !important;
				margin: 0;
				border-radius: 15px 15px 0 0;
			}
			.img-wrapper {
				height: 120px;
			    display: table-cell;
			    vertical-align: middle;
			    width: 210px;
			}
			
		} 
	}
		#sidebar {
			>ul {
				&.sideMenu {
					background-color: $primary-color;
					li{
						&.active {
							background-color: $secondary-color;
						}
					}
				}
			}
			.subMenu {
				li {
					&.parent_list {
						>ul {
							li{
								&.active {
									background-color: $secondary-color;
								}
								p {
									//color: rgba($primary-color, 0.1);
								}
							}
						}
						
					}
				}
			}
			&.operator_menu {
				.subMenu {
					li {
						&.parent_list {
							&.active {
								background-color: $secondary-color;
							}
						}
					}
				}
			}
		}
		.image_upload {
			button {
				border: 1px solid $primary-color;
			}
			p {
				color: $primary-color;
			}
		}

		.profileImg {
			.img_delete {
				p {
					&:last-child {
						color: $primary-color;
					}
				}
			}
		}

		.save_BTN{
			border: 1px solid $primary-color;
			background-color: $primary-color !important;
		}

		.formBtns {
			Button {
				&.btn_clr {
					border: 1px solid $primary-color;
					color: $primary-color;
				}
				&.btn_save {
					background-color: $primary-color;
				}
			}
		}
		.overview {
			ol {
				li {
					&::before {
						border: 1px solid $primary-color;
						color: $primary-color;
					}
					p {
						a:not([href]):not([tabindex]) {
							color: $primary-color;
						}
					}
				}
			}
		}

		.scriptWrapper {
			&.innercontent_wrapper .box {
				&.editScriptSection {
					.headerTitle {
						@include border-direction(bottom, 4px, solid, $primary-color);
						
					}
				}
			}
		}
		.chatWrapper  .settingFormWrapper  .headerTitle  h4 span {
			color:$primary-color !important;
		}
		.preview {
			.chatPreview {
				.headerTitle {
					&.active {
						background-color: $primary-color;	
					}
				}

				.chatBox {
					.msgArea {
						p,a {
							&.reply {
								color: $primary-color;
							}
						}
					}
				}
				.btn_send {
					border-color: $primary-color;
					background-color: $primary-color;
					&:hover {
						color: $primary-color;
					}
					&.preview_btn {
						&:hover {
							background-color: $primary-color;
							color: #fff;
						}
					}
				}
			}
		}

		.optpanelHeader {
			background-color: $primary-color;
		}

		.panelWrapper {
			&.odd {
				background-color: $primary-color-light;
			}
			.panelHeader {
				background-color: $primary-color;
			}
			.scriptPanel {
				>div.panelHeading {
					color: $primary-color;
					p {
						color: $primary-color;
						&:first-child {
							@include border(1px,solid,$primary-color);
							background-color: $primary-color;
						}
					}

					&.collapsed {
						p:first-child  {
							background-image: $plus-icon !important;
							background-size: 11px;
						}
					}
				}
				
				
				.action {
					.data, &.data {
						.optionArea {
							p {
								color: $primary-color;
							}
							.editWrapper {
								>div {
									p {
										color: $primary-color;
									}
								}
							}
						}
					}
				}
			}
		}
		&.ruleComponent.ghostClass {
			@include grabbing-cursor;
		}
		&.ruleComponent.even {
			background-color: $primary-color-light;
		}
		.ruleComponent {
			.optionArea {
				.editWrapper {
					p {
						color: $primary-color;
					}
				}
			}
			&.even {
				background-color: $primary-color-light;
			}	
		}
		
		.fileTransferWrapper {
			.settingFormWrapper form {
				.customField {
					.progress_wrapper {
						.progress {
							background-color: $primary-color;
						}
					}
				}
				
			}
			.scriptPanel {
				p {
					&.action_text {
						color: $primary-color;
					}
				}
			}
		}
		.custom_btn {
			border: 1px solid $primary-color;
			color: $primary-color;
			&.btn-primary:hover {
				background-color: $primary-color;
				border-color: $primary-color;
				color: #fff;
			}
		}

		.export_csvbtn {
			color: $primary-color;
			&.btn-primary:hover {
				a {
					color: #fff;
				}
			}
			a:hover {
				color: #fff;
			}
		}

		.export_csvbtn {
			color: $primary-color;
			a {
				color: $primary-color;
			}
		}
		
		.innercontent_wrapper .with-header{
			.box {
				.headerTitle {
					border-bottom: 4px solid $primary-color;
				}
			}
		}
		.customDrpDwn {
			.css-19bqh2r {
				fill: $primary-color;
				stroke: $primary-color;
			}
			.css-1ep9fjw {
		    	color: $primary-color;
			}
		}
		label.checkbox_custom {
			border: 1px solid $primary-color;
			&.btn-primary:hover {
				background-color: $primary-color;
				border-color: $primary-color;
			}
			&.active{
				background-color: $primary-color;
			}
		}
		&.modal {
			.modal-dialog {
				.modal-header {
					border-bottom: 4px solid $primary-color;
					.close {
						color: $primary-color;
					}
				}
				.modal-footer {
					button {
						&.btn_cancel {
							border: 1px solid $primary-color;
							color: $primary-color;
						}
						&.btn_save {
							background-color: $primary-color;
						}
					}

					#goTologin {
						p {
							margin-right: 85px;
							cursor: pointer;
							color: $primary-color;
						}
					}
				}
			}
		}

		.wrapper {
			.rcs-inner-handle {
				background-color: $primary-color;
				border: 1px solid $primary-color;
			}

		}

		.notiInfoScrollWrapper button.read_more{
			width:119px;
		  height: 39px;
		  color:#fff;
		  background: $primary-color !important;
		  border: 1px solid $primary-color !important
		  }
		  
		.innercontent_wrapper {
			.profile_wrapper {
				.performance-wrapper {
					.performance-content {
						p {
							&.count {
								color: $primary-color;
							}
						}
					}
				}
			}
		}
		label.checkbox_custom {
			border: 1px solid $primary-color;
		    &.btn-primary:not(:disabled):not(.disabled):active, 
			&.btn-primary:not(:disabled):not(.disabled).active, .show > &.btn-primary.dropdown-toggle, &.btn-primary:hover {
				background-color: $primary-color;
				border-color: $primary-color;
			}
			&.active{
				background-color: $primary-color;
				
			}
		}

		.panelWrapper {
			.scriptPanel {
				.action{
					&.traffic_row {
						.active {
							border: 1px solid $primary-color;
						}
						&.odd {
							background-color: rgba($primary-color, 0.1);
						}
						&.even {
							background-color: #ffffff;
							.child_wrapper {
								background-color: rgba($primary-color, 0.1);
							}
						}
					}
					&:nth-child(odd) {
						background-color: rgba($primary-color, 0.1);
					}
					&:nth-child(even), &.even {
						background-color: #ffffff;
						
					}
					&.archiveRow {
						&.active {
							border: 1px solid $primary-color;
						}
					}
				}

			}
		}

		.optscriptPanel {
			.action{
				&.traffic_row {
					.active {
						border: 1px solid $primary-color;
					}
					&.odd {
						background-color: rgba($primary-color, 0.1);
					}
					&.even {
						background-color: #ffffff;
						.child_wrapper {
							background-color: rgba($primary-color, 0.1);
						}
					}
				}
				&:nth-child(odd) {
					background-color: rgba($primary-color, 0.1);
				}
				&:nth-child(even), &.even {
					background-color: #ffffff;
					
				}
				&.archiveRow {
					&.active {
						border: 1px solid $primary-color;
					}
				}
			}

		}
	
		.traffic_wrapper {
			.formBtns {
				background-color : rgba($primary-color, 0.1);
			}
		}
		

		.customDrpDwn {
			.css-10nd86i {
			    &:hover,&:active, &:focus {
		    		border-color: $primary-color;
		    	}
			   
			}
			.css-19bqh2r {
				fill: $primary-color;
				stroke: $primary-color;
			}
			
			.css-15k3avv,.css-11unzgr {
				
				.css-z5z6cw {
					background-color: $primary-color;
					&:hover, &:active {
						background-color: $primary-color;
					}
				}
				.css-wqgs6e {
					&:hover, &:active {
						background-color: $primary-color;
					}
				}
			}

			.css-1ep9fjw {
		    	color: $primary-color;
			}
		}

		.optDeptWrapper {
			
			.operatorSection {
				.operatorsWrapper {
					
				
					.profileImgWrapper {
						
						&.addImage, &.noImage {
							img {
								border: none;
							}
							p{
								color: $primary-color;
							}
						}

					}
					.addWrapper {
						img {
							border: none !important;
						}
						p{
							color: $primary-color;
						}
					}
					.operatorNick {
						p {
							color: $primary-color;

						}
					}
					.operatorDetails {
						p {

							&.email {
								color: $primary-color;
							}
						}
					}
				}
			}
			&.section_Dept {
				.panelContent {
					.editWrapper {
						p{
							color: $primary-color;
						}
					}
				}
			}
		}
		.archive_pagination .pagination{
			border:0.5 solid $primary-color;
			color: $primary-color !important;
			a {
				color:$primary-color !important;
			} 
		}
		.archive_pagination .pagination li.active{
			cursor:pointer;
			margin-right:12px;
			border: $primary-color  solid;
			border-radius:20px;
			width:25px;
			height:25px;
			border-width:thin;
		}
		.traffic_pagination .pagination { 
			border:0.5 solid $primary-color;
			color: $primary-color !important;
			a {
				color:$primary-color !important;
			} 
		  }
		  .traffic_pagination .pagination li.active { 
			cursor:pointer;
			margin-right:12px;
			border: $primary-color  solid;
			border-radius:20px;
			width:25px;
			height:25px;
			border-width:thin;
		  }
		.innercontent_wrapper {
			.traffic_wrapper {
				.panelWrapper{
					.scriptPanel {
						// .collapse_wrapper {
						// 	p {
						// 		&:first-child {
						// 			border: 1px solid $primary-color;
						// 			background-image: $minus-icon;
						// 			background-repeat: no-repeat;
						//    			background-position: center;
						// 		}
						// 	}
						// }
						.collapse_wrapper .iconshow { 
									border: 1px solid $primary-color;
									background-image: $minus-icon;
									background-repeat: no-repeat;
						   			background-position: center;							  
						}
						.with-image {
							p{
								a {
									color: $primary-color;
								}
							}
						}
						.collapsed .collapse_wrapper p:first-child {
							background-image: $plus-icon;
							background-size: 8px;
						}
						 .collapse_wrapper .collapsed  {
							background-image: $plus-icon !important;
							background-size: 8px;
						}
					}

				} 
				
			}
			.widgetWebsiteWrapper {
	
				.widgetContent {
	
					.detailsWrapper {
						p {
							span {
								&.count {
									background-color: $primary-color;
									&:hover {
										border: 1px solid $primary-color;
										color: $primary-color;
									}
								}
							}
							&.domain span {
								color: $primary-color;
							}
						}
					}
					.tumbnail_footer {
						background-color: rgba($primary-color, 0.1);
					}
				}
			}
			.directLinkWrapper {
				.directLinkpreviewWrapper {
					p{
						color: $primary-color;
					}
				}
			}
		}
		.chatInfoWrapper {
			.nav.nav-tabs {
				a.nav-item {
					background-color: $primary-color ;
					border-right: 1px solid #fff !important;
					&.active {
		 				color: $primary-color !important;
		 			}
				}
			}
			.tab-content {
				p.hide_btn{
					color: $primary-color !important;

					svg {
						fill:$primary-color !important;
						stroke:$primary-color !important;
						margin-right: 9px;
						margin-top: -3px;
					}
				}
			}
			.tabContent {
				.data {
					.webInfo, .notes {
						background-color: $primary-color-light !important;// rgba($primary-color, 0.1);
					}
				}
			}
		}
		
		.customerChat {
		 	.chatText {
		 		background-color: $primary-color;
		 		&:before {
		 			content: "";
				    position: absolute;
				    top: -14px;
				    right: 17px;
				    z-index: 1;
				    border: solid 7px transparent;
			    	border-bottom-color: $primary-color;
		 		}
		 	}
		}
		
		.operatorChat {
		 	.chatText {
		 		background-color: rgba($primary-color, 0.1);
		 		&:before {
		 		 	content: "";
				    position: absolute;
				    top: -14px;
				    left: 17px;
				    z-index: 1;
				    border: solid 7px transparent;
			    	border-bottom-color: rgba($primary-color, 0.1);
		 		}
		 	}
		}
		.historyWrapper {
			.transcriptDetails {
				background-color: rgba($primary-color, 0.1);
			}
		}
		.websitesWrapper {
			.websitesDetails {
				.box {
					
					.webLink {
						color: $primary-color;
					}
					.actionsWrapper {
						background-color: $primary-color;
					}
					.overview {
						.row p {
							color: $primary-color;
						}
					}
					.addWrapper {
						img {
							border: 2px solid $primary-color;
						}
						p{
							color: $primary-color;
						}
					
					  .websitePayDollar {
							border:3px solid $primary-color !important;
						}
					}
				}

			}

		}
		.textEditorWrapper {
			.sendImgWrappger {
				background-color: $primary-color;
			}
		}

		.innercontent_wrapper {
			.directLinkWrapper {
				.uploadBtn {
					button {
						border: 1px solid $primary-color !important;
						p{
						 color: $primary-color !important;
						}
					}
				}
				.with_btn {
					.uploadBtn {
						button {
							// background-color: $primary-color !important;
						}
					}
				}
				&.toolBarWrapper {
					.with_btn {
						.uploadBtn {
							button {
								border: 1px solid $primary-color !important;
								background-color: #fff;
								p{
									color: $primary-color !important;
								}
							}
						}
					}
				}
			} 

		}

	}

}

@each $theme, $color in $themes {
	$primaryColor: map-get($color, primary-color);
	$secondaryColor: map-get($color, secondary-color);
	$primary-color-light: map-get($color, primary-color-light);
	$plusIcon:map-get($color,plus-icon);
	$minusIcon:map-get($color,minus-icon);	
  	@include theme($theme, $primaryColor, $secondaryColor, $primary-color-light,$plusIcon,$minusIcon);
}