@font-face {
  font-family: 'Helvetica Neue Cyr';
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/HelveticaNeueCyr-Light.otf");
}
@font-face {
  font-family: 'Helvetica Neue Cyr Medium';
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/HelveticaNeueCyr-Medium.otf");
}
@font-face {
  font-family: 'Helvetica Neue Cyr Roman';
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/HelveticaNeueCyr-Roman.otf");
}
@font-face {
  font-family: 'Segoe UI Semi Bold';
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/seguisb.ttf");
}

@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/segoeui.ttf");
}

@font-face {
  font-family: 'Segoe UI Bold';
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/Segoe UI Bold.ttf");
}

@font-face {
  font-family: 'FontAwesome';
  src: url('assets/fonts/fontAwesome/fontawesome-webfont.eot');
  src: url('assets/fonts/fontAwesome/fontawesome-webfont.eot?#iefix') format('embedded-opentype'), url('assets/fonts/fontAwesome/fontawesome-webfont.woff') format('woff'), url('assets/fonts/fontAwesome/fontawesome-webfont.ttf') format('truetype'), url('assets/fonts/fontAwesome/fontawesome-webfont.svg#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}