.chatResponseWrapper {
	.title {
		margin: 9px;
		padding-left: 8px;

	}
	.formBtns {
		margin-top: 15px;
	}
	.editorWrapper {
		width: 99%;
	}
	.box:first-child {
		margin-bottom: 30px;
	}
	.response_wrapper {
		label {
			text-align: right;
			padding-right: 0;
		}
		&.settingFormWrapper .form-control, .customDrpDwn .css-10nd86i{
			width: 100%;
			max-width: 256px;
		}


		
	}
	.rule_data {
		p {
			color: #000000;
			font-family: "Segoe UI";
			font-size: 14px;
			font-weight: 400;

			&.ques {
				font-family: "Segoe UI Semi Bold";
			}
		}
	}
	.panelWrapper .scrollWrapper {
	    height: calc(100% - 50px);
	    max-height: calc(95vh - 50px);
	}
	.reponseDrpDwn {
		label {
			padding-top: 5px;
			padding-bottom: 9px;
		}
		.form-group {
			margin-bottom: 14px;
		}
		.customDrpDwn .css-10nd86i {
			//width: 253px;
			width:100%;
		}
	}
	.editorWrapper .customEditor {
		height: 94px;
	}
}

.archiveFormWrapper {
	.website_drp {
		label {
			padding-top: 9px;
		}
	}
}
.innercontent_wrapper .chatWrapper{
	h4 {
		margin-top: 10px;
		span {
			color: #3ba8c5;
		}
	}
	.headerTitle {
		.label{
			//max-width: 71px;
		}
	}
	&.with-header .box {
		margin-top: 20px;
		padding: 0 15px 30px 0;
		>div {
			&:first-child {
				padding: 0;
			}
		}
		.headerTitle {
			p {
				text-align: left;
				margin-bottom: 0;
				font-size: 14px;
			}
			.label {
				p {
					font-family: "Segoe UI Semi Bold";
				}
			}
		}
		.contentWrapper {
			padding: 20px 5px 30px 11px;
			.chatData {
				max-height: 80vh;
				width: 100%;
				float: left;
				p{
					text-align: left;
				}
			}
		}
	}
	.chatEnterMsg {
		border-radius: 3px;
		background-color: #f6f6f6;
		margin-top: 10px;
		padding: 15px 20px;
        display: inline-flex;
		position: relative;
		p{
			margin: 0;
			font-size: 14px;

			span {
				color: #adadad;
				font-size: 12px;
				//float: right;
				position: absolute;
				right: 15px;
			}
		}
	}

	.editorWrapper {
		margin: 10px 15px;
		float: left;
		width: 96%;
		.editorToolBar {
			width: 100px;
    		float: left;
    		height: 100px;
    		
    		border: 1px solid #dedede;
			background-color: #ffffff;
			border-top-right-radius: 0;
    		border-bottom-right-radius: 0;
    		border-right: none;
    		.rdw-option-wrapper {
    			min-width: 21px;
    			padding: 0;
    		}
    		.customProps {

    			.editorCoBrowse {
    				position: absolute;
				    top: 35px;
				    left: 67px;
    			}
    			.editorSpellCheck {
				    position: absolute;
				    left: 0;
				    top: 66px;
				}
				.editorPrint {
					position: absolute;
				    left: 38px;
				    top: 66px;
				}
				.editorExitSession {
					margin-left: 66px;
				}
				
    		}
    		.rdw-inline-wrapper {
    			margin-bottom: 0;
    		}
    		.rdw-emoji-wrapper, .rdw-image-wrapper {
    			margin-bottom: 0;
    		}
		}
		.customEditor {
			border: 1px solid #dedede;
			background-color: #ffffff;
			border-top-left-radius: 0;
    		border-bottom-left-radius: 0;
    		height: 100px;

    		.DraftEditor-root {
    			padding-right: 57px;
    			.public-DraftEditor-content {
    				&::placeholder {
    					color: #adadad;
						font-family: "Segoe UI";
						font-size: 14px;
						font-weight: 400;
    				}
	    			
	    		}
    		}

		}
	}
	.chatSection {
		float: left;
		.chatBy {
			color: #000000;
			font-family: "Segoe UI Semi Bold";
			font-size: 14px;
			font-weight: 400;
			margin-bottom: 0;
			span {
				color: #adadad;
				font-family: "Segoe UI";
				font-size: 12px;
			}
		}
		.chatText {
			width: 454px;
			border-radius: 3px;
			padding: 15px 20px 12px;
			margin-top: 12px;
			position: relative;
			max-width: 100%;
			.dropDownWrapper .customDrpDwn > div:nth-child(1) {
				width: 175px;	
			} 
			.fileImg {
				width: 70px;
				border: 1px solid #69b9ce;
				padding: 5px 11px;
				margin: 0 0 4px 9px;
				float: left;
			}
			.download{
				margin-top: 20px;
				float: left;
			}
			.opfileImg {
				width: 70px;
				border: 1px solid #69b9ce;
				padding: 5px 11px;
				margin: 0 0 4px 9px; 
			}
			p {
				margin: 0;
				font-size: 14px;
			}
			&.chatFile {
				width: 384px;
				.fileImg {
					width: 70px;
					height: 75px;
					border: 1px solid #69b9ce;
					padding: 5px 11px;
					margin: 0 0 4px 9px;
					float: left;

					img{
						width: 48px;
						height: 60px;
						object-fit: scale-down;
					}
				}

				.fileDet {
					margin-top: -6px;
					word-wrap: anywhere;
					span {
						//opacity: 0.64;
						opacity:1;
						font-size: 12px;
						margin: 0 7px 0 11px;
					}
				}

				.customDrpDwn {
					margin-top: 20px;
					>span{
						float: left;
						color: #ffffff;
						font-family: "Segoe UI Semi Bold";
						font-size: 14px;
						font-weight: 400;
						margin: 8px 12px 0 0;
					}
					.css-10nd86i {
						width: 198px;
						float: left;
				    }
				}
			}
			
		}
		&.customerChat {
			.chatText {
				p {
					color: #fff;
				}
				
			}
		}
		&.supervisorChat {
			.chatText {
				background-color: #f1ea4f;
				width: 100%;
				&:before {
					content: "";
					position: absolute;
				    top: -14px;
				    left: 17px;
				    z-index: 1;
				    border: solid 7px transparent;
					border-bottom-color: #f1ea4f;
				}
			}
			.chatMsg {
				background-color: #f1ea4f;
				width: 100%;
				border-radius: 3px;
				padding: 15px 20px 12px;
				margin-top: 30px;
				position: relative;

				p {
					margin: 0;
					font-size: 14px;
					font-weight: 400;
					color: #000000;
				}
			}
		}
	}
}
.chatInfoWrapper {
	background-color: #fff;
	box-shadow: 0 0 5px rgba(13, 16, 21, 0.19);
	border-radius: 10px;
	
	.nav.nav-tabs {
	 	margin-top: 40px;

		a.nav-item {
		 	background-repeat: no-repeat;
			background-position: center 10px;
			padding-top: 35px;
			font-family: "Segoe UI";
			font-size: 13px;
			font-weight: 400;
			color: #ffffff;
			background-color: #3ba8c5;
			width: 25%;
			border: none;
			border-radius: 0;
			border-right: 1px solid #69b9ce;
			outline: none;

			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
			&:last-child.active{
				border-right: none;
			}
		 	&:nth-child(1) {
				 outline: none;
		 		border-top-left-radius: 10px;
		 		background-image: url("assets/images/infotab.png");
		 		&.active {
		 			background-image: url("assets/images/infotab_selected.png");
		 		}
		 	}
		 	&:nth-child(2) {
				outline: none;
		 		background-image: url("assets/images/footstepstab.png");
		 		&.active {
		 			background-image: url("assets/images/footstepstab_selected.png");
		 		}
		 	}
		 	&:nth-child(3) {
				outline: none;
		 		background-image: url("assets/images/historytab.png");
		 		&.active {
		 			background-image: url("assets/images/historytab_selected.png");
		 		}
		 	}
		 	&:nth-child(4) {
				outline: none;
		 		border-top-right-radius: 10px;
		 		background-image: url("assets/images/responsetab.png");
		 		&.active {
		 			background-image: url("assets/images/responsetab_selected.png");
					
		 		}
		 	}
		 	&.active {
		 		color: #3ba8c5;
				background-color: #fff;
		 	}
		}
	}
	.tab-content {
		position: relative;

		p.hide_btn{
			color: #3ba8c5;
			font-family: "Segoe UI Bold";
			font-size: 10px;
			font-weight: 700;
			text-transform: uppercase;
			position: absolute;
			width: 100%;
			margin: 0;
			top: -7px;
			span {
				width: 70px;
    			display: inline-block;
    			background-color: #fff;
    			cursor: pointer;
    			img {
    				margin-right: 8px;
    				margin-top: -2px;
    			}
			}
		}

	}
	.tabContent {
		text-align: left;
		margin-top: 24px;
		padding-top: 35px;
		border-top: 1px solid #dedede;
		h6 {
			color: #000000;
			font-family: "Helvetica Neue Cyr Roman";
			font-size: 22px;
			font-weight: 400;
			text-align: left;
			margin: 0 20px 20px;
		}
		color: #000000;
		font-family: "Segoe UI Semi Bold";
		font-size: 14px;
		font-weight: 400;
		.data {
			p {
				margin-bottom: 10px;
				margin-top: 0;
			}
			.label {
				padding-right: 0;
				p {
					margin-left: 6px;
					font-family: "Segoe UI Semi Bold";
					font-size: 14px;
				}
				
			}
			.value {
				p{
					margin-right: 6px;
					font-family: "Segoe UI";
					font-size: 14px;
				}
			}

			.webInfo {
				padding: 25px 20px;
				background-color: #ebf6f9;
				margin-bottom: 20px;
				margin-top: 15px;
				>p {
					font-family: "Segoe UI Semi Bold";
					margin-bottom: 20px;
					margin-top: 0;
					font-size: 14px;
				}
				.row{
					&:last-child {
						p {
							margin-bottom: 0;
						}
					}
				}
				.label {
					p {
						margin-left: 0;
					}
				}
			}

			.notes {
				padding: 25px 20px;
				background-color: #ebf6f9;
				border-radius: 0 0 10px 10px;
				margin-top: 12px;
				h6 {
					color: #293037;
					font-family: "Segoe UI Bold";
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					margin-left: 0;
					margin-right: 0;
				}
				button {
					background-color: transparent;
					&:hover {
						color: inherit;
					}
				}
				.notesData {
					margin-bottom: 27px;
					p {
						width: 100%;
					}
				}
			}
			.rowLayout {
				.label {
					p {
					}
					
				}
				.value {
					p{
						margin: 0 6px;
					}
				}
				.link {
					color: #3ba8c5;
					img {
						margin-left: 8px;
					}
				}
				.disabled {
					color: #adadad;
				}
			}
			.withImage {
				
				img {
					    cursor:pointer;
						margin-left: 8px;
						margin-top: -3px;
					}
			}
		}
		.footstepsWrapper {
			padding-bottom: 35px;
			.link {
					color: #3ba8c5;
					img {
						margin-left: 8px;
					}
				}
		}
		.historyWrapper {
			padding-bottom: 40px;
			>div {
				>div.label,  >div.value{
					cursor: pointer;
				}
			}
			.label {
				p {
					font-size: 12px;

					span {
						color: #adadad;
					}
				}
			}
			.value {
				p{
					color: #3ba8c5;
					font-size: 14px;
				}
			}
			.transcriptDetails {
				padding-top: 25px;
				padding-bottom: 0;
				max-height: 60vh;
				margin-bottom: 20px;
				
				p{
					font-size: 14px;
				}
				.value {
					p{
						color: #000000;
					}
				}
				>img {
					margin-top: -15px;
    				margin-right: -9px;
    				cursor: pointer;
				}
				.transcript {
					h6{
						color: #293037;
						font-family: "Segoe UI Bold";
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;
						margin: 20px 6px 15px 22px;
					}
					>div {
						p{
							margin-left: 6px;
							&:first-child {
								font-family: "Segoe UI Semi Bold";
								margin-bottom: 0;
								span {
									color: #adadad;
								}
							}
						}
					}
				}
				.rcs-custom-scroll .rcs-inner-container > div {
					padding: 0;
				}
			}
		}
		&.responseWrapper{
			.panelWrapper {
				border-top-right-radius: 0;
				border-top-left-radius: 0;
			}
			.searchForm {
				padding: 0 20px;
				.searchText input {
					padding-right: 27px;
    				background-repeat: no-repeat;
    				background-position: 97%;
    				background-image: url("assets/images/search.png");
    				&::placeholder {
    					color: #adadad;
						font-family: "Segoe UI";
						font-size: 14px;
						font-weight: 400;
    				}
				}
				p {
					color: #adadad;
					font-family: "Segoe UI";
					font-size: 14px;
					font-weight: 400;
					span{
						color: #010101;
						font-family: "Segoe UI Semi Bold";
						font-weight: 600;
					}
				}
			}
			.ruleComponent {
				padding: 15px 20px;
				>div {
					&:first-child {
						max-width: max-content;
					}
				}

				.rule_data {
					p {
						&.ques {
							font-family: "Segoe UI Semi Bold";
							font-weight: 600;
						}
					}
				}
			}
		}
	}
}

.archiveRow {
	.checkbox_custom {
		margin-bottom: 2px;
	}
}
.chatArchivePopup .modal-dialog {
	width: 570px;
	max-width: 570px;
	.modal-content .chatInfoScrollWrapper {
		.value p {
			font-family: "Segoe UI";
		}
		.modalBodyTitle:nth-child(1) {
			margin-top: 5px;
			margin-bottom: 10px;
		}
		.infoTranscript {
			.chatmsg{
				p{
					 img {
						height: auto;
						max-width: 100%;
					}
				}
			}
		}
	} 
}

.textEditorWrapper {
	position: relative;
	float: left;
    width: 100%;
	.sendImgWrappger {
		position: absolute;
		width: 38px;
		height: 38px;
		right: 35px;
		border-radius: 50%;
		top: 41px;

		img{
			margin: 11px 8px 11px 12px;
		}
	}
	.sendImgWrappger:focus{
		outline: none;
	}
}