@import url(../node_modules/@syncfusion/ej2-base/styles/material.css);
@import url(../node_modules/@syncfusion/ej2-react-popups/styles/material.css);
.default .existing_opcreate {
  background-color: #3ba8c5 !important;
  color: #fff !important; }

.default .toolBarPreviewWrapper .positionIcons.selected {
  background-color: #3ba8c5 !important;
  border: 1px solid #3ba8c5 !important; }

.default .modal-dialog .modal-content .modal-header button.chatInfoBtn {
  border: 1px solid #3ba8c5 !important;
  background-color: #ffffff !important;
  color: #3ba8c5 !important; }

.default .widgetPosition p span.selected {
  color: #ffffff;
  background-color: #3ba8c5 !important; }

.default .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span {
  color: #3ba8c5 !important; }
  .default .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span.selected {
    color: #000000 !important; }

.default .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw {
  box-shadow: #3ba8c5 !important; }
  .default .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:hover, .default .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:active, .default .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:focus {
    border-color: #3ba8c5 !important; }

.default .invitationRulesWrapper .editRulesSection .relatedDropDown .css-19bqh2r {
  fill: #3ba8c5 !important;
  stroke: #3ba8c5 !important; }

.default .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw, .default .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw {
  background-color: #3ba8c5; }
  .default .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw:hover, .default .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw:active, .default .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw:hover, .default .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw:active {
    background-color: #3ba8c5; }

.default .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-wqgs6e:hover, .default .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-wqgs6e:active, .default .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-wqgs6e:hover, .default .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-wqgs6e:active {
  background-color: #3ba8c5; }

.default .invitationRulesWrapper .editRulesSection .relatedDropDown .css-1ep9fjw {
  color: #3ba8c5; }

.default .modal-dialog .modal-content .modal-header button.chatInfoBtn:hover {
  color: #ffff !important;
  background-color: #3ba8c5 !important; }

.default .divider {
  color: #3ba8c5 !important; }

.default .expire_account {
  color: #3ba8c5 !important; }

.default .archive_pagination ul li a {
  color: #3ba8c5 !important;
  border: none;
  outline: none;
  box-shadow: none; }

.default .archive_pagination ul li.active {
  border-color: #3ba8c5; }

.default .dropdown-item:hover {
  background-color: #3ba8c5 !important;
  color: #f7f5f5 !important;
  font-weight: 700 !important; }

.default .rcs-custom-scroll .rcs-inner-handle {
  background-color: #3ba8c5 !important;
  border-color: #3ba8c5 !important; }

.default .checkout {
  background-color: #3ba8c5; }

.default .profileStatus .status p.dropdown-item {
  color: #000; }

.default .menuList {
  height: 54px;
  background-color: #fff;
  cursor: pointer; }
  .default .menuList.selected {
    background-color: #3ba8c5 !important; }
    .default .menuList.selected p {
      color: #fff; }
    .default .menuList.selected .lineWrapper .line1 {
      background-color: #fff;
      border-color: #fff; }
    .default .menuList.selected .lineWrapper .line2 {
      border-color: #fff; }
      .default .menuList.selected .lineWrapper .line2.mask {
        opacity: 1;
        background-color: #fff; }
  .default .menuList p {
    width: 100%;
    float: left;
    color: #293037;
    font-family: "Segoe UI Semi Bold";
    font-size: 13px;
    font-weight: 700; }
  .default .menuList .lineWrapper {
    position: absolute;
    right: 21px; }
    .default .menuList .lineWrapper .line1 {
      width: 35px;
      height: 20px;
      border-bottom: 1px solid #dedede;
      -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
      position: absolute; }
      .default .menuList .lineWrapper .line1.selectedNext {
        background-color: #3ba8c5 !important;
        border-color: #fff; }
    .default .menuList .lineWrapper .line2 {
      width: 33px;
      height: 20px;
      border-bottom: 1px solid #dedede;
      -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
      position: absolute;
      top: 17px;
      left: -16px; }
      .default .menuList .lineWrapper .line2.mask {
        opacity: 0;
        left: -1px;
        width: 37px;
        top: 29px;
        background-color: #fff;
        border: 0; }
        .default .menuList .lineWrapper .line2.mask.selectedNext {
          opacity: 1;
          background-color: #3ba8c5 !important; }
      .default .menuList .lineWrapper .line2.selectedNext {
        border-color: #fff; }

.default .carousel {
  padding: 12px 0px; }
  .default .carousel img.carouselBtn {
    position: absolute;
    cursor: pointer;
    top: 90px; }
    .default .carousel img.carouselBtn.carousel-left {
      left: 30px; }
  .default .carousel ul {
    margin-left: 0;
    margin-right: 0;
    transition: all 1s; }
    .default .carousel ul.widgetMode li {
      opacity: 0.5;
      float: left; }
      .default .carousel ul.widgetMode li.selected, .default .carousel ul.widgetMode li:hover {
        opacity: 1;
        border: 3px solid #3ba8c5 !important; }
        .default .carousel ul.widgetMode li.selected h4, .default .carousel ul.widgetMode li:hover h4 {
          background-color: #3ba8c5 !important;
          color: #ffffff; }
  .default .carousel .carousel-cell {
    cursor: pointer;
    width: 210px;
    height: 192px;
    box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
    border-radius: 20px;
    background-color: #ffffff; }
    .default .carousel .carousel-cell.opacity_0 {
      opacity: 0;
      margin-right: 60px;
      transition: opacity 2s; }
    .default .carousel .carousel-cell:hover {
      border: 3px solid #3ba8c5 !important; }
      .default .carousel .carousel-cell:hover h4 {
        background-color: #3ba8c5 !important;
        color: #ffffff; }
    .default .carousel .carousel-cell h4 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      padding: 22px 0 18px;
      border-bottom: 4px solid #3ba8c5 !important;
      margin: 0;
      border-radius: 15px 15px 0 0; }
    .default .carousel .carousel-cell .img-wrapper {
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      width: 210px; }

.default #sidebar > ul.sideMenu {
  background-color: #3ba8c5; }
  .default #sidebar > ul.sideMenu li.active {
    background-color: #0b91b4; }

.default #sidebar .subMenu li.parent_list > ul li.active {
  background-color: #0b91b4; }

.default #sidebar.operator_menu .subMenu li.parent_list.active {
  background-color: #0b91b4; }

.default .image_upload button {
  border: 1px solid #3ba8c5; }

.default .image_upload p {
  color: #3ba8c5; }

.default .profileImg .img_delete p:last-child {
  color: #3ba8c5; }

.default .save_BTN {
  border: 1px solid #3ba8c5;
  background-color: #3ba8c5 !important; }

.default .formBtns Button.btn_clr {
  border: 1px solid #3ba8c5;
  color: #3ba8c5; }

.default .formBtns Button.btn_save {
  background-color: #3ba8c5; }

.default .overview ol li::before {
  border: 1px solid #3ba8c5;
  color: #3ba8c5; }

.default .overview ol li p a:not([href]):not([tabindex]) {
  color: #3ba8c5; }

.default .scriptWrapper.innercontent_wrapper .box.editScriptSection .headerTitle {
  border-bottom: 4px solid #3ba8c5; }

.default .chatWrapper .settingFormWrapper .headerTitle h4 span {
  color: #3ba8c5 !important; }

.default .preview .chatPreview .headerTitle.active {
  background-color: #3ba8c5; }

.default .preview .chatPreview .chatBox .msgArea p.reply, .default .preview .chatPreview .chatBox .msgArea a.reply {
  color: #3ba8c5; }

.default .preview .chatPreview .btn_send {
  border-color: #3ba8c5;
  background-color: #3ba8c5; }
  .default .preview .chatPreview .btn_send:hover {
    color: #3ba8c5; }
  .default .preview .chatPreview .btn_send.preview_btn:hover {
    background-color: #3ba8c5;
    color: #fff; }

.default .optpanelHeader {
  background-color: #3ba8c5; }

.default .panelWrapper.odd {
  background-color: #EBF6F9; }

.default .panelWrapper .panelHeader {
  background-color: #3ba8c5; }

.default .panelWrapper .scriptPanel > div.panelHeading {
  color: #3ba8c5; }
  .default .panelWrapper .scriptPanel > div.panelHeading p {
    color: #3ba8c5; }
    .default .panelWrapper .scriptPanel > div.panelHeading p:first-child {
      border: 1px solid #3ba8c5;
      background-color: #3ba8c5; }
  .default .panelWrapper .scriptPanel > div.panelHeading.collapsed p:first-child {
    background-image: url("assets/images/plus_icons/plus_blue.png") !important;
    background-size: 11px; }

.default .panelWrapper .scriptPanel .action .data .optionArea p, .default .panelWrapper .scriptPanel .action.data .optionArea p {
  color: #3ba8c5; }

.default .panelWrapper .scriptPanel .action .data .optionArea .editWrapper > div p, .default .panelWrapper .scriptPanel .action.data .optionArea .editWrapper > div p {
  color: #3ba8c5; }

.default.ruleComponent.ghostClass {
  cursor: url("https://www.google.com/intl/en_ALL/mapfiles/closedhand.cur"), all-scroll;
  cursor: -webkit-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.default.ruleComponent.even {
  background-color: #EBF6F9; }

.default .ruleComponent .optionArea .editWrapper p {
  color: #3ba8c5; }

.default .ruleComponent.even {
  background-color: #EBF6F9; }

.default .fileTransferWrapper .settingFormWrapper form .customField .progress_wrapper .progress {
  background-color: #3ba8c5; }

.default .fileTransferWrapper .scriptPanel p.action_text {
  color: #3ba8c5; }

.default .custom_btn {
  border: 1px solid #3ba8c5;
  color: #3ba8c5; }
  .default .custom_btn.btn-primary:hover {
    background-color: #3ba8c5;
    border-color: #3ba8c5;
    color: #fff; }

.default .export_csvbtn {
  color: #3ba8c5; }
  .default .export_csvbtn.btn-primary:hover a {
    color: #fff; }
  .default .export_csvbtn a:hover {
    color: #fff; }

.default .export_csvbtn {
  color: #3ba8c5; }
  .default .export_csvbtn a {
    color: #3ba8c5; }

.default .innercontent_wrapper .with-header .box .headerTitle {
  border-bottom: 4px solid #3ba8c5; }

.default .customDrpDwn .css-19bqh2r {
  fill: #3ba8c5;
  stroke: #3ba8c5; }

.default .customDrpDwn .css-1ep9fjw {
  color: #3ba8c5; }

.default label.checkbox_custom {
  border: 1px solid #3ba8c5; }
  .default label.checkbox_custom.btn-primary:hover {
    background-color: #3ba8c5;
    border-color: #3ba8c5; }
  .default label.checkbox_custom.active {
    background-color: #3ba8c5; }

.default.modal .modal-dialog .modal-header {
  border-bottom: 4px solid #3ba8c5; }
  .default.modal .modal-dialog .modal-header .close {
    color: #3ba8c5; }

.default.modal .modal-dialog .modal-footer button.btn_cancel {
  border: 1px solid #3ba8c5;
  color: #3ba8c5; }

.default.modal .modal-dialog .modal-footer button.btn_save {
  background-color: #3ba8c5; }

.default.modal .modal-dialog .modal-footer #goTologin p {
  margin-right: 85px;
  cursor: pointer;
  color: #3ba8c5; }

.default .wrapper .rcs-inner-handle {
  background-color: #3ba8c5;
  border: 1px solid #3ba8c5; }

.default .notiInfoScrollWrapper button.read_more {
  width: 119px;
  height: 39px;
  color: #fff;
  background: #3ba8c5 !important;
  border: 1px solid #3ba8c5 !important; }

.default .innercontent_wrapper .profile_wrapper .performance-wrapper .performance-content p.count {
  color: #3ba8c5; }

.default label.checkbox_custom {
  border: 1px solid #3ba8c5; }
  .default label.checkbox_custom.btn-primary:not(:disabled):not(.disabled):active, .default label.checkbox_custom.btn-primary:not(:disabled):not(.disabled).active, .show > .default label.checkbox_custom.btn-primary.dropdown-toggle, .default label.checkbox_custom.btn-primary:hover {
    background-color: #3ba8c5;
    border-color: #3ba8c5; }
  .default label.checkbox_custom.active {
    background-color: #3ba8c5; }

.default .panelWrapper .scriptPanel .action.traffic_row .active {
  border: 1px solid #3ba8c5; }

.default .panelWrapper .scriptPanel .action.traffic_row.odd {
  background-color: rgba(59, 168, 197, 0.1); }

.default .panelWrapper .scriptPanel .action.traffic_row.even {
  background-color: #ffffff; }
  .default .panelWrapper .scriptPanel .action.traffic_row.even .child_wrapper {
    background-color: rgba(59, 168, 197, 0.1); }

.default .panelWrapper .scriptPanel .action:nth-child(odd) {
  background-color: rgba(59, 168, 197, 0.1); }

.default .panelWrapper .scriptPanel .action:nth-child(even), .default .panelWrapper .scriptPanel .action.even {
  background-color: #ffffff; }

.default .panelWrapper .scriptPanel .action.archiveRow.active {
  border: 1px solid #3ba8c5; }

.default .optscriptPanel .action.traffic_row .active {
  border: 1px solid #3ba8c5; }

.default .optscriptPanel .action.traffic_row.odd {
  background-color: rgba(59, 168, 197, 0.1); }

.default .optscriptPanel .action.traffic_row.even {
  background-color: #ffffff; }
  .default .optscriptPanel .action.traffic_row.even .child_wrapper {
    background-color: rgba(59, 168, 197, 0.1); }

.default .optscriptPanel .action:nth-child(odd) {
  background-color: rgba(59, 168, 197, 0.1); }

.default .optscriptPanel .action:nth-child(even), .default .optscriptPanel .action.even {
  background-color: #ffffff; }

.default .optscriptPanel .action.archiveRow.active {
  border: 1px solid #3ba8c5; }

.default .traffic_wrapper .formBtns {
  background-color: rgba(59, 168, 197, 0.1); }

.default .customDrpDwn .css-10nd86i:hover, .default .customDrpDwn .css-10nd86i:active, .default .customDrpDwn .css-10nd86i:focus {
  border-color: #3ba8c5; }

.default .customDrpDwn .css-19bqh2r {
  fill: #3ba8c5;
  stroke: #3ba8c5; }

.default .customDrpDwn .css-15k3avv .css-z5z6cw, .default .customDrpDwn .css-11unzgr .css-z5z6cw {
  background-color: #3ba8c5; }
  .default .customDrpDwn .css-15k3avv .css-z5z6cw:hover, .default .customDrpDwn .css-15k3avv .css-z5z6cw:active, .default .customDrpDwn .css-11unzgr .css-z5z6cw:hover, .default .customDrpDwn .css-11unzgr .css-z5z6cw:active {
    background-color: #3ba8c5; }

.default .customDrpDwn .css-15k3avv .css-wqgs6e:hover, .default .customDrpDwn .css-15k3avv .css-wqgs6e:active, .default .customDrpDwn .css-11unzgr .css-wqgs6e:hover, .default .customDrpDwn .css-11unzgr .css-wqgs6e:active {
  background-color: #3ba8c5; }

.default .customDrpDwn .css-1ep9fjw {
  color: #3ba8c5; }

.default .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage img, .default .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage img {
  border: none; }

.default .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage p, .default .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage p {
  color: #3ba8c5; }

.default .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper img {
  border: none !important; }

.default .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper p {
  color: #3ba8c5; }

.default .optDeptWrapper .operatorSection .operatorsWrapper .operatorNick p {
  color: #3ba8c5; }

.default .optDeptWrapper .operatorSection .operatorsWrapper .operatorDetails p.email {
  color: #3ba8c5; }

.default .optDeptWrapper.section_Dept .panelContent .editWrapper p {
  color: #3ba8c5; }

.default .archive_pagination .pagination {
  border: 0.5 solid #3ba8c5;
  color: #3ba8c5 !important; }
  .default .archive_pagination .pagination a {
    color: #3ba8c5 !important; }

.default .archive_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #3ba8c5 solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.default .traffic_pagination .pagination {
  border: 0.5 solid #3ba8c5;
  color: #3ba8c5 !important; }
  .default .traffic_pagination .pagination a {
    color: #3ba8c5 !important; }

.default .traffic_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #3ba8c5 solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.default .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapse_wrapper .iconshow {
  border: 1px solid #3ba8c5;
  background-image: url("assets/images/minus_icons/minus_blue.png");
  background-repeat: no-repeat;
  background-position: center; }

.default .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .with-image p a {
  color: #3ba8c5; }

.default .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapsed .collapse_wrapper p:first-child {
  background-image: url("assets/images/plus_icons/plus_blue.png");
  background-size: 8px; }

.default .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapse_wrapper .collapsed {
  background-image: url("assets/images/plus_icons/plus_blue.png") !important;
  background-size: 8px; }

.default .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count {
  background-color: #3ba8c5; }
  .default .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count:hover {
    border: 1px solid #3ba8c5;
    color: #3ba8c5; }

.default .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p.domain span {
  color: #3ba8c5; }

.default .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .tumbnail_footer {
  background-color: rgba(59, 168, 197, 0.1); }

.default .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper p {
  color: #3ba8c5; }

.default .chatInfoWrapper .nav.nav-tabs a.nav-item {
  background-color: #3ba8c5;
  border-right: 1px solid #fff !important; }
  .default .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
    color: #3ba8c5 !important; }

.default .chatInfoWrapper .tab-content p.hide_btn {
  color: #3ba8c5 !important; }
  .default .chatInfoWrapper .tab-content p.hide_btn svg {
    fill: #3ba8c5 !important;
    stroke: #3ba8c5 !important;
    margin-right: 9px;
    margin-top: -3px; }

.default .chatInfoWrapper .tabContent .data .webInfo, .default .chatInfoWrapper .tabContent .data .notes {
  background-color: #EBF6F9 !important; }

.default .customerChat .chatText {
  background-color: #3ba8c5; }
  .default .customerChat .chatText:before {
    content: "";
    position: absolute;
    top: -14px;
    right: 17px;
    z-index: 1;
    border: solid 7px transparent;
    border-bottom-color: #3ba8c5; }

.default .operatorChat .chatText {
  background-color: rgba(59, 168, 197, 0.1); }
  .default .operatorChat .chatText:before {
    content: "";
    position: absolute;
    top: -14px;
    left: 17px;
    z-index: 1;
    border: solid 7px transparent;
    border-bottom-color: rgba(59, 168, 197, 0.1); }

.default .historyWrapper .transcriptDetails {
  background-color: rgba(59, 168, 197, 0.1); }

.default .websitesWrapper .websitesDetails .box .webLink {
  color: #3ba8c5; }

.default .websitesWrapper .websitesDetails .box .actionsWrapper {
  background-color: #3ba8c5; }

.default .websitesWrapper .websitesDetails .box .overview .row p {
  color: #3ba8c5; }

.default .websitesWrapper .websitesDetails .box .addWrapper img {
  border: 2px solid #3ba8c5; }

.default .websitesWrapper .websitesDetails .box .addWrapper p {
  color: #3ba8c5; }

.default .websitesWrapper .websitesDetails .box .addWrapper .websitePayDollar {
  border: 3px solid #3ba8c5 !important; }

.default .textEditorWrapper .sendImgWrappger {
  background-color: #3ba8c5; }

.default .innercontent_wrapper .directLinkWrapper .uploadBtn button {
  border: 1px solid #3ba8c5 !important; }
  .default .innercontent_wrapper .directLinkWrapper .uploadBtn button p {
    color: #3ba8c5 !important; }

.default .innercontent_wrapper .directLinkWrapper.toolBarWrapper .with_btn .uploadBtn button {
  border: 1px solid #3ba8c5 !important;
  background-color: #fff; }
  .default .innercontent_wrapper .directLinkWrapper.toolBarWrapper .with_btn .uploadBtn button p {
    color: #3ba8c5 !important; }

.steelBlue .existing_opcreate {
  background-color: #3ba8c5 !important;
  color: #fff !important; }

.steelBlue .toolBarPreviewWrapper .positionIcons.selected {
  background-color: #3ba8c5 !important;
  border: 1px solid #3ba8c5 !important; }

.steelBlue .modal-dialog .modal-content .modal-header button.chatInfoBtn {
  border: 1px solid #3ba8c5 !important;
  background-color: #ffffff !important;
  color: #3ba8c5 !important; }

.steelBlue .widgetPosition p span.selected {
  color: #ffffff;
  background-color: #3ba8c5 !important; }

.steelBlue .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span {
  color: #3ba8c5 !important; }
  .steelBlue .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span.selected {
    color: #000000 !important; }

.steelBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw {
  box-shadow: #3ba8c5 !important; }
  .steelBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:hover, .steelBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:active, .steelBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:focus {
    border-color: #3ba8c5 !important; }

.steelBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-19bqh2r {
  fill: #3ba8c5 !important;
  stroke: #3ba8c5 !important; }

.steelBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw, .steelBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw {
  background-color: #3ba8c5; }
  .steelBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw:hover, .steelBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw:active, .steelBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw:hover, .steelBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw:active {
    background-color: #3ba8c5; }

.steelBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-wqgs6e:hover, .steelBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-wqgs6e:active, .steelBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-wqgs6e:hover, .steelBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-wqgs6e:active {
  background-color: #3ba8c5; }

.steelBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-1ep9fjw {
  color: #3ba8c5; }

.steelBlue .modal-dialog .modal-content .modal-header button.chatInfoBtn:hover {
  color: #ffff !important;
  background-color: #3ba8c5 !important; }

.steelBlue .divider {
  color: #3ba8c5 !important; }

.steelBlue .expire_account {
  color: #3ba8c5 !important; }

.steelBlue .archive_pagination ul li a {
  color: #3ba8c5 !important;
  border: none;
  outline: none;
  box-shadow: none; }

.steelBlue .archive_pagination ul li.active {
  border-color: #3ba8c5; }

.steelBlue .dropdown-item:hover {
  background-color: #3ba8c5 !important;
  color: #f7f5f5 !important;
  font-weight: 700 !important; }

.steelBlue .rcs-custom-scroll .rcs-inner-handle {
  background-color: #3ba8c5 !important;
  border-color: #3ba8c5 !important; }

.steelBlue .checkout {
  background-color: #3ba8c5; }

.steelBlue .profileStatus .status p.dropdown-item {
  color: #000; }

.steelBlue .menuList {
  height: 54px;
  background-color: #fff;
  cursor: pointer; }
  .steelBlue .menuList.selected {
    background-color: #3ba8c5 !important; }
    .steelBlue .menuList.selected p {
      color: #fff; }
    .steelBlue .menuList.selected .lineWrapper .line1 {
      background-color: #fff;
      border-color: #fff; }
    .steelBlue .menuList.selected .lineWrapper .line2 {
      border-color: #fff; }
      .steelBlue .menuList.selected .lineWrapper .line2.mask {
        opacity: 1;
        background-color: #fff; }
  .steelBlue .menuList p {
    width: 100%;
    float: left;
    color: #293037;
    font-family: "Segoe UI Semi Bold";
    font-size: 13px;
    font-weight: 700; }
  .steelBlue .menuList .lineWrapper {
    position: absolute;
    right: 21px; }
    .steelBlue .menuList .lineWrapper .line1 {
      width: 35px;
      height: 20px;
      border-bottom: 1px solid #dedede;
      -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
      position: absolute; }
      .steelBlue .menuList .lineWrapper .line1.selectedNext {
        background-color: #3ba8c5 !important;
        border-color: #fff; }
    .steelBlue .menuList .lineWrapper .line2 {
      width: 33px;
      height: 20px;
      border-bottom: 1px solid #dedede;
      -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
      position: absolute;
      top: 17px;
      left: -16px; }
      .steelBlue .menuList .lineWrapper .line2.mask {
        opacity: 0;
        left: -1px;
        width: 37px;
        top: 29px;
        background-color: #fff;
        border: 0; }
        .steelBlue .menuList .lineWrapper .line2.mask.selectedNext {
          opacity: 1;
          background-color: #3ba8c5 !important; }
      .steelBlue .menuList .lineWrapper .line2.selectedNext {
        border-color: #fff; }

.steelBlue .carousel {
  padding: 12px 0px; }
  .steelBlue .carousel img.carouselBtn {
    position: absolute;
    cursor: pointer;
    top: 90px; }
    .steelBlue .carousel img.carouselBtn.carousel-left {
      left: 30px; }
  .steelBlue .carousel ul {
    margin-left: 0;
    margin-right: 0;
    transition: all 1s; }
    .steelBlue .carousel ul.widgetMode li {
      opacity: 0.5;
      float: left; }
      .steelBlue .carousel ul.widgetMode li.selected, .steelBlue .carousel ul.widgetMode li:hover {
        opacity: 1;
        border: 3px solid #3ba8c5 !important; }
        .steelBlue .carousel ul.widgetMode li.selected h4, .steelBlue .carousel ul.widgetMode li:hover h4 {
          background-color: #3ba8c5 !important;
          color: #ffffff; }
  .steelBlue .carousel .carousel-cell {
    cursor: pointer;
    width: 210px;
    height: 192px;
    box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
    border-radius: 20px;
    background-color: #ffffff; }
    .steelBlue .carousel .carousel-cell.opacity_0 {
      opacity: 0;
      margin-right: 60px;
      transition: opacity 2s; }
    .steelBlue .carousel .carousel-cell:hover {
      border: 3px solid #3ba8c5 !important; }
      .steelBlue .carousel .carousel-cell:hover h4 {
        background-color: #3ba8c5 !important;
        color: #ffffff; }
    .steelBlue .carousel .carousel-cell h4 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      padding: 22px 0 18px;
      border-bottom: 4px solid #3ba8c5 !important;
      margin: 0;
      border-radius: 15px 15px 0 0; }
    .steelBlue .carousel .carousel-cell .img-wrapper {
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      width: 210px; }

.steelBlue #sidebar > ul.sideMenu {
  background-color: #3ba8c5; }
  .steelBlue #sidebar > ul.sideMenu li.active {
    background-color: #0b91b4; }

.steelBlue #sidebar .subMenu li.parent_list > ul li.active {
  background-color: #0b91b4; }

.steelBlue #sidebar.operator_menu .subMenu li.parent_list.active {
  background-color: #0b91b4; }

.steelBlue .image_upload button {
  border: 1px solid #3ba8c5; }

.steelBlue .image_upload p {
  color: #3ba8c5; }

.steelBlue .profileImg .img_delete p:last-child {
  color: #3ba8c5; }

.steelBlue .save_BTN {
  border: 1px solid #3ba8c5;
  background-color: #3ba8c5 !important; }

.steelBlue .formBtns Button.btn_clr {
  border: 1px solid #3ba8c5;
  color: #3ba8c5; }

.steelBlue .formBtns Button.btn_save {
  background-color: #3ba8c5; }

.steelBlue .overview ol li::before {
  border: 1px solid #3ba8c5;
  color: #3ba8c5; }

.steelBlue .overview ol li p a:not([href]):not([tabindex]) {
  color: #3ba8c5; }

.steelBlue .scriptWrapper.innercontent_wrapper .box.editScriptSection .headerTitle {
  border-bottom: 4px solid #3ba8c5; }

.steelBlue .chatWrapper .settingFormWrapper .headerTitle h4 span {
  color: #3ba8c5 !important; }

.steelBlue .preview .chatPreview .headerTitle.active {
  background-color: #3ba8c5; }

.steelBlue .preview .chatPreview .chatBox .msgArea p.reply, .steelBlue .preview .chatPreview .chatBox .msgArea a.reply {
  color: #3ba8c5; }

.steelBlue .preview .chatPreview .btn_send {
  border-color: #3ba8c5;
  background-color: #3ba8c5; }
  .steelBlue .preview .chatPreview .btn_send:hover {
    color: #3ba8c5; }
  .steelBlue .preview .chatPreview .btn_send.preview_btn:hover {
    background-color: #3ba8c5;
    color: #fff; }

.steelBlue .optpanelHeader {
  background-color: #3ba8c5; }

.steelBlue .panelWrapper.odd {
  background-color: #EBF6F9; }

.steelBlue .panelWrapper .panelHeader {
  background-color: #3ba8c5; }

.steelBlue .panelWrapper .scriptPanel > div.panelHeading {
  color: #3ba8c5; }
  .steelBlue .panelWrapper .scriptPanel > div.panelHeading p {
    color: #3ba8c5; }
    .steelBlue .panelWrapper .scriptPanel > div.panelHeading p:first-child {
      border: 1px solid #3ba8c5;
      background-color: #3ba8c5; }
  .steelBlue .panelWrapper .scriptPanel > div.panelHeading.collapsed p:first-child {
    background-image: url("assets/images/plus_icons/plus_steelblue.png") !important;
    background-size: 11px; }

.steelBlue .panelWrapper .scriptPanel .action .data .optionArea p, .steelBlue .panelWrapper .scriptPanel .action.data .optionArea p {
  color: #3ba8c5; }

.steelBlue .panelWrapper .scriptPanel .action .data .optionArea .editWrapper > div p, .steelBlue .panelWrapper .scriptPanel .action.data .optionArea .editWrapper > div p {
  color: #3ba8c5; }

.steelBlue.ruleComponent.ghostClass {
  cursor: url("https://www.google.com/intl/en_ALL/mapfiles/closedhand.cur"), all-scroll;
  cursor: -webkit-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.steelBlue.ruleComponent.even {
  background-color: #EBF6F9; }

.steelBlue .ruleComponent .optionArea .editWrapper p {
  color: #3ba8c5; }

.steelBlue .ruleComponent.even {
  background-color: #EBF6F9; }

.steelBlue .fileTransferWrapper .settingFormWrapper form .customField .progress_wrapper .progress {
  background-color: #3ba8c5; }

.steelBlue .fileTransferWrapper .scriptPanel p.action_text {
  color: #3ba8c5; }

.steelBlue .custom_btn {
  border: 1px solid #3ba8c5;
  color: #3ba8c5; }
  .steelBlue .custom_btn.btn-primary:hover {
    background-color: #3ba8c5;
    border-color: #3ba8c5;
    color: #fff; }

.steelBlue .export_csvbtn {
  color: #3ba8c5; }
  .steelBlue .export_csvbtn.btn-primary:hover a {
    color: #fff; }
  .steelBlue .export_csvbtn a:hover {
    color: #fff; }

.steelBlue .export_csvbtn {
  color: #3ba8c5; }
  .steelBlue .export_csvbtn a {
    color: #3ba8c5; }

.steelBlue .innercontent_wrapper .with-header .box .headerTitle {
  border-bottom: 4px solid #3ba8c5; }

.steelBlue .customDrpDwn .css-19bqh2r {
  fill: #3ba8c5;
  stroke: #3ba8c5; }

.steelBlue .customDrpDwn .css-1ep9fjw {
  color: #3ba8c5; }

.steelBlue label.checkbox_custom {
  border: 1px solid #3ba8c5; }
  .steelBlue label.checkbox_custom.btn-primary:hover {
    background-color: #3ba8c5;
    border-color: #3ba8c5; }
  .steelBlue label.checkbox_custom.active {
    background-color: #3ba8c5; }

.steelBlue.modal .modal-dialog .modal-header {
  border-bottom: 4px solid #3ba8c5; }
  .steelBlue.modal .modal-dialog .modal-header .close {
    color: #3ba8c5; }

.steelBlue.modal .modal-dialog .modal-footer button.btn_cancel {
  border: 1px solid #3ba8c5;
  color: #3ba8c5; }

.steelBlue.modal .modal-dialog .modal-footer button.btn_save {
  background-color: #3ba8c5; }

.steelBlue.modal .modal-dialog .modal-footer #goTologin p {
  margin-right: 85px;
  cursor: pointer;
  color: #3ba8c5; }

.steelBlue .wrapper .rcs-inner-handle {
  background-color: #3ba8c5;
  border: 1px solid #3ba8c5; }

.steelBlue .notiInfoScrollWrapper button.read_more {
  width: 119px;
  height: 39px;
  color: #fff;
  background: #3ba8c5 !important;
  border: 1px solid #3ba8c5 !important; }

.steelBlue .innercontent_wrapper .profile_wrapper .performance-wrapper .performance-content p.count {
  color: #3ba8c5; }

.steelBlue label.checkbox_custom {
  border: 1px solid #3ba8c5; }
  .steelBlue label.checkbox_custom.btn-primary:not(:disabled):not(.disabled):active, .steelBlue label.checkbox_custom.btn-primary:not(:disabled):not(.disabled).active, .show > .steelBlue label.checkbox_custom.btn-primary.dropdown-toggle, .steelBlue label.checkbox_custom.btn-primary:hover {
    background-color: #3ba8c5;
    border-color: #3ba8c5; }
  .steelBlue label.checkbox_custom.active {
    background-color: #3ba8c5; }

.steelBlue .panelWrapper .scriptPanel .action.traffic_row .active {
  border: 1px solid #3ba8c5; }

.steelBlue .panelWrapper .scriptPanel .action.traffic_row.odd {
  background-color: rgba(59, 168, 197, 0.1); }

.steelBlue .panelWrapper .scriptPanel .action.traffic_row.even {
  background-color: #ffffff; }
  .steelBlue .panelWrapper .scriptPanel .action.traffic_row.even .child_wrapper {
    background-color: rgba(59, 168, 197, 0.1); }

.steelBlue .panelWrapper .scriptPanel .action:nth-child(odd) {
  background-color: rgba(59, 168, 197, 0.1); }

.steelBlue .panelWrapper .scriptPanel .action:nth-child(even), .steelBlue .panelWrapper .scriptPanel .action.even {
  background-color: #ffffff; }

.steelBlue .panelWrapper .scriptPanel .action.archiveRow.active {
  border: 1px solid #3ba8c5; }

.steelBlue .optscriptPanel .action.traffic_row .active {
  border: 1px solid #3ba8c5; }

.steelBlue .optscriptPanel .action.traffic_row.odd {
  background-color: rgba(59, 168, 197, 0.1); }

.steelBlue .optscriptPanel .action.traffic_row.even {
  background-color: #ffffff; }
  .steelBlue .optscriptPanel .action.traffic_row.even .child_wrapper {
    background-color: rgba(59, 168, 197, 0.1); }

.steelBlue .optscriptPanel .action:nth-child(odd) {
  background-color: rgba(59, 168, 197, 0.1); }

.steelBlue .optscriptPanel .action:nth-child(even), .steelBlue .optscriptPanel .action.even {
  background-color: #ffffff; }

.steelBlue .optscriptPanel .action.archiveRow.active {
  border: 1px solid #3ba8c5; }

.steelBlue .traffic_wrapper .formBtns {
  background-color: rgba(59, 168, 197, 0.1); }

.steelBlue .customDrpDwn .css-10nd86i:hover, .steelBlue .customDrpDwn .css-10nd86i:active, .steelBlue .customDrpDwn .css-10nd86i:focus {
  border-color: #3ba8c5; }

.steelBlue .customDrpDwn .css-19bqh2r {
  fill: #3ba8c5;
  stroke: #3ba8c5; }

.steelBlue .customDrpDwn .css-15k3avv .css-z5z6cw, .steelBlue .customDrpDwn .css-11unzgr .css-z5z6cw {
  background-color: #3ba8c5; }
  .steelBlue .customDrpDwn .css-15k3avv .css-z5z6cw:hover, .steelBlue .customDrpDwn .css-15k3avv .css-z5z6cw:active, .steelBlue .customDrpDwn .css-11unzgr .css-z5z6cw:hover, .steelBlue .customDrpDwn .css-11unzgr .css-z5z6cw:active {
    background-color: #3ba8c5; }

.steelBlue .customDrpDwn .css-15k3avv .css-wqgs6e:hover, .steelBlue .customDrpDwn .css-15k3avv .css-wqgs6e:active, .steelBlue .customDrpDwn .css-11unzgr .css-wqgs6e:hover, .steelBlue .customDrpDwn .css-11unzgr .css-wqgs6e:active {
  background-color: #3ba8c5; }

.steelBlue .customDrpDwn .css-1ep9fjw {
  color: #3ba8c5; }

.steelBlue .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage img, .steelBlue .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage img {
  border: none; }

.steelBlue .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage p, .steelBlue .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage p {
  color: #3ba8c5; }

.steelBlue .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper img {
  border: none !important; }

.steelBlue .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper p {
  color: #3ba8c5; }

.steelBlue .optDeptWrapper .operatorSection .operatorsWrapper .operatorNick p {
  color: #3ba8c5; }

.steelBlue .optDeptWrapper .operatorSection .operatorsWrapper .operatorDetails p.email {
  color: #3ba8c5; }

.steelBlue .optDeptWrapper.section_Dept .panelContent .editWrapper p {
  color: #3ba8c5; }

.steelBlue .archive_pagination .pagination {
  border: 0.5 solid #3ba8c5;
  color: #3ba8c5 !important; }
  .steelBlue .archive_pagination .pagination a {
    color: #3ba8c5 !important; }

.steelBlue .archive_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #3ba8c5 solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.steelBlue .traffic_pagination .pagination {
  border: 0.5 solid #3ba8c5;
  color: #3ba8c5 !important; }
  .steelBlue .traffic_pagination .pagination a {
    color: #3ba8c5 !important; }

.steelBlue .traffic_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #3ba8c5 solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.steelBlue .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapse_wrapper .iconshow {
  border: 1px solid #3ba8c5;
  background-image: url("assets/images/minus_icons/minus_steelBlue.png");
  background-repeat: no-repeat;
  background-position: center; }

.steelBlue .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .with-image p a {
  color: #3ba8c5; }

.steelBlue .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapsed .collapse_wrapper p:first-child {
  background-image: url("assets/images/plus_icons/plus_steelblue.png");
  background-size: 8px; }

.steelBlue .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapse_wrapper .collapsed {
  background-image: url("assets/images/plus_icons/plus_steelblue.png") !important;
  background-size: 8px; }

.steelBlue .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count {
  background-color: #3ba8c5; }
  .steelBlue .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count:hover {
    border: 1px solid #3ba8c5;
    color: #3ba8c5; }

.steelBlue .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p.domain span {
  color: #3ba8c5; }

.steelBlue .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .tumbnail_footer {
  background-color: rgba(59, 168, 197, 0.1); }

.steelBlue .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper p {
  color: #3ba8c5; }

.steelBlue .chatInfoWrapper .nav.nav-tabs a.nav-item {
  background-color: #3ba8c5;
  border-right: 1px solid #fff !important; }
  .steelBlue .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
    color: #3ba8c5 !important; }

.steelBlue .chatInfoWrapper .tab-content p.hide_btn {
  color: #3ba8c5 !important; }
  .steelBlue .chatInfoWrapper .tab-content p.hide_btn svg {
    fill: #3ba8c5 !important;
    stroke: #3ba8c5 !important;
    margin-right: 9px;
    margin-top: -3px; }

.steelBlue .chatInfoWrapper .tabContent .data .webInfo, .steelBlue .chatInfoWrapper .tabContent .data .notes {
  background-color: #EBF6F9 !important; }

.steelBlue .customerChat .chatText {
  background-color: #3ba8c5; }
  .steelBlue .customerChat .chatText:before {
    content: "";
    position: absolute;
    top: -14px;
    right: 17px;
    z-index: 1;
    border: solid 7px transparent;
    border-bottom-color: #3ba8c5; }

.steelBlue .operatorChat .chatText {
  background-color: rgba(59, 168, 197, 0.1); }
  .steelBlue .operatorChat .chatText:before {
    content: "";
    position: absolute;
    top: -14px;
    left: 17px;
    z-index: 1;
    border: solid 7px transparent;
    border-bottom-color: rgba(59, 168, 197, 0.1); }

.steelBlue .historyWrapper .transcriptDetails {
  background-color: rgba(59, 168, 197, 0.1); }

.steelBlue .websitesWrapper .websitesDetails .box .webLink {
  color: #3ba8c5; }

.steelBlue .websitesWrapper .websitesDetails .box .actionsWrapper {
  background-color: #3ba8c5; }

.steelBlue .websitesWrapper .websitesDetails .box .overview .row p {
  color: #3ba8c5; }

.steelBlue .websitesWrapper .websitesDetails .box .addWrapper img {
  border: 2px solid #3ba8c5; }

.steelBlue .websitesWrapper .websitesDetails .box .addWrapper p {
  color: #3ba8c5; }

.steelBlue .websitesWrapper .websitesDetails .box .addWrapper .websitePayDollar {
  border: 3px solid #3ba8c5 !important; }

.steelBlue .textEditorWrapper .sendImgWrappger {
  background-color: #3ba8c5; }

.steelBlue .innercontent_wrapper .directLinkWrapper .uploadBtn button {
  border: 1px solid #3ba8c5 !important; }
  .steelBlue .innercontent_wrapper .directLinkWrapper .uploadBtn button p {
    color: #3ba8c5 !important; }

.steelBlue .innercontent_wrapper .directLinkWrapper.toolBarWrapper .with_btn .uploadBtn button {
  border: 1px solid #3ba8c5 !important;
  background-color: #fff; }
  .steelBlue .innercontent_wrapper .directLinkWrapper.toolBarWrapper .with_btn .uploadBtn button p {
    color: #3ba8c5 !important; }

.lightSeaGreen .existing_opcreate {
  background-color: #4cb6ac !important;
  color: #fff !important; }

.lightSeaGreen .toolBarPreviewWrapper .positionIcons.selected {
  background-color: #4cb6ac !important;
  border: 1px solid #4cb6ac !important; }

.lightSeaGreen .modal-dialog .modal-content .modal-header button.chatInfoBtn {
  border: 1px solid #4cb6ac !important;
  background-color: #ffffff !important;
  color: #4cb6ac !important; }

.lightSeaGreen .widgetPosition p span.selected {
  color: #ffffff;
  background-color: #4cb6ac !important; }

.lightSeaGreen .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span {
  color: #4cb6ac !important; }
  .lightSeaGreen .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span.selected {
    color: #000000 !important; }

.lightSeaGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw {
  box-shadow: #4cb6ac !important; }
  .lightSeaGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:hover, .lightSeaGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:active, .lightSeaGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:focus {
    border-color: #4cb6ac !important; }

.lightSeaGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-19bqh2r {
  fill: #4cb6ac !important;
  stroke: #4cb6ac !important; }

.lightSeaGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw, .lightSeaGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw {
  background-color: #4cb6ac; }
  .lightSeaGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw:hover, .lightSeaGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw:active, .lightSeaGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw:hover, .lightSeaGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw:active {
    background-color: #4cb6ac; }

.lightSeaGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-wqgs6e:hover, .lightSeaGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-wqgs6e:active, .lightSeaGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-wqgs6e:hover, .lightSeaGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-wqgs6e:active {
  background-color: #4cb6ac; }

.lightSeaGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-1ep9fjw {
  color: #4cb6ac; }

.lightSeaGreen .modal-dialog .modal-content .modal-header button.chatInfoBtn:hover {
  color: #ffff !important;
  background-color: #4cb6ac !important; }

.lightSeaGreen .divider {
  color: #4cb6ac !important; }

.lightSeaGreen .expire_account {
  color: #4cb6ac !important; }

.lightSeaGreen .archive_pagination ul li a {
  color: #4cb6ac !important;
  border: none;
  outline: none;
  box-shadow: none; }

.lightSeaGreen .archive_pagination ul li.active {
  border-color: #4cb6ac; }

.lightSeaGreen .dropdown-item:hover {
  background-color: #4cb6ac !important;
  color: #f7f5f5 !important;
  font-weight: 700 !important; }

.lightSeaGreen .rcs-custom-scroll .rcs-inner-handle {
  background-color: #4cb6ac !important;
  border-color: #4cb6ac !important; }

.lightSeaGreen .checkout {
  background-color: #4cb6ac; }

.lightSeaGreen .profileStatus .status p.dropdown-item {
  color: #000; }

.lightSeaGreen .menuList {
  height: 54px;
  background-color: #fff;
  cursor: pointer; }
  .lightSeaGreen .menuList.selected {
    background-color: #4cb6ac !important; }
    .lightSeaGreen .menuList.selected p {
      color: #fff; }
    .lightSeaGreen .menuList.selected .lineWrapper .line1 {
      background-color: #fff;
      border-color: #fff; }
    .lightSeaGreen .menuList.selected .lineWrapper .line2 {
      border-color: #fff; }
      .lightSeaGreen .menuList.selected .lineWrapper .line2.mask {
        opacity: 1;
        background-color: #fff; }
  .lightSeaGreen .menuList p {
    width: 100%;
    float: left;
    color: #293037;
    font-family: "Segoe UI Semi Bold";
    font-size: 13px;
    font-weight: 700; }
  .lightSeaGreen .menuList .lineWrapper {
    position: absolute;
    right: 21px; }
    .lightSeaGreen .menuList .lineWrapper .line1 {
      width: 35px;
      height: 20px;
      border-bottom: 1px solid #dedede;
      -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
      position: absolute; }
      .lightSeaGreen .menuList .lineWrapper .line1.selectedNext {
        background-color: #4cb6ac !important;
        border-color: #fff; }
    .lightSeaGreen .menuList .lineWrapper .line2 {
      width: 33px;
      height: 20px;
      border-bottom: 1px solid #dedede;
      -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
      position: absolute;
      top: 17px;
      left: -16px; }
      .lightSeaGreen .menuList .lineWrapper .line2.mask {
        opacity: 0;
        left: -1px;
        width: 37px;
        top: 29px;
        background-color: #fff;
        border: 0; }
        .lightSeaGreen .menuList .lineWrapper .line2.mask.selectedNext {
          opacity: 1;
          background-color: #4cb6ac !important; }
      .lightSeaGreen .menuList .lineWrapper .line2.selectedNext {
        border-color: #fff; }

.lightSeaGreen .carousel {
  padding: 12px 0px; }
  .lightSeaGreen .carousel img.carouselBtn {
    position: absolute;
    cursor: pointer;
    top: 90px; }
    .lightSeaGreen .carousel img.carouselBtn.carousel-left {
      left: 30px; }
  .lightSeaGreen .carousel ul {
    margin-left: 0;
    margin-right: 0;
    transition: all 1s; }
    .lightSeaGreen .carousel ul.widgetMode li {
      opacity: 0.5;
      float: left; }
      .lightSeaGreen .carousel ul.widgetMode li.selected, .lightSeaGreen .carousel ul.widgetMode li:hover {
        opacity: 1;
        border: 3px solid #4cb6ac !important; }
        .lightSeaGreen .carousel ul.widgetMode li.selected h4, .lightSeaGreen .carousel ul.widgetMode li:hover h4 {
          background-color: #4cb6ac !important;
          color: #ffffff; }
  .lightSeaGreen .carousel .carousel-cell {
    cursor: pointer;
    width: 210px;
    height: 192px;
    box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
    border-radius: 20px;
    background-color: #ffffff; }
    .lightSeaGreen .carousel .carousel-cell.opacity_0 {
      opacity: 0;
      margin-right: 60px;
      transition: opacity 2s; }
    .lightSeaGreen .carousel .carousel-cell:hover {
      border: 3px solid #4cb6ac !important; }
      .lightSeaGreen .carousel .carousel-cell:hover h4 {
        background-color: #4cb6ac !important;
        color: #ffffff; }
    .lightSeaGreen .carousel .carousel-cell h4 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      padding: 22px 0 18px;
      border-bottom: 4px solid #4cb6ac !important;
      margin: 0;
      border-radius: 15px 15px 0 0; }
    .lightSeaGreen .carousel .carousel-cell .img-wrapper {
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      width: 210px; }

.lightSeaGreen #sidebar > ul.sideMenu {
  background-color: #4cb6ac; }
  .lightSeaGreen #sidebar > ul.sideMenu li.active {
    background-color: #2e968c; }

.lightSeaGreen #sidebar .subMenu li.parent_list > ul li.active {
  background-color: #2e968c; }

.lightSeaGreen #sidebar.operator_menu .subMenu li.parent_list.active {
  background-color: #2e968c; }

.lightSeaGreen .image_upload button {
  border: 1px solid #4cb6ac; }

.lightSeaGreen .image_upload p {
  color: #4cb6ac; }

.lightSeaGreen .profileImg .img_delete p:last-child {
  color: #4cb6ac; }

.lightSeaGreen .save_BTN {
  border: 1px solid #4cb6ac;
  background-color: #4cb6ac !important; }

.lightSeaGreen .formBtns Button.btn_clr {
  border: 1px solid #4cb6ac;
  color: #4cb6ac; }

.lightSeaGreen .formBtns Button.btn_save {
  background-color: #4cb6ac; }

.lightSeaGreen .overview ol li::before {
  border: 1px solid #4cb6ac;
  color: #4cb6ac; }

.lightSeaGreen .overview ol li p a:not([href]):not([tabindex]) {
  color: #4cb6ac; }

.lightSeaGreen .scriptWrapper.innercontent_wrapper .box.editScriptSection .headerTitle {
  border-bottom: 4px solid #4cb6ac; }

.lightSeaGreen .chatWrapper .settingFormWrapper .headerTitle h4 span {
  color: #4cb6ac !important; }

.lightSeaGreen .preview .chatPreview .headerTitle.active {
  background-color: #4cb6ac; }

.lightSeaGreen .preview .chatPreview .chatBox .msgArea p.reply, .lightSeaGreen .preview .chatPreview .chatBox .msgArea a.reply {
  color: #4cb6ac; }

.lightSeaGreen .preview .chatPreview .btn_send {
  border-color: #4cb6ac;
  background-color: #4cb6ac; }
  .lightSeaGreen .preview .chatPreview .btn_send:hover {
    color: #4cb6ac; }
  .lightSeaGreen .preview .chatPreview .btn_send.preview_btn:hover {
    background-color: #4cb6ac;
    color: #fff; }

.lightSeaGreen .optpanelHeader {
  background-color: #4cb6ac; }

.lightSeaGreen .panelWrapper.odd {
  background-color: #EDF8F7; }

.lightSeaGreen .panelWrapper .panelHeader {
  background-color: #4cb6ac; }

.lightSeaGreen .panelWrapper .scriptPanel > div.panelHeading {
  color: #4cb6ac; }
  .lightSeaGreen .panelWrapper .scriptPanel > div.panelHeading p {
    color: #4cb6ac; }
    .lightSeaGreen .panelWrapper .scriptPanel > div.panelHeading p:first-child {
      border: 1px solid #4cb6ac;
      background-color: #4cb6ac; }
  .lightSeaGreen .panelWrapper .scriptPanel > div.panelHeading.collapsed p:first-child {
    background-image: url("assets/images/plus_icons/plus_seagreen.png") !important;
    background-size: 11px; }

.lightSeaGreen .panelWrapper .scriptPanel .action .data .optionArea p, .lightSeaGreen .panelWrapper .scriptPanel .action.data .optionArea p {
  color: #4cb6ac; }

.lightSeaGreen .panelWrapper .scriptPanel .action .data .optionArea .editWrapper > div p, .lightSeaGreen .panelWrapper .scriptPanel .action.data .optionArea .editWrapper > div p {
  color: #4cb6ac; }

.lightSeaGreen.ruleComponent.ghostClass {
  cursor: url("https://www.google.com/intl/en_ALL/mapfiles/closedhand.cur"), all-scroll;
  cursor: -webkit-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.lightSeaGreen.ruleComponent.even {
  background-color: #EDF8F7; }

.lightSeaGreen .ruleComponent .optionArea .editWrapper p {
  color: #4cb6ac; }

.lightSeaGreen .ruleComponent.even {
  background-color: #EDF8F7; }

.lightSeaGreen .fileTransferWrapper .settingFormWrapper form .customField .progress_wrapper .progress {
  background-color: #4cb6ac; }

.lightSeaGreen .fileTransferWrapper .scriptPanel p.action_text {
  color: #4cb6ac; }

.lightSeaGreen .custom_btn {
  border: 1px solid #4cb6ac;
  color: #4cb6ac; }
  .lightSeaGreen .custom_btn.btn-primary:hover {
    background-color: #4cb6ac;
    border-color: #4cb6ac;
    color: #fff; }

.lightSeaGreen .export_csvbtn {
  color: #4cb6ac; }
  .lightSeaGreen .export_csvbtn.btn-primary:hover a {
    color: #fff; }
  .lightSeaGreen .export_csvbtn a:hover {
    color: #fff; }

.lightSeaGreen .export_csvbtn {
  color: #4cb6ac; }
  .lightSeaGreen .export_csvbtn a {
    color: #4cb6ac; }

.lightSeaGreen .innercontent_wrapper .with-header .box .headerTitle {
  border-bottom: 4px solid #4cb6ac; }

.lightSeaGreen .customDrpDwn .css-19bqh2r {
  fill: #4cb6ac;
  stroke: #4cb6ac; }

.lightSeaGreen .customDrpDwn .css-1ep9fjw {
  color: #4cb6ac; }

.lightSeaGreen label.checkbox_custom {
  border: 1px solid #4cb6ac; }
  .lightSeaGreen label.checkbox_custom.btn-primary:hover {
    background-color: #4cb6ac;
    border-color: #4cb6ac; }
  .lightSeaGreen label.checkbox_custom.active {
    background-color: #4cb6ac; }

.lightSeaGreen.modal .modal-dialog .modal-header {
  border-bottom: 4px solid #4cb6ac; }
  .lightSeaGreen.modal .modal-dialog .modal-header .close {
    color: #4cb6ac; }

.lightSeaGreen.modal .modal-dialog .modal-footer button.btn_cancel {
  border: 1px solid #4cb6ac;
  color: #4cb6ac; }

.lightSeaGreen.modal .modal-dialog .modal-footer button.btn_save {
  background-color: #4cb6ac; }

.lightSeaGreen.modal .modal-dialog .modal-footer #goTologin p {
  margin-right: 85px;
  cursor: pointer;
  color: #4cb6ac; }

.lightSeaGreen .wrapper .rcs-inner-handle {
  background-color: #4cb6ac;
  border: 1px solid #4cb6ac; }

.lightSeaGreen .notiInfoScrollWrapper button.read_more {
  width: 119px;
  height: 39px;
  color: #fff;
  background: #4cb6ac !important;
  border: 1px solid #4cb6ac !important; }

.lightSeaGreen .innercontent_wrapper .profile_wrapper .performance-wrapper .performance-content p.count {
  color: #4cb6ac; }

.lightSeaGreen label.checkbox_custom {
  border: 1px solid #4cb6ac; }
  .lightSeaGreen label.checkbox_custom.btn-primary:not(:disabled):not(.disabled):active, .lightSeaGreen label.checkbox_custom.btn-primary:not(:disabled):not(.disabled).active, .show > .lightSeaGreen label.checkbox_custom.btn-primary.dropdown-toggle, .lightSeaGreen label.checkbox_custom.btn-primary:hover {
    background-color: #4cb6ac;
    border-color: #4cb6ac; }
  .lightSeaGreen label.checkbox_custom.active {
    background-color: #4cb6ac; }

.lightSeaGreen .panelWrapper .scriptPanel .action.traffic_row .active {
  border: 1px solid #4cb6ac; }

.lightSeaGreen .panelWrapper .scriptPanel .action.traffic_row.odd {
  background-color: rgba(76, 182, 172, 0.1); }

.lightSeaGreen .panelWrapper .scriptPanel .action.traffic_row.even {
  background-color: #ffffff; }
  .lightSeaGreen .panelWrapper .scriptPanel .action.traffic_row.even .child_wrapper {
    background-color: rgba(76, 182, 172, 0.1); }

.lightSeaGreen .panelWrapper .scriptPanel .action:nth-child(odd) {
  background-color: rgba(76, 182, 172, 0.1); }

.lightSeaGreen .panelWrapper .scriptPanel .action:nth-child(even), .lightSeaGreen .panelWrapper .scriptPanel .action.even {
  background-color: #ffffff; }

.lightSeaGreen .panelWrapper .scriptPanel .action.archiveRow.active {
  border: 1px solid #4cb6ac; }

.lightSeaGreen .optscriptPanel .action.traffic_row .active {
  border: 1px solid #4cb6ac; }

.lightSeaGreen .optscriptPanel .action.traffic_row.odd {
  background-color: rgba(76, 182, 172, 0.1); }

.lightSeaGreen .optscriptPanel .action.traffic_row.even {
  background-color: #ffffff; }
  .lightSeaGreen .optscriptPanel .action.traffic_row.even .child_wrapper {
    background-color: rgba(76, 182, 172, 0.1); }

.lightSeaGreen .optscriptPanel .action:nth-child(odd) {
  background-color: rgba(76, 182, 172, 0.1); }

.lightSeaGreen .optscriptPanel .action:nth-child(even), .lightSeaGreen .optscriptPanel .action.even {
  background-color: #ffffff; }

.lightSeaGreen .optscriptPanel .action.archiveRow.active {
  border: 1px solid #4cb6ac; }

.lightSeaGreen .traffic_wrapper .formBtns {
  background-color: rgba(76, 182, 172, 0.1); }

.lightSeaGreen .customDrpDwn .css-10nd86i:hover, .lightSeaGreen .customDrpDwn .css-10nd86i:active, .lightSeaGreen .customDrpDwn .css-10nd86i:focus {
  border-color: #4cb6ac; }

.lightSeaGreen .customDrpDwn .css-19bqh2r {
  fill: #4cb6ac;
  stroke: #4cb6ac; }

.lightSeaGreen .customDrpDwn .css-15k3avv .css-z5z6cw, .lightSeaGreen .customDrpDwn .css-11unzgr .css-z5z6cw {
  background-color: #4cb6ac; }
  .lightSeaGreen .customDrpDwn .css-15k3avv .css-z5z6cw:hover, .lightSeaGreen .customDrpDwn .css-15k3avv .css-z5z6cw:active, .lightSeaGreen .customDrpDwn .css-11unzgr .css-z5z6cw:hover, .lightSeaGreen .customDrpDwn .css-11unzgr .css-z5z6cw:active {
    background-color: #4cb6ac; }

.lightSeaGreen .customDrpDwn .css-15k3avv .css-wqgs6e:hover, .lightSeaGreen .customDrpDwn .css-15k3avv .css-wqgs6e:active, .lightSeaGreen .customDrpDwn .css-11unzgr .css-wqgs6e:hover, .lightSeaGreen .customDrpDwn .css-11unzgr .css-wqgs6e:active {
  background-color: #4cb6ac; }

.lightSeaGreen .customDrpDwn .css-1ep9fjw {
  color: #4cb6ac; }

.lightSeaGreen .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage img, .lightSeaGreen .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage img {
  border: none; }

.lightSeaGreen .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage p, .lightSeaGreen .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage p {
  color: #4cb6ac; }

.lightSeaGreen .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper img {
  border: none !important; }

.lightSeaGreen .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper p {
  color: #4cb6ac; }

.lightSeaGreen .optDeptWrapper .operatorSection .operatorsWrapper .operatorNick p {
  color: #4cb6ac; }

.lightSeaGreen .optDeptWrapper .operatorSection .operatorsWrapper .operatorDetails p.email {
  color: #4cb6ac; }

.lightSeaGreen .optDeptWrapper.section_Dept .panelContent .editWrapper p {
  color: #4cb6ac; }

.lightSeaGreen .archive_pagination .pagination {
  border: 0.5 solid #4cb6ac;
  color: #4cb6ac !important; }
  .lightSeaGreen .archive_pagination .pagination a {
    color: #4cb6ac !important; }

.lightSeaGreen .archive_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #4cb6ac solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.lightSeaGreen .traffic_pagination .pagination {
  border: 0.5 solid #4cb6ac;
  color: #4cb6ac !important; }
  .lightSeaGreen .traffic_pagination .pagination a {
    color: #4cb6ac !important; }

.lightSeaGreen .traffic_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #4cb6ac solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.lightSeaGreen .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapse_wrapper .iconshow {
  border: 1px solid #4cb6ac;
  background-image: url("assets/images/minus_icons/minus_lightSeaGreen.png");
  background-repeat: no-repeat;
  background-position: center; }

.lightSeaGreen .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .with-image p a {
  color: #4cb6ac; }

.lightSeaGreen .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapsed .collapse_wrapper p:first-child {
  background-image: url("assets/images/plus_icons/plus_seagreen.png");
  background-size: 8px; }

.lightSeaGreen .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapse_wrapper .collapsed {
  background-image: url("assets/images/plus_icons/plus_seagreen.png") !important;
  background-size: 8px; }

.lightSeaGreen .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count {
  background-color: #4cb6ac; }
  .lightSeaGreen .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count:hover {
    border: 1px solid #4cb6ac;
    color: #4cb6ac; }

.lightSeaGreen .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p.domain span {
  color: #4cb6ac; }

.lightSeaGreen .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .tumbnail_footer {
  background-color: rgba(76, 182, 172, 0.1); }

.lightSeaGreen .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper p {
  color: #4cb6ac; }

.lightSeaGreen .chatInfoWrapper .nav.nav-tabs a.nav-item {
  background-color: #4cb6ac;
  border-right: 1px solid #fff !important; }
  .lightSeaGreen .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
    color: #4cb6ac !important; }

.lightSeaGreen .chatInfoWrapper .tab-content p.hide_btn {
  color: #4cb6ac !important; }
  .lightSeaGreen .chatInfoWrapper .tab-content p.hide_btn svg {
    fill: #4cb6ac !important;
    stroke: #4cb6ac !important;
    margin-right: 9px;
    margin-top: -3px; }

.lightSeaGreen .chatInfoWrapper .tabContent .data .webInfo, .lightSeaGreen .chatInfoWrapper .tabContent .data .notes {
  background-color: #EDF8F7 !important; }

.lightSeaGreen .customerChat .chatText {
  background-color: #4cb6ac; }
  .lightSeaGreen .customerChat .chatText:before {
    content: "";
    position: absolute;
    top: -14px;
    right: 17px;
    z-index: 1;
    border: solid 7px transparent;
    border-bottom-color: #4cb6ac; }

.lightSeaGreen .operatorChat .chatText {
  background-color: rgba(76, 182, 172, 0.1); }
  .lightSeaGreen .operatorChat .chatText:before {
    content: "";
    position: absolute;
    top: -14px;
    left: 17px;
    z-index: 1;
    border: solid 7px transparent;
    border-bottom-color: rgba(76, 182, 172, 0.1); }

.lightSeaGreen .historyWrapper .transcriptDetails {
  background-color: rgba(76, 182, 172, 0.1); }

.lightSeaGreen .websitesWrapper .websitesDetails .box .webLink {
  color: #4cb6ac; }

.lightSeaGreen .websitesWrapper .websitesDetails .box .actionsWrapper {
  background-color: #4cb6ac; }

.lightSeaGreen .websitesWrapper .websitesDetails .box .overview .row p {
  color: #4cb6ac; }

.lightSeaGreen .websitesWrapper .websitesDetails .box .addWrapper img {
  border: 2px solid #4cb6ac; }

.lightSeaGreen .websitesWrapper .websitesDetails .box .addWrapper p {
  color: #4cb6ac; }

.lightSeaGreen .websitesWrapper .websitesDetails .box .addWrapper .websitePayDollar {
  border: 3px solid #4cb6ac !important; }

.lightSeaGreen .textEditorWrapper .sendImgWrappger {
  background-color: #4cb6ac; }

.lightSeaGreen .innercontent_wrapper .directLinkWrapper .uploadBtn button {
  border: 1px solid #4cb6ac !important; }
  .lightSeaGreen .innercontent_wrapper .directLinkWrapper .uploadBtn button p {
    color: #4cb6ac !important; }

.lightSeaGreen .innercontent_wrapper .directLinkWrapper.toolBarWrapper .with_btn .uploadBtn button {
  border: 1px solid #4cb6ac !important;
  background-color: #fff; }
  .lightSeaGreen .innercontent_wrapper .directLinkWrapper.toolBarWrapper .with_btn .uploadBtn button p {
    color: #4cb6ac !important; }

.deepPink .existing_opcreate {
  background-color: #ec407a !important;
  color: #fff !important; }

.deepPink .toolBarPreviewWrapper .positionIcons.selected {
  background-color: #ec407a !important;
  border: 1px solid #ec407a !important; }

.deepPink .modal-dialog .modal-content .modal-header button.chatInfoBtn {
  border: 1px solid #ec407a !important;
  background-color: #ffffff !important;
  color: #ec407a !important; }

.deepPink .widgetPosition p span.selected {
  color: #ffffff;
  background-color: #ec407a !important; }

.deepPink .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span {
  color: #ec407a !important; }
  .deepPink .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span.selected {
    color: #000000 !important; }

.deepPink .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw {
  box-shadow: #ec407a !important; }
  .deepPink .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:hover, .deepPink .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:active, .deepPink .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:focus {
    border-color: #ec407a !important; }

.deepPink .invitationRulesWrapper .editRulesSection .relatedDropDown .css-19bqh2r {
  fill: #ec407a !important;
  stroke: #ec407a !important; }

.deepPink .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw, .deepPink .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw {
  background-color: #ec407a; }
  .deepPink .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw:hover, .deepPink .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw:active, .deepPink .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw:hover, .deepPink .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw:active {
    background-color: #ec407a; }

.deepPink .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-wqgs6e:hover, .deepPink .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-wqgs6e:active, .deepPink .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-wqgs6e:hover, .deepPink .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-wqgs6e:active {
  background-color: #ec407a; }

.deepPink .invitationRulesWrapper .editRulesSection .relatedDropDown .css-1ep9fjw {
  color: #ec407a; }

.deepPink .modal-dialog .modal-content .modal-header button.chatInfoBtn:hover {
  color: #ffff !important;
  background-color: #ec407a !important; }

.deepPink .divider {
  color: #ec407a !important; }

.deepPink .expire_account {
  color: #ec407a !important; }

.deepPink .archive_pagination ul li a {
  color: #ec407a !important;
  border: none;
  outline: none;
  box-shadow: none; }

.deepPink .archive_pagination ul li.active {
  border-color: #ec407a; }

.deepPink .dropdown-item:hover {
  background-color: #ec407a !important;
  color: #f7f5f5 !important;
  font-weight: 700 !important; }

.deepPink .rcs-custom-scroll .rcs-inner-handle {
  background-color: #ec407a !important;
  border-color: #ec407a !important; }

.deepPink .checkout {
  background-color: #ec407a; }

.deepPink .profileStatus .status p.dropdown-item {
  color: #000; }

.deepPink .menuList {
  height: 54px;
  background-color: #fff;
  cursor: pointer; }
  .deepPink .menuList.selected {
    background-color: #ec407a !important; }
    .deepPink .menuList.selected p {
      color: #fff; }
    .deepPink .menuList.selected .lineWrapper .line1 {
      background-color: #fff;
      border-color: #fff; }
    .deepPink .menuList.selected .lineWrapper .line2 {
      border-color: #fff; }
      .deepPink .menuList.selected .lineWrapper .line2.mask {
        opacity: 1;
        background-color: #fff; }
  .deepPink .menuList p {
    width: 100%;
    float: left;
    color: #293037;
    font-family: "Segoe UI Semi Bold";
    font-size: 13px;
    font-weight: 700; }
  .deepPink .menuList .lineWrapper {
    position: absolute;
    right: 21px; }
    .deepPink .menuList .lineWrapper .line1 {
      width: 35px;
      height: 20px;
      border-bottom: 1px solid #dedede;
      -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
      position: absolute; }
      .deepPink .menuList .lineWrapper .line1.selectedNext {
        background-color: #ec407a !important;
        border-color: #fff; }
    .deepPink .menuList .lineWrapper .line2 {
      width: 33px;
      height: 20px;
      border-bottom: 1px solid #dedede;
      -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
      position: absolute;
      top: 17px;
      left: -16px; }
      .deepPink .menuList .lineWrapper .line2.mask {
        opacity: 0;
        left: -1px;
        width: 37px;
        top: 29px;
        background-color: #fff;
        border: 0; }
        .deepPink .menuList .lineWrapper .line2.mask.selectedNext {
          opacity: 1;
          background-color: #ec407a !important; }
      .deepPink .menuList .lineWrapper .line2.selectedNext {
        border-color: #fff; }

.deepPink .carousel {
  padding: 12px 0px; }
  .deepPink .carousel img.carouselBtn {
    position: absolute;
    cursor: pointer;
    top: 90px; }
    .deepPink .carousel img.carouselBtn.carousel-left {
      left: 30px; }
  .deepPink .carousel ul {
    margin-left: 0;
    margin-right: 0;
    transition: all 1s; }
    .deepPink .carousel ul.widgetMode li {
      opacity: 0.5;
      float: left; }
      .deepPink .carousel ul.widgetMode li.selected, .deepPink .carousel ul.widgetMode li:hover {
        opacity: 1;
        border: 3px solid #ec407a !important; }
        .deepPink .carousel ul.widgetMode li.selected h4, .deepPink .carousel ul.widgetMode li:hover h4 {
          background-color: #ec407a !important;
          color: #ffffff; }
  .deepPink .carousel .carousel-cell {
    cursor: pointer;
    width: 210px;
    height: 192px;
    box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
    border-radius: 20px;
    background-color: #ffffff; }
    .deepPink .carousel .carousel-cell.opacity_0 {
      opacity: 0;
      margin-right: 60px;
      transition: opacity 2s; }
    .deepPink .carousel .carousel-cell:hover {
      border: 3px solid #ec407a !important; }
      .deepPink .carousel .carousel-cell:hover h4 {
        background-color: #ec407a !important;
        color: #ffffff; }
    .deepPink .carousel .carousel-cell h4 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      padding: 22px 0 18px;
      border-bottom: 4px solid #ec407a !important;
      margin: 0;
      border-radius: 15px 15px 0 0; }
    .deepPink .carousel .carousel-cell .img-wrapper {
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      width: 210px; }

.deepPink #sidebar > ul.sideMenu {
  background-color: #ec407a; }
  .deepPink #sidebar > ul.sideMenu li.active {
    background-color: #d22c64; }

.deepPink #sidebar .subMenu li.parent_list > ul li.active {
  background-color: #d22c64; }

.deepPink #sidebar.operator_menu .subMenu li.parent_list.active {
  background-color: #d22c64; }

.deepPink .image_upload button {
  border: 1px solid #ec407a; }

.deepPink .image_upload p {
  color: #ec407a; }

.deepPink .profileImg .img_delete p:last-child {
  color: #ec407a; }

.deepPink .save_BTN {
  border: 1px solid #ec407a;
  background-color: #ec407a !important; }

.deepPink .formBtns Button.btn_clr {
  border: 1px solid #ec407a;
  color: #ec407a; }

.deepPink .formBtns Button.btn_save {
  background-color: #ec407a; }

.deepPink .overview ol li::before {
  border: 1px solid #ec407a;
  color: #ec407a; }

.deepPink .overview ol li p a:not([href]):not([tabindex]) {
  color: #ec407a; }

.deepPink .scriptWrapper.innercontent_wrapper .box.editScriptSection .headerTitle {
  border-bottom: 4px solid #ec407a; }

.deepPink .chatWrapper .settingFormWrapper .headerTitle h4 span {
  color: #ec407a !important; }

.deepPink .preview .chatPreview .headerTitle.active {
  background-color: #ec407a; }

.deepPink .preview .chatPreview .chatBox .msgArea p.reply, .deepPink .preview .chatPreview .chatBox .msgArea a.reply {
  color: #ec407a; }

.deepPink .preview .chatPreview .btn_send {
  border-color: #ec407a;
  background-color: #ec407a; }
  .deepPink .preview .chatPreview .btn_send:hover {
    color: #ec407a; }
  .deepPink .preview .chatPreview .btn_send.preview_btn:hover {
    background-color: #ec407a;
    color: #fff; }

.deepPink .optpanelHeader {
  background-color: #ec407a; }

.deepPink .panelWrapper.odd {
  background-color: #FDECF1; }

.deepPink .panelWrapper .panelHeader {
  background-color: #ec407a; }

.deepPink .panelWrapper .scriptPanel > div.panelHeading {
  color: #ec407a; }
  .deepPink .panelWrapper .scriptPanel > div.panelHeading p {
    color: #ec407a; }
    .deepPink .panelWrapper .scriptPanel > div.panelHeading p:first-child {
      border: 1px solid #ec407a;
      background-color: #ec407a; }
  .deepPink .panelWrapper .scriptPanel > div.panelHeading.collapsed p:first-child {
    background-image: url("assets/images/plus_icons/plus_pink.png") !important;
    background-size: 11px; }

.deepPink .panelWrapper .scriptPanel .action .data .optionArea p, .deepPink .panelWrapper .scriptPanel .action.data .optionArea p {
  color: #ec407a; }

.deepPink .panelWrapper .scriptPanel .action .data .optionArea .editWrapper > div p, .deepPink .panelWrapper .scriptPanel .action.data .optionArea .editWrapper > div p {
  color: #ec407a; }

.deepPink.ruleComponent.ghostClass {
  cursor: url("https://www.google.com/intl/en_ALL/mapfiles/closedhand.cur"), all-scroll;
  cursor: -webkit-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.deepPink.ruleComponent.even {
  background-color: #FDECF1; }

.deepPink .ruleComponent .optionArea .editWrapper p {
  color: #ec407a; }

.deepPink .ruleComponent.even {
  background-color: #FDECF1; }

.deepPink .fileTransferWrapper .settingFormWrapper form .customField .progress_wrapper .progress {
  background-color: #ec407a; }

.deepPink .fileTransferWrapper .scriptPanel p.action_text {
  color: #ec407a; }

.deepPink .custom_btn {
  border: 1px solid #ec407a;
  color: #ec407a; }
  .deepPink .custom_btn.btn-primary:hover {
    background-color: #ec407a;
    border-color: #ec407a;
    color: #fff; }

.deepPink .export_csvbtn {
  color: #ec407a; }
  .deepPink .export_csvbtn.btn-primary:hover a {
    color: #fff; }
  .deepPink .export_csvbtn a:hover {
    color: #fff; }

.deepPink .export_csvbtn {
  color: #ec407a; }
  .deepPink .export_csvbtn a {
    color: #ec407a; }

.deepPink .innercontent_wrapper .with-header .box .headerTitle {
  border-bottom: 4px solid #ec407a; }

.deepPink .customDrpDwn .css-19bqh2r {
  fill: #ec407a;
  stroke: #ec407a; }

.deepPink .customDrpDwn .css-1ep9fjw {
  color: #ec407a; }

.deepPink label.checkbox_custom {
  border: 1px solid #ec407a; }
  .deepPink label.checkbox_custom.btn-primary:hover {
    background-color: #ec407a;
    border-color: #ec407a; }
  .deepPink label.checkbox_custom.active {
    background-color: #ec407a; }

.deepPink.modal .modal-dialog .modal-header {
  border-bottom: 4px solid #ec407a; }
  .deepPink.modal .modal-dialog .modal-header .close {
    color: #ec407a; }

.deepPink.modal .modal-dialog .modal-footer button.btn_cancel {
  border: 1px solid #ec407a;
  color: #ec407a; }

.deepPink.modal .modal-dialog .modal-footer button.btn_save {
  background-color: #ec407a; }

.deepPink.modal .modal-dialog .modal-footer #goTologin p {
  margin-right: 85px;
  cursor: pointer;
  color: #ec407a; }

.deepPink .wrapper .rcs-inner-handle {
  background-color: #ec407a;
  border: 1px solid #ec407a; }

.deepPink .notiInfoScrollWrapper button.read_more {
  width: 119px;
  height: 39px;
  color: #fff;
  background: #ec407a !important;
  border: 1px solid #ec407a !important; }

.deepPink .innercontent_wrapper .profile_wrapper .performance-wrapper .performance-content p.count {
  color: #ec407a; }

.deepPink label.checkbox_custom {
  border: 1px solid #ec407a; }
  .deepPink label.checkbox_custom.btn-primary:not(:disabled):not(.disabled):active, .deepPink label.checkbox_custom.btn-primary:not(:disabled):not(.disabled).active, .show > .deepPink label.checkbox_custom.btn-primary.dropdown-toggle, .deepPink label.checkbox_custom.btn-primary:hover {
    background-color: #ec407a;
    border-color: #ec407a; }
  .deepPink label.checkbox_custom.active {
    background-color: #ec407a; }

.deepPink .panelWrapper .scriptPanel .action.traffic_row .active {
  border: 1px solid #ec407a; }

.deepPink .panelWrapper .scriptPanel .action.traffic_row.odd {
  background-color: rgba(236, 64, 122, 0.1); }

.deepPink .panelWrapper .scriptPanel .action.traffic_row.even {
  background-color: #ffffff; }
  .deepPink .panelWrapper .scriptPanel .action.traffic_row.even .child_wrapper {
    background-color: rgba(236, 64, 122, 0.1); }

.deepPink .panelWrapper .scriptPanel .action:nth-child(odd) {
  background-color: rgba(236, 64, 122, 0.1); }

.deepPink .panelWrapper .scriptPanel .action:nth-child(even), .deepPink .panelWrapper .scriptPanel .action.even {
  background-color: #ffffff; }

.deepPink .panelWrapper .scriptPanel .action.archiveRow.active {
  border: 1px solid #ec407a; }

.deepPink .optscriptPanel .action.traffic_row .active {
  border: 1px solid #ec407a; }

.deepPink .optscriptPanel .action.traffic_row.odd {
  background-color: rgba(236, 64, 122, 0.1); }

.deepPink .optscriptPanel .action.traffic_row.even {
  background-color: #ffffff; }
  .deepPink .optscriptPanel .action.traffic_row.even .child_wrapper {
    background-color: rgba(236, 64, 122, 0.1); }

.deepPink .optscriptPanel .action:nth-child(odd) {
  background-color: rgba(236, 64, 122, 0.1); }

.deepPink .optscriptPanel .action:nth-child(even), .deepPink .optscriptPanel .action.even {
  background-color: #ffffff; }

.deepPink .optscriptPanel .action.archiveRow.active {
  border: 1px solid #ec407a; }

.deepPink .traffic_wrapper .formBtns {
  background-color: rgba(236, 64, 122, 0.1); }

.deepPink .customDrpDwn .css-10nd86i:hover, .deepPink .customDrpDwn .css-10nd86i:active, .deepPink .customDrpDwn .css-10nd86i:focus {
  border-color: #ec407a; }

.deepPink .customDrpDwn .css-19bqh2r {
  fill: #ec407a;
  stroke: #ec407a; }

.deepPink .customDrpDwn .css-15k3avv .css-z5z6cw, .deepPink .customDrpDwn .css-11unzgr .css-z5z6cw {
  background-color: #ec407a; }
  .deepPink .customDrpDwn .css-15k3avv .css-z5z6cw:hover, .deepPink .customDrpDwn .css-15k3avv .css-z5z6cw:active, .deepPink .customDrpDwn .css-11unzgr .css-z5z6cw:hover, .deepPink .customDrpDwn .css-11unzgr .css-z5z6cw:active {
    background-color: #ec407a; }

.deepPink .customDrpDwn .css-15k3avv .css-wqgs6e:hover, .deepPink .customDrpDwn .css-15k3avv .css-wqgs6e:active, .deepPink .customDrpDwn .css-11unzgr .css-wqgs6e:hover, .deepPink .customDrpDwn .css-11unzgr .css-wqgs6e:active {
  background-color: #ec407a; }

.deepPink .customDrpDwn .css-1ep9fjw {
  color: #ec407a; }

.deepPink .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage img, .deepPink .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage img {
  border: none; }

.deepPink .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage p, .deepPink .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage p {
  color: #ec407a; }

.deepPink .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper img {
  border: none !important; }

.deepPink .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper p {
  color: #ec407a; }

.deepPink .optDeptWrapper .operatorSection .operatorsWrapper .operatorNick p {
  color: #ec407a; }

.deepPink .optDeptWrapper .operatorSection .operatorsWrapper .operatorDetails p.email {
  color: #ec407a; }

.deepPink .optDeptWrapper.section_Dept .panelContent .editWrapper p {
  color: #ec407a; }

.deepPink .archive_pagination .pagination {
  border: 0.5 solid #ec407a;
  color: #ec407a !important; }
  .deepPink .archive_pagination .pagination a {
    color: #ec407a !important; }

.deepPink .archive_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #ec407a solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.deepPink .traffic_pagination .pagination {
  border: 0.5 solid #ec407a;
  color: #ec407a !important; }
  .deepPink .traffic_pagination .pagination a {
    color: #ec407a !important; }

.deepPink .traffic_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #ec407a solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.deepPink .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapse_wrapper .iconshow {
  border: 1px solid #ec407a;
  background-image: url("assets/images/minus_icons/minus_deepPink.png");
  background-repeat: no-repeat;
  background-position: center; }

.deepPink .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .with-image p a {
  color: #ec407a; }

.deepPink .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapsed .collapse_wrapper p:first-child {
  background-image: url("assets/images/plus_icons/plus_pink.png");
  background-size: 8px; }

.deepPink .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapse_wrapper .collapsed {
  background-image: url("assets/images/plus_icons/plus_pink.png") !important;
  background-size: 8px; }

.deepPink .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count {
  background-color: #ec407a; }
  .deepPink .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count:hover {
    border: 1px solid #ec407a;
    color: #ec407a; }

.deepPink .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p.domain span {
  color: #ec407a; }

.deepPink .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .tumbnail_footer {
  background-color: rgba(236, 64, 122, 0.1); }

.deepPink .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper p {
  color: #ec407a; }

.deepPink .chatInfoWrapper .nav.nav-tabs a.nav-item {
  background-color: #ec407a;
  border-right: 1px solid #fff !important; }
  .deepPink .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
    color: #ec407a !important; }

.deepPink .chatInfoWrapper .tab-content p.hide_btn {
  color: #ec407a !important; }
  .deepPink .chatInfoWrapper .tab-content p.hide_btn svg {
    fill: #ec407a !important;
    stroke: #ec407a !important;
    margin-right: 9px;
    margin-top: -3px; }

.deepPink .chatInfoWrapper .tabContent .data .webInfo, .deepPink .chatInfoWrapper .tabContent .data .notes {
  background-color: #FDECF1 !important; }

.deepPink .customerChat .chatText {
  background-color: #ec407a; }
  .deepPink .customerChat .chatText:before {
    content: "";
    position: absolute;
    top: -14px;
    right: 17px;
    z-index: 1;
    border: solid 7px transparent;
    border-bottom-color: #ec407a; }

.deepPink .operatorChat .chatText {
  background-color: rgba(236, 64, 122, 0.1); }
  .deepPink .operatorChat .chatText:before {
    content: "";
    position: absolute;
    top: -14px;
    left: 17px;
    z-index: 1;
    border: solid 7px transparent;
    border-bottom-color: rgba(236, 64, 122, 0.1); }

.deepPink .historyWrapper .transcriptDetails {
  background-color: rgba(236, 64, 122, 0.1); }

.deepPink .websitesWrapper .websitesDetails .box .webLink {
  color: #ec407a; }

.deepPink .websitesWrapper .websitesDetails .box .actionsWrapper {
  background-color: #ec407a; }

.deepPink .websitesWrapper .websitesDetails .box .overview .row p {
  color: #ec407a; }

.deepPink .websitesWrapper .websitesDetails .box .addWrapper img {
  border: 2px solid #ec407a; }

.deepPink .websitesWrapper .websitesDetails .box .addWrapper p {
  color: #ec407a; }

.deepPink .websitesWrapper .websitesDetails .box .addWrapper .websitePayDollar {
  border: 3px solid #ec407a !important; }

.deepPink .textEditorWrapper .sendImgWrappger {
  background-color: #ec407a; }

.deepPink .innercontent_wrapper .directLinkWrapper .uploadBtn button {
  border: 1px solid #ec407a !important; }
  .deepPink .innercontent_wrapper .directLinkWrapper .uploadBtn button p {
    color: #ec407a !important; }

.deepPink .innercontent_wrapper .directLinkWrapper.toolBarWrapper .with_btn .uploadBtn button {
  border: 1px solid #ec407a !important;
  background-color: #fff; }
  .deepPink .innercontent_wrapper .directLinkWrapper.toolBarWrapper .with_btn .uploadBtn button p {
    color: #ec407a !important; }

.brown .existing_opcreate {
  background-color: #6e4c42 !important;
  color: #fff !important; }

.brown .toolBarPreviewWrapper .positionIcons.selected {
  background-color: #6e4c42 !important;
  border: 1px solid #6e4c42 !important; }

.brown .modal-dialog .modal-content .modal-header button.chatInfoBtn {
  border: 1px solid #6e4c42 !important;
  background-color: #ffffff !important;
  color: #6e4c42 !important; }

.brown .widgetPosition p span.selected {
  color: #ffffff;
  background-color: #6e4c42 !important; }

.brown .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span {
  color: #6e4c42 !important; }
  .brown .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span.selected {
    color: #000000 !important; }

.brown .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw {
  box-shadow: #6e4c42 !important; }
  .brown .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:hover, .brown .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:active, .brown .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:focus {
    border-color: #6e4c42 !important; }

.brown .invitationRulesWrapper .editRulesSection .relatedDropDown .css-19bqh2r {
  fill: #6e4c42 !important;
  stroke: #6e4c42 !important; }

.brown .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw, .brown .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw {
  background-color: #6e4c42; }
  .brown .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw:hover, .brown .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw:active, .brown .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw:hover, .brown .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw:active {
    background-color: #6e4c42; }

.brown .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-wqgs6e:hover, .brown .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-wqgs6e:active, .brown .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-wqgs6e:hover, .brown .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-wqgs6e:active {
  background-color: #6e4c42; }

.brown .invitationRulesWrapper .editRulesSection .relatedDropDown .css-1ep9fjw {
  color: #6e4c42; }

.brown .modal-dialog .modal-content .modal-header button.chatInfoBtn:hover {
  color: #ffff !important;
  background-color: #6e4c42 !important; }

.brown .divider {
  color: #6e4c42 !important; }

.brown .expire_account {
  color: #6e4c42 !important; }

.brown .archive_pagination ul li a {
  color: #6e4c42 !important;
  border: none;
  outline: none;
  box-shadow: none; }

.brown .archive_pagination ul li.active {
  border-color: #6e4c42; }

.brown .dropdown-item:hover {
  background-color: #6e4c42 !important;
  color: #f7f5f5 !important;
  font-weight: 700 !important; }

.brown .rcs-custom-scroll .rcs-inner-handle {
  background-color: #6e4c42 !important;
  border-color: #6e4c42 !important; }

.brown .checkout {
  background-color: #6e4c42; }

.brown .profileStatus .status p.dropdown-item {
  color: #000; }

.brown .menuList {
  height: 54px;
  background-color: #fff;
  cursor: pointer; }
  .brown .menuList.selected {
    background-color: #6e4c42 !important; }
    .brown .menuList.selected p {
      color: #fff; }
    .brown .menuList.selected .lineWrapper .line1 {
      background-color: #fff;
      border-color: #fff; }
    .brown .menuList.selected .lineWrapper .line2 {
      border-color: #fff; }
      .brown .menuList.selected .lineWrapper .line2.mask {
        opacity: 1;
        background-color: #fff; }
  .brown .menuList p {
    width: 100%;
    float: left;
    color: #293037;
    font-family: "Segoe UI Semi Bold";
    font-size: 13px;
    font-weight: 700; }
  .brown .menuList .lineWrapper {
    position: absolute;
    right: 21px; }
    .brown .menuList .lineWrapper .line1 {
      width: 35px;
      height: 20px;
      border-bottom: 1px solid #dedede;
      -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
      position: absolute; }
      .brown .menuList .lineWrapper .line1.selectedNext {
        background-color: #6e4c42 !important;
        border-color: #fff; }
    .brown .menuList .lineWrapper .line2 {
      width: 33px;
      height: 20px;
      border-bottom: 1px solid #dedede;
      -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
      position: absolute;
      top: 17px;
      left: -16px; }
      .brown .menuList .lineWrapper .line2.mask {
        opacity: 0;
        left: -1px;
        width: 37px;
        top: 29px;
        background-color: #fff;
        border: 0; }
        .brown .menuList .lineWrapper .line2.mask.selectedNext {
          opacity: 1;
          background-color: #6e4c42 !important; }
      .brown .menuList .lineWrapper .line2.selectedNext {
        border-color: #fff; }

.brown .carousel {
  padding: 12px 0px; }
  .brown .carousel img.carouselBtn {
    position: absolute;
    cursor: pointer;
    top: 90px; }
    .brown .carousel img.carouselBtn.carousel-left {
      left: 30px; }
  .brown .carousel ul {
    margin-left: 0;
    margin-right: 0;
    transition: all 1s; }
    .brown .carousel ul.widgetMode li {
      opacity: 0.5;
      float: left; }
      .brown .carousel ul.widgetMode li.selected, .brown .carousel ul.widgetMode li:hover {
        opacity: 1;
        border: 3px solid #6e4c42 !important; }
        .brown .carousel ul.widgetMode li.selected h4, .brown .carousel ul.widgetMode li:hover h4 {
          background-color: #6e4c42 !important;
          color: #ffffff; }
  .brown .carousel .carousel-cell {
    cursor: pointer;
    width: 210px;
    height: 192px;
    box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
    border-radius: 20px;
    background-color: #ffffff; }
    .brown .carousel .carousel-cell.opacity_0 {
      opacity: 0;
      margin-right: 60px;
      transition: opacity 2s; }
    .brown .carousel .carousel-cell:hover {
      border: 3px solid #6e4c42 !important; }
      .brown .carousel .carousel-cell:hover h4 {
        background-color: #6e4c42 !important;
        color: #ffffff; }
    .brown .carousel .carousel-cell h4 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      padding: 22px 0 18px;
      border-bottom: 4px solid #6e4c42 !important;
      margin: 0;
      border-radius: 15px 15px 0 0; }
    .brown .carousel .carousel-cell .img-wrapper {
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      width: 210px; }

.brown #sidebar > ul.sideMenu {
  background-color: #6e4c42; }
  .brown #sidebar > ul.sideMenu li.active {
    background-color: #573930; }

.brown #sidebar .subMenu li.parent_list > ul li.active {
  background-color: #573930; }

.brown #sidebar.operator_menu .subMenu li.parent_list.active {
  background-color: #573930; }

.brown .image_upload button {
  border: 1px solid #6e4c42; }

.brown .image_upload p {
  color: #6e4c42; }

.brown .profileImg .img_delete p:last-child {
  color: #6e4c42; }

.brown .save_BTN {
  border: 1px solid #6e4c42;
  background-color: #6e4c42 !important; }

.brown .formBtns Button.btn_clr {
  border: 1px solid #6e4c42;
  color: #6e4c42; }

.brown .formBtns Button.btn_save {
  background-color: #6e4c42; }

.brown .overview ol li::before {
  border: 1px solid #6e4c42;
  color: #6e4c42; }

.brown .overview ol li p a:not([href]):not([tabindex]) {
  color: #6e4c42; }

.brown .scriptWrapper.innercontent_wrapper .box.editScriptSection .headerTitle {
  border-bottom: 4px solid #6e4c42; }

.brown .chatWrapper .settingFormWrapper .headerTitle h4 span {
  color: #6e4c42 !important; }

.brown .preview .chatPreview .headerTitle.active {
  background-color: #6e4c42; }

.brown .preview .chatPreview .chatBox .msgArea p.reply, .brown .preview .chatPreview .chatBox .msgArea a.reply {
  color: #6e4c42; }

.brown .preview .chatPreview .btn_send {
  border-color: #6e4c42;
  background-color: #6e4c42; }
  .brown .preview .chatPreview .btn_send:hover {
    color: #6e4c42; }
  .brown .preview .chatPreview .btn_send.preview_btn:hover {
    background-color: #6e4c42;
    color: #fff; }

.brown .optpanelHeader {
  background-color: #6e4c42; }

.brown .panelWrapper.odd {
  background-color: #F0EDEC; }

.brown .panelWrapper .panelHeader {
  background-color: #6e4c42; }

.brown .panelWrapper .scriptPanel > div.panelHeading {
  color: #6e4c42; }
  .brown .panelWrapper .scriptPanel > div.panelHeading p {
    color: #6e4c42; }
    .brown .panelWrapper .scriptPanel > div.panelHeading p:first-child {
      border: 1px solid #6e4c42;
      background-color: #6e4c42; }
  .brown .panelWrapper .scriptPanel > div.panelHeading.collapsed p:first-child {
    background-image: url("assets/images/plus_icons/plus_brown.png") !important;
    background-size: 11px; }

.brown .panelWrapper .scriptPanel .action .data .optionArea p, .brown .panelWrapper .scriptPanel .action.data .optionArea p {
  color: #6e4c42; }

.brown .panelWrapper .scriptPanel .action .data .optionArea .editWrapper > div p, .brown .panelWrapper .scriptPanel .action.data .optionArea .editWrapper > div p {
  color: #6e4c42; }

.brown.ruleComponent.ghostClass {
  cursor: url("https://www.google.com/intl/en_ALL/mapfiles/closedhand.cur"), all-scroll;
  cursor: -webkit-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.brown.ruleComponent.even {
  background-color: #F0EDEC; }

.brown .ruleComponent .optionArea .editWrapper p {
  color: #6e4c42; }

.brown .ruleComponent.even {
  background-color: #F0EDEC; }

.brown .fileTransferWrapper .settingFormWrapper form .customField .progress_wrapper .progress {
  background-color: #6e4c42; }

.brown .fileTransferWrapper .scriptPanel p.action_text {
  color: #6e4c42; }

.brown .custom_btn {
  border: 1px solid #6e4c42;
  color: #6e4c42; }
  .brown .custom_btn.btn-primary:hover {
    background-color: #6e4c42;
    border-color: #6e4c42;
    color: #fff; }

.brown .export_csvbtn {
  color: #6e4c42; }
  .brown .export_csvbtn.btn-primary:hover a {
    color: #fff; }
  .brown .export_csvbtn a:hover {
    color: #fff; }

.brown .export_csvbtn {
  color: #6e4c42; }
  .brown .export_csvbtn a {
    color: #6e4c42; }

.brown .innercontent_wrapper .with-header .box .headerTitle {
  border-bottom: 4px solid #6e4c42; }

.brown .customDrpDwn .css-19bqh2r {
  fill: #6e4c42;
  stroke: #6e4c42; }

.brown .customDrpDwn .css-1ep9fjw {
  color: #6e4c42; }

.brown label.checkbox_custom {
  border: 1px solid #6e4c42; }
  .brown label.checkbox_custom.btn-primary:hover {
    background-color: #6e4c42;
    border-color: #6e4c42; }
  .brown label.checkbox_custom.active {
    background-color: #6e4c42; }

.brown.modal .modal-dialog .modal-header {
  border-bottom: 4px solid #6e4c42; }
  .brown.modal .modal-dialog .modal-header .close {
    color: #6e4c42; }

.brown.modal .modal-dialog .modal-footer button.btn_cancel {
  border: 1px solid #6e4c42;
  color: #6e4c42; }

.brown.modal .modal-dialog .modal-footer button.btn_save {
  background-color: #6e4c42; }

.brown.modal .modal-dialog .modal-footer #goTologin p {
  margin-right: 85px;
  cursor: pointer;
  color: #6e4c42; }

.brown .wrapper .rcs-inner-handle {
  background-color: #6e4c42;
  border: 1px solid #6e4c42; }

.brown .notiInfoScrollWrapper button.read_more {
  width: 119px;
  height: 39px;
  color: #fff;
  background: #6e4c42 !important;
  border: 1px solid #6e4c42 !important; }

.brown .innercontent_wrapper .profile_wrapper .performance-wrapper .performance-content p.count {
  color: #6e4c42; }

.brown label.checkbox_custom {
  border: 1px solid #6e4c42; }
  .brown label.checkbox_custom.btn-primary:not(:disabled):not(.disabled):active, .brown label.checkbox_custom.btn-primary:not(:disabled):not(.disabled).active, .show > .brown label.checkbox_custom.btn-primary.dropdown-toggle, .brown label.checkbox_custom.btn-primary:hover {
    background-color: #6e4c42;
    border-color: #6e4c42; }
  .brown label.checkbox_custom.active {
    background-color: #6e4c42; }

.brown .panelWrapper .scriptPanel .action.traffic_row .active {
  border: 1px solid #6e4c42; }

.brown .panelWrapper .scriptPanel .action.traffic_row.odd {
  background-color: rgba(110, 76, 66, 0.1); }

.brown .panelWrapper .scriptPanel .action.traffic_row.even {
  background-color: #ffffff; }
  .brown .panelWrapper .scriptPanel .action.traffic_row.even .child_wrapper {
    background-color: rgba(110, 76, 66, 0.1); }

.brown .panelWrapper .scriptPanel .action:nth-child(odd) {
  background-color: rgba(110, 76, 66, 0.1); }

.brown .panelWrapper .scriptPanel .action:nth-child(even), .brown .panelWrapper .scriptPanel .action.even {
  background-color: #ffffff; }

.brown .panelWrapper .scriptPanel .action.archiveRow.active {
  border: 1px solid #6e4c42; }

.brown .optscriptPanel .action.traffic_row .active {
  border: 1px solid #6e4c42; }

.brown .optscriptPanel .action.traffic_row.odd {
  background-color: rgba(110, 76, 66, 0.1); }

.brown .optscriptPanel .action.traffic_row.even {
  background-color: #ffffff; }
  .brown .optscriptPanel .action.traffic_row.even .child_wrapper {
    background-color: rgba(110, 76, 66, 0.1); }

.brown .optscriptPanel .action:nth-child(odd) {
  background-color: rgba(110, 76, 66, 0.1); }

.brown .optscriptPanel .action:nth-child(even), .brown .optscriptPanel .action.even {
  background-color: #ffffff; }

.brown .optscriptPanel .action.archiveRow.active {
  border: 1px solid #6e4c42; }

.brown .traffic_wrapper .formBtns {
  background-color: rgba(110, 76, 66, 0.1); }

.brown .customDrpDwn .css-10nd86i:hover, .brown .customDrpDwn .css-10nd86i:active, .brown .customDrpDwn .css-10nd86i:focus {
  border-color: #6e4c42; }

.brown .customDrpDwn .css-19bqh2r {
  fill: #6e4c42;
  stroke: #6e4c42; }

.brown .customDrpDwn .css-15k3avv .css-z5z6cw, .brown .customDrpDwn .css-11unzgr .css-z5z6cw {
  background-color: #6e4c42; }
  .brown .customDrpDwn .css-15k3avv .css-z5z6cw:hover, .brown .customDrpDwn .css-15k3avv .css-z5z6cw:active, .brown .customDrpDwn .css-11unzgr .css-z5z6cw:hover, .brown .customDrpDwn .css-11unzgr .css-z5z6cw:active {
    background-color: #6e4c42; }

.brown .customDrpDwn .css-15k3avv .css-wqgs6e:hover, .brown .customDrpDwn .css-15k3avv .css-wqgs6e:active, .brown .customDrpDwn .css-11unzgr .css-wqgs6e:hover, .brown .customDrpDwn .css-11unzgr .css-wqgs6e:active {
  background-color: #6e4c42; }

.brown .customDrpDwn .css-1ep9fjw {
  color: #6e4c42; }

.brown .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage img, .brown .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage img {
  border: none; }

.brown .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage p, .brown .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage p {
  color: #6e4c42; }

.brown .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper img {
  border: none !important; }

.brown .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper p {
  color: #6e4c42; }

.brown .optDeptWrapper .operatorSection .operatorsWrapper .operatorNick p {
  color: #6e4c42; }

.brown .optDeptWrapper .operatorSection .operatorsWrapper .operatorDetails p.email {
  color: #6e4c42; }

.brown .optDeptWrapper.section_Dept .panelContent .editWrapper p {
  color: #6e4c42; }

.brown .archive_pagination .pagination {
  border: 0.5 solid #6e4c42;
  color: #6e4c42 !important; }
  .brown .archive_pagination .pagination a {
    color: #6e4c42 !important; }

.brown .archive_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #6e4c42 solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.brown .traffic_pagination .pagination {
  border: 0.5 solid #6e4c42;
  color: #6e4c42 !important; }
  .brown .traffic_pagination .pagination a {
    color: #6e4c42 !important; }

.brown .traffic_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #6e4c42 solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.brown .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapse_wrapper .iconshow {
  border: 1px solid #6e4c42;
  background-image: url("assets/images/minus_icons/minus_brown.png");
  background-repeat: no-repeat;
  background-position: center; }

.brown .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .with-image p a {
  color: #6e4c42; }

.brown .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapsed .collapse_wrapper p:first-child {
  background-image: url("assets/images/plus_icons/plus_brown.png");
  background-size: 8px; }

.brown .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapse_wrapper .collapsed {
  background-image: url("assets/images/plus_icons/plus_brown.png") !important;
  background-size: 8px; }

.brown .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count {
  background-color: #6e4c42; }
  .brown .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count:hover {
    border: 1px solid #6e4c42;
    color: #6e4c42; }

.brown .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p.domain span {
  color: #6e4c42; }

.brown .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .tumbnail_footer {
  background-color: rgba(110, 76, 66, 0.1); }

.brown .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper p {
  color: #6e4c42; }

.brown .chatInfoWrapper .nav.nav-tabs a.nav-item {
  background-color: #6e4c42;
  border-right: 1px solid #fff !important; }
  .brown .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
    color: #6e4c42 !important; }

.brown .chatInfoWrapper .tab-content p.hide_btn {
  color: #6e4c42 !important; }
  .brown .chatInfoWrapper .tab-content p.hide_btn svg {
    fill: #6e4c42 !important;
    stroke: #6e4c42 !important;
    margin-right: 9px;
    margin-top: -3px; }

.brown .chatInfoWrapper .tabContent .data .webInfo, .brown .chatInfoWrapper .tabContent .data .notes {
  background-color: #F0EDEC !important; }

.brown .customerChat .chatText {
  background-color: #6e4c42; }
  .brown .customerChat .chatText:before {
    content: "";
    position: absolute;
    top: -14px;
    right: 17px;
    z-index: 1;
    border: solid 7px transparent;
    border-bottom-color: #6e4c42; }

.brown .operatorChat .chatText {
  background-color: rgba(110, 76, 66, 0.1); }
  .brown .operatorChat .chatText:before {
    content: "";
    position: absolute;
    top: -14px;
    left: 17px;
    z-index: 1;
    border: solid 7px transparent;
    border-bottom-color: rgba(110, 76, 66, 0.1); }

.brown .historyWrapper .transcriptDetails {
  background-color: rgba(110, 76, 66, 0.1); }

.brown .websitesWrapper .websitesDetails .box .webLink {
  color: #6e4c42; }

.brown .websitesWrapper .websitesDetails .box .actionsWrapper {
  background-color: #6e4c42; }

.brown .websitesWrapper .websitesDetails .box .overview .row p {
  color: #6e4c42; }

.brown .websitesWrapper .websitesDetails .box .addWrapper img {
  border: 2px solid #6e4c42; }

.brown .websitesWrapper .websitesDetails .box .addWrapper p {
  color: #6e4c42; }

.brown .websitesWrapper .websitesDetails .box .addWrapper .websitePayDollar {
  border: 3px solid #6e4c42 !important; }

.brown .textEditorWrapper .sendImgWrappger {
  background-color: #6e4c42; }

.brown .innercontent_wrapper .directLinkWrapper .uploadBtn button {
  border: 1px solid #6e4c42 !important; }
  .brown .innercontent_wrapper .directLinkWrapper .uploadBtn button p {
    color: #6e4c42 !important; }

.brown .innercontent_wrapper .directLinkWrapper.toolBarWrapper .with_btn .uploadBtn button {
  border: 1px solid #6e4c42 !important;
  background-color: #fff; }
  .brown .innercontent_wrapper .directLinkWrapper.toolBarWrapper .with_btn .uploadBtn button p {
    color: #6e4c42 !important; }

.navyBlue .existing_opcreate {
  background-color: #234e91 !important;
  color: #fff !important; }

.navyBlue .toolBarPreviewWrapper .positionIcons.selected {
  background-color: #234e91 !important;
  border: 1px solid #234e91 !important; }

.navyBlue .modal-dialog .modal-content .modal-header button.chatInfoBtn {
  border: 1px solid #234e91 !important;
  background-color: #ffffff !important;
  color: #234e91 !important; }

.navyBlue .widgetPosition p span.selected {
  color: #ffffff;
  background-color: #234e91 !important; }

.navyBlue .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span {
  color: #234e91 !important; }
  .navyBlue .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span.selected {
    color: #000000 !important; }

.navyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw {
  box-shadow: #234e91 !important; }
  .navyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:hover, .navyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:active, .navyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:focus {
    border-color: #234e91 !important; }

.navyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-19bqh2r {
  fill: #234e91 !important;
  stroke: #234e91 !important; }

.navyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw, .navyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw {
  background-color: #234e91; }
  .navyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw:hover, .navyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw:active, .navyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw:hover, .navyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw:active {
    background-color: #234e91; }

.navyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-wqgs6e:hover, .navyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-wqgs6e:active, .navyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-wqgs6e:hover, .navyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-wqgs6e:active {
  background-color: #234e91; }

.navyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-1ep9fjw {
  color: #234e91; }

.navyBlue .modal-dialog .modal-content .modal-header button.chatInfoBtn:hover {
  color: #ffff !important;
  background-color: #234e91 !important; }

.navyBlue .divider {
  color: #234e91 !important; }

.navyBlue .expire_account {
  color: #234e91 !important; }

.navyBlue .archive_pagination ul li a {
  color: #234e91 !important;
  border: none;
  outline: none;
  box-shadow: none; }

.navyBlue .archive_pagination ul li.active {
  border-color: #234e91; }

.navyBlue .dropdown-item:hover {
  background-color: #234e91 !important;
  color: #f7f5f5 !important;
  font-weight: 700 !important; }

.navyBlue .rcs-custom-scroll .rcs-inner-handle {
  background-color: #234e91 !important;
  border-color: #234e91 !important; }

.navyBlue .checkout {
  background-color: #234e91; }

.navyBlue .profileStatus .status p.dropdown-item {
  color: #000; }

.navyBlue .menuList {
  height: 54px;
  background-color: #fff;
  cursor: pointer; }
  .navyBlue .menuList.selected {
    background-color: #234e91 !important; }
    .navyBlue .menuList.selected p {
      color: #fff; }
    .navyBlue .menuList.selected .lineWrapper .line1 {
      background-color: #fff;
      border-color: #fff; }
    .navyBlue .menuList.selected .lineWrapper .line2 {
      border-color: #fff; }
      .navyBlue .menuList.selected .lineWrapper .line2.mask {
        opacity: 1;
        background-color: #fff; }
  .navyBlue .menuList p {
    width: 100%;
    float: left;
    color: #293037;
    font-family: "Segoe UI Semi Bold";
    font-size: 13px;
    font-weight: 700; }
  .navyBlue .menuList .lineWrapper {
    position: absolute;
    right: 21px; }
    .navyBlue .menuList .lineWrapper .line1 {
      width: 35px;
      height: 20px;
      border-bottom: 1px solid #dedede;
      -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
      position: absolute; }
      .navyBlue .menuList .lineWrapper .line1.selectedNext {
        background-color: #234e91 !important;
        border-color: #fff; }
    .navyBlue .menuList .lineWrapper .line2 {
      width: 33px;
      height: 20px;
      border-bottom: 1px solid #dedede;
      -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
      position: absolute;
      top: 17px;
      left: -16px; }
      .navyBlue .menuList .lineWrapper .line2.mask {
        opacity: 0;
        left: -1px;
        width: 37px;
        top: 29px;
        background-color: #fff;
        border: 0; }
        .navyBlue .menuList .lineWrapper .line2.mask.selectedNext {
          opacity: 1;
          background-color: #234e91 !important; }
      .navyBlue .menuList .lineWrapper .line2.selectedNext {
        border-color: #fff; }

.navyBlue .carousel {
  padding: 12px 0px; }
  .navyBlue .carousel img.carouselBtn {
    position: absolute;
    cursor: pointer;
    top: 90px; }
    .navyBlue .carousel img.carouselBtn.carousel-left {
      left: 30px; }
  .navyBlue .carousel ul {
    margin-left: 0;
    margin-right: 0;
    transition: all 1s; }
    .navyBlue .carousel ul.widgetMode li {
      opacity: 0.5;
      float: left; }
      .navyBlue .carousel ul.widgetMode li.selected, .navyBlue .carousel ul.widgetMode li:hover {
        opacity: 1;
        border: 3px solid #234e91 !important; }
        .navyBlue .carousel ul.widgetMode li.selected h4, .navyBlue .carousel ul.widgetMode li:hover h4 {
          background-color: #234e91 !important;
          color: #ffffff; }
  .navyBlue .carousel .carousel-cell {
    cursor: pointer;
    width: 210px;
    height: 192px;
    box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
    border-radius: 20px;
    background-color: #ffffff; }
    .navyBlue .carousel .carousel-cell.opacity_0 {
      opacity: 0;
      margin-right: 60px;
      transition: opacity 2s; }
    .navyBlue .carousel .carousel-cell:hover {
      border: 3px solid #234e91 !important; }
      .navyBlue .carousel .carousel-cell:hover h4 {
        background-color: #234e91 !important;
        color: #ffffff; }
    .navyBlue .carousel .carousel-cell h4 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      padding: 22px 0 18px;
      border-bottom: 4px solid #234e91 !important;
      margin: 0;
      border-radius: 15px 15px 0 0; }
    .navyBlue .carousel .carousel-cell .img-wrapper {
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      width: 210px; }

.navyBlue #sidebar > ul.sideMenu {
  background-color: #234e91; }
  .navyBlue #sidebar > ul.sideMenu li.active {
    background-color: #0a3a84; }

.navyBlue #sidebar .subMenu li.parent_list > ul li.active {
  background-color: #0a3a84; }

.navyBlue #sidebar.operator_menu .subMenu li.parent_list.active {
  background-color: #0a3a84; }

.navyBlue .image_upload button {
  border: 1px solid #234e91; }

.navyBlue .image_upload p {
  color: #234e91; }

.navyBlue .profileImg .img_delete p:last-child {
  color: #234e91; }

.navyBlue .save_BTN {
  border: 1px solid #234e91;
  background-color: #234e91 !important; }

.navyBlue .formBtns Button.btn_clr {
  border: 1px solid #234e91;
  color: #234e91; }

.navyBlue .formBtns Button.btn_save {
  background-color: #234e91; }

.navyBlue .overview ol li::before {
  border: 1px solid #234e91;
  color: #234e91; }

.navyBlue .overview ol li p a:not([href]):not([tabindex]) {
  color: #234e91; }

.navyBlue .scriptWrapper.innercontent_wrapper .box.editScriptSection .headerTitle {
  border-bottom: 4px solid #234e91; }

.navyBlue .chatWrapper .settingFormWrapper .headerTitle h4 span {
  color: #234e91 !important; }

.navyBlue .preview .chatPreview .headerTitle.active {
  background-color: #234e91; }

.navyBlue .preview .chatPreview .chatBox .msgArea p.reply, .navyBlue .preview .chatPreview .chatBox .msgArea a.reply {
  color: #234e91; }

.navyBlue .preview .chatPreview .btn_send {
  border-color: #234e91;
  background-color: #234e91; }
  .navyBlue .preview .chatPreview .btn_send:hover {
    color: #234e91; }
  .navyBlue .preview .chatPreview .btn_send.preview_btn:hover {
    background-color: #234e91;
    color: #fff; }

.navyBlue .optpanelHeader {
  background-color: #234e91; }

.navyBlue .panelWrapper.odd {
  background-color: #E8EDF4; }

.navyBlue .panelWrapper .panelHeader {
  background-color: #234e91; }

.navyBlue .panelWrapper .scriptPanel > div.panelHeading {
  color: #234e91; }
  .navyBlue .panelWrapper .scriptPanel > div.panelHeading p {
    color: #234e91; }
    .navyBlue .panelWrapper .scriptPanel > div.panelHeading p:first-child {
      border: 1px solid #234e91;
      background-color: #234e91; }
  .navyBlue .panelWrapper .scriptPanel > div.panelHeading.collapsed p:first-child {
    background-image: url("assets/images/plus_icons/plus_navyblue.png") !important;
    background-size: 11px; }

.navyBlue .panelWrapper .scriptPanel .action .data .optionArea p, .navyBlue .panelWrapper .scriptPanel .action.data .optionArea p {
  color: #234e91; }

.navyBlue .panelWrapper .scriptPanel .action .data .optionArea .editWrapper > div p, .navyBlue .panelWrapper .scriptPanel .action.data .optionArea .editWrapper > div p {
  color: #234e91; }

.navyBlue.ruleComponent.ghostClass {
  cursor: url("https://www.google.com/intl/en_ALL/mapfiles/closedhand.cur"), all-scroll;
  cursor: -webkit-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.navyBlue.ruleComponent.even {
  background-color: #E8EDF4; }

.navyBlue .ruleComponent .optionArea .editWrapper p {
  color: #234e91; }

.navyBlue .ruleComponent.even {
  background-color: #E8EDF4; }

.navyBlue .fileTransferWrapper .settingFormWrapper form .customField .progress_wrapper .progress {
  background-color: #234e91; }

.navyBlue .fileTransferWrapper .scriptPanel p.action_text {
  color: #234e91; }

.navyBlue .custom_btn {
  border: 1px solid #234e91;
  color: #234e91; }
  .navyBlue .custom_btn.btn-primary:hover {
    background-color: #234e91;
    border-color: #234e91;
    color: #fff; }

.navyBlue .export_csvbtn {
  color: #234e91; }
  .navyBlue .export_csvbtn.btn-primary:hover a {
    color: #fff; }
  .navyBlue .export_csvbtn a:hover {
    color: #fff; }

.navyBlue .export_csvbtn {
  color: #234e91; }
  .navyBlue .export_csvbtn a {
    color: #234e91; }

.navyBlue .innercontent_wrapper .with-header .box .headerTitle {
  border-bottom: 4px solid #234e91; }

.navyBlue .customDrpDwn .css-19bqh2r {
  fill: #234e91;
  stroke: #234e91; }

.navyBlue .customDrpDwn .css-1ep9fjw {
  color: #234e91; }

.navyBlue label.checkbox_custom {
  border: 1px solid #234e91; }
  .navyBlue label.checkbox_custom.btn-primary:hover {
    background-color: #234e91;
    border-color: #234e91; }
  .navyBlue label.checkbox_custom.active {
    background-color: #234e91; }

.navyBlue.modal .modal-dialog .modal-header {
  border-bottom: 4px solid #234e91; }
  .navyBlue.modal .modal-dialog .modal-header .close {
    color: #234e91; }

.navyBlue.modal .modal-dialog .modal-footer button.btn_cancel {
  border: 1px solid #234e91;
  color: #234e91; }

.navyBlue.modal .modal-dialog .modal-footer button.btn_save {
  background-color: #234e91; }

.navyBlue.modal .modal-dialog .modal-footer #goTologin p {
  margin-right: 85px;
  cursor: pointer;
  color: #234e91; }

.navyBlue .wrapper .rcs-inner-handle {
  background-color: #234e91;
  border: 1px solid #234e91; }

.navyBlue .notiInfoScrollWrapper button.read_more {
  width: 119px;
  height: 39px;
  color: #fff;
  background: #234e91 !important;
  border: 1px solid #234e91 !important; }

.navyBlue .innercontent_wrapper .profile_wrapper .performance-wrapper .performance-content p.count {
  color: #234e91; }

.navyBlue label.checkbox_custom {
  border: 1px solid #234e91; }
  .navyBlue label.checkbox_custom.btn-primary:not(:disabled):not(.disabled):active, .navyBlue label.checkbox_custom.btn-primary:not(:disabled):not(.disabled).active, .show > .navyBlue label.checkbox_custom.btn-primary.dropdown-toggle, .navyBlue label.checkbox_custom.btn-primary:hover {
    background-color: #234e91;
    border-color: #234e91; }
  .navyBlue label.checkbox_custom.active {
    background-color: #234e91; }

.navyBlue .panelWrapper .scriptPanel .action.traffic_row .active {
  border: 1px solid #234e91; }

.navyBlue .panelWrapper .scriptPanel .action.traffic_row.odd {
  background-color: rgba(35, 78, 145, 0.1); }

.navyBlue .panelWrapper .scriptPanel .action.traffic_row.even {
  background-color: #ffffff; }
  .navyBlue .panelWrapper .scriptPanel .action.traffic_row.even .child_wrapper {
    background-color: rgba(35, 78, 145, 0.1); }

.navyBlue .panelWrapper .scriptPanel .action:nth-child(odd) {
  background-color: rgba(35, 78, 145, 0.1); }

.navyBlue .panelWrapper .scriptPanel .action:nth-child(even), .navyBlue .panelWrapper .scriptPanel .action.even {
  background-color: #ffffff; }

.navyBlue .panelWrapper .scriptPanel .action.archiveRow.active {
  border: 1px solid #234e91; }

.navyBlue .optscriptPanel .action.traffic_row .active {
  border: 1px solid #234e91; }

.navyBlue .optscriptPanel .action.traffic_row.odd {
  background-color: rgba(35, 78, 145, 0.1); }

.navyBlue .optscriptPanel .action.traffic_row.even {
  background-color: #ffffff; }
  .navyBlue .optscriptPanel .action.traffic_row.even .child_wrapper {
    background-color: rgba(35, 78, 145, 0.1); }

.navyBlue .optscriptPanel .action:nth-child(odd) {
  background-color: rgba(35, 78, 145, 0.1); }

.navyBlue .optscriptPanel .action:nth-child(even), .navyBlue .optscriptPanel .action.even {
  background-color: #ffffff; }

.navyBlue .optscriptPanel .action.archiveRow.active {
  border: 1px solid #234e91; }

.navyBlue .traffic_wrapper .formBtns {
  background-color: rgba(35, 78, 145, 0.1); }

.navyBlue .customDrpDwn .css-10nd86i:hover, .navyBlue .customDrpDwn .css-10nd86i:active, .navyBlue .customDrpDwn .css-10nd86i:focus {
  border-color: #234e91; }

.navyBlue .customDrpDwn .css-19bqh2r {
  fill: #234e91;
  stroke: #234e91; }

.navyBlue .customDrpDwn .css-15k3avv .css-z5z6cw, .navyBlue .customDrpDwn .css-11unzgr .css-z5z6cw {
  background-color: #234e91; }
  .navyBlue .customDrpDwn .css-15k3avv .css-z5z6cw:hover, .navyBlue .customDrpDwn .css-15k3avv .css-z5z6cw:active, .navyBlue .customDrpDwn .css-11unzgr .css-z5z6cw:hover, .navyBlue .customDrpDwn .css-11unzgr .css-z5z6cw:active {
    background-color: #234e91; }

.navyBlue .customDrpDwn .css-15k3avv .css-wqgs6e:hover, .navyBlue .customDrpDwn .css-15k3avv .css-wqgs6e:active, .navyBlue .customDrpDwn .css-11unzgr .css-wqgs6e:hover, .navyBlue .customDrpDwn .css-11unzgr .css-wqgs6e:active {
  background-color: #234e91; }

.navyBlue .customDrpDwn .css-1ep9fjw {
  color: #234e91; }

.navyBlue .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage img, .navyBlue .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage img {
  border: none; }

.navyBlue .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage p, .navyBlue .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage p {
  color: #234e91; }

.navyBlue .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper img {
  border: none !important; }

.navyBlue .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper p {
  color: #234e91; }

.navyBlue .optDeptWrapper .operatorSection .operatorsWrapper .operatorNick p {
  color: #234e91; }

.navyBlue .optDeptWrapper .operatorSection .operatorsWrapper .operatorDetails p.email {
  color: #234e91; }

.navyBlue .optDeptWrapper.section_Dept .panelContent .editWrapper p {
  color: #234e91; }

.navyBlue .archive_pagination .pagination {
  border: 0.5 solid #234e91;
  color: #234e91 !important; }
  .navyBlue .archive_pagination .pagination a {
    color: #234e91 !important; }

.navyBlue .archive_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #234e91 solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.navyBlue .traffic_pagination .pagination {
  border: 0.5 solid #234e91;
  color: #234e91 !important; }
  .navyBlue .traffic_pagination .pagination a {
    color: #234e91 !important; }

.navyBlue .traffic_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #234e91 solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.navyBlue .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapse_wrapper .iconshow {
  border: 1px solid #234e91;
  background-image: url("assets/images/minus_icons/minus_navyBlue.png");
  background-repeat: no-repeat;
  background-position: center; }

.navyBlue .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .with-image p a {
  color: #234e91; }

.navyBlue .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapsed .collapse_wrapper p:first-child {
  background-image: url("assets/images/plus_icons/plus_navyblue.png");
  background-size: 8px; }

.navyBlue .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapse_wrapper .collapsed {
  background-image: url("assets/images/plus_icons/plus_navyblue.png") !important;
  background-size: 8px; }

.navyBlue .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count {
  background-color: #234e91; }
  .navyBlue .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count:hover {
    border: 1px solid #234e91;
    color: #234e91; }

.navyBlue .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p.domain span {
  color: #234e91; }

.navyBlue .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .tumbnail_footer {
  background-color: rgba(35, 78, 145, 0.1); }

.navyBlue .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper p {
  color: #234e91; }

.navyBlue .chatInfoWrapper .nav.nav-tabs a.nav-item {
  background-color: #234e91;
  border-right: 1px solid #fff !important; }
  .navyBlue .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
    color: #234e91 !important; }

.navyBlue .chatInfoWrapper .tab-content p.hide_btn {
  color: #234e91 !important; }
  .navyBlue .chatInfoWrapper .tab-content p.hide_btn svg {
    fill: #234e91 !important;
    stroke: #234e91 !important;
    margin-right: 9px;
    margin-top: -3px; }

.navyBlue .chatInfoWrapper .tabContent .data .webInfo, .navyBlue .chatInfoWrapper .tabContent .data .notes {
  background-color: #E8EDF4 !important; }

.navyBlue .customerChat .chatText {
  background-color: #234e91; }
  .navyBlue .customerChat .chatText:before {
    content: "";
    position: absolute;
    top: -14px;
    right: 17px;
    z-index: 1;
    border: solid 7px transparent;
    border-bottom-color: #234e91; }

.navyBlue .operatorChat .chatText {
  background-color: rgba(35, 78, 145, 0.1); }
  .navyBlue .operatorChat .chatText:before {
    content: "";
    position: absolute;
    top: -14px;
    left: 17px;
    z-index: 1;
    border: solid 7px transparent;
    border-bottom-color: rgba(35, 78, 145, 0.1); }

.navyBlue .historyWrapper .transcriptDetails {
  background-color: rgba(35, 78, 145, 0.1); }

.navyBlue .websitesWrapper .websitesDetails .box .webLink {
  color: #234e91; }

.navyBlue .websitesWrapper .websitesDetails .box .actionsWrapper {
  background-color: #234e91; }

.navyBlue .websitesWrapper .websitesDetails .box .overview .row p {
  color: #234e91; }

.navyBlue .websitesWrapper .websitesDetails .box .addWrapper img {
  border: 2px solid #234e91; }

.navyBlue .websitesWrapper .websitesDetails .box .addWrapper p {
  color: #234e91; }

.navyBlue .websitesWrapper .websitesDetails .box .addWrapper .websitePayDollar {
  border: 3px solid #234e91 !important; }

.navyBlue .textEditorWrapper .sendImgWrappger {
  background-color: #234e91; }

.navyBlue .innercontent_wrapper .directLinkWrapper .uploadBtn button {
  border: 1px solid #234e91 !important; }
  .navyBlue .innercontent_wrapper .directLinkWrapper .uploadBtn button p {
    color: #234e91 !important; }

.navyBlue .innercontent_wrapper .directLinkWrapper.toolBarWrapper .with_btn .uploadBtn button {
  border: 1px solid #234e91 !important;
  background-color: #fff; }
  .navyBlue .innercontent_wrapper .directLinkWrapper.toolBarWrapper .with_btn .uploadBtn button p {
    color: #234e91 !important; }

.mustardYellow .existing_opcreate {
  background-color: #e89712 !important;
  color: #fff !important; }

.mustardYellow .toolBarPreviewWrapper .positionIcons.selected {
  background-color: #e89712 !important;
  border: 1px solid #e89712 !important; }

.mustardYellow .modal-dialog .modal-content .modal-header button.chatInfoBtn {
  border: 1px solid #e89712 !important;
  background-color: #ffffff !important;
  color: #e89712 !important; }

.mustardYellow .widgetPosition p span.selected {
  color: #ffffff;
  background-color: #e89712 !important; }

.mustardYellow .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span {
  color: #e89712 !important; }
  .mustardYellow .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span.selected {
    color: #000000 !important; }

.mustardYellow .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw {
  box-shadow: #e89712 !important; }
  .mustardYellow .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:hover, .mustardYellow .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:active, .mustardYellow .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:focus {
    border-color: #e89712 !important; }

.mustardYellow .invitationRulesWrapper .editRulesSection .relatedDropDown .css-19bqh2r {
  fill: #e89712 !important;
  stroke: #e89712 !important; }

.mustardYellow .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw, .mustardYellow .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw {
  background-color: #e89712; }
  .mustardYellow .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw:hover, .mustardYellow .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw:active, .mustardYellow .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw:hover, .mustardYellow .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw:active {
    background-color: #e89712; }

.mustardYellow .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-wqgs6e:hover, .mustardYellow .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-wqgs6e:active, .mustardYellow .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-wqgs6e:hover, .mustardYellow .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-wqgs6e:active {
  background-color: #e89712; }

.mustardYellow .invitationRulesWrapper .editRulesSection .relatedDropDown .css-1ep9fjw {
  color: #e89712; }

.mustardYellow .modal-dialog .modal-content .modal-header button.chatInfoBtn:hover {
  color: #ffff !important;
  background-color: #e89712 !important; }

.mustardYellow .divider {
  color: #e89712 !important; }

.mustardYellow .expire_account {
  color: #e89712 !important; }

.mustardYellow .archive_pagination ul li a {
  color: #e89712 !important;
  border: none;
  outline: none;
  box-shadow: none; }

.mustardYellow .archive_pagination ul li.active {
  border-color: #e89712; }

.mustardYellow .dropdown-item:hover {
  background-color: #e89712 !important;
  color: #f7f5f5 !important;
  font-weight: 700 !important; }

.mustardYellow .rcs-custom-scroll .rcs-inner-handle {
  background-color: #e89712 !important;
  border-color: #e89712 !important; }

.mustardYellow .checkout {
  background-color: #e89712; }

.mustardYellow .profileStatus .status p.dropdown-item {
  color: #000; }

.mustardYellow .menuList {
  height: 54px;
  background-color: #fff;
  cursor: pointer; }
  .mustardYellow .menuList.selected {
    background-color: #e89712 !important; }
    .mustardYellow .menuList.selected p {
      color: #fff; }
    .mustardYellow .menuList.selected .lineWrapper .line1 {
      background-color: #fff;
      border-color: #fff; }
    .mustardYellow .menuList.selected .lineWrapper .line2 {
      border-color: #fff; }
      .mustardYellow .menuList.selected .lineWrapper .line2.mask {
        opacity: 1;
        background-color: #fff; }
  .mustardYellow .menuList p {
    width: 100%;
    float: left;
    color: #293037;
    font-family: "Segoe UI Semi Bold";
    font-size: 13px;
    font-weight: 700; }
  .mustardYellow .menuList .lineWrapper {
    position: absolute;
    right: 21px; }
    .mustardYellow .menuList .lineWrapper .line1 {
      width: 35px;
      height: 20px;
      border-bottom: 1px solid #dedede;
      -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
      position: absolute; }
      .mustardYellow .menuList .lineWrapper .line1.selectedNext {
        background-color: #e89712 !important;
        border-color: #fff; }
    .mustardYellow .menuList .lineWrapper .line2 {
      width: 33px;
      height: 20px;
      border-bottom: 1px solid #dedede;
      -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
      position: absolute;
      top: 17px;
      left: -16px; }
      .mustardYellow .menuList .lineWrapper .line2.mask {
        opacity: 0;
        left: -1px;
        width: 37px;
        top: 29px;
        background-color: #fff;
        border: 0; }
        .mustardYellow .menuList .lineWrapper .line2.mask.selectedNext {
          opacity: 1;
          background-color: #e89712 !important; }
      .mustardYellow .menuList .lineWrapper .line2.selectedNext {
        border-color: #fff; }

.mustardYellow .carousel {
  padding: 12px 0px; }
  .mustardYellow .carousel img.carouselBtn {
    position: absolute;
    cursor: pointer;
    top: 90px; }
    .mustardYellow .carousel img.carouselBtn.carousel-left {
      left: 30px; }
  .mustardYellow .carousel ul {
    margin-left: 0;
    margin-right: 0;
    transition: all 1s; }
    .mustardYellow .carousel ul.widgetMode li {
      opacity: 0.5;
      float: left; }
      .mustardYellow .carousel ul.widgetMode li.selected, .mustardYellow .carousel ul.widgetMode li:hover {
        opacity: 1;
        border: 3px solid #e89712 !important; }
        .mustardYellow .carousel ul.widgetMode li.selected h4, .mustardYellow .carousel ul.widgetMode li:hover h4 {
          background-color: #e89712 !important;
          color: #ffffff; }
  .mustardYellow .carousel .carousel-cell {
    cursor: pointer;
    width: 210px;
    height: 192px;
    box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
    border-radius: 20px;
    background-color: #ffffff; }
    .mustardYellow .carousel .carousel-cell.opacity_0 {
      opacity: 0;
      margin-right: 60px;
      transition: opacity 2s; }
    .mustardYellow .carousel .carousel-cell:hover {
      border: 3px solid #e89712 !important; }
      .mustardYellow .carousel .carousel-cell:hover h4 {
        background-color: #e89712 !important;
        color: #ffffff; }
    .mustardYellow .carousel .carousel-cell h4 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      padding: 22px 0 18px;
      border-bottom: 4px solid #e89712 !important;
      margin: 0;
      border-radius: 15px 15px 0 0; }
    .mustardYellow .carousel .carousel-cell .img-wrapper {
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      width: 210px; }

.mustardYellow #sidebar > ul.sideMenu {
  background-color: #e89712; }
  .mustardYellow #sidebar > ul.sideMenu li.active {
    background-color: #d38608; }

.mustardYellow #sidebar .subMenu li.parent_list > ul li.active {
  background-color: #d38608; }

.mustardYellow #sidebar.operator_menu .subMenu li.parent_list.active {
  background-color: #d38608; }

.mustardYellow .image_upload button {
  border: 1px solid #e89712; }

.mustardYellow .image_upload p {
  color: #e89712; }

.mustardYellow .profileImg .img_delete p:last-child {
  color: #e89712; }

.mustardYellow .save_BTN {
  border: 1px solid #e89712;
  background-color: #e89712 !important; }

.mustardYellow .formBtns Button.btn_clr {
  border: 1px solid #e89712;
  color: #e89712; }

.mustardYellow .formBtns Button.btn_save {
  background-color: #e89712; }

.mustardYellow .overview ol li::before {
  border: 1px solid #e89712;
  color: #e89712; }

.mustardYellow .overview ol li p a:not([href]):not([tabindex]) {
  color: #e89712; }

.mustardYellow .scriptWrapper.innercontent_wrapper .box.editScriptSection .headerTitle {
  border-bottom: 4px solid #e89712; }

.mustardYellow .chatWrapper .settingFormWrapper .headerTitle h4 span {
  color: #e89712 !important; }

.mustardYellow .preview .chatPreview .headerTitle.active {
  background-color: #e89712; }

.mustardYellow .preview .chatPreview .chatBox .msgArea p.reply, .mustardYellow .preview .chatPreview .chatBox .msgArea a.reply {
  color: #e89712; }

.mustardYellow .preview .chatPreview .btn_send {
  border-color: #e89712;
  background-color: #e89712; }
  .mustardYellow .preview .chatPreview .btn_send:hover {
    color: #e89712; }
  .mustardYellow .preview .chatPreview .btn_send.preview_btn:hover {
    background-color: #e89712;
    color: #fff; }

.mustardYellow .optpanelHeader {
  background-color: #e89712; }

.mustardYellow .panelWrapper.odd {
  background-color: #FDF4E8; }

.mustardYellow .panelWrapper .panelHeader {
  background-color: #e89712; }

.mustardYellow .panelWrapper .scriptPanel > div.panelHeading {
  color: #e89712; }
  .mustardYellow .panelWrapper .scriptPanel > div.panelHeading p {
    color: #e89712; }
    .mustardYellow .panelWrapper .scriptPanel > div.panelHeading p:first-child {
      border: 1px solid #e89712;
      background-color: #e89712; }
  .mustardYellow .panelWrapper .scriptPanel > div.panelHeading.collapsed p:first-child {
    background-image: url("assets/images/plus_icons/plus_yellow.png") !important;
    background-size: 11px; }

.mustardYellow .panelWrapper .scriptPanel .action .data .optionArea p, .mustardYellow .panelWrapper .scriptPanel .action.data .optionArea p {
  color: #e89712; }

.mustardYellow .panelWrapper .scriptPanel .action .data .optionArea .editWrapper > div p, .mustardYellow .panelWrapper .scriptPanel .action.data .optionArea .editWrapper > div p {
  color: #e89712; }

.mustardYellow.ruleComponent.ghostClass {
  cursor: url("https://www.google.com/intl/en_ALL/mapfiles/closedhand.cur"), all-scroll;
  cursor: -webkit-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.mustardYellow.ruleComponent.even {
  background-color: #FDF4E8; }

.mustardYellow .ruleComponent .optionArea .editWrapper p {
  color: #e89712; }

.mustardYellow .ruleComponent.even {
  background-color: #FDF4E8; }

.mustardYellow .fileTransferWrapper .settingFormWrapper form .customField .progress_wrapper .progress {
  background-color: #e89712; }

.mustardYellow .fileTransferWrapper .scriptPanel p.action_text {
  color: #e89712; }

.mustardYellow .custom_btn {
  border: 1px solid #e89712;
  color: #e89712; }
  .mustardYellow .custom_btn.btn-primary:hover {
    background-color: #e89712;
    border-color: #e89712;
    color: #fff; }

.mustardYellow .export_csvbtn {
  color: #e89712; }
  .mustardYellow .export_csvbtn.btn-primary:hover a {
    color: #fff; }
  .mustardYellow .export_csvbtn a:hover {
    color: #fff; }

.mustardYellow .export_csvbtn {
  color: #e89712; }
  .mustardYellow .export_csvbtn a {
    color: #e89712; }

.mustardYellow .innercontent_wrapper .with-header .box .headerTitle {
  border-bottom: 4px solid #e89712; }

.mustardYellow .customDrpDwn .css-19bqh2r {
  fill: #e89712;
  stroke: #e89712; }

.mustardYellow .customDrpDwn .css-1ep9fjw {
  color: #e89712; }

.mustardYellow label.checkbox_custom {
  border: 1px solid #e89712; }
  .mustardYellow label.checkbox_custom.btn-primary:hover {
    background-color: #e89712;
    border-color: #e89712; }
  .mustardYellow label.checkbox_custom.active {
    background-color: #e89712; }

.mustardYellow.modal .modal-dialog .modal-header {
  border-bottom: 4px solid #e89712; }
  .mustardYellow.modal .modal-dialog .modal-header .close {
    color: #e89712; }

.mustardYellow.modal .modal-dialog .modal-footer button.btn_cancel {
  border: 1px solid #e89712;
  color: #e89712; }

.mustardYellow.modal .modal-dialog .modal-footer button.btn_save {
  background-color: #e89712; }

.mustardYellow.modal .modal-dialog .modal-footer #goTologin p {
  margin-right: 85px;
  cursor: pointer;
  color: #e89712; }

.mustardYellow .wrapper .rcs-inner-handle {
  background-color: #e89712;
  border: 1px solid #e89712; }

.mustardYellow .notiInfoScrollWrapper button.read_more {
  width: 119px;
  height: 39px;
  color: #fff;
  background: #e89712 !important;
  border: 1px solid #e89712 !important; }

.mustardYellow .innercontent_wrapper .profile_wrapper .performance-wrapper .performance-content p.count {
  color: #e89712; }

.mustardYellow label.checkbox_custom {
  border: 1px solid #e89712; }
  .mustardYellow label.checkbox_custom.btn-primary:not(:disabled):not(.disabled):active, .mustardYellow label.checkbox_custom.btn-primary:not(:disabled):not(.disabled).active, .show > .mustardYellow label.checkbox_custom.btn-primary.dropdown-toggle, .mustardYellow label.checkbox_custom.btn-primary:hover {
    background-color: #e89712;
    border-color: #e89712; }
  .mustardYellow label.checkbox_custom.active {
    background-color: #e89712; }

.mustardYellow .panelWrapper .scriptPanel .action.traffic_row .active {
  border: 1px solid #e89712; }

.mustardYellow .panelWrapper .scriptPanel .action.traffic_row.odd {
  background-color: rgba(232, 151, 18, 0.1); }

.mustardYellow .panelWrapper .scriptPanel .action.traffic_row.even {
  background-color: #ffffff; }
  .mustardYellow .panelWrapper .scriptPanel .action.traffic_row.even .child_wrapper {
    background-color: rgba(232, 151, 18, 0.1); }

.mustardYellow .panelWrapper .scriptPanel .action:nth-child(odd) {
  background-color: rgba(232, 151, 18, 0.1); }

.mustardYellow .panelWrapper .scriptPanel .action:nth-child(even), .mustardYellow .panelWrapper .scriptPanel .action.even {
  background-color: #ffffff; }

.mustardYellow .panelWrapper .scriptPanel .action.archiveRow.active {
  border: 1px solid #e89712; }

.mustardYellow .optscriptPanel .action.traffic_row .active {
  border: 1px solid #e89712; }

.mustardYellow .optscriptPanel .action.traffic_row.odd {
  background-color: rgba(232, 151, 18, 0.1); }

.mustardYellow .optscriptPanel .action.traffic_row.even {
  background-color: #ffffff; }
  .mustardYellow .optscriptPanel .action.traffic_row.even .child_wrapper {
    background-color: rgba(232, 151, 18, 0.1); }

.mustardYellow .optscriptPanel .action:nth-child(odd) {
  background-color: rgba(232, 151, 18, 0.1); }

.mustardYellow .optscriptPanel .action:nth-child(even), .mustardYellow .optscriptPanel .action.even {
  background-color: #ffffff; }

.mustardYellow .optscriptPanel .action.archiveRow.active {
  border: 1px solid #e89712; }

.mustardYellow .traffic_wrapper .formBtns {
  background-color: rgba(232, 151, 18, 0.1); }

.mustardYellow .customDrpDwn .css-10nd86i:hover, .mustardYellow .customDrpDwn .css-10nd86i:active, .mustardYellow .customDrpDwn .css-10nd86i:focus {
  border-color: #e89712; }

.mustardYellow .customDrpDwn .css-19bqh2r {
  fill: #e89712;
  stroke: #e89712; }

.mustardYellow .customDrpDwn .css-15k3avv .css-z5z6cw, .mustardYellow .customDrpDwn .css-11unzgr .css-z5z6cw {
  background-color: #e89712; }
  .mustardYellow .customDrpDwn .css-15k3avv .css-z5z6cw:hover, .mustardYellow .customDrpDwn .css-15k3avv .css-z5z6cw:active, .mustardYellow .customDrpDwn .css-11unzgr .css-z5z6cw:hover, .mustardYellow .customDrpDwn .css-11unzgr .css-z5z6cw:active {
    background-color: #e89712; }

.mustardYellow .customDrpDwn .css-15k3avv .css-wqgs6e:hover, .mustardYellow .customDrpDwn .css-15k3avv .css-wqgs6e:active, .mustardYellow .customDrpDwn .css-11unzgr .css-wqgs6e:hover, .mustardYellow .customDrpDwn .css-11unzgr .css-wqgs6e:active {
  background-color: #e89712; }

.mustardYellow .customDrpDwn .css-1ep9fjw {
  color: #e89712; }

.mustardYellow .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage img, .mustardYellow .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage img {
  border: none; }

.mustardYellow .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage p, .mustardYellow .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage p {
  color: #e89712; }

.mustardYellow .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper img {
  border: none !important; }

.mustardYellow .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper p {
  color: #e89712; }

.mustardYellow .optDeptWrapper .operatorSection .operatorsWrapper .operatorNick p {
  color: #e89712; }

.mustardYellow .optDeptWrapper .operatorSection .operatorsWrapper .operatorDetails p.email {
  color: #e89712; }

.mustardYellow .optDeptWrapper.section_Dept .panelContent .editWrapper p {
  color: #e89712; }

.mustardYellow .archive_pagination .pagination {
  border: 0.5 solid #e89712;
  color: #e89712 !important; }
  .mustardYellow .archive_pagination .pagination a {
    color: #e89712 !important; }

.mustardYellow .archive_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #e89712 solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.mustardYellow .traffic_pagination .pagination {
  border: 0.5 solid #e89712;
  color: #e89712 !important; }
  .mustardYellow .traffic_pagination .pagination a {
    color: #e89712 !important; }

.mustardYellow .traffic_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #e89712 solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.mustardYellow .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapse_wrapper .iconshow {
  border: 1px solid #e89712;
  background-image: url("assets/images/minus_icons/minus_mustardYellow.png");
  background-repeat: no-repeat;
  background-position: center; }

.mustardYellow .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .with-image p a {
  color: #e89712; }

.mustardYellow .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapsed .collapse_wrapper p:first-child {
  background-image: url("assets/images/plus_icons/plus_yellow.png");
  background-size: 8px; }

.mustardYellow .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapse_wrapper .collapsed {
  background-image: url("assets/images/plus_icons/plus_yellow.png") !important;
  background-size: 8px; }

.mustardYellow .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count {
  background-color: #e89712; }
  .mustardYellow .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count:hover {
    border: 1px solid #e89712;
    color: #e89712; }

.mustardYellow .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p.domain span {
  color: #e89712; }

.mustardYellow .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .tumbnail_footer {
  background-color: rgba(232, 151, 18, 0.1); }

.mustardYellow .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper p {
  color: #e89712; }

.mustardYellow .chatInfoWrapper .nav.nav-tabs a.nav-item {
  background-color: #e89712;
  border-right: 1px solid #fff !important; }
  .mustardYellow .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
    color: #e89712 !important; }

.mustardYellow .chatInfoWrapper .tab-content p.hide_btn {
  color: #e89712 !important; }
  .mustardYellow .chatInfoWrapper .tab-content p.hide_btn svg {
    fill: #e89712 !important;
    stroke: #e89712 !important;
    margin-right: 9px;
    margin-top: -3px; }

.mustardYellow .chatInfoWrapper .tabContent .data .webInfo, .mustardYellow .chatInfoWrapper .tabContent .data .notes {
  background-color: #FDF4E8 !important; }

.mustardYellow .customerChat .chatText {
  background-color: #e89712; }
  .mustardYellow .customerChat .chatText:before {
    content: "";
    position: absolute;
    top: -14px;
    right: 17px;
    z-index: 1;
    border: solid 7px transparent;
    border-bottom-color: #e89712; }

.mustardYellow .operatorChat .chatText {
  background-color: rgba(232, 151, 18, 0.1); }
  .mustardYellow .operatorChat .chatText:before {
    content: "";
    position: absolute;
    top: -14px;
    left: 17px;
    z-index: 1;
    border: solid 7px transparent;
    border-bottom-color: rgba(232, 151, 18, 0.1); }

.mustardYellow .historyWrapper .transcriptDetails {
  background-color: rgba(232, 151, 18, 0.1); }

.mustardYellow .websitesWrapper .websitesDetails .box .webLink {
  color: #e89712; }

.mustardYellow .websitesWrapper .websitesDetails .box .actionsWrapper {
  background-color: #e89712; }

.mustardYellow .websitesWrapper .websitesDetails .box .overview .row p {
  color: #e89712; }

.mustardYellow .websitesWrapper .websitesDetails .box .addWrapper img {
  border: 2px solid #e89712; }

.mustardYellow .websitesWrapper .websitesDetails .box .addWrapper p {
  color: #e89712; }

.mustardYellow .websitesWrapper .websitesDetails .box .addWrapper .websitePayDollar {
  border: 3px solid #e89712 !important; }

.mustardYellow .textEditorWrapper .sendImgWrappger {
  background-color: #e89712; }

.mustardYellow .innercontent_wrapper .directLinkWrapper .uploadBtn button {
  border: 1px solid #e89712 !important; }
  .mustardYellow .innercontent_wrapper .directLinkWrapper .uploadBtn button p {
    color: #e89712 !important; }

.mustardYellow .innercontent_wrapper .directLinkWrapper.toolBarWrapper .with_btn .uploadBtn button {
  border: 1px solid #e89712 !important;
  background-color: #fff; }
  .mustardYellow .innercontent_wrapper .directLinkWrapper.toolBarWrapper .with_btn .uploadBtn button p {
    color: #e89712 !important; }

.violet .existing_opcreate {
  background-color: #b968c7 !important;
  color: #fff !important; }

.violet .toolBarPreviewWrapper .positionIcons.selected {
  background-color: #b968c7 !important;
  border: 1px solid #b968c7 !important; }

.violet .modal-dialog .modal-content .modal-header button.chatInfoBtn {
  border: 1px solid #b968c7 !important;
  background-color: #ffffff !important;
  color: #b968c7 !important; }

.violet .widgetPosition p span.selected {
  color: #ffffff;
  background-color: #b968c7 !important; }

.violet .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span {
  color: #b968c7 !important; }
  .violet .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span.selected {
    color: #000000 !important; }

.violet .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw {
  box-shadow: #b968c7 !important; }
  .violet .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:hover, .violet .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:active, .violet .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:focus {
    border-color: #b968c7 !important; }

.violet .invitationRulesWrapper .editRulesSection .relatedDropDown .css-19bqh2r {
  fill: #b968c7 !important;
  stroke: #b968c7 !important; }

.violet .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw, .violet .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw {
  background-color: #b968c7; }
  .violet .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw:hover, .violet .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw:active, .violet .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw:hover, .violet .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw:active {
    background-color: #b968c7; }

.violet .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-wqgs6e:hover, .violet .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-wqgs6e:active, .violet .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-wqgs6e:hover, .violet .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-wqgs6e:active {
  background-color: #b968c7; }

.violet .invitationRulesWrapper .editRulesSection .relatedDropDown .css-1ep9fjw {
  color: #b968c7; }

.violet .modal-dialog .modal-content .modal-header button.chatInfoBtn:hover {
  color: #ffff !important;
  background-color: #b968c7 !important; }

.violet .divider {
  color: #b968c7 !important; }

.violet .expire_account {
  color: #b968c7 !important; }

.violet .archive_pagination ul li a {
  color: #b968c7 !important;
  border: none;
  outline: none;
  box-shadow: none; }

.violet .archive_pagination ul li.active {
  border-color: #b968c7; }

.violet .dropdown-item:hover {
  background-color: #b968c7 !important;
  color: #f7f5f5 !important;
  font-weight: 700 !important; }

.violet .rcs-custom-scroll .rcs-inner-handle {
  background-color: #b968c7 !important;
  border-color: #b968c7 !important; }

.violet .checkout {
  background-color: #b968c7; }

.violet .profileStatus .status p.dropdown-item {
  color: #000; }

.violet .menuList {
  height: 54px;
  background-color: #fff;
  cursor: pointer; }
  .violet .menuList.selected {
    background-color: #b968c7 !important; }
    .violet .menuList.selected p {
      color: #fff; }
    .violet .menuList.selected .lineWrapper .line1 {
      background-color: #fff;
      border-color: #fff; }
    .violet .menuList.selected .lineWrapper .line2 {
      border-color: #fff; }
      .violet .menuList.selected .lineWrapper .line2.mask {
        opacity: 1;
        background-color: #fff; }
  .violet .menuList p {
    width: 100%;
    float: left;
    color: #293037;
    font-family: "Segoe UI Semi Bold";
    font-size: 13px;
    font-weight: 700; }
  .violet .menuList .lineWrapper {
    position: absolute;
    right: 21px; }
    .violet .menuList .lineWrapper .line1 {
      width: 35px;
      height: 20px;
      border-bottom: 1px solid #dedede;
      -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
      position: absolute; }
      .violet .menuList .lineWrapper .line1.selectedNext {
        background-color: #b968c7 !important;
        border-color: #fff; }
    .violet .menuList .lineWrapper .line2 {
      width: 33px;
      height: 20px;
      border-bottom: 1px solid #dedede;
      -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
      position: absolute;
      top: 17px;
      left: -16px; }
      .violet .menuList .lineWrapper .line2.mask {
        opacity: 0;
        left: -1px;
        width: 37px;
        top: 29px;
        background-color: #fff;
        border: 0; }
        .violet .menuList .lineWrapper .line2.mask.selectedNext {
          opacity: 1;
          background-color: #b968c7 !important; }
      .violet .menuList .lineWrapper .line2.selectedNext {
        border-color: #fff; }

.violet .carousel {
  padding: 12px 0px; }
  .violet .carousel img.carouselBtn {
    position: absolute;
    cursor: pointer;
    top: 90px; }
    .violet .carousel img.carouselBtn.carousel-left {
      left: 30px; }
  .violet .carousel ul {
    margin-left: 0;
    margin-right: 0;
    transition: all 1s; }
    .violet .carousel ul.widgetMode li {
      opacity: 0.5;
      float: left; }
      .violet .carousel ul.widgetMode li.selected, .violet .carousel ul.widgetMode li:hover {
        opacity: 1;
        border: 3px solid #b968c7 !important; }
        .violet .carousel ul.widgetMode li.selected h4, .violet .carousel ul.widgetMode li:hover h4 {
          background-color: #b968c7 !important;
          color: #ffffff; }
  .violet .carousel .carousel-cell {
    cursor: pointer;
    width: 210px;
    height: 192px;
    box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
    border-radius: 20px;
    background-color: #ffffff; }
    .violet .carousel .carousel-cell.opacity_0 {
      opacity: 0;
      margin-right: 60px;
      transition: opacity 2s; }
    .violet .carousel .carousel-cell:hover {
      border: 3px solid #b968c7 !important; }
      .violet .carousel .carousel-cell:hover h4 {
        background-color: #b968c7 !important;
        color: #ffffff; }
    .violet .carousel .carousel-cell h4 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      padding: 22px 0 18px;
      border-bottom: 4px solid #b968c7 !important;
      margin: 0;
      border-radius: 15px 15px 0 0; }
    .violet .carousel .carousel-cell .img-wrapper {
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      width: 210px; }

.violet #sidebar > ul.sideMenu {
  background-color: #b968c7; }
  .violet #sidebar > ul.sideMenu li.active {
    background-color: #ab48bf; }

.violet #sidebar .subMenu li.parent_list > ul li.active {
  background-color: #ab48bf; }

.violet #sidebar.operator_menu .subMenu li.parent_list.active {
  background-color: #ab48bf; }

.violet .image_upload button {
  border: 1px solid #b968c7; }

.violet .image_upload p {
  color: #b968c7; }

.violet .profileImg .img_delete p:last-child {
  color: #b968c7; }

.violet .save_BTN {
  border: 1px solid #b968c7;
  background-color: #b968c7 !important; }

.violet .formBtns Button.btn_clr {
  border: 1px solid #b968c7;
  color: #b968c7; }

.violet .formBtns Button.btn_save {
  background-color: #b968c7; }

.violet .overview ol li::before {
  border: 1px solid #b968c7;
  color: #b968c7; }

.violet .overview ol li p a:not([href]):not([tabindex]) {
  color: #b968c7; }

.violet .scriptWrapper.innercontent_wrapper .box.editScriptSection .headerTitle {
  border-bottom: 4px solid #b968c7; }

.violet .chatWrapper .settingFormWrapper .headerTitle h4 span {
  color: #b968c7 !important; }

.violet .preview .chatPreview .headerTitle.active {
  background-color: #b968c7; }

.violet .preview .chatPreview .chatBox .msgArea p.reply, .violet .preview .chatPreview .chatBox .msgArea a.reply {
  color: #b968c7; }

.violet .preview .chatPreview .btn_send {
  border-color: #b968c7;
  background-color: #b968c7; }
  .violet .preview .chatPreview .btn_send:hover {
    color: #b968c7; }
  .violet .preview .chatPreview .btn_send.preview_btn:hover {
    background-color: #b968c7;
    color: #fff; }

.violet .optpanelHeader {
  background-color: #b968c7; }

.violet .panelWrapper.odd {
  background-color: #F8F0F9; }

.violet .panelWrapper .panelHeader {
  background-color: #b968c7; }

.violet .panelWrapper .scriptPanel > div.panelHeading {
  color: #b968c7; }
  .violet .panelWrapper .scriptPanel > div.panelHeading p {
    color: #b968c7; }
    .violet .panelWrapper .scriptPanel > div.panelHeading p:first-child {
      border: 1px solid #b968c7;
      background-color: #b968c7; }
  .violet .panelWrapper .scriptPanel > div.panelHeading.collapsed p:first-child {
    background-image: url("assets/images/plus_icons/plus_voilet.png") !important;
    background-size: 11px; }

.violet .panelWrapper .scriptPanel .action .data .optionArea p, .violet .panelWrapper .scriptPanel .action.data .optionArea p {
  color: #b968c7; }

.violet .panelWrapper .scriptPanel .action .data .optionArea .editWrapper > div p, .violet .panelWrapper .scriptPanel .action.data .optionArea .editWrapper > div p {
  color: #b968c7; }

.violet.ruleComponent.ghostClass {
  cursor: url("https://www.google.com/intl/en_ALL/mapfiles/closedhand.cur"), all-scroll;
  cursor: -webkit-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.violet.ruleComponent.even {
  background-color: #F8F0F9; }

.violet .ruleComponent .optionArea .editWrapper p {
  color: #b968c7; }

.violet .ruleComponent.even {
  background-color: #F8F0F9; }

.violet .fileTransferWrapper .settingFormWrapper form .customField .progress_wrapper .progress {
  background-color: #b968c7; }

.violet .fileTransferWrapper .scriptPanel p.action_text {
  color: #b968c7; }

.violet .custom_btn {
  border: 1px solid #b968c7;
  color: #b968c7; }
  .violet .custom_btn.btn-primary:hover {
    background-color: #b968c7;
    border-color: #b968c7;
    color: #fff; }

.violet .export_csvbtn {
  color: #b968c7; }
  .violet .export_csvbtn.btn-primary:hover a {
    color: #fff; }
  .violet .export_csvbtn a:hover {
    color: #fff; }

.violet .export_csvbtn {
  color: #b968c7; }
  .violet .export_csvbtn a {
    color: #b968c7; }

.violet .innercontent_wrapper .with-header .box .headerTitle {
  border-bottom: 4px solid #b968c7; }

.violet .customDrpDwn .css-19bqh2r {
  fill: #b968c7;
  stroke: #b968c7; }

.violet .customDrpDwn .css-1ep9fjw {
  color: #b968c7; }

.violet label.checkbox_custom {
  border: 1px solid #b968c7; }
  .violet label.checkbox_custom.btn-primary:hover {
    background-color: #b968c7;
    border-color: #b968c7; }
  .violet label.checkbox_custom.active {
    background-color: #b968c7; }

.violet.modal .modal-dialog .modal-header {
  border-bottom: 4px solid #b968c7; }
  .violet.modal .modal-dialog .modal-header .close {
    color: #b968c7; }

.violet.modal .modal-dialog .modal-footer button.btn_cancel {
  border: 1px solid #b968c7;
  color: #b968c7; }

.violet.modal .modal-dialog .modal-footer button.btn_save {
  background-color: #b968c7; }

.violet.modal .modal-dialog .modal-footer #goTologin p {
  margin-right: 85px;
  cursor: pointer;
  color: #b968c7; }

.violet .wrapper .rcs-inner-handle {
  background-color: #b968c7;
  border: 1px solid #b968c7; }

.violet .notiInfoScrollWrapper button.read_more {
  width: 119px;
  height: 39px;
  color: #fff;
  background: #b968c7 !important;
  border: 1px solid #b968c7 !important; }

.violet .innercontent_wrapper .profile_wrapper .performance-wrapper .performance-content p.count {
  color: #b968c7; }

.violet label.checkbox_custom {
  border: 1px solid #b968c7; }
  .violet label.checkbox_custom.btn-primary:not(:disabled):not(.disabled):active, .violet label.checkbox_custom.btn-primary:not(:disabled):not(.disabled).active, .show > .violet label.checkbox_custom.btn-primary.dropdown-toggle, .violet label.checkbox_custom.btn-primary:hover {
    background-color: #b968c7;
    border-color: #b968c7; }
  .violet label.checkbox_custom.active {
    background-color: #b968c7; }

.violet .panelWrapper .scriptPanel .action.traffic_row .active {
  border: 1px solid #b968c7; }

.violet .panelWrapper .scriptPanel .action.traffic_row.odd {
  background-color: rgba(185, 104, 199, 0.1); }

.violet .panelWrapper .scriptPanel .action.traffic_row.even {
  background-color: #ffffff; }
  .violet .panelWrapper .scriptPanel .action.traffic_row.even .child_wrapper {
    background-color: rgba(185, 104, 199, 0.1); }

.violet .panelWrapper .scriptPanel .action:nth-child(odd) {
  background-color: rgba(185, 104, 199, 0.1); }

.violet .panelWrapper .scriptPanel .action:nth-child(even), .violet .panelWrapper .scriptPanel .action.even {
  background-color: #ffffff; }

.violet .panelWrapper .scriptPanel .action.archiveRow.active {
  border: 1px solid #b968c7; }

.violet .optscriptPanel .action.traffic_row .active {
  border: 1px solid #b968c7; }

.violet .optscriptPanel .action.traffic_row.odd {
  background-color: rgba(185, 104, 199, 0.1); }

.violet .optscriptPanel .action.traffic_row.even {
  background-color: #ffffff; }
  .violet .optscriptPanel .action.traffic_row.even .child_wrapper {
    background-color: rgba(185, 104, 199, 0.1); }

.violet .optscriptPanel .action:nth-child(odd) {
  background-color: rgba(185, 104, 199, 0.1); }

.violet .optscriptPanel .action:nth-child(even), .violet .optscriptPanel .action.even {
  background-color: #ffffff; }

.violet .optscriptPanel .action.archiveRow.active {
  border: 1px solid #b968c7; }

.violet .traffic_wrapper .formBtns {
  background-color: rgba(185, 104, 199, 0.1); }

.violet .customDrpDwn .css-10nd86i:hover, .violet .customDrpDwn .css-10nd86i:active, .violet .customDrpDwn .css-10nd86i:focus {
  border-color: #b968c7; }

.violet .customDrpDwn .css-19bqh2r {
  fill: #b968c7;
  stroke: #b968c7; }

.violet .customDrpDwn .css-15k3avv .css-z5z6cw, .violet .customDrpDwn .css-11unzgr .css-z5z6cw {
  background-color: #b968c7; }
  .violet .customDrpDwn .css-15k3avv .css-z5z6cw:hover, .violet .customDrpDwn .css-15k3avv .css-z5z6cw:active, .violet .customDrpDwn .css-11unzgr .css-z5z6cw:hover, .violet .customDrpDwn .css-11unzgr .css-z5z6cw:active {
    background-color: #b968c7; }

.violet .customDrpDwn .css-15k3avv .css-wqgs6e:hover, .violet .customDrpDwn .css-15k3avv .css-wqgs6e:active, .violet .customDrpDwn .css-11unzgr .css-wqgs6e:hover, .violet .customDrpDwn .css-11unzgr .css-wqgs6e:active {
  background-color: #b968c7; }

.violet .customDrpDwn .css-1ep9fjw {
  color: #b968c7; }

.violet .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage img, .violet .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage img {
  border: none; }

.violet .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage p, .violet .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage p {
  color: #b968c7; }

.violet .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper img {
  border: none !important; }

.violet .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper p {
  color: #b968c7; }

.violet .optDeptWrapper .operatorSection .operatorsWrapper .operatorNick p {
  color: #b968c7; }

.violet .optDeptWrapper .operatorSection .operatorsWrapper .operatorDetails p.email {
  color: #b968c7; }

.violet .optDeptWrapper.section_Dept .panelContent .editWrapper p {
  color: #b968c7; }

.violet .archive_pagination .pagination {
  border: 0.5 solid #b968c7;
  color: #b968c7 !important; }
  .violet .archive_pagination .pagination a {
    color: #b968c7 !important; }

.violet .archive_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #b968c7 solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.violet .traffic_pagination .pagination {
  border: 0.5 solid #b968c7;
  color: #b968c7 !important; }
  .violet .traffic_pagination .pagination a {
    color: #b968c7 !important; }

.violet .traffic_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #b968c7 solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.violet .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapse_wrapper .iconshow {
  border: 1px solid #b968c7;
  background-image: url("assets/images/minus_icons/minus_violet.png");
  background-repeat: no-repeat;
  background-position: center; }

.violet .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .with-image p a {
  color: #b968c7; }

.violet .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapsed .collapse_wrapper p:first-child {
  background-image: url("assets/images/plus_icons/plus_voilet.png");
  background-size: 8px; }

.violet .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapse_wrapper .collapsed {
  background-image: url("assets/images/plus_icons/plus_voilet.png") !important;
  background-size: 8px; }

.violet .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count {
  background-color: #b968c7; }
  .violet .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count:hover {
    border: 1px solid #b968c7;
    color: #b968c7; }

.violet .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p.domain span {
  color: #b968c7; }

.violet .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .tumbnail_footer {
  background-color: rgba(185, 104, 199, 0.1); }

.violet .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper p {
  color: #b968c7; }

.violet .chatInfoWrapper .nav.nav-tabs a.nav-item {
  background-color: #b968c7;
  border-right: 1px solid #fff !important; }
  .violet .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
    color: #b968c7 !important; }

.violet .chatInfoWrapper .tab-content p.hide_btn {
  color: #b968c7 !important; }
  .violet .chatInfoWrapper .tab-content p.hide_btn svg {
    fill: #b968c7 !important;
    stroke: #b968c7 !important;
    margin-right: 9px;
    margin-top: -3px; }

.violet .chatInfoWrapper .tabContent .data .webInfo, .violet .chatInfoWrapper .tabContent .data .notes {
  background-color: #F8F0F9 !important; }

.violet .customerChat .chatText {
  background-color: #b968c7; }
  .violet .customerChat .chatText:before {
    content: "";
    position: absolute;
    top: -14px;
    right: 17px;
    z-index: 1;
    border: solid 7px transparent;
    border-bottom-color: #b968c7; }

.violet .operatorChat .chatText {
  background-color: rgba(185, 104, 199, 0.1); }
  .violet .operatorChat .chatText:before {
    content: "";
    position: absolute;
    top: -14px;
    left: 17px;
    z-index: 1;
    border: solid 7px transparent;
    border-bottom-color: rgba(185, 104, 199, 0.1); }

.violet .historyWrapper .transcriptDetails {
  background-color: rgba(185, 104, 199, 0.1); }

.violet .websitesWrapper .websitesDetails .box .webLink {
  color: #b968c7; }

.violet .websitesWrapper .websitesDetails .box .actionsWrapper {
  background-color: #b968c7; }

.violet .websitesWrapper .websitesDetails .box .overview .row p {
  color: #b968c7; }

.violet .websitesWrapper .websitesDetails .box .addWrapper img {
  border: 2px solid #b968c7; }

.violet .websitesWrapper .websitesDetails .box .addWrapper p {
  color: #b968c7; }

.violet .websitesWrapper .websitesDetails .box .addWrapper .websitePayDollar {
  border: 3px solid #b968c7 !important; }

.violet .textEditorWrapper .sendImgWrappger {
  background-color: #b968c7; }

.violet .innercontent_wrapper .directLinkWrapper .uploadBtn button {
  border: 1px solid #b968c7 !important; }
  .violet .innercontent_wrapper .directLinkWrapper .uploadBtn button p {
    color: #b968c7 !important; }

.violet .innercontent_wrapper .directLinkWrapper.toolBarWrapper .with_btn .uploadBtn button {
  border: 1px solid #b968c7 !important;
  background-color: #fff; }
  .violet .innercontent_wrapper .directLinkWrapper.toolBarWrapper .with_btn .uploadBtn button p {
    color: #b968c7 !important; }

.blue .existing_opcreate {
  background-color: #039be6 !important;
  color: #fff !important; }

.blue .toolBarPreviewWrapper .positionIcons.selected {
  background-color: #039be6 !important;
  border: 1px solid #039be6 !important; }

.blue .modal-dialog .modal-content .modal-header button.chatInfoBtn {
  border: 1px solid #039be6 !important;
  background-color: #ffffff !important;
  color: #039be6 !important; }

.blue .widgetPosition p span.selected {
  color: #ffffff;
  background-color: #039be6 !important; }

.blue .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span {
  color: #039be6 !important; }
  .blue .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span.selected {
    color: #000000 !important; }

.blue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw {
  box-shadow: #039be6 !important; }
  .blue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:hover, .blue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:active, .blue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:focus {
    border-color: #039be6 !important; }

.blue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-19bqh2r {
  fill: #039be6 !important;
  stroke: #039be6 !important; }

.blue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw, .blue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw {
  background-color: #039be6; }
  .blue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw:hover, .blue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw:active, .blue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw:hover, .blue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw:active {
    background-color: #039be6; }

.blue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-wqgs6e:hover, .blue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-wqgs6e:active, .blue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-wqgs6e:hover, .blue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-wqgs6e:active {
  background-color: #039be6; }

.blue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-1ep9fjw {
  color: #039be6; }

.blue .modal-dialog .modal-content .modal-header button.chatInfoBtn:hover {
  color: #ffff !important;
  background-color: #039be6 !important; }

.blue .divider {
  color: #039be6 !important; }

.blue .expire_account {
  color: #039be6 !important; }

.blue .archive_pagination ul li a {
  color: #039be6 !important;
  border: none;
  outline: none;
  box-shadow: none; }

.blue .archive_pagination ul li.active {
  border-color: #039be6; }

.blue .dropdown-item:hover {
  background-color: #039be6 !important;
  color: #f7f5f5 !important;
  font-weight: 700 !important; }

.blue .rcs-custom-scroll .rcs-inner-handle {
  background-color: #039be6 !important;
  border-color: #039be6 !important; }

.blue .checkout {
  background-color: #039be6; }

.blue .profileStatus .status p.dropdown-item {
  color: #000; }

.blue .menuList {
  height: 54px;
  background-color: #fff;
  cursor: pointer; }
  .blue .menuList.selected {
    background-color: #039be6 !important; }
    .blue .menuList.selected p {
      color: #fff; }
    .blue .menuList.selected .lineWrapper .line1 {
      background-color: #fff;
      border-color: #fff; }
    .blue .menuList.selected .lineWrapper .line2 {
      border-color: #fff; }
      .blue .menuList.selected .lineWrapper .line2.mask {
        opacity: 1;
        background-color: #fff; }
  .blue .menuList p {
    width: 100%;
    float: left;
    color: #293037;
    font-family: "Segoe UI Semi Bold";
    font-size: 13px;
    font-weight: 700; }
  .blue .menuList .lineWrapper {
    position: absolute;
    right: 21px; }
    .blue .menuList .lineWrapper .line1 {
      width: 35px;
      height: 20px;
      border-bottom: 1px solid #dedede;
      -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
      position: absolute; }
      .blue .menuList .lineWrapper .line1.selectedNext {
        background-color: #039be6 !important;
        border-color: #fff; }
    .blue .menuList .lineWrapper .line2 {
      width: 33px;
      height: 20px;
      border-bottom: 1px solid #dedede;
      -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
      position: absolute;
      top: 17px;
      left: -16px; }
      .blue .menuList .lineWrapper .line2.mask {
        opacity: 0;
        left: -1px;
        width: 37px;
        top: 29px;
        background-color: #fff;
        border: 0; }
        .blue .menuList .lineWrapper .line2.mask.selectedNext {
          opacity: 1;
          background-color: #039be6 !important; }
      .blue .menuList .lineWrapper .line2.selectedNext {
        border-color: #fff; }

.blue .carousel {
  padding: 12px 0px; }
  .blue .carousel img.carouselBtn {
    position: absolute;
    cursor: pointer;
    top: 90px; }
    .blue .carousel img.carouselBtn.carousel-left {
      left: 30px; }
  .blue .carousel ul {
    margin-left: 0;
    margin-right: 0;
    transition: all 1s; }
    .blue .carousel ul.widgetMode li {
      opacity: 0.5;
      float: left; }
      .blue .carousel ul.widgetMode li.selected, .blue .carousel ul.widgetMode li:hover {
        opacity: 1;
        border: 3px solid #039be6 !important; }
        .blue .carousel ul.widgetMode li.selected h4, .blue .carousel ul.widgetMode li:hover h4 {
          background-color: #039be6 !important;
          color: #ffffff; }
  .blue .carousel .carousel-cell {
    cursor: pointer;
    width: 210px;
    height: 192px;
    box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
    border-radius: 20px;
    background-color: #ffffff; }
    .blue .carousel .carousel-cell.opacity_0 {
      opacity: 0;
      margin-right: 60px;
      transition: opacity 2s; }
    .blue .carousel .carousel-cell:hover {
      border: 3px solid #039be6 !important; }
      .blue .carousel .carousel-cell:hover h4 {
        background-color: #039be6 !important;
        color: #ffffff; }
    .blue .carousel .carousel-cell h4 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      padding: 22px 0 18px;
      border-bottom: 4px solid #039be6 !important;
      margin: 0;
      border-radius: 15px 15px 0 0; }
    .blue .carousel .carousel-cell .img-wrapper {
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      width: 210px; }

.blue #sidebar > ul.sideMenu {
  background-color: #039be6; }
  .blue #sidebar > ul.sideMenu li.active {
    background-color: #1665c1; }

.blue #sidebar .subMenu li.parent_list > ul li.active {
  background-color: #1665c1; }

.blue #sidebar.operator_menu .subMenu li.parent_list.active {
  background-color: #1665c1; }

.blue .image_upload button {
  border: 1px solid #039be6; }

.blue .image_upload p {
  color: #039be6; }

.blue .profileImg .img_delete p:last-child {
  color: #039be6; }

.blue .save_BTN {
  border: 1px solid #039be6;
  background-color: #039be6 !important; }

.blue .formBtns Button.btn_clr {
  border: 1px solid #039be6;
  color: #039be6; }

.blue .formBtns Button.btn_save {
  background-color: #039be6; }

.blue .overview ol li::before {
  border: 1px solid #039be6;
  color: #039be6; }

.blue .overview ol li p a:not([href]):not([tabindex]) {
  color: #039be6; }

.blue .scriptWrapper.innercontent_wrapper .box.editScriptSection .headerTitle {
  border-bottom: 4px solid #039be6; }

.blue .chatWrapper .settingFormWrapper .headerTitle h4 span {
  color: #039be6 !important; }

.blue .preview .chatPreview .headerTitle.active {
  background-color: #039be6; }

.blue .preview .chatPreview .chatBox .msgArea p.reply, .blue .preview .chatPreview .chatBox .msgArea a.reply {
  color: #039be6; }

.blue .preview .chatPreview .btn_send {
  border-color: #039be6;
  background-color: #039be6; }
  .blue .preview .chatPreview .btn_send:hover {
    color: #039be6; }
  .blue .preview .chatPreview .btn_send.preview_btn:hover {
    background-color: #039be6;
    color: #fff; }

.blue .optpanelHeader {
  background-color: #039be6; }

.blue .panelWrapper.odd {
  background-color: #E6F5FC; }

.blue .panelWrapper .panelHeader {
  background-color: #039be6; }

.blue .panelWrapper .scriptPanel > div.panelHeading {
  color: #039be6; }
  .blue .panelWrapper .scriptPanel > div.panelHeading p {
    color: #039be6; }
    .blue .panelWrapper .scriptPanel > div.panelHeading p:first-child {
      border: 1px solid #039be6;
      background-color: #039be6; }
  .blue .panelWrapper .scriptPanel > div.panelHeading.collapsed p:first-child {
    background-image: url("assets/images/plus_icons/plus_blue.png") !important;
    background-size: 11px; }

.blue .panelWrapper .scriptPanel .action .data .optionArea p, .blue .panelWrapper .scriptPanel .action.data .optionArea p {
  color: #039be6; }

.blue .panelWrapper .scriptPanel .action .data .optionArea .editWrapper > div p, .blue .panelWrapper .scriptPanel .action.data .optionArea .editWrapper > div p {
  color: #039be6; }

.blue.ruleComponent.ghostClass {
  cursor: url("https://www.google.com/intl/en_ALL/mapfiles/closedhand.cur"), all-scroll;
  cursor: -webkit-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.blue.ruleComponent.even {
  background-color: #E6F5FC; }

.blue .ruleComponent .optionArea .editWrapper p {
  color: #039be6; }

.blue .ruleComponent.even {
  background-color: #E6F5FC; }

.blue .fileTransferWrapper .settingFormWrapper form .customField .progress_wrapper .progress {
  background-color: #039be6; }

.blue .fileTransferWrapper .scriptPanel p.action_text {
  color: #039be6; }

.blue .custom_btn {
  border: 1px solid #039be6;
  color: #039be6; }
  .blue .custom_btn.btn-primary:hover {
    background-color: #039be6;
    border-color: #039be6;
    color: #fff; }

.blue .export_csvbtn {
  color: #039be6; }
  .blue .export_csvbtn.btn-primary:hover a {
    color: #fff; }
  .blue .export_csvbtn a:hover {
    color: #fff; }

.blue .export_csvbtn {
  color: #039be6; }
  .blue .export_csvbtn a {
    color: #039be6; }

.blue .innercontent_wrapper .with-header .box .headerTitle {
  border-bottom: 4px solid #039be6; }

.blue .customDrpDwn .css-19bqh2r {
  fill: #039be6;
  stroke: #039be6; }

.blue .customDrpDwn .css-1ep9fjw {
  color: #039be6; }

.blue label.checkbox_custom {
  border: 1px solid #039be6; }
  .blue label.checkbox_custom.btn-primary:hover {
    background-color: #039be6;
    border-color: #039be6; }
  .blue label.checkbox_custom.active {
    background-color: #039be6; }

.blue.modal .modal-dialog .modal-header {
  border-bottom: 4px solid #039be6; }
  .blue.modal .modal-dialog .modal-header .close {
    color: #039be6; }

.blue.modal .modal-dialog .modal-footer button.btn_cancel {
  border: 1px solid #039be6;
  color: #039be6; }

.blue.modal .modal-dialog .modal-footer button.btn_save {
  background-color: #039be6; }

.blue.modal .modal-dialog .modal-footer #goTologin p {
  margin-right: 85px;
  cursor: pointer;
  color: #039be6; }

.blue .wrapper .rcs-inner-handle {
  background-color: #039be6;
  border: 1px solid #039be6; }

.blue .notiInfoScrollWrapper button.read_more {
  width: 119px;
  height: 39px;
  color: #fff;
  background: #039be6 !important;
  border: 1px solid #039be6 !important; }

.blue .innercontent_wrapper .profile_wrapper .performance-wrapper .performance-content p.count {
  color: #039be6; }

.blue label.checkbox_custom {
  border: 1px solid #039be6; }
  .blue label.checkbox_custom.btn-primary:not(:disabled):not(.disabled):active, .blue label.checkbox_custom.btn-primary:not(:disabled):not(.disabled).active, .show > .blue label.checkbox_custom.btn-primary.dropdown-toggle, .blue label.checkbox_custom.btn-primary:hover {
    background-color: #039be6;
    border-color: #039be6; }
  .blue label.checkbox_custom.active {
    background-color: #039be6; }

.blue .panelWrapper .scriptPanel .action.traffic_row .active {
  border: 1px solid #039be6; }

.blue .panelWrapper .scriptPanel .action.traffic_row.odd {
  background-color: rgba(3, 155, 230, 0.1); }

.blue .panelWrapper .scriptPanel .action.traffic_row.even {
  background-color: #ffffff; }
  .blue .panelWrapper .scriptPanel .action.traffic_row.even .child_wrapper {
    background-color: rgba(3, 155, 230, 0.1); }

.blue .panelWrapper .scriptPanel .action:nth-child(odd) {
  background-color: rgba(3, 155, 230, 0.1); }

.blue .panelWrapper .scriptPanel .action:nth-child(even), .blue .panelWrapper .scriptPanel .action.even {
  background-color: #ffffff; }

.blue .panelWrapper .scriptPanel .action.archiveRow.active {
  border: 1px solid #039be6; }

.blue .optscriptPanel .action.traffic_row .active {
  border: 1px solid #039be6; }

.blue .optscriptPanel .action.traffic_row.odd {
  background-color: rgba(3, 155, 230, 0.1); }

.blue .optscriptPanel .action.traffic_row.even {
  background-color: #ffffff; }
  .blue .optscriptPanel .action.traffic_row.even .child_wrapper {
    background-color: rgba(3, 155, 230, 0.1); }

.blue .optscriptPanel .action:nth-child(odd) {
  background-color: rgba(3, 155, 230, 0.1); }

.blue .optscriptPanel .action:nth-child(even), .blue .optscriptPanel .action.even {
  background-color: #ffffff; }

.blue .optscriptPanel .action.archiveRow.active {
  border: 1px solid #039be6; }

.blue .traffic_wrapper .formBtns {
  background-color: rgba(3, 155, 230, 0.1); }

.blue .customDrpDwn .css-10nd86i:hover, .blue .customDrpDwn .css-10nd86i:active, .blue .customDrpDwn .css-10nd86i:focus {
  border-color: #039be6; }

.blue .customDrpDwn .css-19bqh2r {
  fill: #039be6;
  stroke: #039be6; }

.blue .customDrpDwn .css-15k3avv .css-z5z6cw, .blue .customDrpDwn .css-11unzgr .css-z5z6cw {
  background-color: #039be6; }
  .blue .customDrpDwn .css-15k3avv .css-z5z6cw:hover, .blue .customDrpDwn .css-15k3avv .css-z5z6cw:active, .blue .customDrpDwn .css-11unzgr .css-z5z6cw:hover, .blue .customDrpDwn .css-11unzgr .css-z5z6cw:active {
    background-color: #039be6; }

.blue .customDrpDwn .css-15k3avv .css-wqgs6e:hover, .blue .customDrpDwn .css-15k3avv .css-wqgs6e:active, .blue .customDrpDwn .css-11unzgr .css-wqgs6e:hover, .blue .customDrpDwn .css-11unzgr .css-wqgs6e:active {
  background-color: #039be6; }

.blue .customDrpDwn .css-1ep9fjw {
  color: #039be6; }

.blue .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage img, .blue .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage img {
  border: none; }

.blue .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage p, .blue .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage p {
  color: #039be6; }

.blue .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper img {
  border: none !important; }

.blue .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper p {
  color: #039be6; }

.blue .optDeptWrapper .operatorSection .operatorsWrapper .operatorNick p {
  color: #039be6; }

.blue .optDeptWrapper .operatorSection .operatorsWrapper .operatorDetails p.email {
  color: #039be6; }

.blue .optDeptWrapper.section_Dept .panelContent .editWrapper p {
  color: #039be6; }

.blue .archive_pagination .pagination {
  border: 0.5 solid #039be6;
  color: #039be6 !important; }
  .blue .archive_pagination .pagination a {
    color: #039be6 !important; }

.blue .archive_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #039be6 solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.blue .traffic_pagination .pagination {
  border: 0.5 solid #039be6;
  color: #039be6 !important; }
  .blue .traffic_pagination .pagination a {
    color: #039be6 !important; }

.blue .traffic_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #039be6 solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.blue .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapse_wrapper .iconshow {
  border: 1px solid #039be6;
  background-image: url("assets/images/minus_icons/minus_blue.png");
  background-repeat: no-repeat;
  background-position: center; }

.blue .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .with-image p a {
  color: #039be6; }

.blue .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapsed .collapse_wrapper p:first-child {
  background-image: url("assets/images/plus_icons/plus_blue.png");
  background-size: 8px; }

.blue .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapse_wrapper .collapsed {
  background-image: url("assets/images/plus_icons/plus_blue.png") !important;
  background-size: 8px; }

.blue .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count {
  background-color: #039be6; }
  .blue .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count:hover {
    border: 1px solid #039be6;
    color: #039be6; }

.blue .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p.domain span {
  color: #039be6; }

.blue .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .tumbnail_footer {
  background-color: rgba(3, 155, 230, 0.1); }

.blue .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper p {
  color: #039be6; }

.blue .chatInfoWrapper .nav.nav-tabs a.nav-item {
  background-color: #039be6;
  border-right: 1px solid #fff !important; }
  .blue .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
    color: #039be6 !important; }

.blue .chatInfoWrapper .tab-content p.hide_btn {
  color: #039be6 !important; }
  .blue .chatInfoWrapper .tab-content p.hide_btn svg {
    fill: #039be6 !important;
    stroke: #039be6 !important;
    margin-right: 9px;
    margin-top: -3px; }

.blue .chatInfoWrapper .tabContent .data .webInfo, .blue .chatInfoWrapper .tabContent .data .notes {
  background-color: #E6F5FC !important; }

.blue .customerChat .chatText {
  background-color: #039be6; }
  .blue .customerChat .chatText:before {
    content: "";
    position: absolute;
    top: -14px;
    right: 17px;
    z-index: 1;
    border: solid 7px transparent;
    border-bottom-color: #039be6; }

.blue .operatorChat .chatText {
  background-color: rgba(3, 155, 230, 0.1); }
  .blue .operatorChat .chatText:before {
    content: "";
    position: absolute;
    top: -14px;
    left: 17px;
    z-index: 1;
    border: solid 7px transparent;
    border-bottom-color: rgba(3, 155, 230, 0.1); }

.blue .historyWrapper .transcriptDetails {
  background-color: rgba(3, 155, 230, 0.1); }

.blue .websitesWrapper .websitesDetails .box .webLink {
  color: #039be6; }

.blue .websitesWrapper .websitesDetails .box .actionsWrapper {
  background-color: #039be6; }

.blue .websitesWrapper .websitesDetails .box .overview .row p {
  color: #039be6; }

.blue .websitesWrapper .websitesDetails .box .addWrapper img {
  border: 2px solid #039be6; }

.blue .websitesWrapper .websitesDetails .box .addWrapper p {
  color: #039be6; }

.blue .websitesWrapper .websitesDetails .box .addWrapper .websitePayDollar {
  border: 3px solid #039be6 !important; }

.blue .textEditorWrapper .sendImgWrappger {
  background-color: #039be6; }

.blue .innercontent_wrapper .directLinkWrapper .uploadBtn button {
  border: 1px solid #039be6 !important; }
  .blue .innercontent_wrapper .directLinkWrapper .uploadBtn button p {
    color: #039be6 !important; }

.blue .innercontent_wrapper .directLinkWrapper.toolBarWrapper .with_btn .uploadBtn button {
  border: 1px solid #039be6 !important;
  background-color: #fff; }
  .blue .innercontent_wrapper .directLinkWrapper.toolBarWrapper .with_btn .uploadBtn button p {
    color: #039be6 !important; }

.darkGreen .existing_opcreate {
  background-color: #00887a !important;
  color: #fff !important; }

.darkGreen .toolBarPreviewWrapper .positionIcons.selected {
  background-color: #00887a !important;
  border: 1px solid #00887a !important; }

.darkGreen .modal-dialog .modal-content .modal-header button.chatInfoBtn {
  border: 1px solid #00887a !important;
  background-color: #ffffff !important;
  color: #00887a !important; }

.darkGreen .widgetPosition p span.selected {
  color: #ffffff;
  background-color: #00887a !important; }

.darkGreen .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span {
  color: #00887a !important; }
  .darkGreen .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span.selected {
    color: #000000 !important; }

.darkGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw {
  box-shadow: #00887a !important; }
  .darkGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:hover, .darkGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:active, .darkGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:focus {
    border-color: #00887a !important; }

.darkGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-19bqh2r {
  fill: #00887a !important;
  stroke: #00887a !important; }

.darkGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw, .darkGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw {
  background-color: #00887a; }
  .darkGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw:hover, .darkGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw:active, .darkGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw:hover, .darkGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw:active {
    background-color: #00887a; }

.darkGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-wqgs6e:hover, .darkGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-wqgs6e:active, .darkGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-wqgs6e:hover, .darkGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-wqgs6e:active {
  background-color: #00887a; }

.darkGreen .invitationRulesWrapper .editRulesSection .relatedDropDown .css-1ep9fjw {
  color: #00887a; }

.darkGreen .modal-dialog .modal-content .modal-header button.chatInfoBtn:hover {
  color: #ffff !important;
  background-color: #00887a !important; }

.darkGreen .divider {
  color: #00887a !important; }

.darkGreen .expire_account {
  color: #00887a !important; }

.darkGreen .archive_pagination ul li a {
  color: #00887a !important;
  border: none;
  outline: none;
  box-shadow: none; }

.darkGreen .archive_pagination ul li.active {
  border-color: #00887a; }

.darkGreen .dropdown-item:hover {
  background-color: #00887a !important;
  color: #f7f5f5 !important;
  font-weight: 700 !important; }

.darkGreen .rcs-custom-scroll .rcs-inner-handle {
  background-color: #00887a !important;
  border-color: #00887a !important; }

.darkGreen .checkout {
  background-color: #00887a; }

.darkGreen .profileStatus .status p.dropdown-item {
  color: #000; }

.darkGreen .menuList {
  height: 54px;
  background-color: #fff;
  cursor: pointer; }
  .darkGreen .menuList.selected {
    background-color: #00887a !important; }
    .darkGreen .menuList.selected p {
      color: #fff; }
    .darkGreen .menuList.selected .lineWrapper .line1 {
      background-color: #fff;
      border-color: #fff; }
    .darkGreen .menuList.selected .lineWrapper .line2 {
      border-color: #fff; }
      .darkGreen .menuList.selected .lineWrapper .line2.mask {
        opacity: 1;
        background-color: #fff; }
  .darkGreen .menuList p {
    width: 100%;
    float: left;
    color: #293037;
    font-family: "Segoe UI Semi Bold";
    font-size: 13px;
    font-weight: 700; }
  .darkGreen .menuList .lineWrapper {
    position: absolute;
    right: 21px; }
    .darkGreen .menuList .lineWrapper .line1 {
      width: 35px;
      height: 20px;
      border-bottom: 1px solid #dedede;
      -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
      position: absolute; }
      .darkGreen .menuList .lineWrapper .line1.selectedNext {
        background-color: #00887a !important;
        border-color: #fff; }
    .darkGreen .menuList .lineWrapper .line2 {
      width: 33px;
      height: 20px;
      border-bottom: 1px solid #dedede;
      -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
      position: absolute;
      top: 17px;
      left: -16px; }
      .darkGreen .menuList .lineWrapper .line2.mask {
        opacity: 0;
        left: -1px;
        width: 37px;
        top: 29px;
        background-color: #fff;
        border: 0; }
        .darkGreen .menuList .lineWrapper .line2.mask.selectedNext {
          opacity: 1;
          background-color: #00887a !important; }
      .darkGreen .menuList .lineWrapper .line2.selectedNext {
        border-color: #fff; }

.darkGreen .carousel {
  padding: 12px 0px; }
  .darkGreen .carousel img.carouselBtn {
    position: absolute;
    cursor: pointer;
    top: 90px; }
    .darkGreen .carousel img.carouselBtn.carousel-left {
      left: 30px; }
  .darkGreen .carousel ul {
    margin-left: 0;
    margin-right: 0;
    transition: all 1s; }
    .darkGreen .carousel ul.widgetMode li {
      opacity: 0.5;
      float: left; }
      .darkGreen .carousel ul.widgetMode li.selected, .darkGreen .carousel ul.widgetMode li:hover {
        opacity: 1;
        border: 3px solid #00887a !important; }
        .darkGreen .carousel ul.widgetMode li.selected h4, .darkGreen .carousel ul.widgetMode li:hover h4 {
          background-color: #00887a !important;
          color: #ffffff; }
  .darkGreen .carousel .carousel-cell {
    cursor: pointer;
    width: 210px;
    height: 192px;
    box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
    border-radius: 20px;
    background-color: #ffffff; }
    .darkGreen .carousel .carousel-cell.opacity_0 {
      opacity: 0;
      margin-right: 60px;
      transition: opacity 2s; }
    .darkGreen .carousel .carousel-cell:hover {
      border: 3px solid #00887a !important; }
      .darkGreen .carousel .carousel-cell:hover h4 {
        background-color: #00887a !important;
        color: #ffffff; }
    .darkGreen .carousel .carousel-cell h4 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      padding: 22px 0 18px;
      border-bottom: 4px solid #00887a !important;
      margin: 0;
      border-radius: 15px 15px 0 0; }
    .darkGreen .carousel .carousel-cell .img-wrapper {
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      width: 210px; }

.darkGreen #sidebar > ul.sideMenu {
  background-color: #00887a; }
  .darkGreen #sidebar > ul.sideMenu li.active {
    background-color: #00695b; }

.darkGreen #sidebar .subMenu li.parent_list > ul li.active {
  background-color: #00695b; }

.darkGreen #sidebar.operator_menu .subMenu li.parent_list.active {
  background-color: #00695b; }

.darkGreen .image_upload button {
  border: 1px solid #00887a; }

.darkGreen .image_upload p {
  color: #00887a; }

.darkGreen .profileImg .img_delete p:last-child {
  color: #00887a; }

.darkGreen .save_BTN {
  border: 1px solid #00887a;
  background-color: #00887a !important; }

.darkGreen .formBtns Button.btn_clr {
  border: 1px solid #00887a;
  color: #00887a; }

.darkGreen .formBtns Button.btn_save {
  background-color: #00887a; }

.darkGreen .overview ol li::before {
  border: 1px solid #00887a;
  color: #00887a; }

.darkGreen .overview ol li p a:not([href]):not([tabindex]) {
  color: #00887a; }

.darkGreen .scriptWrapper.innercontent_wrapper .box.editScriptSection .headerTitle {
  border-bottom: 4px solid #00887a; }

.darkGreen .chatWrapper .settingFormWrapper .headerTitle h4 span {
  color: #00887a !important; }

.darkGreen .preview .chatPreview .headerTitle.active {
  background-color: #00887a; }

.darkGreen .preview .chatPreview .chatBox .msgArea p.reply, .darkGreen .preview .chatPreview .chatBox .msgArea a.reply {
  color: #00887a; }

.darkGreen .preview .chatPreview .btn_send {
  border-color: #00887a;
  background-color: #00887a; }
  .darkGreen .preview .chatPreview .btn_send:hover {
    color: #00887a; }
  .darkGreen .preview .chatPreview .btn_send.preview_btn:hover {
    background-color: #00887a;
    color: #fff; }

.darkGreen .optpanelHeader {
  background-color: #00887a; }

.darkGreen .panelWrapper.odd {
  background-color: #E6F3F1; }

.darkGreen .panelWrapper .panelHeader {
  background-color: #00887a; }

.darkGreen .panelWrapper .scriptPanel > div.panelHeading {
  color: #00887a; }
  .darkGreen .panelWrapper .scriptPanel > div.panelHeading p {
    color: #00887a; }
    .darkGreen .panelWrapper .scriptPanel > div.panelHeading p:first-child {
      border: 1px solid #00887a;
      background-color: #00887a; }
  .darkGreen .panelWrapper .scriptPanel > div.panelHeading.collapsed p:first-child {
    background-image: url("assets/images/plus_icons/plus_darkgreen.png") !important;
    background-size: 11px; }

.darkGreen .panelWrapper .scriptPanel .action .data .optionArea p, .darkGreen .panelWrapper .scriptPanel .action.data .optionArea p {
  color: #00887a; }

.darkGreen .panelWrapper .scriptPanel .action .data .optionArea .editWrapper > div p, .darkGreen .panelWrapper .scriptPanel .action.data .optionArea .editWrapper > div p {
  color: #00887a; }

.darkGreen.ruleComponent.ghostClass {
  cursor: url("https://www.google.com/intl/en_ALL/mapfiles/closedhand.cur"), all-scroll;
  cursor: -webkit-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.darkGreen.ruleComponent.even {
  background-color: #E6F3F1; }

.darkGreen .ruleComponent .optionArea .editWrapper p {
  color: #00887a; }

.darkGreen .ruleComponent.even {
  background-color: #E6F3F1; }

.darkGreen .fileTransferWrapper .settingFormWrapper form .customField .progress_wrapper .progress {
  background-color: #00887a; }

.darkGreen .fileTransferWrapper .scriptPanel p.action_text {
  color: #00887a; }

.darkGreen .custom_btn {
  border: 1px solid #00887a;
  color: #00887a; }
  .darkGreen .custom_btn.btn-primary:hover {
    background-color: #00887a;
    border-color: #00887a;
    color: #fff; }

.darkGreen .export_csvbtn {
  color: #00887a; }
  .darkGreen .export_csvbtn.btn-primary:hover a {
    color: #fff; }
  .darkGreen .export_csvbtn a:hover {
    color: #fff; }

.darkGreen .export_csvbtn {
  color: #00887a; }
  .darkGreen .export_csvbtn a {
    color: #00887a; }

.darkGreen .innercontent_wrapper .with-header .box .headerTitle {
  border-bottom: 4px solid #00887a; }

.darkGreen .customDrpDwn .css-19bqh2r {
  fill: #00887a;
  stroke: #00887a; }

.darkGreen .customDrpDwn .css-1ep9fjw {
  color: #00887a; }

.darkGreen label.checkbox_custom {
  border: 1px solid #00887a; }
  .darkGreen label.checkbox_custom.btn-primary:hover {
    background-color: #00887a;
    border-color: #00887a; }
  .darkGreen label.checkbox_custom.active {
    background-color: #00887a; }

.darkGreen.modal .modal-dialog .modal-header {
  border-bottom: 4px solid #00887a; }
  .darkGreen.modal .modal-dialog .modal-header .close {
    color: #00887a; }

.darkGreen.modal .modal-dialog .modal-footer button.btn_cancel {
  border: 1px solid #00887a;
  color: #00887a; }

.darkGreen.modal .modal-dialog .modal-footer button.btn_save {
  background-color: #00887a; }

.darkGreen.modal .modal-dialog .modal-footer #goTologin p {
  margin-right: 85px;
  cursor: pointer;
  color: #00887a; }

.darkGreen .wrapper .rcs-inner-handle {
  background-color: #00887a;
  border: 1px solid #00887a; }

.darkGreen .notiInfoScrollWrapper button.read_more {
  width: 119px;
  height: 39px;
  color: #fff;
  background: #00887a !important;
  border: 1px solid #00887a !important; }

.darkGreen .innercontent_wrapper .profile_wrapper .performance-wrapper .performance-content p.count {
  color: #00887a; }

.darkGreen label.checkbox_custom {
  border: 1px solid #00887a; }
  .darkGreen label.checkbox_custom.btn-primary:not(:disabled):not(.disabled):active, .darkGreen label.checkbox_custom.btn-primary:not(:disabled):not(.disabled).active, .show > .darkGreen label.checkbox_custom.btn-primary.dropdown-toggle, .darkGreen label.checkbox_custom.btn-primary:hover {
    background-color: #00887a;
    border-color: #00887a; }
  .darkGreen label.checkbox_custom.active {
    background-color: #00887a; }

.darkGreen .panelWrapper .scriptPanel .action.traffic_row .active {
  border: 1px solid #00887a; }

.darkGreen .panelWrapper .scriptPanel .action.traffic_row.odd {
  background-color: rgba(0, 136, 122, 0.1); }

.darkGreen .panelWrapper .scriptPanel .action.traffic_row.even {
  background-color: #ffffff; }
  .darkGreen .panelWrapper .scriptPanel .action.traffic_row.even .child_wrapper {
    background-color: rgba(0, 136, 122, 0.1); }

.darkGreen .panelWrapper .scriptPanel .action:nth-child(odd) {
  background-color: rgba(0, 136, 122, 0.1); }

.darkGreen .panelWrapper .scriptPanel .action:nth-child(even), .darkGreen .panelWrapper .scriptPanel .action.even {
  background-color: #ffffff; }

.darkGreen .panelWrapper .scriptPanel .action.archiveRow.active {
  border: 1px solid #00887a; }

.darkGreen .optscriptPanel .action.traffic_row .active {
  border: 1px solid #00887a; }

.darkGreen .optscriptPanel .action.traffic_row.odd {
  background-color: rgba(0, 136, 122, 0.1); }

.darkGreen .optscriptPanel .action.traffic_row.even {
  background-color: #ffffff; }
  .darkGreen .optscriptPanel .action.traffic_row.even .child_wrapper {
    background-color: rgba(0, 136, 122, 0.1); }

.darkGreen .optscriptPanel .action:nth-child(odd) {
  background-color: rgba(0, 136, 122, 0.1); }

.darkGreen .optscriptPanel .action:nth-child(even), .darkGreen .optscriptPanel .action.even {
  background-color: #ffffff; }

.darkGreen .optscriptPanel .action.archiveRow.active {
  border: 1px solid #00887a; }

.darkGreen .traffic_wrapper .formBtns {
  background-color: rgba(0, 136, 122, 0.1); }

.darkGreen .customDrpDwn .css-10nd86i:hover, .darkGreen .customDrpDwn .css-10nd86i:active, .darkGreen .customDrpDwn .css-10nd86i:focus {
  border-color: #00887a; }

.darkGreen .customDrpDwn .css-19bqh2r {
  fill: #00887a;
  stroke: #00887a; }

.darkGreen .customDrpDwn .css-15k3avv .css-z5z6cw, .darkGreen .customDrpDwn .css-11unzgr .css-z5z6cw {
  background-color: #00887a; }
  .darkGreen .customDrpDwn .css-15k3avv .css-z5z6cw:hover, .darkGreen .customDrpDwn .css-15k3avv .css-z5z6cw:active, .darkGreen .customDrpDwn .css-11unzgr .css-z5z6cw:hover, .darkGreen .customDrpDwn .css-11unzgr .css-z5z6cw:active {
    background-color: #00887a; }

.darkGreen .customDrpDwn .css-15k3avv .css-wqgs6e:hover, .darkGreen .customDrpDwn .css-15k3avv .css-wqgs6e:active, .darkGreen .customDrpDwn .css-11unzgr .css-wqgs6e:hover, .darkGreen .customDrpDwn .css-11unzgr .css-wqgs6e:active {
  background-color: #00887a; }

.darkGreen .customDrpDwn .css-1ep9fjw {
  color: #00887a; }

.darkGreen .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage img, .darkGreen .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage img {
  border: none; }

.darkGreen .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage p, .darkGreen .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage p {
  color: #00887a; }

.darkGreen .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper img {
  border: none !important; }

.darkGreen .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper p {
  color: #00887a; }

.darkGreen .optDeptWrapper .operatorSection .operatorsWrapper .operatorNick p {
  color: #00887a; }

.darkGreen .optDeptWrapper .operatorSection .operatorsWrapper .operatorDetails p.email {
  color: #00887a; }

.darkGreen .optDeptWrapper.section_Dept .panelContent .editWrapper p {
  color: #00887a; }

.darkGreen .archive_pagination .pagination {
  border: 0.5 solid #00887a;
  color: #00887a !important; }
  .darkGreen .archive_pagination .pagination a {
    color: #00887a !important; }

.darkGreen .archive_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #00887a solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.darkGreen .traffic_pagination .pagination {
  border: 0.5 solid #00887a;
  color: #00887a !important; }
  .darkGreen .traffic_pagination .pagination a {
    color: #00887a !important; }

.darkGreen .traffic_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #00887a solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.darkGreen .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapse_wrapper .iconshow {
  border: 1px solid #00887a;
  background-image: url("assets/images/minus_icons/minus_darkGreen.png");
  background-repeat: no-repeat;
  background-position: center; }

.darkGreen .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .with-image p a {
  color: #00887a; }

.darkGreen .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapsed .collapse_wrapper p:first-child {
  background-image: url("assets/images/plus_icons/plus_darkgreen.png");
  background-size: 8px; }

.darkGreen .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapse_wrapper .collapsed {
  background-image: url("assets/images/plus_icons/plus_darkgreen.png") !important;
  background-size: 8px; }

.darkGreen .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count {
  background-color: #00887a; }
  .darkGreen .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count:hover {
    border: 1px solid #00887a;
    color: #00887a; }

.darkGreen .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p.domain span {
  color: #00887a; }

.darkGreen .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .tumbnail_footer {
  background-color: rgba(0, 136, 122, 0.1); }

.darkGreen .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper p {
  color: #00887a; }

.darkGreen .chatInfoWrapper .nav.nav-tabs a.nav-item {
  background-color: #00887a;
  border-right: 1px solid #fff !important; }
  .darkGreen .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
    color: #00887a !important; }

.darkGreen .chatInfoWrapper .tab-content p.hide_btn {
  color: #00887a !important; }
  .darkGreen .chatInfoWrapper .tab-content p.hide_btn svg {
    fill: #00887a !important;
    stroke: #00887a !important;
    margin-right: 9px;
    margin-top: -3px; }

.darkGreen .chatInfoWrapper .tabContent .data .webInfo, .darkGreen .chatInfoWrapper .tabContent .data .notes {
  background-color: #E6F3F1 !important; }

.darkGreen .customerChat .chatText {
  background-color: #00887a; }
  .darkGreen .customerChat .chatText:before {
    content: "";
    position: absolute;
    top: -14px;
    right: 17px;
    z-index: 1;
    border: solid 7px transparent;
    border-bottom-color: #00887a; }

.darkGreen .operatorChat .chatText {
  background-color: rgba(0, 136, 122, 0.1); }
  .darkGreen .operatorChat .chatText:before {
    content: "";
    position: absolute;
    top: -14px;
    left: 17px;
    z-index: 1;
    border: solid 7px transparent;
    border-bottom-color: rgba(0, 136, 122, 0.1); }

.darkGreen .historyWrapper .transcriptDetails {
  background-color: rgba(0, 136, 122, 0.1); }

.darkGreen .websitesWrapper .websitesDetails .box .webLink {
  color: #00887a; }

.darkGreen .websitesWrapper .websitesDetails .box .actionsWrapper {
  background-color: #00887a; }

.darkGreen .websitesWrapper .websitesDetails .box .overview .row p {
  color: #00887a; }

.darkGreen .websitesWrapper .websitesDetails .box .addWrapper img {
  border: 2px solid #00887a; }

.darkGreen .websitesWrapper .websitesDetails .box .addWrapper p {
  color: #00887a; }

.darkGreen .websitesWrapper .websitesDetails .box .addWrapper .websitePayDollar {
  border: 3px solid #00887a !important; }

.darkGreen .textEditorWrapper .sendImgWrappger {
  background-color: #00887a; }

.darkGreen .innercontent_wrapper .directLinkWrapper .uploadBtn button {
  border: 1px solid #00887a !important; }
  .darkGreen .innercontent_wrapper .directLinkWrapper .uploadBtn button p {
    color: #00887a !important; }

.darkGreen .innercontent_wrapper .directLinkWrapper.toolBarWrapper .with_btn .uploadBtn button {
  border: 1px solid #00887a !important;
  background-color: #fff; }
  .darkGreen .innercontent_wrapper .directLinkWrapper.toolBarWrapper .with_btn .uploadBtn button p {
    color: #00887a !important; }

.green .existing_opcreate {
  background-color: #4b8f4e !important;
  color: #fff !important; }

.green .toolBarPreviewWrapper .positionIcons.selected {
  background-color: #4b8f4e !important;
  border: 1px solid #4b8f4e !important; }

.green .modal-dialog .modal-content .modal-header button.chatInfoBtn {
  border: 1px solid #4b8f4e !important;
  background-color: #ffffff !important;
  color: #4b8f4e !important; }

.green .widgetPosition p span.selected {
  color: #ffffff;
  background-color: #4b8f4e !important; }

.green .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span {
  color: #4b8f4e !important; }
  .green .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span.selected {
    color: #000000 !important; }

.green .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw {
  box-shadow: #4b8f4e !important; }
  .green .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:hover, .green .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:active, .green .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:focus {
    border-color: #4b8f4e !important; }

.green .invitationRulesWrapper .editRulesSection .relatedDropDown .css-19bqh2r {
  fill: #4b8f4e !important;
  stroke: #4b8f4e !important; }

.green .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw, .green .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw {
  background-color: #4b8f4e; }
  .green .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw:hover, .green .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw:active, .green .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw:hover, .green .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw:active {
    background-color: #4b8f4e; }

.green .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-wqgs6e:hover, .green .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-wqgs6e:active, .green .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-wqgs6e:hover, .green .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-wqgs6e:active {
  background-color: #4b8f4e; }

.green .invitationRulesWrapper .editRulesSection .relatedDropDown .css-1ep9fjw {
  color: #4b8f4e; }

.green .modal-dialog .modal-content .modal-header button.chatInfoBtn:hover {
  color: #ffff !important;
  background-color: #4b8f4e !important; }

.green .divider {
  color: #4b8f4e !important; }

.green .expire_account {
  color: #4b8f4e !important; }

.green .archive_pagination ul li a {
  color: #4b8f4e !important;
  border: none;
  outline: none;
  box-shadow: none; }

.green .archive_pagination ul li.active {
  border-color: #4b8f4e; }

.green .dropdown-item:hover {
  background-color: #4b8f4e !important;
  color: #f7f5f5 !important;
  font-weight: 700 !important; }

.green .rcs-custom-scroll .rcs-inner-handle {
  background-color: #4b8f4e !important;
  border-color: #4b8f4e !important; }

.green .checkout {
  background-color: #4b8f4e; }

.green .profileStatus .status p.dropdown-item {
  color: #000; }

.green .menuList {
  height: 54px;
  background-color: #fff;
  cursor: pointer; }
  .green .menuList.selected {
    background-color: #4b8f4e !important; }
    .green .menuList.selected p {
      color: #fff; }
    .green .menuList.selected .lineWrapper .line1 {
      background-color: #fff;
      border-color: #fff; }
    .green .menuList.selected .lineWrapper .line2 {
      border-color: #fff; }
      .green .menuList.selected .lineWrapper .line2.mask {
        opacity: 1;
        background-color: #fff; }
  .green .menuList p {
    width: 100%;
    float: left;
    color: #293037;
    font-family: "Segoe UI Semi Bold";
    font-size: 13px;
    font-weight: 700; }
  .green .menuList .lineWrapper {
    position: absolute;
    right: 21px; }
    .green .menuList .lineWrapper .line1 {
      width: 35px;
      height: 20px;
      border-bottom: 1px solid #dedede;
      -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
      position: absolute; }
      .green .menuList .lineWrapper .line1.selectedNext {
        background-color: #4b8f4e !important;
        border-color: #fff; }
    .green .menuList .lineWrapper .line2 {
      width: 33px;
      height: 20px;
      border-bottom: 1px solid #dedede;
      -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
      position: absolute;
      top: 17px;
      left: -16px; }
      .green .menuList .lineWrapper .line2.mask {
        opacity: 0;
        left: -1px;
        width: 37px;
        top: 29px;
        background-color: #fff;
        border: 0; }
        .green .menuList .lineWrapper .line2.mask.selectedNext {
          opacity: 1;
          background-color: #4b8f4e !important; }
      .green .menuList .lineWrapper .line2.selectedNext {
        border-color: #fff; }

.green .carousel {
  padding: 12px 0px; }
  .green .carousel img.carouselBtn {
    position: absolute;
    cursor: pointer;
    top: 90px; }
    .green .carousel img.carouselBtn.carousel-left {
      left: 30px; }
  .green .carousel ul {
    margin-left: 0;
    margin-right: 0;
    transition: all 1s; }
    .green .carousel ul.widgetMode li {
      opacity: 0.5;
      float: left; }
      .green .carousel ul.widgetMode li.selected, .green .carousel ul.widgetMode li:hover {
        opacity: 1;
        border: 3px solid #4b8f4e !important; }
        .green .carousel ul.widgetMode li.selected h4, .green .carousel ul.widgetMode li:hover h4 {
          background-color: #4b8f4e !important;
          color: #ffffff; }
  .green .carousel .carousel-cell {
    cursor: pointer;
    width: 210px;
    height: 192px;
    box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
    border-radius: 20px;
    background-color: #ffffff; }
    .green .carousel .carousel-cell.opacity_0 {
      opacity: 0;
      margin-right: 60px;
      transition: opacity 2s; }
    .green .carousel .carousel-cell:hover {
      border: 3px solid #4b8f4e !important; }
      .green .carousel .carousel-cell:hover h4 {
        background-color: #4b8f4e !important;
        color: #ffffff; }
    .green .carousel .carousel-cell h4 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      padding: 22px 0 18px;
      border-bottom: 4px solid #4b8f4e !important;
      margin: 0;
      border-radius: 15px 15px 0 0; }
    .green .carousel .carousel-cell .img-wrapper {
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      width: 210px; }

.green #sidebar > ul.sideMenu {
  background-color: #4b8f4e; }
  .green #sidebar > ul.sideMenu li.active {
    background-color: #2d7d30; }

.green #sidebar .subMenu li.parent_list > ul li.active {
  background-color: #2d7d30; }

.green #sidebar.operator_menu .subMenu li.parent_list.active {
  background-color: #2d7d30; }

.green .image_upload button {
  border: 1px solid #4b8f4e; }

.green .image_upload p {
  color: #4b8f4e; }

.green .profileImg .img_delete p:last-child {
  color: #4b8f4e; }

.green .save_BTN {
  border: 1px solid #4b8f4e;
  background-color: #4b8f4e !important; }

.green .formBtns Button.btn_clr {
  border: 1px solid #4b8f4e;
  color: #4b8f4e; }

.green .formBtns Button.btn_save {
  background-color: #4b8f4e; }

.green .overview ol li::before {
  border: 1px solid #4b8f4e;
  color: #4b8f4e; }

.green .overview ol li p a:not([href]):not([tabindex]) {
  color: #4b8f4e; }

.green .scriptWrapper.innercontent_wrapper .box.editScriptSection .headerTitle {
  border-bottom: 4px solid #4b8f4e; }

.green .chatWrapper .settingFormWrapper .headerTitle h4 span {
  color: #4b8f4e !important; }

.green .preview .chatPreview .headerTitle.active {
  background-color: #4b8f4e; }

.green .preview .chatPreview .chatBox .msgArea p.reply, .green .preview .chatPreview .chatBox .msgArea a.reply {
  color: #4b8f4e; }

.green .preview .chatPreview .btn_send {
  border-color: #4b8f4e;
  background-color: #4b8f4e; }
  .green .preview .chatPreview .btn_send:hover {
    color: #4b8f4e; }
  .green .preview .chatPreview .btn_send.preview_btn:hover {
    background-color: #4b8f4e;
    color: #fff; }

.green .optpanelHeader {
  background-color: #4b8f4e; }

.green .panelWrapper.odd {
  background-color: #EDF3ED; }

.green .panelWrapper .panelHeader {
  background-color: #4b8f4e; }

.green .panelWrapper .scriptPanel > div.panelHeading {
  color: #4b8f4e; }
  .green .panelWrapper .scriptPanel > div.panelHeading p {
    color: #4b8f4e; }
    .green .panelWrapper .scriptPanel > div.panelHeading p:first-child {
      border: 1px solid #4b8f4e;
      background-color: #4b8f4e; }
  .green .panelWrapper .scriptPanel > div.panelHeading.collapsed p:first-child {
    background-image: url("assets/images/plus_icons/plus_green.png") !important;
    background-size: 11px; }

.green .panelWrapper .scriptPanel .action .data .optionArea p, .green .panelWrapper .scriptPanel .action.data .optionArea p {
  color: #4b8f4e; }

.green .panelWrapper .scriptPanel .action .data .optionArea .editWrapper > div p, .green .panelWrapper .scriptPanel .action.data .optionArea .editWrapper > div p {
  color: #4b8f4e; }

.green.ruleComponent.ghostClass {
  cursor: url("https://www.google.com/intl/en_ALL/mapfiles/closedhand.cur"), all-scroll;
  cursor: -webkit-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.green.ruleComponent.even {
  background-color: #EDF3ED; }

.green .ruleComponent .optionArea .editWrapper p {
  color: #4b8f4e; }

.green .ruleComponent.even {
  background-color: #EDF3ED; }

.green .fileTransferWrapper .settingFormWrapper form .customField .progress_wrapper .progress {
  background-color: #4b8f4e; }

.green .fileTransferWrapper .scriptPanel p.action_text {
  color: #4b8f4e; }

.green .custom_btn {
  border: 1px solid #4b8f4e;
  color: #4b8f4e; }
  .green .custom_btn.btn-primary:hover {
    background-color: #4b8f4e;
    border-color: #4b8f4e;
    color: #fff; }

.green .export_csvbtn {
  color: #4b8f4e; }
  .green .export_csvbtn.btn-primary:hover a {
    color: #fff; }
  .green .export_csvbtn a:hover {
    color: #fff; }

.green .export_csvbtn {
  color: #4b8f4e; }
  .green .export_csvbtn a {
    color: #4b8f4e; }

.green .innercontent_wrapper .with-header .box .headerTitle {
  border-bottom: 4px solid #4b8f4e; }

.green .customDrpDwn .css-19bqh2r {
  fill: #4b8f4e;
  stroke: #4b8f4e; }

.green .customDrpDwn .css-1ep9fjw {
  color: #4b8f4e; }

.green label.checkbox_custom {
  border: 1px solid #4b8f4e; }
  .green label.checkbox_custom.btn-primary:hover {
    background-color: #4b8f4e;
    border-color: #4b8f4e; }
  .green label.checkbox_custom.active {
    background-color: #4b8f4e; }

.green.modal .modal-dialog .modal-header {
  border-bottom: 4px solid #4b8f4e; }
  .green.modal .modal-dialog .modal-header .close {
    color: #4b8f4e; }

.green.modal .modal-dialog .modal-footer button.btn_cancel {
  border: 1px solid #4b8f4e;
  color: #4b8f4e; }

.green.modal .modal-dialog .modal-footer button.btn_save {
  background-color: #4b8f4e; }

.green.modal .modal-dialog .modal-footer #goTologin p {
  margin-right: 85px;
  cursor: pointer;
  color: #4b8f4e; }

.green .wrapper .rcs-inner-handle {
  background-color: #4b8f4e;
  border: 1px solid #4b8f4e; }

.green .notiInfoScrollWrapper button.read_more {
  width: 119px;
  height: 39px;
  color: #fff;
  background: #4b8f4e !important;
  border: 1px solid #4b8f4e !important; }

.green .innercontent_wrapper .profile_wrapper .performance-wrapper .performance-content p.count {
  color: #4b8f4e; }

.green label.checkbox_custom {
  border: 1px solid #4b8f4e; }
  .green label.checkbox_custom.btn-primary:not(:disabled):not(.disabled):active, .green label.checkbox_custom.btn-primary:not(:disabled):not(.disabled).active, .show > .green label.checkbox_custom.btn-primary.dropdown-toggle, .green label.checkbox_custom.btn-primary:hover {
    background-color: #4b8f4e;
    border-color: #4b8f4e; }
  .green label.checkbox_custom.active {
    background-color: #4b8f4e; }

.green .panelWrapper .scriptPanel .action.traffic_row .active {
  border: 1px solid #4b8f4e; }

.green .panelWrapper .scriptPanel .action.traffic_row.odd {
  background-color: rgba(75, 143, 78, 0.1); }

.green .panelWrapper .scriptPanel .action.traffic_row.even {
  background-color: #ffffff; }
  .green .panelWrapper .scriptPanel .action.traffic_row.even .child_wrapper {
    background-color: rgba(75, 143, 78, 0.1); }

.green .panelWrapper .scriptPanel .action:nth-child(odd) {
  background-color: rgba(75, 143, 78, 0.1); }

.green .panelWrapper .scriptPanel .action:nth-child(even), .green .panelWrapper .scriptPanel .action.even {
  background-color: #ffffff; }

.green .panelWrapper .scriptPanel .action.archiveRow.active {
  border: 1px solid #4b8f4e; }

.green .optscriptPanel .action.traffic_row .active {
  border: 1px solid #4b8f4e; }

.green .optscriptPanel .action.traffic_row.odd {
  background-color: rgba(75, 143, 78, 0.1); }

.green .optscriptPanel .action.traffic_row.even {
  background-color: #ffffff; }
  .green .optscriptPanel .action.traffic_row.even .child_wrapper {
    background-color: rgba(75, 143, 78, 0.1); }

.green .optscriptPanel .action:nth-child(odd) {
  background-color: rgba(75, 143, 78, 0.1); }

.green .optscriptPanel .action:nth-child(even), .green .optscriptPanel .action.even {
  background-color: #ffffff; }

.green .optscriptPanel .action.archiveRow.active {
  border: 1px solid #4b8f4e; }

.green .traffic_wrapper .formBtns {
  background-color: rgba(75, 143, 78, 0.1); }

.green .customDrpDwn .css-10nd86i:hover, .green .customDrpDwn .css-10nd86i:active, .green .customDrpDwn .css-10nd86i:focus {
  border-color: #4b8f4e; }

.green .customDrpDwn .css-19bqh2r {
  fill: #4b8f4e;
  stroke: #4b8f4e; }

.green .customDrpDwn .css-15k3avv .css-z5z6cw, .green .customDrpDwn .css-11unzgr .css-z5z6cw {
  background-color: #4b8f4e; }
  .green .customDrpDwn .css-15k3avv .css-z5z6cw:hover, .green .customDrpDwn .css-15k3avv .css-z5z6cw:active, .green .customDrpDwn .css-11unzgr .css-z5z6cw:hover, .green .customDrpDwn .css-11unzgr .css-z5z6cw:active {
    background-color: #4b8f4e; }

.green .customDrpDwn .css-15k3avv .css-wqgs6e:hover, .green .customDrpDwn .css-15k3avv .css-wqgs6e:active, .green .customDrpDwn .css-11unzgr .css-wqgs6e:hover, .green .customDrpDwn .css-11unzgr .css-wqgs6e:active {
  background-color: #4b8f4e; }

.green .customDrpDwn .css-1ep9fjw {
  color: #4b8f4e; }

.green .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage img, .green .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage img {
  border: none; }

.green .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage p, .green .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage p {
  color: #4b8f4e; }

.green .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper img {
  border: none !important; }

.green .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper p {
  color: #4b8f4e; }

.green .optDeptWrapper .operatorSection .operatorsWrapper .operatorNick p {
  color: #4b8f4e; }

.green .optDeptWrapper .operatorSection .operatorsWrapper .operatorDetails p.email {
  color: #4b8f4e; }

.green .optDeptWrapper.section_Dept .panelContent .editWrapper p {
  color: #4b8f4e; }

.green .archive_pagination .pagination {
  border: 0.5 solid #4b8f4e;
  color: #4b8f4e !important; }
  .green .archive_pagination .pagination a {
    color: #4b8f4e !important; }

.green .archive_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #4b8f4e solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.green .traffic_pagination .pagination {
  border: 0.5 solid #4b8f4e;
  color: #4b8f4e !important; }
  .green .traffic_pagination .pagination a {
    color: #4b8f4e !important; }

.green .traffic_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #4b8f4e solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.green .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapse_wrapper .iconshow {
  border: 1px solid #4b8f4e;
  background-image: url("assets/images/minus_icons/minus_green.png");
  background-repeat: no-repeat;
  background-position: center; }

.green .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .with-image p a {
  color: #4b8f4e; }

.green .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapsed .collapse_wrapper p:first-child {
  background-image: url("assets/images/plus_icons/plus_green.png");
  background-size: 8px; }

.green .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapse_wrapper .collapsed {
  background-image: url("assets/images/plus_icons/plus_green.png") !important;
  background-size: 8px; }

.green .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count {
  background-color: #4b8f4e; }
  .green .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count:hover {
    border: 1px solid #4b8f4e;
    color: #4b8f4e; }

.green .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p.domain span {
  color: #4b8f4e; }

.green .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .tumbnail_footer {
  background-color: rgba(75, 143, 78, 0.1); }

.green .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper p {
  color: #4b8f4e; }

.green .chatInfoWrapper .nav.nav-tabs a.nav-item {
  background-color: #4b8f4e;
  border-right: 1px solid #fff !important; }
  .green .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
    color: #4b8f4e !important; }

.green .chatInfoWrapper .tab-content p.hide_btn {
  color: #4b8f4e !important; }
  .green .chatInfoWrapper .tab-content p.hide_btn svg {
    fill: #4b8f4e !important;
    stroke: #4b8f4e !important;
    margin-right: 9px;
    margin-top: -3px; }

.green .chatInfoWrapper .tabContent .data .webInfo, .green .chatInfoWrapper .tabContent .data .notes {
  background-color: #EDF3ED !important; }

.green .customerChat .chatText {
  background-color: #4b8f4e; }
  .green .customerChat .chatText:before {
    content: "";
    position: absolute;
    top: -14px;
    right: 17px;
    z-index: 1;
    border: solid 7px transparent;
    border-bottom-color: #4b8f4e; }

.green .operatorChat .chatText {
  background-color: rgba(75, 143, 78, 0.1); }
  .green .operatorChat .chatText:before {
    content: "";
    position: absolute;
    top: -14px;
    left: 17px;
    z-index: 1;
    border: solid 7px transparent;
    border-bottom-color: rgba(75, 143, 78, 0.1); }

.green .historyWrapper .transcriptDetails {
  background-color: rgba(75, 143, 78, 0.1); }

.green .websitesWrapper .websitesDetails .box .webLink {
  color: #4b8f4e; }

.green .websitesWrapper .websitesDetails .box .actionsWrapper {
  background-color: #4b8f4e; }

.green .websitesWrapper .websitesDetails .box .overview .row p {
  color: #4b8f4e; }

.green .websitesWrapper .websitesDetails .box .addWrapper img {
  border: 2px solid #4b8f4e; }

.green .websitesWrapper .websitesDetails .box .addWrapper p {
  color: #4b8f4e; }

.green .websitesWrapper .websitesDetails .box .addWrapper .websitePayDollar {
  border: 3px solid #4b8f4e !important; }

.green .textEditorWrapper .sendImgWrappger {
  background-color: #4b8f4e; }

.green .innercontent_wrapper .directLinkWrapper .uploadBtn button {
  border: 1px solid #4b8f4e !important; }
  .green .innercontent_wrapper .directLinkWrapper .uploadBtn button p {
    color: #4b8f4e !important; }

.green .innercontent_wrapper .directLinkWrapper.toolBarWrapper .with_btn .uploadBtn button {
  border: 1px solid #4b8f4e !important;
  background-color: #fff; }
  .green .innercontent_wrapper .directLinkWrapper.toolBarWrapper .with_btn .uploadBtn button p {
    color: #4b8f4e !important; }

.grey .existing_opcreate {
  background-color: #5f7c8c !important;
  color: #fff !important; }

.grey .toolBarPreviewWrapper .positionIcons.selected {
  background-color: #5f7c8c !important;
  border: 1px solid #5f7c8c !important; }

.grey .modal-dialog .modal-content .modal-header button.chatInfoBtn {
  border: 1px solid #5f7c8c !important;
  background-color: #ffffff !important;
  color: #5f7c8c !important; }

.grey .widgetPosition p span.selected {
  color: #ffffff;
  background-color: #5f7c8c !important; }

.grey .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span {
  color: #5f7c8c !important; }
  .grey .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span.selected {
    color: #000000 !important; }

.grey .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw {
  box-shadow: #5f7c8c !important; }
  .grey .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:hover, .grey .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:active, .grey .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:focus {
    border-color: #5f7c8c !important; }

.grey .invitationRulesWrapper .editRulesSection .relatedDropDown .css-19bqh2r {
  fill: #5f7c8c !important;
  stroke: #5f7c8c !important; }

.grey .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw, .grey .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw {
  background-color: #5f7c8c; }
  .grey .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw:hover, .grey .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw:active, .grey .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw:hover, .grey .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw:active {
    background-color: #5f7c8c; }

.grey .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-wqgs6e:hover, .grey .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-wqgs6e:active, .grey .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-wqgs6e:hover, .grey .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-wqgs6e:active {
  background-color: #5f7c8c; }

.grey .invitationRulesWrapper .editRulesSection .relatedDropDown .css-1ep9fjw {
  color: #5f7c8c; }

.grey .modal-dialog .modal-content .modal-header button.chatInfoBtn:hover {
  color: #ffff !important;
  background-color: #5f7c8c !important; }

.grey .divider {
  color: #5f7c8c !important; }

.grey .expire_account {
  color: #5f7c8c !important; }

.grey .archive_pagination ul li a {
  color: #5f7c8c !important;
  border: none;
  outline: none;
  box-shadow: none; }

.grey .archive_pagination ul li.active {
  border-color: #5f7c8c; }

.grey .dropdown-item:hover {
  background-color: #5f7c8c !important;
  color: #f7f5f5 !important;
  font-weight: 700 !important; }

.grey .rcs-custom-scroll .rcs-inner-handle {
  background-color: #5f7c8c !important;
  border-color: #5f7c8c !important; }

.grey .checkout {
  background-color: #5f7c8c; }

.grey .profileStatus .status p.dropdown-item {
  color: #000; }

.grey .menuList {
  height: 54px;
  background-color: #fff;
  cursor: pointer; }
  .grey .menuList.selected {
    background-color: #5f7c8c !important; }
    .grey .menuList.selected p {
      color: #fff; }
    .grey .menuList.selected .lineWrapper .line1 {
      background-color: #fff;
      border-color: #fff; }
    .grey .menuList.selected .lineWrapper .line2 {
      border-color: #fff; }
      .grey .menuList.selected .lineWrapper .line2.mask {
        opacity: 1;
        background-color: #fff; }
  .grey .menuList p {
    width: 100%;
    float: left;
    color: #293037;
    font-family: "Segoe UI Semi Bold";
    font-size: 13px;
    font-weight: 700; }
  .grey .menuList .lineWrapper {
    position: absolute;
    right: 21px; }
    .grey .menuList .lineWrapper .line1 {
      width: 35px;
      height: 20px;
      border-bottom: 1px solid #dedede;
      -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
      position: absolute; }
      .grey .menuList .lineWrapper .line1.selectedNext {
        background-color: #5f7c8c !important;
        border-color: #fff; }
    .grey .menuList .lineWrapper .line2 {
      width: 33px;
      height: 20px;
      border-bottom: 1px solid #dedede;
      -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
      position: absolute;
      top: 17px;
      left: -16px; }
      .grey .menuList .lineWrapper .line2.mask {
        opacity: 0;
        left: -1px;
        width: 37px;
        top: 29px;
        background-color: #fff;
        border: 0; }
        .grey .menuList .lineWrapper .line2.mask.selectedNext {
          opacity: 1;
          background-color: #5f7c8c !important; }
      .grey .menuList .lineWrapper .line2.selectedNext {
        border-color: #fff; }

.grey .carousel {
  padding: 12px 0px; }
  .grey .carousel img.carouselBtn {
    position: absolute;
    cursor: pointer;
    top: 90px; }
    .grey .carousel img.carouselBtn.carousel-left {
      left: 30px; }
  .grey .carousel ul {
    margin-left: 0;
    margin-right: 0;
    transition: all 1s; }
    .grey .carousel ul.widgetMode li {
      opacity: 0.5;
      float: left; }
      .grey .carousel ul.widgetMode li.selected, .grey .carousel ul.widgetMode li:hover {
        opacity: 1;
        border: 3px solid #5f7c8c !important; }
        .grey .carousel ul.widgetMode li.selected h4, .grey .carousel ul.widgetMode li:hover h4 {
          background-color: #5f7c8c !important;
          color: #ffffff; }
  .grey .carousel .carousel-cell {
    cursor: pointer;
    width: 210px;
    height: 192px;
    box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
    border-radius: 20px;
    background-color: #ffffff; }
    .grey .carousel .carousel-cell.opacity_0 {
      opacity: 0;
      margin-right: 60px;
      transition: opacity 2s; }
    .grey .carousel .carousel-cell:hover {
      border: 3px solid #5f7c8c !important; }
      .grey .carousel .carousel-cell:hover h4 {
        background-color: #5f7c8c !important;
        color: #ffffff; }
    .grey .carousel .carousel-cell h4 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      padding: 22px 0 18px;
      border-bottom: 4px solid #5f7c8c !important;
      margin: 0;
      border-radius: 15px 15px 0 0; }
    .grey .carousel .carousel-cell .img-wrapper {
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      width: 210px; }

.grey #sidebar > ul.sideMenu {
  background-color: #5f7c8c; }
  .grey #sidebar > ul.sideMenu li.active {
    background-color: #506a78; }

.grey #sidebar .subMenu li.parent_list > ul li.active {
  background-color: #506a78; }

.grey #sidebar.operator_menu .subMenu li.parent_list.active {
  background-color: #506a78; }

.grey .image_upload button {
  border: 1px solid #5f7c8c; }

.grey .image_upload p {
  color: #5f7c8c; }

.grey .profileImg .img_delete p:last-child {
  color: #5f7c8c; }

.grey .save_BTN {
  border: 1px solid #5f7c8c;
  background-color: #5f7c8c !important; }

.grey .formBtns Button.btn_clr {
  border: 1px solid #5f7c8c;
  color: #5f7c8c; }

.grey .formBtns Button.btn_save {
  background-color: #5f7c8c; }

.grey .overview ol li::before {
  border: 1px solid #5f7c8c;
  color: #5f7c8c; }

.grey .overview ol li p a:not([href]):not([tabindex]) {
  color: #5f7c8c; }

.grey .scriptWrapper.innercontent_wrapper .box.editScriptSection .headerTitle {
  border-bottom: 4px solid #5f7c8c; }

.grey .chatWrapper .settingFormWrapper .headerTitle h4 span {
  color: #5f7c8c !important; }

.grey .preview .chatPreview .headerTitle.active {
  background-color: #5f7c8c; }

.grey .preview .chatPreview .chatBox .msgArea p.reply, .grey .preview .chatPreview .chatBox .msgArea a.reply {
  color: #5f7c8c; }

.grey .preview .chatPreview .btn_send {
  border-color: #5f7c8c;
  background-color: #5f7c8c; }
  .grey .preview .chatPreview .btn_send:hover {
    color: #5f7c8c; }
  .grey .preview .chatPreview .btn_send.preview_btn:hover {
    background-color: #5f7c8c;
    color: #fff; }

.grey .optpanelHeader {
  background-color: #5f7c8c; }

.grey .panelWrapper.odd {
  background-color: #EFF2F3; }

.grey .panelWrapper .panelHeader {
  background-color: #5f7c8c; }

.grey .panelWrapper .scriptPanel > div.panelHeading {
  color: #5f7c8c; }
  .grey .panelWrapper .scriptPanel > div.panelHeading p {
    color: #5f7c8c; }
    .grey .panelWrapper .scriptPanel > div.panelHeading p:first-child {
      border: 1px solid #5f7c8c;
      background-color: #5f7c8c; }
  .grey .panelWrapper .scriptPanel > div.panelHeading.collapsed p:first-child {
    background-image: url("assets/images/plus_icons/plus_grey.png") !important;
    background-size: 11px; }

.grey .panelWrapper .scriptPanel .action .data .optionArea p, .grey .panelWrapper .scriptPanel .action.data .optionArea p {
  color: #5f7c8c; }

.grey .panelWrapper .scriptPanel .action .data .optionArea .editWrapper > div p, .grey .panelWrapper .scriptPanel .action.data .optionArea .editWrapper > div p {
  color: #5f7c8c; }

.grey.ruleComponent.ghostClass {
  cursor: url("https://www.google.com/intl/en_ALL/mapfiles/closedhand.cur"), all-scroll;
  cursor: -webkit-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.grey.ruleComponent.even {
  background-color: #EFF2F3; }

.grey .ruleComponent .optionArea .editWrapper p {
  color: #5f7c8c; }

.grey .ruleComponent.even {
  background-color: #EFF2F3; }

.grey .fileTransferWrapper .settingFormWrapper form .customField .progress_wrapper .progress {
  background-color: #5f7c8c; }

.grey .fileTransferWrapper .scriptPanel p.action_text {
  color: #5f7c8c; }

.grey .custom_btn {
  border: 1px solid #5f7c8c;
  color: #5f7c8c; }
  .grey .custom_btn.btn-primary:hover {
    background-color: #5f7c8c;
    border-color: #5f7c8c;
    color: #fff; }

.grey .export_csvbtn {
  color: #5f7c8c; }
  .grey .export_csvbtn.btn-primary:hover a {
    color: #fff; }
  .grey .export_csvbtn a:hover {
    color: #fff; }

.grey .export_csvbtn {
  color: #5f7c8c; }
  .grey .export_csvbtn a {
    color: #5f7c8c; }

.grey .innercontent_wrapper .with-header .box .headerTitle {
  border-bottom: 4px solid #5f7c8c; }

.grey .customDrpDwn .css-19bqh2r {
  fill: #5f7c8c;
  stroke: #5f7c8c; }

.grey .customDrpDwn .css-1ep9fjw {
  color: #5f7c8c; }

.grey label.checkbox_custom {
  border: 1px solid #5f7c8c; }
  .grey label.checkbox_custom.btn-primary:hover {
    background-color: #5f7c8c;
    border-color: #5f7c8c; }
  .grey label.checkbox_custom.active {
    background-color: #5f7c8c; }

.grey.modal .modal-dialog .modal-header {
  border-bottom: 4px solid #5f7c8c; }
  .grey.modal .modal-dialog .modal-header .close {
    color: #5f7c8c; }

.grey.modal .modal-dialog .modal-footer button.btn_cancel {
  border: 1px solid #5f7c8c;
  color: #5f7c8c; }

.grey.modal .modal-dialog .modal-footer button.btn_save {
  background-color: #5f7c8c; }

.grey.modal .modal-dialog .modal-footer #goTologin p {
  margin-right: 85px;
  cursor: pointer;
  color: #5f7c8c; }

.grey .wrapper .rcs-inner-handle {
  background-color: #5f7c8c;
  border: 1px solid #5f7c8c; }

.grey .notiInfoScrollWrapper button.read_more {
  width: 119px;
  height: 39px;
  color: #fff;
  background: #5f7c8c !important;
  border: 1px solid #5f7c8c !important; }

.grey .innercontent_wrapper .profile_wrapper .performance-wrapper .performance-content p.count {
  color: #5f7c8c; }

.grey label.checkbox_custom {
  border: 1px solid #5f7c8c; }
  .grey label.checkbox_custom.btn-primary:not(:disabled):not(.disabled):active, .grey label.checkbox_custom.btn-primary:not(:disabled):not(.disabled).active, .show > .grey label.checkbox_custom.btn-primary.dropdown-toggle, .grey label.checkbox_custom.btn-primary:hover {
    background-color: #5f7c8c;
    border-color: #5f7c8c; }
  .grey label.checkbox_custom.active {
    background-color: #5f7c8c; }

.grey .panelWrapper .scriptPanel .action.traffic_row .active {
  border: 1px solid #5f7c8c; }

.grey .panelWrapper .scriptPanel .action.traffic_row.odd {
  background-color: rgba(95, 124, 140, 0.1); }

.grey .panelWrapper .scriptPanel .action.traffic_row.even {
  background-color: #ffffff; }
  .grey .panelWrapper .scriptPanel .action.traffic_row.even .child_wrapper {
    background-color: rgba(95, 124, 140, 0.1); }

.grey .panelWrapper .scriptPanel .action:nth-child(odd) {
  background-color: rgba(95, 124, 140, 0.1); }

.grey .panelWrapper .scriptPanel .action:nth-child(even), .grey .panelWrapper .scriptPanel .action.even {
  background-color: #ffffff; }

.grey .panelWrapper .scriptPanel .action.archiveRow.active {
  border: 1px solid #5f7c8c; }

.grey .optscriptPanel .action.traffic_row .active {
  border: 1px solid #5f7c8c; }

.grey .optscriptPanel .action.traffic_row.odd {
  background-color: rgba(95, 124, 140, 0.1); }

.grey .optscriptPanel .action.traffic_row.even {
  background-color: #ffffff; }
  .grey .optscriptPanel .action.traffic_row.even .child_wrapper {
    background-color: rgba(95, 124, 140, 0.1); }

.grey .optscriptPanel .action:nth-child(odd) {
  background-color: rgba(95, 124, 140, 0.1); }

.grey .optscriptPanel .action:nth-child(even), .grey .optscriptPanel .action.even {
  background-color: #ffffff; }

.grey .optscriptPanel .action.archiveRow.active {
  border: 1px solid #5f7c8c; }

.grey .traffic_wrapper .formBtns {
  background-color: rgba(95, 124, 140, 0.1); }

.grey .customDrpDwn .css-10nd86i:hover, .grey .customDrpDwn .css-10nd86i:active, .grey .customDrpDwn .css-10nd86i:focus {
  border-color: #5f7c8c; }

.grey .customDrpDwn .css-19bqh2r {
  fill: #5f7c8c;
  stroke: #5f7c8c; }

.grey .customDrpDwn .css-15k3avv .css-z5z6cw, .grey .customDrpDwn .css-11unzgr .css-z5z6cw {
  background-color: #5f7c8c; }
  .grey .customDrpDwn .css-15k3avv .css-z5z6cw:hover, .grey .customDrpDwn .css-15k3avv .css-z5z6cw:active, .grey .customDrpDwn .css-11unzgr .css-z5z6cw:hover, .grey .customDrpDwn .css-11unzgr .css-z5z6cw:active {
    background-color: #5f7c8c; }

.grey .customDrpDwn .css-15k3avv .css-wqgs6e:hover, .grey .customDrpDwn .css-15k3avv .css-wqgs6e:active, .grey .customDrpDwn .css-11unzgr .css-wqgs6e:hover, .grey .customDrpDwn .css-11unzgr .css-wqgs6e:active {
  background-color: #5f7c8c; }

.grey .customDrpDwn .css-1ep9fjw {
  color: #5f7c8c; }

.grey .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage img, .grey .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage img {
  border: none; }

.grey .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage p, .grey .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage p {
  color: #5f7c8c; }

.grey .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper img {
  border: none !important; }

.grey .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper p {
  color: #5f7c8c; }

.grey .optDeptWrapper .operatorSection .operatorsWrapper .operatorNick p {
  color: #5f7c8c; }

.grey .optDeptWrapper .operatorSection .operatorsWrapper .operatorDetails p.email {
  color: #5f7c8c; }

.grey .optDeptWrapper.section_Dept .panelContent .editWrapper p {
  color: #5f7c8c; }

.grey .archive_pagination .pagination {
  border: 0.5 solid #5f7c8c;
  color: #5f7c8c !important; }
  .grey .archive_pagination .pagination a {
    color: #5f7c8c !important; }

.grey .archive_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #5f7c8c solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.grey .traffic_pagination .pagination {
  border: 0.5 solid #5f7c8c;
  color: #5f7c8c !important; }
  .grey .traffic_pagination .pagination a {
    color: #5f7c8c !important; }

.grey .traffic_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #5f7c8c solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.grey .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapse_wrapper .iconshow {
  border: 1px solid #5f7c8c;
  background-image: url("assets/images/minus_icons/minus_grey.png");
  background-repeat: no-repeat;
  background-position: center; }

.grey .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .with-image p a {
  color: #5f7c8c; }

.grey .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapsed .collapse_wrapper p:first-child {
  background-image: url("assets/images/plus_icons/plus_grey.png");
  background-size: 8px; }

.grey .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapse_wrapper .collapsed {
  background-image: url("assets/images/plus_icons/plus_grey.png") !important;
  background-size: 8px; }

.grey .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count {
  background-color: #5f7c8c; }
  .grey .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count:hover {
    border: 1px solid #5f7c8c;
    color: #5f7c8c; }

.grey .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p.domain span {
  color: #5f7c8c; }

.grey .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .tumbnail_footer {
  background-color: rgba(95, 124, 140, 0.1); }

.grey .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper p {
  color: #5f7c8c; }

.grey .chatInfoWrapper .nav.nav-tabs a.nav-item {
  background-color: #5f7c8c;
  border-right: 1px solid #fff !important; }
  .grey .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
    color: #5f7c8c !important; }

.grey .chatInfoWrapper .tab-content p.hide_btn {
  color: #5f7c8c !important; }
  .grey .chatInfoWrapper .tab-content p.hide_btn svg {
    fill: #5f7c8c !important;
    stroke: #5f7c8c !important;
    margin-right: 9px;
    margin-top: -3px; }

.grey .chatInfoWrapper .tabContent .data .webInfo, .grey .chatInfoWrapper .tabContent .data .notes {
  background-color: #EFF2F3 !important; }

.grey .customerChat .chatText {
  background-color: #5f7c8c; }
  .grey .customerChat .chatText:before {
    content: "";
    position: absolute;
    top: -14px;
    right: 17px;
    z-index: 1;
    border: solid 7px transparent;
    border-bottom-color: #5f7c8c; }

.grey .operatorChat .chatText {
  background-color: rgba(95, 124, 140, 0.1); }
  .grey .operatorChat .chatText:before {
    content: "";
    position: absolute;
    top: -14px;
    left: 17px;
    z-index: 1;
    border: solid 7px transparent;
    border-bottom-color: rgba(95, 124, 140, 0.1); }

.grey .historyWrapper .transcriptDetails {
  background-color: rgba(95, 124, 140, 0.1); }

.grey .websitesWrapper .websitesDetails .box .webLink {
  color: #5f7c8c; }

.grey .websitesWrapper .websitesDetails .box .actionsWrapper {
  background-color: #5f7c8c; }

.grey .websitesWrapper .websitesDetails .box .overview .row p {
  color: #5f7c8c; }

.grey .websitesWrapper .websitesDetails .box .addWrapper img {
  border: 2px solid #5f7c8c; }

.grey .websitesWrapper .websitesDetails .box .addWrapper p {
  color: #5f7c8c; }

.grey .websitesWrapper .websitesDetails .box .addWrapper .websitePayDollar {
  border: 3px solid #5f7c8c !important; }

.grey .textEditorWrapper .sendImgWrappger {
  background-color: #5f7c8c; }

.grey .innercontent_wrapper .directLinkWrapper .uploadBtn button {
  border: 1px solid #5f7c8c !important; }
  .grey .innercontent_wrapper .directLinkWrapper .uploadBtn button p {
    color: #5f7c8c !important; }

.grey .innercontent_wrapper .directLinkWrapper.toolBarWrapper .with_btn .uploadBtn button {
  border: 1px solid #5f7c8c !important;
  background-color: #fff; }
  .grey .innercontent_wrapper .directLinkWrapper.toolBarWrapper .with_btn .uploadBtn button p {
    color: #5f7c8c !important; }

.deepSkyBlue .existing_opcreate {
  background-color: #64a9e0 !important;
  color: #fff !important; }

.deepSkyBlue .toolBarPreviewWrapper .positionIcons.selected {
  background-color: #64a9e0 !important;
  border: 1px solid #64a9e0 !important; }

.deepSkyBlue .modal-dialog .modal-content .modal-header button.chatInfoBtn {
  border: 1px solid #64a9e0 !important;
  background-color: #ffffff !important;
  color: #64a9e0 !important; }

.deepSkyBlue .widgetPosition p span.selected {
  color: #ffffff;
  background-color: #64a9e0 !important; }

.deepSkyBlue .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span {
  color: #64a9e0 !important; }
  .deepSkyBlue .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span.selected {
    color: #000000 !important; }

.deepSkyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw {
  box-shadow: #64a9e0 !important; }
  .deepSkyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:hover, .deepSkyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:active, .deepSkyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-2o5izw:focus {
    border-color: #64a9e0 !important; }

.deepSkyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-19bqh2r {
  fill: #64a9e0 !important;
  stroke: #64a9e0 !important; }

.deepSkyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw, .deepSkyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw {
  background-color: #64a9e0; }
  .deepSkyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw:hover, .deepSkyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-z5z6cw:active, .deepSkyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw:hover, .deepSkyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-z5z6cw:active {
    background-color: #64a9e0; }

.deepSkyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-wqgs6e:hover, .deepSkyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-15k3avv .css-wqgs6e:active, .deepSkyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-wqgs6e:hover, .deepSkyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-11unzgr .css-wqgs6e:active {
  background-color: #64a9e0; }

.deepSkyBlue .invitationRulesWrapper .editRulesSection .relatedDropDown .css-1ep9fjw {
  color: #64a9e0; }

.deepSkyBlue .modal-dialog .modal-content .modal-header button.chatInfoBtn:hover {
  color: #ffff !important;
  background-color: #64a9e0 !important; }

.deepSkyBlue .divider {
  color: #64a9e0 !important; }

.deepSkyBlue .expire_account {
  color: #64a9e0 !important; }

.deepSkyBlue .archive_pagination ul li a {
  color: #64a9e0 !important;
  border: none;
  outline: none;
  box-shadow: none; }

.deepSkyBlue .archive_pagination ul li.active {
  border-color: #64a9e0; }

.deepSkyBlue .dropdown-item:hover {
  background-color: #64a9e0 !important;
  color: #f7f5f5 !important;
  font-weight: 700 !important; }

.deepSkyBlue .rcs-custom-scroll .rcs-inner-handle {
  background-color: #64a9e0 !important;
  border-color: #64a9e0 !important; }

.deepSkyBlue .checkout {
  background-color: #64a9e0; }

.deepSkyBlue .profileStatus .status p.dropdown-item {
  color: #000; }

.deepSkyBlue .menuList {
  height: 54px;
  background-color: #fff;
  cursor: pointer; }
  .deepSkyBlue .menuList.selected {
    background-color: #64a9e0 !important; }
    .deepSkyBlue .menuList.selected p {
      color: #fff; }
    .deepSkyBlue .menuList.selected .lineWrapper .line1 {
      background-color: #fff;
      border-color: #fff; }
    .deepSkyBlue .menuList.selected .lineWrapper .line2 {
      border-color: #fff; }
      .deepSkyBlue .menuList.selected .lineWrapper .line2.mask {
        opacity: 1;
        background-color: #fff; }
  .deepSkyBlue .menuList p {
    width: 100%;
    float: left;
    color: #293037;
    font-family: "Segoe UI Semi Bold";
    font-size: 13px;
    font-weight: 700; }
  .deepSkyBlue .menuList .lineWrapper {
    position: absolute;
    right: 21px; }
    .deepSkyBlue .menuList .lineWrapper .line1 {
      width: 35px;
      height: 20px;
      border-bottom: 1px solid #dedede;
      -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
      position: absolute; }
      .deepSkyBlue .menuList .lineWrapper .line1.selectedNext {
        background-color: #64a9e0 !important;
        border-color: #fff; }
    .deepSkyBlue .menuList .lineWrapper .line2 {
      width: 33px;
      height: 20px;
      border-bottom: 1px solid #dedede;
      -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
      position: absolute;
      top: 17px;
      left: -16px; }
      .deepSkyBlue .menuList .lineWrapper .line2.mask {
        opacity: 0;
        left: -1px;
        width: 37px;
        top: 29px;
        background-color: #fff;
        border: 0; }
        .deepSkyBlue .menuList .lineWrapper .line2.mask.selectedNext {
          opacity: 1;
          background-color: #64a9e0 !important; }
      .deepSkyBlue .menuList .lineWrapper .line2.selectedNext {
        border-color: #fff; }

.deepSkyBlue .carousel {
  padding: 12px 0px; }
  .deepSkyBlue .carousel img.carouselBtn {
    position: absolute;
    cursor: pointer;
    top: 90px; }
    .deepSkyBlue .carousel img.carouselBtn.carousel-left {
      left: 30px; }
  .deepSkyBlue .carousel ul {
    margin-left: 0;
    margin-right: 0;
    transition: all 1s; }
    .deepSkyBlue .carousel ul.widgetMode li {
      opacity: 0.5;
      float: left; }
      .deepSkyBlue .carousel ul.widgetMode li.selected, .deepSkyBlue .carousel ul.widgetMode li:hover {
        opacity: 1;
        border: 3px solid #64a9e0 !important; }
        .deepSkyBlue .carousel ul.widgetMode li.selected h4, .deepSkyBlue .carousel ul.widgetMode li:hover h4 {
          background-color: #64a9e0 !important;
          color: #ffffff; }
  .deepSkyBlue .carousel .carousel-cell {
    cursor: pointer;
    width: 210px;
    height: 192px;
    box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
    border-radius: 20px;
    background-color: #ffffff; }
    .deepSkyBlue .carousel .carousel-cell.opacity_0 {
      opacity: 0;
      margin-right: 60px;
      transition: opacity 2s; }
    .deepSkyBlue .carousel .carousel-cell:hover {
      border: 3px solid #64a9e0 !important; }
      .deepSkyBlue .carousel .carousel-cell:hover h4 {
        background-color: #64a9e0 !important;
        color: #ffffff; }
    .deepSkyBlue .carousel .carousel-cell h4 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      padding: 22px 0 18px;
      border-bottom: 4px solid #64a9e0 !important;
      margin: 0;
      border-radius: 15px 15px 0 0; }
    .deepSkyBlue .carousel .carousel-cell .img-wrapper {
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      width: 210px; }

.deepSkyBlue #sidebar > ul.sideMenu {
  background-color: #64a9e0; }
  .deepSkyBlue #sidebar > ul.sideMenu li.active {
    background-color: #5699ce; }

.deepSkyBlue #sidebar .subMenu li.parent_list > ul li.active {
  background-color: #5699ce; }

.deepSkyBlue #sidebar.operator_menu .subMenu li.parent_list.active {
  background-color: #5699ce; }

.deepSkyBlue .image_upload button {
  border: 1px solid #64a9e0; }

.deepSkyBlue .image_upload p {
  color: #64a9e0; }

.deepSkyBlue .profileImg .img_delete p:last-child {
  color: #64a9e0; }

.deepSkyBlue .save_BTN {
  border: 1px solid #64a9e0;
  background-color: #64a9e0 !important; }

.deepSkyBlue .formBtns Button.btn_clr {
  border: 1px solid #64a9e0;
  color: #64a9e0; }

.deepSkyBlue .formBtns Button.btn_save {
  background-color: #64a9e0; }

.deepSkyBlue .overview ol li::before {
  border: 1px solid #64a9e0;
  color: #64a9e0; }

.deepSkyBlue .overview ol li p a:not([href]):not([tabindex]) {
  color: #64a9e0; }

.deepSkyBlue .scriptWrapper.innercontent_wrapper .box.editScriptSection .headerTitle {
  border-bottom: 4px solid #64a9e0; }

.deepSkyBlue .chatWrapper .settingFormWrapper .headerTitle h4 span {
  color: #64a9e0 !important; }

.deepSkyBlue .preview .chatPreview .headerTitle.active {
  background-color: #64a9e0; }

.deepSkyBlue .preview .chatPreview .chatBox .msgArea p.reply, .deepSkyBlue .preview .chatPreview .chatBox .msgArea a.reply {
  color: #64a9e0; }

.deepSkyBlue .preview .chatPreview .btn_send {
  border-color: #64a9e0;
  background-color: #64a9e0; }
  .deepSkyBlue .preview .chatPreview .btn_send:hover {
    color: #64a9e0; }
  .deepSkyBlue .preview .chatPreview .btn_send.preview_btn:hover {
    background-color: #64a9e0;
    color: #fff; }

.deepSkyBlue .optpanelHeader {
  background-color: #64a9e0; }

.deepSkyBlue .panelWrapper.odd {
  background-color: #EFF6FC; }

.deepSkyBlue .panelWrapper .panelHeader {
  background-color: #64a9e0; }

.deepSkyBlue .panelWrapper .scriptPanel > div.panelHeading {
  color: #64a9e0; }
  .deepSkyBlue .panelWrapper .scriptPanel > div.panelHeading p {
    color: #64a9e0; }
    .deepSkyBlue .panelWrapper .scriptPanel > div.panelHeading p:first-child {
      border: 1px solid #64a9e0;
      background-color: #64a9e0; }
  .deepSkyBlue .panelWrapper .scriptPanel > div.panelHeading.collapsed p:first-child {
    background-image: url("assets/images/plus_icons/plus_skyblue.png") !important;
    background-size: 11px; }

.deepSkyBlue .panelWrapper .scriptPanel .action .data .optionArea p, .deepSkyBlue .panelWrapper .scriptPanel .action.data .optionArea p {
  color: #64a9e0; }

.deepSkyBlue .panelWrapper .scriptPanel .action .data .optionArea .editWrapper > div p, .deepSkyBlue .panelWrapper .scriptPanel .action.data .optionArea .editWrapper > div p {
  color: #64a9e0; }

.deepSkyBlue.ruleComponent.ghostClass {
  cursor: url("https://www.google.com/intl/en_ALL/mapfiles/closedhand.cur"), all-scroll;
  cursor: -webkit-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.deepSkyBlue.ruleComponent.even {
  background-color: #EFF6FC; }

.deepSkyBlue .ruleComponent .optionArea .editWrapper p {
  color: #64a9e0; }

.deepSkyBlue .ruleComponent.even {
  background-color: #EFF6FC; }

.deepSkyBlue .fileTransferWrapper .settingFormWrapper form .customField .progress_wrapper .progress {
  background-color: #64a9e0; }

.deepSkyBlue .fileTransferWrapper .scriptPanel p.action_text {
  color: #64a9e0; }

.deepSkyBlue .custom_btn {
  border: 1px solid #64a9e0;
  color: #64a9e0; }
  .deepSkyBlue .custom_btn.btn-primary:hover {
    background-color: #64a9e0;
    border-color: #64a9e0;
    color: #fff; }

.deepSkyBlue .export_csvbtn {
  color: #64a9e0; }
  .deepSkyBlue .export_csvbtn.btn-primary:hover a {
    color: #fff; }
  .deepSkyBlue .export_csvbtn a:hover {
    color: #fff; }

.deepSkyBlue .export_csvbtn {
  color: #64a9e0; }
  .deepSkyBlue .export_csvbtn a {
    color: #64a9e0; }

.deepSkyBlue .innercontent_wrapper .with-header .box .headerTitle {
  border-bottom: 4px solid #64a9e0; }

.deepSkyBlue .customDrpDwn .css-19bqh2r {
  fill: #64a9e0;
  stroke: #64a9e0; }

.deepSkyBlue .customDrpDwn .css-1ep9fjw {
  color: #64a9e0; }

.deepSkyBlue label.checkbox_custom {
  border: 1px solid #64a9e0; }
  .deepSkyBlue label.checkbox_custom.btn-primary:hover {
    background-color: #64a9e0;
    border-color: #64a9e0; }
  .deepSkyBlue label.checkbox_custom.active {
    background-color: #64a9e0; }

.deepSkyBlue.modal .modal-dialog .modal-header {
  border-bottom: 4px solid #64a9e0; }
  .deepSkyBlue.modal .modal-dialog .modal-header .close {
    color: #64a9e0; }

.deepSkyBlue.modal .modal-dialog .modal-footer button.btn_cancel {
  border: 1px solid #64a9e0;
  color: #64a9e0; }

.deepSkyBlue.modal .modal-dialog .modal-footer button.btn_save {
  background-color: #64a9e0; }

.deepSkyBlue.modal .modal-dialog .modal-footer #goTologin p {
  margin-right: 85px;
  cursor: pointer;
  color: #64a9e0; }

.deepSkyBlue .wrapper .rcs-inner-handle {
  background-color: #64a9e0;
  border: 1px solid #64a9e0; }

.deepSkyBlue .notiInfoScrollWrapper button.read_more {
  width: 119px;
  height: 39px;
  color: #fff;
  background: #64a9e0 !important;
  border: 1px solid #64a9e0 !important; }

.deepSkyBlue .innercontent_wrapper .profile_wrapper .performance-wrapper .performance-content p.count {
  color: #64a9e0; }

.deepSkyBlue label.checkbox_custom {
  border: 1px solid #64a9e0; }
  .deepSkyBlue label.checkbox_custom.btn-primary:not(:disabled):not(.disabled):active, .deepSkyBlue label.checkbox_custom.btn-primary:not(:disabled):not(.disabled).active, .show > .deepSkyBlue label.checkbox_custom.btn-primary.dropdown-toggle, .deepSkyBlue label.checkbox_custom.btn-primary:hover {
    background-color: #64a9e0;
    border-color: #64a9e0; }
  .deepSkyBlue label.checkbox_custom.active {
    background-color: #64a9e0; }

.deepSkyBlue .panelWrapper .scriptPanel .action.traffic_row .active {
  border: 1px solid #64a9e0; }

.deepSkyBlue .panelWrapper .scriptPanel .action.traffic_row.odd {
  background-color: rgba(100, 169, 224, 0.1); }

.deepSkyBlue .panelWrapper .scriptPanel .action.traffic_row.even {
  background-color: #ffffff; }
  .deepSkyBlue .panelWrapper .scriptPanel .action.traffic_row.even .child_wrapper {
    background-color: rgba(100, 169, 224, 0.1); }

.deepSkyBlue .panelWrapper .scriptPanel .action:nth-child(odd) {
  background-color: rgba(100, 169, 224, 0.1); }

.deepSkyBlue .panelWrapper .scriptPanel .action:nth-child(even), .deepSkyBlue .panelWrapper .scriptPanel .action.even {
  background-color: #ffffff; }

.deepSkyBlue .panelWrapper .scriptPanel .action.archiveRow.active {
  border: 1px solid #64a9e0; }

.deepSkyBlue .optscriptPanel .action.traffic_row .active {
  border: 1px solid #64a9e0; }

.deepSkyBlue .optscriptPanel .action.traffic_row.odd {
  background-color: rgba(100, 169, 224, 0.1); }

.deepSkyBlue .optscriptPanel .action.traffic_row.even {
  background-color: #ffffff; }
  .deepSkyBlue .optscriptPanel .action.traffic_row.even .child_wrapper {
    background-color: rgba(100, 169, 224, 0.1); }

.deepSkyBlue .optscriptPanel .action:nth-child(odd) {
  background-color: rgba(100, 169, 224, 0.1); }

.deepSkyBlue .optscriptPanel .action:nth-child(even), .deepSkyBlue .optscriptPanel .action.even {
  background-color: #ffffff; }

.deepSkyBlue .optscriptPanel .action.archiveRow.active {
  border: 1px solid #64a9e0; }

.deepSkyBlue .traffic_wrapper .formBtns {
  background-color: rgba(100, 169, 224, 0.1); }

.deepSkyBlue .customDrpDwn .css-10nd86i:hover, .deepSkyBlue .customDrpDwn .css-10nd86i:active, .deepSkyBlue .customDrpDwn .css-10nd86i:focus {
  border-color: #64a9e0; }

.deepSkyBlue .customDrpDwn .css-19bqh2r {
  fill: #64a9e0;
  stroke: #64a9e0; }

.deepSkyBlue .customDrpDwn .css-15k3avv .css-z5z6cw, .deepSkyBlue .customDrpDwn .css-11unzgr .css-z5z6cw {
  background-color: #64a9e0; }
  .deepSkyBlue .customDrpDwn .css-15k3avv .css-z5z6cw:hover, .deepSkyBlue .customDrpDwn .css-15k3avv .css-z5z6cw:active, .deepSkyBlue .customDrpDwn .css-11unzgr .css-z5z6cw:hover, .deepSkyBlue .customDrpDwn .css-11unzgr .css-z5z6cw:active {
    background-color: #64a9e0; }

.deepSkyBlue .customDrpDwn .css-15k3avv .css-wqgs6e:hover, .deepSkyBlue .customDrpDwn .css-15k3avv .css-wqgs6e:active, .deepSkyBlue .customDrpDwn .css-11unzgr .css-wqgs6e:hover, .deepSkyBlue .customDrpDwn .css-11unzgr .css-wqgs6e:active {
  background-color: #64a9e0; }

.deepSkyBlue .customDrpDwn .css-1ep9fjw {
  color: #64a9e0; }

.deepSkyBlue .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage img, .deepSkyBlue .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage img {
  border: none; }

.deepSkyBlue .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage p, .deepSkyBlue .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage p {
  color: #64a9e0; }

.deepSkyBlue .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper img {
  border: none !important; }

.deepSkyBlue .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper p {
  color: #64a9e0; }

.deepSkyBlue .optDeptWrapper .operatorSection .operatorsWrapper .operatorNick p {
  color: #64a9e0; }

.deepSkyBlue .optDeptWrapper .operatorSection .operatorsWrapper .operatorDetails p.email {
  color: #64a9e0; }

.deepSkyBlue .optDeptWrapper.section_Dept .panelContent .editWrapper p {
  color: #64a9e0; }

.deepSkyBlue .archive_pagination .pagination {
  border: 0.5 solid #64a9e0;
  color: #64a9e0 !important; }
  .deepSkyBlue .archive_pagination .pagination a {
    color: #64a9e0 !important; }

.deepSkyBlue .archive_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #64a9e0 solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.deepSkyBlue .traffic_pagination .pagination {
  border: 0.5 solid #64a9e0;
  color: #64a9e0 !important; }
  .deepSkyBlue .traffic_pagination .pagination a {
    color: #64a9e0 !important; }

.deepSkyBlue .traffic_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #64a9e0 solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.deepSkyBlue .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapse_wrapper .iconshow {
  border: 1px solid #64a9e0;
  background-image: url("assets/images/minus_icons/minus_deepSkyBlue.png");
  background-repeat: no-repeat;
  background-position: center; }

.deepSkyBlue .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .with-image p a {
  color: #64a9e0; }

.deepSkyBlue .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapsed .collapse_wrapper p:first-child {
  background-image: url("assets/images/plus_icons/plus_skyblue.png");
  background-size: 8px; }

.deepSkyBlue .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapse_wrapper .collapsed {
  background-image: url("assets/images/plus_icons/plus_skyblue.png") !important;
  background-size: 8px; }

.deepSkyBlue .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count {
  background-color: #64a9e0; }
  .deepSkyBlue .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count:hover {
    border: 1px solid #64a9e0;
    color: #64a9e0; }

.deepSkyBlue .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p.domain span {
  color: #64a9e0; }

.deepSkyBlue .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .tumbnail_footer {
  background-color: rgba(100, 169, 224, 0.1); }

.deepSkyBlue .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper p {
  color: #64a9e0; }

.deepSkyBlue .chatInfoWrapper .nav.nav-tabs a.nav-item {
  background-color: #64a9e0;
  border-right: 1px solid #fff !important; }
  .deepSkyBlue .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
    color: #64a9e0 !important; }

.deepSkyBlue .chatInfoWrapper .tab-content p.hide_btn {
  color: #64a9e0 !important; }
  .deepSkyBlue .chatInfoWrapper .tab-content p.hide_btn svg {
    fill: #64a9e0 !important;
    stroke: #64a9e0 !important;
    margin-right: 9px;
    margin-top: -3px; }

.deepSkyBlue .chatInfoWrapper .tabContent .data .webInfo, .deepSkyBlue .chatInfoWrapper .tabContent .data .notes {
  background-color: #EFF6FC !important; }

.deepSkyBlue .customerChat .chatText {
  background-color: #64a9e0; }
  .deepSkyBlue .customerChat .chatText:before {
    content: "";
    position: absolute;
    top: -14px;
    right: 17px;
    z-index: 1;
    border: solid 7px transparent;
    border-bottom-color: #64a9e0; }

.deepSkyBlue .operatorChat .chatText {
  background-color: rgba(100, 169, 224, 0.1); }
  .deepSkyBlue .operatorChat .chatText:before {
    content: "";
    position: absolute;
    top: -14px;
    left: 17px;
    z-index: 1;
    border: solid 7px transparent;
    border-bottom-color: rgba(100, 169, 224, 0.1); }

.deepSkyBlue .historyWrapper .transcriptDetails {
  background-color: rgba(100, 169, 224, 0.1); }

.deepSkyBlue .websitesWrapper .websitesDetails .box .webLink {
  color: #64a9e0; }

.deepSkyBlue .websitesWrapper .websitesDetails .box .actionsWrapper {
  background-color: #64a9e0; }

.deepSkyBlue .websitesWrapper .websitesDetails .box .overview .row p {
  color: #64a9e0; }

.deepSkyBlue .websitesWrapper .websitesDetails .box .addWrapper img {
  border: 2px solid #64a9e0; }

.deepSkyBlue .websitesWrapper .websitesDetails .box .addWrapper p {
  color: #64a9e0; }

.deepSkyBlue .websitesWrapper .websitesDetails .box .addWrapper .websitePayDollar {
  border: 3px solid #64a9e0 !important; }

.deepSkyBlue .textEditorWrapper .sendImgWrappger {
  background-color: #64a9e0; }

.deepSkyBlue .innercontent_wrapper .directLinkWrapper .uploadBtn button {
  border: 1px solid #64a9e0 !important; }
  .deepSkyBlue .innercontent_wrapper .directLinkWrapper .uploadBtn button p {
    color: #64a9e0 !important; }

.deepSkyBlue .innercontent_wrapper .directLinkWrapper.toolBarWrapper .with_btn .uploadBtn button {
  border: 1px solid #64a9e0 !important;
  background-color: #fff; }
  .deepSkyBlue .innercontent_wrapper .directLinkWrapper.toolBarWrapper .with_btn .uploadBtn button p {
    color: #64a9e0 !important; }

@font-face {
  font-family: 'Helvetica Neue Cyr';
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/HelveticaNeueCyr-Light.otf"); }

@font-face {
  font-family: 'Helvetica Neue Cyr Medium';
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/HelveticaNeueCyr-Medium.otf"); }

@font-face {
  font-family: 'Helvetica Neue Cyr Roman';
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/HelveticaNeueCyr-Roman.otf"); }

@font-face {
  font-family: 'Segoe UI Semi Bold';
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/seguisb.ttf"); }

@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/segoeui.ttf"); }

@font-face {
  font-family: 'Segoe UI Bold';
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/Segoe UI Bold.ttf"); }

@font-face {
  font-family: 'FontAwesome';
  src: url("assets/fonts/fontAwesome/fontawesome-webfont.eot");
  src: url("assets/fonts/fontAwesome/fontawesome-webfont.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fontAwesome/fontawesome-webfont.woff") format("woff"), url("assets/fonts/fontAwesome/fontawesome-webfont.ttf") format("truetype"), url("assets/fonts/fontAwesome/fontawesome-webfont.svg#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal; }

/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active, a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */ }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

.chatResponseWrapper .title {
  margin: 9px;
  padding-left: 8px; }

.chatResponseWrapper .formBtns {
  margin-top: 15px; }

.chatResponseWrapper .editorWrapper {
  width: 99%; }

.chatResponseWrapper .box:first-child {
  margin-bottom: 30px; }

.chatResponseWrapper .response_wrapper label {
  text-align: right;
  padding-right: 0; }

.chatResponseWrapper .response_wrapper.settingFormWrapper .form-control, .chatResponseWrapper .response_wrapper .customDrpDwn .css-10nd86i {
  width: 100%;
  max-width: 256px; }

.chatResponseWrapper .rule_data p {
  color: #000000;
  font-family: "Segoe UI";
  font-size: 14px;
  font-weight: 400; }
  .chatResponseWrapper .rule_data p.ques {
    font-family: "Segoe UI Semi Bold"; }

.chatResponseWrapper .panelWrapper .scrollWrapper {
  height: calc(100% - 50px);
  max-height: calc(95vh - 50px); }

.chatResponseWrapper .reponseDrpDwn label {
  padding-top: 5px;
  padding-bottom: 9px; }

.chatResponseWrapper .reponseDrpDwn .form-group {
  margin-bottom: 14px; }

.chatResponseWrapper .reponseDrpDwn .customDrpDwn .css-10nd86i {
  width: 100%; }

.chatResponseWrapper .editorWrapper .customEditor {
  height: 94px; }

.archiveFormWrapper .website_drp label {
  padding-top: 9px; }

.innercontent_wrapper .chatWrapper h4 {
  margin-top: 10px; }
  .innercontent_wrapper .chatWrapper h4 span {
    color: #3ba8c5; }

.innercontent_wrapper .chatWrapper.with-header .box {
  margin-top: 20px;
  padding: 0 15px 30px 0; }
  .innercontent_wrapper .chatWrapper.with-header .box > div:first-child {
    padding: 0; }
  .innercontent_wrapper .chatWrapper.with-header .box .headerTitle p {
    text-align: left;
    margin-bottom: 0;
    font-size: 14px; }
  .innercontent_wrapper .chatWrapper.with-header .box .headerTitle .label p {
    font-family: "Segoe UI Semi Bold"; }
  .innercontent_wrapper .chatWrapper.with-header .box .contentWrapper {
    padding: 20px 5px 30px 11px; }
    .innercontent_wrapper .chatWrapper.with-header .box .contentWrapper .chatData {
      max-height: 80vh;
      width: 100%;
      float: left; }
      .innercontent_wrapper .chatWrapper.with-header .box .contentWrapper .chatData p {
        text-align: left; }

.innercontent_wrapper .chatWrapper .chatEnterMsg {
  border-radius: 3px;
  background-color: #f6f6f6;
  margin-top: 10px;
  padding: 15px 20px;
  display: inline-flex;
  position: relative; }
  .innercontent_wrapper .chatWrapper .chatEnterMsg p {
    margin: 0;
    font-size: 14px; }
    .innercontent_wrapper .chatWrapper .chatEnterMsg p span {
      color: #adadad;
      font-size: 12px;
      position: absolute;
      right: 15px; }

.innercontent_wrapper .chatWrapper .editorWrapper {
  margin: 10px 15px;
  float: left;
  width: 96%; }
  .innercontent_wrapper .chatWrapper .editorWrapper .editorToolBar {
    width: 100px;
    float: left;
    height: 100px;
    border: 1px solid #dedede;
    background-color: #ffffff;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none; }
    .innercontent_wrapper .chatWrapper .editorWrapper .editorToolBar .rdw-option-wrapper {
      min-width: 21px;
      padding: 0; }
    .innercontent_wrapper .chatWrapper .editorWrapper .editorToolBar .customProps .editorCoBrowse {
      position: absolute;
      top: 35px;
      left: 67px; }
    .innercontent_wrapper .chatWrapper .editorWrapper .editorToolBar .customProps .editorSpellCheck {
      position: absolute;
      left: 0;
      top: 66px; }
    .innercontent_wrapper .chatWrapper .editorWrapper .editorToolBar .customProps .editorPrint {
      position: absolute;
      left: 38px;
      top: 66px; }
    .innercontent_wrapper .chatWrapper .editorWrapper .editorToolBar .customProps .editorExitSession {
      margin-left: 66px; }
    .innercontent_wrapper .chatWrapper .editorWrapper .editorToolBar .rdw-inline-wrapper {
      margin-bottom: 0; }
    .innercontent_wrapper .chatWrapper .editorWrapper .editorToolBar .rdw-emoji-wrapper, .innercontent_wrapper .chatWrapper .editorWrapper .editorToolBar .rdw-image-wrapper {
      margin-bottom: 0; }
  .innercontent_wrapper .chatWrapper .editorWrapper .customEditor {
    border: 1px solid #dedede;
    background-color: #ffffff;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 100px; }
    .innercontent_wrapper .chatWrapper .editorWrapper .customEditor .DraftEditor-root {
      padding-right: 57px; }
      .innercontent_wrapper .chatWrapper .editorWrapper .customEditor .DraftEditor-root .public-DraftEditor-content::-webkit-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .innercontent_wrapper .chatWrapper .editorWrapper .customEditor .DraftEditor-root .public-DraftEditor-content::-ms-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .innercontent_wrapper .chatWrapper .editorWrapper .customEditor .DraftEditor-root .public-DraftEditor-content::placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }

.innercontent_wrapper .chatWrapper .chatSection {
  float: left; }
  .innercontent_wrapper .chatWrapper .chatSection .chatBy {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0; }
    .innercontent_wrapper .chatWrapper .chatSection .chatBy span {
      color: #adadad;
      font-family: "Segoe UI";
      font-size: 12px; }
  .innercontent_wrapper .chatWrapper .chatSection .chatText {
    width: 454px;
    border-radius: 3px;
    padding: 15px 20px 12px;
    margin-top: 12px;
    position: relative;
    max-width: 100%; }
    .innercontent_wrapper .chatWrapper .chatSection .chatText .dropDownWrapper .customDrpDwn > div:nth-child(1) {
      width: 175px; }
    .innercontent_wrapper .chatWrapper .chatSection .chatText .fileImg {
      width: 70px;
      border: 1px solid #69b9ce;
      padding: 5px 11px;
      margin: 0 0 4px 9px;
      float: left; }
    .innercontent_wrapper .chatWrapper .chatSection .chatText .download {
      margin-top: 20px;
      float: left; }
    .innercontent_wrapper .chatWrapper .chatSection .chatText .opfileImg {
      width: 70px;
      border: 1px solid #69b9ce;
      padding: 5px 11px;
      margin: 0 0 4px 9px; }
    .innercontent_wrapper .chatWrapper .chatSection .chatText p {
      margin: 0;
      font-size: 14px; }
    .innercontent_wrapper .chatWrapper .chatSection .chatText.chatFile {
      width: 384px; }
      .innercontent_wrapper .chatWrapper .chatSection .chatText.chatFile .fileImg {
        width: 70px;
        height: 75px;
        border: 1px solid #69b9ce;
        padding: 5px 11px;
        margin: 0 0 4px 9px;
        float: left; }
        .innercontent_wrapper .chatWrapper .chatSection .chatText.chatFile .fileImg img {
          width: 48px;
          height: 60px;
          object-fit: scale-down; }
      .innercontent_wrapper .chatWrapper .chatSection .chatText.chatFile .fileDet {
        margin-top: -6px;
        word-wrap: anywhere; }
        .innercontent_wrapper .chatWrapper .chatSection .chatText.chatFile .fileDet span {
          opacity: 1;
          font-size: 12px;
          margin: 0 7px 0 11px; }
      .innercontent_wrapper .chatWrapper .chatSection .chatText.chatFile .customDrpDwn {
        margin-top: 20px; }
        .innercontent_wrapper .chatWrapper .chatSection .chatText.chatFile .customDrpDwn > span {
          float: left;
          color: #ffffff;
          font-family: "Segoe UI Semi Bold";
          font-size: 14px;
          font-weight: 400;
          margin: 8px 12px 0 0; }
        .innercontent_wrapper .chatWrapper .chatSection .chatText.chatFile .customDrpDwn .css-10nd86i {
          width: 198px;
          float: left; }
  .innercontent_wrapper .chatWrapper .chatSection.customerChat .chatText p {
    color: #fff; }
  .innercontent_wrapper .chatWrapper .chatSection.supervisorChat .chatText {
    background-color: #f1ea4f;
    width: 100%; }
    .innercontent_wrapper .chatWrapper .chatSection.supervisorChat .chatText:before {
      content: "";
      position: absolute;
      top: -14px;
      left: 17px;
      z-index: 1;
      border: solid 7px transparent;
      border-bottom-color: #f1ea4f; }
  .innercontent_wrapper .chatWrapper .chatSection.supervisorChat .chatMsg {
    background-color: #f1ea4f;
    width: 100%;
    border-radius: 3px;
    padding: 15px 20px 12px;
    margin-top: 30px;
    position: relative; }
    .innercontent_wrapper .chatWrapper .chatSection.supervisorChat .chatMsg p {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      color: #000000; }

.chatInfoWrapper {
  background-color: #fff;
  box-shadow: 0 0 5px rgba(13, 16, 21, 0.19);
  border-radius: 10px; }
  .chatInfoWrapper .nav.nav-tabs {
    margin-top: 40px; }
    .chatInfoWrapper .nav.nav-tabs a.nav-item {
      background-repeat: no-repeat;
      background-position: center 10px;
      padding-top: 35px;
      font-family: "Segoe UI";
      font-size: 13px;
      font-weight: 400;
      color: #ffffff;
      background-color: #3ba8c5;
      width: 25%;
      border: none;
      border-radius: 0;
      border-right: 1px solid #69b9ce;
      outline: none;
      box-shadow: none; }
      .chatInfoWrapper .nav.nav-tabs a.nav-item:last-child.active {
        border-right: none; }
      .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1) {
        outline: none;
        border-top-left-radius: 10px;
        background-image: url("assets/images/infotab.png"); }
        .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1).active {
          background-image: url("assets/images/infotab_selected.png"); }
      .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2) {
        outline: none;
        background-image: url("assets/images/footstepstab.png"); }
        .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2).active {
          background-image: url("assets/images/footstepstab_selected.png"); }
      .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3) {
        outline: none;
        background-image: url("assets/images/historytab.png"); }
        .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3).active {
          background-image: url("assets/images/historytab_selected.png"); }
      .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4) {
        outline: none;
        border-top-right-radius: 10px;
        background-image: url("assets/images/responsetab.png"); }
        .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4).active {
          background-image: url("assets/images/responsetab_selected.png"); }
      .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
        color: #3ba8c5;
        background-color: #fff; }
  .chatInfoWrapper .tab-content {
    position: relative; }
    .chatInfoWrapper .tab-content p.hide_btn {
      color: #3ba8c5;
      font-family: "Segoe UI Bold";
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      position: absolute;
      width: 100%;
      margin: 0;
      top: -7px; }
      .chatInfoWrapper .tab-content p.hide_btn span {
        width: 70px;
        display: inline-block;
        background-color: #fff;
        cursor: pointer; }
        .chatInfoWrapper .tab-content p.hide_btn span img {
          margin-right: 8px;
          margin-top: -2px; }
  .chatInfoWrapper .tabContent {
    text-align: left;
    margin-top: 24px;
    padding-top: 35px;
    border-top: 1px solid #dedede;
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400; }
    .chatInfoWrapper .tabContent h6 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      text-align: left;
      margin: 0 20px 20px; }
    .chatInfoWrapper .tabContent .data p {
      margin-bottom: 10px;
      margin-top: 0; }
    .chatInfoWrapper .tabContent .data .label {
      padding-right: 0; }
      .chatInfoWrapper .tabContent .data .label p {
        margin-left: 6px;
        font-family: "Segoe UI Semi Bold";
        font-size: 14px; }
    .chatInfoWrapper .tabContent .data .value p {
      margin-right: 6px;
      font-family: "Segoe UI";
      font-size: 14px; }
    .chatInfoWrapper .tabContent .data .webInfo {
      padding: 25px 20px;
      background-color: #ebf6f9;
      margin-bottom: 20px;
      margin-top: 15px; }
      .chatInfoWrapper .tabContent .data .webInfo > p {
        font-family: "Segoe UI Semi Bold";
        margin-bottom: 20px;
        margin-top: 0;
        font-size: 14px; }
      .chatInfoWrapper .tabContent .data .webInfo .row:last-child p {
        margin-bottom: 0; }
      .chatInfoWrapper .tabContent .data .webInfo .label p {
        margin-left: 0; }
    .chatInfoWrapper .tabContent .data .notes {
      padding: 25px 20px;
      background-color: #ebf6f9;
      border-radius: 0 0 10px 10px;
      margin-top: 12px; }
      .chatInfoWrapper .tabContent .data .notes h6 {
        color: #293037;
        font-family: "Segoe UI Bold";
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        margin-left: 0;
        margin-right: 0; }
      .chatInfoWrapper .tabContent .data .notes button {
        background-color: transparent; }
        .chatInfoWrapper .tabContent .data .notes button:hover {
          color: inherit; }
      .chatInfoWrapper .tabContent .data .notes .notesData {
        margin-bottom: 27px; }
        .chatInfoWrapper .tabContent .data .notes .notesData p {
          width: 100%; }
    .chatInfoWrapper .tabContent .data .rowLayout .value p {
      margin: 0 6px; }
    .chatInfoWrapper .tabContent .data .rowLayout .link {
      color: #3ba8c5; }
      .chatInfoWrapper .tabContent .data .rowLayout .link img {
        margin-left: 8px; }
    .chatInfoWrapper .tabContent .data .rowLayout .disabled {
      color: #adadad; }
    .chatInfoWrapper .tabContent .data .withImage img {
      cursor: pointer;
      margin-left: 8px;
      margin-top: -3px; }
    .chatInfoWrapper .tabContent .footstepsWrapper {
      padding-bottom: 35px; }
      .chatInfoWrapper .tabContent .footstepsWrapper .link {
        color: #3ba8c5; }
        .chatInfoWrapper .tabContent .footstepsWrapper .link img {
          margin-left: 8px; }
    .chatInfoWrapper .tabContent .historyWrapper {
      padding-bottom: 40px; }
      .chatInfoWrapper .tabContent .historyWrapper > div > div.label, .chatInfoWrapper .tabContent .historyWrapper > div > div.value {
        cursor: pointer; }
      .chatInfoWrapper .tabContent .historyWrapper .label p {
        font-size: 12px; }
        .chatInfoWrapper .tabContent .historyWrapper .label p span {
          color: #adadad; }
      .chatInfoWrapper .tabContent .historyWrapper .value p {
        color: #3ba8c5;
        font-size: 14px; }
      .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails {
        padding-top: 25px;
        padding-bottom: 0;
        max-height: 60vh;
        margin-bottom: 20px; }
        .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails p {
          font-size: 14px; }
        .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .value p {
          color: #000000; }
        .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails > img {
          margin-top: -15px;
          margin-right: -9px;
          cursor: pointer; }
        .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript h6 {
          color: #293037;
          font-family: "Segoe UI Bold";
          font-size: 14px;
          font-weight: 700;
          text-transform: uppercase;
          margin: 20px 6px 15px 22px; }
        .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p {
          margin-left: 6px; }
          .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child {
            font-family: "Segoe UI Semi Bold";
            margin-bottom: 0; }
            .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child span {
              color: #adadad; }
        .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .rcs-custom-scroll .rcs-inner-container > div {
          padding: 0; }
    .chatInfoWrapper .tabContent.responseWrapper .panelWrapper {
      border-top-right-radius: 0;
      border-top-left-radius: 0; }
    .chatInfoWrapper .tabContent.responseWrapper .searchForm {
      padding: 0 20px; }
      .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input {
        padding-right: 27px;
        background-repeat: no-repeat;
        background-position: 97%;
        background-image: url("assets/images/search.png"); }
        .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-webkit-input-placeholder {
          color: #adadad;
          font-family: "Segoe UI";
          font-size: 14px;
          font-weight: 400; }
        .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-ms-input-placeholder {
          color: #adadad;
          font-family: "Segoe UI";
          font-size: 14px;
          font-weight: 400; }
        .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::placeholder {
          color: #adadad;
          font-family: "Segoe UI";
          font-size: 14px;
          font-weight: 400; }
      .chatInfoWrapper .tabContent.responseWrapper .searchForm p {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
        .chatInfoWrapper .tabContent.responseWrapper .searchForm p span {
          color: #010101;
          font-family: "Segoe UI Semi Bold";
          font-weight: 600; }
    .chatInfoWrapper .tabContent.responseWrapper .ruleComponent {
      padding: 15px 20px; }
      .chatInfoWrapper .tabContent.responseWrapper .ruleComponent > div:first-child {
        max-width: -webkit-max-content;
        max-width: -moz-max-content;
        max-width: max-content; }
      .chatInfoWrapper .tabContent.responseWrapper .ruleComponent .rule_data p.ques {
        font-family: "Segoe UI Semi Bold";
        font-weight: 600; }

.archiveRow .checkbox_custom {
  margin-bottom: 2px; }

.chatArchivePopup .modal-dialog {
  width: 570px;
  max-width: 570px; }
  .chatArchivePopup .modal-dialog .modal-content .chatInfoScrollWrapper .value p {
    font-family: "Segoe UI"; }
  .chatArchivePopup .modal-dialog .modal-content .chatInfoScrollWrapper .modalBodyTitle:nth-child(1) {
    margin-top: 5px;
    margin-bottom: 10px; }
  .chatArchivePopup .modal-dialog .modal-content .chatInfoScrollWrapper .infoTranscript .chatmsg p img {
    height: auto;
    max-width: 100%; }

.textEditorWrapper {
  position: relative;
  float: left;
  width: 100%; }
  .textEditorWrapper .sendImgWrappger {
    position: absolute;
    width: 38px;
    height: 38px;
    right: 35px;
    border-radius: 50%;
    top: 41px; }
    .textEditorWrapper .sendImgWrappger img {
      margin: 11px 8px 11px 12px; }
  .textEditorWrapper .sendImgWrappger:focus {
    outline: none; }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 940px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: table; }

.modal-video-inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%; }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -35px;
  right: -35px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

.header {
  margin-bottom: 2px;
  height: 61px;
  box-shadow: 0 0 5px rgba(13, 16, 21, 0.23);
  background-color: #ffffff;
  position: fixed;
  z-index: 1030; }
  .header .logo-img {
    padding-left: 8px;
    cursor: pointer; }
  .header img, .header p {
    float: left; }

.custome_company_name {
  margin: 8px;
  font-weight: 300;
  font-size: 13px;
  padding-left: 8px;
  cursor: pointer;
  max-width: 100%;
  height: auto; }

.expire_account {
  padding: 19px 0 6px 19px;
  font-family: 'Helvetica Neue Cyr';
  color: #8fbfcc;
  font-size: 13px;
  font-weight: 300;
  margin: 0; }

.divider {
  background-image: url("assets/images/divider.png");
  background-repeat: no-repeat;
  padding: 6px 0 6px 19px;
  font-family: 'Helvetica Neue Cyr';
  color: #8fbfcc;
  font-size: 13px;
  font-weight: 300;
  margin: 0; }

.bg_circle {
  border-radius: 50%;
  border-color: rgba(255, 255, 255, 0);
  border-width: 3px;
  border-style: solid;
  width: 44px;
  height: 44px;
  position: relative;
  z-index: 0; }

.green_circle {
  border-radius: 50%;
  border-color: #51af31;
  border-width: 3px;
  border-style: solid;
  width: 44px;
  height: 44px;
  position: absolute;
  z-index: 1; }

.red_circle {
  border-radius: 50%;
  border-color: #c94040;
  border-width: 3px;
  border-style: solid;
  width: 44px;
  height: 44px;
  position: absolute;
  z-index: 1; }

.notification {
  margin: 0 20px;
  position: relative;
  display: inline-block; }
  .notification .notification_ellipse {
    border: 2px solid white;
    border-radius: 50%;
    background-color: #c94040;
    position: absolute;
    top: 0px;
    right: -5px;
    width: 17px;
    height: 17px;
    z-index: 43; }
    .notification .notification_ellipse p {
      font-size: 10px;
      margin: 0;
      text-align: center;
      width: 100%;
      color: #ffffff;
      margin-top: -2px; }

.profileStatus {
  margin-right: 45px; }
  .profileStatus .float {
    float: none; }
  .profileStatus .avatar {
    margin-right: 13px; }
  .profileStatus .status {
    float: left;
    padding-right: -2px; }
    .profileStatus .status p {
      margin: 0;
      font-size: 15px;
      font-family: 'Helvetica Neue Cyr Roman';
      font-weight: 400;
      color: #242831; }
      .profileStatus .status p.statusDisplay {
        font-size: 13px;
        position: relative;
        font-family: 'Helvetica Neue Cyr';
        font-weight: 300; }
        .profileStatus .status p.statusDisplay.text-active {
          color: #67b84b; }
      .profileStatus .status p.dropdown-item {
        color: #000;
        font-family: 'Segoe UI';
        font-size: 13px;
        padding-left: 22px; }
    .profileStatus .status .status-img {
      position: absolute;
      right: -12px;
      bottom: 6px; }
    .profileStatus .status .dropdown-menu.show {
      width: 121px;
      min-width: 121px;
      height: 100px;
      box-shadow: 0 0 5px rgba(13, 16, 21, 0.19);
      border-radius: 3px;
      background-color: #ffffff;
      margin-left: -22px; }

.checkout {
  cursor: pointer;
  background-color: #3ba8c5;
  height: 100%;
  color: #ffffff;
  padding: 19px 23px 14px 29px;
  margin-left: 31px; }
  .checkout p {
    margin: 0;
    padding-left: 10px;
    font-family: 'Segoe UI Bold';
    font-size: 14px;
    font-weight: 700; }

.custom_btn {
  height: 29px;
  border-radius: 3px;
  border: 1px solid #3ba8c5;
  background-color: #ffffff;
  padding: 0px 18px;
  color: #3ba8c5;
  font-family: 'Segoe UI Bold';
  font-size: 13px; }
  .custom_btn.btn-primary:hover {
    background-color: #3ba8c5;
    border-color: #3ba8c5; }

.scheduleformBtns {
  padding-top: 6px;
  padding-right: 0; }

.invitationRow .leftSection .invitationimg {
  height: 148px;
  width: 115px; }

.responsetitle {
  overflow-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */ }

.previewsave .widget-preview .widget-preview-title {
  padding: 15px 0 0 18px;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  text-align: left; }

.widgetContent .widget_iconpreview {
  width: 280px;
  background-Size: 260px;
  background-Repeat: no-repeat;
  height: 80px !important; }

.previewsave .widget-preview .widget-preview-text {
  width: 146px;
  overflow: hidden;
  white-space: nowrap;
  padding: 1px 0 0;
  text-align: center;
  color: #a8a8a8;
  font-size: 10px;
  margin-left: -6px; }

.previewsave .widget-preview .widgeticon-preview-title {
  padding: 15px 0 0 18px;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  text-align: left; }

.previewsave .widget-preview .widgeticon-preview-text {
  width: 146px;
  overflow: hidden;
  white-space: nowrap;
  padding: 1px 0 0;
  text-align: center;
  color: #a8a8a8;
  font-size: 10px;
  margin-left: -6px; }

#icon_buttons ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
  height: 50px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }

#icon_buttons ul span {
  text-decoration: none;
  color: #fff;
  display: table-cell;
  font-weight: 700;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  padding-left: 5px !important;
  padding-bottom: 4px; }

#icon_buttons ul span img {
  width: 115px; }

#icon_buttons ul li div {
  display: inline-table;
  vertical-align: middle;
  height: 100%; }

#icon_buttons li {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  height: 100%;
  min-width: 30px;
  min-height: 30px;
  margin-bottom: 0; }

#icon_buttons ul li:first-child {
  border-left: 0; }

#icon_buttons ul li:last-child {
  border-right: 0; }

#div_buttons ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
  background: #0481b0;
  height: 50px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
  margin-bottom: 0px; }

#div_buttons ul span {
  text-decoration: none;
  color: #fff;
  display: table-cell;
  font-weight: 700;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 8px;
  padding-right: 15px;
  vertical-align: middle; }

#div_buttons ul li div {
  display: inline-table;
  vertical-align: middle;
  height: 100%; }

#div_buttons li {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  border-bottom: none;
  border-top: none;
  border-right: 1px solid;
  border-left: 1px solid;
  border-right-color: #1895c4;
  border-left-color: #44c1f0;
  height: 100%;
  min-width: 30px;
  min-height: 30px;
  margin-bottom: 0; }

#div_buttons ul li:first-child {
  border-left: 0; }

#div_buttons ul li:last-child {
  border-right: 0; }

#maindiv {
  width: 100%;
  overflow: auto;
  margin-top: 18px; }

.helptourstartbtn {
  background-color: #e89712;
  border: 0px none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  padding: 8px;
  -moz-appearance: none; }

.mailer .modal-dialog .getcodetextarea {
  width: 100%; }

.contentlogin {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px; }

.forgotnew {
  color: #3ba8c5;
  cursor: pointer; }

.forgotnew::hover {
  color: #000000; }

.pullLeft {
  float: left; }

.main_content {
  margin-left: 90px; }
  .main_content.menu_active {
    margin-left: 285px; }

.operatorPanel .main_content.menu_active {
  margin-left: 214px; }

.form-group {
  position: relative; }
  .form-group .form-control {
    border-radius: 5px;
    border: 1px solid #dedede; }
    .form-group .form-control.has-error {
      border-color: #c94040; }
  .form-group .error-block {
    float: left;
    font-size: 12px;
    position: absolute;
    left: 15px;
    color: #c94040; }

.form-control:focus, .btn-primary:focus, .btn-primary.focus {
  box-shadow: none; }

.custom_radionBtn {
  background-color: transparent;
  border: none;
  display: block;
  position: relative;
  padding-left: 35px;
  padding-right: 35px;
  margin-bottom: 12px;
  color: #000000;
  font-family: 'Segoe UI';
  font-size: 13px;
  font-weight: 400;
  line-height: 14px; }
  .custom_radionBtn input:checked ~ .customRadioOption {
    background-color: #aac3a1; }
    .custom_radionBtn input:checked ~ .customRadioOption:after {
      display: block; }
  .custom_radionBtn.btn-primary:hover, .custom_radionBtn.btn-primary:not(:disabled):not(.disabled):focus, .custom_radionBtn.btn-primary:not(:disabled):not(.disabled).active:focus, .custom_radionBtn.btn-primary:not(:disabled):not(.disabled):active, .custom_radionBtn.btn-primary:not(:disabled):not(.disabled).active {
    color: #000;
    color: initial;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    z-index: 0; }
  .custom_radionBtn .customRadioOption {
    position: absolute;
    top: 0;
    left: 0;
    height: 27px;
    width: 27px;
    background-color: #e7e7e7;
    border-radius: 50%;
    border: 1px solid #dedede; }
    .custom_radionBtn .customRadioOption:after {
      content: "";
      position: absolute;
      display: none;
      top: 5px;
      left: 5px;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      background-color: #ffffff;
      background-image: linear-gradient(-45deg, #e2e7e7 0%, #ffffff 100%); }

.editorWrapper {
  border-radius: 5px;
  background-color: #f6f6f6;
  border: 1px solid transparent; }
  .editorWrapper .editorToolBar {
    background-color: #f6f6f6;
    margin: 0;
    height: 34px;
    position: relative; }
    .editorWrapper .editorToolBar .editorInline > div {
      background-repeat: no-repeat;
      background-position: 50%;
      background-color: transparent;
      border: none; }
      .editorWrapper .editorToolBar .editorInline > div img {
        display: none; }
      .editorWrapper .editorToolBar .editorInline > div:first-child {
        background-image: url("assets/images/boldIcon.png"); }
      .editorWrapper .editorToolBar .editorInline > div:nth-child(2) {
        background-image: url("assets/images/italicIcon.png"); }
      .editorWrapper .editorToolBar .editorInline > div:nth-child(3) {
        background-image: url("assets/images/underlineIcon.png"); }
    .editorWrapper .editorToolBar .editorLink {
      background-repeat: no-repeat;
      background-position: 50%;
      background-color: transparent;
      border: none;
      background-image: url("assets/images/linkIcon.png"); }
      .editorWrapper .editorToolBar .editorLink img {
        display: none; }
    .editorWrapper .editorToolBar .textEditorLink {
      background-repeat: no-repeat;
      background-position: 50%;
      background-color: transparent;
      border: none;
      background-image: url("assets/images/linkIcon.png"); }
      .editorWrapper .editorToolBar .textEditorLink img {
        display: none; }
      .editorWrapper .editorToolBar .textEditorLink .rdw-option-wrapper {
        background: none !important; }
      .editorWrapper .editorToolBar .textEditorLink .rdw-link-modal {
        height: auto; }
    .editorWrapper .editorToolBar .rdw-emoji-wrapper {
      margin-left: 0px; }
    .editorWrapper .editorToolBar .editorEmoji {
      background-repeat: no-repeat;
      background-position: 50%;
      background-color: transparent;
      border: none;
      background-image: url("assets/images/emojiIcon.png"); }
      .editorWrapper .editorToolBar .editorEmoji img {
        display: none; }
    .editorWrapper .editorToolBar .editorSpellCheck {
      position: absolute;
      left: 168px;
      top: 2px; }
      .editorWrapper .editorToolBar .editorSpellCheck img {
        padding: 5px;
        min-width: 25px;
        border-radius: 2px;
        margin: 0 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer; }
  .editorWrapper .customEditor {
    height: 84px;
    border-radius: 5px;
    border: 1px solid #dedede;
    background-color: #ffffff;
    overflow-y: auto; }
    .editorWrapper .customEditor .DraftEditor-root {
      padding: 12px; }
      .editorWrapper .customEditor .DraftEditor-root .public-DraftStyleDefault-block {
        margin: 0; }
        .editorWrapper .customEditor .DraftEditor-root .public-DraftStyleDefault-block span {
          color: #000000;
          font-family: 'Segoe UI';
          font-size: 14px;
          line-height: 20px; }

.innercontent_wrapper .with-header .box .header-no-border .headerTitle {
  border-bottom: none; }

.innercontent_wrapper .with-header .box .headerTitle {
  padding: 24px 26px;
  border-bottom: 4px solid #3ba8c5; }

.innercontent_wrapper .with-header .box .contentWrapper {
  padding: 34px 26px; }
  .innercontent_wrapper .with-header .box .contentWrapper .sub-header {
    color: #293037;
    font-family: "Segoe UI Bold";
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 20px; }

.custom_textArea textarea {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #dedede;
  background-color: #ffffff;
  padding: 9px 15px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.customDrpDwn .css-10nd86i {
  width: 256px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #dedede;
  border-radius: 4px; }
  .customDrpDwn .css-10nd86i:hover, .customDrpDwn .css-10nd86i:active, .customDrpDwn .css-10nd86i:focus {
    border-color: #4CADC6; }
  .customDrpDwn .css-10nd86i .css-vj8t7z, .customDrpDwn .css-10nd86i .css-2o5izw {
    box-shadow: none;
    border: none; }

.customDrpDwn .css-19bqh2r {
  fill: #3ba8c5;
  stroke: #3ba8c5; }

.customDrpDwn .css-11unzgr {
  max-height: 172px; }

.customDrpDwn .css-15k3avv {
  margin-top: 5px;
  box-shadow: 0 2px 11px #d9d9d9; }
  .customDrpDwn .css-15k3avv .css-11unzgr {
    border: none;
    max-height: 172px; }
    .customDrpDwn .css-15k3avv .css-11unzgr svg {
      display: none; }
  .customDrpDwn .css-15k3avv .css-z5z6cw {
    border: none;
    color: #fff;
    cursor: pointer;
    padding: 5px 12px; }
    .customDrpDwn .css-15k3avv .css-z5z6cw:hover {
      background-color: #41A8C3;
      color: #fff; }
  .customDrpDwn .css-15k3avv .css-wqgs6e {
    background-color: transparent;
    cursor: pointer; }
    .customDrpDwn .css-15k3avv .css-wqgs6e:hover {
      background-color: #41A8C3;
      color: #fff; }

.customDrpDwn .css-1ep9fjw {
  color: #3ba8c5; }

.logoIcon.customDrpDwn .css-10nd86i {
  width: 100%;
  border: none; }
  .logoIcon.customDrpDwn .css-10nd86i:hover, .logoIcon.customDrpDwn .css-10nd86i:active, .logoIcon.customDrpDwn .css-10nd86i:focus {
    border-color: transparent; }
  .logoIcon.customDrpDwn .css-10nd86i .css-vj8t7z, .logoIcon.customDrpDwn .css-10nd86i .css-2o5izw {
    background-color: transparent; }
  .logoIcon.customDrpDwn .css-10nd86i .css-1hwfws3 {
    height: 69px; }
  .logoIcon.customDrpDwn .css-10nd86i .css-xp4uvy {
    width: 100%;
    text-align: center; }
  .logoIcon.customDrpDwn .css-10nd86i .css-1wy0on6 span {
    display: none; }

.logoIcon.customDrpDwn .css-15k3avv {
  margin-top: 5px;
  max-height: 185px;
  overflow-y: auto;
  box-shadow: 0 2px 11px #363a45;
  background-color: #363a45; }
  .logoIcon.customDrpDwn .css-15k3avv > div {
    margin-left: -17px; }
    .logoIcon.customDrpDwn .css-15k3avv > div > div {
      border-bottom: 1px solid #66696f;
      width: 100%;
      text-align: center; }

.logoIcon.customDrpDwn .css-1ep9fjw {
  color: #fff; }

.logoIcon.customDrpDwn .css-19bqh2r {
  fill: #fff;
  stroke: #fff; }

.innercontent_wrapper .box .searchText input {
  padding-right: 27px;
  background-repeat: no-repeat;
  background-position: 97%;
  background-image: url("assets/images/search.png"); }

label.checkbox_custom {
  cursor: pointer;
  height: 16px;
  border-radius: 4px;
  border: 1px solid #3ba8c5;
  box-shadow: none;
  width: 16px;
  background-color: transparent;
  padding: 0;
  position: relative; }
  label.checkbox_custom.btn-primary:not(:disabled):not(.disabled):active, label.checkbox_custom.btn-primary:not(:disabled):not(.disabled).active, .show > label.checkbox_custom.btn-primary.dropdown-toggle, label.checkbox_custom.btn-primary:hover {
    background-color: #3ba8c5;
    border-color: #3ba8c5; }
  label.checkbox_custom.active {
    background-color: #3ba8c5; }
    label.checkbox_custom.active img {
      position: absolute;
      left: 3px;
      top: 4px; }
  label.checkbox_custom input {
    opacity: 0; }

.modal-dialog .modal-content {
  margin: 0 auto;
  box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
  border-radius: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 4px 0 40px; }
  .modal-dialog .modal-content .modal-header {
    border-bottom: 4px solid #3ba8c5;
    padding-left: 30px;
    padding-right: 15px; }
    .modal-dialog .modal-content .modal-header .modal-title {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 18px;
      font-weight: 400;
      text-transform: capitalize; }
    .modal-dialog .modal-content .modal-header .close {
      padding: 0 15px;
      color: #3ba8c5;
      opacity: 1; }
  .modal-dialog .modal-content .modal-body {
    padding-left: 30px;
    padding-right: 30px; }
    .modal-dialog .modal-content .modal-body p {
      color: #000000;
      font-family: "Segoe UI Semi Bold";
      font-size: 14px;
      font-weight: 400; }
  .modal-dialog .modal-content .modal-footer {
    border-top: 0;
    padding: 0 1rem; }
    .modal-dialog .modal-content .modal-footer button {
      padding: 0 30px;
      line-height: 24px;
      height: 38px;
      border-radius: 3px;
      font-family: "Segoe UI Bold";
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;
      vertical-align: middle; }
      .modal-dialog .modal-content .modal-footer button.btn_cancel {
        border: 1px solid #3ba8c5;
        background-color: #ffffff;
        color: #3ba8c5; }
      .modal-dialog .modal-content .modal-footer button.btn_save {
        border: none;
        background-color: #3ba8c5; }

.wrapper .rcs-custom-scroll {
  max-height: inherit; }
  .wrapper .rcs-custom-scroll .rcs-outer-container {
    max-height: inherit; }
    .wrapper .rcs-custom-scroll .rcs-outer-container .rcs-inner-container {
      max-height: inherit; }

.wrapper .rcs-inner-handle {
  border-radius: 4px;
  background-color: #3ba8c5;
  width: 7px;
  border: 1px solid #3ba8c5; }

.wrapper .rcs-custom-scroll .rcs-inner-container {
  overflow-y: auto; }
  .wrapper .rcs-custom-scroll .rcs-inner-container > div {
    padding: 0 15px; }

.scroll_row {
  overflow-x: auto;
  box-shadow: 0 0 5px rgba(13, 16, 21, 0.19);
  border-radius: 10px; }
  .scroll_row .panelWrapper {
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content; }

.wrapper .operators_Wrapper .rcs-custom-scroll .rcs-inner-container > div {
  padding: 0; }

.operators_Wrapper {
  padding: 27px 0;
  width: 100%;
  height: calc(100vh - 61px); }
  .operators_Wrapper h6 {
    padding: 0 17px;
    color: #f7f5f5;
    font-family: "Segoe UI Bold";
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 18px;
    text-align: left;
    line-height: 20px; }
    .operators_Wrapper h6 span {
      color: #67b84b; }
  .operators_Wrapper .rcs-custom-scroll {
    padding-top: 15px; }
  .operators_Wrapper .operator_details {
    padding: 15px;
    cursor: pointer; }
    .operators_Wrapper .operator_details.quedOperator {
      position: relative; }
      .operators_Wrapper .operator_details.quedOperator p {
        margin-top: 28px; }
      .operators_Wrapper .operator_details.quedOperator .admin {
        height: 44px; }
    .operators_Wrapper .operator_details.active {
      background-color: #363a45; }
    .operators_Wrapper .operator_details p {
      margin: 13px 0 0 0;
      color: #ffffff;
      font-family: "Segoe UI Semi Bold";
      font-size: 14px;
      font-weight: 400; }
    .operators_Wrapper .operator_details .role img {
      border-radius: 50%; }
    .operators_Wrapper .operator_details .role.operator img {
      border: 2px solid #3ba8c5; }
    .operators_Wrapper .operator_details .role.admin img {
      border: 2px solid #51af31; }
      .operators_Wrapper .operator_details .role.admin img.quedImg {
        zoom: 1.6;
        position: absolute;
        top: 0;
        left: 15px; }
  .operators_Wrapper .optionwrapper {
    position: absolute; }
  .operators_Wrapper .operatorsOptions {
    width: 176px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 0 10px 10px 0;
    background-color: #363a45;
    position: absolute;
    padding: 24px 45px 30px 20px;
    left: 124px;
    z-index: 9999;
    text-align: left;
    top: 0; }
    .operators_Wrapper .operatorsOptions p {
      color: #6b838d;
      font-family: "Segoe UI";
      font-size: 13px;
      font-weight: 400;
      line-height: 25px;
      margin: 0; }
      .operators_Wrapper .operatorsOptions p img {
        margin-right: 11px; }
      .operators_Wrapper .operatorsOptions p.active {
        color: #a8d3de; }
  .operators_Wrapper.currentChats {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 250px;
    border-bottom: 1px solid #66696f; }
    .operators_Wrapper.currentChats h6 {
      margin-bottom: 26px; }
    .operators_Wrapper.currentChats .currentChatsWrapper {
      max-height: 150px;
      overflow-y: auto; }
    .operators_Wrapper.currentChats .operator_details {
      padding: 3px 11px 5px 17px;
      height: 30px;
      margin-top: 0; }
      .operators_Wrapper.currentChats .operator_details:hover {
        background-color: #66696f; }
      .operators_Wrapper.currentChats .operator_details.active {
        background-color: #0b91b5; }
    .operators_Wrapper.currentChats p {
      text-align: left;
      margin-top: 0; }
      .operators_Wrapper.currentChats p span {
        width: 7px;
        height: 7px;
        background-color: #c94040;
        vertical-align: middle;
        margin-top: 8px;
        border-radius: 50%; }

#sidebar.operator_menu {
  min-width: 229px;
  max-width: 229px;
  transition: all 0.3s; }
  #sidebar.operator_menu .submenu_wrapper {
    min-width: 124px;
    max-width: 124px; }
  #sidebar.operator_menu .hideIcon {
    width: 124px;
    display: none; }
  #sidebar.operator_menu .subMenu li.parent_list {
    padding: 21px 12px 20px 17px; }
    #sidebar.operator_menu .subMenu li.parent_list > a > p {
      margin: 0; }
    #sidebar.operator_menu .subMenu li.parent_list.active {
      background-color: #0b91b5; }
    #sidebar.operator_menu .subMenu li.parent_list .operators_Wrapper {
      height: 100%; }

.opacity-none {
  opacity: 0 !important; }

.custom_radioBtn {
  position: relative; }
  .custom_radioBtn p {
    color: #000000 !important;
    font-family: "Segoe UI" !important;
    font-size: 11px !important;
    font-weight: 700 !important; }
  .custom_radioBtn .active p {
    color: #fff !important; }
  .custom_radioBtn.disabled .btn {
    cursor: default; }

.custom_removeBtn {
  margin-left: 50%; }

.ruleComponent.chat .custom_radioBtn p {
  top: 3px; }

.ruleComponent.chat .fieldSection p {
  color: #242831;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }

.DraftEditor-editorContainer {
  height: 95%; }

span.online_text {
  color: #67b84b; }

span.mandatory {
  color: #e44e4e;
  margin-left: 3px; }

.settingFormWrapper .form-label.field-error {
  color: #e44e4e; }

.websitesWrapper {
  padding: 30px 15px 30px 30px; }
  .websitesWrapper .websitesDetails {
    margin-bottom: 19px; }
    .websitesWrapper .websitesDetails p {
      padding: 0 13px; }
    .websitesWrapper .websitesDetails .box {
      padding: 0;
      float: left; }
      .websitesWrapper .websitesDetails .box .addWrapper {
        padding: 97px 0 99px;
        cursor: pointer; }
        .websitesWrapper .websitesDetails .box .addWrapper img {
          border: 2px solid #3ba8c5;
          border-radius: 50%;
          padding: 21px; }
        .websitesWrapper .websitesDetails .box .addWrapper p {
          color: #3ba8c5;
          font-family: "Segoe UI Bold";
          font-size: 14px;
          font-weight: 700;
          text-transform: uppercase;
          margin: 13px 0 0 0; }
      .websitesWrapper .websitesDetails .box h4 {
        color: #000000;
        font-family: "Helvetica Neue Cyr Roman";
        font-size: 24px;
        font-weight: 400;
        width: 100%;
        float: left;
        padding: 0 13px;
        margin-bottom: 0; }
      .websitesWrapper .websitesDetails .box .webLink {
        color: #3ba8c5;
        font-family: "Segoe UI";
        font-size: 15px;
        font-weight: 400;
        width: 100%;
        float: left; }
      .websitesWrapper .websitesDetails .box .actionsWrapper {
        width: 100%;
        float: left;
        background-color: #3ba8c5;
        padding: 9px 13px 10px;
        margin-top: 10px; }
        .websitesWrapper .websitesDetails .box .actionsWrapper p {
          margin: 0;
          padding: 4px 0 3px;
          color: #ffffff;
          font-family: "Helvetica Neue Cyr";
          font-size: 14px;
          font-weight: 500;
          text-transform: uppercase; }
          .websitesWrapper .websitesDetails .box .actionsWrapper p span {
            cursor: pointer; }
          .websitesWrapper .websitesDetails .box .actionsWrapper p:first-child {
            border-right: 1px solid rgba(255, 255, 255, 0.3);
            text-align: right;
            padding-right: 22px; }
          .websitesWrapper .websitesDetails .box .actionsWrapper p:last-child {
            text-align: left;
            padding-left: 22px; }
            .websitesWrapper .websitesDetails .box .actionsWrapper p:last-child img {
              margin-right: 8px; }
          .websitesWrapper .websitesDetails .box .actionsWrapper p img {
            margin-right: 6px;
            margin-top: -3px; }
      .websitesWrapper .websitesDetails .box .overview {
        width: 100%;
        float: left;
        text-transform: uppercase;
        margin-bottom: 14px; }
        .websitesWrapper .websitesDetails .box .overview .overviewHeader {
          color: #000000;
          font-size: 18px;
          font-weight: 300;
          margin-top: 20px;
          font-family: "Helvetica Neue Cyr"; }
        .websitesWrapper .websitesDetails .box .overview .row p {
          font-size: 30px;
          border-right: 1px solid #DFDFDF;
          padding-top: 11px;
          padding-bottom: 12px;
          font-family: "Helvetica Neue Cyr";
          font-weight: 700; }
          .websitesWrapper .websitesDetails .box .overview .row p span {
            color: #363a45;
            font-size: 9px; }
          .websitesWrapper .websitesDetails .box .overview .row p:last-child {
            border-right: 0; }
    .websitesWrapper .websitesDetails .activeMode {
      height: 17px;
      margin: 0;
      margin-top: 12px;
      width: 100%;
      float: left; }
      .websitesWrapper .websitesDetails .activeMode span {
        width: 17px;
        height: 17px;
        float: right;
        border-radius: 50%; }
        .websitesWrapper .websitesDetails .activeMode span.installed_icon {
          background-color: #51af31; }
        .websitesWrapper .websitesDetails .activeMode span.uninstalled_icon {
          background-color: #c94040; }

@media print {
  p p {
    margin: 0px 15px; }
  div div div p {
    margin-left: 30px;
    padding: 0px 15px; }
  p > div {
    margin-left: 30px;
    padding: 0px 15px; }
  .infoTranscript .time {
    width: 10%; }
  .infoTranscript .name {
    width: 22%; }
  .infoTranscript .msg {
    width: 65%; }
  .infoTranscript .row .chat_p {
    font-family: "Segoe UI" !important;
    font-weight: 400; }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
    float: left; }
  .col-12 {
    width: 100%; }
  .col-11 {
    width: 91.66666667%; }
  .col-10 {
    width: 83.33333333%; }
  .col-9 {
    width: 75%; }
  .col-8 {
    width: 66.66666667%; }
  .col-7 {
    width: 58.33333333%; }
  .col-6 {
    width: 50%; }
  .col-5 {
    width: 41.66666667%; }
  .col-4 {
    width: 33.33333333%; }
  .col-3 {
    width: 25%; }
  .col-2 {
    width: 16.66666667%; }
  .col-1 {
    width: 8.33333333%; }
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px; } }

.overview h5 {
  color: #293037;
  font-family: 'Segoe UI Bold';
  font-size: 13px;
  font-weight: 700;
  line-height: 30.4px; }

.overview ol {
  list-style: none;
  counter-reset: li;
  -webkit-padding-start: 22px;
          padding-inline-start: 22px;
  margin-top: 22px; }
  .overview ol li {
    counter-increment: li;
    margin-bottom: 10px; }
    .overview ol li::before {
      content: counter(li);
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid #3ba8c5;
      color: #3ba8c5;
      font-family: 'Segoe UI Bold';
      font-size: 13px;
      float: left;
      padding-left: 7px; }
    .overview ol li p {
      margin-left: 34px;
      margin-bottom: 0; }
      .overview ol li p a:not([href]):not([tabindex]) {
        color: #3ba8c5; }

.overview ul {
  list-style-image: url("assets/images/list_icon.png"); }
  .overview ul li {
    padding-left: 8px; }

.overview p, .overview li {
  color: #000000;
  font-family: 'Segoe UI';
  font-size: 14px;
  line-height: 20px; }

.overview .section {
  margin-bottom: 35px; }
  .overview .section:last-child {
    margin-bottom: 0; }

.scriptWrapper .panelWrapper .scrollWrapper .scrollArea {
  max-height: 43vh;
  margin-top: -63px;
  z-index: 1; }
  .scriptWrapper .panelWrapper .scrollWrapper .scrollArea .rcs-custom-scroll .rcs-inner-container {
    margin-top: 63px; }

.scriptWrapper.innercontent_wrapper .box {
  padding: 26px; }
  .scriptWrapper.innercontent_wrapper .box.editScriptSection {
    padding: 0; }
    .scriptWrapper.innercontent_wrapper .box.editScriptSection .headerTitle {
      padding: 15px 26px 8px;
      border-bottom: 4px solid #3ba8c5; }
      .scriptWrapper.innercontent_wrapper .box.editScriptSection .headerTitle h4 {
        color: #000000;
        font-family: 'Helvetica Neue Cyr Roman';
        font-size: 22px;
        margin: 0; }
    .scriptWrapper.innercontent_wrapper .box.editScriptSection .contentWrapper {
      padding: 34px 26px; }
      .scriptWrapper.innercontent_wrapper .box.editScriptSection .contentWrapper .customDrpDwn .css-10nd86i {
        width: 100%; }
      .scriptWrapper.innercontent_wrapper .box.editScriptSection .contentWrapper .form-control {
        width: 100%; }
    .scriptWrapper.innercontent_wrapper .box.editScriptSection .formBtns {
      padding-top: 15px;
      margin-top: 5px; }

.vascriptWrapper .panelWrapper .scrollWrapper .scrollArea {
  max-height: 43vh;
  z-index: 1; }
  .vascriptWrapper .panelWrapper .scrollWrapper .scrollArea .rcs-custom-scroll .rcs-inner-container {
    margin-top: 0px; }

.vascriptWrapper.innercontent_wrapper .box {
  padding: 26px; }
  .vascriptWrapper.innercontent_wrapper .box.editScriptSection {
    padding: 0; }
    .vascriptWrapper.innercontent_wrapper .box.editScriptSection .headerTitle {
      padding: 15px 26px 8px;
      border-bottom: 4px solid #3ba8c5; }
      .vascriptWrapper.innercontent_wrapper .box.editScriptSection .headerTitle h4 {
        color: #000000;
        font-family: 'Helvetica Neue Cyr Roman';
        font-size: 22px;
        margin: 0; }
    .vascriptWrapper.innercontent_wrapper .box.editScriptSection .contentWrapper {
      padding: 34px 26px; }
      .vascriptWrapper.innercontent_wrapper .box.editScriptSection .contentWrapper .customDrpDwn .css-10nd86i {
        width: 100%; }
      .vascriptWrapper.innercontent_wrapper .box.editScriptSection .contentWrapper .form-control {
        width: 100%; }
    .vascriptWrapper.innercontent_wrapper .box.editScriptSection .formBtns {
      padding-top: 15px;
      margin-top: 5px; }

.settingFormWrapper.editScriptSection {
  margin-top: 26px; }
  .settingFormWrapper.editScriptSection .custom_radioBtn {
    float: left; }
    .settingFormWrapper.editScriptSection .custom_radioBtn > p {
      float: right;
      margin: 8px 26px 0 13px;
      color: #000000;
      font-family: 'Segoe UI Semi Bold';
      font-size: 14px; }

.preview {
  padding: 0 3px 0 32px; }
  .preview .chatPreview {
    max-width: 306px;
    height: 370px;
    box-shadow: 0 0 38px 2px rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
    border-radius: 5px; }
    .preview .chatPreview .headerTitle {
      background-color: #e2e2e2;
      padding: 9px 20px;
      border-radius: 5px 5px 0 0; }
      .preview .chatPreview .headerTitle.active {
        background-color: #3ba8c5; }
      .preview .chatPreview .headerTitle h6 {
        color: #ffffff;
        font-family: 'Segoe UI Bold';
        font-size: 13px;
        font-weight: 700;
        text-align: left;
        margin-bottom: 0; }
    .preview .chatPreview .chatBox {
      padding: 0 0 20px 20px;
      height: 305px; }
      .preview .chatPreview .chatBox .msgArea {
        height: 76%; }
        .preview .chatPreview .chatBox .msgArea .rcs-custom-scroll .rcs-inner-container > div {
          padding-top: 20px !important; }
        .preview .chatPreview .chatBox .msgArea p {
          margin: 0 0 7px 0; }
        .preview .chatPreview .chatBox .msgArea p, .preview .chatPreview .chatBox .msgArea a {
          color: #242831;
          font-family: 'Segoe UI';
          font-size: 13px;
          font-weight: 400; }
          .preview .chatPreview .chatBox .msgArea p.reply, .preview .chatPreview .chatBox .msgArea a.reply {
            color: #3ba8c5; }
      .preview .chatPreview .chatBox .chatArea .form-group {
        margin: 0; }
      .preview .chatPreview .chatBox .chatArea input {
        margin: 10px 0;
        height: 37px;
        border-radius: 5px;
        border: 1px solid #dedede;
        background-color: #ffffff;
        padding: 12px;
        font-family: 'Segoe UI';
        font-size: 14px;
        font-weight: 400;
        width: 98%; }
      .preview .chatPreview .chatBox .chatareasend {
        pointer-events: none;
        opacity: 0.5; }
      .preview .chatPreview .chatBox .formBtns {
        margin: 0;
        padding: 0;
        border: none; }
        .preview .chatPreview .chatBox .formBtns .btn_send {
          margin-left: 0; }
    .preview .chatPreview .btn_send {
      min-width: 89px;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      height: 38px;
      border-radius: 3px;
      border-color: #3ba8c5;
      background-color: #3ba8c5;
      color: #ffffff;
      font-family: 'Segoe UI Bold';
      font-size: 13px;
      font-weight: 700; }
      .preview .chatPreview .btn_send:hover {
        background-color: #ffffff;
        color: #3ba8c5; }

.panelWrapper {
  box-shadow: 0 0 5px rgba(13, 16, 21, 0.19);
  border-radius: 10px;
  background-color: #ffffff;
  max-height: 95vh;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow: hidden;
  padding-bottom: 15px; }
  .panelWrapper .scrollWrapper {
    height: calc(100% - 105px);
    max-height: calc(95vh - 105px); }
  .panelWrapper .addBtn {
    margin: 10px 0 15px 15px; }
  .panelWrapper .panelHeader {
    background-color: #3ba8c5;
    border-radius: 10px 10px 0 0;
    padding-right: 15px; }
    .panelWrapper .panelHeader p {
      color: #ffffff;
      font-family: 'Helvetica Neue Cyr Roman';
      font-size: 14px; }
  .panelWrapper .scriptPanel > div.panelHeading {
    color: #3ba8c5;
    font-family: 'Segoe UI Bold';
    font-size: 14px;
    padding: 18px;
    z-index: 2; }
    .panelWrapper .scriptPanel > div.panelHeading p {
      float: left;
      margin: 0;
      color: #3ba8c5;
      font-family: 'Segoe UI Bold';
      font-size: 14px;
      line-height: 27px; }
    .panelWrapper .scriptPanel > div.panelHeading.dropdown-toggle::after {
      display: none; }
    .panelWrapper .scriptPanel > div.panelHeading p:first-child {
      width: 27px;
      height: 27px;
      border-radius: 50%;
      border: 1px solid #3ba8c5;
      background-color: #3ba8c5;
      background-image: url("assets/images/expand.png");
      background-repeat: no-repeat;
      background-position: center;
      padding: 0; }
    .panelWrapper .scriptPanel > div.panelHeading.collapsed p {
      color: #293037; }
    .panelWrapper .scriptPanel > div.panelHeading.collapsed p:first-child {
      border: 1px solid #dedede;
      background-color: #ffffff; }
  .panelWrapper .scriptPanel .action {
    padding: 15px 15px 15px 0; }
    .panelWrapper .scriptPanel .action:nth-child(odd) {
      background-color: rgba(59, 168, 197, 0.1); }
    .panelWrapper .scriptPanel .action:nth-child(even), .panelWrapper .scriptPanel .action.even {
      background-color: #ffffff; }
    .panelWrapper .scriptPanel .action .count {
      color: #000000;
      font-family: 'Segoe UI';
      font-size: 14px; }
      .panelWrapper .scriptPanel .action .count p {
        margin: 0; }
      .panelWrapper .scriptPanel .action .count.editing, .panelWrapper .scriptPanel .action .count.disabled {
        opacity: 0.2; }
    .panelWrapper .scriptPanel .action .data p, .panelWrapper .scriptPanel .action.data p {
      color: #242831;
      font-size: 14px;
      margin: 0; }
    .panelWrapper .scriptPanel .action .data .quest, .panelWrapper .scriptPanel .action.data .quest {
      font-family: 'Segoe UI Semi Bold'; }
    .panelWrapper .scriptPanel .action .data .expAns, .panelWrapper .scriptPanel .action.data .expAns {
      font-family: 'Segoe UI'; }
    .panelWrapper .scriptPanel .action .data .editing, .panelWrapper .scriptPanel .action .data .disabled, .panelWrapper .scriptPanel .action.data .editing, .panelWrapper .scriptPanel .action.data .disabled {
      opacity: 0.2; }
    .panelWrapper .scriptPanel .action .data .optionArea .editWrapper, .panelWrapper .scriptPanel .action.data .optionArea .editWrapper {
      float: right; }
      .panelWrapper .scriptPanel .action .data .optionArea .editWrapper > div, .panelWrapper .scriptPanel .action.data .optionArea .editWrapper > div {
        float: left;
        margin-right: 20px; }
        .panelWrapper .scriptPanel .action .data .optionArea .editWrapper > div p, .panelWrapper .scriptPanel .action.data .optionArea .editWrapper > div p {
          color: #3ba8c5;
          font-family: 'Helvetica Neue Cyr Medium';
          font-size: 14px;
          letter-spacing: 0.28px; }
          .panelWrapper .scriptPanel .action .data .optionArea .editWrapper > div p img, .panelWrapper .scriptPanel .action.data .optionArea .editWrapper > div p img {
            margin-right: 9px; }
        .panelWrapper .scriptPanel .action .data .optionArea .editWrapper > div.custom_radioBtn label, .panelWrapper .scriptPanel .action.data .optionArea .editWrapper > div.custom_radioBtn label {
          margin-bottom: 0; }
        .panelWrapper .scriptPanel .action .data .optionArea .editWrapper > div.custom_radioBtn p.btnText, .panelWrapper .scriptPanel .action.data .optionArea .editWrapper > div.custom_radioBtn p.btnText {
          margin: 15px 0;
          font-family: 'Segoe UI Semi Bold';
          font-size: 11px; }
          .panelWrapper .scriptPanel .action .data .optionArea .editWrapper > div.custom_radioBtn p.btnText.active, .panelWrapper .scriptPanel .action.data .optionArea .editWrapper > div.custom_radioBtn p.btnText.active {
            color: #ffffff; }

.ruleComponent {
  padding: 13px 27px;
  pointer-events: visible !important;
  cursor: pointer; }
  .ruleComponent:active {
    cursor: url("https://www.google.com/intl/en_ALL/mapfiles/closedhand.cur"), all-scroll;
    cursor: -webkit-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing; }
  .ruleComponent.placeholder {
    opacity: 0; }
  .ruleComponent.dragged {
    margin-left: 0px;
    opacity: 0.7; }
  .ruleComponent.even {
    background-color: #EBF6F9; }
  .ruleComponent.odd {
    background-color: #ffffff; }
  .ruleComponent > div {
    float: left; }
  .ruleComponent .rule_data {
    width: 95%;
    padding-left: 30px; }
    .ruleComponent .rule_data p {
      color: #000000;
      font-family: 'Segoe UI';
      font-size: 14px;
      font-weight: 400;
      margin: 0; }
    .ruleComponent .rule_data ul {
      margin-bottom: 0; }
  .ruleComponent .optionArea .editWrapper {
    float: right; }
    .ruleComponent .optionArea .editWrapper > div {
      float: left; }
    .ruleComponent .optionArea .editWrapper > div:first-child {
      margin-right: 20px; }
    .ruleComponent .optionArea .editWrapper p {
      color: #3ba8c5;
      font-family: 'Helvetica Neue Cyr';
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase; }
      .ruleComponent .optionArea .editWrapper p img {
        margin-right: 10px;
        margin-top: -3px; }

.invitationRulesWrapper p.desc {
  margin-top: 0px;
  color: #000000;
  font-family: 'Segoe UI';
  font-size: 14px;
  font-weight: 400;
  text-align: justify; }

.invitationRulesWrapper .panelWrapper {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }
  .invitationRulesWrapper .panelWrapper .scrollWrapper {
    height: calc(100% - 55px);
    max-height: calc(95vh - 55px); }

.invitationRulesWrapper .editRulesSection .customFormGroup .customDrpDwn .css-10nd86i {
  width: 241px; }

.invitationRulesWrapper .editRulesSection .customFormGroup.drpDwnGrp .customDrpDwn:first-child {
  margin-right: 15px; }

.invitationRulesWrapper .editRulesSection .customFormGroup > div, .invitationRulesWrapper .editRulesSection .customFormGroup > span {
  float: left; }

.invitationRulesWrapper .editRulesSection .customFormGroup > div:nth-child(3) {
  width: 78px; }

.invitationRulesWrapper .editRulesSection .customFormGroup > span {
  color: #000000;
  font-family: 'Segoe UI';
  font-size: 14px;
  margin-top: 6px;
  margin-left: 15px; }
  .invitationRulesWrapper .editRulesSection .customFormGroup > span:nth-child(2) {
    margin-right: 15px; }

.invitationRulesWrapper .editRulesSection .customFormGroup .customInput {
  width: 57px;
  height: 37px;
  float: left; }

.invitationRulesWrapper .editRulesSection .conditions .btn-group-toggle, .invitationRulesWrapper .editRulesSection .invitation_msg .btn-group-toggle {
  float: left;
  margin-top: 3px; }

.invitationRulesWrapper .editRulesSection .conditionsWrapper {
  max-height: 150px;
  overflow-y: auto;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: 0 0 5px rgba(13, 16, 21, 0.19);
  border-radius: 10px;
  background-color: #ffffff;
  margin: 19px 0 9px 0;
  float: left; }
  .invitationRulesWrapper .editRulesSection .conditionsWrapper.conditionComponent {
    padding: 15px;
    max-height: -webkit-max-content;
    max-height: -moz-max-content;
    max-height: max-content; }
    .invitationRulesWrapper .editRulesSection .conditionsWrapper.conditionComponent .btn_save {
      margin-right: 10px; }
    .invitationRulesWrapper .editRulesSection .conditionsWrapper.conditionComponent p.conditonPreview {
      margin-left: 15px; }
    .invitationRulesWrapper .editRulesSection .conditionsWrapper.conditionComponent .form-control, .invitationRulesWrapper .editRulesSection .conditionsWrapper.conditionComponent .customDrpDwn .css-10nd86i {
      max-width: 256px;
      width: 100%; }
  .invitationRulesWrapper .editRulesSection .conditionsWrapper .conditionsList {
    padding: 0; }
    .invitationRulesWrapper .editRulesSection .conditionsWrapper .conditionsList p {
      color: #000000;
      font-family: 'Segoe UI';
      font-size: 14px;
      font-weight: 400; }
  .invitationRulesWrapper .editRulesSection .conditionsWrapper .customDrpDwn .css-15k3avv .css-11unzgr {
    max-height: 160px; }

.invitationRulesWrapper .editRulesSection .invitation_msg .btn-group-toggle {
  display: inline-block; }
  .invitationRulesWrapper .editRulesSection .invitation_msg .btn-group-toggle > label {
    margin-bottom: 12px;
    text-align: left; }
    .invitationRulesWrapper .editRulesSection .invitation_msg .btn-group-toggle > label:last-child {
      margin-bottom: 0; }

.invitationRulesWrapper .editRulesSection .relatedDropDown {
  margin-top: 12px;
  color: #000000;
  font-family: 'Segoe UI';
  font-size: 14px;
  font-weight: 400; }

.invitationRulesWrapper .editRulesSection textarea {
  height: 90px;
  border-radius: 5px;
  border: 1px solid #dedede;
  background-color: #ffffff;
  padding: 13px;
  color: #000000;
  font-family: 'Segoe UI';
  font-size: 14px;
  font-weight: 400;
  margin-top: 12px; }

.invitationRulesWrapper .rules_wrapper {
  margin-top: 55px; }
  .invitationRulesWrapper .rules_wrapper h4 {
    color: #000000;
    font-family: 'Helvetica Neue Cyr Roman';
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 20px; }
  .invitationRulesWrapper .rules_wrapper .box {
    padding: 27px; }

.innercontent_wrapper .securityWrapper {
  margin-bottom: 18px; }
  .innercontent_wrapper .securityWrapper:last-child {
    margin-bottom: 0; }
  .innercontent_wrapper .securityWrapper .box .custom_textArea span {
    color: #adadad;
    font-family: 'Segoe UI Semi Bold';
    font-size: 14px;
    font-weight: 400; }
  .innercontent_wrapper .securityWrapper .box .custom_textArea textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    background-color: #ffffff;
    padding: 15px;
    color: #000000;
    font-family: 'Segoe UI Semi Bold';
    font-size: 14px;
    font-weight: 400; }
  .innercontent_wrapper .securityWrapper .box .country_col {
    margin-top: 10px; }
  .innercontent_wrapper .securityWrapper .box .country_text {
    text-align: left;
    margin-top: 0; }
    .innercontent_wrapper .securityWrapper .box .country_text img {
      margin-top: -4px; }
    .innercontent_wrapper .securityWrapper .box .country_text span {
      margin-left: 10px;
      color: #000000;
      font-family: 'Segoe UI Semi Bold';
      font-size: 14px;
      font-weight: 400; }
  .innercontent_wrapper .securityWrapper .box .contentWrapper .custom_input_text {
    width: 100%; }
  .innercontent_wrapper .securityWrapper .custom_input_companytext {
    width: 100%; }

.languageWrapper.innercontent_wrapper .box {
  padding: 26px; }

.languageWrapper .panelWrapper {
  max-height: 77vh; }
  .languageWrapper .panelWrapper .panelHeader {
    padding: 0 15px; }
  .languageWrapper .panelWrapper .scriptPanel .action {
    padding: 11px 15px 15px; }

.languageWrapper .scrollWrapper {
  height: calc(100% - 57px);
  max-height: calc(77vh - 57px); }
  .languageWrapper .scrollWrapper .panelContent p {
    color: #000000;
    font-family: 'Segoe UI';
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    overflow-wrap: break-word; }
    .languageWrapper .scrollWrapper .panelContent p.base {
      color: #242831; }

.fileTransferWrapper .headerTitle {
  margin: 40px 0 13px 0; }
  .fileTransferWrapper .headerTitle h4 {
    color: #000000;
    font-family: 'Helvetica Neue Cyr Roman';
    font-size: 22px;
    font-weight: 400; }

.fileTransferWrapper .panelWrapper {
  min-width: 650px;
  box-shadow: none; }

.fileTransferWrapper .settingFormWrapper form .customField p {
  max-width: 258px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400;
  margin: 9px 0 8px 0; }
  .fileTransferWrapper .settingFormWrapper form .customField p img {
    margin: -4px 0 0 10px; }

.fileTransferWrapper .settingFormWrapper form .customField .progress_wrapper {
  max-width: 258px;
  background-color: #e7e7e7;
  border-radius: 2px; }
  .fileTransferWrapper .settingFormWrapper form .customField .progress_wrapper .progress {
    background-color: #3ba8c5;
    border-radius: 2px;
    height: 4px; }

.fileTransferWrapper .scriptPanel .action.editing {
  opacity: 0.2; }

.fileTransferWrapper .scriptPanel .action .img-wrapper {
  padding: 8px 7px 9px 8px;
  border-radius: 50%; }
  .fileTransferWrapper .scriptPanel .action .img-wrapper.download {
    border: 1px solid #7fae72; }
  .fileTransferWrapper .scriptPanel .action .img-wrapper.upload {
    border: 1px solid #e44e4e; }

.fileTransferWrapper .scriptPanel p {
  margin: 0;
  color: #000000;
  font-family: 'Segoe UI';
  font-size: 14px;
  font-weight: 400; }
  .fileTransferWrapper .scriptPanel p.action_text {
    cursor: pointer;
    color: #3ba8c5;
    font-family: 'Helvetica Neue Cyr Medium';
    font-size: 14px;
    font-weight: 500; }
    .fileTransferWrapper .scriptPanel p.action_text img {
      margin-right: 12px; }

.fileTransferWrapper .btn_del, .fileTransferWrapper .btn_clr {
  width: auto;
  padding: 8px 29px; }

.fileTransferWrapper .headerCheck label.checkbox_custom {
  border-color: #fff; }
  .fileTransferWrapper .headerCheck label.checkbox_custom.btn-primary:not(:disabled):not(.disabled):active, .fileTransferWrapper .headerCheck label.checkbox_custom.btn-primary:not(:disabled):not(.disabled).active, .show > .fileTransferWrapper .headerCheck label.checkbox_custom.btn-primary.dropdown-toggle, .fileTransferWrapper .headerCheck label.checkbox_custom.btn-primary:hover {
    border-color: #fff; }

.chatInvWrapper {
  margin-bottom: 30px; }
  .chatInvWrapper:last-child {
    margin-bottom: 0; }
  .chatInvWrapper .section.box {
    padding: 30px; }
    .chatInvWrapper .section.box .form-control.customInput {
      width: 57px;
      height: 37px;
      border-radius: 5px;
      border: 1px solid #dedede;
      background-color: transparent;
      margin: 5px 8px 0;
      float: left; }
    .chatInvWrapper .section.box .custom_label span {
      float: left;
      margin-top: 12px; }

.innercontent_wrapper .optDeptWrapper.with-header .box .contentWrapper {
  padding: 26px 26px 5px; }

.innercontent_wrapper .optDeptWrapper.with-header .operatorsWrapper .box .contentWrapper {
  padding: 14px 15px 5px; }

.optDeptWrapper .customDrpDwn .css-10nd86i {
  width: 100%; }

.optDeptWrapper.with-header {
  margin-bottom: 30px; }

.optDeptWrapper .box .headerTitle h4 {
  font-family: "Helvetica Neue Cyr Roman";
  font-size: 22px;
  font-weight: 400; }

.optDeptWrapper .chatRedirect {
  text-align: left; }
  .optDeptWrapper .chatRedirect label {
    text-align: left; }

.optDeptWrapper .operatorSection {
  background-color: #f9f9f9;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px; }
  .optDeptWrapper .operatorSection .operatorsWrapper {
    display: inline-block;
    margin-bottom: 7px;
    margin-top: 25px; }
    .optDeptWrapper .operatorSection .operatorsWrapper .settingFormWrapper .form-control {
      width: 100%; }
    .optDeptWrapper .operatorSection .operatorsWrapper .box {
      min-width: 280px;
      float: left;
      width: 30.4%;
      padding: 0 0 19px 0;
      margin: 0 10px 20px 10px;
      height: 691px;
      position: relative; }
      .optDeptWrapper .operatorSection .operatorsWrapper .box .btnRow {
        position: absolute;
        width: 100%;
        bottom: 19px; }
      .optDeptWrapper .operatorSection .operatorsWrapper .box .col-form-label {
        margin-left: 14px; }
      .optDeptWrapper .operatorSection .operatorsWrapper .box .formBtns {
        padding-left: 0;
        margin-top: 0;
        padding-top: 20px; }
        .optDeptWrapper .operatorSection .operatorsWrapper .box .formBtns .btn_clr {
          margin-left: 0; }
    .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper {
      border-bottom: 1px solid #dfdfdf;
      height: 168px;
      position: relative;
      overflow: hidden; }
      .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper img.data_image, .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper img.editImage {
        transform: rotate(-30deg);
        -webkit-transform: rotate(-30deg);
        -moz-transform: rotate(-30deg);
        -ms-transform: rotate(-30deg);
        -o-transform: rotate(-30deg);
        margin-top: 23px;
        height: 187px; }
      .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper .img_delete {
        position: absolute;
        top: 4px;
        right: 15px;
        cursor: pointer; }
      .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper input {
        position: absolute;
        top: 0;
        left: 0;
        height: 168px;
        opacity: 0; }
      .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage {
        position: relative; }
        .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage p {
          font-family: "Segoe UI Bold";
          font-size: 14px;
          font-weight: 700;
          text-transform: uppercase;
          margin: 0;
          position: absolute;
          top: 60%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
        .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage .defaultImage {
          width: 120px;
          height: 120px;
          border: 2px solid #ceebf3;
          background-color: #ebf6f9;
          margin: 24px auto;
          border-radius: 50%;
          padding: 10px; }
          .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.noImage .defaultImage img {
            border: none; }
      .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage {
        padding: 35px 104px 30px; }
        .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage img {
          border: none;
          border-radius: 50%;
          padding: 21px; }
        .optDeptWrapper .operatorSection .operatorsWrapper .profileImgWrapper.addImage p {
          color: #3ba8c5;
          font-family: "Segoe UI Bold";
          font-size: 14px;
          font-weight: 700;
          text-transform: uppercase;
          margin: 13px 0 0 0; }
    .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper {
      padding: 294px 104px;
      cursor: pointer; }
      .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper img {
        border: 2px solid #3ba8c5;
        border-radius: 50%;
        padding: 21px; }
      .optDeptWrapper .operatorSection .operatorsWrapper .addWrapper p {
        color: #3ba8c5;
        font-family: "Segoe UI Bold";
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 13px 0 0 0; }
    .optDeptWrapper .operatorSection .operatorsWrapper .operatorNick {
      border-bottom: 1px solid #dfdfdf;
      padding: 20px 20px 17px; }
      .optDeptWrapper .operatorSection .operatorsWrapper .operatorNick p {
        color: #3ba8c5;
        font-family: "Helvetica Neue Cyr Roman";
        font-size: 22px;
        font-weight: 400;
        margin: 0;
        text-align: left; }
        .optDeptWrapper .operatorSection .operatorsWrapper .operatorNick p span {
          float: right;
          width: 17px;
          height: 17px;
          border-radius: 50%;
          margin-left: 20px;
          margin-top: 8px; }
          .optDeptWrapper .operatorSection .operatorsWrapper .operatorNick p span.online {
            background-color: #51af31; }
          .optDeptWrapper .operatorSection .operatorsWrapper .operatorNick p span.offline {
            background-color: #c94040; }
    .optDeptWrapper .operatorSection .operatorsWrapper .operatorDetails {
      padding: 17px 20px; }
      .optDeptWrapper .operatorSection .operatorsWrapper .operatorDetails p {
        color: #000000;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        margin: 0 0 12px 0; }
        .optDeptWrapper .operatorSection .operatorsWrapper .operatorDetails p span {
          font-family: "Segoe UI Semi Bold";
          font-weight: 600; }
        .optDeptWrapper .operatorSection .operatorsWrapper .operatorDetails p.email {
          color: #3ba8c5; }

.optDeptWrapper.section_Dept .box {
  padding: 30px; }
  .optDeptWrapper.section_Dept .box .headerTitle {
    margin-bottom: 23px; }
  .optDeptWrapper.section_Dept .box .formBtns {
    margin-top: 0px;
    border-top: none;
    margin-bottom: 30px; }

.optDeptWrapper.section_Dept .panelWrapper {
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  box-shadow: none; }

.optDeptWrapper.section_Dept .panelHeader > div:first-child p {
  margin-left: 15px; }

.optDeptWrapper.section_Dept .panelContent p {
  color: #000000;
  font-family: "Segoe UI";
  font-size: 14px;
  font-weight: 400;
  margin: 0px; }

.optDeptWrapper.section_Dept .panelContent form > div {
  float: left;
  margin-bottom: 0; }
  .optDeptWrapper.section_Dept .panelContent form > div .form-control {
    width: 100%; }
  .optDeptWrapper.section_Dept .panelContent form > div.customDrpDwn.has-error .css-vj8t7z {
    border: 1px solid #c94040; }

.optDeptWrapper.section_Dept .panelContent > div:first-child p {
  margin-left: 15px; }

.optDeptWrapper.section_Dept .panelContent .editWrapper {
  float: right; }
  .optDeptWrapper.section_Dept .panelContent .editWrapper p {
    color: #3ba8c5;
    font-family: "Helvetica Neue Cyr Medium";
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.28px; }
    .optDeptWrapper.section_Dept .panelContent .editWrapper p span {
      margin: 0 15px 0 7px; }
    .optDeptWrapper.section_Dept .panelContent .editWrapper p img {
      margin-top: -4px; }

.chat.data .fieldSection p {
  color: #242831;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }

.chat.data p.mandatoryData {
  width: 20px;
  margin-right: 10px;
  text-transform: none;
  color: #242831 !important;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }

.chat.data .custom_radioBtn p {
  color: #000000;
  font-family: "Segoe UI";
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase; }

.chat.data .custom_radioBtn .active p {
  color: #fff; }

.chat .optionArea .editWrapper > div:nth-child(2) {
  margin-right: 0px; }

.widgetSettingsWrapper .section {
  margin-bottom: 30px; }
  .widgetSettingsWrapper .section:last-child {
    margin-bottom: 0; }

.widgetSettingsWrapper .addSettingsIcon {
  width: 20px; }

.widgetSettingsWrapper .scroll_row {
  margin-bottom: 35px; }
  .widgetSettingsWrapper .scroll_row .panelWrapper {
    min-width: 635px; }
  .widgetSettingsWrapper .scroll_row .ruleComponent {
    padding: 11px 13px 9px 5px;
    cursor: pointer; }
    .widgetSettingsWrapper .scroll_row .ruleComponent .optionArea .editWrapper {
      float: none; }
      .widgetSettingsWrapper .scroll_row .ruleComponent .optionArea .editWrapper > div:first-child {
        margin-right: 0;
        margin-left: 8px; }
      .widgetSettingsWrapper .scroll_row .ruleComponent .optionArea .editWrapper .save_edit p img {
        margin-top: 0; }
      .widgetSettingsWrapper .scroll_row .ruleComponent .optionArea .editWrapper .del p img {
        margin-right: 2px;
        margin-top: -2px; }
      .widgetSettingsWrapper .scroll_row .ruleComponent .optionArea .editWrapper p.mandatoryData {
        width: 100%;
        padding-right: 50px; }
    .widgetSettingsWrapper .scroll_row .ruleComponent .css-1ep9fjw {
      padding: 8px 8px 8px 2px; }

.widgetSettingsWrapper .panelWrapper {
  box-shadow: none; }
  .widgetSettingsWrapper .panelWrapper .panelHeader {
    padding-left: 19px; }
  .widgetSettingsWrapper .panelWrapper .scriptPanel .editing .form-control {
    width: 100%; }
  .widgetSettingsWrapper .panelWrapper .scriptPanel .chat.data .custom_radioBtn p {
    top: -11px; }

.widgetSettingsWrapper.innercontent_wrapper .with-header .box .chatPreview .headerTitle {
  padding: 10px 9px; }

.widgetSettingsWrapper.innercontent_wrapper .soundSettings .customDrpDwn > div, .widgetSettingsWrapper.innercontent_wrapper .soundSettings .customDrpDwn > img {
  float: left; }

.widgetSettingsWrapper.innercontent_wrapper .soundSettings .customDrpDwn > img {
  margin: 7px 10px 6px 15px; }

.widgetSettingsWrapper .chatSettings .customDrpDwn .css-10nd86i {
  width: auto; }

.widgetSettingsWrapper .chatSettings .form-control {
  width: 100%; }

.widgetSettingsWrapper .chatSettings .paramField {
  position: relative; }
  .widgetSettingsWrapper .chatSettings .paramField textarea {
    position: absolute;
    left: 0;
    top: -20px;
    z-index: 2; }

.widgetSettingsWrapper .chatSettings textarea {
  width: 100%;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #dedede;
  border-radius: 4px; }

.widgetSettingsWrapper .chatSettings .panelWrapper .form-group, .widgetSettingsWrapper .chatSettings:nth-child(2) .form-group {
  margin-bottom: 5px; }

.widgetSettingsWrapper .chatSettings:nth-child(3) .scroll_row {
  margin-top: 30px; }

.widgetSettingsWrapper .chatSettings .formBtns {
  margin-top: 10px; }

.widgetSettingsWrapper .chatSettings .radioBtnGrp input {
  margin-bottom: 7px; }

.widgetSettingsWrapper .chatSettings span.mandatory {
  color: #e44e4e;
  margin-left: 3px; }

.widgetSettingsWrapper .soundSettings .soundPackageSection {
  border-radius: 10px;
  background-color: #f6f6f6;
  padding: 20px 19px 7px;
  margin-top: 20px; }
  .widgetSettingsWrapper .soundSettings .soundPackageSection .sound-grp > div {
    padding-left: 5px; }

.widgetSettingsWrapper .preview .chatPreview {
  height: 475px; }
  .widgetSettingsWrapper .preview .chatPreview .chatBox {
    height: 395px; }
    .widgetSettingsWrapper .preview .chatPreview .chatBox .msgArea {
      height: 97%; }
      .widgetSettingsWrapper .preview .chatPreview .chatBox .msgArea .form-group {
        margin-bottom: 5px; }
  .widgetSettingsWrapper .preview .chatPreview .custom_radioBtn .btnText {
    top: 4px;
    color: #000000;
    font-family: "Segoe UI";
    font-size: 11px;
    font-weight: 700; }
  .widgetSettingsWrapper .preview .chatPreview .chatBox {
    padding: 0 0 20px 10px; }
    .widgetSettingsWrapper .preview .chatPreview .chatBox .msgArea p {
      font-family: "Segoe UI Semi Bold";
      font-size: 14px;
      font-weight: 400; }
  .widgetSettingsWrapper .preview .chatPreview p {
    color: #242831;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400; }
  .widgetSettingsWrapper .preview .chatPreview .chatArea {
    margin-top: 15px; }
  .widgetSettingsWrapper .preview .chatPreview .formBtns {
    padding: 0 15px; }
    .widgetSettingsWrapper .preview .chatPreview .formBtns p {
      float: left;
      text-transform: none;
      margin: 8px 0; }
      .widgetSettingsWrapper .preview .chatPreview .formBtns p .mandatory {
        margin-right: 3px;
        margin-left: 0; }
    .widgetSettingsWrapper .preview .chatPreview .formBtns .btn_send p {
      color: #fff;
      margin: -2px 0; }
  .widgetSettingsWrapper .preview .chatPreview .checkbox_custom {
    margin-top: 8px; }

.widgetSettingsWrapper .btn-group-toggle {
  display: inline-block; }
  .widgetSettingsWrapper .btn-group-toggle label {
    text-align: left; }
    .widgetSettingsWrapper .btn-group-toggle label.btn {
      margin-bottom: 13px;
      color: #000000;
      font-family: 'Segoe UI Semi Bold';
      font-size: 14px; }

.innercontent_wrapper .websiteContentWrapper .header_title h4 {
  color: #3ba8c5;
  font-family: "Helvetica Neue Cyr Roman";
  font-size: 22px;
  font-weight: 400;
  margin-right: 20px; }
  .innercontent_wrapper .websiteContentWrapper .header_title h4.websiteName {
    color: #000000; }

.innercontent_wrapper .websiteContentWrapper .box {
  padding: 28px 0 19px; }
  .innercontent_wrapper .websiteContentWrapper .box .websiteFormWrapper {
    padding: 0 30px; }
  .innercontent_wrapper .websiteContentWrapper .box .formBtns {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px; }

.innercontent_wrapper .websiteContentWrapper .logoWrapper {
  max-width: 230px;
  max-height: 230px;
  margin-right: 25px;
  margin-left: 15px; }
  .innercontent_wrapper .websiteContentWrapper .logoWrapper img {
    opacity: 0.7;
    border-radius: 50%;
    padding: 21px;
    margin-top: 17px; }
  .innercontent_wrapper .websiteContentWrapper .logoWrapper p {
    margin-top: 7px;
    color: #3ba8c5;
    font-family: "Segoe UI Bold";
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase; }
  .innercontent_wrapper .websiteContentWrapper .logoWrapper input {
    position: absolute;
    top: 60px;
    left: 70px;
    height: 110px;
    opacity: 0;
    width: 90px;
    cursor: pointer; }

.innercontent_wrapper .websiteContentWrapper .websiteFormWrapper .form-control {
  width: 100%; }

.innercontent_wrapper .websiteContentWrapper .websiteFormWrapper .form-group {
  /*margin-bottom: 13px;*/ }

.innercontent_wrapper .websiteContentWrapper .websiteFormWrapper .form-label {
  line-height: 1.3; }
  .innercontent_wrapper .websiteContentWrapper .websiteFormWrapper .form-label.forEmail {
    padding-bottom: 0;
    padding-top: 0; }

.websiteWidgetGetCode .formBtns Button {
  height: 50px; }

.innercontent_wrapper .widgetWebsiteWrapper .box.settingFormWrapper {
  padding: 30px 15px; }

.innercontent_wrapper .widgetWebsiteWrapper .widgetThumbnailWrapper .addWrapper {
  padding: 96px 0;
  cursor: pointer; }
  .innercontent_wrapper .widgetWebsiteWrapper .widgetThumbnailWrapper .addWrapper img {
    border: 2px solid #3ba8c5;
    border-radius: 50%;
    padding: 21px; }
  .innercontent_wrapper .widgetWebsiteWrapper .widgetThumbnailWrapper .addWrapper p {
    color: #3ba8c5;
    font-family: "Segoe UI Bold";
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 13px 0 0 0; }

.innercontent_wrapper .widgetWebsiteWrapper .widgetThumbnailWrapper .noneWrapper {
  padding: 96px 0;
  cursor: pointer; }
  .innercontent_wrapper .widgetWebsiteWrapper .widgetThumbnailWrapper .noneWrapper img {
    border: none;
    border-radius: 0%;
    padding: 11px; }

.innercontent_wrapper .widgetWebsiteWrapper .widgetThumbnailWrapper .noWrapper {
  padding: 118px 0;
  cursor: pointer; }
  .innercontent_wrapper .widgetWebsiteWrapper .widgetThumbnailWrapper .noWrapper img {
    border: none;
    border-radius: 0%;
    padding: 11px; }

.innercontent_wrapper .widgetWebsiteWrapper .widgetContent {
  padding: 12px 12px 0;
  margin-bottom: 30px; }
  .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .logoWrapper p {
    height: 22px;
    margin: 0; }
    .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .logoWrapper p span {
      width: 17px;
      height: 17px;
      float: right;
      border-radius: 50%; }
      .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .logoWrapper p span.installed_icon {
        background-color: #51af31; }
      .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .logoWrapper p span.uninstalled_icon {
        background-color: #c94040; }
  .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    margin: 10px 0; }
    .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span {
      color: #7d7d7d; }
      .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count {
        margin-left: 6px;
        border-radius: 50%;
        background-color: #3ba8c5;
        padding: 1px 8px 4px 7px;
        color: #ffffff;
        font-family: "Segoe UI Bold";
        font-size: 12px;
        font-weight: 700;
        cursor: pointer; }
        .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p span.count:hover {
          padding: 0px 7px 3px 6px;
          border-color: 1px solid #3ba8c5;
          background-color: #fff;
          color: #3ba8c5; }
    .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p.domain {
      position: relative;
      cursor: pointer; }
      .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p.domain span {
        color: #3ba8c5; }
      .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper p.domain .domainList {
        width: 260px;
        box-shadow: 0 0 5px rgba(13, 16, 21, 0.19);
        border-radius: 3px;
        background-color: #ffffff;
        padding: 20px 14px;
        position: absolute;
        z-index: 5;
        top: 27px;
        right: -27px;
        text-align: left;
        color: #000000;
        word-wrap: break-word;
        height: 200px;
        overflow: hidden; }
  .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper .operators {
    position: relative; }
  .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .detailsWrapper .operatorsList {
    width: 190px;
    box-shadow: 0 0 5px rgba(13, 16, 21, 0.19);
    border-radius: 3px;
    background-color: #ffffff;
    padding: 20px;
    position: absolute;
    z-index: 5;
    top: 27px;
    right: -27px;
    text-align: left; }
  .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .tumbnail_footer {
    margin: 0 -12px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px; }
    .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .tumbnail_footer .formBtns {
      border-top: 0;
      padding: 11px 0 10px;
      margin-top: 27px; }
      .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .tumbnail_footer .formBtns Button {
        width: 91px;
        height: 29px; }
      .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .tumbnail_footer .formBtns .edit_btn {
        margin: 0 19px 0 20px; }
      .innercontent_wrapper .widgetWebsiteWrapper .widgetContent .tumbnail_footer .formBtns img {
        cursor: pointer; }

@media (max-width: 1030px) {
  .ruleComponent .optionArea .editWrapper > div:first-child {
    margin-right: 16px; } }

@media (max-width: 768px) {
  .innercontent_wrapper .securityWrapper .box .headerTitle {
    padding: 15px; }
  .innercontent_wrapper .securityWrapper .box .custom_textArea span {
    font-size: 12px; }
  .innercontent_wrapper .securityWrapper .box .custom_textArea textarea {
    font-size: 12px; }
  .innercontent_wrapper .securityWrapper .box .country_text span {
    font-size: 12px; }
  .fileTransferWrapper .panelWrapper .panelHeader p {
    font-size: 12px;
    margin: 8px 0; }
  .fileTransferWrapper .panelWrapper .scriptPanel p {
    font-size: 12px; }
  .chatInvWrapper .section.box {
    padding: 15px; } }

@media (max-width: 576px) {
  .customDrpDwn .css-10nd86i {
    width: 100%;
    max-width: 256px; }
  .panelWrapper .scriptPanel > div.panelHeading p:first-child {
    width: 20px;
    height: 20px;
    margin-top: 4px; }
  .ruleComponent {
    padding: 13px 8px; }
    .ruleComponent:active {
      cursor: url("https://www.google.com/intl/en_ALL/mapfiles/closedhand.cur"), all-scroll;
      cursor: -webkit-grabbing;
      cursor: -o-grabbing;
      cursor: -ms-grabbing;
      cursor: grabbing; }
    .ruleComponent .rule_data {
      width: 88%;
      padding-left: 16px; }
      .ruleComponent .rule_data .optionArea {
        margin-top: 10px; }
  .invitationRulesWrapper .rules_wrapper .box {
    padding: 10px; }
  .editRulesSection .customDrpDwn {
    margin-bottom: 10px; }
    .editRulesSection .customDrpDwn .css-10nd86i {
      width: 100%; }
  .editScriptSection .customDrpDwn .css-10nd86i {
    max-width: none; }
  .fileTransferWrapper .settingFormWrapper form .customField p {
    font-size: 12px; }
  .fileTransferWrapper .formBtns {
    padding-left: 0;
    padding-right: 0; }
  .fileTransferWrapper .btn_del, .fileTransferWrapper .btn_clr {
    width: auto;
    padding: 8px 8px; }
  .widgetSettingsWrapper .soundPackageSection .customDrpDwn .css-10nd86i {
    max-width: 253px; } }

@media (max-width: 375px) {
  .fileTransferWrapper .btn_clr {
    margin-bottom: 5px;
    padding: 8px 24px; }
  .fileTransferWrapper .btn_del {
    margin-left: 0; }
  .widgetSettingsWrapper .soundPackageSection .customDrpDwn .css-10nd86i {
    width: 166px; }
  .widgetSettingsWrapper.innercontent_wrapper .soundSettings .customDrpDwn > img {
    margin-left: 10px; }
  .widgetSettingsWrapper .soundSettings .soundPackageSection {
    padding: 11px 8px 7px; }
    .widgetSettingsWrapper .soundSettings .soundPackageSection .sound-grp {
      margin-left: 4px; } }

.innercontent_wrapper .profile_wrapper {
  margin-bottom: 18px; }
  .innercontent_wrapper .profile_wrapper:last-child {
    margin-bottom: 0; }
  .innercontent_wrapper .profile_wrapper .box {
    padding: 40px 30px; }
    .innercontent_wrapper .profile_wrapper .box.settingFormWrapper .logOff {
      background-color: #e9ecef;
      color: #9d9696; }
    .innercontent_wrapper .profile_wrapper .box.settingFormWrapper .loopCount {
      width: 65px;
      height: 37px; }
  .innercontent_wrapper .profile_wrapper h4 {
    margin-bottom: 0; }
  .innercontent_wrapper .profile_wrapper form {
    margin-top: 27px; }
  .innercontent_wrapper .profile_wrapper .operator_options {
    margin-top: 44px; }
    .innercontent_wrapper .profile_wrapper .operator_options .headerTitle {
      margin-bottom: 28px; }
    .innercontent_wrapper .profile_wrapper .operator_options button {
      margin-top: 15px; }
    .innercontent_wrapper .profile_wrapper .operator_options .invitation_msg textarea {
      margin-top: 15px;
      width: 100%;
      height: 84px;
      border-radius: 0.25rem;
      border: 1px solid #ced4da;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      color: #000000;
      font-family: 'Segoe UI Semi Bold';
      font-size: 14px;
      font-weight: 400;
      padding: 5px 8px; }
    .innercontent_wrapper .profile_wrapper .operator_options .invitation_msg .btn-group-toggle {
      display: inline-block; }
      .innercontent_wrapper .profile_wrapper .operator_options .invitation_msg .btn-group-toggle .custom_radionBtn {
        margin-top: 13px; }
  .innercontent_wrapper .profile_wrapper .dept_section {
    margin-top: 50px; }
    .innercontent_wrapper .profile_wrapper .dept_section h5 {
      color: #000000;
      font-family: 'Helvetica Neue Cyr Roman';
      font-size: 22px;
      font-weight: 400;
      line-height: 36px;
      margin-bottom: 0; }
    .innercontent_wrapper .profile_wrapper .dept_section > div {
      margin-top: 12px; }
      .innercontent_wrapper .profile_wrapper .dept_section > div h6 {
        color: #293037;
        font-family: 'Segoe UI Bold';
        font-size: 13px;
        font-weight: 700;
        line-height: 30.4px;
        margin: 0; }
      .innercontent_wrapper .profile_wrapper .dept_section > div ul {
        list-style: none;
        padding-left: 20px;
        margin-top: 3px;
        margin-bottom: 0; }
      .innercontent_wrapper .profile_wrapper .dept_section > div li {
        color: #000000;
        font-family: 'Segoe UI Semi Bold';
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; }
  .innercontent_wrapper .profile_wrapper .customDrpDwn > div, .innercontent_wrapper .profile_wrapper .customDrpDwn > img, .innercontent_wrapper .profile_wrapper .customDrpDwn label, .innercontent_wrapper .profile_wrapper .customDrpDwn input {
    float: left; }
  .innercontent_wrapper .profile_wrapper .customDrpDwn label {
    width: auto; }
  .innercontent_wrapper .profile_wrapper .customDrpDwn > img {
    margin: 7px 10px 6px 15px; }
  .innercontent_wrapper .profile_wrapper .chat_settings > p {
    margin: 9px 0 7px 11px;
    color: #000000;
    font-family: 'Segoe UI Semi Bold';
    font-size: 14px;
    font-weight: 400; }
  .innercontent_wrapper .profile_wrapper .form_control_repeat > input {
    margin: 0 9px 0 15px;
    float: left; }
  .innercontent_wrapper .profile_wrapper .form_control_repeat button {
    margin-top: 4px; }
  .innercontent_wrapper .profile_wrapper .available_zone .day_wrapper {
    margin-bottom: 13px; }
    .innercontent_wrapper .profile_wrapper .available_zone .day_wrapper p.day_label {
      color: #000000;
      font-family: 'Segoe UI Semi Bold';
      font-size: 14px;
      font-weight: 400;
      margin: 8px 4px 8px 0;
      text-align: left;
      width: 73px;
      padding-left: 0; }
    .innercontent_wrapper .profile_wrapper .available_zone .day_wrapper input {
      width: 61px;
      height: 37px;
      margin-left: 10px; }
  .innercontent_wrapper .profile_wrapper .performance-wrapper {
    float: right;
    width: 100%;
    max-width: 424px; }
    .innercontent_wrapper .profile_wrapper .performance-wrapper .performance-content {
      margin-top: 27px;
      width: 100%;
      max-width: 424px;
      height: 127px;
      box-shadow: 0 0 5px rgba(13, 16, 21, 0.19);
      border-radius: 10px;
      background-color: #ffffff;
      margin-left: 0;
      padding: 30px 0; }
      .innercontent_wrapper .profile_wrapper .performance-wrapper .performance-content p {
        margin: 0; }
        .innercontent_wrapper .profile_wrapper .performance-wrapper .performance-content p.count {
          color: #3ba8c5;
          font-family: 'Helvetica Neue Cyr Medium';
          font-size: 30px;
          font-weight: 700; }
          .innercontent_wrapper .profile_wrapper .performance-wrapper .performance-content p.count img {
            margin-left: 12px;
            margin-top: -6px; }
        .innercontent_wrapper .profile_wrapper .performance-wrapper .performance-content p.type {
          color: #293037;
          font-family: 'Segoe UI Bold';
          font-size: 13px;
          font-weight: 700; }
      .innercontent_wrapper .profile_wrapper .performance-wrapper .performance-content .chats {
        border-right: 1px solid #d0d0d0; }

@media (max-width: 375px) {
  .sound-grp .customDrpDwn .css-10nd86i {
    width: 208px; }
  .innercontent_wrapper .profile_wrapper .chat_settings > p {
    font-size: 12px;
    text-align: left;
    width: 70%;
    margin-top: 0; }
  .innercontent_wrapper .profile_wrapper .box {
    padding: 20px 15px; }
  .innercontent_wrapper .profile_wrapper .performance-wrapper .performance-content p.count {
    font-size: 26px !important; }
  .innercontent_wrapper .profile_wrapper .form_control_repeat > input {
    margin: 10px 10px 10px 0; }
  .innercontent_wrapper .profile_wrapper .form_control_repeat button {
    margin-top: 0; } }

@media (max-width: 768px) {
  .innercontent_wrapper .profile_wrapper .form_control_repeat > input {
    margin: 10px 10px 10px 0; }
  .innercontent_wrapper .profile_wrapper .form_control_repeat button {
    margin-top: 14px;
    padding: 0px 8px; }
  .innercontent_wrapper .profile_wrapper .performance-wrapper {
    float: left;
    margin-top: 50px; }
    .innercontent_wrapper .profile_wrapper .performance-wrapper .performance-content {
      margin-top: 27px;
      width: 100%;
      max-width: 424px;
      height: 127px;
      box-shadow: 0 0 5px rgba(13, 16, 21, 0.19);
      border-radius: 10px;
      background-color: #ffffff;
      padding: 30px 0;
      margin-left: 0; }
      .innercontent_wrapper .profile_wrapper .performance-wrapper .performance-content p {
        margin: 0; }
        .innercontent_wrapper .profile_wrapper .performance-wrapper .performance-content p.count {
          color: #3ba8c5;
          font-family: 'Helvetica Neue Cyr Medium';
          font-size: 30px;
          font-weight: 700; }
          .innercontent_wrapper .profile_wrapper .performance-wrapper .performance-content p.count img {
            margin-left: 12px;
            margin-top: -6px; }
        .innercontent_wrapper .profile_wrapper .performance-wrapper .performance-content p.type {
          color: #293037;
          font-family: 'Segoe UI Bold';
          font-size: 13px;
          font-weight: 700; }
      .innercontent_wrapper .profile_wrapper .performance-wrapper .performance-content .chats {
        border-right: 1px solid #d0d0d0; } }

.innercontent_wrapper .traffic_wrapper .rcs-custom-scroll .rcs-inner-container > div {
  padding: 0; }

.innercontent_wrapper .traffic_wrapper .box {
  padding: 40px 30px; }

.innercontent_wrapper .traffic_wrapper .headerTitle h4 {
  color: #000000;
  font-family: "Helvetica Neue Cyr Roman";
  font-size: 22px; }

.innercontent_wrapper .traffic_wrapper .panelWrapper .panelHeader {
  padding: 0 30px; }
  .innercontent_wrapper .traffic_wrapper .panelWrapper .panelHeader p {
    color: #ffffff;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 14px;
    text-align: left;
    margin: 15px 0 13px; }

.innercontent_wrapper .traffic_wrapper .panelWrapper .scrollWrapper > div {
  width: 100%; }

.innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .panelContent {
  padding-bottom: 0;
  padding-right: 0;
  padding-top: 0; }
  .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .panelContent div.parent_toggle {
    padding-right: 11px;
    padding-left: 11px; }
  .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .panelContent .child_wrapper .link_row {
    padding: 0 30px; }

.innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .action.traffic_row.odd .child_wrapper {
  background-color: #fff; }

.innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel p {
  color: #000000;
  font-size: 14px; }
  .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel p .link_img {
    margin-left: 5px; }

.innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .with-image p:first-child {
  width: 16px;
  height: 16px;
  padding: 0;
  float: left;
  margin: 18px 0 0 0; }

.innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .with-image p:last-child {
  margin-left: 18px; }

.innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .with-image p img {
  margin-right: 10px;
  margin-top: -3px; }
  .innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .with-image p img.child-img {
    margin-left: 23px; }

.innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .with-image p a {
  color: #3ba8c5;
  font-size: 14px;
  font-weight: 400; }

.innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .with-image .link > div:first-child {
  flex: 0 0 15%;
  max-width: 15%; }

.innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .with-image .link > div:last-child {
  flex: 0 0 85%;
  max-width: 85%; }

.innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .collapsed .collapse_wrapper p:first-child {
  background-image: url("assets/images/traffic_collapse.png"); }

.innercontent_wrapper .traffic_wrapper .panelWrapper .scriptPanel .dropdown-toggle::after {
  display: none; }

.innercontent_wrapper .traffic_wrapper .formBtns {
  margin: 0;
  padding: 10px 30px; }

.innercontent_wrapper .traffic_wrapper .optheader {
  color: #ffffff !important;
  font-family: 'Helvetica Neue Cyr Roman' !important;
  font-size: 14px !important; }

.innercontent_wrapper .traffic_wrapper .dropDownWrapper {
  margin-bottom: 30px;
  float: right; }
  .innercontent_wrapper .traffic_wrapper .dropDownWrapper .customDrpDwn span {
    margin-top: 6px;
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400; }
  .innercontent_wrapper .traffic_wrapper .dropDownWrapper .customDrpDwn span, .innercontent_wrapper .traffic_wrapper .dropDownWrapper .customDrpDwn > div {
    float: left; }
  .innercontent_wrapper .traffic_wrapper .dropDownWrapper .customDrpDwn > div {
    margin-left: 10px; }
    .innercontent_wrapper .traffic_wrapper .dropDownWrapper .customDrpDwn > div:nth-child(2) {
      width: 88px; }
    .innercontent_wrapper .traffic_wrapper .dropDownWrapper .customDrpDwn > div:nth-child(3) {
      width: 198px; }

.default .widgetWrapper h4.widgetName {
  color: #3ba8c5;
  margin-right: 22px; }

.default .widgetWrapper .exit_btn {
  position: absolute;
  right: 37px;
  color: #3ba8c5;
  font-family: "Segoe UI";
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer; }
  .default .widgetWrapper .exit_btn img {
    margin-right: 8px;
    margin-top: -3px; }

.default .widgetSelectorIndicator {
  margin: 30px auto 0; }
  .default .widgetSelectorIndicator p {
    color: #a1a4a8;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    margin-top: 25px; }

.default .innercontent_wrapper .directLinkWrapper {
  margin-top: 56px; }
  .default .innercontent_wrapper .directLinkWrapper h4 {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    margin-left: 18px;
    margin-bottom: 34px;
    position: relative; }
    .default .innercontent_wrapper .directLinkWrapper h4 span {
      border: 1px solid #3ba8c5;
      background-color: #ffffff;
      border-radius: 50%;
      position: absolute;
      margin-left: 9px;
      width: 24px;
      height: 24px;
      display: inline-block; }
      .default .innercontent_wrapper .directLinkWrapper h4 span img {
        position: absolute;
        top: 5px;
        left: 10px; }
  .default .innercontent_wrapper .directLinkWrapper .with_btn input {
    float: left; }
  .default .innercontent_wrapper .directLinkWrapper .with_btn .uploadBtn button {
    background-color: #3ba8c5; }
    .default .innercontent_wrapper .directLinkWrapper .with_btn .uploadBtn button p {
      color: #fff; }
  .default .innercontent_wrapper .directLinkWrapper .invitationCustomImg {
    margin-bottom: 0.5rem; }
  .default .innercontent_wrapper .directLinkWrapper .btn_wrapper {
    margin-bottom: 15px; }
    .default .innercontent_wrapper .directLinkWrapper .btn_wrapper .uploadBtn.iconUpload {
      margin-left: 0;
      margin-right: 5px; }
      .default .innercontent_wrapper .directLinkWrapper .btn_wrapper .uploadBtn.iconUpload button.uploadIcon input {
        width: 60px; }
  .default .innercontent_wrapper .directLinkWrapper .box {
    padding: 30px; }
    .default .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:first-child {
      width: 131px;
      margin-right: 10px; }
    .default .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:nth-child(2) {
      width: 117px; }
      .default .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:nth-child(2) .css-10nd86i {
        width: 117px; }
  .default .innercontent_wrapper .directLinkWrapper .formBtns Button.btn_save {
    width: auto; }
  .default .innercontent_wrapper .directLinkWrapper .customOption .row > div:nth-child(2) > div:nth-child(2) {
    position: absolute !important;
    z-index: 2;
    top: 44px;
    left: 157px; }
    .default .innercontent_wrapper .directLinkWrapper .customOption .row > div:nth-child(2) > div:nth-child(2) .flexbox-fix {
      display: none !important; }
  .default .innercontent_wrapper .directLinkWrapper .colorSelector .css-1wy0on6 {
    background-color: #fff; }
  .default .innercontent_wrapper .directLinkWrapper .colorSelector input {
    border: none; }
    .default .innercontent_wrapper .directLinkWrapper .colorSelector input:focus {
      border: none; }
  .default .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper {
    position: relative; }
    .default .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper p {
      width: 178px;
      float: left;
      padding-top: 30%;
      font-size: 22px;
      text-align: right;
      text-decoration: underline; }
  .default .innercontent_wrapper .directLinkWrapper .directLinkpreview {
    background-repeat: no-repeat;
    width: 165px;
    height: 114px;
    float: right;
    margin-top: 100px;
    margin-right: 50px;
    margin-left: 24px;
    background-size: cover !important; }
  .default .innercontent_wrapper .directLinkWrapper .theme {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-top: 7px; }
  .default .innercontent_wrapper .directLinkWrapper .preview {
    width: 330px;
    height: 101px;
    padding: 12px 0 0 0;
    margin-top: 40px;
    margin-bottom: 47px;
    background-repeat: no-repeat; }
    .default .innercontent_wrapper .directLinkWrapper .preview .widget-preview {
      width: 166px;
      height: 76px; }
      .default .innercontent_wrapper .directLinkWrapper .preview .widget-preview div {
        font-family: Arial,Helvetica,sans-serif; }
      .default .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-title {
        padding: 11px 0 0 18px;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        text-align: left; }
      .default .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-text {
        width: 146px;
        overflow: hidden;
        white-space: nowrap;
        padding: 1px 0 0;
        text-align: center;
        color: #A8A8A8;
        font-size: 12px;
        margin-left: 10px; }
      .default .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-copy {
        padding: 8px 13px 0;
        text-align: right;
        font-size: 10px;
        color: #fff;
        opacity: .5;
        -moz-opacity: .5;
        -khtml-opacity: .5;
        filter: alpha(opacity=50); }
  .default .innercontent_wrapper .directLinkWrapper .widgetPosition {
    width: 330px; }
    .default .innercontent_wrapper .directLinkWrapper .widgetPosition p {
      width: 236px;
      height: 30px;
      border-radius: 5px;
      border: 1px solid #dedede;
      background-color: #ffffff;
      color: #000000;
      font-family: Segoe UI;
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 37px;
      margin-left: auto;
      margin-right: auto; }
      .default .innercontent_wrapper .directLinkWrapper .widgetPosition p span {
        width: 50%;
        display: inline-block;
        padding: 6px 0;
        cursor: pointer; }
        .default .innercontent_wrapper .directLinkWrapper .widgetPosition p span:first-child {
          border-radius: 5px 0 0 5px; }
        .default .innercontent_wrapper .directLinkWrapper .widgetPosition p span:last-child {
          border-radius: 0 5px 5px 0; }
        .default .innercontent_wrapper .directLinkWrapper .widgetPosition p span.selected {
          color: #ffffff;
          background-color: #3ba8c5; }
  .default .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper {
    width: 90%;
    margin: 0 auto; }
    .default .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp {
      width: 34px;
      height: 34px;
      border: 1px solid #dedede;
      border-radius: 50%;
      float: left;
      padding: 3px;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer; }
      .default .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp div {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        margin: 0; }
      .default .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:hover, .default .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp.selected {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        transition: -webkit-transform 100ms ease 0s;
        transition: transform 100ms ease 0s;
        transition: transform 100ms ease 0s, -webkit-transform 100ms ease 0s;
        padding: 2px; }
        .default .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:hover div, .default .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp.selected div {
          -webkit-transform: scale(0.9);
                  transform: scale(0.9); }
      .default .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(1):hover {
        border: 2px solid #0475A8; }
      .default .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(2):hover {
        border: 2px solid #5F8706; }
      .default .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(3):hover {
        border: 2px solid #D6680B; }
      .default .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(4):hover {
        border: 2px solid #B0097D; }
      .default .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(5):hover {
        border: 2px solid #9D0A09; }
      .default .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(6):hover {
        border: 2px solid #424E54; }
      .default .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(7):hover {
        border: 2px solid #037FAF; }
      .default .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(8):hover {
        border: 2px solid #608706; }
      .default .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(9):hover {
        border: 2px solid #D26009; }
      .default .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(10):hover {
        border: 2px solid #AF097D; }
      .default .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(11):hover {
        border: 2px solid #950909; }
      .default .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(12):hover {
        border: 2px solid #3E484E; }
      .default .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(13):hover {
        border: 2px solid #0476A8; }
      .default .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(14):hover {
        border: 2px solid #98B45D; }
      .default .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(15):hover {
        border: 2px solid #ADADAD; }
      .default .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(16):hover {
        border: 2px solid #98A7AD; }
  .default .innercontent_wrapper .directLinkWrapper .circle-picker {
    padding: 0px 9px; }
    .default .innercontent_wrapper .directLinkWrapper .circle-picker > span > div > span {
      padding: 4px;
      position: relative;
      border: 1px solid #dedede;
      border-radius: 50%;
      display: inline-block;
      width: 34px;
      height: 34px; }
    .default .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(1) > div:hover span {
      border: 2px solid #0475A8; }
    .default .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(1) > div > span > div {
      box-shadow: #0475A8 0px 0px 0px 17px inset !important; }
    .default .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(2) > div:hover span {
      border: 2px solid #5F8706; }
    .default .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(3) > div:hover span {
      border: 2px solid #D6680B; }
    .default .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(4) > div:hover span {
      border: 2px solid #B0097D; }
    .default .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(5) > div:hover span {
      border: 2px solid #9D0A09; }
    .default .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(6) > div:hover span {
      border: 2px solid #424E54; }
    .default .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(7) > div:hover span {
      border: 2px solid #037FAF; }
    .default .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(8) > div:hover span {
      border: 2px solid #587D06; }
    .default .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(9) > div:hover span {
      border: 2px solid #D26009; }
    .default .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(10) > div:hover span {
      border: 2px solid #AF087C; }
    .default .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(11) > div:hover span {
      border: 2px solid #950909; }
    .default .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(12) > div:hover span {
      border: 2px solid #3E484E; }
    .default .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(13) > div:hover span {
      border: 2px solid #0476A8; }
    .default .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(14) > div:hover span {
      border: 2px solid #98B45D; }
    .default .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(15) > div:hover span {
      border: 2px solid #ADADAD; }
    .default .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(16) > div:hover span {
      border: 2px solid #98A7AD; }
  .default .innercontent_wrapper .directLinkWrapper .iconWrapper {
    height: 390px;
    position: relative; }
    .default .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn {
      position: absolute;
      width: 26px;
      height: 27px;
      border: 1px solid #dedede;
      border-radius: 50%;
      top: -27px;
      right: 6px;
      cursor: pointer; }
      .default .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn img {
        margin-top: -2px;
        margin-left: 1px; }
      .default .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn.bottomScroll {
        top: auto;
        bottom: -27px; }
    .default .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow {
      margin-bottom: 13px;
      margin-right: 30px;
      border: 1px solid #dedede;
      border-radius: 10px;
      cursor: pointer; }
      .default .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div {
        width: 151px;
        height: 87px;
        background-color: #ffffff;
        margin-right: 12px;
        padding: 16px;
        border-radius: 10px; }
        .default .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div.no-image {
          opacity: 0; }
        .default .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div:last-child {
          margin-right: 0; }
      .default .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow:last-child {
        margin-bottom: 0; }
      .default .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow.selected {
        background-color: #dedede; }
        .default .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow.selected > div {
          background-color: #dedede; }
    .default .innercontent_wrapper .directLinkWrapper .iconWrapper .rcs-custom-scroll .rcs-inner-handle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #3ba8c5;
      background-color: #3ba8c5;
      margin-left: -8px; }
    .default .innercontent_wrapper .directLinkWrapper .iconWrapper .rcs-custom-scroll .rcs-custom-scrollbar {
      opacity: 1;
      right: 13px;
      border-left: 2px solid #dedede;
      height: 590px; }
  .default .innercontent_wrapper .directLinkWrapper .uploadBtn {
    margin-top: 13px; }
    .default .innercontent_wrapper .directLinkWrapper .uploadBtn.iconUpload {
      margin-top: 5px;
      margin-left: 6px;
      float: left; }
    .default .innercontent_wrapper .directLinkWrapper .uploadBtn button {
      width: 218px;
      height: 29px;
      border-radius: 3px;
      border: 1px solid #3ba8c5;
      background-color: #ffffff;
      position: relative;
      padding: 0; }
      .default .innercontent_wrapper .directLinkWrapper .uploadBtn button.uploadIcon {
        width: auto;
        float: left;
        padding: 0 5px; }
        .default .innercontent_wrapper .directLinkWrapper .uploadBtn button.uploadIcon input {
          width: auto; }
      .default .innercontent_wrapper .directLinkWrapper .uploadBtn button p {
        margin: 0;
        color: #3ba8c5;
        font-family: "Segoe UI";
        font-size: 13px;
        font-weight: 700;
        line-height: 30.4px;
        text-transform: uppercase; }
        .default .innercontent_wrapper .directLinkWrapper .uploadBtn button p img {
          margin-right: 4px;
          margin-top: -4px; }
      .default .innercontent_wrapper .directLinkWrapper .uploadBtn button input {
        position: absolute;
        top: 0;
        left: 0;
        width: 218px;
        height: 29px;
        opacity: 0;
        cursor: pointer; }
  .default .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span {
    margin-right: 15px;
    color: #3ba8c5;
    text-decoration: underline;
    cursor: pointer; }
    .default .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span.selected {
      text-decoration: none;
      color: #000000; }

.default .carousel {
  padding: 12px 0px; }
  .default .carousel img.carouselBtn {
    position: absolute;
    cursor: pointer;
    top: 90px; }
    .default .carousel img.carouselBtn.carousel-left {
      left: 30px; }
  .default .carousel ul {
    margin-left: 0;
    margin-right: 0;
    transition: all 1s; }
    .default .carousel ul.widgetMode li {
      opacity: 0.5;
      float: left; }
      .default .carousel ul.widgetMode li.selected, .default .carousel ul.widgetMode li:hover {
        opacity: 1;
        border: 3px solid #3ba8c5; }
        .default .carousel ul.widgetMode li.selected h4, .default .carousel ul.widgetMode li:hover h4 {
          background-color: #3ba8c5;
          color: #ffffff; }
  .default .carousel .carousel-cell {
    cursor: pointer;
    width: 210px;
    height: 192px;
    box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
    border-radius: 20px;
    background-color: #ffffff;
    padding: 0px;
    display: inline-block;
    margin-right: 20px;
    float: left; }
    .default .carousel .carousel-cell.opacity_0 {
      opacity: 0;
      margin-right: 60px;
      transition: opacity 2s; }
    .default .carousel .carousel-cell:hover {
      border: 3px solid #3ba8c5; }
      .default .carousel .carousel-cell:hover h4 {
        background-color: #3ba8c5;
        color: #ffffff; }
    .default .carousel .carousel-cell h4 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      padding: 22px 0 18px;
      border-bottom: 4px solid #3ba8c5;
      margin: 0;
      border-radius: 15px 15px 0 0; }
    .default .carousel .carousel-cell .img-wrapper {
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      width: 210px; }

.default .settingFormWrapper .codeWrapper {
  margin-top: 69px; }
  .default .settingFormWrapper .codeWrapper p {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    text-align: left; }
  .default .settingFormWrapper .codeWrapper textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    padding: 13px;
    margin-bottom: 10px; }
  .default .settingFormWrapper .codeWrapper .formBtns {
    padding: 0;
    margin: 0;
    border: none; }
    .default .settingFormWrapper .codeWrapper .formBtns Button.btn_clr {
      margin-left: 0;
      margin-right: 10px;
      width: auto;
      padding: 9px 30px; }
      .default .settingFormWrapper .codeWrapper .formBtns Button.btn_clr p {
        text-align: center;
        color: #3ba8c5; }
  .default .settingFormWrapper .codeWrapper .advancedSection {
    margin-top: -48px; }
    .default .settingFormWrapper .codeWrapper .advancedSection .advancedOption p {
      color: #3ba8c5;
      font-family: "Segoe UI Bold";
      font-size: 14px;
      font-weight: 700; }
      .default .settingFormWrapper .codeWrapper .advancedSection .advancedOption p:first-child {
        width: 27px;
        height: 27px;
        border-radius: 50%;
        border: 1px solid #3ba8c5;
        background-color: #3ba8c5;
        background-image: url("assets/images/expand.png");
        background-repeat: no-repeat;
        background-position: center;
        padding: 0;
        float: left;
        margin-right: 9px; }
      .default .settingFormWrapper .codeWrapper .advancedSection .advancedOption p:last-child {
        margin-top: 20px; }
    .default .settingFormWrapper .codeWrapper .advancedSection .advancedOption.collapsed p:first-child {
      border: 1px solid #dedede;
      background-color: #ffffff;
      background-image: url("assets/images/plus_icons/plus_blue.png");
      background-size: 11px; }
    .default .settingFormWrapper .codeWrapper .advancedSection .advancedOption.dropdown-toggle::after {
      display: none; }

.default .mailer .modal-dialog textarea {
  border-radius: 5px;
  border: 1px solid #dedede;
  padding: 13px;
  width: 367px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px; }

.default .mailer .modal-dialog label {
  text-align: left;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.default .mailer .modal-dialog .form-control {
  width: 256px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.default .toolBarWrapper .toolTipDesignWrapper {
  border-radius: 10px;
  background-color: #f6f6f6;
  padding: 20px 20px 4px; }

.default .toolBarWrapper .toolBarMenuWrapper {
  height: 53px;
  margin-bottom: 36px;
  position: relative; }
  .default .toolBarWrapper .toolBarMenuWrapper .toolBarBoxWrapper {
    position: absolute;
    height: 13px;
    width: 100%;
    top: -14px;
    left: 0;
    background-color: #fff;
    z-index: 7; }
    .default .toolBarWrapper .toolBarMenuWrapper .toolBarBoxWrapper.bottom {
      top: auto;
      bottom: -13px; }
  .default .toolBarWrapper .toolBarMenuWrapper .toolBarBox {
    position: absolute;
    border: 1px solid #dedede;
    height: 54px;
    width: 98%;
    border-right: 0;
    top: -1px;
    z-index: 5;
    left: 0;
    pointer-events: none; }
  .default .toolBarWrapper .toolBarMenuWrapper .menuList {
    height: 54px;
    background-color: #fff;
    cursor: pointer; }
    .default .toolBarWrapper .toolBarMenuWrapper .menuList.selected {
      background-color: #3ba8c5; }
      .default .toolBarWrapper .toolBarMenuWrapper .menuList.selected p {
        color: #fff; }
      .default .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line1 {
        background-color: #fff;
        border-color: #3ba8c5; }
      .default .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line2 {
        border-color: #3ba8c5; }
        .default .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line2.mask {
          opacity: 1;
          background-color: #fff; }
    .default .toolBarWrapper .toolBarMenuWrapper .menuList p {
      width: 100%;
      float: left;
      color: #293037;
      font-family: "Segoe UI Semi Bold";
      font-size: 13px;
      font-weight: 700; }
    .default .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper {
      position: absolute;
      right: 21px; }
      .default .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line1 {
        width: 35px;
        height: 20px;
        border-bottom: 1px solid #dedede;
        -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
        position: absolute; }
        .default .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line1.selectedNext {
          background-color: #3ba8c5;
          border-color: #fff; }
      .default .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2 {
        width: 33px;
        height: 20px;
        border-bottom: 1px solid #dedede;
        -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
        position: absolute;
        top: 17px;
        left: -16px; }
        .default .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.mask {
          opacity: 0;
          left: -1px;
          width: 37px;
          top: 29px;
          background-color: #fff;
          border: 0; }
          .default .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.mask.selectedNext {
            opacity: 1;
            background-color: #3ba8c5; }
        .default .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.selectedNext {
          border-color: #fff; }

.default .toolBarWrapper .form-label.chatWindow {
  padding-top: 3px; }

.default .toolBarWrapper .chat_windowWrapper .btn-group-toggle {
  display: inline-block; }
  .default .toolBarWrapper .chat_windowWrapper .btn-group-toggle .custom_radionBtn {
    font-size: 14px;
    font-family: 'Segoe UI Semi Bold'; }
  .default .toolBarWrapper .chat_windowWrapper .btn-group-toggle > label {
    margin-bottom: 17px;
    text-align: left; }
    .default .toolBarWrapper .chat_windowWrapper .btn-group-toggle > label:last-child {
      margin-bottom: 14px; }

.default .toolBarWrapper .settingFormWrapper .customSize .form-label {
  font-size: 13px; }

.default .toolBarWrapper .settingFormWrapper .customSize .form-control {
  width: 62px;
  height: 29px;
  border-radius: 3px;
  float: left;
  margin-right: 12px;
  font-size: 13px; }

.default .toolBarWrapper .settingFormWrapper .customSize span {
  float: left;
  margin-top: 6px;
  font-size: 13px; }

.default .toolBarWrapper .toolBarPreviewWrapper {
  height: 302px;
  width: 418px;
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("assets/images/toolBarPreviewBg.png");
  position: relative; }
  .default .toolBarWrapper .toolBarPreviewWrapper .positionIcons {
    position: absolute;
    border: 1px solid #dedede;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    cursor: pointer; }
    .default .toolBarWrapper .toolBarPreviewWrapper .positionIcons.selected {
      background-color: #3ba8c5; }
    .default .toolBarWrapper .toolBarPreviewWrapper .positionIcons.left {
      left: -11px;
      top: 44%;
      padding-right: 2px;
      padding-top: 1px; }
    .default .toolBarWrapper .toolBarPreviewWrapper .positionIcons.right {
      right: -11px;
      top: 44%;
      padding-left: 2px;
      padding-top: 1px; }
    .default .toolBarWrapper .toolBarPreviewWrapper .positionIcons.center {
      bottom: -30px;
      left: 50%;
      padding-top: 2px;
      padding-left: 1px; }
    .default .toolBarWrapper .toolBarPreviewWrapper .positionIcons.bottomLeft {
      bottom: -28px;
      left: -11px;
      padding-left: 4px; }
    .default .toolBarWrapper .toolBarPreviewWrapper .positionIcons.bottomRight {
      bottom: -28px;
      right: -11px;
      padding-right: 4px; }
  .default .toolBarWrapper .toolBarPreviewWrapper .toolBarWrapper {
    width: 366px;
    height: 250px;
    position: absolute;
    top: 25px;
    left: 25px; }
  .default .toolBarWrapper .toolBarPreviewWrapper .ulWrapper {
    position: absolute;
    max-width: 366px;
    max-height: 250px;
    overflow: auto; }
    .default .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.bar_vertical .btn_wrapper {
      height: 54px; }
      .default .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.bar_vertical .btn_wrapper .btn_icon.HelpOnClick-WithText {
        margin-left: auto;
        margin-right: auto; }
    .default .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.transform {
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
  .default .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns {
    list-style: none;
    height: 41px;
    padding: 0;
    margin: 0;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
    .default .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper {
      border-right: 1px solid;
      border-left: 1px solid;
      height: 100%;
      float: left;
      min-width: 30px;
      min-height: 30px;
      margin-bottom: 0;
      display: table; }
      .default .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper:first-child {
        border-left: 0; }
      .default .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper:last-child {
        border-right: 0; }
      .default .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div, .default .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a {
        display: table-cell;
        vertical-align: middle; }
        .default .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div div.preview_row, .default .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a div.preview_row {
          display: table-row;
          height: 100%;
          width: 100%; }
        .default .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div div.preview_clmn, .default .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a div.preview_clmn {
          display: table-cell;
          vertical-align: middle; }
        .default .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div > div, .default .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div {
          display: inline-table;
          vertical-align: middle;
          height: 100%; }
      .default .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_icon {
        margin-top: -1px;
        margin-left: 14px;
        margin-right: 14px; }
        .default .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_icon.HelpOnClick-WithText {
          margin-left: 12px;
          margin-right: 0; }
      .default .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_caption {
        color: #fff;
        display: table-cell;
        font-weight: 700;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 8px;
        padding-right: 15px;
        vertical-align: middle; }

.default .toolBarWrapper .Tooltip__Base-sc-19cbsjq-0 {
  width: 200px !important; }

.default .toolBarWrapper .Tooltip__Left-sc-19cbsjq-3 {
  right: 20px; }

.default .toolBarWrapper .customDrpDwn.customDrpDwn-small .css-10nd86i {
  width: 141px;
  float: left; }

.default .toolBarWrapper .customDrpDwn.customDrpDwn-small .customSize .form-control {
  width: 51px;
  height: 37px;
  margin-right: 9px; }

.default .toolBarWrapper .customDrpDwn.customDrpDwn-small .customSize span {
  color: #7d7d7d;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }

.default .toolBarWrapper .buttonSettingsWrapper {
  box-shadow: none;
  border-radius: 0;
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content; }
  .default .toolBarWrapper .buttonSettingsWrapper .buttonsSection {
    margin-left: 7%;
    border-radius: 10px;
    background-color: #f6f6f6;
    padding: 26px 20px 15px; }
    .default .toolBarWrapper .buttonSettingsWrapper .buttonsSection .editorWrapper .editorToolBar .editorLink {
      display: none; }
  .default .toolBarWrapper .buttonSettingsWrapper .customDrpDwn .css-15k3avv {
    z-index: 10; }
  .default .toolBarWrapper .buttonSettingsWrapper textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    background-color: #ffffff;
    padding: 15px;
    color: #000000;
    font-family: 'Segoe UI Semi Bold';
    font-size: 14px;
    font-weight: 400; }

.default .toolBarWrapper .forIcon .css-10nd86i {
  float: left; }

.default .toolBarWrapper .forIcon .formBtns {
  padding: 0;
  border-top: none;
  margin: 0;
  float: left; }
  .default .toolBarWrapper .forIcon .formBtns Button {
    margin-left: 6px;
    padding-left: 9px;
    padding-right: 9px; }

.default .toolBarWrapper .invitationColorPicker > div {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }
  .default .toolBarWrapper .invitationColorPicker > div > div.colorSelector {
    width: 256px; }
  .default .toolBarWrapper .invitationColorPicker > div > div:nth-child(2) {
    width: 256px;
    position: absolute !important;
    z-index: 2;
    left: 15px; }
    .default .toolBarWrapper .invitationColorPicker > div > div:nth-child(2) .flexbox-fix {
      opacity: 0; }

.default .toolBarWrapper .previewMargin.customSize {
  width: 187px;
  margin-top: 36px;
  margin: 36px auto 0; }

.default .HelpOnClick-FontIconsLibrary {
  max-height: 70vh; }

.default .toolbarInvitationPreview {
  height: 150px;
  width: 320px;
  border: 1px solid #035d85;
  border-radius: 5px;
  background-color: #eeeeee;
  margin: 56px auto; }
  .default .toolbarInvitationPreview .invitationMessageWrapper {
    height: 60px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 5px;
    margin-top: 23px;
    font-size: 16px; }
  .default .toolbarInvitationPreview button.close {
    margin-right: 3px; }
  .default .toolbarInvitationPreview button.btn_chat {
    font-size: 14px;
    width: auto;
    display: block;
    padding: 11px 15px;
    border: 1px solid #7e7c7c;
    border-radius: 5px;
    cursor: pointer;
    float: none;
    margin: 0 auto; }

.default .imageLibrary img {
  padding: 4px;
  cursor: pointer; }
  .default .imageLibrary img.selected {
    background-color: #e6eef9; }

.steelBlue .widgetWrapper h4.widgetName {
  color: #3ba8c5;
  margin-right: 22px; }

.steelBlue .widgetWrapper .exit_btn {
  position: absolute;
  right: 37px;
  color: #3ba8c5;
  font-family: "Segoe UI";
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer; }
  .steelBlue .widgetWrapper .exit_btn img {
    margin-right: 8px;
    margin-top: -3px; }

.steelBlue .widgetSelectorIndicator {
  margin: 30px auto 0; }
  .steelBlue .widgetSelectorIndicator p {
    color: #a1a4a8;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    margin-top: 25px; }

.steelBlue .innercontent_wrapper .directLinkWrapper {
  margin-top: 56px; }
  .steelBlue .innercontent_wrapper .directLinkWrapper h4 {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    margin-left: 18px;
    margin-bottom: 34px;
    position: relative; }
    .steelBlue .innercontent_wrapper .directLinkWrapper h4 span {
      border: 1px solid #3ba8c5;
      background-color: #ffffff;
      border-radius: 50%;
      position: absolute;
      margin-left: 9px;
      width: 24px;
      height: 24px;
      display: inline-block; }
      .steelBlue .innercontent_wrapper .directLinkWrapper h4 span img {
        position: absolute;
        top: 5px;
        left: 10px; }
  .steelBlue .innercontent_wrapper .directLinkWrapper .with_btn input {
    float: left; }
  .steelBlue .innercontent_wrapper .directLinkWrapper .with_btn .uploadBtn button {
    background-color: #3ba8c5; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .with_btn .uploadBtn button p {
      color: #fff; }
  .steelBlue .innercontent_wrapper .directLinkWrapper .invitationCustomImg {
    margin-bottom: 0.5rem; }
  .steelBlue .innercontent_wrapper .directLinkWrapper .btn_wrapper {
    margin-bottom: 15px; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .btn_wrapper .uploadBtn.iconUpload {
      margin-left: 0;
      margin-right: 5px; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .btn_wrapper .uploadBtn.iconUpload button.uploadIcon input {
        width: 60px; }
  .steelBlue .innercontent_wrapper .directLinkWrapper .box {
    padding: 30px; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:first-child {
      width: 131px;
      margin-right: 10px; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:nth-child(2) {
      width: 117px; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:nth-child(2) .css-10nd86i {
        width: 117px; }
  .steelBlue .innercontent_wrapper .directLinkWrapper .formBtns Button.btn_save {
    width: auto; }
  .steelBlue .innercontent_wrapper .directLinkWrapper .customOption .row > div:nth-child(2) > div:nth-child(2) {
    position: absolute !important;
    z-index: 2;
    top: 44px;
    left: 157px; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .customOption .row > div:nth-child(2) > div:nth-child(2) .flexbox-fix {
      display: none !important; }
  .steelBlue .innercontent_wrapper .directLinkWrapper .colorSelector .css-1wy0on6 {
    background-color: #fff; }
  .steelBlue .innercontent_wrapper .directLinkWrapper .colorSelector input {
    border: none; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .colorSelector input:focus {
      border: none; }
  .steelBlue .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper {
    position: relative; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper p {
      width: 178px;
      float: left;
      padding-top: 30%;
      font-size: 22px;
      text-align: right;
      text-decoration: underline; }
  .steelBlue .innercontent_wrapper .directLinkWrapper .directLinkpreview {
    background-repeat: no-repeat;
    width: 165px;
    height: 114px;
    float: right;
    margin-top: 100px;
    margin-right: 50px;
    margin-left: 24px;
    background-size: cover !important; }
  .steelBlue .innercontent_wrapper .directLinkWrapper .theme {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-top: 7px; }
  .steelBlue .innercontent_wrapper .directLinkWrapper .preview {
    width: 330px;
    height: 101px;
    padding: 12px 0 0 0;
    margin-top: 40px;
    margin-bottom: 47px;
    background-repeat: no-repeat; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .preview .widget-preview {
      width: 166px;
      height: 76px; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .preview .widget-preview div {
        font-family: Arial,Helvetica,sans-serif; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-title {
        padding: 11px 0 0 18px;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        text-align: left; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-text {
        width: 146px;
        overflow: hidden;
        white-space: nowrap;
        padding: 1px 0 0;
        text-align: center;
        color: #A8A8A8;
        font-size: 12px;
        margin-left: 10px; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-copy {
        padding: 8px 13px 0;
        text-align: right;
        font-size: 10px;
        color: #fff;
        opacity: .5;
        -moz-opacity: .5;
        -khtml-opacity: .5;
        filter: alpha(opacity=50); }
  .steelBlue .innercontent_wrapper .directLinkWrapper .widgetPosition {
    width: 330px; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .widgetPosition p {
      width: 236px;
      height: 30px;
      border-radius: 5px;
      border: 1px solid #dedede;
      background-color: #ffffff;
      color: #000000;
      font-family: Segoe UI;
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 37px;
      margin-left: auto;
      margin-right: auto; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .widgetPosition p span {
        width: 50%;
        display: inline-block;
        padding: 6px 0;
        cursor: pointer; }
        .steelBlue .innercontent_wrapper .directLinkWrapper .widgetPosition p span:first-child {
          border-radius: 5px 0 0 5px; }
        .steelBlue .innercontent_wrapper .directLinkWrapper .widgetPosition p span:last-child {
          border-radius: 0 5px 5px 0; }
        .steelBlue .innercontent_wrapper .directLinkWrapper .widgetPosition p span.selected {
          color: #ffffff;
          background-color: #3ba8c5; }
  .steelBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper {
    width: 90%;
    margin: 0 auto; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp {
      width: 34px;
      height: 34px;
      border: 1px solid #dedede;
      border-radius: 50%;
      float: left;
      padding: 3px;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp div {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        margin: 0; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:hover, .steelBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp.selected {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        transition: -webkit-transform 100ms ease 0s;
        transition: transform 100ms ease 0s;
        transition: transform 100ms ease 0s, -webkit-transform 100ms ease 0s;
        padding: 2px; }
        .steelBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:hover div, .steelBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp.selected div {
          -webkit-transform: scale(0.9);
                  transform: scale(0.9); }
      .steelBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(1):hover {
        border: 2px solid #0475A8; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(2):hover {
        border: 2px solid #5F8706; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(3):hover {
        border: 2px solid #D6680B; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(4):hover {
        border: 2px solid #B0097D; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(5):hover {
        border: 2px solid #9D0A09; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(6):hover {
        border: 2px solid #424E54; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(7):hover {
        border: 2px solid #037FAF; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(8):hover {
        border: 2px solid #608706; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(9):hover {
        border: 2px solid #D26009; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(10):hover {
        border: 2px solid #AF097D; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(11):hover {
        border: 2px solid #950909; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(12):hover {
        border: 2px solid #3E484E; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(13):hover {
        border: 2px solid #0476A8; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(14):hover {
        border: 2px solid #98B45D; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(15):hover {
        border: 2px solid #ADADAD; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(16):hover {
        border: 2px solid #98A7AD; }
  .steelBlue .innercontent_wrapper .directLinkWrapper .circle-picker {
    padding: 0px 9px; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span > div > span {
      padding: 4px;
      position: relative;
      border: 1px solid #dedede;
      border-radius: 50%;
      display: inline-block;
      width: 34px;
      height: 34px; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(1) > div:hover span {
      border: 2px solid #0475A8; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(1) > div > span > div {
      box-shadow: #0475A8 0px 0px 0px 17px inset !important; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(2) > div:hover span {
      border: 2px solid #5F8706; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(3) > div:hover span {
      border: 2px solid #D6680B; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(4) > div:hover span {
      border: 2px solid #B0097D; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(5) > div:hover span {
      border: 2px solid #9D0A09; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(6) > div:hover span {
      border: 2px solid #424E54; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(7) > div:hover span {
      border: 2px solid #037FAF; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(8) > div:hover span {
      border: 2px solid #587D06; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(9) > div:hover span {
      border: 2px solid #D26009; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(10) > div:hover span {
      border: 2px solid #AF087C; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(11) > div:hover span {
      border: 2px solid #950909; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(12) > div:hover span {
      border: 2px solid #3E484E; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(13) > div:hover span {
      border: 2px solid #0476A8; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(14) > div:hover span {
      border: 2px solid #98B45D; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(15) > div:hover span {
      border: 2px solid #ADADAD; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(16) > div:hover span {
      border: 2px solid #98A7AD; }
  .steelBlue .innercontent_wrapper .directLinkWrapper .iconWrapper {
    height: 390px;
    position: relative; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn {
      position: absolute;
      width: 26px;
      height: 27px;
      border: 1px solid #dedede;
      border-radius: 50%;
      top: -27px;
      right: 6px;
      cursor: pointer; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn img {
        margin-top: -2px;
        margin-left: 1px; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn.bottomScroll {
        top: auto;
        bottom: -27px; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow {
      margin-bottom: 13px;
      margin-right: 30px;
      border: 1px solid #dedede;
      border-radius: 10px;
      cursor: pointer; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div {
        width: 151px;
        height: 87px;
        background-color: #ffffff;
        margin-right: 12px;
        padding: 16px;
        border-radius: 10px; }
        .steelBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div.no-image {
          opacity: 0; }
        .steelBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div:last-child {
          margin-right: 0; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow:last-child {
        margin-bottom: 0; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow.selected {
        background-color: #dedede; }
        .steelBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow.selected > div {
          background-color: #dedede; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .rcs-custom-scroll .rcs-inner-handle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #3ba8c5;
      background-color: #3ba8c5;
      margin-left: -8px; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .rcs-custom-scroll .rcs-custom-scrollbar {
      opacity: 1;
      right: 13px;
      border-left: 2px solid #dedede;
      height: 590px; }
  .steelBlue .innercontent_wrapper .directLinkWrapper .uploadBtn {
    margin-top: 13px; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .uploadBtn.iconUpload {
      margin-top: 5px;
      margin-left: 6px;
      float: left; }
    .steelBlue .innercontent_wrapper .directLinkWrapper .uploadBtn button {
      width: 218px;
      height: 29px;
      border-radius: 3px;
      border: 1px solid #3ba8c5;
      background-color: #ffffff;
      position: relative;
      padding: 0; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .uploadBtn button.uploadIcon {
        width: auto;
        float: left;
        padding: 0 5px; }
        .steelBlue .innercontent_wrapper .directLinkWrapper .uploadBtn button.uploadIcon input {
          width: auto; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .uploadBtn button p {
        margin: 0;
        color: #3ba8c5;
        font-family: "Segoe UI";
        font-size: 13px;
        font-weight: 700;
        line-height: 30.4px;
        text-transform: uppercase; }
        .steelBlue .innercontent_wrapper .directLinkWrapper .uploadBtn button p img {
          margin-right: 4px;
          margin-top: -4px; }
      .steelBlue .innercontent_wrapper .directLinkWrapper .uploadBtn button input {
        position: absolute;
        top: 0;
        left: 0;
        width: 218px;
        height: 29px;
        opacity: 0;
        cursor: pointer; }
  .steelBlue .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span {
    margin-right: 15px;
    color: #3ba8c5;
    text-decoration: underline;
    cursor: pointer; }
    .steelBlue .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span.selected {
      text-decoration: none;
      color: #000000; }

.steelBlue .carousel {
  padding: 12px 0px; }
  .steelBlue .carousel img.carouselBtn {
    position: absolute;
    cursor: pointer;
    top: 90px; }
    .steelBlue .carousel img.carouselBtn.carousel-left {
      left: 30px; }
  .steelBlue .carousel ul {
    margin-left: 0;
    margin-right: 0;
    transition: all 1s; }
    .steelBlue .carousel ul.widgetMode li {
      opacity: 0.5;
      float: left; }
      .steelBlue .carousel ul.widgetMode li.selected, .steelBlue .carousel ul.widgetMode li:hover {
        opacity: 1;
        border: 3px solid #3ba8c5; }
        .steelBlue .carousel ul.widgetMode li.selected h4, .steelBlue .carousel ul.widgetMode li:hover h4 {
          background-color: #3ba8c5;
          color: #ffffff; }
  .steelBlue .carousel .carousel-cell {
    cursor: pointer;
    width: 210px;
    height: 192px;
    box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
    border-radius: 20px;
    background-color: #ffffff;
    padding: 0px;
    display: inline-block;
    margin-right: 20px;
    float: left; }
    .steelBlue .carousel .carousel-cell.opacity_0 {
      opacity: 0;
      margin-right: 60px;
      transition: opacity 2s; }
    .steelBlue .carousel .carousel-cell:hover {
      border: 3px solid #3ba8c5; }
      .steelBlue .carousel .carousel-cell:hover h4 {
        background-color: #3ba8c5;
        color: #ffffff; }
    .steelBlue .carousel .carousel-cell h4 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      padding: 22px 0 18px;
      border-bottom: 4px solid #3ba8c5;
      margin: 0;
      border-radius: 15px 15px 0 0; }
    .steelBlue .carousel .carousel-cell .img-wrapper {
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      width: 210px; }

.steelBlue .settingFormWrapper .codeWrapper {
  margin-top: 69px; }
  .steelBlue .settingFormWrapper .codeWrapper p {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    text-align: left; }
  .steelBlue .settingFormWrapper .codeWrapper textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    padding: 13px;
    margin-bottom: 10px; }
  .steelBlue .settingFormWrapper .codeWrapper .formBtns {
    padding: 0;
    margin: 0;
    border: none; }
    .steelBlue .settingFormWrapper .codeWrapper .formBtns Button.btn_clr {
      margin-left: 0;
      margin-right: 10px;
      width: auto;
      padding: 9px 30px; }
      .steelBlue .settingFormWrapper .codeWrapper .formBtns Button.btn_clr p {
        text-align: center;
        color: #3ba8c5; }
  .steelBlue .settingFormWrapper .codeWrapper .advancedSection {
    margin-top: -48px; }
    .steelBlue .settingFormWrapper .codeWrapper .advancedSection .advancedOption p {
      color: #3ba8c5;
      font-family: "Segoe UI Bold";
      font-size: 14px;
      font-weight: 700; }
      .steelBlue .settingFormWrapper .codeWrapper .advancedSection .advancedOption p:first-child {
        width: 27px;
        height: 27px;
        border-radius: 50%;
        border: 1px solid #3ba8c5;
        background-color: #3ba8c5;
        background-image: url("assets/images/expand.png");
        background-repeat: no-repeat;
        background-position: center;
        padding: 0;
        float: left;
        margin-right: 9px; }
      .steelBlue .settingFormWrapper .codeWrapper .advancedSection .advancedOption p:last-child {
        margin-top: 20px; }
    .steelBlue .settingFormWrapper .codeWrapper .advancedSection .advancedOption.collapsed p:first-child {
      border: 1px solid #dedede;
      background-color: #ffffff;
      background-image: url("assets/images/plus_icons/plus_steelblue.png");
      background-size: 11px; }
    .steelBlue .settingFormWrapper .codeWrapper .advancedSection .advancedOption.dropdown-toggle::after {
      display: none; }

.steelBlue .mailer .modal-dialog textarea {
  border-radius: 5px;
  border: 1px solid #dedede;
  padding: 13px;
  width: 367px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px; }

.steelBlue .mailer .modal-dialog label {
  text-align: left;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.steelBlue .mailer .modal-dialog .form-control {
  width: 256px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.steelBlue .toolBarWrapper .toolTipDesignWrapper {
  border-radius: 10px;
  background-color: #f6f6f6;
  padding: 20px 20px 4px; }

.steelBlue .toolBarWrapper .toolBarMenuWrapper {
  height: 53px;
  margin-bottom: 36px;
  position: relative; }
  .steelBlue .toolBarWrapper .toolBarMenuWrapper .toolBarBoxWrapper {
    position: absolute;
    height: 13px;
    width: 100%;
    top: -14px;
    left: 0;
    background-color: #fff;
    z-index: 7; }
    .steelBlue .toolBarWrapper .toolBarMenuWrapper .toolBarBoxWrapper.bottom {
      top: auto;
      bottom: -13px; }
  .steelBlue .toolBarWrapper .toolBarMenuWrapper .toolBarBox {
    position: absolute;
    border: 1px solid #dedede;
    height: 54px;
    width: 98%;
    border-right: 0;
    top: -1px;
    z-index: 5;
    left: 0;
    pointer-events: none; }
  .steelBlue .toolBarWrapper .toolBarMenuWrapper .menuList {
    height: 54px;
    background-color: #fff;
    cursor: pointer; }
    .steelBlue .toolBarWrapper .toolBarMenuWrapper .menuList.selected {
      background-color: #3ba8c5; }
      .steelBlue .toolBarWrapper .toolBarMenuWrapper .menuList.selected p {
        color: #fff; }
      .steelBlue .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line1 {
        background-color: #fff;
        border-color: #3ba8c5; }
      .steelBlue .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line2 {
        border-color: #3ba8c5; }
        .steelBlue .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line2.mask {
          opacity: 1;
          background-color: #fff; }
    .steelBlue .toolBarWrapper .toolBarMenuWrapper .menuList p {
      width: 100%;
      float: left;
      color: #293037;
      font-family: "Segoe UI Semi Bold";
      font-size: 13px;
      font-weight: 700; }
    .steelBlue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper {
      position: absolute;
      right: 21px; }
      .steelBlue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line1 {
        width: 35px;
        height: 20px;
        border-bottom: 1px solid #dedede;
        -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
        position: absolute; }
        .steelBlue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line1.selectedNext {
          background-color: #3ba8c5;
          border-color: #fff; }
      .steelBlue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2 {
        width: 33px;
        height: 20px;
        border-bottom: 1px solid #dedede;
        -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
        position: absolute;
        top: 17px;
        left: -16px; }
        .steelBlue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.mask {
          opacity: 0;
          left: -1px;
          width: 37px;
          top: 29px;
          background-color: #fff;
          border: 0; }
          .steelBlue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.mask.selectedNext {
            opacity: 1;
            background-color: #3ba8c5; }
        .steelBlue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.selectedNext {
          border-color: #fff; }

.steelBlue .toolBarWrapper .form-label.chatWindow {
  padding-top: 3px; }

.steelBlue .toolBarWrapper .chat_windowWrapper .btn-group-toggle {
  display: inline-block; }
  .steelBlue .toolBarWrapper .chat_windowWrapper .btn-group-toggle .custom_radionBtn {
    font-size: 14px;
    font-family: 'Segoe UI Semi Bold'; }
  .steelBlue .toolBarWrapper .chat_windowWrapper .btn-group-toggle > label {
    margin-bottom: 17px;
    text-align: left; }
    .steelBlue .toolBarWrapper .chat_windowWrapper .btn-group-toggle > label:last-child {
      margin-bottom: 14px; }

.steelBlue .toolBarWrapper .settingFormWrapper .customSize .form-label {
  font-size: 13px; }

.steelBlue .toolBarWrapper .settingFormWrapper .customSize .form-control {
  width: 62px;
  height: 29px;
  border-radius: 3px;
  float: left;
  margin-right: 12px;
  font-size: 13px; }

.steelBlue .toolBarWrapper .settingFormWrapper .customSize span {
  float: left;
  margin-top: 6px;
  font-size: 13px; }

.steelBlue .toolBarWrapper .toolBarPreviewWrapper {
  height: 302px;
  width: 418px;
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("assets/images/toolBarPreviewBg.png");
  position: relative; }
  .steelBlue .toolBarWrapper .toolBarPreviewWrapper .positionIcons {
    position: absolute;
    border: 1px solid #dedede;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    cursor: pointer; }
    .steelBlue .toolBarWrapper .toolBarPreviewWrapper .positionIcons.selected {
      background-color: #3ba8c5; }
    .steelBlue .toolBarWrapper .toolBarPreviewWrapper .positionIcons.left {
      left: -11px;
      top: 44%;
      padding-right: 2px;
      padding-top: 1px; }
    .steelBlue .toolBarWrapper .toolBarPreviewWrapper .positionIcons.right {
      right: -11px;
      top: 44%;
      padding-left: 2px;
      padding-top: 1px; }
    .steelBlue .toolBarWrapper .toolBarPreviewWrapper .positionIcons.center {
      bottom: -30px;
      left: 50%;
      padding-top: 2px;
      padding-left: 1px; }
    .steelBlue .toolBarWrapper .toolBarPreviewWrapper .positionIcons.bottomLeft {
      bottom: -28px;
      left: -11px;
      padding-left: 4px; }
    .steelBlue .toolBarWrapper .toolBarPreviewWrapper .positionIcons.bottomRight {
      bottom: -28px;
      right: -11px;
      padding-right: 4px; }
  .steelBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarWrapper {
    width: 366px;
    height: 250px;
    position: absolute;
    top: 25px;
    left: 25px; }
  .steelBlue .toolBarWrapper .toolBarPreviewWrapper .ulWrapper {
    position: absolute;
    max-width: 366px;
    max-height: 250px;
    overflow: auto; }
    .steelBlue .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.bar_vertical .btn_wrapper {
      height: 54px; }
      .steelBlue .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.bar_vertical .btn_wrapper .btn_icon.HelpOnClick-WithText {
        margin-left: auto;
        margin-right: auto; }
    .steelBlue .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.transform {
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
  .steelBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns {
    list-style: none;
    height: 41px;
    padding: 0;
    margin: 0;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
    .steelBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper {
      border-right: 1px solid;
      border-left: 1px solid;
      height: 100%;
      float: left;
      min-width: 30px;
      min-height: 30px;
      margin-bottom: 0;
      display: table; }
      .steelBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper:first-child {
        border-left: 0; }
      .steelBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper:last-child {
        border-right: 0; }
      .steelBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div, .steelBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a {
        display: table-cell;
        vertical-align: middle; }
        .steelBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div div.preview_row, .steelBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a div.preview_row {
          display: table-row;
          height: 100%;
          width: 100%; }
        .steelBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div div.preview_clmn, .steelBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a div.preview_clmn {
          display: table-cell;
          vertical-align: middle; }
        .steelBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div > div, .steelBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div {
          display: inline-table;
          vertical-align: middle;
          height: 100%; }
      .steelBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_icon {
        margin-top: -1px;
        margin-left: 14px;
        margin-right: 14px; }
        .steelBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_icon.HelpOnClick-WithText {
          margin-left: 12px;
          margin-right: 0; }
      .steelBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_caption {
        color: #fff;
        display: table-cell;
        font-weight: 700;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 8px;
        padding-right: 15px;
        vertical-align: middle; }

.steelBlue .toolBarWrapper .Tooltip__Base-sc-19cbsjq-0 {
  width: 200px !important; }

.steelBlue .toolBarWrapper .Tooltip__Left-sc-19cbsjq-3 {
  right: 20px; }

.steelBlue .toolBarWrapper .customDrpDwn.customDrpDwn-small .css-10nd86i {
  width: 141px;
  float: left; }

.steelBlue .toolBarWrapper .customDrpDwn.customDrpDwn-small .customSize .form-control {
  width: 51px;
  height: 37px;
  margin-right: 9px; }

.steelBlue .toolBarWrapper .customDrpDwn.customDrpDwn-small .customSize span {
  color: #7d7d7d;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }

.steelBlue .toolBarWrapper .buttonSettingsWrapper {
  box-shadow: none;
  border-radius: 0;
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content; }
  .steelBlue .toolBarWrapper .buttonSettingsWrapper .buttonsSection {
    margin-left: 7%;
    border-radius: 10px;
    background-color: #f6f6f6;
    padding: 26px 20px 15px; }
    .steelBlue .toolBarWrapper .buttonSettingsWrapper .buttonsSection .editorWrapper .editorToolBar .editorLink {
      display: none; }
  .steelBlue .toolBarWrapper .buttonSettingsWrapper .customDrpDwn .css-15k3avv {
    z-index: 10; }
  .steelBlue .toolBarWrapper .buttonSettingsWrapper textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    background-color: #ffffff;
    padding: 15px;
    color: #000000;
    font-family: 'Segoe UI Semi Bold';
    font-size: 14px;
    font-weight: 400; }

.steelBlue .toolBarWrapper .forIcon .css-10nd86i {
  float: left; }

.steelBlue .toolBarWrapper .forIcon .formBtns {
  padding: 0;
  border-top: none;
  margin: 0;
  float: left; }
  .steelBlue .toolBarWrapper .forIcon .formBtns Button {
    margin-left: 6px;
    padding-left: 9px;
    padding-right: 9px; }

.steelBlue .toolBarWrapper .invitationColorPicker > div {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }
  .steelBlue .toolBarWrapper .invitationColorPicker > div > div.colorSelector {
    width: 256px; }
  .steelBlue .toolBarWrapper .invitationColorPicker > div > div:nth-child(2) {
    width: 256px;
    position: absolute !important;
    z-index: 2;
    left: 15px; }
    .steelBlue .toolBarWrapper .invitationColorPicker > div > div:nth-child(2) .flexbox-fix {
      opacity: 0; }

.steelBlue .toolBarWrapper .previewMargin.customSize {
  width: 187px;
  margin-top: 36px;
  margin: 36px auto 0; }

.steelBlue .HelpOnClick-FontIconsLibrary {
  max-height: 70vh; }

.steelBlue .toolbarInvitationPreview {
  height: 150px;
  width: 320px;
  border: 1px solid #035d85;
  border-radius: 5px;
  background-color: #eeeeee;
  margin: 56px auto; }
  .steelBlue .toolbarInvitationPreview .invitationMessageWrapper {
    height: 60px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 5px;
    margin-top: 23px;
    font-size: 16px; }
  .steelBlue .toolbarInvitationPreview button.close {
    margin-right: 3px; }
  .steelBlue .toolbarInvitationPreview button.btn_chat {
    font-size: 14px;
    width: auto;
    display: block;
    padding: 11px 15px;
    border: 1px solid #7e7c7c;
    border-radius: 5px;
    cursor: pointer;
    float: none;
    margin: 0 auto; }

.steelBlue .imageLibrary img {
  padding: 4px;
  cursor: pointer; }
  .steelBlue .imageLibrary img.selected {
    background-color: #e6eef9; }

.lightSeaGreen .widgetWrapper h4.widgetName {
  color: #3ba8c5;
  margin-right: 22px; }

.lightSeaGreen .widgetWrapper .exit_btn {
  position: absolute;
  right: 37px;
  color: #3ba8c5;
  font-family: "Segoe UI";
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer; }
  .lightSeaGreen .widgetWrapper .exit_btn img {
    margin-right: 8px;
    margin-top: -3px; }

.lightSeaGreen .widgetSelectorIndicator {
  margin: 30px auto 0; }
  .lightSeaGreen .widgetSelectorIndicator p {
    color: #a1a4a8;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    margin-top: 25px; }

.lightSeaGreen .innercontent_wrapper .directLinkWrapper {
  margin-top: 56px; }
  .lightSeaGreen .innercontent_wrapper .directLinkWrapper h4 {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    margin-left: 18px;
    margin-bottom: 34px;
    position: relative; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper h4 span {
      border: 1px solid #3ba8c5;
      background-color: #ffffff;
      border-radius: 50%;
      position: absolute;
      margin-left: 9px;
      width: 24px;
      height: 24px;
      display: inline-block; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper h4 span img {
        position: absolute;
        top: 5px;
        left: 10px; }
  .lightSeaGreen .innercontent_wrapper .directLinkWrapper .with_btn input {
    float: left; }
  .lightSeaGreen .innercontent_wrapper .directLinkWrapper .with_btn .uploadBtn button {
    background-color: #3ba8c5; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .with_btn .uploadBtn button p {
      color: #fff; }
  .lightSeaGreen .innercontent_wrapper .directLinkWrapper .invitationCustomImg {
    margin-bottom: 0.5rem; }
  .lightSeaGreen .innercontent_wrapper .directLinkWrapper .btn_wrapper {
    margin-bottom: 15px; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .btn_wrapper .uploadBtn.iconUpload {
      margin-left: 0;
      margin-right: 5px; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .btn_wrapper .uploadBtn.iconUpload button.uploadIcon input {
        width: 60px; }
  .lightSeaGreen .innercontent_wrapper .directLinkWrapper .box {
    padding: 30px; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:first-child {
      width: 131px;
      margin-right: 10px; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:nth-child(2) {
      width: 117px; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:nth-child(2) .css-10nd86i {
        width: 117px; }
  .lightSeaGreen .innercontent_wrapper .directLinkWrapper .formBtns Button.btn_save {
    width: auto; }
  .lightSeaGreen .innercontent_wrapper .directLinkWrapper .customOption .row > div:nth-child(2) > div:nth-child(2) {
    position: absolute !important;
    z-index: 2;
    top: 44px;
    left: 157px; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .customOption .row > div:nth-child(2) > div:nth-child(2) .flexbox-fix {
      display: none !important; }
  .lightSeaGreen .innercontent_wrapper .directLinkWrapper .colorSelector .css-1wy0on6 {
    background-color: #fff; }
  .lightSeaGreen .innercontent_wrapper .directLinkWrapper .colorSelector input {
    border: none; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .colorSelector input:focus {
      border: none; }
  .lightSeaGreen .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper {
    position: relative; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper p {
      width: 178px;
      float: left;
      padding-top: 30%;
      font-size: 22px;
      text-align: right;
      text-decoration: underline; }
  .lightSeaGreen .innercontent_wrapper .directLinkWrapper .directLinkpreview {
    background-repeat: no-repeat;
    width: 165px;
    height: 114px;
    float: right;
    margin-top: 100px;
    margin-right: 50px;
    margin-left: 24px;
    background-size: cover !important; }
  .lightSeaGreen .innercontent_wrapper .directLinkWrapper .theme {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-top: 7px; }
  .lightSeaGreen .innercontent_wrapper .directLinkWrapper .preview {
    width: 330px;
    height: 101px;
    padding: 12px 0 0 0;
    margin-top: 40px;
    margin-bottom: 47px;
    background-repeat: no-repeat; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .preview .widget-preview {
      width: 166px;
      height: 76px; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .preview .widget-preview div {
        font-family: Arial,Helvetica,sans-serif; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-title {
        padding: 11px 0 0 18px;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        text-align: left; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-text {
        width: 146px;
        overflow: hidden;
        white-space: nowrap;
        padding: 1px 0 0;
        text-align: center;
        color: #A8A8A8;
        font-size: 12px;
        margin-left: 10px; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-copy {
        padding: 8px 13px 0;
        text-align: right;
        font-size: 10px;
        color: #fff;
        opacity: .5;
        -moz-opacity: .5;
        -khtml-opacity: .5;
        filter: alpha(opacity=50); }
  .lightSeaGreen .innercontent_wrapper .directLinkWrapper .widgetPosition {
    width: 330px; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .widgetPosition p {
      width: 236px;
      height: 30px;
      border-radius: 5px;
      border: 1px solid #dedede;
      background-color: #ffffff;
      color: #000000;
      font-family: Segoe UI;
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 37px;
      margin-left: auto;
      margin-right: auto; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .widgetPosition p span {
        width: 50%;
        display: inline-block;
        padding: 6px 0;
        cursor: pointer; }
        .lightSeaGreen .innercontent_wrapper .directLinkWrapper .widgetPosition p span:first-child {
          border-radius: 5px 0 0 5px; }
        .lightSeaGreen .innercontent_wrapper .directLinkWrapper .widgetPosition p span:last-child {
          border-radius: 0 5px 5px 0; }
        .lightSeaGreen .innercontent_wrapper .directLinkWrapper .widgetPosition p span.selected {
          color: #ffffff;
          background-color: #3ba8c5; }
  .lightSeaGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper {
    width: 90%;
    margin: 0 auto; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp {
      width: 34px;
      height: 34px;
      border: 1px solid #dedede;
      border-radius: 50%;
      float: left;
      padding: 3px;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp div {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        margin: 0; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:hover, .lightSeaGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp.selected {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        transition: -webkit-transform 100ms ease 0s;
        transition: transform 100ms ease 0s;
        transition: transform 100ms ease 0s, -webkit-transform 100ms ease 0s;
        padding: 2px; }
        .lightSeaGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:hover div, .lightSeaGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp.selected div {
          -webkit-transform: scale(0.9);
                  transform: scale(0.9); }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(1):hover {
        border: 2px solid #0475A8; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(2):hover {
        border: 2px solid #5F8706; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(3):hover {
        border: 2px solid #D6680B; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(4):hover {
        border: 2px solid #B0097D; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(5):hover {
        border: 2px solid #9D0A09; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(6):hover {
        border: 2px solid #424E54; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(7):hover {
        border: 2px solid #037FAF; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(8):hover {
        border: 2px solid #608706; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(9):hover {
        border: 2px solid #D26009; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(10):hover {
        border: 2px solid #AF097D; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(11):hover {
        border: 2px solid #950909; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(12):hover {
        border: 2px solid #3E484E; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(13):hover {
        border: 2px solid #0476A8; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(14):hover {
        border: 2px solid #98B45D; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(15):hover {
        border: 2px solid #ADADAD; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(16):hover {
        border: 2px solid #98A7AD; }
  .lightSeaGreen .innercontent_wrapper .directLinkWrapper .circle-picker {
    padding: 0px 9px; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span > div > span {
      padding: 4px;
      position: relative;
      border: 1px solid #dedede;
      border-radius: 50%;
      display: inline-block;
      width: 34px;
      height: 34px; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(1) > div:hover span {
      border: 2px solid #0475A8; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(1) > div > span > div {
      box-shadow: #0475A8 0px 0px 0px 17px inset !important; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(2) > div:hover span {
      border: 2px solid #5F8706; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(3) > div:hover span {
      border: 2px solid #D6680B; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(4) > div:hover span {
      border: 2px solid #B0097D; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(5) > div:hover span {
      border: 2px solid #9D0A09; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(6) > div:hover span {
      border: 2px solid #424E54; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(7) > div:hover span {
      border: 2px solid #037FAF; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(8) > div:hover span {
      border: 2px solid #587D06; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(9) > div:hover span {
      border: 2px solid #D26009; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(10) > div:hover span {
      border: 2px solid #AF087C; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(11) > div:hover span {
      border: 2px solid #950909; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(12) > div:hover span {
      border: 2px solid #3E484E; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(13) > div:hover span {
      border: 2px solid #0476A8; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(14) > div:hover span {
      border: 2px solid #98B45D; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(15) > div:hover span {
      border: 2px solid #ADADAD; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(16) > div:hover span {
      border: 2px solid #98A7AD; }
  .lightSeaGreen .innercontent_wrapper .directLinkWrapper .iconWrapper {
    height: 390px;
    position: relative; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn {
      position: absolute;
      width: 26px;
      height: 27px;
      border: 1px solid #dedede;
      border-radius: 50%;
      top: -27px;
      right: 6px;
      cursor: pointer; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn img {
        margin-top: -2px;
        margin-left: 1px; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn.bottomScroll {
        top: auto;
        bottom: -27px; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow {
      margin-bottom: 13px;
      margin-right: 30px;
      border: 1px solid #dedede;
      border-radius: 10px;
      cursor: pointer; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div {
        width: 151px;
        height: 87px;
        background-color: #ffffff;
        margin-right: 12px;
        padding: 16px;
        border-radius: 10px; }
        .lightSeaGreen .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div.no-image {
          opacity: 0; }
        .lightSeaGreen .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div:last-child {
          margin-right: 0; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow:last-child {
        margin-bottom: 0; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow.selected {
        background-color: #dedede; }
        .lightSeaGreen .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow.selected > div {
          background-color: #dedede; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .iconWrapper .rcs-custom-scroll .rcs-inner-handle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #3ba8c5;
      background-color: #3ba8c5;
      margin-left: -8px; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .iconWrapper .rcs-custom-scroll .rcs-custom-scrollbar {
      opacity: 1;
      right: 13px;
      border-left: 2px solid #dedede;
      height: 590px; }
  .lightSeaGreen .innercontent_wrapper .directLinkWrapper .uploadBtn {
    margin-top: 13px; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .uploadBtn.iconUpload {
      margin-top: 5px;
      margin-left: 6px;
      float: left; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper .uploadBtn button {
      width: 218px;
      height: 29px;
      border-radius: 3px;
      border: 1px solid #3ba8c5;
      background-color: #ffffff;
      position: relative;
      padding: 0; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .uploadBtn button.uploadIcon {
        width: auto;
        float: left;
        padding: 0 5px; }
        .lightSeaGreen .innercontent_wrapper .directLinkWrapper .uploadBtn button.uploadIcon input {
          width: auto; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .uploadBtn button p {
        margin: 0;
        color: #3ba8c5;
        font-family: "Segoe UI";
        font-size: 13px;
        font-weight: 700;
        line-height: 30.4px;
        text-transform: uppercase; }
        .lightSeaGreen .innercontent_wrapper .directLinkWrapper .uploadBtn button p img {
          margin-right: 4px;
          margin-top: -4px; }
      .lightSeaGreen .innercontent_wrapper .directLinkWrapper .uploadBtn button input {
        position: absolute;
        top: 0;
        left: 0;
        width: 218px;
        height: 29px;
        opacity: 0;
        cursor: pointer; }
  .lightSeaGreen .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span {
    margin-right: 15px;
    color: #3ba8c5;
    text-decoration: underline;
    cursor: pointer; }
    .lightSeaGreen .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span.selected {
      text-decoration: none;
      color: #000000; }

.lightSeaGreen .carousel {
  padding: 12px 0px; }
  .lightSeaGreen .carousel img.carouselBtn {
    position: absolute;
    cursor: pointer;
    top: 90px; }
    .lightSeaGreen .carousel img.carouselBtn.carousel-left {
      left: 30px; }
  .lightSeaGreen .carousel ul {
    margin-left: 0;
    margin-right: 0;
    transition: all 1s; }
    .lightSeaGreen .carousel ul.widgetMode li {
      opacity: 0.5;
      float: left; }
      .lightSeaGreen .carousel ul.widgetMode li.selected, .lightSeaGreen .carousel ul.widgetMode li:hover {
        opacity: 1;
        border: 3px solid #3ba8c5; }
        .lightSeaGreen .carousel ul.widgetMode li.selected h4, .lightSeaGreen .carousel ul.widgetMode li:hover h4 {
          background-color: #3ba8c5;
          color: #ffffff; }
  .lightSeaGreen .carousel .carousel-cell {
    cursor: pointer;
    width: 210px;
    height: 192px;
    box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
    border-radius: 20px;
    background-color: #ffffff;
    padding: 0px;
    display: inline-block;
    margin-right: 20px;
    float: left; }
    .lightSeaGreen .carousel .carousel-cell.opacity_0 {
      opacity: 0;
      margin-right: 60px;
      transition: opacity 2s; }
    .lightSeaGreen .carousel .carousel-cell:hover {
      border: 3px solid #3ba8c5; }
      .lightSeaGreen .carousel .carousel-cell:hover h4 {
        background-color: #3ba8c5;
        color: #ffffff; }
    .lightSeaGreen .carousel .carousel-cell h4 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      padding: 22px 0 18px;
      border-bottom: 4px solid #3ba8c5;
      margin: 0;
      border-radius: 15px 15px 0 0; }
    .lightSeaGreen .carousel .carousel-cell .img-wrapper {
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      width: 210px; }

.lightSeaGreen .settingFormWrapper .codeWrapper {
  margin-top: 69px; }
  .lightSeaGreen .settingFormWrapper .codeWrapper p {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    text-align: left; }
  .lightSeaGreen .settingFormWrapper .codeWrapper textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    padding: 13px;
    margin-bottom: 10px; }
  .lightSeaGreen .settingFormWrapper .codeWrapper .formBtns {
    padding: 0;
    margin: 0;
    border: none; }
    .lightSeaGreen .settingFormWrapper .codeWrapper .formBtns Button.btn_clr {
      margin-left: 0;
      margin-right: 10px;
      width: auto;
      padding: 9px 30px; }
      .lightSeaGreen .settingFormWrapper .codeWrapper .formBtns Button.btn_clr p {
        text-align: center;
        color: #3ba8c5; }
  .lightSeaGreen .settingFormWrapper .codeWrapper .advancedSection {
    margin-top: -48px; }
    .lightSeaGreen .settingFormWrapper .codeWrapper .advancedSection .advancedOption p {
      color: #3ba8c5;
      font-family: "Segoe UI Bold";
      font-size: 14px;
      font-weight: 700; }
      .lightSeaGreen .settingFormWrapper .codeWrapper .advancedSection .advancedOption p:first-child {
        width: 27px;
        height: 27px;
        border-radius: 50%;
        border: 1px solid #4cb6ac;
        background-color: #4cb6ac;
        background-image: url("assets/images/expand.png");
        background-repeat: no-repeat;
        background-position: center;
        padding: 0;
        float: left;
        margin-right: 9px; }
      .lightSeaGreen .settingFormWrapper .codeWrapper .advancedSection .advancedOption p:last-child {
        margin-top: 20px; }
    .lightSeaGreen .settingFormWrapper .codeWrapper .advancedSection .advancedOption.collapsed p:first-child {
      border: 1px solid #dedede;
      background-color: #ffffff;
      background-image: url("assets/images/plus_icons/plus_seagreen.png");
      background-size: 11px; }
    .lightSeaGreen .settingFormWrapper .codeWrapper .advancedSection .advancedOption.dropdown-toggle::after {
      display: none; }

.lightSeaGreen .mailer .modal-dialog textarea {
  border-radius: 5px;
  border: 1px solid #dedede;
  padding: 13px;
  width: 367px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px; }

.lightSeaGreen .mailer .modal-dialog label {
  text-align: left;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.lightSeaGreen .mailer .modal-dialog .form-control {
  width: 256px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.lightSeaGreen .toolBarWrapper .toolTipDesignWrapper {
  border-radius: 10px;
  background-color: #f6f6f6;
  padding: 20px 20px 4px; }

.lightSeaGreen .toolBarWrapper .toolBarMenuWrapper {
  height: 53px;
  margin-bottom: 36px;
  position: relative; }
  .lightSeaGreen .toolBarWrapper .toolBarMenuWrapper .toolBarBoxWrapper {
    position: absolute;
    height: 13px;
    width: 100%;
    top: -14px;
    left: 0;
    background-color: #fff;
    z-index: 7; }
    .lightSeaGreen .toolBarWrapper .toolBarMenuWrapper .toolBarBoxWrapper.bottom {
      top: auto;
      bottom: -13px; }
  .lightSeaGreen .toolBarWrapper .toolBarMenuWrapper .toolBarBox {
    position: absolute;
    border: 1px solid #dedede;
    height: 54px;
    width: 98%;
    border-right: 0;
    top: -1px;
    z-index: 5;
    left: 0;
    pointer-events: none; }
  .lightSeaGreen .toolBarWrapper .toolBarMenuWrapper .menuList {
    height: 54px;
    background-color: #fff;
    cursor: pointer; }
    .lightSeaGreen .toolBarWrapper .toolBarMenuWrapper .menuList.selected {
      background-color: #3ba8c5; }
      .lightSeaGreen .toolBarWrapper .toolBarMenuWrapper .menuList.selected p {
        color: #fff; }
      .lightSeaGreen .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line1 {
        background-color: #fff;
        border-color: #3ba8c5; }
      .lightSeaGreen .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line2 {
        border-color: #3ba8c5; }
        .lightSeaGreen .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line2.mask {
          opacity: 1;
          background-color: #fff; }
    .lightSeaGreen .toolBarWrapper .toolBarMenuWrapper .menuList p {
      width: 100%;
      float: left;
      color: #293037;
      font-family: "Segoe UI Semi Bold";
      font-size: 13px;
      font-weight: 700; }
    .lightSeaGreen .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper {
      position: absolute;
      right: 21px; }
      .lightSeaGreen .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line1 {
        width: 35px;
        height: 20px;
        border-bottom: 1px solid #dedede;
        -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
        position: absolute; }
        .lightSeaGreen .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line1.selectedNext {
          background-color: #3ba8c5;
          border-color: #fff; }
      .lightSeaGreen .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2 {
        width: 33px;
        height: 20px;
        border-bottom: 1px solid #dedede;
        -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
        position: absolute;
        top: 17px;
        left: -16px; }
        .lightSeaGreen .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.mask {
          opacity: 0;
          left: -1px;
          width: 37px;
          top: 29px;
          background-color: #fff;
          border: 0; }
          .lightSeaGreen .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.mask.selectedNext {
            opacity: 1;
            background-color: #3ba8c5; }
        .lightSeaGreen .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.selectedNext {
          border-color: #fff; }

.lightSeaGreen .toolBarWrapper .form-label.chatWindow {
  padding-top: 3px; }

.lightSeaGreen .toolBarWrapper .chat_windowWrapper .btn-group-toggle {
  display: inline-block; }
  .lightSeaGreen .toolBarWrapper .chat_windowWrapper .btn-group-toggle .custom_radionBtn {
    font-size: 14px;
    font-family: 'Segoe UI Semi Bold'; }
  .lightSeaGreen .toolBarWrapper .chat_windowWrapper .btn-group-toggle > label {
    margin-bottom: 17px;
    text-align: left; }
    .lightSeaGreen .toolBarWrapper .chat_windowWrapper .btn-group-toggle > label:last-child {
      margin-bottom: 14px; }

.lightSeaGreen .toolBarWrapper .settingFormWrapper .customSize .form-label {
  font-size: 13px; }

.lightSeaGreen .toolBarWrapper .settingFormWrapper .customSize .form-control {
  width: 62px;
  height: 29px;
  border-radius: 3px;
  float: left;
  margin-right: 12px;
  font-size: 13px; }

.lightSeaGreen .toolBarWrapper .settingFormWrapper .customSize span {
  float: left;
  margin-top: 6px;
  font-size: 13px; }

.lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper {
  height: 302px;
  width: 418px;
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("assets/images/toolBarPreviewBg.png");
  position: relative; }
  .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .positionIcons {
    position: absolute;
    border: 1px solid #dedede;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    cursor: pointer; }
    .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .positionIcons.selected {
      background-color: #3ba8c5; }
    .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .positionIcons.left {
      left: -11px;
      top: 44%;
      padding-right: 2px;
      padding-top: 1px; }
    .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .positionIcons.right {
      right: -11px;
      top: 44%;
      padding-left: 2px;
      padding-top: 1px; }
    .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .positionIcons.center {
      bottom: -30px;
      left: 50%;
      padding-top: 2px;
      padding-left: 1px; }
    .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .positionIcons.bottomLeft {
      bottom: -28px;
      left: -11px;
      padding-left: 4px; }
    .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .positionIcons.bottomRight {
      bottom: -28px;
      right: -11px;
      padding-right: 4px; }
  .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarWrapper {
    width: 366px;
    height: 250px;
    position: absolute;
    top: 25px;
    left: 25px; }
  .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .ulWrapper {
    position: absolute;
    max-width: 366px;
    max-height: 250px;
    overflow: auto; }
    .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.bar_vertical .btn_wrapper {
      height: 54px; }
      .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.bar_vertical .btn_wrapper .btn_icon.HelpOnClick-WithText {
        margin-left: auto;
        margin-right: auto; }
    .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.transform {
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
  .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns {
    list-style: none;
    height: 41px;
    padding: 0;
    margin: 0;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
    .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper {
      border-right: 1px solid;
      border-left: 1px solid;
      height: 100%;
      float: left;
      min-width: 30px;
      min-height: 30px;
      margin-bottom: 0;
      display: table; }
      .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper:first-child {
        border-left: 0; }
      .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper:last-child {
        border-right: 0; }
      .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div, .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a {
        display: table-cell;
        vertical-align: middle; }
        .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div div.preview_row, .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a div.preview_row {
          display: table-row;
          height: 100%;
          width: 100%; }
        .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div div.preview_clmn, .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a div.preview_clmn {
          display: table-cell;
          vertical-align: middle; }
        .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div > div, .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div {
          display: inline-table;
          vertical-align: middle;
          height: 100%; }
      .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_icon {
        margin-top: -1px;
        margin-left: 14px;
        margin-right: 14px; }
        .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_icon.HelpOnClick-WithText {
          margin-left: 12px;
          margin-right: 0; }
      .lightSeaGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_caption {
        color: #fff;
        display: table-cell;
        font-weight: 700;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 8px;
        padding-right: 15px;
        vertical-align: middle; }

.lightSeaGreen .toolBarWrapper .Tooltip__Base-sc-19cbsjq-0 {
  width: 200px !important; }

.lightSeaGreen .toolBarWrapper .Tooltip__Left-sc-19cbsjq-3 {
  right: 20px; }

.lightSeaGreen .toolBarWrapper .customDrpDwn.customDrpDwn-small .css-10nd86i {
  width: 141px;
  float: left; }

.lightSeaGreen .toolBarWrapper .customDrpDwn.customDrpDwn-small .customSize .form-control {
  width: 51px;
  height: 37px;
  margin-right: 9px; }

.lightSeaGreen .toolBarWrapper .customDrpDwn.customDrpDwn-small .customSize span {
  color: #7d7d7d;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }

.lightSeaGreen .toolBarWrapper .buttonSettingsWrapper {
  box-shadow: none;
  border-radius: 0;
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content; }
  .lightSeaGreen .toolBarWrapper .buttonSettingsWrapper .buttonsSection {
    margin-left: 7%;
    border-radius: 10px;
    background-color: #f6f6f6;
    padding: 26px 20px 15px; }
    .lightSeaGreen .toolBarWrapper .buttonSettingsWrapper .buttonsSection .editorWrapper .editorToolBar .editorLink {
      display: none; }
  .lightSeaGreen .toolBarWrapper .buttonSettingsWrapper .customDrpDwn .css-15k3avv {
    z-index: 10; }
  .lightSeaGreen .toolBarWrapper .buttonSettingsWrapper textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    background-color: #ffffff;
    padding: 15px;
    color: #000000;
    font-family: 'Segoe UI Semi Bold';
    font-size: 14px;
    font-weight: 400; }

.lightSeaGreen .toolBarWrapper .forIcon .css-10nd86i {
  float: left; }

.lightSeaGreen .toolBarWrapper .forIcon .formBtns {
  padding: 0;
  border-top: none;
  margin: 0;
  float: left; }
  .lightSeaGreen .toolBarWrapper .forIcon .formBtns Button {
    margin-left: 6px;
    padding-left: 9px;
    padding-right: 9px; }

.lightSeaGreen .toolBarWrapper .invitationColorPicker > div {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }
  .lightSeaGreen .toolBarWrapper .invitationColorPicker > div > div.colorSelector {
    width: 256px; }
  .lightSeaGreen .toolBarWrapper .invitationColorPicker > div > div:nth-child(2) {
    width: 256px;
    position: absolute !important;
    z-index: 2;
    left: 15px; }
    .lightSeaGreen .toolBarWrapper .invitationColorPicker > div > div:nth-child(2) .flexbox-fix {
      opacity: 0; }

.lightSeaGreen .toolBarWrapper .previewMargin.customSize {
  width: 187px;
  margin-top: 36px;
  margin: 36px auto 0; }

.lightSeaGreen .HelpOnClick-FontIconsLibrary {
  max-height: 70vh; }

.lightSeaGreen .toolbarInvitationPreview {
  height: 150px;
  width: 320px;
  border: 1px solid #035d85;
  border-radius: 5px;
  background-color: #eeeeee;
  margin: 56px auto; }
  .lightSeaGreen .toolbarInvitationPreview .invitationMessageWrapper {
    height: 60px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 5px;
    margin-top: 23px;
    font-size: 16px; }
  .lightSeaGreen .toolbarInvitationPreview button.close {
    margin-right: 3px; }
  .lightSeaGreen .toolbarInvitationPreview button.btn_chat {
    font-size: 14px;
    width: auto;
    display: block;
    padding: 11px 15px;
    border: 1px solid #7e7c7c;
    border-radius: 5px;
    cursor: pointer;
    float: none;
    margin: 0 auto; }

.lightSeaGreen .imageLibrary img {
  padding: 4px;
  cursor: pointer; }
  .lightSeaGreen .imageLibrary img.selected {
    background-color: #e6eef9; }

.deepPink .widgetWrapper h4.widgetName {
  color: #3ba8c5;
  margin-right: 22px; }

.deepPink .widgetWrapper .exit_btn {
  position: absolute;
  right: 37px;
  color: #3ba8c5;
  font-family: "Segoe UI";
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer; }
  .deepPink .widgetWrapper .exit_btn img {
    margin-right: 8px;
    margin-top: -3px; }

.deepPink .widgetSelectorIndicator {
  margin: 30px auto 0; }
  .deepPink .widgetSelectorIndicator p {
    color: #a1a4a8;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    margin-top: 25px; }

.deepPink .innercontent_wrapper .directLinkWrapper {
  margin-top: 56px; }
  .deepPink .innercontent_wrapper .directLinkWrapper h4 {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    margin-left: 18px;
    margin-bottom: 34px;
    position: relative; }
    .deepPink .innercontent_wrapper .directLinkWrapper h4 span {
      border: 1px solid #3ba8c5;
      background-color: #ffffff;
      border-radius: 50%;
      position: absolute;
      margin-left: 9px;
      width: 24px;
      height: 24px;
      display: inline-block; }
      .deepPink .innercontent_wrapper .directLinkWrapper h4 span img {
        position: absolute;
        top: 5px;
        left: 10px; }
  .deepPink .innercontent_wrapper .directLinkWrapper .with_btn input {
    float: left; }
  .deepPink .innercontent_wrapper .directLinkWrapper .with_btn .uploadBtn button {
    background-color: #3ba8c5; }
    .deepPink .innercontent_wrapper .directLinkWrapper .with_btn .uploadBtn button p {
      color: #fff; }
  .deepPink .innercontent_wrapper .directLinkWrapper .invitationCustomImg {
    margin-bottom: 0.5rem; }
  .deepPink .innercontent_wrapper .directLinkWrapper .btn_wrapper {
    margin-bottom: 15px; }
    .deepPink .innercontent_wrapper .directLinkWrapper .btn_wrapper .uploadBtn.iconUpload {
      margin-left: 0;
      margin-right: 5px; }
      .deepPink .innercontent_wrapper .directLinkWrapper .btn_wrapper .uploadBtn.iconUpload button.uploadIcon input {
        width: 60px; }
  .deepPink .innercontent_wrapper .directLinkWrapper .box {
    padding: 30px; }
    .deepPink .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:first-child {
      width: 131px;
      margin-right: 10px; }
    .deepPink .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:nth-child(2) {
      width: 117px; }
      .deepPink .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:nth-child(2) .css-10nd86i {
        width: 117px; }
  .deepPink .innercontent_wrapper .directLinkWrapper .formBtns Button.btn_save {
    width: auto; }
  .deepPink .innercontent_wrapper .directLinkWrapper .customOption .row > div:nth-child(2) > div:nth-child(2) {
    position: absolute !important;
    z-index: 2;
    top: 44px;
    left: 157px; }
    .deepPink .innercontent_wrapper .directLinkWrapper .customOption .row > div:nth-child(2) > div:nth-child(2) .flexbox-fix {
      display: none !important; }
  .deepPink .innercontent_wrapper .directLinkWrapper .colorSelector .css-1wy0on6 {
    background-color: #fff; }
  .deepPink .innercontent_wrapper .directLinkWrapper .colorSelector input {
    border: none; }
    .deepPink .innercontent_wrapper .directLinkWrapper .colorSelector input:focus {
      border: none; }
  .deepPink .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper {
    position: relative; }
    .deepPink .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper p {
      width: 178px;
      float: left;
      padding-top: 30%;
      font-size: 22px;
      text-align: right;
      text-decoration: underline; }
  .deepPink .innercontent_wrapper .directLinkWrapper .directLinkpreview {
    background-repeat: no-repeat;
    width: 165px;
    height: 114px;
    float: right;
    margin-top: 100px;
    margin-right: 50px;
    margin-left: 24px;
    background-size: cover !important; }
  .deepPink .innercontent_wrapper .directLinkWrapper .theme {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-top: 7px; }
  .deepPink .innercontent_wrapper .directLinkWrapper .preview {
    width: 330px;
    height: 101px;
    padding: 12px 0 0 0;
    margin-top: 40px;
    margin-bottom: 47px;
    background-repeat: no-repeat; }
    .deepPink .innercontent_wrapper .directLinkWrapper .preview .widget-preview {
      width: 166px;
      height: 76px; }
      .deepPink .innercontent_wrapper .directLinkWrapper .preview .widget-preview div {
        font-family: Arial,Helvetica,sans-serif; }
      .deepPink .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-title {
        padding: 11px 0 0 18px;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        text-align: left; }
      .deepPink .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-text {
        width: 146px;
        overflow: hidden;
        white-space: nowrap;
        padding: 1px 0 0;
        text-align: center;
        color: #A8A8A8;
        font-size: 12px;
        margin-left: 10px; }
      .deepPink .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-copy {
        padding: 8px 13px 0;
        text-align: right;
        font-size: 10px;
        color: #fff;
        opacity: .5;
        -moz-opacity: .5;
        -khtml-opacity: .5;
        filter: alpha(opacity=50); }
  .deepPink .innercontent_wrapper .directLinkWrapper .widgetPosition {
    width: 330px; }
    .deepPink .innercontent_wrapper .directLinkWrapper .widgetPosition p {
      width: 236px;
      height: 30px;
      border-radius: 5px;
      border: 1px solid #dedede;
      background-color: #ffffff;
      color: #000000;
      font-family: Segoe UI;
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 37px;
      margin-left: auto;
      margin-right: auto; }
      .deepPink .innercontent_wrapper .directLinkWrapper .widgetPosition p span {
        width: 50%;
        display: inline-block;
        padding: 6px 0;
        cursor: pointer; }
        .deepPink .innercontent_wrapper .directLinkWrapper .widgetPosition p span:first-child {
          border-radius: 5px 0 0 5px; }
        .deepPink .innercontent_wrapper .directLinkWrapper .widgetPosition p span:last-child {
          border-radius: 0 5px 5px 0; }
        .deepPink .innercontent_wrapper .directLinkWrapper .widgetPosition p span.selected {
          color: #ffffff;
          background-color: #3ba8c5; }
  .deepPink .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper {
    width: 90%;
    margin: 0 auto; }
    .deepPink .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp {
      width: 34px;
      height: 34px;
      border: 1px solid #dedede;
      border-radius: 50%;
      float: left;
      padding: 3px;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer; }
      .deepPink .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp div {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        margin: 0; }
      .deepPink .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:hover, .deepPink .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp.selected {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        transition: -webkit-transform 100ms ease 0s;
        transition: transform 100ms ease 0s;
        transition: transform 100ms ease 0s, -webkit-transform 100ms ease 0s;
        padding: 2px; }
        .deepPink .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:hover div, .deepPink .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp.selected div {
          -webkit-transform: scale(0.9);
                  transform: scale(0.9); }
      .deepPink .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(1):hover {
        border: 2px solid #0475A8; }
      .deepPink .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(2):hover {
        border: 2px solid #5F8706; }
      .deepPink .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(3):hover {
        border: 2px solid #D6680B; }
      .deepPink .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(4):hover {
        border: 2px solid #B0097D; }
      .deepPink .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(5):hover {
        border: 2px solid #9D0A09; }
      .deepPink .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(6):hover {
        border: 2px solid #424E54; }
      .deepPink .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(7):hover {
        border: 2px solid #037FAF; }
      .deepPink .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(8):hover {
        border: 2px solid #608706; }
      .deepPink .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(9):hover {
        border: 2px solid #D26009; }
      .deepPink .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(10):hover {
        border: 2px solid #AF097D; }
      .deepPink .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(11):hover {
        border: 2px solid #950909; }
      .deepPink .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(12):hover {
        border: 2px solid #3E484E; }
      .deepPink .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(13):hover {
        border: 2px solid #0476A8; }
      .deepPink .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(14):hover {
        border: 2px solid #98B45D; }
      .deepPink .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(15):hover {
        border: 2px solid #ADADAD; }
      .deepPink .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(16):hover {
        border: 2px solid #98A7AD; }
  .deepPink .innercontent_wrapper .directLinkWrapper .circle-picker {
    padding: 0px 9px; }
    .deepPink .innercontent_wrapper .directLinkWrapper .circle-picker > span > div > span {
      padding: 4px;
      position: relative;
      border: 1px solid #dedede;
      border-radius: 50%;
      display: inline-block;
      width: 34px;
      height: 34px; }
    .deepPink .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(1) > div:hover span {
      border: 2px solid #0475A8; }
    .deepPink .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(1) > div > span > div {
      box-shadow: #0475A8 0px 0px 0px 17px inset !important; }
    .deepPink .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(2) > div:hover span {
      border: 2px solid #5F8706; }
    .deepPink .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(3) > div:hover span {
      border: 2px solid #D6680B; }
    .deepPink .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(4) > div:hover span {
      border: 2px solid #B0097D; }
    .deepPink .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(5) > div:hover span {
      border: 2px solid #9D0A09; }
    .deepPink .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(6) > div:hover span {
      border: 2px solid #424E54; }
    .deepPink .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(7) > div:hover span {
      border: 2px solid #037FAF; }
    .deepPink .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(8) > div:hover span {
      border: 2px solid #587D06; }
    .deepPink .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(9) > div:hover span {
      border: 2px solid #D26009; }
    .deepPink .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(10) > div:hover span {
      border: 2px solid #AF087C; }
    .deepPink .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(11) > div:hover span {
      border: 2px solid #950909; }
    .deepPink .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(12) > div:hover span {
      border: 2px solid #3E484E; }
    .deepPink .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(13) > div:hover span {
      border: 2px solid #0476A8; }
    .deepPink .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(14) > div:hover span {
      border: 2px solid #98B45D; }
    .deepPink .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(15) > div:hover span {
      border: 2px solid #ADADAD; }
    .deepPink .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(16) > div:hover span {
      border: 2px solid #98A7AD; }
  .deepPink .innercontent_wrapper .directLinkWrapper .iconWrapper {
    height: 390px;
    position: relative; }
    .deepPink .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn {
      position: absolute;
      width: 26px;
      height: 27px;
      border: 1px solid #dedede;
      border-radius: 50%;
      top: -27px;
      right: 6px;
      cursor: pointer; }
      .deepPink .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn img {
        margin-top: -2px;
        margin-left: 1px; }
      .deepPink .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn.bottomScroll {
        top: auto;
        bottom: -27px; }
    .deepPink .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow {
      margin-bottom: 13px;
      margin-right: 30px;
      border: 1px solid #dedede;
      border-radius: 10px;
      cursor: pointer; }
      .deepPink .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div {
        width: 151px;
        height: 87px;
        background-color: #ffffff;
        margin-right: 12px;
        padding: 16px;
        border-radius: 10px; }
        .deepPink .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div.no-image {
          opacity: 0; }
        .deepPink .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div:last-child {
          margin-right: 0; }
      .deepPink .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow:last-child {
        margin-bottom: 0; }
      .deepPink .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow.selected {
        background-color: #dedede; }
        .deepPink .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow.selected > div {
          background-color: #dedede; }
    .deepPink .innercontent_wrapper .directLinkWrapper .iconWrapper .rcs-custom-scroll .rcs-inner-handle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #3ba8c5;
      background-color: #3ba8c5;
      margin-left: -8px; }
    .deepPink .innercontent_wrapper .directLinkWrapper .iconWrapper .rcs-custom-scroll .rcs-custom-scrollbar {
      opacity: 1;
      right: 13px;
      border-left: 2px solid #dedede;
      height: 590px; }
  .deepPink .innercontent_wrapper .directLinkWrapper .uploadBtn {
    margin-top: 13px; }
    .deepPink .innercontent_wrapper .directLinkWrapper .uploadBtn.iconUpload {
      margin-top: 5px;
      margin-left: 6px;
      float: left; }
    .deepPink .innercontent_wrapper .directLinkWrapper .uploadBtn button {
      width: 218px;
      height: 29px;
      border-radius: 3px;
      border: 1px solid #3ba8c5;
      background-color: #ffffff;
      position: relative;
      padding: 0; }
      .deepPink .innercontent_wrapper .directLinkWrapper .uploadBtn button.uploadIcon {
        width: auto;
        float: left;
        padding: 0 5px; }
        .deepPink .innercontent_wrapper .directLinkWrapper .uploadBtn button.uploadIcon input {
          width: auto; }
      .deepPink .innercontent_wrapper .directLinkWrapper .uploadBtn button p {
        margin: 0;
        color: #3ba8c5;
        font-family: "Segoe UI";
        font-size: 13px;
        font-weight: 700;
        line-height: 30.4px;
        text-transform: uppercase; }
        .deepPink .innercontent_wrapper .directLinkWrapper .uploadBtn button p img {
          margin-right: 4px;
          margin-top: -4px; }
      .deepPink .innercontent_wrapper .directLinkWrapper .uploadBtn button input {
        position: absolute;
        top: 0;
        left: 0;
        width: 218px;
        height: 29px;
        opacity: 0;
        cursor: pointer; }
  .deepPink .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span {
    margin-right: 15px;
    color: #3ba8c5;
    text-decoration: underline;
    cursor: pointer; }
    .deepPink .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span.selected {
      text-decoration: none;
      color: #000000; }

.deepPink .carousel {
  padding: 12px 0px; }
  .deepPink .carousel img.carouselBtn {
    position: absolute;
    cursor: pointer;
    top: 90px; }
    .deepPink .carousel img.carouselBtn.carousel-left {
      left: 30px; }
  .deepPink .carousel ul {
    margin-left: 0;
    margin-right: 0;
    transition: all 1s; }
    .deepPink .carousel ul.widgetMode li {
      opacity: 0.5;
      float: left; }
      .deepPink .carousel ul.widgetMode li.selected, .deepPink .carousel ul.widgetMode li:hover {
        opacity: 1;
        border: 3px solid #3ba8c5; }
        .deepPink .carousel ul.widgetMode li.selected h4, .deepPink .carousel ul.widgetMode li:hover h4 {
          background-color: #3ba8c5;
          color: #ffffff; }
  .deepPink .carousel .carousel-cell {
    cursor: pointer;
    width: 210px;
    height: 192px;
    box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
    border-radius: 20px;
    background-color: #ffffff;
    padding: 0px;
    display: inline-block;
    margin-right: 20px;
    float: left; }
    .deepPink .carousel .carousel-cell.opacity_0 {
      opacity: 0;
      margin-right: 60px;
      transition: opacity 2s; }
    .deepPink .carousel .carousel-cell:hover {
      border: 3px solid #3ba8c5; }
      .deepPink .carousel .carousel-cell:hover h4 {
        background-color: #3ba8c5;
        color: #ffffff; }
    .deepPink .carousel .carousel-cell h4 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      padding: 22px 0 18px;
      border-bottom: 4px solid #3ba8c5;
      margin: 0;
      border-radius: 15px 15px 0 0; }
    .deepPink .carousel .carousel-cell .img-wrapper {
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      width: 210px; }

.deepPink .settingFormWrapper .codeWrapper {
  margin-top: 69px; }
  .deepPink .settingFormWrapper .codeWrapper p {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    text-align: left; }
  .deepPink .settingFormWrapper .codeWrapper textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    padding: 13px;
    margin-bottom: 10px; }
  .deepPink .settingFormWrapper .codeWrapper .formBtns {
    padding: 0;
    margin: 0;
    border: none; }
    .deepPink .settingFormWrapper .codeWrapper .formBtns Button.btn_clr {
      margin-left: 0;
      margin-right: 10px;
      width: auto;
      padding: 9px 30px; }
      .deepPink .settingFormWrapper .codeWrapper .formBtns Button.btn_clr p {
        text-align: center;
        color: #3ba8c5; }
  .deepPink .settingFormWrapper .codeWrapper .advancedSection {
    margin-top: -48px; }
    .deepPink .settingFormWrapper .codeWrapper .advancedSection .advancedOption p {
      color: #3ba8c5;
      font-family: "Segoe UI Bold";
      font-size: 14px;
      font-weight: 700; }
      .deepPink .settingFormWrapper .codeWrapper .advancedSection .advancedOption p:first-child {
        width: 27px;
        height: 27px;
        border-radius: 50%;
        border: 1px solid #ec407a;
        background-color: #ec407a;
        background-image: url("assets/images/expand.png");
        background-repeat: no-repeat;
        background-position: center;
        padding: 0;
        float: left;
        margin-right: 9px; }
      .deepPink .settingFormWrapper .codeWrapper .advancedSection .advancedOption p:last-child {
        margin-top: 20px; }
    .deepPink .settingFormWrapper .codeWrapper .advancedSection .advancedOption.collapsed p:first-child {
      border: 1px solid #dedede;
      background-color: #ffffff;
      background-image: url("assets/images/plus_icons/plus_pink.png");
      background-size: 11px; }
    .deepPink .settingFormWrapper .codeWrapper .advancedSection .advancedOption.dropdown-toggle::after {
      display: none; }

.deepPink .mailer .modal-dialog textarea {
  border-radius: 5px;
  border: 1px solid #dedede;
  padding: 13px;
  width: 367px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px; }

.deepPink .mailer .modal-dialog label {
  text-align: left;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.deepPink .mailer .modal-dialog .form-control {
  width: 256px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.deepPink .toolBarWrapper .toolTipDesignWrapper {
  border-radius: 10px;
  background-color: #f6f6f6;
  padding: 20px 20px 4px; }

.deepPink .toolBarWrapper .toolBarMenuWrapper {
  height: 53px;
  margin-bottom: 36px;
  position: relative; }
  .deepPink .toolBarWrapper .toolBarMenuWrapper .toolBarBoxWrapper {
    position: absolute;
    height: 13px;
    width: 100%;
    top: -14px;
    left: 0;
    background-color: #fff;
    z-index: 7; }
    .deepPink .toolBarWrapper .toolBarMenuWrapper .toolBarBoxWrapper.bottom {
      top: auto;
      bottom: -13px; }
  .deepPink .toolBarWrapper .toolBarMenuWrapper .toolBarBox {
    position: absolute;
    border: 1px solid #dedede;
    height: 54px;
    width: 98%;
    border-right: 0;
    top: -1px;
    z-index: 5;
    left: 0;
    pointer-events: none; }
  .deepPink .toolBarWrapper .toolBarMenuWrapper .menuList {
    height: 54px;
    background-color: #fff;
    cursor: pointer; }
    .deepPink .toolBarWrapper .toolBarMenuWrapper .menuList.selected {
      background-color: #3ba8c5; }
      .deepPink .toolBarWrapper .toolBarMenuWrapper .menuList.selected p {
        color: #fff; }
      .deepPink .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line1 {
        background-color: #fff;
        border-color: #3ba8c5; }
      .deepPink .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line2 {
        border-color: #3ba8c5; }
        .deepPink .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line2.mask {
          opacity: 1;
          background-color: #fff; }
    .deepPink .toolBarWrapper .toolBarMenuWrapper .menuList p {
      width: 100%;
      float: left;
      color: #293037;
      font-family: "Segoe UI Semi Bold";
      font-size: 13px;
      font-weight: 700; }
    .deepPink .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper {
      position: absolute;
      right: 21px; }
      .deepPink .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line1 {
        width: 35px;
        height: 20px;
        border-bottom: 1px solid #dedede;
        -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
        position: absolute; }
        .deepPink .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line1.selectedNext {
          background-color: #3ba8c5;
          border-color: #fff; }
      .deepPink .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2 {
        width: 33px;
        height: 20px;
        border-bottom: 1px solid #dedede;
        -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
        position: absolute;
        top: 17px;
        left: -16px; }
        .deepPink .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.mask {
          opacity: 0;
          left: -1px;
          width: 37px;
          top: 29px;
          background-color: #fff;
          border: 0; }
          .deepPink .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.mask.selectedNext {
            opacity: 1;
            background-color: #3ba8c5; }
        .deepPink .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.selectedNext {
          border-color: #fff; }

.deepPink .toolBarWrapper .form-label.chatWindow {
  padding-top: 3px; }

.deepPink .toolBarWrapper .chat_windowWrapper .btn-group-toggle {
  display: inline-block; }
  .deepPink .toolBarWrapper .chat_windowWrapper .btn-group-toggle .custom_radionBtn {
    font-size: 14px;
    font-family: 'Segoe UI Semi Bold'; }
  .deepPink .toolBarWrapper .chat_windowWrapper .btn-group-toggle > label {
    margin-bottom: 17px;
    text-align: left; }
    .deepPink .toolBarWrapper .chat_windowWrapper .btn-group-toggle > label:last-child {
      margin-bottom: 14px; }

.deepPink .toolBarWrapper .settingFormWrapper .customSize .form-label {
  font-size: 13px; }

.deepPink .toolBarWrapper .settingFormWrapper .customSize .form-control {
  width: 62px;
  height: 29px;
  border-radius: 3px;
  float: left;
  margin-right: 12px;
  font-size: 13px; }

.deepPink .toolBarWrapper .settingFormWrapper .customSize span {
  float: left;
  margin-top: 6px;
  font-size: 13px; }

.deepPink .toolBarWrapper .toolBarPreviewWrapper {
  height: 302px;
  width: 418px;
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("assets/images/toolBarPreviewBg.png");
  position: relative; }
  .deepPink .toolBarWrapper .toolBarPreviewWrapper .positionIcons {
    position: absolute;
    border: 1px solid #dedede;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    cursor: pointer; }
    .deepPink .toolBarWrapper .toolBarPreviewWrapper .positionIcons.selected {
      background-color: #3ba8c5; }
    .deepPink .toolBarWrapper .toolBarPreviewWrapper .positionIcons.left {
      left: -11px;
      top: 44%;
      padding-right: 2px;
      padding-top: 1px; }
    .deepPink .toolBarWrapper .toolBarPreviewWrapper .positionIcons.right {
      right: -11px;
      top: 44%;
      padding-left: 2px;
      padding-top: 1px; }
    .deepPink .toolBarWrapper .toolBarPreviewWrapper .positionIcons.center {
      bottom: -30px;
      left: 50%;
      padding-top: 2px;
      padding-left: 1px; }
    .deepPink .toolBarWrapper .toolBarPreviewWrapper .positionIcons.bottomLeft {
      bottom: -28px;
      left: -11px;
      padding-left: 4px; }
    .deepPink .toolBarWrapper .toolBarPreviewWrapper .positionIcons.bottomRight {
      bottom: -28px;
      right: -11px;
      padding-right: 4px; }
  .deepPink .toolBarWrapper .toolBarPreviewWrapper .toolBarWrapper {
    width: 366px;
    height: 250px;
    position: absolute;
    top: 25px;
    left: 25px; }
  .deepPink .toolBarWrapper .toolBarPreviewWrapper .ulWrapper {
    position: absolute;
    max-width: 366px;
    max-height: 250px;
    overflow: auto; }
    .deepPink .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.bar_vertical .btn_wrapper {
      height: 54px; }
      .deepPink .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.bar_vertical .btn_wrapper .btn_icon.HelpOnClick-WithText {
        margin-left: auto;
        margin-right: auto; }
    .deepPink .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.transform {
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
  .deepPink .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns {
    list-style: none;
    height: 41px;
    padding: 0;
    margin: 0;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
    .deepPink .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper {
      border-right: 1px solid;
      border-left: 1px solid;
      height: 100%;
      float: left;
      min-width: 30px;
      min-height: 30px;
      margin-bottom: 0;
      display: table; }
      .deepPink .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper:first-child {
        border-left: 0; }
      .deepPink .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper:last-child {
        border-right: 0; }
      .deepPink .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div, .deepPink .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a {
        display: table-cell;
        vertical-align: middle; }
        .deepPink .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div div.preview_row, .deepPink .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a div.preview_row {
          display: table-row;
          height: 100%;
          width: 100%; }
        .deepPink .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div div.preview_clmn, .deepPink .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a div.preview_clmn {
          display: table-cell;
          vertical-align: middle; }
        .deepPink .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div > div, .deepPink .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div {
          display: inline-table;
          vertical-align: middle;
          height: 100%; }
      .deepPink .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_icon {
        margin-top: -1px;
        margin-left: 14px;
        margin-right: 14px; }
        .deepPink .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_icon.HelpOnClick-WithText {
          margin-left: 12px;
          margin-right: 0; }
      .deepPink .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_caption {
        color: #fff;
        display: table-cell;
        font-weight: 700;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 8px;
        padding-right: 15px;
        vertical-align: middle; }

.deepPink .toolBarWrapper .Tooltip__Base-sc-19cbsjq-0 {
  width: 200px !important; }

.deepPink .toolBarWrapper .Tooltip__Left-sc-19cbsjq-3 {
  right: 20px; }

.deepPink .toolBarWrapper .customDrpDwn.customDrpDwn-small .css-10nd86i {
  width: 141px;
  float: left; }

.deepPink .toolBarWrapper .customDrpDwn.customDrpDwn-small .customSize .form-control {
  width: 51px;
  height: 37px;
  margin-right: 9px; }

.deepPink .toolBarWrapper .customDrpDwn.customDrpDwn-small .customSize span {
  color: #7d7d7d;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }

.deepPink .toolBarWrapper .buttonSettingsWrapper {
  box-shadow: none;
  border-radius: 0;
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content; }
  .deepPink .toolBarWrapper .buttonSettingsWrapper .buttonsSection {
    margin-left: 7%;
    border-radius: 10px;
    background-color: #f6f6f6;
    padding: 26px 20px 15px; }
    .deepPink .toolBarWrapper .buttonSettingsWrapper .buttonsSection .editorWrapper .editorToolBar .editorLink {
      display: none; }
  .deepPink .toolBarWrapper .buttonSettingsWrapper .customDrpDwn .css-15k3avv {
    z-index: 10; }
  .deepPink .toolBarWrapper .buttonSettingsWrapper textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    background-color: #ffffff;
    padding: 15px;
    color: #000000;
    font-family: 'Segoe UI Semi Bold';
    font-size: 14px;
    font-weight: 400; }

.deepPink .toolBarWrapper .forIcon .css-10nd86i {
  float: left; }

.deepPink .toolBarWrapper .forIcon .formBtns {
  padding: 0;
  border-top: none;
  margin: 0;
  float: left; }
  .deepPink .toolBarWrapper .forIcon .formBtns Button {
    margin-left: 6px;
    padding-left: 9px;
    padding-right: 9px; }

.deepPink .toolBarWrapper .invitationColorPicker > div {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }
  .deepPink .toolBarWrapper .invitationColorPicker > div > div.colorSelector {
    width: 256px; }
  .deepPink .toolBarWrapper .invitationColorPicker > div > div:nth-child(2) {
    width: 256px;
    position: absolute !important;
    z-index: 2;
    left: 15px; }
    .deepPink .toolBarWrapper .invitationColorPicker > div > div:nth-child(2) .flexbox-fix {
      opacity: 0; }

.deepPink .toolBarWrapper .previewMargin.customSize {
  width: 187px;
  margin-top: 36px;
  margin: 36px auto 0; }

.deepPink .HelpOnClick-FontIconsLibrary {
  max-height: 70vh; }

.deepPink .toolbarInvitationPreview {
  height: 150px;
  width: 320px;
  border: 1px solid #035d85;
  border-radius: 5px;
  background-color: #eeeeee;
  margin: 56px auto; }
  .deepPink .toolbarInvitationPreview .invitationMessageWrapper {
    height: 60px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 5px;
    margin-top: 23px;
    font-size: 16px; }
  .deepPink .toolbarInvitationPreview button.close {
    margin-right: 3px; }
  .deepPink .toolbarInvitationPreview button.btn_chat {
    font-size: 14px;
    width: auto;
    display: block;
    padding: 11px 15px;
    border: 1px solid #7e7c7c;
    border-radius: 5px;
    cursor: pointer;
    float: none;
    margin: 0 auto; }

.deepPink .imageLibrary img {
  padding: 4px;
  cursor: pointer; }
  .deepPink .imageLibrary img.selected {
    background-color: #e6eef9; }

.brown .widgetWrapper h4.widgetName {
  color: #3ba8c5;
  margin-right: 22px; }

.brown .widgetWrapper .exit_btn {
  position: absolute;
  right: 37px;
  color: #3ba8c5;
  font-family: "Segoe UI";
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer; }
  .brown .widgetWrapper .exit_btn img {
    margin-right: 8px;
    margin-top: -3px; }

.brown .widgetSelectorIndicator {
  margin: 30px auto 0; }
  .brown .widgetSelectorIndicator p {
    color: #a1a4a8;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    margin-top: 25px; }

.brown .innercontent_wrapper .directLinkWrapper {
  margin-top: 56px; }
  .brown .innercontent_wrapper .directLinkWrapper h4 {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    margin-left: 18px;
    margin-bottom: 34px;
    position: relative; }
    .brown .innercontent_wrapper .directLinkWrapper h4 span {
      border: 1px solid #3ba8c5;
      background-color: #ffffff;
      border-radius: 50%;
      position: absolute;
      margin-left: 9px;
      width: 24px;
      height: 24px;
      display: inline-block; }
      .brown .innercontent_wrapper .directLinkWrapper h4 span img {
        position: absolute;
        top: 5px;
        left: 10px; }
  .brown .innercontent_wrapper .directLinkWrapper .with_btn input {
    float: left; }
  .brown .innercontent_wrapper .directLinkWrapper .with_btn .uploadBtn button {
    background-color: #3ba8c5; }
    .brown .innercontent_wrapper .directLinkWrapper .with_btn .uploadBtn button p {
      color: #fff; }
  .brown .innercontent_wrapper .directLinkWrapper .invitationCustomImg {
    margin-bottom: 0.5rem; }
  .brown .innercontent_wrapper .directLinkWrapper .btn_wrapper {
    margin-bottom: 15px; }
    .brown .innercontent_wrapper .directLinkWrapper .btn_wrapper .uploadBtn.iconUpload {
      margin-left: 0;
      margin-right: 5px; }
      .brown .innercontent_wrapper .directLinkWrapper .btn_wrapper .uploadBtn.iconUpload button.uploadIcon input {
        width: 60px; }
  .brown .innercontent_wrapper .directLinkWrapper .box {
    padding: 30px; }
    .brown .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:first-child {
      width: 131px;
      margin-right: 10px; }
    .brown .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:nth-child(2) {
      width: 117px; }
      .brown .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:nth-child(2) .css-10nd86i {
        width: 117px; }
  .brown .innercontent_wrapper .directLinkWrapper .formBtns Button.btn_save {
    width: auto; }
  .brown .innercontent_wrapper .directLinkWrapper .customOption .row > div:nth-child(2) > div:nth-child(2) {
    position: absolute !important;
    z-index: 2;
    top: 44px;
    left: 157px; }
    .brown .innercontent_wrapper .directLinkWrapper .customOption .row > div:nth-child(2) > div:nth-child(2) .flexbox-fix {
      display: none !important; }
  .brown .innercontent_wrapper .directLinkWrapper .colorSelector .css-1wy0on6 {
    background-color: #fff; }
  .brown .innercontent_wrapper .directLinkWrapper .colorSelector input {
    border: none; }
    .brown .innercontent_wrapper .directLinkWrapper .colorSelector input:focus {
      border: none; }
  .brown .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper {
    position: relative; }
    .brown .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper p {
      width: 178px;
      float: left;
      padding-top: 30%;
      font-size: 22px;
      text-align: right;
      text-decoration: underline; }
  .brown .innercontent_wrapper .directLinkWrapper .directLinkpreview {
    background-repeat: no-repeat;
    width: 165px;
    height: 114px;
    float: right;
    margin-top: 100px;
    margin-right: 50px;
    margin-left: 24px;
    background-size: cover !important; }
  .brown .innercontent_wrapper .directLinkWrapper .theme {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-top: 7px; }
  .brown .innercontent_wrapper .directLinkWrapper .preview {
    width: 330px;
    height: 101px;
    padding: 12px 0 0 0;
    margin-top: 40px;
    margin-bottom: 47px;
    background-repeat: no-repeat; }
    .brown .innercontent_wrapper .directLinkWrapper .preview .widget-preview {
      width: 166px;
      height: 76px; }
      .brown .innercontent_wrapper .directLinkWrapper .preview .widget-preview div {
        font-family: Arial,Helvetica,sans-serif; }
      .brown .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-title {
        padding: 11px 0 0 18px;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        text-align: left; }
      .brown .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-text {
        width: 146px;
        overflow: hidden;
        white-space: nowrap;
        padding: 1px 0 0;
        text-align: center;
        color: #A8A8A8;
        font-size: 12px;
        margin-left: 10px; }
      .brown .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-copy {
        padding: 8px 13px 0;
        text-align: right;
        font-size: 10px;
        color: #fff;
        opacity: .5;
        -moz-opacity: .5;
        -khtml-opacity: .5;
        filter: alpha(opacity=50); }
  .brown .innercontent_wrapper .directLinkWrapper .widgetPosition {
    width: 330px; }
    .brown .innercontent_wrapper .directLinkWrapper .widgetPosition p {
      width: 236px;
      height: 30px;
      border-radius: 5px;
      border: 1px solid #dedede;
      background-color: #ffffff;
      color: #000000;
      font-family: Segoe UI;
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 37px;
      margin-left: auto;
      margin-right: auto; }
      .brown .innercontent_wrapper .directLinkWrapper .widgetPosition p span {
        width: 50%;
        display: inline-block;
        padding: 6px 0;
        cursor: pointer; }
        .brown .innercontent_wrapper .directLinkWrapper .widgetPosition p span:first-child {
          border-radius: 5px 0 0 5px; }
        .brown .innercontent_wrapper .directLinkWrapper .widgetPosition p span:last-child {
          border-radius: 0 5px 5px 0; }
        .brown .innercontent_wrapper .directLinkWrapper .widgetPosition p span.selected {
          color: #ffffff;
          background-color: #3ba8c5; }
  .brown .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper {
    width: 90%;
    margin: 0 auto; }
    .brown .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp {
      width: 34px;
      height: 34px;
      border: 1px solid #dedede;
      border-radius: 50%;
      float: left;
      padding: 3px;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer; }
      .brown .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp div {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        margin: 0; }
      .brown .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:hover, .brown .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp.selected {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        transition: -webkit-transform 100ms ease 0s;
        transition: transform 100ms ease 0s;
        transition: transform 100ms ease 0s, -webkit-transform 100ms ease 0s;
        padding: 2px; }
        .brown .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:hover div, .brown .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp.selected div {
          -webkit-transform: scale(0.9);
                  transform: scale(0.9); }
      .brown .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(1):hover {
        border: 2px solid #0475A8; }
      .brown .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(2):hover {
        border: 2px solid #5F8706; }
      .brown .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(3):hover {
        border: 2px solid #D6680B; }
      .brown .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(4):hover {
        border: 2px solid #B0097D; }
      .brown .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(5):hover {
        border: 2px solid #9D0A09; }
      .brown .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(6):hover {
        border: 2px solid #424E54; }
      .brown .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(7):hover {
        border: 2px solid #037FAF; }
      .brown .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(8):hover {
        border: 2px solid #608706; }
      .brown .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(9):hover {
        border: 2px solid #D26009; }
      .brown .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(10):hover {
        border: 2px solid #AF097D; }
      .brown .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(11):hover {
        border: 2px solid #950909; }
      .brown .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(12):hover {
        border: 2px solid #3E484E; }
      .brown .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(13):hover {
        border: 2px solid #0476A8; }
      .brown .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(14):hover {
        border: 2px solid #98B45D; }
      .brown .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(15):hover {
        border: 2px solid #ADADAD; }
      .brown .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(16):hover {
        border: 2px solid #98A7AD; }
  .brown .innercontent_wrapper .directLinkWrapper .circle-picker {
    padding: 0px 9px; }
    .brown .innercontent_wrapper .directLinkWrapper .circle-picker > span > div > span {
      padding: 4px;
      position: relative;
      border: 1px solid #dedede;
      border-radius: 50%;
      display: inline-block;
      width: 34px;
      height: 34px; }
    .brown .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(1) > div:hover span {
      border: 2px solid #0475A8; }
    .brown .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(1) > div > span > div {
      box-shadow: #0475A8 0px 0px 0px 17px inset !important; }
    .brown .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(2) > div:hover span {
      border: 2px solid #5F8706; }
    .brown .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(3) > div:hover span {
      border: 2px solid #D6680B; }
    .brown .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(4) > div:hover span {
      border: 2px solid #B0097D; }
    .brown .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(5) > div:hover span {
      border: 2px solid #9D0A09; }
    .brown .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(6) > div:hover span {
      border: 2px solid #424E54; }
    .brown .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(7) > div:hover span {
      border: 2px solid #037FAF; }
    .brown .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(8) > div:hover span {
      border: 2px solid #587D06; }
    .brown .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(9) > div:hover span {
      border: 2px solid #D26009; }
    .brown .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(10) > div:hover span {
      border: 2px solid #AF087C; }
    .brown .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(11) > div:hover span {
      border: 2px solid #950909; }
    .brown .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(12) > div:hover span {
      border: 2px solid #3E484E; }
    .brown .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(13) > div:hover span {
      border: 2px solid #0476A8; }
    .brown .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(14) > div:hover span {
      border: 2px solid #98B45D; }
    .brown .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(15) > div:hover span {
      border: 2px solid #ADADAD; }
    .brown .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(16) > div:hover span {
      border: 2px solid #98A7AD; }
  .brown .innercontent_wrapper .directLinkWrapper .iconWrapper {
    height: 390px;
    position: relative; }
    .brown .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn {
      position: absolute;
      width: 26px;
      height: 27px;
      border: 1px solid #dedede;
      border-radius: 50%;
      top: -27px;
      right: 6px;
      cursor: pointer; }
      .brown .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn img {
        margin-top: -2px;
        margin-left: 1px; }
      .brown .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn.bottomScroll {
        top: auto;
        bottom: -27px; }
    .brown .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow {
      margin-bottom: 13px;
      margin-right: 30px;
      border: 1px solid #dedede;
      border-radius: 10px;
      cursor: pointer; }
      .brown .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div {
        width: 151px;
        height: 87px;
        background-color: #ffffff;
        margin-right: 12px;
        padding: 16px;
        border-radius: 10px; }
        .brown .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div.no-image {
          opacity: 0; }
        .brown .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div:last-child {
          margin-right: 0; }
      .brown .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow:last-child {
        margin-bottom: 0; }
      .brown .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow.selected {
        background-color: #dedede; }
        .brown .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow.selected > div {
          background-color: #dedede; }
    .brown .innercontent_wrapper .directLinkWrapper .iconWrapper .rcs-custom-scroll .rcs-inner-handle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #3ba8c5;
      background-color: #3ba8c5;
      margin-left: -8px; }
    .brown .innercontent_wrapper .directLinkWrapper .iconWrapper .rcs-custom-scroll .rcs-custom-scrollbar {
      opacity: 1;
      right: 13px;
      border-left: 2px solid #dedede;
      height: 590px; }
  .brown .innercontent_wrapper .directLinkWrapper .uploadBtn {
    margin-top: 13px; }
    .brown .innercontent_wrapper .directLinkWrapper .uploadBtn.iconUpload {
      margin-top: 5px;
      margin-left: 6px;
      float: left; }
    .brown .innercontent_wrapper .directLinkWrapper .uploadBtn button {
      width: 218px;
      height: 29px;
      border-radius: 3px;
      border: 1px solid #3ba8c5;
      background-color: #ffffff;
      position: relative;
      padding: 0; }
      .brown .innercontent_wrapper .directLinkWrapper .uploadBtn button.uploadIcon {
        width: auto;
        float: left;
        padding: 0 5px; }
        .brown .innercontent_wrapper .directLinkWrapper .uploadBtn button.uploadIcon input {
          width: auto; }
      .brown .innercontent_wrapper .directLinkWrapper .uploadBtn button p {
        margin: 0;
        color: #3ba8c5;
        font-family: "Segoe UI";
        font-size: 13px;
        font-weight: 700;
        line-height: 30.4px;
        text-transform: uppercase; }
        .brown .innercontent_wrapper .directLinkWrapper .uploadBtn button p img {
          margin-right: 4px;
          margin-top: -4px; }
      .brown .innercontent_wrapper .directLinkWrapper .uploadBtn button input {
        position: absolute;
        top: 0;
        left: 0;
        width: 218px;
        height: 29px;
        opacity: 0;
        cursor: pointer; }
  .brown .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span {
    margin-right: 15px;
    color: #3ba8c5;
    text-decoration: underline;
    cursor: pointer; }
    .brown .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span.selected {
      text-decoration: none;
      color: #000000; }

.brown .carousel {
  padding: 12px 0px; }
  .brown .carousel img.carouselBtn {
    position: absolute;
    cursor: pointer;
    top: 90px; }
    .brown .carousel img.carouselBtn.carousel-left {
      left: 30px; }
  .brown .carousel ul {
    margin-left: 0;
    margin-right: 0;
    transition: all 1s; }
    .brown .carousel ul.widgetMode li {
      opacity: 0.5;
      float: left; }
      .brown .carousel ul.widgetMode li.selected, .brown .carousel ul.widgetMode li:hover {
        opacity: 1;
        border: 3px solid #3ba8c5; }
        .brown .carousel ul.widgetMode li.selected h4, .brown .carousel ul.widgetMode li:hover h4 {
          background-color: #3ba8c5;
          color: #ffffff; }
  .brown .carousel .carousel-cell {
    cursor: pointer;
    width: 210px;
    height: 192px;
    box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
    border-radius: 20px;
    background-color: #ffffff;
    padding: 0px;
    display: inline-block;
    margin-right: 20px;
    float: left; }
    .brown .carousel .carousel-cell.opacity_0 {
      opacity: 0;
      margin-right: 60px;
      transition: opacity 2s; }
    .brown .carousel .carousel-cell:hover {
      border: 3px solid #3ba8c5; }
      .brown .carousel .carousel-cell:hover h4 {
        background-color: #3ba8c5;
        color: #ffffff; }
    .brown .carousel .carousel-cell h4 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      padding: 22px 0 18px;
      border-bottom: 4px solid #3ba8c5;
      margin: 0;
      border-radius: 15px 15px 0 0; }
    .brown .carousel .carousel-cell .img-wrapper {
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      width: 210px; }

.brown .settingFormWrapper .codeWrapper {
  margin-top: 69px; }
  .brown .settingFormWrapper .codeWrapper p {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    text-align: left; }
  .brown .settingFormWrapper .codeWrapper textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    padding: 13px;
    margin-bottom: 10px; }
  .brown .settingFormWrapper .codeWrapper .formBtns {
    padding: 0;
    margin: 0;
    border: none; }
    .brown .settingFormWrapper .codeWrapper .formBtns Button.btn_clr {
      margin-left: 0;
      margin-right: 10px;
      width: auto;
      padding: 9px 30px; }
      .brown .settingFormWrapper .codeWrapper .formBtns Button.btn_clr p {
        text-align: center;
        color: #3ba8c5; }
  .brown .settingFormWrapper .codeWrapper .advancedSection {
    margin-top: -48px; }
    .brown .settingFormWrapper .codeWrapper .advancedSection .advancedOption p {
      color: #3ba8c5;
      font-family: "Segoe UI Bold";
      font-size: 14px;
      font-weight: 700; }
      .brown .settingFormWrapper .codeWrapper .advancedSection .advancedOption p:first-child {
        width: 27px;
        height: 27px;
        border-radius: 50%;
        border: 1px solid #6e4c42;
        background-color: #6e4c42;
        background-image: url("assets/images/expand.png");
        background-repeat: no-repeat;
        background-position: center;
        padding: 0;
        float: left;
        margin-right: 9px; }
      .brown .settingFormWrapper .codeWrapper .advancedSection .advancedOption p:last-child {
        margin-top: 20px; }
    .brown .settingFormWrapper .codeWrapper .advancedSection .advancedOption.collapsed p:first-child {
      border: 1px solid #dedede;
      background-color: #ffffff;
      background-image: url("assets/images/plus_icons/plus_brown.png");
      background-size: 11px; }
    .brown .settingFormWrapper .codeWrapper .advancedSection .advancedOption.dropdown-toggle::after {
      display: none; }

.brown .mailer .modal-dialog textarea {
  border-radius: 5px;
  border: 1px solid #dedede;
  padding: 13px;
  width: 367px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px; }

.brown .mailer .modal-dialog label {
  text-align: left;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.brown .mailer .modal-dialog .form-control {
  width: 256px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.brown .toolBarWrapper .toolTipDesignWrapper {
  border-radius: 10px;
  background-color: #f6f6f6;
  padding: 20px 20px 4px; }

.brown .toolBarWrapper .toolBarMenuWrapper {
  height: 53px;
  margin-bottom: 36px;
  position: relative; }
  .brown .toolBarWrapper .toolBarMenuWrapper .toolBarBoxWrapper {
    position: absolute;
    height: 13px;
    width: 100%;
    top: -14px;
    left: 0;
    background-color: #fff;
    z-index: 7; }
    .brown .toolBarWrapper .toolBarMenuWrapper .toolBarBoxWrapper.bottom {
      top: auto;
      bottom: -13px; }
  .brown .toolBarWrapper .toolBarMenuWrapper .toolBarBox {
    position: absolute;
    border: 1px solid #dedede;
    height: 54px;
    width: 98%;
    border-right: 0;
    top: -1px;
    z-index: 5;
    left: 0;
    pointer-events: none; }
  .brown .toolBarWrapper .toolBarMenuWrapper .menuList {
    height: 54px;
    background-color: #fff;
    cursor: pointer; }
    .brown .toolBarWrapper .toolBarMenuWrapper .menuList.selected {
      background-color: #3ba8c5; }
      .brown .toolBarWrapper .toolBarMenuWrapper .menuList.selected p {
        color: #fff; }
      .brown .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line1 {
        background-color: #fff;
        border-color: #3ba8c5; }
      .brown .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line2 {
        border-color: #3ba8c5; }
        .brown .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line2.mask {
          opacity: 1;
          background-color: #fff; }
    .brown .toolBarWrapper .toolBarMenuWrapper .menuList p {
      width: 100%;
      float: left;
      color: #293037;
      font-family: "Segoe UI Semi Bold";
      font-size: 13px;
      font-weight: 700; }
    .brown .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper {
      position: absolute;
      right: 21px; }
      .brown .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line1 {
        width: 35px;
        height: 20px;
        border-bottom: 1px solid #dedede;
        -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
        position: absolute; }
        .brown .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line1.selectedNext {
          background-color: #3ba8c5;
          border-color: #fff; }
      .brown .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2 {
        width: 33px;
        height: 20px;
        border-bottom: 1px solid #dedede;
        -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
        position: absolute;
        top: 17px;
        left: -16px; }
        .brown .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.mask {
          opacity: 0;
          left: -1px;
          width: 37px;
          top: 29px;
          background-color: #fff;
          border: 0; }
          .brown .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.mask.selectedNext {
            opacity: 1;
            background-color: #3ba8c5; }
        .brown .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.selectedNext {
          border-color: #fff; }

.brown .toolBarWrapper .form-label.chatWindow {
  padding-top: 3px; }

.brown .toolBarWrapper .chat_windowWrapper .btn-group-toggle {
  display: inline-block; }
  .brown .toolBarWrapper .chat_windowWrapper .btn-group-toggle .custom_radionBtn {
    font-size: 14px;
    font-family: 'Segoe UI Semi Bold'; }
  .brown .toolBarWrapper .chat_windowWrapper .btn-group-toggle > label {
    margin-bottom: 17px;
    text-align: left; }
    .brown .toolBarWrapper .chat_windowWrapper .btn-group-toggle > label:last-child {
      margin-bottom: 14px; }

.brown .toolBarWrapper .settingFormWrapper .customSize .form-label {
  font-size: 13px; }

.brown .toolBarWrapper .settingFormWrapper .customSize .form-control {
  width: 62px;
  height: 29px;
  border-radius: 3px;
  float: left;
  margin-right: 12px;
  font-size: 13px; }

.brown .toolBarWrapper .settingFormWrapper .customSize span {
  float: left;
  margin-top: 6px;
  font-size: 13px; }

.brown .toolBarWrapper .toolBarPreviewWrapper {
  height: 302px;
  width: 418px;
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("assets/images/toolBarPreviewBg.png");
  position: relative; }
  .brown .toolBarWrapper .toolBarPreviewWrapper .positionIcons {
    position: absolute;
    border: 1px solid #dedede;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    cursor: pointer; }
    .brown .toolBarWrapper .toolBarPreviewWrapper .positionIcons.selected {
      background-color: #3ba8c5; }
    .brown .toolBarWrapper .toolBarPreviewWrapper .positionIcons.left {
      left: -11px;
      top: 44%;
      padding-right: 2px;
      padding-top: 1px; }
    .brown .toolBarWrapper .toolBarPreviewWrapper .positionIcons.right {
      right: -11px;
      top: 44%;
      padding-left: 2px;
      padding-top: 1px; }
    .brown .toolBarWrapper .toolBarPreviewWrapper .positionIcons.center {
      bottom: -30px;
      left: 50%;
      padding-top: 2px;
      padding-left: 1px; }
    .brown .toolBarWrapper .toolBarPreviewWrapper .positionIcons.bottomLeft {
      bottom: -28px;
      left: -11px;
      padding-left: 4px; }
    .brown .toolBarWrapper .toolBarPreviewWrapper .positionIcons.bottomRight {
      bottom: -28px;
      right: -11px;
      padding-right: 4px; }
  .brown .toolBarWrapper .toolBarPreviewWrapper .toolBarWrapper {
    width: 366px;
    height: 250px;
    position: absolute;
    top: 25px;
    left: 25px; }
  .brown .toolBarWrapper .toolBarPreviewWrapper .ulWrapper {
    position: absolute;
    max-width: 366px;
    max-height: 250px;
    overflow: auto; }
    .brown .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.bar_vertical .btn_wrapper {
      height: 54px; }
      .brown .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.bar_vertical .btn_wrapper .btn_icon.HelpOnClick-WithText {
        margin-left: auto;
        margin-right: auto; }
    .brown .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.transform {
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
  .brown .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns {
    list-style: none;
    height: 41px;
    padding: 0;
    margin: 0;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
    .brown .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper {
      border-right: 1px solid;
      border-left: 1px solid;
      height: 100%;
      float: left;
      min-width: 30px;
      min-height: 30px;
      margin-bottom: 0;
      display: table; }
      .brown .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper:first-child {
        border-left: 0; }
      .brown .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper:last-child {
        border-right: 0; }
      .brown .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div, .brown .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a {
        display: table-cell;
        vertical-align: middle; }
        .brown .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div div.preview_row, .brown .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a div.preview_row {
          display: table-row;
          height: 100%;
          width: 100%; }
        .brown .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div div.preview_clmn, .brown .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a div.preview_clmn {
          display: table-cell;
          vertical-align: middle; }
        .brown .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div > div, .brown .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div {
          display: inline-table;
          vertical-align: middle;
          height: 100%; }
      .brown .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_icon {
        margin-top: -1px;
        margin-left: 14px;
        margin-right: 14px; }
        .brown .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_icon.HelpOnClick-WithText {
          margin-left: 12px;
          margin-right: 0; }
      .brown .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_caption {
        color: #fff;
        display: table-cell;
        font-weight: 700;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 8px;
        padding-right: 15px;
        vertical-align: middle; }

.brown .toolBarWrapper .Tooltip__Base-sc-19cbsjq-0 {
  width: 200px !important; }

.brown .toolBarWrapper .Tooltip__Left-sc-19cbsjq-3 {
  right: 20px; }

.brown .toolBarWrapper .customDrpDwn.customDrpDwn-small .css-10nd86i {
  width: 141px;
  float: left; }

.brown .toolBarWrapper .customDrpDwn.customDrpDwn-small .customSize .form-control {
  width: 51px;
  height: 37px;
  margin-right: 9px; }

.brown .toolBarWrapper .customDrpDwn.customDrpDwn-small .customSize span {
  color: #7d7d7d;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }

.brown .toolBarWrapper .buttonSettingsWrapper {
  box-shadow: none;
  border-radius: 0;
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content; }
  .brown .toolBarWrapper .buttonSettingsWrapper .buttonsSection {
    margin-left: 7%;
    border-radius: 10px;
    background-color: #f6f6f6;
    padding: 26px 20px 15px; }
    .brown .toolBarWrapper .buttonSettingsWrapper .buttonsSection .editorWrapper .editorToolBar .editorLink {
      display: none; }
  .brown .toolBarWrapper .buttonSettingsWrapper .customDrpDwn .css-15k3avv {
    z-index: 10; }
  .brown .toolBarWrapper .buttonSettingsWrapper textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    background-color: #ffffff;
    padding: 15px;
    color: #000000;
    font-family: 'Segoe UI Semi Bold';
    font-size: 14px;
    font-weight: 400; }

.brown .toolBarWrapper .forIcon .css-10nd86i {
  float: left; }

.brown .toolBarWrapper .forIcon .formBtns {
  padding: 0;
  border-top: none;
  margin: 0;
  float: left; }
  .brown .toolBarWrapper .forIcon .formBtns Button {
    margin-left: 6px;
    padding-left: 9px;
    padding-right: 9px; }

.brown .toolBarWrapper .invitationColorPicker > div {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }
  .brown .toolBarWrapper .invitationColorPicker > div > div.colorSelector {
    width: 256px; }
  .brown .toolBarWrapper .invitationColorPicker > div > div:nth-child(2) {
    width: 256px;
    position: absolute !important;
    z-index: 2;
    left: 15px; }
    .brown .toolBarWrapper .invitationColorPicker > div > div:nth-child(2) .flexbox-fix {
      opacity: 0; }

.brown .toolBarWrapper .previewMargin.customSize {
  width: 187px;
  margin-top: 36px;
  margin: 36px auto 0; }

.brown .HelpOnClick-FontIconsLibrary {
  max-height: 70vh; }

.brown .toolbarInvitationPreview {
  height: 150px;
  width: 320px;
  border: 1px solid #035d85;
  border-radius: 5px;
  background-color: #eeeeee;
  margin: 56px auto; }
  .brown .toolbarInvitationPreview .invitationMessageWrapper {
    height: 60px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 5px;
    margin-top: 23px;
    font-size: 16px; }
  .brown .toolbarInvitationPreview button.close {
    margin-right: 3px; }
  .brown .toolbarInvitationPreview button.btn_chat {
    font-size: 14px;
    width: auto;
    display: block;
    padding: 11px 15px;
    border: 1px solid #7e7c7c;
    border-radius: 5px;
    cursor: pointer;
    float: none;
    margin: 0 auto; }

.brown .imageLibrary img {
  padding: 4px;
  cursor: pointer; }
  .brown .imageLibrary img.selected {
    background-color: #e6eef9; }

.navyBlue .widgetWrapper h4.widgetName {
  color: #3ba8c5;
  margin-right: 22px; }

.navyBlue .widgetWrapper .exit_btn {
  position: absolute;
  right: 37px;
  color: #3ba8c5;
  font-family: "Segoe UI";
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer; }
  .navyBlue .widgetWrapper .exit_btn img {
    margin-right: 8px;
    margin-top: -3px; }

.navyBlue .widgetSelectorIndicator {
  margin: 30px auto 0; }
  .navyBlue .widgetSelectorIndicator p {
    color: #a1a4a8;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    margin-top: 25px; }

.navyBlue .innercontent_wrapper .directLinkWrapper {
  margin-top: 56px; }
  .navyBlue .innercontent_wrapper .directLinkWrapper h4 {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    margin-left: 18px;
    margin-bottom: 34px;
    position: relative; }
    .navyBlue .innercontent_wrapper .directLinkWrapper h4 span {
      border: 1px solid #3ba8c5;
      background-color: #ffffff;
      border-radius: 50%;
      position: absolute;
      margin-left: 9px;
      width: 24px;
      height: 24px;
      display: inline-block; }
      .navyBlue .innercontent_wrapper .directLinkWrapper h4 span img {
        position: absolute;
        top: 5px;
        left: 10px; }
  .navyBlue .innercontent_wrapper .directLinkWrapper .with_btn input {
    float: left; }
  .navyBlue .innercontent_wrapper .directLinkWrapper .with_btn .uploadBtn button {
    background-color: #3ba8c5; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .with_btn .uploadBtn button p {
      color: #fff; }
  .navyBlue .innercontent_wrapper .directLinkWrapper .invitationCustomImg {
    margin-bottom: 0.5rem; }
  .navyBlue .innercontent_wrapper .directLinkWrapper .btn_wrapper {
    margin-bottom: 15px; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .btn_wrapper .uploadBtn.iconUpload {
      margin-left: 0;
      margin-right: 5px; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .btn_wrapper .uploadBtn.iconUpload button.uploadIcon input {
        width: 60px; }
  .navyBlue .innercontent_wrapper .directLinkWrapper .box {
    padding: 30px; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:first-child {
      width: 131px;
      margin-right: 10px; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:nth-child(2) {
      width: 117px; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:nth-child(2) .css-10nd86i {
        width: 117px; }
  .navyBlue .innercontent_wrapper .directLinkWrapper .formBtns Button.btn_save {
    width: auto; }
  .navyBlue .innercontent_wrapper .directLinkWrapper .customOption .row > div:nth-child(2) > div:nth-child(2) {
    position: absolute !important;
    z-index: 2;
    top: 44px;
    left: 157px; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .customOption .row > div:nth-child(2) > div:nth-child(2) .flexbox-fix {
      display: none !important; }
  .navyBlue .innercontent_wrapper .directLinkWrapper .colorSelector .css-1wy0on6 {
    background-color: #fff; }
  .navyBlue .innercontent_wrapper .directLinkWrapper .colorSelector input {
    border: none; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .colorSelector input:focus {
      border: none; }
  .navyBlue .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper {
    position: relative; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper p {
      width: 178px;
      float: left;
      padding-top: 30%;
      font-size: 22px;
      text-align: right;
      text-decoration: underline; }
  .navyBlue .innercontent_wrapper .directLinkWrapper .directLinkpreview {
    background-repeat: no-repeat;
    width: 165px;
    height: 114px;
    float: right;
    margin-top: 100px;
    margin-right: 50px;
    margin-left: 24px;
    background-size: cover !important; }
  .navyBlue .innercontent_wrapper .directLinkWrapper .theme {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-top: 7px; }
  .navyBlue .innercontent_wrapper .directLinkWrapper .preview {
    width: 330px;
    height: 101px;
    padding: 12px 0 0 0;
    margin-top: 40px;
    margin-bottom: 47px;
    background-repeat: no-repeat; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .preview .widget-preview {
      width: 166px;
      height: 76px; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .preview .widget-preview div {
        font-family: Arial,Helvetica,sans-serif; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-title {
        padding: 11px 0 0 18px;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        text-align: left; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-text {
        width: 146px;
        overflow: hidden;
        white-space: nowrap;
        padding: 1px 0 0;
        text-align: center;
        color: #A8A8A8;
        font-size: 12px;
        margin-left: 10px; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-copy {
        padding: 8px 13px 0;
        text-align: right;
        font-size: 10px;
        color: #fff;
        opacity: .5;
        -moz-opacity: .5;
        -khtml-opacity: .5;
        filter: alpha(opacity=50); }
  .navyBlue .innercontent_wrapper .directLinkWrapper .widgetPosition {
    width: 330px; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .widgetPosition p {
      width: 236px;
      height: 30px;
      border-radius: 5px;
      border: 1px solid #dedede;
      background-color: #ffffff;
      color: #000000;
      font-family: Segoe UI;
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 37px;
      margin-left: auto;
      margin-right: auto; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .widgetPosition p span {
        width: 50%;
        display: inline-block;
        padding: 6px 0;
        cursor: pointer; }
        .navyBlue .innercontent_wrapper .directLinkWrapper .widgetPosition p span:first-child {
          border-radius: 5px 0 0 5px; }
        .navyBlue .innercontent_wrapper .directLinkWrapper .widgetPosition p span:last-child {
          border-radius: 0 5px 5px 0; }
        .navyBlue .innercontent_wrapper .directLinkWrapper .widgetPosition p span.selected {
          color: #ffffff;
          background-color: #3ba8c5; }
  .navyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper {
    width: 90%;
    margin: 0 auto; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp {
      width: 34px;
      height: 34px;
      border: 1px solid #dedede;
      border-radius: 50%;
      float: left;
      padding: 3px;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp div {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        margin: 0; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:hover, .navyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp.selected {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        transition: -webkit-transform 100ms ease 0s;
        transition: transform 100ms ease 0s;
        transition: transform 100ms ease 0s, -webkit-transform 100ms ease 0s;
        padding: 2px; }
        .navyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:hover div, .navyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp.selected div {
          -webkit-transform: scale(0.9);
                  transform: scale(0.9); }
      .navyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(1):hover {
        border: 2px solid #0475A8; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(2):hover {
        border: 2px solid #5F8706; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(3):hover {
        border: 2px solid #D6680B; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(4):hover {
        border: 2px solid #B0097D; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(5):hover {
        border: 2px solid #9D0A09; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(6):hover {
        border: 2px solid #424E54; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(7):hover {
        border: 2px solid #037FAF; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(8):hover {
        border: 2px solid #608706; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(9):hover {
        border: 2px solid #D26009; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(10):hover {
        border: 2px solid #AF097D; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(11):hover {
        border: 2px solid #950909; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(12):hover {
        border: 2px solid #3E484E; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(13):hover {
        border: 2px solid #0476A8; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(14):hover {
        border: 2px solid #98B45D; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(15):hover {
        border: 2px solid #ADADAD; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(16):hover {
        border: 2px solid #98A7AD; }
  .navyBlue .innercontent_wrapper .directLinkWrapper .circle-picker {
    padding: 0px 9px; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span > div > span {
      padding: 4px;
      position: relative;
      border: 1px solid #dedede;
      border-radius: 50%;
      display: inline-block;
      width: 34px;
      height: 34px; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(1) > div:hover span {
      border: 2px solid #0475A8; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(1) > div > span > div {
      box-shadow: #0475A8 0px 0px 0px 17px inset !important; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(2) > div:hover span {
      border: 2px solid #5F8706; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(3) > div:hover span {
      border: 2px solid #D6680B; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(4) > div:hover span {
      border: 2px solid #B0097D; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(5) > div:hover span {
      border: 2px solid #9D0A09; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(6) > div:hover span {
      border: 2px solid #424E54; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(7) > div:hover span {
      border: 2px solid #037FAF; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(8) > div:hover span {
      border: 2px solid #587D06; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(9) > div:hover span {
      border: 2px solid #D26009; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(10) > div:hover span {
      border: 2px solid #AF087C; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(11) > div:hover span {
      border: 2px solid #950909; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(12) > div:hover span {
      border: 2px solid #3E484E; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(13) > div:hover span {
      border: 2px solid #0476A8; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(14) > div:hover span {
      border: 2px solid #98B45D; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(15) > div:hover span {
      border: 2px solid #ADADAD; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(16) > div:hover span {
      border: 2px solid #98A7AD; }
  .navyBlue .innercontent_wrapper .directLinkWrapper .iconWrapper {
    height: 390px;
    position: relative; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn {
      position: absolute;
      width: 26px;
      height: 27px;
      border: 1px solid #dedede;
      border-radius: 50%;
      top: -27px;
      right: 6px;
      cursor: pointer; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn img {
        margin-top: -2px;
        margin-left: 1px; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn.bottomScroll {
        top: auto;
        bottom: -27px; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow {
      margin-bottom: 13px;
      margin-right: 30px;
      border: 1px solid #dedede;
      border-radius: 10px;
      cursor: pointer; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div {
        width: 151px;
        height: 87px;
        background-color: #ffffff;
        margin-right: 12px;
        padding: 16px;
        border-radius: 10px; }
        .navyBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div.no-image {
          opacity: 0; }
        .navyBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div:last-child {
          margin-right: 0; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow:last-child {
        margin-bottom: 0; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow.selected {
        background-color: #dedede; }
        .navyBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow.selected > div {
          background-color: #dedede; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .rcs-custom-scroll .rcs-inner-handle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #3ba8c5;
      background-color: #3ba8c5;
      margin-left: -8px; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .rcs-custom-scroll .rcs-custom-scrollbar {
      opacity: 1;
      right: 13px;
      border-left: 2px solid #dedede;
      height: 590px; }
  .navyBlue .innercontent_wrapper .directLinkWrapper .uploadBtn {
    margin-top: 13px; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .uploadBtn.iconUpload {
      margin-top: 5px;
      margin-left: 6px;
      float: left; }
    .navyBlue .innercontent_wrapper .directLinkWrapper .uploadBtn button {
      width: 218px;
      height: 29px;
      border-radius: 3px;
      border: 1px solid #3ba8c5;
      background-color: #ffffff;
      position: relative;
      padding: 0; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .uploadBtn button.uploadIcon {
        width: auto;
        float: left;
        padding: 0 5px; }
        .navyBlue .innercontent_wrapper .directLinkWrapper .uploadBtn button.uploadIcon input {
          width: auto; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .uploadBtn button p {
        margin: 0;
        color: #3ba8c5;
        font-family: "Segoe UI";
        font-size: 13px;
        font-weight: 700;
        line-height: 30.4px;
        text-transform: uppercase; }
        .navyBlue .innercontent_wrapper .directLinkWrapper .uploadBtn button p img {
          margin-right: 4px;
          margin-top: -4px; }
      .navyBlue .innercontent_wrapper .directLinkWrapper .uploadBtn button input {
        position: absolute;
        top: 0;
        left: 0;
        width: 218px;
        height: 29px;
        opacity: 0;
        cursor: pointer; }
  .navyBlue .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span {
    margin-right: 15px;
    color: #3ba8c5;
    text-decoration: underline;
    cursor: pointer; }
    .navyBlue .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span.selected {
      text-decoration: none;
      color: #000000; }

.navyBlue .carousel {
  padding: 12px 0px; }
  .navyBlue .carousel img.carouselBtn {
    position: absolute;
    cursor: pointer;
    top: 90px; }
    .navyBlue .carousel img.carouselBtn.carousel-left {
      left: 30px; }
  .navyBlue .carousel ul {
    margin-left: 0;
    margin-right: 0;
    transition: all 1s; }
    .navyBlue .carousel ul.widgetMode li {
      opacity: 0.5;
      float: left; }
      .navyBlue .carousel ul.widgetMode li.selected, .navyBlue .carousel ul.widgetMode li:hover {
        opacity: 1;
        border: 3px solid #3ba8c5; }
        .navyBlue .carousel ul.widgetMode li.selected h4, .navyBlue .carousel ul.widgetMode li:hover h4 {
          background-color: #3ba8c5;
          color: #ffffff; }
  .navyBlue .carousel .carousel-cell {
    cursor: pointer;
    width: 210px;
    height: 192px;
    box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
    border-radius: 20px;
    background-color: #ffffff;
    padding: 0px;
    display: inline-block;
    margin-right: 20px;
    float: left; }
    .navyBlue .carousel .carousel-cell.opacity_0 {
      opacity: 0;
      margin-right: 60px;
      transition: opacity 2s; }
    .navyBlue .carousel .carousel-cell:hover {
      border: 3px solid #3ba8c5; }
      .navyBlue .carousel .carousel-cell:hover h4 {
        background-color: #3ba8c5;
        color: #ffffff; }
    .navyBlue .carousel .carousel-cell h4 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      padding: 22px 0 18px;
      border-bottom: 4px solid #3ba8c5;
      margin: 0;
      border-radius: 15px 15px 0 0; }
    .navyBlue .carousel .carousel-cell .img-wrapper {
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      width: 210px; }

.navyBlue .settingFormWrapper .codeWrapper {
  margin-top: 69px; }
  .navyBlue .settingFormWrapper .codeWrapper p {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    text-align: left; }
  .navyBlue .settingFormWrapper .codeWrapper textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    padding: 13px;
    margin-bottom: 10px; }
  .navyBlue .settingFormWrapper .codeWrapper .formBtns {
    padding: 0;
    margin: 0;
    border: none; }
    .navyBlue .settingFormWrapper .codeWrapper .formBtns Button.btn_clr {
      margin-left: 0;
      margin-right: 10px;
      width: auto;
      padding: 9px 30px; }
      .navyBlue .settingFormWrapper .codeWrapper .formBtns Button.btn_clr p {
        text-align: center;
        color: #3ba8c5; }
  .navyBlue .settingFormWrapper .codeWrapper .advancedSection {
    margin-top: -48px; }
    .navyBlue .settingFormWrapper .codeWrapper .advancedSection .advancedOption p {
      color: #3ba8c5;
      font-family: "Segoe UI Bold";
      font-size: 14px;
      font-weight: 700; }
      .navyBlue .settingFormWrapper .codeWrapper .advancedSection .advancedOption p:first-child {
        width: 27px;
        height: 27px;
        border-radius: 50%;
        border: 1px solid #234e91;
        background-color: #234e91;
        background-image: url("assets/images/expand.png");
        background-repeat: no-repeat;
        background-position: center;
        padding: 0;
        float: left;
        margin-right: 9px; }
      .navyBlue .settingFormWrapper .codeWrapper .advancedSection .advancedOption p:last-child {
        margin-top: 20px; }
    .navyBlue .settingFormWrapper .codeWrapper .advancedSection .advancedOption.collapsed p:first-child {
      border: 1px solid #dedede;
      background-color: #ffffff;
      background-image: url("assets/images/plus_icons/plus_navyblue.png");
      background-size: 11px; }
    .navyBlue .settingFormWrapper .codeWrapper .advancedSection .advancedOption.dropdown-toggle::after {
      display: none; }

.navyBlue .mailer .modal-dialog textarea {
  border-radius: 5px;
  border: 1px solid #dedede;
  padding: 13px;
  width: 367px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px; }

.navyBlue .mailer .modal-dialog label {
  text-align: left;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.navyBlue .mailer .modal-dialog .form-control {
  width: 256px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.navyBlue .toolBarWrapper .toolTipDesignWrapper {
  border-radius: 10px;
  background-color: #f6f6f6;
  padding: 20px 20px 4px; }

.navyBlue .toolBarWrapper .toolBarMenuWrapper {
  height: 53px;
  margin-bottom: 36px;
  position: relative; }
  .navyBlue .toolBarWrapper .toolBarMenuWrapper .toolBarBoxWrapper {
    position: absolute;
    height: 13px;
    width: 100%;
    top: -14px;
    left: 0;
    background-color: #fff;
    z-index: 7; }
    .navyBlue .toolBarWrapper .toolBarMenuWrapper .toolBarBoxWrapper.bottom {
      top: auto;
      bottom: -13px; }
  .navyBlue .toolBarWrapper .toolBarMenuWrapper .toolBarBox {
    position: absolute;
    border: 1px solid #dedede;
    height: 54px;
    width: 98%;
    border-right: 0;
    top: -1px;
    z-index: 5;
    left: 0;
    pointer-events: none; }
  .navyBlue .toolBarWrapper .toolBarMenuWrapper .menuList {
    height: 54px;
    background-color: #fff;
    cursor: pointer; }
    .navyBlue .toolBarWrapper .toolBarMenuWrapper .menuList.selected {
      background-color: #3ba8c5; }
      .navyBlue .toolBarWrapper .toolBarMenuWrapper .menuList.selected p {
        color: #fff; }
      .navyBlue .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line1 {
        background-color: #fff;
        border-color: #3ba8c5; }
      .navyBlue .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line2 {
        border-color: #3ba8c5; }
        .navyBlue .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line2.mask {
          opacity: 1;
          background-color: #fff; }
    .navyBlue .toolBarWrapper .toolBarMenuWrapper .menuList p {
      width: 100%;
      float: left;
      color: #293037;
      font-family: "Segoe UI Semi Bold";
      font-size: 13px;
      font-weight: 700; }
    .navyBlue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper {
      position: absolute;
      right: 21px; }
      .navyBlue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line1 {
        width: 35px;
        height: 20px;
        border-bottom: 1px solid #dedede;
        -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
        position: absolute; }
        .navyBlue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line1.selectedNext {
          background-color: #3ba8c5;
          border-color: #fff; }
      .navyBlue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2 {
        width: 33px;
        height: 20px;
        border-bottom: 1px solid #dedede;
        -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
        position: absolute;
        top: 17px;
        left: -16px; }
        .navyBlue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.mask {
          opacity: 0;
          left: -1px;
          width: 37px;
          top: 29px;
          background-color: #fff;
          border: 0; }
          .navyBlue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.mask.selectedNext {
            opacity: 1;
            background-color: #3ba8c5; }
        .navyBlue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.selectedNext {
          border-color: #fff; }

.navyBlue .toolBarWrapper .form-label.chatWindow {
  padding-top: 3px; }

.navyBlue .toolBarWrapper .chat_windowWrapper .btn-group-toggle {
  display: inline-block; }
  .navyBlue .toolBarWrapper .chat_windowWrapper .btn-group-toggle .custom_radionBtn {
    font-size: 14px;
    font-family: 'Segoe UI Semi Bold'; }
  .navyBlue .toolBarWrapper .chat_windowWrapper .btn-group-toggle > label {
    margin-bottom: 17px;
    text-align: left; }
    .navyBlue .toolBarWrapper .chat_windowWrapper .btn-group-toggle > label:last-child {
      margin-bottom: 14px; }

.navyBlue .toolBarWrapper .settingFormWrapper .customSize .form-label {
  font-size: 13px; }

.navyBlue .toolBarWrapper .settingFormWrapper .customSize .form-control {
  width: 62px;
  height: 29px;
  border-radius: 3px;
  float: left;
  margin-right: 12px;
  font-size: 13px; }

.navyBlue .toolBarWrapper .settingFormWrapper .customSize span {
  float: left;
  margin-top: 6px;
  font-size: 13px; }

.navyBlue .toolBarWrapper .toolBarPreviewWrapper {
  height: 302px;
  width: 418px;
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("assets/images/toolBarPreviewBg.png");
  position: relative; }
  .navyBlue .toolBarWrapper .toolBarPreviewWrapper .positionIcons {
    position: absolute;
    border: 1px solid #dedede;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    cursor: pointer; }
    .navyBlue .toolBarWrapper .toolBarPreviewWrapper .positionIcons.selected {
      background-color: #3ba8c5; }
    .navyBlue .toolBarWrapper .toolBarPreviewWrapper .positionIcons.left {
      left: -11px;
      top: 44%;
      padding-right: 2px;
      padding-top: 1px; }
    .navyBlue .toolBarWrapper .toolBarPreviewWrapper .positionIcons.right {
      right: -11px;
      top: 44%;
      padding-left: 2px;
      padding-top: 1px; }
    .navyBlue .toolBarWrapper .toolBarPreviewWrapper .positionIcons.center {
      bottom: -30px;
      left: 50%;
      padding-top: 2px;
      padding-left: 1px; }
    .navyBlue .toolBarWrapper .toolBarPreviewWrapper .positionIcons.bottomLeft {
      bottom: -28px;
      left: -11px;
      padding-left: 4px; }
    .navyBlue .toolBarWrapper .toolBarPreviewWrapper .positionIcons.bottomRight {
      bottom: -28px;
      right: -11px;
      padding-right: 4px; }
  .navyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarWrapper {
    width: 366px;
    height: 250px;
    position: absolute;
    top: 25px;
    left: 25px; }
  .navyBlue .toolBarWrapper .toolBarPreviewWrapper .ulWrapper {
    position: absolute;
    max-width: 366px;
    max-height: 250px;
    overflow: auto; }
    .navyBlue .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.bar_vertical .btn_wrapper {
      height: 54px; }
      .navyBlue .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.bar_vertical .btn_wrapper .btn_icon.HelpOnClick-WithText {
        margin-left: auto;
        margin-right: auto; }
    .navyBlue .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.transform {
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
  .navyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns {
    list-style: none;
    height: 41px;
    padding: 0;
    margin: 0;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
    .navyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper {
      border-right: 1px solid;
      border-left: 1px solid;
      height: 100%;
      float: left;
      min-width: 30px;
      min-height: 30px;
      margin-bottom: 0;
      display: table; }
      .navyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper:first-child {
        border-left: 0; }
      .navyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper:last-child {
        border-right: 0; }
      .navyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div, .navyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a {
        display: table-cell;
        vertical-align: middle; }
        .navyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div div.preview_row, .navyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a div.preview_row {
          display: table-row;
          height: 100%;
          width: 100%; }
        .navyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div div.preview_clmn, .navyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a div.preview_clmn {
          display: table-cell;
          vertical-align: middle; }
        .navyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div > div, .navyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div {
          display: inline-table;
          vertical-align: middle;
          height: 100%; }
      .navyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_icon {
        margin-top: -1px;
        margin-left: 14px;
        margin-right: 14px; }
        .navyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_icon.HelpOnClick-WithText {
          margin-left: 12px;
          margin-right: 0; }
      .navyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_caption {
        color: #fff;
        display: table-cell;
        font-weight: 700;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 8px;
        padding-right: 15px;
        vertical-align: middle; }

.navyBlue .toolBarWrapper .Tooltip__Base-sc-19cbsjq-0 {
  width: 200px !important; }

.navyBlue .toolBarWrapper .Tooltip__Left-sc-19cbsjq-3 {
  right: 20px; }

.navyBlue .toolBarWrapper .customDrpDwn.customDrpDwn-small .css-10nd86i {
  width: 141px;
  float: left; }

.navyBlue .toolBarWrapper .customDrpDwn.customDrpDwn-small .customSize .form-control {
  width: 51px;
  height: 37px;
  margin-right: 9px; }

.navyBlue .toolBarWrapper .customDrpDwn.customDrpDwn-small .customSize span {
  color: #7d7d7d;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }

.navyBlue .toolBarWrapper .buttonSettingsWrapper {
  box-shadow: none;
  border-radius: 0;
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content; }
  .navyBlue .toolBarWrapper .buttonSettingsWrapper .buttonsSection {
    margin-left: 7%;
    border-radius: 10px;
    background-color: #f6f6f6;
    padding: 26px 20px 15px; }
    .navyBlue .toolBarWrapper .buttonSettingsWrapper .buttonsSection .editorWrapper .editorToolBar .editorLink {
      display: none; }
  .navyBlue .toolBarWrapper .buttonSettingsWrapper .customDrpDwn .css-15k3avv {
    z-index: 10; }
  .navyBlue .toolBarWrapper .buttonSettingsWrapper textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    background-color: #ffffff;
    padding: 15px;
    color: #000000;
    font-family: 'Segoe UI Semi Bold';
    font-size: 14px;
    font-weight: 400; }

.navyBlue .toolBarWrapper .forIcon .css-10nd86i {
  float: left; }

.navyBlue .toolBarWrapper .forIcon .formBtns {
  padding: 0;
  border-top: none;
  margin: 0;
  float: left; }
  .navyBlue .toolBarWrapper .forIcon .formBtns Button {
    margin-left: 6px;
    padding-left: 9px;
    padding-right: 9px; }

.navyBlue .toolBarWrapper .invitationColorPicker > div {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }
  .navyBlue .toolBarWrapper .invitationColorPicker > div > div.colorSelector {
    width: 256px; }
  .navyBlue .toolBarWrapper .invitationColorPicker > div > div:nth-child(2) {
    width: 256px;
    position: absolute !important;
    z-index: 2;
    left: 15px; }
    .navyBlue .toolBarWrapper .invitationColorPicker > div > div:nth-child(2) .flexbox-fix {
      opacity: 0; }

.navyBlue .toolBarWrapper .previewMargin.customSize {
  width: 187px;
  margin-top: 36px;
  margin: 36px auto 0; }

.navyBlue .HelpOnClick-FontIconsLibrary {
  max-height: 70vh; }

.navyBlue .toolbarInvitationPreview {
  height: 150px;
  width: 320px;
  border: 1px solid #035d85;
  border-radius: 5px;
  background-color: #eeeeee;
  margin: 56px auto; }
  .navyBlue .toolbarInvitationPreview .invitationMessageWrapper {
    height: 60px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 5px;
    margin-top: 23px;
    font-size: 16px; }
  .navyBlue .toolbarInvitationPreview button.close {
    margin-right: 3px; }
  .navyBlue .toolbarInvitationPreview button.btn_chat {
    font-size: 14px;
    width: auto;
    display: block;
    padding: 11px 15px;
    border: 1px solid #7e7c7c;
    border-radius: 5px;
    cursor: pointer;
    float: none;
    margin: 0 auto; }

.navyBlue .imageLibrary img {
  padding: 4px;
  cursor: pointer; }
  .navyBlue .imageLibrary img.selected {
    background-color: #e6eef9; }

.mustardYellow .widgetWrapper h4.widgetName {
  color: #3ba8c5;
  margin-right: 22px; }

.mustardYellow .widgetWrapper .exit_btn {
  position: absolute;
  right: 37px;
  color: #3ba8c5;
  font-family: "Segoe UI";
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer; }
  .mustardYellow .widgetWrapper .exit_btn img {
    margin-right: 8px;
    margin-top: -3px; }

.mustardYellow .widgetSelectorIndicator {
  margin: 30px auto 0; }
  .mustardYellow .widgetSelectorIndicator p {
    color: #a1a4a8;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    margin-top: 25px; }

.mustardYellow .innercontent_wrapper .directLinkWrapper {
  margin-top: 56px; }
  .mustardYellow .innercontent_wrapper .directLinkWrapper h4 {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    margin-left: 18px;
    margin-bottom: 34px;
    position: relative; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper h4 span {
      border: 1px solid #3ba8c5;
      background-color: #ffffff;
      border-radius: 50%;
      position: absolute;
      margin-left: 9px;
      width: 24px;
      height: 24px;
      display: inline-block; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper h4 span img {
        position: absolute;
        top: 5px;
        left: 10px; }
  .mustardYellow .innercontent_wrapper .directLinkWrapper .with_btn input {
    float: left; }
  .mustardYellow .innercontent_wrapper .directLinkWrapper .with_btn .uploadBtn button {
    background-color: #3ba8c5; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .with_btn .uploadBtn button p {
      color: #fff; }
  .mustardYellow .innercontent_wrapper .directLinkWrapper .invitationCustomImg {
    margin-bottom: 0.5rem; }
  .mustardYellow .innercontent_wrapper .directLinkWrapper .btn_wrapper {
    margin-bottom: 15px; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .btn_wrapper .uploadBtn.iconUpload {
      margin-left: 0;
      margin-right: 5px; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .btn_wrapper .uploadBtn.iconUpload button.uploadIcon input {
        width: 60px; }
  .mustardYellow .innercontent_wrapper .directLinkWrapper .box {
    padding: 30px; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:first-child {
      width: 131px;
      margin-right: 10px; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:nth-child(2) {
      width: 117px; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:nth-child(2) .css-10nd86i {
        width: 117px; }
  .mustardYellow .innercontent_wrapper .directLinkWrapper .formBtns Button.btn_save {
    width: auto; }
  .mustardYellow .innercontent_wrapper .directLinkWrapper .customOption .row > div:nth-child(2) > div:nth-child(2) {
    position: absolute !important;
    z-index: 2;
    top: 44px;
    left: 157px; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .customOption .row > div:nth-child(2) > div:nth-child(2) .flexbox-fix {
      display: none !important; }
  .mustardYellow .innercontent_wrapper .directLinkWrapper .colorSelector .css-1wy0on6 {
    background-color: #fff; }
  .mustardYellow .innercontent_wrapper .directLinkWrapper .colorSelector input {
    border: none; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .colorSelector input:focus {
      border: none; }
  .mustardYellow .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper {
    position: relative; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper p {
      width: 178px;
      float: left;
      padding-top: 30%;
      font-size: 22px;
      text-align: right;
      text-decoration: underline; }
  .mustardYellow .innercontent_wrapper .directLinkWrapper .directLinkpreview {
    background-repeat: no-repeat;
    width: 165px;
    height: 114px;
    float: right;
    margin-top: 100px;
    margin-right: 50px;
    margin-left: 24px;
    background-size: cover !important; }
  .mustardYellow .innercontent_wrapper .directLinkWrapper .theme {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-top: 7px; }
  .mustardYellow .innercontent_wrapper .directLinkWrapper .preview {
    width: 330px;
    height: 101px;
    padding: 12px 0 0 0;
    margin-top: 40px;
    margin-bottom: 47px;
    background-repeat: no-repeat; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .preview .widget-preview {
      width: 166px;
      height: 76px; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .preview .widget-preview div {
        font-family: Arial,Helvetica,sans-serif; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-title {
        padding: 11px 0 0 18px;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        text-align: left; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-text {
        width: 146px;
        overflow: hidden;
        white-space: nowrap;
        padding: 1px 0 0;
        text-align: center;
        color: #A8A8A8;
        font-size: 12px;
        margin-left: 10px; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-copy {
        padding: 8px 13px 0;
        text-align: right;
        font-size: 10px;
        color: #fff;
        opacity: .5;
        -moz-opacity: .5;
        -khtml-opacity: .5;
        filter: alpha(opacity=50); }
  .mustardYellow .innercontent_wrapper .directLinkWrapper .widgetPosition {
    width: 330px; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .widgetPosition p {
      width: 236px;
      height: 30px;
      border-radius: 5px;
      border: 1px solid #dedede;
      background-color: #ffffff;
      color: #000000;
      font-family: Segoe UI;
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 37px;
      margin-left: auto;
      margin-right: auto; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .widgetPosition p span {
        width: 50%;
        display: inline-block;
        padding: 6px 0;
        cursor: pointer; }
        .mustardYellow .innercontent_wrapper .directLinkWrapper .widgetPosition p span:first-child {
          border-radius: 5px 0 0 5px; }
        .mustardYellow .innercontent_wrapper .directLinkWrapper .widgetPosition p span:last-child {
          border-radius: 0 5px 5px 0; }
        .mustardYellow .innercontent_wrapper .directLinkWrapper .widgetPosition p span.selected {
          color: #ffffff;
          background-color: #3ba8c5; }
  .mustardYellow .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper {
    width: 90%;
    margin: 0 auto; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp {
      width: 34px;
      height: 34px;
      border: 1px solid #dedede;
      border-radius: 50%;
      float: left;
      padding: 3px;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp div {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        margin: 0; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:hover, .mustardYellow .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp.selected {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        transition: -webkit-transform 100ms ease 0s;
        transition: transform 100ms ease 0s;
        transition: transform 100ms ease 0s, -webkit-transform 100ms ease 0s;
        padding: 2px; }
        .mustardYellow .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:hover div, .mustardYellow .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp.selected div {
          -webkit-transform: scale(0.9);
                  transform: scale(0.9); }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(1):hover {
        border: 2px solid #0475A8; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(2):hover {
        border: 2px solid #5F8706; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(3):hover {
        border: 2px solid #D6680B; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(4):hover {
        border: 2px solid #B0097D; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(5):hover {
        border: 2px solid #9D0A09; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(6):hover {
        border: 2px solid #424E54; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(7):hover {
        border: 2px solid #037FAF; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(8):hover {
        border: 2px solid #608706; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(9):hover {
        border: 2px solid #D26009; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(10):hover {
        border: 2px solid #AF097D; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(11):hover {
        border: 2px solid #950909; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(12):hover {
        border: 2px solid #3E484E; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(13):hover {
        border: 2px solid #0476A8; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(14):hover {
        border: 2px solid #98B45D; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(15):hover {
        border: 2px solid #ADADAD; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(16):hover {
        border: 2px solid #98A7AD; }
  .mustardYellow .innercontent_wrapper .directLinkWrapper .circle-picker {
    padding: 0px 9px; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .circle-picker > span > div > span {
      padding: 4px;
      position: relative;
      border: 1px solid #dedede;
      border-radius: 50%;
      display: inline-block;
      width: 34px;
      height: 34px; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(1) > div:hover span {
      border: 2px solid #0475A8; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(1) > div > span > div {
      box-shadow: #0475A8 0px 0px 0px 17px inset !important; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(2) > div:hover span {
      border: 2px solid #5F8706; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(3) > div:hover span {
      border: 2px solid #D6680B; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(4) > div:hover span {
      border: 2px solid #B0097D; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(5) > div:hover span {
      border: 2px solid #9D0A09; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(6) > div:hover span {
      border: 2px solid #424E54; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(7) > div:hover span {
      border: 2px solid #037FAF; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(8) > div:hover span {
      border: 2px solid #587D06; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(9) > div:hover span {
      border: 2px solid #D26009; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(10) > div:hover span {
      border: 2px solid #AF087C; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(11) > div:hover span {
      border: 2px solid #950909; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(12) > div:hover span {
      border: 2px solid #3E484E; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(13) > div:hover span {
      border: 2px solid #0476A8; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(14) > div:hover span {
      border: 2px solid #98B45D; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(15) > div:hover span {
      border: 2px solid #ADADAD; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(16) > div:hover span {
      border: 2px solid #98A7AD; }
  .mustardYellow .innercontent_wrapper .directLinkWrapper .iconWrapper {
    height: 390px;
    position: relative; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn {
      position: absolute;
      width: 26px;
      height: 27px;
      border: 1px solid #dedede;
      border-radius: 50%;
      top: -27px;
      right: 6px;
      cursor: pointer; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn img {
        margin-top: -2px;
        margin-left: 1px; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn.bottomScroll {
        top: auto;
        bottom: -27px; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow {
      margin-bottom: 13px;
      margin-right: 30px;
      border: 1px solid #dedede;
      border-radius: 10px;
      cursor: pointer; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div {
        width: 151px;
        height: 87px;
        background-color: #ffffff;
        margin-right: 12px;
        padding: 16px;
        border-radius: 10px; }
        .mustardYellow .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div.no-image {
          opacity: 0; }
        .mustardYellow .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div:last-child {
          margin-right: 0; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow:last-child {
        margin-bottom: 0; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow.selected {
        background-color: #dedede; }
        .mustardYellow .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow.selected > div {
          background-color: #dedede; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .iconWrapper .rcs-custom-scroll .rcs-inner-handle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #3ba8c5;
      background-color: #3ba8c5;
      margin-left: -8px; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .iconWrapper .rcs-custom-scroll .rcs-custom-scrollbar {
      opacity: 1;
      right: 13px;
      border-left: 2px solid #dedede;
      height: 590px; }
  .mustardYellow .innercontent_wrapper .directLinkWrapper .uploadBtn {
    margin-top: 13px; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .uploadBtn.iconUpload {
      margin-top: 5px;
      margin-left: 6px;
      float: left; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper .uploadBtn button {
      width: 218px;
      height: 29px;
      border-radius: 3px;
      border: 1px solid #3ba8c5;
      background-color: #ffffff;
      position: relative;
      padding: 0; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .uploadBtn button.uploadIcon {
        width: auto;
        float: left;
        padding: 0 5px; }
        .mustardYellow .innercontent_wrapper .directLinkWrapper .uploadBtn button.uploadIcon input {
          width: auto; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .uploadBtn button p {
        margin: 0;
        color: #3ba8c5;
        font-family: "Segoe UI";
        font-size: 13px;
        font-weight: 700;
        line-height: 30.4px;
        text-transform: uppercase; }
        .mustardYellow .innercontent_wrapper .directLinkWrapper .uploadBtn button p img {
          margin-right: 4px;
          margin-top: -4px; }
      .mustardYellow .innercontent_wrapper .directLinkWrapper .uploadBtn button input {
        position: absolute;
        top: 0;
        left: 0;
        width: 218px;
        height: 29px;
        opacity: 0;
        cursor: pointer; }
  .mustardYellow .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span {
    margin-right: 15px;
    color: #3ba8c5;
    text-decoration: underline;
    cursor: pointer; }
    .mustardYellow .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span.selected {
      text-decoration: none;
      color: #000000; }

.mustardYellow .carousel {
  padding: 12px 0px; }
  .mustardYellow .carousel img.carouselBtn {
    position: absolute;
    cursor: pointer;
    top: 90px; }
    .mustardYellow .carousel img.carouselBtn.carousel-left {
      left: 30px; }
  .mustardYellow .carousel ul {
    margin-left: 0;
    margin-right: 0;
    transition: all 1s; }
    .mustardYellow .carousel ul.widgetMode li {
      opacity: 0.5;
      float: left; }
      .mustardYellow .carousel ul.widgetMode li.selected, .mustardYellow .carousel ul.widgetMode li:hover {
        opacity: 1;
        border: 3px solid #3ba8c5; }
        .mustardYellow .carousel ul.widgetMode li.selected h4, .mustardYellow .carousel ul.widgetMode li:hover h4 {
          background-color: #3ba8c5;
          color: #ffffff; }
  .mustardYellow .carousel .carousel-cell {
    cursor: pointer;
    width: 210px;
    height: 192px;
    box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
    border-radius: 20px;
    background-color: #ffffff;
    padding: 0px;
    display: inline-block;
    margin-right: 20px;
    float: left; }
    .mustardYellow .carousel .carousel-cell.opacity_0 {
      opacity: 0;
      margin-right: 60px;
      transition: opacity 2s; }
    .mustardYellow .carousel .carousel-cell:hover {
      border: 3px solid #3ba8c5; }
      .mustardYellow .carousel .carousel-cell:hover h4 {
        background-color: #3ba8c5;
        color: #ffffff; }
    .mustardYellow .carousel .carousel-cell h4 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      padding: 22px 0 18px;
      border-bottom: 4px solid #3ba8c5;
      margin: 0;
      border-radius: 15px 15px 0 0; }
    .mustardYellow .carousel .carousel-cell .img-wrapper {
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      width: 210px; }

.mustardYellow .settingFormWrapper .codeWrapper {
  margin-top: 69px; }
  .mustardYellow .settingFormWrapper .codeWrapper p {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    text-align: left; }
  .mustardYellow .settingFormWrapper .codeWrapper textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    padding: 13px;
    margin-bottom: 10px; }
  .mustardYellow .settingFormWrapper .codeWrapper .formBtns {
    padding: 0;
    margin: 0;
    border: none; }
    .mustardYellow .settingFormWrapper .codeWrapper .formBtns Button.btn_clr {
      margin-left: 0;
      margin-right: 10px;
      width: auto;
      padding: 9px 30px; }
      .mustardYellow .settingFormWrapper .codeWrapper .formBtns Button.btn_clr p {
        text-align: center;
        color: #3ba8c5; }
  .mustardYellow .settingFormWrapper .codeWrapper .advancedSection {
    margin-top: -48px; }
    .mustardYellow .settingFormWrapper .codeWrapper .advancedSection .advancedOption p {
      color: #3ba8c5;
      font-family: "Segoe UI Bold";
      font-size: 14px;
      font-weight: 700; }
      .mustardYellow .settingFormWrapper .codeWrapper .advancedSection .advancedOption p:first-child {
        width: 27px;
        height: 27px;
        border-radius: 50%;
        border: 1px solid #e89712;
        background-color: #e89712;
        background-image: url("assets/images/expand.png");
        background-repeat: no-repeat;
        background-position: center;
        padding: 0;
        float: left;
        margin-right: 9px; }
      .mustardYellow .settingFormWrapper .codeWrapper .advancedSection .advancedOption p:last-child {
        margin-top: 20px; }
    .mustardYellow .settingFormWrapper .codeWrapper .advancedSection .advancedOption.collapsed p:first-child {
      border: 1px solid #dedede;
      background-color: #ffffff;
      background-image: url("assets/images/plus_icons/plus_yellow.png");
      background-size: 11px; }
    .mustardYellow .settingFormWrapper .codeWrapper .advancedSection .advancedOption.dropdown-toggle::after {
      display: none; }

.mustardYellow .mailer .modal-dialog textarea {
  border-radius: 5px;
  border: 1px solid #dedede;
  padding: 13px;
  width: 367px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px; }

.mustardYellow .mailer .modal-dialog label {
  text-align: left;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.mustardYellow .mailer .modal-dialog .form-control {
  width: 256px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.mustardYellow .toolBarWrapper .toolTipDesignWrapper {
  border-radius: 10px;
  background-color: #f6f6f6;
  padding: 20px 20px 4px; }

.mustardYellow .toolBarWrapper .toolBarMenuWrapper {
  height: 53px;
  margin-bottom: 36px;
  position: relative; }
  .mustardYellow .toolBarWrapper .toolBarMenuWrapper .toolBarBoxWrapper {
    position: absolute;
    height: 13px;
    width: 100%;
    top: -14px;
    left: 0;
    background-color: #fff;
    z-index: 7; }
    .mustardYellow .toolBarWrapper .toolBarMenuWrapper .toolBarBoxWrapper.bottom {
      top: auto;
      bottom: -13px; }
  .mustardYellow .toolBarWrapper .toolBarMenuWrapper .toolBarBox {
    position: absolute;
    border: 1px solid #dedede;
    height: 54px;
    width: 98%;
    border-right: 0;
    top: -1px;
    z-index: 5;
    left: 0;
    pointer-events: none; }
  .mustardYellow .toolBarWrapper .toolBarMenuWrapper .menuList {
    height: 54px;
    background-color: #fff;
    cursor: pointer; }
    .mustardYellow .toolBarWrapper .toolBarMenuWrapper .menuList.selected {
      background-color: #3ba8c5; }
      .mustardYellow .toolBarWrapper .toolBarMenuWrapper .menuList.selected p {
        color: #fff; }
      .mustardYellow .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line1 {
        background-color: #fff;
        border-color: #3ba8c5; }
      .mustardYellow .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line2 {
        border-color: #3ba8c5; }
        .mustardYellow .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line2.mask {
          opacity: 1;
          background-color: #fff; }
    .mustardYellow .toolBarWrapper .toolBarMenuWrapper .menuList p {
      width: 100%;
      float: left;
      color: #293037;
      font-family: "Segoe UI Semi Bold";
      font-size: 13px;
      font-weight: 700; }
    .mustardYellow .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper {
      position: absolute;
      right: 21px; }
      .mustardYellow .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line1 {
        width: 35px;
        height: 20px;
        border-bottom: 1px solid #dedede;
        -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
        position: absolute; }
        .mustardYellow .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line1.selectedNext {
          background-color: #3ba8c5;
          border-color: #fff; }
      .mustardYellow .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2 {
        width: 33px;
        height: 20px;
        border-bottom: 1px solid #dedede;
        -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
        position: absolute;
        top: 17px;
        left: -16px; }
        .mustardYellow .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.mask {
          opacity: 0;
          left: -1px;
          width: 37px;
          top: 29px;
          background-color: #fff;
          border: 0; }
          .mustardYellow .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.mask.selectedNext {
            opacity: 1;
            background-color: #3ba8c5; }
        .mustardYellow .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.selectedNext {
          border-color: #fff; }

.mustardYellow .toolBarWrapper .form-label.chatWindow {
  padding-top: 3px; }

.mustardYellow .toolBarWrapper .chat_windowWrapper .btn-group-toggle {
  display: inline-block; }
  .mustardYellow .toolBarWrapper .chat_windowWrapper .btn-group-toggle .custom_radionBtn {
    font-size: 14px;
    font-family: 'Segoe UI Semi Bold'; }
  .mustardYellow .toolBarWrapper .chat_windowWrapper .btn-group-toggle > label {
    margin-bottom: 17px;
    text-align: left; }
    .mustardYellow .toolBarWrapper .chat_windowWrapper .btn-group-toggle > label:last-child {
      margin-bottom: 14px; }

.mustardYellow .toolBarWrapper .settingFormWrapper .customSize .form-label {
  font-size: 13px; }

.mustardYellow .toolBarWrapper .settingFormWrapper .customSize .form-control {
  width: 62px;
  height: 29px;
  border-radius: 3px;
  float: left;
  margin-right: 12px;
  font-size: 13px; }

.mustardYellow .toolBarWrapper .settingFormWrapper .customSize span {
  float: left;
  margin-top: 6px;
  font-size: 13px; }

.mustardYellow .toolBarWrapper .toolBarPreviewWrapper {
  height: 302px;
  width: 418px;
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("assets/images/toolBarPreviewBg.png");
  position: relative; }
  .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .positionIcons {
    position: absolute;
    border: 1px solid #dedede;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    cursor: pointer; }
    .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .positionIcons.selected {
      background-color: #3ba8c5; }
    .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .positionIcons.left {
      left: -11px;
      top: 44%;
      padding-right: 2px;
      padding-top: 1px; }
    .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .positionIcons.right {
      right: -11px;
      top: 44%;
      padding-left: 2px;
      padding-top: 1px; }
    .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .positionIcons.center {
      bottom: -30px;
      left: 50%;
      padding-top: 2px;
      padding-left: 1px; }
    .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .positionIcons.bottomLeft {
      bottom: -28px;
      left: -11px;
      padding-left: 4px; }
    .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .positionIcons.bottomRight {
      bottom: -28px;
      right: -11px;
      padding-right: 4px; }
  .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .toolBarWrapper {
    width: 366px;
    height: 250px;
    position: absolute;
    top: 25px;
    left: 25px; }
  .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .ulWrapper {
    position: absolute;
    max-width: 366px;
    max-height: 250px;
    overflow: auto; }
    .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.bar_vertical .btn_wrapper {
      height: 54px; }
      .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.bar_vertical .btn_wrapper .btn_icon.HelpOnClick-WithText {
        margin-left: auto;
        margin-right: auto; }
    .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.transform {
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
  .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns {
    list-style: none;
    height: 41px;
    padding: 0;
    margin: 0;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
    .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper {
      border-right: 1px solid;
      border-left: 1px solid;
      height: 100%;
      float: left;
      min-width: 30px;
      min-height: 30px;
      margin-bottom: 0;
      display: table; }
      .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper:first-child {
        border-left: 0; }
      .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper:last-child {
        border-right: 0; }
      .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div, .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a {
        display: table-cell;
        vertical-align: middle; }
        .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div div.preview_row, .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a div.preview_row {
          display: table-row;
          height: 100%;
          width: 100%; }
        .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div div.preview_clmn, .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a div.preview_clmn {
          display: table-cell;
          vertical-align: middle; }
        .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div > div, .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div {
          display: inline-table;
          vertical-align: middle;
          height: 100%; }
      .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_icon {
        margin-top: -1px;
        margin-left: 14px;
        margin-right: 14px; }
        .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_icon.HelpOnClick-WithText {
          margin-left: 12px;
          margin-right: 0; }
      .mustardYellow .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_caption {
        color: #fff;
        display: table-cell;
        font-weight: 700;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 8px;
        padding-right: 15px;
        vertical-align: middle; }

.mustardYellow .toolBarWrapper .Tooltip__Base-sc-19cbsjq-0 {
  width: 200px !important; }

.mustardYellow .toolBarWrapper .Tooltip__Left-sc-19cbsjq-3 {
  right: 20px; }

.mustardYellow .toolBarWrapper .customDrpDwn.customDrpDwn-small .css-10nd86i {
  width: 141px;
  float: left; }

.mustardYellow .toolBarWrapper .customDrpDwn.customDrpDwn-small .customSize .form-control {
  width: 51px;
  height: 37px;
  margin-right: 9px; }

.mustardYellow .toolBarWrapper .customDrpDwn.customDrpDwn-small .customSize span {
  color: #7d7d7d;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }

.mustardYellow .toolBarWrapper .buttonSettingsWrapper {
  box-shadow: none;
  border-radius: 0;
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content; }
  .mustardYellow .toolBarWrapper .buttonSettingsWrapper .buttonsSection {
    margin-left: 7%;
    border-radius: 10px;
    background-color: #f6f6f6;
    padding: 26px 20px 15px; }
    .mustardYellow .toolBarWrapper .buttonSettingsWrapper .buttonsSection .editorWrapper .editorToolBar .editorLink {
      display: none; }
  .mustardYellow .toolBarWrapper .buttonSettingsWrapper .customDrpDwn .css-15k3avv {
    z-index: 10; }
  .mustardYellow .toolBarWrapper .buttonSettingsWrapper textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    background-color: #ffffff;
    padding: 15px;
    color: #000000;
    font-family: 'Segoe UI Semi Bold';
    font-size: 14px;
    font-weight: 400; }

.mustardYellow .toolBarWrapper .forIcon .css-10nd86i {
  float: left; }

.mustardYellow .toolBarWrapper .forIcon .formBtns {
  padding: 0;
  border-top: none;
  margin: 0;
  float: left; }
  .mustardYellow .toolBarWrapper .forIcon .formBtns Button {
    margin-left: 6px;
    padding-left: 9px;
    padding-right: 9px; }

.mustardYellow .toolBarWrapper .invitationColorPicker > div {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }
  .mustardYellow .toolBarWrapper .invitationColorPicker > div > div.colorSelector {
    width: 256px; }
  .mustardYellow .toolBarWrapper .invitationColorPicker > div > div:nth-child(2) {
    width: 256px;
    position: absolute !important;
    z-index: 2;
    left: 15px; }
    .mustardYellow .toolBarWrapper .invitationColorPicker > div > div:nth-child(2) .flexbox-fix {
      opacity: 0; }

.mustardYellow .toolBarWrapper .previewMargin.customSize {
  width: 187px;
  margin-top: 36px;
  margin: 36px auto 0; }

.mustardYellow .HelpOnClick-FontIconsLibrary {
  max-height: 70vh; }

.mustardYellow .toolbarInvitationPreview {
  height: 150px;
  width: 320px;
  border: 1px solid #035d85;
  border-radius: 5px;
  background-color: #eeeeee;
  margin: 56px auto; }
  .mustardYellow .toolbarInvitationPreview .invitationMessageWrapper {
    height: 60px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 5px;
    margin-top: 23px;
    font-size: 16px; }
  .mustardYellow .toolbarInvitationPreview button.close {
    margin-right: 3px; }
  .mustardYellow .toolbarInvitationPreview button.btn_chat {
    font-size: 14px;
    width: auto;
    display: block;
    padding: 11px 15px;
    border: 1px solid #7e7c7c;
    border-radius: 5px;
    cursor: pointer;
    float: none;
    margin: 0 auto; }

.mustardYellow .imageLibrary img {
  padding: 4px;
  cursor: pointer; }
  .mustardYellow .imageLibrary img.selected {
    background-color: #e6eef9; }

.violet .widgetWrapper h4.widgetName {
  color: #3ba8c5;
  margin-right: 22px; }

.violet .widgetWrapper .exit_btn {
  position: absolute;
  right: 37px;
  color: #3ba8c5;
  font-family: "Segoe UI";
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer; }
  .violet .widgetWrapper .exit_btn img {
    margin-right: 8px;
    margin-top: -3px; }

.violet .widgetSelectorIndicator {
  margin: 30px auto 0; }
  .violet .widgetSelectorIndicator p {
    color: #a1a4a8;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    margin-top: 25px; }

.violet .innercontent_wrapper .directLinkWrapper {
  margin-top: 56px; }
  .violet .innercontent_wrapper .directLinkWrapper h4 {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    margin-left: 18px;
    margin-bottom: 34px;
    position: relative; }
    .violet .innercontent_wrapper .directLinkWrapper h4 span {
      border: 1px solid #3ba8c5;
      background-color: #ffffff;
      border-radius: 50%;
      position: absolute;
      margin-left: 9px;
      width: 24px;
      height: 24px;
      display: inline-block; }
      .violet .innercontent_wrapper .directLinkWrapper h4 span img {
        position: absolute;
        top: 5px;
        left: 10px; }
  .violet .innercontent_wrapper .directLinkWrapper .with_btn input {
    float: left; }
  .violet .innercontent_wrapper .directLinkWrapper .with_btn .uploadBtn button {
    background-color: #3ba8c5; }
    .violet .innercontent_wrapper .directLinkWrapper .with_btn .uploadBtn button p {
      color: #fff; }
  .violet .innercontent_wrapper .directLinkWrapper .invitationCustomImg {
    margin-bottom: 0.5rem; }
  .violet .innercontent_wrapper .directLinkWrapper .btn_wrapper {
    margin-bottom: 15px; }
    .violet .innercontent_wrapper .directLinkWrapper .btn_wrapper .uploadBtn.iconUpload {
      margin-left: 0;
      margin-right: 5px; }
      .violet .innercontent_wrapper .directLinkWrapper .btn_wrapper .uploadBtn.iconUpload button.uploadIcon input {
        width: 60px; }
  .violet .innercontent_wrapper .directLinkWrapper .box {
    padding: 30px; }
    .violet .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:first-child {
      width: 131px;
      margin-right: 10px; }
    .violet .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:nth-child(2) {
      width: 117px; }
      .violet .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:nth-child(2) .css-10nd86i {
        width: 117px; }
  .violet .innercontent_wrapper .directLinkWrapper .formBtns Button.btn_save {
    width: auto; }
  .violet .innercontent_wrapper .directLinkWrapper .customOption .row > div:nth-child(2) > div:nth-child(2) {
    position: absolute !important;
    z-index: 2;
    top: 44px;
    left: 157px; }
    .violet .innercontent_wrapper .directLinkWrapper .customOption .row > div:nth-child(2) > div:nth-child(2) .flexbox-fix {
      display: none !important; }
  .violet .innercontent_wrapper .directLinkWrapper .colorSelector .css-1wy0on6 {
    background-color: #fff; }
  .violet .innercontent_wrapper .directLinkWrapper .colorSelector input {
    border: none; }
    .violet .innercontent_wrapper .directLinkWrapper .colorSelector input:focus {
      border: none; }
  .violet .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper {
    position: relative; }
    .violet .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper p {
      width: 178px;
      float: left;
      padding-top: 30%;
      font-size: 22px;
      text-align: right;
      text-decoration: underline; }
  .violet .innercontent_wrapper .directLinkWrapper .directLinkpreview {
    background-repeat: no-repeat;
    width: 165px;
    height: 114px;
    float: right;
    margin-top: 100px;
    margin-right: 50px;
    margin-left: 24px;
    background-size: cover !important; }
  .violet .innercontent_wrapper .directLinkWrapper .theme {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-top: 7px; }
  .violet .innercontent_wrapper .directLinkWrapper .preview {
    width: 330px;
    height: 101px;
    padding: 12px 0 0 0;
    margin-top: 40px;
    margin-bottom: 47px;
    background-repeat: no-repeat; }
    .violet .innercontent_wrapper .directLinkWrapper .preview .widget-preview {
      width: 166px;
      height: 76px; }
      .violet .innercontent_wrapper .directLinkWrapper .preview .widget-preview div {
        font-family: Arial,Helvetica,sans-serif; }
      .violet .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-title {
        padding: 11px 0 0 18px;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        text-align: left; }
      .violet .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-text {
        width: 146px;
        overflow: hidden;
        white-space: nowrap;
        padding: 1px 0 0;
        text-align: center;
        color: #A8A8A8;
        font-size: 12px;
        margin-left: 10px; }
      .violet .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-copy {
        padding: 8px 13px 0;
        text-align: right;
        font-size: 10px;
        color: #fff;
        opacity: .5;
        -moz-opacity: .5;
        -khtml-opacity: .5;
        filter: alpha(opacity=50); }
  .violet .innercontent_wrapper .directLinkWrapper .widgetPosition {
    width: 330px; }
    .violet .innercontent_wrapper .directLinkWrapper .widgetPosition p {
      width: 236px;
      height: 30px;
      border-radius: 5px;
      border: 1px solid #dedede;
      background-color: #ffffff;
      color: #000000;
      font-family: Segoe UI;
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 37px;
      margin-left: auto;
      margin-right: auto; }
      .violet .innercontent_wrapper .directLinkWrapper .widgetPosition p span {
        width: 50%;
        display: inline-block;
        padding: 6px 0;
        cursor: pointer; }
        .violet .innercontent_wrapper .directLinkWrapper .widgetPosition p span:first-child {
          border-radius: 5px 0 0 5px; }
        .violet .innercontent_wrapper .directLinkWrapper .widgetPosition p span:last-child {
          border-radius: 0 5px 5px 0; }
        .violet .innercontent_wrapper .directLinkWrapper .widgetPosition p span.selected {
          color: #ffffff;
          background-color: #3ba8c5; }
  .violet .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper {
    width: 90%;
    margin: 0 auto; }
    .violet .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp {
      width: 34px;
      height: 34px;
      border: 1px solid #dedede;
      border-radius: 50%;
      float: left;
      padding: 3px;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer; }
      .violet .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp div {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        margin: 0; }
      .violet .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:hover, .violet .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp.selected {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        transition: -webkit-transform 100ms ease 0s;
        transition: transform 100ms ease 0s;
        transition: transform 100ms ease 0s, -webkit-transform 100ms ease 0s;
        padding: 2px; }
        .violet .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:hover div, .violet .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp.selected div {
          -webkit-transform: scale(0.9);
                  transform: scale(0.9); }
      .violet .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(1):hover {
        border: 2px solid #0475A8; }
      .violet .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(2):hover {
        border: 2px solid #5F8706; }
      .violet .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(3):hover {
        border: 2px solid #D6680B; }
      .violet .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(4):hover {
        border: 2px solid #B0097D; }
      .violet .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(5):hover {
        border: 2px solid #9D0A09; }
      .violet .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(6):hover {
        border: 2px solid #424E54; }
      .violet .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(7):hover {
        border: 2px solid #037FAF; }
      .violet .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(8):hover {
        border: 2px solid #608706; }
      .violet .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(9):hover {
        border: 2px solid #D26009; }
      .violet .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(10):hover {
        border: 2px solid #AF097D; }
      .violet .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(11):hover {
        border: 2px solid #950909; }
      .violet .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(12):hover {
        border: 2px solid #3E484E; }
      .violet .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(13):hover {
        border: 2px solid #0476A8; }
      .violet .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(14):hover {
        border: 2px solid #98B45D; }
      .violet .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(15):hover {
        border: 2px solid #ADADAD; }
      .violet .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(16):hover {
        border: 2px solid #98A7AD; }
  .violet .innercontent_wrapper .directLinkWrapper .circle-picker {
    padding: 0px 9px; }
    .violet .innercontent_wrapper .directLinkWrapper .circle-picker > span > div > span {
      padding: 4px;
      position: relative;
      border: 1px solid #dedede;
      border-radius: 50%;
      display: inline-block;
      width: 34px;
      height: 34px; }
    .violet .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(1) > div:hover span {
      border: 2px solid #0475A8; }
    .violet .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(1) > div > span > div {
      box-shadow: #0475A8 0px 0px 0px 17px inset !important; }
    .violet .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(2) > div:hover span {
      border: 2px solid #5F8706; }
    .violet .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(3) > div:hover span {
      border: 2px solid #D6680B; }
    .violet .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(4) > div:hover span {
      border: 2px solid #B0097D; }
    .violet .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(5) > div:hover span {
      border: 2px solid #9D0A09; }
    .violet .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(6) > div:hover span {
      border: 2px solid #424E54; }
    .violet .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(7) > div:hover span {
      border: 2px solid #037FAF; }
    .violet .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(8) > div:hover span {
      border: 2px solid #587D06; }
    .violet .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(9) > div:hover span {
      border: 2px solid #D26009; }
    .violet .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(10) > div:hover span {
      border: 2px solid #AF087C; }
    .violet .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(11) > div:hover span {
      border: 2px solid #950909; }
    .violet .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(12) > div:hover span {
      border: 2px solid #3E484E; }
    .violet .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(13) > div:hover span {
      border: 2px solid #0476A8; }
    .violet .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(14) > div:hover span {
      border: 2px solid #98B45D; }
    .violet .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(15) > div:hover span {
      border: 2px solid #ADADAD; }
    .violet .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(16) > div:hover span {
      border: 2px solid #98A7AD; }
  .violet .innercontent_wrapper .directLinkWrapper .iconWrapper {
    height: 390px;
    position: relative; }
    .violet .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn {
      position: absolute;
      width: 26px;
      height: 27px;
      border: 1px solid #dedede;
      border-radius: 50%;
      top: -27px;
      right: 6px;
      cursor: pointer; }
      .violet .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn img {
        margin-top: -2px;
        margin-left: 1px; }
      .violet .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn.bottomScroll {
        top: auto;
        bottom: -27px; }
    .violet .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow {
      margin-bottom: 13px;
      margin-right: 30px;
      border: 1px solid #dedede;
      border-radius: 10px;
      cursor: pointer; }
      .violet .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div {
        width: 151px;
        height: 87px;
        background-color: #ffffff;
        margin-right: 12px;
        padding: 16px;
        border-radius: 10px; }
        .violet .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div.no-image {
          opacity: 0; }
        .violet .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div:last-child {
          margin-right: 0; }
      .violet .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow:last-child {
        margin-bottom: 0; }
      .violet .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow.selected {
        background-color: #dedede; }
        .violet .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow.selected > div {
          background-color: #dedede; }
    .violet .innercontent_wrapper .directLinkWrapper .iconWrapper .rcs-custom-scroll .rcs-inner-handle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #3ba8c5;
      background-color: #3ba8c5;
      margin-left: -8px; }
    .violet .innercontent_wrapper .directLinkWrapper .iconWrapper .rcs-custom-scroll .rcs-custom-scrollbar {
      opacity: 1;
      right: 13px;
      border-left: 2px solid #dedede;
      height: 590px; }
  .violet .innercontent_wrapper .directLinkWrapper .uploadBtn {
    margin-top: 13px; }
    .violet .innercontent_wrapper .directLinkWrapper .uploadBtn.iconUpload {
      margin-top: 5px;
      margin-left: 6px;
      float: left; }
    .violet .innercontent_wrapper .directLinkWrapper .uploadBtn button {
      width: 218px;
      height: 29px;
      border-radius: 3px;
      border: 1px solid #3ba8c5;
      background-color: #ffffff;
      position: relative;
      padding: 0; }
      .violet .innercontent_wrapper .directLinkWrapper .uploadBtn button.uploadIcon {
        width: auto;
        float: left;
        padding: 0 5px; }
        .violet .innercontent_wrapper .directLinkWrapper .uploadBtn button.uploadIcon input {
          width: auto; }
      .violet .innercontent_wrapper .directLinkWrapper .uploadBtn button p {
        margin: 0;
        color: #3ba8c5;
        font-family: "Segoe UI";
        font-size: 13px;
        font-weight: 700;
        line-height: 30.4px;
        text-transform: uppercase; }
        .violet .innercontent_wrapper .directLinkWrapper .uploadBtn button p img {
          margin-right: 4px;
          margin-top: -4px; }
      .violet .innercontent_wrapper .directLinkWrapper .uploadBtn button input {
        position: absolute;
        top: 0;
        left: 0;
        width: 218px;
        height: 29px;
        opacity: 0;
        cursor: pointer; }
  .violet .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span {
    margin-right: 15px;
    color: #3ba8c5;
    text-decoration: underline;
    cursor: pointer; }
    .violet .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span.selected {
      text-decoration: none;
      color: #000000; }

.violet .carousel {
  padding: 12px 0px; }
  .violet .carousel img.carouselBtn {
    position: absolute;
    cursor: pointer;
    top: 90px; }
    .violet .carousel img.carouselBtn.carousel-left {
      left: 30px; }
  .violet .carousel ul {
    margin-left: 0;
    margin-right: 0;
    transition: all 1s; }
    .violet .carousel ul.widgetMode li {
      opacity: 0.5;
      float: left; }
      .violet .carousel ul.widgetMode li.selected, .violet .carousel ul.widgetMode li:hover {
        opacity: 1;
        border: 3px solid #3ba8c5; }
        .violet .carousel ul.widgetMode li.selected h4, .violet .carousel ul.widgetMode li:hover h4 {
          background-color: #3ba8c5;
          color: #ffffff; }
  .violet .carousel .carousel-cell {
    cursor: pointer;
    width: 210px;
    height: 192px;
    box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
    border-radius: 20px;
    background-color: #ffffff;
    padding: 0px;
    display: inline-block;
    margin-right: 20px;
    float: left; }
    .violet .carousel .carousel-cell.opacity_0 {
      opacity: 0;
      margin-right: 60px;
      transition: opacity 2s; }
    .violet .carousel .carousel-cell:hover {
      border: 3px solid #3ba8c5; }
      .violet .carousel .carousel-cell:hover h4 {
        background-color: #3ba8c5;
        color: #ffffff; }
    .violet .carousel .carousel-cell h4 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      padding: 22px 0 18px;
      border-bottom: 4px solid #3ba8c5;
      margin: 0;
      border-radius: 15px 15px 0 0; }
    .violet .carousel .carousel-cell .img-wrapper {
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      width: 210px; }

.violet .settingFormWrapper .codeWrapper {
  margin-top: 69px; }
  .violet .settingFormWrapper .codeWrapper p {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    text-align: left; }
  .violet .settingFormWrapper .codeWrapper textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    padding: 13px;
    margin-bottom: 10px; }
  .violet .settingFormWrapper .codeWrapper .formBtns {
    padding: 0;
    margin: 0;
    border: none; }
    .violet .settingFormWrapper .codeWrapper .formBtns Button.btn_clr {
      margin-left: 0;
      margin-right: 10px;
      width: auto;
      padding: 9px 30px; }
      .violet .settingFormWrapper .codeWrapper .formBtns Button.btn_clr p {
        text-align: center;
        color: #3ba8c5; }
  .violet .settingFormWrapper .codeWrapper .advancedSection {
    margin-top: -48px; }
    .violet .settingFormWrapper .codeWrapper .advancedSection .advancedOption p {
      color: #3ba8c5;
      font-family: "Segoe UI Bold";
      font-size: 14px;
      font-weight: 700; }
      .violet .settingFormWrapper .codeWrapper .advancedSection .advancedOption p:first-child {
        width: 27px;
        height: 27px;
        border-radius: 50%;
        border: 1px solid #b968c7;
        background-color: #b968c7;
        background-image: url("assets/images/expand.png");
        background-repeat: no-repeat;
        background-position: center;
        padding: 0;
        float: left;
        margin-right: 9px; }
      .violet .settingFormWrapper .codeWrapper .advancedSection .advancedOption p:last-child {
        margin-top: 20px; }
    .violet .settingFormWrapper .codeWrapper .advancedSection .advancedOption.collapsed p:first-child {
      border: 1px solid #dedede;
      background-color: #ffffff;
      background-image: url("assets/images/plus_icons/plus_voilet.png");
      background-size: 11px; }
    .violet .settingFormWrapper .codeWrapper .advancedSection .advancedOption.dropdown-toggle::after {
      display: none; }

.violet .mailer .modal-dialog textarea {
  border-radius: 5px;
  border: 1px solid #dedede;
  padding: 13px;
  width: 367px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px; }

.violet .mailer .modal-dialog label {
  text-align: left;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.violet .mailer .modal-dialog .form-control {
  width: 256px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.violet .toolBarWrapper .toolTipDesignWrapper {
  border-radius: 10px;
  background-color: #f6f6f6;
  padding: 20px 20px 4px; }

.violet .toolBarWrapper .toolBarMenuWrapper {
  height: 53px;
  margin-bottom: 36px;
  position: relative; }
  .violet .toolBarWrapper .toolBarMenuWrapper .toolBarBoxWrapper {
    position: absolute;
    height: 13px;
    width: 100%;
    top: -14px;
    left: 0;
    background-color: #fff;
    z-index: 7; }
    .violet .toolBarWrapper .toolBarMenuWrapper .toolBarBoxWrapper.bottom {
      top: auto;
      bottom: -13px; }
  .violet .toolBarWrapper .toolBarMenuWrapper .toolBarBox {
    position: absolute;
    border: 1px solid #dedede;
    height: 54px;
    width: 98%;
    border-right: 0;
    top: -1px;
    z-index: 5;
    left: 0;
    pointer-events: none; }
  .violet .toolBarWrapper .toolBarMenuWrapper .menuList {
    height: 54px;
    background-color: #fff;
    cursor: pointer; }
    .violet .toolBarWrapper .toolBarMenuWrapper .menuList.selected {
      background-color: #3ba8c5; }
      .violet .toolBarWrapper .toolBarMenuWrapper .menuList.selected p {
        color: #fff; }
      .violet .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line1 {
        background-color: #fff;
        border-color: #3ba8c5; }
      .violet .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line2 {
        border-color: #3ba8c5; }
        .violet .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line2.mask {
          opacity: 1;
          background-color: #fff; }
    .violet .toolBarWrapper .toolBarMenuWrapper .menuList p {
      width: 100%;
      float: left;
      color: #293037;
      font-family: "Segoe UI Semi Bold";
      font-size: 13px;
      font-weight: 700; }
    .violet .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper {
      position: absolute;
      right: 21px; }
      .violet .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line1 {
        width: 35px;
        height: 20px;
        border-bottom: 1px solid #dedede;
        -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
        position: absolute; }
        .violet .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line1.selectedNext {
          background-color: #3ba8c5;
          border-color: #fff; }
      .violet .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2 {
        width: 33px;
        height: 20px;
        border-bottom: 1px solid #dedede;
        -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
        position: absolute;
        top: 17px;
        left: -16px; }
        .violet .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.mask {
          opacity: 0;
          left: -1px;
          width: 37px;
          top: 29px;
          background-color: #fff;
          border: 0; }
          .violet .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.mask.selectedNext {
            opacity: 1;
            background-color: #3ba8c5; }
        .violet .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.selectedNext {
          border-color: #fff; }

.violet .toolBarWrapper .form-label.chatWindow {
  padding-top: 3px; }

.violet .toolBarWrapper .chat_windowWrapper .btn-group-toggle {
  display: inline-block; }
  .violet .toolBarWrapper .chat_windowWrapper .btn-group-toggle .custom_radionBtn {
    font-size: 14px;
    font-family: 'Segoe UI Semi Bold'; }
  .violet .toolBarWrapper .chat_windowWrapper .btn-group-toggle > label {
    margin-bottom: 17px;
    text-align: left; }
    .violet .toolBarWrapper .chat_windowWrapper .btn-group-toggle > label:last-child {
      margin-bottom: 14px; }

.violet .toolBarWrapper .settingFormWrapper .customSize .form-label {
  font-size: 13px; }

.violet .toolBarWrapper .settingFormWrapper .customSize .form-control {
  width: 62px;
  height: 29px;
  border-radius: 3px;
  float: left;
  margin-right: 12px;
  font-size: 13px; }

.violet .toolBarWrapper .settingFormWrapper .customSize span {
  float: left;
  margin-top: 6px;
  font-size: 13px; }

.violet .toolBarWrapper .toolBarPreviewWrapper {
  height: 302px;
  width: 418px;
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("assets/images/toolBarPreviewBg.png");
  position: relative; }
  .violet .toolBarWrapper .toolBarPreviewWrapper .positionIcons {
    position: absolute;
    border: 1px solid #dedede;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    cursor: pointer; }
    .violet .toolBarWrapper .toolBarPreviewWrapper .positionIcons.selected {
      background-color: #3ba8c5; }
    .violet .toolBarWrapper .toolBarPreviewWrapper .positionIcons.left {
      left: -11px;
      top: 44%;
      padding-right: 2px;
      padding-top: 1px; }
    .violet .toolBarWrapper .toolBarPreviewWrapper .positionIcons.right {
      right: -11px;
      top: 44%;
      padding-left: 2px;
      padding-top: 1px; }
    .violet .toolBarWrapper .toolBarPreviewWrapper .positionIcons.center {
      bottom: -30px;
      left: 50%;
      padding-top: 2px;
      padding-left: 1px; }
    .violet .toolBarWrapper .toolBarPreviewWrapper .positionIcons.bottomLeft {
      bottom: -28px;
      left: -11px;
      padding-left: 4px; }
    .violet .toolBarWrapper .toolBarPreviewWrapper .positionIcons.bottomRight {
      bottom: -28px;
      right: -11px;
      padding-right: 4px; }
  .violet .toolBarWrapper .toolBarPreviewWrapper .toolBarWrapper {
    width: 366px;
    height: 250px;
    position: absolute;
    top: 25px;
    left: 25px; }
  .violet .toolBarWrapper .toolBarPreviewWrapper .ulWrapper {
    position: absolute;
    max-width: 366px;
    max-height: 250px;
    overflow: auto; }
    .violet .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.bar_vertical .btn_wrapper {
      height: 54px; }
      .violet .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.bar_vertical .btn_wrapper .btn_icon.HelpOnClick-WithText {
        margin-left: auto;
        margin-right: auto; }
    .violet .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.transform {
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
  .violet .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns {
    list-style: none;
    height: 41px;
    padding: 0;
    margin: 0;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
    .violet .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper {
      border-right: 1px solid;
      border-left: 1px solid;
      height: 100%;
      float: left;
      min-width: 30px;
      min-height: 30px;
      margin-bottom: 0;
      display: table; }
      .violet .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper:first-child {
        border-left: 0; }
      .violet .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper:last-child {
        border-right: 0; }
      .violet .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div, .violet .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a {
        display: table-cell;
        vertical-align: middle; }
        .violet .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div div.preview_row, .violet .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a div.preview_row {
          display: table-row;
          height: 100%;
          width: 100%; }
        .violet .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div div.preview_clmn, .violet .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a div.preview_clmn {
          display: table-cell;
          vertical-align: middle; }
        .violet .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div > div, .violet .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div {
          display: inline-table;
          vertical-align: middle;
          height: 100%; }
      .violet .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_icon {
        margin-top: -1px;
        margin-left: 14px;
        margin-right: 14px; }
        .violet .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_icon.HelpOnClick-WithText {
          margin-left: 12px;
          margin-right: 0; }
      .violet .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_caption {
        color: #fff;
        display: table-cell;
        font-weight: 700;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 8px;
        padding-right: 15px;
        vertical-align: middle; }

.violet .toolBarWrapper .Tooltip__Base-sc-19cbsjq-0 {
  width: 200px !important; }

.violet .toolBarWrapper .Tooltip__Left-sc-19cbsjq-3 {
  right: 20px; }

.violet .toolBarWrapper .customDrpDwn.customDrpDwn-small .css-10nd86i {
  width: 141px;
  float: left; }

.violet .toolBarWrapper .customDrpDwn.customDrpDwn-small .customSize .form-control {
  width: 51px;
  height: 37px;
  margin-right: 9px; }

.violet .toolBarWrapper .customDrpDwn.customDrpDwn-small .customSize span {
  color: #7d7d7d;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }

.violet .toolBarWrapper .buttonSettingsWrapper {
  box-shadow: none;
  border-radius: 0;
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content; }
  .violet .toolBarWrapper .buttonSettingsWrapper .buttonsSection {
    margin-left: 7%;
    border-radius: 10px;
    background-color: #f6f6f6;
    padding: 26px 20px 15px; }
    .violet .toolBarWrapper .buttonSettingsWrapper .buttonsSection .editorWrapper .editorToolBar .editorLink {
      display: none; }
  .violet .toolBarWrapper .buttonSettingsWrapper .customDrpDwn .css-15k3avv {
    z-index: 10; }
  .violet .toolBarWrapper .buttonSettingsWrapper textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    background-color: #ffffff;
    padding: 15px;
    color: #000000;
    font-family: 'Segoe UI Semi Bold';
    font-size: 14px;
    font-weight: 400; }

.violet .toolBarWrapper .forIcon .css-10nd86i {
  float: left; }

.violet .toolBarWrapper .forIcon .formBtns {
  padding: 0;
  border-top: none;
  margin: 0;
  float: left; }
  .violet .toolBarWrapper .forIcon .formBtns Button {
    margin-left: 6px;
    padding-left: 9px;
    padding-right: 9px; }

.violet .toolBarWrapper .invitationColorPicker > div {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }
  .violet .toolBarWrapper .invitationColorPicker > div > div.colorSelector {
    width: 256px; }
  .violet .toolBarWrapper .invitationColorPicker > div > div:nth-child(2) {
    width: 256px;
    position: absolute !important;
    z-index: 2;
    left: 15px; }
    .violet .toolBarWrapper .invitationColorPicker > div > div:nth-child(2) .flexbox-fix {
      opacity: 0; }

.violet .toolBarWrapper .previewMargin.customSize {
  width: 187px;
  margin-top: 36px;
  margin: 36px auto 0; }

.violet .HelpOnClick-FontIconsLibrary {
  max-height: 70vh; }

.violet .toolbarInvitationPreview {
  height: 150px;
  width: 320px;
  border: 1px solid #035d85;
  border-radius: 5px;
  background-color: #eeeeee;
  margin: 56px auto; }
  .violet .toolbarInvitationPreview .invitationMessageWrapper {
    height: 60px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 5px;
    margin-top: 23px;
    font-size: 16px; }
  .violet .toolbarInvitationPreview button.close {
    margin-right: 3px; }
  .violet .toolbarInvitationPreview button.btn_chat {
    font-size: 14px;
    width: auto;
    display: block;
    padding: 11px 15px;
    border: 1px solid #7e7c7c;
    border-radius: 5px;
    cursor: pointer;
    float: none;
    margin: 0 auto; }

.violet .imageLibrary img {
  padding: 4px;
  cursor: pointer; }
  .violet .imageLibrary img.selected {
    background-color: #e6eef9; }

.blue .widgetWrapper h4.widgetName {
  color: #3ba8c5;
  margin-right: 22px; }

.blue .widgetWrapper .exit_btn {
  position: absolute;
  right: 37px;
  color: #3ba8c5;
  font-family: "Segoe UI";
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer; }
  .blue .widgetWrapper .exit_btn img {
    margin-right: 8px;
    margin-top: -3px; }

.blue .widgetSelectorIndicator {
  margin: 30px auto 0; }
  .blue .widgetSelectorIndicator p {
    color: #a1a4a8;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    margin-top: 25px; }

.blue .innercontent_wrapper .directLinkWrapper {
  margin-top: 56px; }
  .blue .innercontent_wrapper .directLinkWrapper h4 {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    margin-left: 18px;
    margin-bottom: 34px;
    position: relative; }
    .blue .innercontent_wrapper .directLinkWrapper h4 span {
      border: 1px solid #3ba8c5;
      background-color: #ffffff;
      border-radius: 50%;
      position: absolute;
      margin-left: 9px;
      width: 24px;
      height: 24px;
      display: inline-block; }
      .blue .innercontent_wrapper .directLinkWrapper h4 span img {
        position: absolute;
        top: 5px;
        left: 10px; }
  .blue .innercontent_wrapper .directLinkWrapper .with_btn input {
    float: left; }
  .blue .innercontent_wrapper .directLinkWrapper .with_btn .uploadBtn button {
    background-color: #3ba8c5; }
    .blue .innercontent_wrapper .directLinkWrapper .with_btn .uploadBtn button p {
      color: #fff; }
  .blue .innercontent_wrapper .directLinkWrapper .invitationCustomImg {
    margin-bottom: 0.5rem; }
  .blue .innercontent_wrapper .directLinkWrapper .btn_wrapper {
    margin-bottom: 15px; }
    .blue .innercontent_wrapper .directLinkWrapper .btn_wrapper .uploadBtn.iconUpload {
      margin-left: 0;
      margin-right: 5px; }
      .blue .innercontent_wrapper .directLinkWrapper .btn_wrapper .uploadBtn.iconUpload button.uploadIcon input {
        width: 60px; }
  .blue .innercontent_wrapper .directLinkWrapper .box {
    padding: 30px; }
    .blue .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:first-child {
      width: 131px;
      margin-right: 10px; }
    .blue .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:nth-child(2) {
      width: 117px; }
      .blue .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:nth-child(2) .css-10nd86i {
        width: 117px; }
  .blue .innercontent_wrapper .directLinkWrapper .formBtns Button.btn_save {
    width: auto; }
  .blue .innercontent_wrapper .directLinkWrapper .customOption .row > div:nth-child(2) > div:nth-child(2) {
    position: absolute !important;
    z-index: 2;
    top: 44px;
    left: 157px; }
    .blue .innercontent_wrapper .directLinkWrapper .customOption .row > div:nth-child(2) > div:nth-child(2) .flexbox-fix {
      display: none !important; }
  .blue .innercontent_wrapper .directLinkWrapper .colorSelector .css-1wy0on6 {
    background-color: #fff; }
  .blue .innercontent_wrapper .directLinkWrapper .colorSelector input {
    border: none; }
    .blue .innercontent_wrapper .directLinkWrapper .colorSelector input:focus {
      border: none; }
  .blue .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper {
    position: relative; }
    .blue .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper p {
      width: 178px;
      float: left;
      padding-top: 30%;
      font-size: 22px;
      text-align: right;
      text-decoration: underline; }
  .blue .innercontent_wrapper .directLinkWrapper .directLinkpreview {
    background-repeat: no-repeat;
    width: 165px;
    height: 114px;
    float: right;
    margin-top: 100px;
    margin-right: 50px;
    margin-left: 24px;
    background-size: cover !important; }
  .blue .innercontent_wrapper .directLinkWrapper .theme {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-top: 7px; }
  .blue .innercontent_wrapper .directLinkWrapper .preview {
    width: 330px;
    height: 101px;
    padding: 12px 0 0 0;
    margin-top: 40px;
    margin-bottom: 47px;
    background-repeat: no-repeat; }
    .blue .innercontent_wrapper .directLinkWrapper .preview .widget-preview {
      width: 166px;
      height: 76px; }
      .blue .innercontent_wrapper .directLinkWrapper .preview .widget-preview div {
        font-family: Arial,Helvetica,sans-serif; }
      .blue .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-title {
        padding: 11px 0 0 18px;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        text-align: left; }
      .blue .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-text {
        width: 146px;
        overflow: hidden;
        white-space: nowrap;
        padding: 1px 0 0;
        text-align: center;
        color: #A8A8A8;
        font-size: 12px;
        margin-left: 10px; }
      .blue .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-copy {
        padding: 8px 13px 0;
        text-align: right;
        font-size: 10px;
        color: #fff;
        opacity: .5;
        -moz-opacity: .5;
        -khtml-opacity: .5;
        filter: alpha(opacity=50); }
  .blue .innercontent_wrapper .directLinkWrapper .widgetPosition {
    width: 330px; }
    .blue .innercontent_wrapper .directLinkWrapper .widgetPosition p {
      width: 236px;
      height: 30px;
      border-radius: 5px;
      border: 1px solid #dedede;
      background-color: #ffffff;
      color: #000000;
      font-family: Segoe UI;
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 37px;
      margin-left: auto;
      margin-right: auto; }
      .blue .innercontent_wrapper .directLinkWrapper .widgetPosition p span {
        width: 50%;
        display: inline-block;
        padding: 6px 0;
        cursor: pointer; }
        .blue .innercontent_wrapper .directLinkWrapper .widgetPosition p span:first-child {
          border-radius: 5px 0 0 5px; }
        .blue .innercontent_wrapper .directLinkWrapper .widgetPosition p span:last-child {
          border-radius: 0 5px 5px 0; }
        .blue .innercontent_wrapper .directLinkWrapper .widgetPosition p span.selected {
          color: #ffffff;
          background-color: #3ba8c5; }
  .blue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper {
    width: 90%;
    margin: 0 auto; }
    .blue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp {
      width: 34px;
      height: 34px;
      border: 1px solid #dedede;
      border-radius: 50%;
      float: left;
      padding: 3px;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer; }
      .blue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp div {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        margin: 0; }
      .blue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:hover, .blue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp.selected {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        transition: -webkit-transform 100ms ease 0s;
        transition: transform 100ms ease 0s;
        transition: transform 100ms ease 0s, -webkit-transform 100ms ease 0s;
        padding: 2px; }
        .blue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:hover div, .blue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp.selected div {
          -webkit-transform: scale(0.9);
                  transform: scale(0.9); }
      .blue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(1):hover {
        border: 2px solid #0475A8; }
      .blue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(2):hover {
        border: 2px solid #5F8706; }
      .blue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(3):hover {
        border: 2px solid #D6680B; }
      .blue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(4):hover {
        border: 2px solid #B0097D; }
      .blue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(5):hover {
        border: 2px solid #9D0A09; }
      .blue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(6):hover {
        border: 2px solid #424E54; }
      .blue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(7):hover {
        border: 2px solid #037FAF; }
      .blue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(8):hover {
        border: 2px solid #608706; }
      .blue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(9):hover {
        border: 2px solid #D26009; }
      .blue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(10):hover {
        border: 2px solid #AF097D; }
      .blue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(11):hover {
        border: 2px solid #950909; }
      .blue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(12):hover {
        border: 2px solid #3E484E; }
      .blue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(13):hover {
        border: 2px solid #0476A8; }
      .blue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(14):hover {
        border: 2px solid #98B45D; }
      .blue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(15):hover {
        border: 2px solid #ADADAD; }
      .blue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(16):hover {
        border: 2px solid #98A7AD; }
  .blue .innercontent_wrapper .directLinkWrapper .circle-picker {
    padding: 0px 9px; }
    .blue .innercontent_wrapper .directLinkWrapper .circle-picker > span > div > span {
      padding: 4px;
      position: relative;
      border: 1px solid #dedede;
      border-radius: 50%;
      display: inline-block;
      width: 34px;
      height: 34px; }
    .blue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(1) > div:hover span {
      border: 2px solid #0475A8; }
    .blue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(1) > div > span > div {
      box-shadow: #0475A8 0px 0px 0px 17px inset !important; }
    .blue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(2) > div:hover span {
      border: 2px solid #5F8706; }
    .blue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(3) > div:hover span {
      border: 2px solid #D6680B; }
    .blue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(4) > div:hover span {
      border: 2px solid #B0097D; }
    .blue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(5) > div:hover span {
      border: 2px solid #9D0A09; }
    .blue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(6) > div:hover span {
      border: 2px solid #424E54; }
    .blue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(7) > div:hover span {
      border: 2px solid #037FAF; }
    .blue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(8) > div:hover span {
      border: 2px solid #587D06; }
    .blue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(9) > div:hover span {
      border: 2px solid #D26009; }
    .blue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(10) > div:hover span {
      border: 2px solid #AF087C; }
    .blue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(11) > div:hover span {
      border: 2px solid #950909; }
    .blue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(12) > div:hover span {
      border: 2px solid #3E484E; }
    .blue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(13) > div:hover span {
      border: 2px solid #0476A8; }
    .blue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(14) > div:hover span {
      border: 2px solid #98B45D; }
    .blue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(15) > div:hover span {
      border: 2px solid #ADADAD; }
    .blue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(16) > div:hover span {
      border: 2px solid #98A7AD; }
  .blue .innercontent_wrapper .directLinkWrapper .iconWrapper {
    height: 390px;
    position: relative; }
    .blue .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn {
      position: absolute;
      width: 26px;
      height: 27px;
      border: 1px solid #dedede;
      border-radius: 50%;
      top: -27px;
      right: 6px;
      cursor: pointer; }
      .blue .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn img {
        margin-top: -2px;
        margin-left: 1px; }
      .blue .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn.bottomScroll {
        top: auto;
        bottom: -27px; }
    .blue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow {
      margin-bottom: 13px;
      margin-right: 30px;
      border: 1px solid #dedede;
      border-radius: 10px;
      cursor: pointer; }
      .blue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div {
        width: 151px;
        height: 87px;
        background-color: #ffffff;
        margin-right: 12px;
        padding: 16px;
        border-radius: 10px; }
        .blue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div.no-image {
          opacity: 0; }
        .blue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div:last-child {
          margin-right: 0; }
      .blue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow:last-child {
        margin-bottom: 0; }
      .blue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow.selected {
        background-color: #dedede; }
        .blue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow.selected > div {
          background-color: #dedede; }
    .blue .innercontent_wrapper .directLinkWrapper .iconWrapper .rcs-custom-scroll .rcs-inner-handle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #3ba8c5;
      background-color: #3ba8c5;
      margin-left: -8px; }
    .blue .innercontent_wrapper .directLinkWrapper .iconWrapper .rcs-custom-scroll .rcs-custom-scrollbar {
      opacity: 1;
      right: 13px;
      border-left: 2px solid #dedede;
      height: 590px; }
  .blue .innercontent_wrapper .directLinkWrapper .uploadBtn {
    margin-top: 13px; }
    .blue .innercontent_wrapper .directLinkWrapper .uploadBtn.iconUpload {
      margin-top: 5px;
      margin-left: 6px;
      float: left; }
    .blue .innercontent_wrapper .directLinkWrapper .uploadBtn button {
      width: 218px;
      height: 29px;
      border-radius: 3px;
      border: 1px solid #3ba8c5;
      background-color: #ffffff;
      position: relative;
      padding: 0; }
      .blue .innercontent_wrapper .directLinkWrapper .uploadBtn button.uploadIcon {
        width: auto;
        float: left;
        padding: 0 5px; }
        .blue .innercontent_wrapper .directLinkWrapper .uploadBtn button.uploadIcon input {
          width: auto; }
      .blue .innercontent_wrapper .directLinkWrapper .uploadBtn button p {
        margin: 0;
        color: #3ba8c5;
        font-family: "Segoe UI";
        font-size: 13px;
        font-weight: 700;
        line-height: 30.4px;
        text-transform: uppercase; }
        .blue .innercontent_wrapper .directLinkWrapper .uploadBtn button p img {
          margin-right: 4px;
          margin-top: -4px; }
      .blue .innercontent_wrapper .directLinkWrapper .uploadBtn button input {
        position: absolute;
        top: 0;
        left: 0;
        width: 218px;
        height: 29px;
        opacity: 0;
        cursor: pointer; }
  .blue .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span {
    margin-right: 15px;
    color: #3ba8c5;
    text-decoration: underline;
    cursor: pointer; }
    .blue .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span.selected {
      text-decoration: none;
      color: #000000; }

.blue .carousel {
  padding: 12px 0px; }
  .blue .carousel img.carouselBtn {
    position: absolute;
    cursor: pointer;
    top: 90px; }
    .blue .carousel img.carouselBtn.carousel-left {
      left: 30px; }
  .blue .carousel ul {
    margin-left: 0;
    margin-right: 0;
    transition: all 1s; }
    .blue .carousel ul.widgetMode li {
      opacity: 0.5;
      float: left; }
      .blue .carousel ul.widgetMode li.selected, .blue .carousel ul.widgetMode li:hover {
        opacity: 1;
        border: 3px solid #3ba8c5; }
        .blue .carousel ul.widgetMode li.selected h4, .blue .carousel ul.widgetMode li:hover h4 {
          background-color: #3ba8c5;
          color: #ffffff; }
  .blue .carousel .carousel-cell {
    cursor: pointer;
    width: 210px;
    height: 192px;
    box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
    border-radius: 20px;
    background-color: #ffffff;
    padding: 0px;
    display: inline-block;
    margin-right: 20px;
    float: left; }
    .blue .carousel .carousel-cell.opacity_0 {
      opacity: 0;
      margin-right: 60px;
      transition: opacity 2s; }
    .blue .carousel .carousel-cell:hover {
      border: 3px solid #3ba8c5; }
      .blue .carousel .carousel-cell:hover h4 {
        background-color: #3ba8c5;
        color: #ffffff; }
    .blue .carousel .carousel-cell h4 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      padding: 22px 0 18px;
      border-bottom: 4px solid #3ba8c5;
      margin: 0;
      border-radius: 15px 15px 0 0; }
    .blue .carousel .carousel-cell .img-wrapper {
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      width: 210px; }

.blue .settingFormWrapper .codeWrapper {
  margin-top: 69px; }
  .blue .settingFormWrapper .codeWrapper p {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    text-align: left; }
  .blue .settingFormWrapper .codeWrapper textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    padding: 13px;
    margin-bottom: 10px; }
  .blue .settingFormWrapper .codeWrapper .formBtns {
    padding: 0;
    margin: 0;
    border: none; }
    .blue .settingFormWrapper .codeWrapper .formBtns Button.btn_clr {
      margin-left: 0;
      margin-right: 10px;
      width: auto;
      padding: 9px 30px; }
      .blue .settingFormWrapper .codeWrapper .formBtns Button.btn_clr p {
        text-align: center;
        color: #3ba8c5; }
  .blue .settingFormWrapper .codeWrapper .advancedSection {
    margin-top: -48px; }
    .blue .settingFormWrapper .codeWrapper .advancedSection .advancedOption p {
      color: #3ba8c5;
      font-family: "Segoe UI Bold";
      font-size: 14px;
      font-weight: 700; }
      .blue .settingFormWrapper .codeWrapper .advancedSection .advancedOption p:first-child {
        width: 27px;
        height: 27px;
        border-radius: 50%;
        border: 1px solid #039be6;
        background-color: #039be6;
        background-image: url("assets/images/expand.png");
        background-repeat: no-repeat;
        background-position: center;
        padding: 0;
        float: left;
        margin-right: 9px; }
      .blue .settingFormWrapper .codeWrapper .advancedSection .advancedOption p:last-child {
        margin-top: 20px; }
    .blue .settingFormWrapper .codeWrapper .advancedSection .advancedOption.collapsed p:first-child {
      border: 1px solid #dedede;
      background-color: #ffffff;
      background-image: url("assets/images/plus_icons/plus_blue.png");
      background-size: 11px; }
    .blue .settingFormWrapper .codeWrapper .advancedSection .advancedOption.dropdown-toggle::after {
      display: none; }

.blue .mailer .modal-dialog textarea {
  border-radius: 5px;
  border: 1px solid #dedede;
  padding: 13px;
  width: 367px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px; }

.blue .mailer .modal-dialog label {
  text-align: left;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.blue .mailer .modal-dialog .form-control {
  width: 256px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.blue .toolBarWrapper .toolTipDesignWrapper {
  border-radius: 10px;
  background-color: #f6f6f6;
  padding: 20px 20px 4px; }

.blue .toolBarWrapper .toolBarMenuWrapper {
  height: 53px;
  margin-bottom: 36px;
  position: relative; }
  .blue .toolBarWrapper .toolBarMenuWrapper .toolBarBoxWrapper {
    position: absolute;
    height: 13px;
    width: 100%;
    top: -14px;
    left: 0;
    background-color: #fff;
    z-index: 7; }
    .blue .toolBarWrapper .toolBarMenuWrapper .toolBarBoxWrapper.bottom {
      top: auto;
      bottom: -13px; }
  .blue .toolBarWrapper .toolBarMenuWrapper .toolBarBox {
    position: absolute;
    border: 1px solid #dedede;
    height: 54px;
    width: 98%;
    border-right: 0;
    top: -1px;
    z-index: 5;
    left: 0;
    pointer-events: none; }
  .blue .toolBarWrapper .toolBarMenuWrapper .menuList {
    height: 54px;
    background-color: #fff;
    cursor: pointer; }
    .blue .toolBarWrapper .toolBarMenuWrapper .menuList.selected {
      background-color: #3ba8c5; }
      .blue .toolBarWrapper .toolBarMenuWrapper .menuList.selected p {
        color: #fff; }
      .blue .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line1 {
        background-color: #fff;
        border-color: #3ba8c5; }
      .blue .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line2 {
        border-color: #3ba8c5; }
        .blue .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line2.mask {
          opacity: 1;
          background-color: #fff; }
    .blue .toolBarWrapper .toolBarMenuWrapper .menuList p {
      width: 100%;
      float: left;
      color: #293037;
      font-family: "Segoe UI Semi Bold";
      font-size: 13px;
      font-weight: 700; }
    .blue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper {
      position: absolute;
      right: 21px; }
      .blue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line1 {
        width: 35px;
        height: 20px;
        border-bottom: 1px solid #dedede;
        -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
        position: absolute; }
        .blue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line1.selectedNext {
          background-color: #3ba8c5;
          border-color: #fff; }
      .blue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2 {
        width: 33px;
        height: 20px;
        border-bottom: 1px solid #dedede;
        -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
        position: absolute;
        top: 17px;
        left: -16px; }
        .blue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.mask {
          opacity: 0;
          left: -1px;
          width: 37px;
          top: 29px;
          background-color: #fff;
          border: 0; }
          .blue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.mask.selectedNext {
            opacity: 1;
            background-color: #3ba8c5; }
        .blue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.selectedNext {
          border-color: #fff; }

.blue .toolBarWrapper .form-label.chatWindow {
  padding-top: 3px; }

.blue .toolBarWrapper .chat_windowWrapper .btn-group-toggle {
  display: inline-block; }
  .blue .toolBarWrapper .chat_windowWrapper .btn-group-toggle .custom_radionBtn {
    font-size: 14px;
    font-family: 'Segoe UI Semi Bold'; }
  .blue .toolBarWrapper .chat_windowWrapper .btn-group-toggle > label {
    margin-bottom: 17px;
    text-align: left; }
    .blue .toolBarWrapper .chat_windowWrapper .btn-group-toggle > label:last-child {
      margin-bottom: 14px; }

.blue .toolBarWrapper .settingFormWrapper .customSize .form-label {
  font-size: 13px; }

.blue .toolBarWrapper .settingFormWrapper .customSize .form-control {
  width: 62px;
  height: 29px;
  border-radius: 3px;
  float: left;
  margin-right: 12px;
  font-size: 13px; }

.blue .toolBarWrapper .settingFormWrapper .customSize span {
  float: left;
  margin-top: 6px;
  font-size: 13px; }

.blue .toolBarWrapper .toolBarPreviewWrapper {
  height: 302px;
  width: 418px;
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("assets/images/toolBarPreviewBg.png");
  position: relative; }
  .blue .toolBarWrapper .toolBarPreviewWrapper .positionIcons {
    position: absolute;
    border: 1px solid #dedede;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    cursor: pointer; }
    .blue .toolBarWrapper .toolBarPreviewWrapper .positionIcons.selected {
      background-color: #3ba8c5; }
    .blue .toolBarWrapper .toolBarPreviewWrapper .positionIcons.left {
      left: -11px;
      top: 44%;
      padding-right: 2px;
      padding-top: 1px; }
    .blue .toolBarWrapper .toolBarPreviewWrapper .positionIcons.right {
      right: -11px;
      top: 44%;
      padding-left: 2px;
      padding-top: 1px; }
    .blue .toolBarWrapper .toolBarPreviewWrapper .positionIcons.center {
      bottom: -30px;
      left: 50%;
      padding-top: 2px;
      padding-left: 1px; }
    .blue .toolBarWrapper .toolBarPreviewWrapper .positionIcons.bottomLeft {
      bottom: -28px;
      left: -11px;
      padding-left: 4px; }
    .blue .toolBarWrapper .toolBarPreviewWrapper .positionIcons.bottomRight {
      bottom: -28px;
      right: -11px;
      padding-right: 4px; }
  .blue .toolBarWrapper .toolBarPreviewWrapper .toolBarWrapper {
    width: 366px;
    height: 250px;
    position: absolute;
    top: 25px;
    left: 25px; }
  .blue .toolBarWrapper .toolBarPreviewWrapper .ulWrapper {
    position: absolute;
    max-width: 366px;
    max-height: 250px;
    overflow: auto; }
    .blue .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.bar_vertical .btn_wrapper {
      height: 54px; }
      .blue .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.bar_vertical .btn_wrapper .btn_icon.HelpOnClick-WithText {
        margin-left: auto;
        margin-right: auto; }
    .blue .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.transform {
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
  .blue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns {
    list-style: none;
    height: 41px;
    padding: 0;
    margin: 0;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
    .blue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper {
      border-right: 1px solid;
      border-left: 1px solid;
      height: 100%;
      float: left;
      min-width: 30px;
      min-height: 30px;
      margin-bottom: 0;
      display: table; }
      .blue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper:first-child {
        border-left: 0; }
      .blue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper:last-child {
        border-right: 0; }
      .blue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div, .blue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a {
        display: table-cell;
        vertical-align: middle; }
        .blue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div div.preview_row, .blue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a div.preview_row {
          display: table-row;
          height: 100%;
          width: 100%; }
        .blue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div div.preview_clmn, .blue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a div.preview_clmn {
          display: table-cell;
          vertical-align: middle; }
        .blue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div > div, .blue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div {
          display: inline-table;
          vertical-align: middle;
          height: 100%; }
      .blue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_icon {
        margin-top: -1px;
        margin-left: 14px;
        margin-right: 14px; }
        .blue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_icon.HelpOnClick-WithText {
          margin-left: 12px;
          margin-right: 0; }
      .blue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_caption {
        color: #fff;
        display: table-cell;
        font-weight: 700;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 8px;
        padding-right: 15px;
        vertical-align: middle; }

.blue .toolBarWrapper .Tooltip__Base-sc-19cbsjq-0 {
  width: 200px !important; }

.blue .toolBarWrapper .Tooltip__Left-sc-19cbsjq-3 {
  right: 20px; }

.blue .toolBarWrapper .customDrpDwn.customDrpDwn-small .css-10nd86i {
  width: 141px;
  float: left; }

.blue .toolBarWrapper .customDrpDwn.customDrpDwn-small .customSize .form-control {
  width: 51px;
  height: 37px;
  margin-right: 9px; }

.blue .toolBarWrapper .customDrpDwn.customDrpDwn-small .customSize span {
  color: #7d7d7d;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }

.blue .toolBarWrapper .buttonSettingsWrapper {
  box-shadow: none;
  border-radius: 0;
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content; }
  .blue .toolBarWrapper .buttonSettingsWrapper .buttonsSection {
    margin-left: 7%;
    border-radius: 10px;
    background-color: #f6f6f6;
    padding: 26px 20px 15px; }
    .blue .toolBarWrapper .buttonSettingsWrapper .buttonsSection .editorWrapper .editorToolBar .editorLink {
      display: none; }
  .blue .toolBarWrapper .buttonSettingsWrapper .customDrpDwn .css-15k3avv {
    z-index: 10; }
  .blue .toolBarWrapper .buttonSettingsWrapper textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    background-color: #ffffff;
    padding: 15px;
    color: #000000;
    font-family: 'Segoe UI Semi Bold';
    font-size: 14px;
    font-weight: 400; }

.blue .toolBarWrapper .forIcon .css-10nd86i {
  float: left; }

.blue .toolBarWrapper .forIcon .formBtns {
  padding: 0;
  border-top: none;
  margin: 0;
  float: left; }
  .blue .toolBarWrapper .forIcon .formBtns Button {
    margin-left: 6px;
    padding-left: 9px;
    padding-right: 9px; }

.blue .toolBarWrapper .invitationColorPicker > div {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }
  .blue .toolBarWrapper .invitationColorPicker > div > div.colorSelector {
    width: 256px; }
  .blue .toolBarWrapper .invitationColorPicker > div > div:nth-child(2) {
    width: 256px;
    position: absolute !important;
    z-index: 2;
    left: 15px; }
    .blue .toolBarWrapper .invitationColorPicker > div > div:nth-child(2) .flexbox-fix {
      opacity: 0; }

.blue .toolBarWrapper .previewMargin.customSize {
  width: 187px;
  margin-top: 36px;
  margin: 36px auto 0; }

.blue .HelpOnClick-FontIconsLibrary {
  max-height: 70vh; }

.blue .toolbarInvitationPreview {
  height: 150px;
  width: 320px;
  border: 1px solid #035d85;
  border-radius: 5px;
  background-color: #eeeeee;
  margin: 56px auto; }
  .blue .toolbarInvitationPreview .invitationMessageWrapper {
    height: 60px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 5px;
    margin-top: 23px;
    font-size: 16px; }
  .blue .toolbarInvitationPreview button.close {
    margin-right: 3px; }
  .blue .toolbarInvitationPreview button.btn_chat {
    font-size: 14px;
    width: auto;
    display: block;
    padding: 11px 15px;
    border: 1px solid #7e7c7c;
    border-radius: 5px;
    cursor: pointer;
    float: none;
    margin: 0 auto; }

.blue .imageLibrary img {
  padding: 4px;
  cursor: pointer; }
  .blue .imageLibrary img.selected {
    background-color: #e6eef9; }

.darkGreen .widgetWrapper h4.widgetName {
  color: #3ba8c5;
  margin-right: 22px; }

.darkGreen .widgetWrapper .exit_btn {
  position: absolute;
  right: 37px;
  color: #3ba8c5;
  font-family: "Segoe UI";
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer; }
  .darkGreen .widgetWrapper .exit_btn img {
    margin-right: 8px;
    margin-top: -3px; }

.darkGreen .widgetSelectorIndicator {
  margin: 30px auto 0; }
  .darkGreen .widgetSelectorIndicator p {
    color: #a1a4a8;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    margin-top: 25px; }

.darkGreen .innercontent_wrapper .directLinkWrapper {
  margin-top: 56px; }
  .darkGreen .innercontent_wrapper .directLinkWrapper h4 {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    margin-left: 18px;
    margin-bottom: 34px;
    position: relative; }
    .darkGreen .innercontent_wrapper .directLinkWrapper h4 span {
      border: 1px solid #3ba8c5;
      background-color: #ffffff;
      border-radius: 50%;
      position: absolute;
      margin-left: 9px;
      width: 24px;
      height: 24px;
      display: inline-block; }
      .darkGreen .innercontent_wrapper .directLinkWrapper h4 span img {
        position: absolute;
        top: 5px;
        left: 10px; }
  .darkGreen .innercontent_wrapper .directLinkWrapper .with_btn input {
    float: left; }
  .darkGreen .innercontent_wrapper .directLinkWrapper .with_btn .uploadBtn button {
    background-color: #3ba8c5; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .with_btn .uploadBtn button p {
      color: #fff; }
  .darkGreen .innercontent_wrapper .directLinkWrapper .invitationCustomImg {
    margin-bottom: 0.5rem; }
  .darkGreen .innercontent_wrapper .directLinkWrapper .btn_wrapper {
    margin-bottom: 15px; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .btn_wrapper .uploadBtn.iconUpload {
      margin-left: 0;
      margin-right: 5px; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .btn_wrapper .uploadBtn.iconUpload button.uploadIcon input {
        width: 60px; }
  .darkGreen .innercontent_wrapper .directLinkWrapper .box {
    padding: 30px; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:first-child {
      width: 131px;
      margin-right: 10px; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:nth-child(2) {
      width: 117px; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:nth-child(2) .css-10nd86i {
        width: 117px; }
  .darkGreen .innercontent_wrapper .directLinkWrapper .formBtns Button.btn_save {
    width: auto; }
  .darkGreen .innercontent_wrapper .directLinkWrapper .customOption .row > div:nth-child(2) > div:nth-child(2) {
    position: absolute !important;
    z-index: 2;
    top: 44px;
    left: 157px; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .customOption .row > div:nth-child(2) > div:nth-child(2) .flexbox-fix {
      display: none !important; }
  .darkGreen .innercontent_wrapper .directLinkWrapper .colorSelector .css-1wy0on6 {
    background-color: #fff; }
  .darkGreen .innercontent_wrapper .directLinkWrapper .colorSelector input {
    border: none; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .colorSelector input:focus {
      border: none; }
  .darkGreen .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper {
    position: relative; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper p {
      width: 178px;
      float: left;
      padding-top: 30%;
      font-size: 22px;
      text-align: right;
      text-decoration: underline; }
  .darkGreen .innercontent_wrapper .directLinkWrapper .directLinkpreview {
    background-repeat: no-repeat;
    width: 165px;
    height: 114px;
    float: right;
    margin-top: 100px;
    margin-right: 50px;
    margin-left: 24px;
    background-size: cover !important; }
  .darkGreen .innercontent_wrapper .directLinkWrapper .theme {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-top: 7px; }
  .darkGreen .innercontent_wrapper .directLinkWrapper .preview {
    width: 330px;
    height: 101px;
    padding: 12px 0 0 0;
    margin-top: 40px;
    margin-bottom: 47px;
    background-repeat: no-repeat; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .preview .widget-preview {
      width: 166px;
      height: 76px; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .preview .widget-preview div {
        font-family: Arial,Helvetica,sans-serif; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-title {
        padding: 11px 0 0 18px;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        text-align: left; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-text {
        width: 146px;
        overflow: hidden;
        white-space: nowrap;
        padding: 1px 0 0;
        text-align: center;
        color: #A8A8A8;
        font-size: 12px;
        margin-left: 10px; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-copy {
        padding: 8px 13px 0;
        text-align: right;
        font-size: 10px;
        color: #fff;
        opacity: .5;
        -moz-opacity: .5;
        -khtml-opacity: .5;
        filter: alpha(opacity=50); }
  .darkGreen .innercontent_wrapper .directLinkWrapper .widgetPosition {
    width: 330px; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .widgetPosition p {
      width: 236px;
      height: 30px;
      border-radius: 5px;
      border: 1px solid #dedede;
      background-color: #ffffff;
      color: #000000;
      font-family: Segoe UI;
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 37px;
      margin-left: auto;
      margin-right: auto; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .widgetPosition p span {
        width: 50%;
        display: inline-block;
        padding: 6px 0;
        cursor: pointer; }
        .darkGreen .innercontent_wrapper .directLinkWrapper .widgetPosition p span:first-child {
          border-radius: 5px 0 0 5px; }
        .darkGreen .innercontent_wrapper .directLinkWrapper .widgetPosition p span:last-child {
          border-radius: 0 5px 5px 0; }
        .darkGreen .innercontent_wrapper .directLinkWrapper .widgetPosition p span.selected {
          color: #ffffff;
          background-color: #3ba8c5; }
  .darkGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper {
    width: 90%;
    margin: 0 auto; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp {
      width: 34px;
      height: 34px;
      border: 1px solid #dedede;
      border-radius: 50%;
      float: left;
      padding: 3px;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp div {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        margin: 0; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:hover, .darkGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp.selected {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        transition: -webkit-transform 100ms ease 0s;
        transition: transform 100ms ease 0s;
        transition: transform 100ms ease 0s, -webkit-transform 100ms ease 0s;
        padding: 2px; }
        .darkGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:hover div, .darkGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp.selected div {
          -webkit-transform: scale(0.9);
                  transform: scale(0.9); }
      .darkGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(1):hover {
        border: 2px solid #0475A8; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(2):hover {
        border: 2px solid #5F8706; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(3):hover {
        border: 2px solid #D6680B; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(4):hover {
        border: 2px solid #B0097D; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(5):hover {
        border: 2px solid #9D0A09; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(6):hover {
        border: 2px solid #424E54; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(7):hover {
        border: 2px solid #037FAF; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(8):hover {
        border: 2px solid #608706; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(9):hover {
        border: 2px solid #D26009; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(10):hover {
        border: 2px solid #AF097D; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(11):hover {
        border: 2px solid #950909; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(12):hover {
        border: 2px solid #3E484E; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(13):hover {
        border: 2px solid #0476A8; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(14):hover {
        border: 2px solid #98B45D; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(15):hover {
        border: 2px solid #ADADAD; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(16):hover {
        border: 2px solid #98A7AD; }
  .darkGreen .innercontent_wrapper .directLinkWrapper .circle-picker {
    padding: 0px 9px; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span > div > span {
      padding: 4px;
      position: relative;
      border: 1px solid #dedede;
      border-radius: 50%;
      display: inline-block;
      width: 34px;
      height: 34px; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(1) > div:hover span {
      border: 2px solid #0475A8; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(1) > div > span > div {
      box-shadow: #0475A8 0px 0px 0px 17px inset !important; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(2) > div:hover span {
      border: 2px solid #5F8706; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(3) > div:hover span {
      border: 2px solid #D6680B; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(4) > div:hover span {
      border: 2px solid #B0097D; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(5) > div:hover span {
      border: 2px solid #9D0A09; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(6) > div:hover span {
      border: 2px solid #424E54; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(7) > div:hover span {
      border: 2px solid #037FAF; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(8) > div:hover span {
      border: 2px solid #587D06; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(9) > div:hover span {
      border: 2px solid #D26009; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(10) > div:hover span {
      border: 2px solid #AF087C; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(11) > div:hover span {
      border: 2px solid #950909; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(12) > div:hover span {
      border: 2px solid #3E484E; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(13) > div:hover span {
      border: 2px solid #0476A8; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(14) > div:hover span {
      border: 2px solid #98B45D; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(15) > div:hover span {
      border: 2px solid #ADADAD; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(16) > div:hover span {
      border: 2px solid #98A7AD; }
  .darkGreen .innercontent_wrapper .directLinkWrapper .iconWrapper {
    height: 390px;
    position: relative; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn {
      position: absolute;
      width: 26px;
      height: 27px;
      border: 1px solid #dedede;
      border-radius: 50%;
      top: -27px;
      right: 6px;
      cursor: pointer; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn img {
        margin-top: -2px;
        margin-left: 1px; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn.bottomScroll {
        top: auto;
        bottom: -27px; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow {
      margin-bottom: 13px;
      margin-right: 30px;
      border: 1px solid #dedede;
      border-radius: 10px;
      cursor: pointer; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div {
        width: 151px;
        height: 87px;
        background-color: #ffffff;
        margin-right: 12px;
        padding: 16px;
        border-radius: 10px; }
        .darkGreen .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div.no-image {
          opacity: 0; }
        .darkGreen .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div:last-child {
          margin-right: 0; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow:last-child {
        margin-bottom: 0; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow.selected {
        background-color: #dedede; }
        .darkGreen .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow.selected > div {
          background-color: #dedede; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .iconWrapper .rcs-custom-scroll .rcs-inner-handle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #3ba8c5;
      background-color: #3ba8c5;
      margin-left: -8px; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .iconWrapper .rcs-custom-scroll .rcs-custom-scrollbar {
      opacity: 1;
      right: 13px;
      border-left: 2px solid #dedede;
      height: 590px; }
  .darkGreen .innercontent_wrapper .directLinkWrapper .uploadBtn {
    margin-top: 13px; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .uploadBtn.iconUpload {
      margin-top: 5px;
      margin-left: 6px;
      float: left; }
    .darkGreen .innercontent_wrapper .directLinkWrapper .uploadBtn button {
      width: 218px;
      height: 29px;
      border-radius: 3px;
      border: 1px solid #3ba8c5;
      background-color: #ffffff;
      position: relative;
      padding: 0; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .uploadBtn button.uploadIcon {
        width: auto;
        float: left;
        padding: 0 5px; }
        .darkGreen .innercontent_wrapper .directLinkWrapper .uploadBtn button.uploadIcon input {
          width: auto; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .uploadBtn button p {
        margin: 0;
        color: #3ba8c5;
        font-family: "Segoe UI";
        font-size: 13px;
        font-weight: 700;
        line-height: 30.4px;
        text-transform: uppercase; }
        .darkGreen .innercontent_wrapper .directLinkWrapper .uploadBtn button p img {
          margin-right: 4px;
          margin-top: -4px; }
      .darkGreen .innercontent_wrapper .directLinkWrapper .uploadBtn button input {
        position: absolute;
        top: 0;
        left: 0;
        width: 218px;
        height: 29px;
        opacity: 0;
        cursor: pointer; }
  .darkGreen .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span {
    margin-right: 15px;
    color: #3ba8c5;
    text-decoration: underline;
    cursor: pointer; }
    .darkGreen .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span.selected {
      text-decoration: none;
      color: #000000; }

.darkGreen .carousel {
  padding: 12px 0px; }
  .darkGreen .carousel img.carouselBtn {
    position: absolute;
    cursor: pointer;
    top: 90px; }
    .darkGreen .carousel img.carouselBtn.carousel-left {
      left: 30px; }
  .darkGreen .carousel ul {
    margin-left: 0;
    margin-right: 0;
    transition: all 1s; }
    .darkGreen .carousel ul.widgetMode li {
      opacity: 0.5;
      float: left; }
      .darkGreen .carousel ul.widgetMode li.selected, .darkGreen .carousel ul.widgetMode li:hover {
        opacity: 1;
        border: 3px solid #3ba8c5; }
        .darkGreen .carousel ul.widgetMode li.selected h4, .darkGreen .carousel ul.widgetMode li:hover h4 {
          background-color: #3ba8c5;
          color: #ffffff; }
  .darkGreen .carousel .carousel-cell {
    cursor: pointer;
    width: 210px;
    height: 192px;
    box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
    border-radius: 20px;
    background-color: #ffffff;
    padding: 0px;
    display: inline-block;
    margin-right: 20px;
    float: left; }
    .darkGreen .carousel .carousel-cell.opacity_0 {
      opacity: 0;
      margin-right: 60px;
      transition: opacity 2s; }
    .darkGreen .carousel .carousel-cell:hover {
      border: 3px solid #3ba8c5; }
      .darkGreen .carousel .carousel-cell:hover h4 {
        background-color: #3ba8c5;
        color: #ffffff; }
    .darkGreen .carousel .carousel-cell h4 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      padding: 22px 0 18px;
      border-bottom: 4px solid #3ba8c5;
      margin: 0;
      border-radius: 15px 15px 0 0; }
    .darkGreen .carousel .carousel-cell .img-wrapper {
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      width: 210px; }

.darkGreen .settingFormWrapper .codeWrapper {
  margin-top: 69px; }
  .darkGreen .settingFormWrapper .codeWrapper p {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    text-align: left; }
  .darkGreen .settingFormWrapper .codeWrapper textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    padding: 13px;
    margin-bottom: 10px; }
  .darkGreen .settingFormWrapper .codeWrapper .formBtns {
    padding: 0;
    margin: 0;
    border: none; }
    .darkGreen .settingFormWrapper .codeWrapper .formBtns Button.btn_clr {
      margin-left: 0;
      margin-right: 10px;
      width: auto;
      padding: 9px 30px; }
      .darkGreen .settingFormWrapper .codeWrapper .formBtns Button.btn_clr p {
        text-align: center;
        color: #3ba8c5; }
  .darkGreen .settingFormWrapper .codeWrapper .advancedSection {
    margin-top: -48px; }
    .darkGreen .settingFormWrapper .codeWrapper .advancedSection .advancedOption p {
      color: #3ba8c5;
      font-family: "Segoe UI Bold";
      font-size: 14px;
      font-weight: 700; }
      .darkGreen .settingFormWrapper .codeWrapper .advancedSection .advancedOption p:first-child {
        width: 27px;
        height: 27px;
        border-radius: 50%;
        border: 1px solid #00887a;
        background-color: #00887a;
        background-image: url("assets/images/expand.png");
        background-repeat: no-repeat;
        background-position: center;
        padding: 0;
        float: left;
        margin-right: 9px; }
      .darkGreen .settingFormWrapper .codeWrapper .advancedSection .advancedOption p:last-child {
        margin-top: 20px; }
    .darkGreen .settingFormWrapper .codeWrapper .advancedSection .advancedOption.collapsed p:first-child {
      border: 1px solid #dedede;
      background-color: #ffffff;
      background-image: url("assets/images/plus_icons/plus_darkgreen.png");
      background-size: 11px; }
    .darkGreen .settingFormWrapper .codeWrapper .advancedSection .advancedOption.dropdown-toggle::after {
      display: none; }

.darkGreen .mailer .modal-dialog textarea {
  border-radius: 5px;
  border: 1px solid #dedede;
  padding: 13px;
  width: 367px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px; }

.darkGreen .mailer .modal-dialog label {
  text-align: left;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.darkGreen .mailer .modal-dialog .form-control {
  width: 256px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.darkGreen .toolBarWrapper .toolTipDesignWrapper {
  border-radius: 10px;
  background-color: #f6f6f6;
  padding: 20px 20px 4px; }

.darkGreen .toolBarWrapper .toolBarMenuWrapper {
  height: 53px;
  margin-bottom: 36px;
  position: relative; }
  .darkGreen .toolBarWrapper .toolBarMenuWrapper .toolBarBoxWrapper {
    position: absolute;
    height: 13px;
    width: 100%;
    top: -14px;
    left: 0;
    background-color: #fff;
    z-index: 7; }
    .darkGreen .toolBarWrapper .toolBarMenuWrapper .toolBarBoxWrapper.bottom {
      top: auto;
      bottom: -13px; }
  .darkGreen .toolBarWrapper .toolBarMenuWrapper .toolBarBox {
    position: absolute;
    border: 1px solid #dedede;
    height: 54px;
    width: 98%;
    border-right: 0;
    top: -1px;
    z-index: 5;
    left: 0;
    pointer-events: none; }
  .darkGreen .toolBarWrapper .toolBarMenuWrapper .menuList {
    height: 54px;
    background-color: #fff;
    cursor: pointer; }
    .darkGreen .toolBarWrapper .toolBarMenuWrapper .menuList.selected {
      background-color: #3ba8c5; }
      .darkGreen .toolBarWrapper .toolBarMenuWrapper .menuList.selected p {
        color: #fff; }
      .darkGreen .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line1 {
        background-color: #fff;
        border-color: #3ba8c5; }
      .darkGreen .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line2 {
        border-color: #3ba8c5; }
        .darkGreen .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line2.mask {
          opacity: 1;
          background-color: #fff; }
    .darkGreen .toolBarWrapper .toolBarMenuWrapper .menuList p {
      width: 100%;
      float: left;
      color: #293037;
      font-family: "Segoe UI Semi Bold";
      font-size: 13px;
      font-weight: 700; }
    .darkGreen .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper {
      position: absolute;
      right: 21px; }
      .darkGreen .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line1 {
        width: 35px;
        height: 20px;
        border-bottom: 1px solid #dedede;
        -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
        position: absolute; }
        .darkGreen .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line1.selectedNext {
          background-color: #3ba8c5;
          border-color: #fff; }
      .darkGreen .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2 {
        width: 33px;
        height: 20px;
        border-bottom: 1px solid #dedede;
        -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
        position: absolute;
        top: 17px;
        left: -16px; }
        .darkGreen .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.mask {
          opacity: 0;
          left: -1px;
          width: 37px;
          top: 29px;
          background-color: #fff;
          border: 0; }
          .darkGreen .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.mask.selectedNext {
            opacity: 1;
            background-color: #3ba8c5; }
        .darkGreen .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.selectedNext {
          border-color: #fff; }

.darkGreen .toolBarWrapper .form-label.chatWindow {
  padding-top: 3px; }

.darkGreen .toolBarWrapper .chat_windowWrapper .btn-group-toggle {
  display: inline-block; }
  .darkGreen .toolBarWrapper .chat_windowWrapper .btn-group-toggle .custom_radionBtn {
    font-size: 14px;
    font-family: 'Segoe UI Semi Bold'; }
  .darkGreen .toolBarWrapper .chat_windowWrapper .btn-group-toggle > label {
    margin-bottom: 17px;
    text-align: left; }
    .darkGreen .toolBarWrapper .chat_windowWrapper .btn-group-toggle > label:last-child {
      margin-bottom: 14px; }

.darkGreen .toolBarWrapper .settingFormWrapper .customSize .form-label {
  font-size: 13px; }

.darkGreen .toolBarWrapper .settingFormWrapper .customSize .form-control {
  width: 62px;
  height: 29px;
  border-radius: 3px;
  float: left;
  margin-right: 12px;
  font-size: 13px; }

.darkGreen .toolBarWrapper .settingFormWrapper .customSize span {
  float: left;
  margin-top: 6px;
  font-size: 13px; }

.darkGreen .toolBarWrapper .toolBarPreviewWrapper {
  height: 302px;
  width: 418px;
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("assets/images/toolBarPreviewBg.png");
  position: relative; }
  .darkGreen .toolBarWrapper .toolBarPreviewWrapper .positionIcons {
    position: absolute;
    border: 1px solid #dedede;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    cursor: pointer; }
    .darkGreen .toolBarWrapper .toolBarPreviewWrapper .positionIcons.selected {
      background-color: #3ba8c5; }
    .darkGreen .toolBarWrapper .toolBarPreviewWrapper .positionIcons.left {
      left: -11px;
      top: 44%;
      padding-right: 2px;
      padding-top: 1px; }
    .darkGreen .toolBarWrapper .toolBarPreviewWrapper .positionIcons.right {
      right: -11px;
      top: 44%;
      padding-left: 2px;
      padding-top: 1px; }
    .darkGreen .toolBarWrapper .toolBarPreviewWrapper .positionIcons.center {
      bottom: -30px;
      left: 50%;
      padding-top: 2px;
      padding-left: 1px; }
    .darkGreen .toolBarWrapper .toolBarPreviewWrapper .positionIcons.bottomLeft {
      bottom: -28px;
      left: -11px;
      padding-left: 4px; }
    .darkGreen .toolBarWrapper .toolBarPreviewWrapper .positionIcons.bottomRight {
      bottom: -28px;
      right: -11px;
      padding-right: 4px; }
  .darkGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarWrapper {
    width: 366px;
    height: 250px;
    position: absolute;
    top: 25px;
    left: 25px; }
  .darkGreen .toolBarWrapper .toolBarPreviewWrapper .ulWrapper {
    position: absolute;
    max-width: 366px;
    max-height: 250px;
    overflow: auto; }
    .darkGreen .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.bar_vertical .btn_wrapper {
      height: 54px; }
      .darkGreen .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.bar_vertical .btn_wrapper .btn_icon.HelpOnClick-WithText {
        margin-left: auto;
        margin-right: auto; }
    .darkGreen .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.transform {
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
  .darkGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns {
    list-style: none;
    height: 41px;
    padding: 0;
    margin: 0;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
    .darkGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper {
      border-right: 1px solid;
      border-left: 1px solid;
      height: 100%;
      float: left;
      min-width: 30px;
      min-height: 30px;
      margin-bottom: 0;
      display: table; }
      .darkGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper:first-child {
        border-left: 0; }
      .darkGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper:last-child {
        border-right: 0; }
      .darkGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div, .darkGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a {
        display: table-cell;
        vertical-align: middle; }
        .darkGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div div.preview_row, .darkGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a div.preview_row {
          display: table-row;
          height: 100%;
          width: 100%; }
        .darkGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div div.preview_clmn, .darkGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a div.preview_clmn {
          display: table-cell;
          vertical-align: middle; }
        .darkGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div > div, .darkGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div {
          display: inline-table;
          vertical-align: middle;
          height: 100%; }
      .darkGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_icon {
        margin-top: -1px;
        margin-left: 14px;
        margin-right: 14px; }
        .darkGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_icon.HelpOnClick-WithText {
          margin-left: 12px;
          margin-right: 0; }
      .darkGreen .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_caption {
        color: #fff;
        display: table-cell;
        font-weight: 700;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 8px;
        padding-right: 15px;
        vertical-align: middle; }

.darkGreen .toolBarWrapper .Tooltip__Base-sc-19cbsjq-0 {
  width: 200px !important; }

.darkGreen .toolBarWrapper .Tooltip__Left-sc-19cbsjq-3 {
  right: 20px; }

.darkGreen .toolBarWrapper .customDrpDwn.customDrpDwn-small .css-10nd86i {
  width: 141px;
  float: left; }

.darkGreen .toolBarWrapper .customDrpDwn.customDrpDwn-small .customSize .form-control {
  width: 51px;
  height: 37px;
  margin-right: 9px; }

.darkGreen .toolBarWrapper .customDrpDwn.customDrpDwn-small .customSize span {
  color: #7d7d7d;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }

.darkGreen .toolBarWrapper .buttonSettingsWrapper {
  box-shadow: none;
  border-radius: 0;
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content; }
  .darkGreen .toolBarWrapper .buttonSettingsWrapper .buttonsSection {
    margin-left: 7%;
    border-radius: 10px;
    background-color: #f6f6f6;
    padding: 26px 20px 15px; }
    .darkGreen .toolBarWrapper .buttonSettingsWrapper .buttonsSection .editorWrapper .editorToolBar .editorLink {
      display: none; }
  .darkGreen .toolBarWrapper .buttonSettingsWrapper .customDrpDwn .css-15k3avv {
    z-index: 10; }
  .darkGreen .toolBarWrapper .buttonSettingsWrapper textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    background-color: #ffffff;
    padding: 15px;
    color: #000000;
    font-family: 'Segoe UI Semi Bold';
    font-size: 14px;
    font-weight: 400; }

.darkGreen .toolBarWrapper .forIcon .css-10nd86i {
  float: left; }

.darkGreen .toolBarWrapper .forIcon .formBtns {
  padding: 0;
  border-top: none;
  margin: 0;
  float: left; }
  .darkGreen .toolBarWrapper .forIcon .formBtns Button {
    margin-left: 6px;
    padding-left: 9px;
    padding-right: 9px; }

.darkGreen .toolBarWrapper .invitationColorPicker > div {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }
  .darkGreen .toolBarWrapper .invitationColorPicker > div > div.colorSelector {
    width: 256px; }
  .darkGreen .toolBarWrapper .invitationColorPicker > div > div:nth-child(2) {
    width: 256px;
    position: absolute !important;
    z-index: 2;
    left: 15px; }
    .darkGreen .toolBarWrapper .invitationColorPicker > div > div:nth-child(2) .flexbox-fix {
      opacity: 0; }

.darkGreen .toolBarWrapper .previewMargin.customSize {
  width: 187px;
  margin-top: 36px;
  margin: 36px auto 0; }

.darkGreen .HelpOnClick-FontIconsLibrary {
  max-height: 70vh; }

.darkGreen .toolbarInvitationPreview {
  height: 150px;
  width: 320px;
  border: 1px solid #035d85;
  border-radius: 5px;
  background-color: #eeeeee;
  margin: 56px auto; }
  .darkGreen .toolbarInvitationPreview .invitationMessageWrapper {
    height: 60px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 5px;
    margin-top: 23px;
    font-size: 16px; }
  .darkGreen .toolbarInvitationPreview button.close {
    margin-right: 3px; }
  .darkGreen .toolbarInvitationPreview button.btn_chat {
    font-size: 14px;
    width: auto;
    display: block;
    padding: 11px 15px;
    border: 1px solid #7e7c7c;
    border-radius: 5px;
    cursor: pointer;
    float: none;
    margin: 0 auto; }

.darkGreen .imageLibrary img {
  padding: 4px;
  cursor: pointer; }
  .darkGreen .imageLibrary img.selected {
    background-color: #e6eef9; }

.green .widgetWrapper h4.widgetName {
  color: #3ba8c5;
  margin-right: 22px; }

.green .widgetWrapper .exit_btn {
  position: absolute;
  right: 37px;
  color: #3ba8c5;
  font-family: "Segoe UI";
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer; }
  .green .widgetWrapper .exit_btn img {
    margin-right: 8px;
    margin-top: -3px; }

.green .widgetSelectorIndicator {
  margin: 30px auto 0; }
  .green .widgetSelectorIndicator p {
    color: #a1a4a8;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    margin-top: 25px; }

.green .innercontent_wrapper .directLinkWrapper {
  margin-top: 56px; }
  .green .innercontent_wrapper .directLinkWrapper h4 {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    margin-left: 18px;
    margin-bottom: 34px;
    position: relative; }
    .green .innercontent_wrapper .directLinkWrapper h4 span {
      border: 1px solid #3ba8c5;
      background-color: #ffffff;
      border-radius: 50%;
      position: absolute;
      margin-left: 9px;
      width: 24px;
      height: 24px;
      display: inline-block; }
      .green .innercontent_wrapper .directLinkWrapper h4 span img {
        position: absolute;
        top: 5px;
        left: 10px; }
  .green .innercontent_wrapper .directLinkWrapper .with_btn input {
    float: left; }
  .green .innercontent_wrapper .directLinkWrapper .with_btn .uploadBtn button {
    background-color: #3ba8c5; }
    .green .innercontent_wrapper .directLinkWrapper .with_btn .uploadBtn button p {
      color: #fff; }
  .green .innercontent_wrapper .directLinkWrapper .invitationCustomImg {
    margin-bottom: 0.5rem; }
  .green .innercontent_wrapper .directLinkWrapper .btn_wrapper {
    margin-bottom: 15px; }
    .green .innercontent_wrapper .directLinkWrapper .btn_wrapper .uploadBtn.iconUpload {
      margin-left: 0;
      margin-right: 5px; }
      .green .innercontent_wrapper .directLinkWrapper .btn_wrapper .uploadBtn.iconUpload button.uploadIcon input {
        width: 60px; }
  .green .innercontent_wrapper .directLinkWrapper .box {
    padding: 30px; }
    .green .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:first-child {
      width: 131px;
      margin-right: 10px; }
    .green .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:nth-child(2) {
      width: 117px; }
      .green .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:nth-child(2) .css-10nd86i {
        width: 117px; }
  .green .innercontent_wrapper .directLinkWrapper .formBtns Button.btn_save {
    width: auto; }
  .green .innercontent_wrapper .directLinkWrapper .customOption .row > div:nth-child(2) > div:nth-child(2) {
    position: absolute !important;
    z-index: 2;
    top: 44px;
    left: 157px; }
    .green .innercontent_wrapper .directLinkWrapper .customOption .row > div:nth-child(2) > div:nth-child(2) .flexbox-fix {
      display: none !important; }
  .green .innercontent_wrapper .directLinkWrapper .colorSelector .css-1wy0on6 {
    background-color: #fff; }
  .green .innercontent_wrapper .directLinkWrapper .colorSelector input {
    border: none; }
    .green .innercontent_wrapper .directLinkWrapper .colorSelector input:focus {
      border: none; }
  .green .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper {
    position: relative; }
    .green .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper p {
      width: 178px;
      float: left;
      padding-top: 30%;
      font-size: 22px;
      text-align: right;
      text-decoration: underline; }
  .green .innercontent_wrapper .directLinkWrapper .directLinkpreview {
    background-repeat: no-repeat;
    width: 165px;
    height: 114px;
    float: right;
    margin-top: 100px;
    margin-right: 50px;
    margin-left: 24px;
    background-size: cover !important; }
  .green .innercontent_wrapper .directLinkWrapper .theme {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-top: 7px; }
  .green .innercontent_wrapper .directLinkWrapper .preview {
    width: 330px;
    height: 101px;
    padding: 12px 0 0 0;
    margin-top: 40px;
    margin-bottom: 47px;
    background-repeat: no-repeat; }
    .green .innercontent_wrapper .directLinkWrapper .preview .widget-preview {
      width: 166px;
      height: 76px; }
      .green .innercontent_wrapper .directLinkWrapper .preview .widget-preview div {
        font-family: Arial,Helvetica,sans-serif; }
      .green .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-title {
        padding: 11px 0 0 18px;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        text-align: left; }
      .green .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-text {
        width: 146px;
        overflow: hidden;
        white-space: nowrap;
        padding: 1px 0 0;
        text-align: center;
        color: #A8A8A8;
        font-size: 12px;
        margin-left: 10px; }
      .green .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-copy {
        padding: 8px 13px 0;
        text-align: right;
        font-size: 10px;
        color: #fff;
        opacity: .5;
        -moz-opacity: .5;
        -khtml-opacity: .5;
        filter: alpha(opacity=50); }
  .green .innercontent_wrapper .directLinkWrapper .widgetPosition {
    width: 330px; }
    .green .innercontent_wrapper .directLinkWrapper .widgetPosition p {
      width: 236px;
      height: 30px;
      border-radius: 5px;
      border: 1px solid #dedede;
      background-color: #ffffff;
      color: #000000;
      font-family: Segoe UI;
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 37px;
      margin-left: auto;
      margin-right: auto; }
      .green .innercontent_wrapper .directLinkWrapper .widgetPosition p span {
        width: 50%;
        display: inline-block;
        padding: 6px 0;
        cursor: pointer; }
        .green .innercontent_wrapper .directLinkWrapper .widgetPosition p span:first-child {
          border-radius: 5px 0 0 5px; }
        .green .innercontent_wrapper .directLinkWrapper .widgetPosition p span:last-child {
          border-radius: 0 5px 5px 0; }
        .green .innercontent_wrapper .directLinkWrapper .widgetPosition p span.selected {
          color: #ffffff;
          background-color: #3ba8c5; }
  .green .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper {
    width: 90%;
    margin: 0 auto; }
    .green .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp {
      width: 34px;
      height: 34px;
      border: 1px solid #dedede;
      border-radius: 50%;
      float: left;
      padding: 3px;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer; }
      .green .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp div {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        margin: 0; }
      .green .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:hover, .green .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp.selected {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        transition: -webkit-transform 100ms ease 0s;
        transition: transform 100ms ease 0s;
        transition: transform 100ms ease 0s, -webkit-transform 100ms ease 0s;
        padding: 2px; }
        .green .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:hover div, .green .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp.selected div {
          -webkit-transform: scale(0.9);
                  transform: scale(0.9); }
      .green .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(1):hover {
        border: 2px solid #0475A8; }
      .green .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(2):hover {
        border: 2px solid #5F8706; }
      .green .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(3):hover {
        border: 2px solid #D6680B; }
      .green .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(4):hover {
        border: 2px solid #B0097D; }
      .green .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(5):hover {
        border: 2px solid #9D0A09; }
      .green .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(6):hover {
        border: 2px solid #424E54; }
      .green .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(7):hover {
        border: 2px solid #037FAF; }
      .green .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(8):hover {
        border: 2px solid #608706; }
      .green .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(9):hover {
        border: 2px solid #D26009; }
      .green .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(10):hover {
        border: 2px solid #AF097D; }
      .green .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(11):hover {
        border: 2px solid #950909; }
      .green .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(12):hover {
        border: 2px solid #3E484E; }
      .green .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(13):hover {
        border: 2px solid #0476A8; }
      .green .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(14):hover {
        border: 2px solid #98B45D; }
      .green .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(15):hover {
        border: 2px solid #ADADAD; }
      .green .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(16):hover {
        border: 2px solid #98A7AD; }
  .green .innercontent_wrapper .directLinkWrapper .circle-picker {
    padding: 0px 9px; }
    .green .innercontent_wrapper .directLinkWrapper .circle-picker > span > div > span {
      padding: 4px;
      position: relative;
      border: 1px solid #dedede;
      border-radius: 50%;
      display: inline-block;
      width: 34px;
      height: 34px; }
    .green .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(1) > div:hover span {
      border: 2px solid #0475A8; }
    .green .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(1) > div > span > div {
      box-shadow: #0475A8 0px 0px 0px 17px inset !important; }
    .green .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(2) > div:hover span {
      border: 2px solid #5F8706; }
    .green .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(3) > div:hover span {
      border: 2px solid #D6680B; }
    .green .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(4) > div:hover span {
      border: 2px solid #B0097D; }
    .green .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(5) > div:hover span {
      border: 2px solid #9D0A09; }
    .green .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(6) > div:hover span {
      border: 2px solid #424E54; }
    .green .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(7) > div:hover span {
      border: 2px solid #037FAF; }
    .green .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(8) > div:hover span {
      border: 2px solid #587D06; }
    .green .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(9) > div:hover span {
      border: 2px solid #D26009; }
    .green .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(10) > div:hover span {
      border: 2px solid #AF087C; }
    .green .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(11) > div:hover span {
      border: 2px solid #950909; }
    .green .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(12) > div:hover span {
      border: 2px solid #3E484E; }
    .green .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(13) > div:hover span {
      border: 2px solid #0476A8; }
    .green .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(14) > div:hover span {
      border: 2px solid #98B45D; }
    .green .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(15) > div:hover span {
      border: 2px solid #ADADAD; }
    .green .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(16) > div:hover span {
      border: 2px solid #98A7AD; }
  .green .innercontent_wrapper .directLinkWrapper .iconWrapper {
    height: 390px;
    position: relative; }
    .green .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn {
      position: absolute;
      width: 26px;
      height: 27px;
      border: 1px solid #dedede;
      border-radius: 50%;
      top: -27px;
      right: 6px;
      cursor: pointer; }
      .green .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn img {
        margin-top: -2px;
        margin-left: 1px; }
      .green .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn.bottomScroll {
        top: auto;
        bottom: -27px; }
    .green .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow {
      margin-bottom: 13px;
      margin-right: 30px;
      border: 1px solid #dedede;
      border-radius: 10px;
      cursor: pointer; }
      .green .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div {
        width: 151px;
        height: 87px;
        background-color: #ffffff;
        margin-right: 12px;
        padding: 16px;
        border-radius: 10px; }
        .green .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div.no-image {
          opacity: 0; }
        .green .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div:last-child {
          margin-right: 0; }
      .green .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow:last-child {
        margin-bottom: 0; }
      .green .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow.selected {
        background-color: #dedede; }
        .green .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow.selected > div {
          background-color: #dedede; }
    .green .innercontent_wrapper .directLinkWrapper .iconWrapper .rcs-custom-scroll .rcs-inner-handle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #3ba8c5;
      background-color: #3ba8c5;
      margin-left: -8px; }
    .green .innercontent_wrapper .directLinkWrapper .iconWrapper .rcs-custom-scroll .rcs-custom-scrollbar {
      opacity: 1;
      right: 13px;
      border-left: 2px solid #dedede;
      height: 590px; }
  .green .innercontent_wrapper .directLinkWrapper .uploadBtn {
    margin-top: 13px; }
    .green .innercontent_wrapper .directLinkWrapper .uploadBtn.iconUpload {
      margin-top: 5px;
      margin-left: 6px;
      float: left; }
    .green .innercontent_wrapper .directLinkWrapper .uploadBtn button {
      width: 218px;
      height: 29px;
      border-radius: 3px;
      border: 1px solid #3ba8c5;
      background-color: #ffffff;
      position: relative;
      padding: 0; }
      .green .innercontent_wrapper .directLinkWrapper .uploadBtn button.uploadIcon {
        width: auto;
        float: left;
        padding: 0 5px; }
        .green .innercontent_wrapper .directLinkWrapper .uploadBtn button.uploadIcon input {
          width: auto; }
      .green .innercontent_wrapper .directLinkWrapper .uploadBtn button p {
        margin: 0;
        color: #3ba8c5;
        font-family: "Segoe UI";
        font-size: 13px;
        font-weight: 700;
        line-height: 30.4px;
        text-transform: uppercase; }
        .green .innercontent_wrapper .directLinkWrapper .uploadBtn button p img {
          margin-right: 4px;
          margin-top: -4px; }
      .green .innercontent_wrapper .directLinkWrapper .uploadBtn button input {
        position: absolute;
        top: 0;
        left: 0;
        width: 218px;
        height: 29px;
        opacity: 0;
        cursor: pointer; }
  .green .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span {
    margin-right: 15px;
    color: #3ba8c5;
    text-decoration: underline;
    cursor: pointer; }
    .green .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span.selected {
      text-decoration: none;
      color: #000000; }

.green .carousel {
  padding: 12px 0px; }
  .green .carousel img.carouselBtn {
    position: absolute;
    cursor: pointer;
    top: 90px; }
    .green .carousel img.carouselBtn.carousel-left {
      left: 30px; }
  .green .carousel ul {
    margin-left: 0;
    margin-right: 0;
    transition: all 1s; }
    .green .carousel ul.widgetMode li {
      opacity: 0.5;
      float: left; }
      .green .carousel ul.widgetMode li.selected, .green .carousel ul.widgetMode li:hover {
        opacity: 1;
        border: 3px solid #3ba8c5; }
        .green .carousel ul.widgetMode li.selected h4, .green .carousel ul.widgetMode li:hover h4 {
          background-color: #3ba8c5;
          color: #ffffff; }
  .green .carousel .carousel-cell {
    cursor: pointer;
    width: 210px;
    height: 192px;
    box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
    border-radius: 20px;
    background-color: #ffffff;
    padding: 0px;
    display: inline-block;
    margin-right: 20px;
    float: left; }
    .green .carousel .carousel-cell.opacity_0 {
      opacity: 0;
      margin-right: 60px;
      transition: opacity 2s; }
    .green .carousel .carousel-cell:hover {
      border: 3px solid #3ba8c5; }
      .green .carousel .carousel-cell:hover h4 {
        background-color: #3ba8c5;
        color: #ffffff; }
    .green .carousel .carousel-cell h4 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      padding: 22px 0 18px;
      border-bottom: 4px solid #3ba8c5;
      margin: 0;
      border-radius: 15px 15px 0 0; }
    .green .carousel .carousel-cell .img-wrapper {
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      width: 210px; }

.green .settingFormWrapper .codeWrapper {
  margin-top: 69px; }
  .green .settingFormWrapper .codeWrapper p {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    text-align: left; }
  .green .settingFormWrapper .codeWrapper textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    padding: 13px;
    margin-bottom: 10px; }
  .green .settingFormWrapper .codeWrapper .formBtns {
    padding: 0;
    margin: 0;
    border: none; }
    .green .settingFormWrapper .codeWrapper .formBtns Button.btn_clr {
      margin-left: 0;
      margin-right: 10px;
      width: auto;
      padding: 9px 30px; }
      .green .settingFormWrapper .codeWrapper .formBtns Button.btn_clr p {
        text-align: center;
        color: #3ba8c5; }
  .green .settingFormWrapper .codeWrapper .advancedSection {
    margin-top: -48px; }
    .green .settingFormWrapper .codeWrapper .advancedSection .advancedOption p {
      color: #3ba8c5;
      font-family: "Segoe UI Bold";
      font-size: 14px;
      font-weight: 700; }
      .green .settingFormWrapper .codeWrapper .advancedSection .advancedOption p:first-child {
        width: 27px;
        height: 27px;
        border-radius: 50%;
        border: 1px solid #4b8f4e;
        background-color: #4b8f4e;
        background-image: url("assets/images/expand.png");
        background-repeat: no-repeat;
        background-position: center;
        padding: 0;
        float: left;
        margin-right: 9px; }
      .green .settingFormWrapper .codeWrapper .advancedSection .advancedOption p:last-child {
        margin-top: 20px; }
    .green .settingFormWrapper .codeWrapper .advancedSection .advancedOption.collapsed p:first-child {
      border: 1px solid #dedede;
      background-color: #ffffff;
      background-image: url("assets/images/plus_icons/plus_green.png");
      background-size: 11px; }
    .green .settingFormWrapper .codeWrapper .advancedSection .advancedOption.dropdown-toggle::after {
      display: none; }

.green .mailer .modal-dialog textarea {
  border-radius: 5px;
  border: 1px solid #dedede;
  padding: 13px;
  width: 367px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px; }

.green .mailer .modal-dialog label {
  text-align: left;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.green .mailer .modal-dialog .form-control {
  width: 256px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.green .toolBarWrapper .toolTipDesignWrapper {
  border-radius: 10px;
  background-color: #f6f6f6;
  padding: 20px 20px 4px; }

.green .toolBarWrapper .toolBarMenuWrapper {
  height: 53px;
  margin-bottom: 36px;
  position: relative; }
  .green .toolBarWrapper .toolBarMenuWrapper .toolBarBoxWrapper {
    position: absolute;
    height: 13px;
    width: 100%;
    top: -14px;
    left: 0;
    background-color: #fff;
    z-index: 7; }
    .green .toolBarWrapper .toolBarMenuWrapper .toolBarBoxWrapper.bottom {
      top: auto;
      bottom: -13px; }
  .green .toolBarWrapper .toolBarMenuWrapper .toolBarBox {
    position: absolute;
    border: 1px solid #dedede;
    height: 54px;
    width: 98%;
    border-right: 0;
    top: -1px;
    z-index: 5;
    left: 0;
    pointer-events: none; }
  .green .toolBarWrapper .toolBarMenuWrapper .menuList {
    height: 54px;
    background-color: #fff;
    cursor: pointer; }
    .green .toolBarWrapper .toolBarMenuWrapper .menuList.selected {
      background-color: #3ba8c5; }
      .green .toolBarWrapper .toolBarMenuWrapper .menuList.selected p {
        color: #fff; }
      .green .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line1 {
        background-color: #fff;
        border-color: #3ba8c5; }
      .green .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line2 {
        border-color: #3ba8c5; }
        .green .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line2.mask {
          opacity: 1;
          background-color: #fff; }
    .green .toolBarWrapper .toolBarMenuWrapper .menuList p {
      width: 100%;
      float: left;
      color: #293037;
      font-family: "Segoe UI Semi Bold";
      font-size: 13px;
      font-weight: 700; }
    .green .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper {
      position: absolute;
      right: 21px; }
      .green .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line1 {
        width: 35px;
        height: 20px;
        border-bottom: 1px solid #dedede;
        -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
        position: absolute; }
        .green .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line1.selectedNext {
          background-color: #3ba8c5;
          border-color: #fff; }
      .green .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2 {
        width: 33px;
        height: 20px;
        border-bottom: 1px solid #dedede;
        -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
        position: absolute;
        top: 17px;
        left: -16px; }
        .green .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.mask {
          opacity: 0;
          left: -1px;
          width: 37px;
          top: 29px;
          background-color: #fff;
          border: 0; }
          .green .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.mask.selectedNext {
            opacity: 1;
            background-color: #3ba8c5; }
        .green .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.selectedNext {
          border-color: #fff; }

.green .toolBarWrapper .form-label.chatWindow {
  padding-top: 3px; }

.green .toolBarWrapper .chat_windowWrapper .btn-group-toggle {
  display: inline-block; }
  .green .toolBarWrapper .chat_windowWrapper .btn-group-toggle .custom_radionBtn {
    font-size: 14px;
    font-family: 'Segoe UI Semi Bold'; }
  .green .toolBarWrapper .chat_windowWrapper .btn-group-toggle > label {
    margin-bottom: 17px;
    text-align: left; }
    .green .toolBarWrapper .chat_windowWrapper .btn-group-toggle > label:last-child {
      margin-bottom: 14px; }

.green .toolBarWrapper .settingFormWrapper .customSize .form-label {
  font-size: 13px; }

.green .toolBarWrapper .settingFormWrapper .customSize .form-control {
  width: 62px;
  height: 29px;
  border-radius: 3px;
  float: left;
  margin-right: 12px;
  font-size: 13px; }

.green .toolBarWrapper .settingFormWrapper .customSize span {
  float: left;
  margin-top: 6px;
  font-size: 13px; }

.green .toolBarWrapper .toolBarPreviewWrapper {
  height: 302px;
  width: 418px;
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("assets/images/toolBarPreviewBg.png");
  position: relative; }
  .green .toolBarWrapper .toolBarPreviewWrapper .positionIcons {
    position: absolute;
    border: 1px solid #dedede;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    cursor: pointer; }
    .green .toolBarWrapper .toolBarPreviewWrapper .positionIcons.selected {
      background-color: #3ba8c5; }
    .green .toolBarWrapper .toolBarPreviewWrapper .positionIcons.left {
      left: -11px;
      top: 44%;
      padding-right: 2px;
      padding-top: 1px; }
    .green .toolBarWrapper .toolBarPreviewWrapper .positionIcons.right {
      right: -11px;
      top: 44%;
      padding-left: 2px;
      padding-top: 1px; }
    .green .toolBarWrapper .toolBarPreviewWrapper .positionIcons.center {
      bottom: -30px;
      left: 50%;
      padding-top: 2px;
      padding-left: 1px; }
    .green .toolBarWrapper .toolBarPreviewWrapper .positionIcons.bottomLeft {
      bottom: -28px;
      left: -11px;
      padding-left: 4px; }
    .green .toolBarWrapper .toolBarPreviewWrapper .positionIcons.bottomRight {
      bottom: -28px;
      right: -11px;
      padding-right: 4px; }
  .green .toolBarWrapper .toolBarPreviewWrapper .toolBarWrapper {
    width: 366px;
    height: 250px;
    position: absolute;
    top: 25px;
    left: 25px; }
  .green .toolBarWrapper .toolBarPreviewWrapper .ulWrapper {
    position: absolute;
    max-width: 366px;
    max-height: 250px;
    overflow: auto; }
    .green .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.bar_vertical .btn_wrapper {
      height: 54px; }
      .green .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.bar_vertical .btn_wrapper .btn_icon.HelpOnClick-WithText {
        margin-left: auto;
        margin-right: auto; }
    .green .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.transform {
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
  .green .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns {
    list-style: none;
    height: 41px;
    padding: 0;
    margin: 0;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
    .green .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper {
      border-right: 1px solid;
      border-left: 1px solid;
      height: 100%;
      float: left;
      min-width: 30px;
      min-height: 30px;
      margin-bottom: 0;
      display: table; }
      .green .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper:first-child {
        border-left: 0; }
      .green .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper:last-child {
        border-right: 0; }
      .green .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div, .green .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a {
        display: table-cell;
        vertical-align: middle; }
        .green .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div div.preview_row, .green .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a div.preview_row {
          display: table-row;
          height: 100%;
          width: 100%; }
        .green .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div div.preview_clmn, .green .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a div.preview_clmn {
          display: table-cell;
          vertical-align: middle; }
        .green .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div > div, .green .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div {
          display: inline-table;
          vertical-align: middle;
          height: 100%; }
      .green .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_icon {
        margin-top: -1px;
        margin-left: 14px;
        margin-right: 14px; }
        .green .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_icon.HelpOnClick-WithText {
          margin-left: 12px;
          margin-right: 0; }
      .green .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_caption {
        color: #fff;
        display: table-cell;
        font-weight: 700;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 8px;
        padding-right: 15px;
        vertical-align: middle; }

.green .toolBarWrapper .Tooltip__Base-sc-19cbsjq-0 {
  width: 200px !important; }

.green .toolBarWrapper .Tooltip__Left-sc-19cbsjq-3 {
  right: 20px; }

.green .toolBarWrapper .customDrpDwn.customDrpDwn-small .css-10nd86i {
  width: 141px;
  float: left; }

.green .toolBarWrapper .customDrpDwn.customDrpDwn-small .customSize .form-control {
  width: 51px;
  height: 37px;
  margin-right: 9px; }

.green .toolBarWrapper .customDrpDwn.customDrpDwn-small .customSize span {
  color: #7d7d7d;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }

.green .toolBarWrapper .buttonSettingsWrapper {
  box-shadow: none;
  border-radius: 0;
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content; }
  .green .toolBarWrapper .buttonSettingsWrapper .buttonsSection {
    margin-left: 7%;
    border-radius: 10px;
    background-color: #f6f6f6;
    padding: 26px 20px 15px; }
    .green .toolBarWrapper .buttonSettingsWrapper .buttonsSection .editorWrapper .editorToolBar .editorLink {
      display: none; }
  .green .toolBarWrapper .buttonSettingsWrapper .customDrpDwn .css-15k3avv {
    z-index: 10; }
  .green .toolBarWrapper .buttonSettingsWrapper textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    background-color: #ffffff;
    padding: 15px;
    color: #000000;
    font-family: 'Segoe UI Semi Bold';
    font-size: 14px;
    font-weight: 400; }

.green .toolBarWrapper .forIcon .css-10nd86i {
  float: left; }

.green .toolBarWrapper .forIcon .formBtns {
  padding: 0;
  border-top: none;
  margin: 0;
  float: left; }
  .green .toolBarWrapper .forIcon .formBtns Button {
    margin-left: 6px;
    padding-left: 9px;
    padding-right: 9px; }

.green .toolBarWrapper .invitationColorPicker > div {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }
  .green .toolBarWrapper .invitationColorPicker > div > div.colorSelector {
    width: 256px; }
  .green .toolBarWrapper .invitationColorPicker > div > div:nth-child(2) {
    width: 256px;
    position: absolute !important;
    z-index: 2;
    left: 15px; }
    .green .toolBarWrapper .invitationColorPicker > div > div:nth-child(2) .flexbox-fix {
      opacity: 0; }

.green .toolBarWrapper .previewMargin.customSize {
  width: 187px;
  margin-top: 36px;
  margin: 36px auto 0; }

.green .HelpOnClick-FontIconsLibrary {
  max-height: 70vh; }

.green .toolbarInvitationPreview {
  height: 150px;
  width: 320px;
  border: 1px solid #035d85;
  border-radius: 5px;
  background-color: #eeeeee;
  margin: 56px auto; }
  .green .toolbarInvitationPreview .invitationMessageWrapper {
    height: 60px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 5px;
    margin-top: 23px;
    font-size: 16px; }
  .green .toolbarInvitationPreview button.close {
    margin-right: 3px; }
  .green .toolbarInvitationPreview button.btn_chat {
    font-size: 14px;
    width: auto;
    display: block;
    padding: 11px 15px;
    border: 1px solid #7e7c7c;
    border-radius: 5px;
    cursor: pointer;
    float: none;
    margin: 0 auto; }

.green .imageLibrary img {
  padding: 4px;
  cursor: pointer; }
  .green .imageLibrary img.selected {
    background-color: #e6eef9; }

.grey .widgetWrapper h4.widgetName {
  color: #3ba8c5;
  margin-right: 22px; }

.grey .widgetWrapper .exit_btn {
  position: absolute;
  right: 37px;
  color: #3ba8c5;
  font-family: "Segoe UI";
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer; }
  .grey .widgetWrapper .exit_btn img {
    margin-right: 8px;
    margin-top: -3px; }

.grey .widgetSelectorIndicator {
  margin: 30px auto 0; }
  .grey .widgetSelectorIndicator p {
    color: #a1a4a8;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    margin-top: 25px; }

.grey .innercontent_wrapper .directLinkWrapper {
  margin-top: 56px; }
  .grey .innercontent_wrapper .directLinkWrapper h4 {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    margin-left: 18px;
    margin-bottom: 34px;
    position: relative; }
    .grey .innercontent_wrapper .directLinkWrapper h4 span {
      border: 1px solid #3ba8c5;
      background-color: #ffffff;
      border-radius: 50%;
      position: absolute;
      margin-left: 9px;
      width: 24px;
      height: 24px;
      display: inline-block; }
      .grey .innercontent_wrapper .directLinkWrapper h4 span img {
        position: absolute;
        top: 5px;
        left: 10px; }
  .grey .innercontent_wrapper .directLinkWrapper .with_btn input {
    float: left; }
  .grey .innercontent_wrapper .directLinkWrapper .with_btn .uploadBtn button {
    background-color: #3ba8c5; }
    .grey .innercontent_wrapper .directLinkWrapper .with_btn .uploadBtn button p {
      color: #fff; }
  .grey .innercontent_wrapper .directLinkWrapper .invitationCustomImg {
    margin-bottom: 0.5rem; }
  .grey .innercontent_wrapper .directLinkWrapper .btn_wrapper {
    margin-bottom: 15px; }
    .grey .innercontent_wrapper .directLinkWrapper .btn_wrapper .uploadBtn.iconUpload {
      margin-left: 0;
      margin-right: 5px; }
      .grey .innercontent_wrapper .directLinkWrapper .btn_wrapper .uploadBtn.iconUpload button.uploadIcon input {
        width: 60px; }
  .grey .innercontent_wrapper .directLinkWrapper .box {
    padding: 30px; }
    .grey .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:first-child {
      width: 131px;
      margin-right: 10px; }
    .grey .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:nth-child(2) {
      width: 117px; }
      .grey .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:nth-child(2) .css-10nd86i {
        width: 117px; }
  .grey .innercontent_wrapper .directLinkWrapper .formBtns Button.btn_save {
    width: auto; }
  .grey .innercontent_wrapper .directLinkWrapper .customOption .row > div:nth-child(2) > div:nth-child(2) {
    position: absolute !important;
    z-index: 2;
    top: 44px;
    left: 157px; }
    .grey .innercontent_wrapper .directLinkWrapper .customOption .row > div:nth-child(2) > div:nth-child(2) .flexbox-fix {
      display: none !important; }
  .grey .innercontent_wrapper .directLinkWrapper .colorSelector .css-1wy0on6 {
    background-color: #fff; }
  .grey .innercontent_wrapper .directLinkWrapper .colorSelector input {
    border: none; }
    .grey .innercontent_wrapper .directLinkWrapper .colorSelector input:focus {
      border: none; }
  .grey .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper {
    position: relative; }
    .grey .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper p {
      width: 178px;
      float: left;
      padding-top: 30%;
      font-size: 22px;
      text-align: right;
      text-decoration: underline; }
  .grey .innercontent_wrapper .directLinkWrapper .directLinkpreview {
    background-repeat: no-repeat;
    width: 165px;
    height: 114px;
    float: right;
    margin-top: 100px;
    margin-right: 50px;
    margin-left: 24px;
    background-size: cover !important; }
  .grey .innercontent_wrapper .directLinkWrapper .theme {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-top: 7px; }
  .grey .innercontent_wrapper .directLinkWrapper .preview {
    width: 330px;
    height: 101px;
    padding: 12px 0 0 0;
    margin-top: 40px;
    margin-bottom: 47px;
    background-repeat: no-repeat; }
    .grey .innercontent_wrapper .directLinkWrapper .preview .widget-preview {
      width: 166px;
      height: 76px; }
      .grey .innercontent_wrapper .directLinkWrapper .preview .widget-preview div {
        font-family: Arial,Helvetica,sans-serif; }
      .grey .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-title {
        padding: 11px 0 0 18px;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        text-align: left; }
      .grey .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-text {
        width: 146px;
        overflow: hidden;
        white-space: nowrap;
        padding: 1px 0 0;
        text-align: center;
        color: #A8A8A8;
        font-size: 12px;
        margin-left: 10px; }
      .grey .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-copy {
        padding: 8px 13px 0;
        text-align: right;
        font-size: 10px;
        color: #fff;
        opacity: .5;
        -moz-opacity: .5;
        -khtml-opacity: .5;
        filter: alpha(opacity=50); }
  .grey .innercontent_wrapper .directLinkWrapper .widgetPosition {
    width: 330px; }
    .grey .innercontent_wrapper .directLinkWrapper .widgetPosition p {
      width: 236px;
      height: 30px;
      border-radius: 5px;
      border: 1px solid #dedede;
      background-color: #ffffff;
      color: #000000;
      font-family: Segoe UI;
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 37px;
      margin-left: auto;
      margin-right: auto; }
      .grey .innercontent_wrapper .directLinkWrapper .widgetPosition p span {
        width: 50%;
        display: inline-block;
        padding: 6px 0;
        cursor: pointer; }
        .grey .innercontent_wrapper .directLinkWrapper .widgetPosition p span:first-child {
          border-radius: 5px 0 0 5px; }
        .grey .innercontent_wrapper .directLinkWrapper .widgetPosition p span:last-child {
          border-radius: 0 5px 5px 0; }
        .grey .innercontent_wrapper .directLinkWrapper .widgetPosition p span.selected {
          color: #ffffff;
          background-color: #3ba8c5; }
  .grey .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper {
    width: 90%;
    margin: 0 auto; }
    .grey .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp {
      width: 34px;
      height: 34px;
      border: 1px solid #dedede;
      border-radius: 50%;
      float: left;
      padding: 3px;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer; }
      .grey .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp div {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        margin: 0; }
      .grey .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:hover, .grey .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp.selected {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        transition: -webkit-transform 100ms ease 0s;
        transition: transform 100ms ease 0s;
        transition: transform 100ms ease 0s, -webkit-transform 100ms ease 0s;
        padding: 2px; }
        .grey .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:hover div, .grey .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp.selected div {
          -webkit-transform: scale(0.9);
                  transform: scale(0.9); }
      .grey .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(1):hover {
        border: 2px solid #0475A8; }
      .grey .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(2):hover {
        border: 2px solid #5F8706; }
      .grey .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(3):hover {
        border: 2px solid #D6680B; }
      .grey .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(4):hover {
        border: 2px solid #B0097D; }
      .grey .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(5):hover {
        border: 2px solid #9D0A09; }
      .grey .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(6):hover {
        border: 2px solid #424E54; }
      .grey .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(7):hover {
        border: 2px solid #037FAF; }
      .grey .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(8):hover {
        border: 2px solid #608706; }
      .grey .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(9):hover {
        border: 2px solid #D26009; }
      .grey .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(10):hover {
        border: 2px solid #AF097D; }
      .grey .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(11):hover {
        border: 2px solid #950909; }
      .grey .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(12):hover {
        border: 2px solid #3E484E; }
      .grey .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(13):hover {
        border: 2px solid #0476A8; }
      .grey .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(14):hover {
        border: 2px solid #98B45D; }
      .grey .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(15):hover {
        border: 2px solid #ADADAD; }
      .grey .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(16):hover {
        border: 2px solid #98A7AD; }
  .grey .innercontent_wrapper .directLinkWrapper .circle-picker {
    padding: 0px 9px; }
    .grey .innercontent_wrapper .directLinkWrapper .circle-picker > span > div > span {
      padding: 4px;
      position: relative;
      border: 1px solid #dedede;
      border-radius: 50%;
      display: inline-block;
      width: 34px;
      height: 34px; }
    .grey .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(1) > div:hover span {
      border: 2px solid #0475A8; }
    .grey .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(1) > div > span > div {
      box-shadow: #0475A8 0px 0px 0px 17px inset !important; }
    .grey .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(2) > div:hover span {
      border: 2px solid #5F8706; }
    .grey .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(3) > div:hover span {
      border: 2px solid #D6680B; }
    .grey .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(4) > div:hover span {
      border: 2px solid #B0097D; }
    .grey .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(5) > div:hover span {
      border: 2px solid #9D0A09; }
    .grey .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(6) > div:hover span {
      border: 2px solid #424E54; }
    .grey .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(7) > div:hover span {
      border: 2px solid #037FAF; }
    .grey .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(8) > div:hover span {
      border: 2px solid #587D06; }
    .grey .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(9) > div:hover span {
      border: 2px solid #D26009; }
    .grey .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(10) > div:hover span {
      border: 2px solid #AF087C; }
    .grey .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(11) > div:hover span {
      border: 2px solid #950909; }
    .grey .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(12) > div:hover span {
      border: 2px solid #3E484E; }
    .grey .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(13) > div:hover span {
      border: 2px solid #0476A8; }
    .grey .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(14) > div:hover span {
      border: 2px solid #98B45D; }
    .grey .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(15) > div:hover span {
      border: 2px solid #ADADAD; }
    .grey .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(16) > div:hover span {
      border: 2px solid #98A7AD; }
  .grey .innercontent_wrapper .directLinkWrapper .iconWrapper {
    height: 390px;
    position: relative; }
    .grey .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn {
      position: absolute;
      width: 26px;
      height: 27px;
      border: 1px solid #dedede;
      border-radius: 50%;
      top: -27px;
      right: 6px;
      cursor: pointer; }
      .grey .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn img {
        margin-top: -2px;
        margin-left: 1px; }
      .grey .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn.bottomScroll {
        top: auto;
        bottom: -27px; }
    .grey .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow {
      margin-bottom: 13px;
      margin-right: 30px;
      border: 1px solid #dedede;
      border-radius: 10px;
      cursor: pointer; }
      .grey .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div {
        width: 151px;
        height: 87px;
        background-color: #ffffff;
        margin-right: 12px;
        padding: 16px;
        border-radius: 10px; }
        .grey .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div.no-image {
          opacity: 0; }
        .grey .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div:last-child {
          margin-right: 0; }
      .grey .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow:last-child {
        margin-bottom: 0; }
      .grey .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow.selected {
        background-color: #dedede; }
        .grey .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow.selected > div {
          background-color: #dedede; }
    .grey .innercontent_wrapper .directLinkWrapper .iconWrapper .rcs-custom-scroll .rcs-inner-handle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #3ba8c5;
      background-color: #3ba8c5;
      margin-left: -8px; }
    .grey .innercontent_wrapper .directLinkWrapper .iconWrapper .rcs-custom-scroll .rcs-custom-scrollbar {
      opacity: 1;
      right: 13px;
      border-left: 2px solid #dedede;
      height: 590px; }
  .grey .innercontent_wrapper .directLinkWrapper .uploadBtn {
    margin-top: 13px; }
    .grey .innercontent_wrapper .directLinkWrapper .uploadBtn.iconUpload {
      margin-top: 5px;
      margin-left: 6px;
      float: left; }
    .grey .innercontent_wrapper .directLinkWrapper .uploadBtn button {
      width: 218px;
      height: 29px;
      border-radius: 3px;
      border: 1px solid #3ba8c5;
      background-color: #ffffff;
      position: relative;
      padding: 0; }
      .grey .innercontent_wrapper .directLinkWrapper .uploadBtn button.uploadIcon {
        width: auto;
        float: left;
        padding: 0 5px; }
        .grey .innercontent_wrapper .directLinkWrapper .uploadBtn button.uploadIcon input {
          width: auto; }
      .grey .innercontent_wrapper .directLinkWrapper .uploadBtn button p {
        margin: 0;
        color: #3ba8c5;
        font-family: "Segoe UI";
        font-size: 13px;
        font-weight: 700;
        line-height: 30.4px;
        text-transform: uppercase; }
        .grey .innercontent_wrapper .directLinkWrapper .uploadBtn button p img {
          margin-right: 4px;
          margin-top: -4px; }
      .grey .innercontent_wrapper .directLinkWrapper .uploadBtn button input {
        position: absolute;
        top: 0;
        left: 0;
        width: 218px;
        height: 29px;
        opacity: 0;
        cursor: pointer; }
  .grey .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span {
    margin-right: 15px;
    color: #3ba8c5;
    text-decoration: underline;
    cursor: pointer; }
    .grey .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span.selected {
      text-decoration: none;
      color: #000000; }

.grey .carousel {
  padding: 12px 0px; }
  .grey .carousel img.carouselBtn {
    position: absolute;
    cursor: pointer;
    top: 90px; }
    .grey .carousel img.carouselBtn.carousel-left {
      left: 30px; }
  .grey .carousel ul {
    margin-left: 0;
    margin-right: 0;
    transition: all 1s; }
    .grey .carousel ul.widgetMode li {
      opacity: 0.5;
      float: left; }
      .grey .carousel ul.widgetMode li.selected, .grey .carousel ul.widgetMode li:hover {
        opacity: 1;
        border: 3px solid #3ba8c5; }
        .grey .carousel ul.widgetMode li.selected h4, .grey .carousel ul.widgetMode li:hover h4 {
          background-color: #3ba8c5;
          color: #ffffff; }
  .grey .carousel .carousel-cell {
    cursor: pointer;
    width: 210px;
    height: 192px;
    box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
    border-radius: 20px;
    background-color: #ffffff;
    padding: 0px;
    display: inline-block;
    margin-right: 20px;
    float: left; }
    .grey .carousel .carousel-cell.opacity_0 {
      opacity: 0;
      margin-right: 60px;
      transition: opacity 2s; }
    .grey .carousel .carousel-cell:hover {
      border: 3px solid #3ba8c5; }
      .grey .carousel .carousel-cell:hover h4 {
        background-color: #3ba8c5;
        color: #ffffff; }
    .grey .carousel .carousel-cell h4 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      padding: 22px 0 18px;
      border-bottom: 4px solid #3ba8c5;
      margin: 0;
      border-radius: 15px 15px 0 0; }
    .grey .carousel .carousel-cell .img-wrapper {
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      width: 210px; }

.grey .settingFormWrapper .codeWrapper {
  margin-top: 69px; }
  .grey .settingFormWrapper .codeWrapper p {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    text-align: left; }
  .grey .settingFormWrapper .codeWrapper textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    padding: 13px;
    margin-bottom: 10px; }
  .grey .settingFormWrapper .codeWrapper .formBtns {
    padding: 0;
    margin: 0;
    border: none; }
    .grey .settingFormWrapper .codeWrapper .formBtns Button.btn_clr {
      margin-left: 0;
      margin-right: 10px;
      width: auto;
      padding: 9px 30px; }
      .grey .settingFormWrapper .codeWrapper .formBtns Button.btn_clr p {
        text-align: center;
        color: #3ba8c5; }
  .grey .settingFormWrapper .codeWrapper .advancedSection {
    margin-top: -48px; }
    .grey .settingFormWrapper .codeWrapper .advancedSection .advancedOption p {
      color: #3ba8c5;
      font-family: "Segoe UI Bold";
      font-size: 14px;
      font-weight: 700; }
      .grey .settingFormWrapper .codeWrapper .advancedSection .advancedOption p:first-child {
        width: 27px;
        height: 27px;
        border-radius: 50%;
        border: 1px solid #5f7c8c;
        background-color: #5f7c8c;
        background-image: url("assets/images/expand.png");
        background-repeat: no-repeat;
        background-position: center;
        padding: 0;
        float: left;
        margin-right: 9px; }
      .grey .settingFormWrapper .codeWrapper .advancedSection .advancedOption p:last-child {
        margin-top: 20px; }
    .grey .settingFormWrapper .codeWrapper .advancedSection .advancedOption.collapsed p:first-child {
      border: 1px solid #dedede;
      background-color: #ffffff;
      background-image: url("assets/images/plus_icons/plus_grey.png");
      background-size: 11px; }
    .grey .settingFormWrapper .codeWrapper .advancedSection .advancedOption.dropdown-toggle::after {
      display: none; }

.grey .mailer .modal-dialog textarea {
  border-radius: 5px;
  border: 1px solid #dedede;
  padding: 13px;
  width: 367px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px; }

.grey .mailer .modal-dialog label {
  text-align: left;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.grey .mailer .modal-dialog .form-control {
  width: 256px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.grey .toolBarWrapper .toolTipDesignWrapper {
  border-radius: 10px;
  background-color: #f6f6f6;
  padding: 20px 20px 4px; }

.grey .toolBarWrapper .toolBarMenuWrapper {
  height: 53px;
  margin-bottom: 36px;
  position: relative; }
  .grey .toolBarWrapper .toolBarMenuWrapper .toolBarBoxWrapper {
    position: absolute;
    height: 13px;
    width: 100%;
    top: -14px;
    left: 0;
    background-color: #fff;
    z-index: 7; }
    .grey .toolBarWrapper .toolBarMenuWrapper .toolBarBoxWrapper.bottom {
      top: auto;
      bottom: -13px; }
  .grey .toolBarWrapper .toolBarMenuWrapper .toolBarBox {
    position: absolute;
    border: 1px solid #dedede;
    height: 54px;
    width: 98%;
    border-right: 0;
    top: -1px;
    z-index: 5;
    left: 0;
    pointer-events: none; }
  .grey .toolBarWrapper .toolBarMenuWrapper .menuList {
    height: 54px;
    background-color: #fff;
    cursor: pointer; }
    .grey .toolBarWrapper .toolBarMenuWrapper .menuList.selected {
      background-color: #3ba8c5; }
      .grey .toolBarWrapper .toolBarMenuWrapper .menuList.selected p {
        color: #fff; }
      .grey .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line1 {
        background-color: #fff;
        border-color: #3ba8c5; }
      .grey .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line2 {
        border-color: #3ba8c5; }
        .grey .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line2.mask {
          opacity: 1;
          background-color: #fff; }
    .grey .toolBarWrapper .toolBarMenuWrapper .menuList p {
      width: 100%;
      float: left;
      color: #293037;
      font-family: "Segoe UI Semi Bold";
      font-size: 13px;
      font-weight: 700; }
    .grey .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper {
      position: absolute;
      right: 21px; }
      .grey .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line1 {
        width: 35px;
        height: 20px;
        border-bottom: 1px solid #dedede;
        -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
        position: absolute; }
        .grey .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line1.selectedNext {
          background-color: #3ba8c5;
          border-color: #fff; }
      .grey .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2 {
        width: 33px;
        height: 20px;
        border-bottom: 1px solid #dedede;
        -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
        position: absolute;
        top: 17px;
        left: -16px; }
        .grey .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.mask {
          opacity: 0;
          left: -1px;
          width: 37px;
          top: 29px;
          background-color: #fff;
          border: 0; }
          .grey .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.mask.selectedNext {
            opacity: 1;
            background-color: #3ba8c5; }
        .grey .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.selectedNext {
          border-color: #fff; }

.grey .toolBarWrapper .form-label.chatWindow {
  padding-top: 3px; }

.grey .toolBarWrapper .chat_windowWrapper .btn-group-toggle {
  display: inline-block; }
  .grey .toolBarWrapper .chat_windowWrapper .btn-group-toggle .custom_radionBtn {
    font-size: 14px;
    font-family: 'Segoe UI Semi Bold'; }
  .grey .toolBarWrapper .chat_windowWrapper .btn-group-toggle > label {
    margin-bottom: 17px;
    text-align: left; }
    .grey .toolBarWrapper .chat_windowWrapper .btn-group-toggle > label:last-child {
      margin-bottom: 14px; }

.grey .toolBarWrapper .settingFormWrapper .customSize .form-label {
  font-size: 13px; }

.grey .toolBarWrapper .settingFormWrapper .customSize .form-control {
  width: 62px;
  height: 29px;
  border-radius: 3px;
  float: left;
  margin-right: 12px;
  font-size: 13px; }

.grey .toolBarWrapper .settingFormWrapper .customSize span {
  float: left;
  margin-top: 6px;
  font-size: 13px; }

.grey .toolBarWrapper .toolBarPreviewWrapper {
  height: 302px;
  width: 418px;
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("assets/images/toolBarPreviewBg.png");
  position: relative; }
  .grey .toolBarWrapper .toolBarPreviewWrapper .positionIcons {
    position: absolute;
    border: 1px solid #dedede;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    cursor: pointer; }
    .grey .toolBarWrapper .toolBarPreviewWrapper .positionIcons.selected {
      background-color: #3ba8c5; }
    .grey .toolBarWrapper .toolBarPreviewWrapper .positionIcons.left {
      left: -11px;
      top: 44%;
      padding-right: 2px;
      padding-top: 1px; }
    .grey .toolBarWrapper .toolBarPreviewWrapper .positionIcons.right {
      right: -11px;
      top: 44%;
      padding-left: 2px;
      padding-top: 1px; }
    .grey .toolBarWrapper .toolBarPreviewWrapper .positionIcons.center {
      bottom: -30px;
      left: 50%;
      padding-top: 2px;
      padding-left: 1px; }
    .grey .toolBarWrapper .toolBarPreviewWrapper .positionIcons.bottomLeft {
      bottom: -28px;
      left: -11px;
      padding-left: 4px; }
    .grey .toolBarWrapper .toolBarPreviewWrapper .positionIcons.bottomRight {
      bottom: -28px;
      right: -11px;
      padding-right: 4px; }
  .grey .toolBarWrapper .toolBarPreviewWrapper .toolBarWrapper {
    width: 366px;
    height: 250px;
    position: absolute;
    top: 25px;
    left: 25px; }
  .grey .toolBarWrapper .toolBarPreviewWrapper .ulWrapper {
    position: absolute;
    max-width: 366px;
    max-height: 250px;
    overflow: auto; }
    .grey .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.bar_vertical .btn_wrapper {
      height: 54px; }
      .grey .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.bar_vertical .btn_wrapper .btn_icon.HelpOnClick-WithText {
        margin-left: auto;
        margin-right: auto; }
    .grey .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.transform {
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
  .grey .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns {
    list-style: none;
    height: 41px;
    padding: 0;
    margin: 0;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
    .grey .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper {
      border-right: 1px solid;
      border-left: 1px solid;
      height: 100%;
      float: left;
      min-width: 30px;
      min-height: 30px;
      margin-bottom: 0;
      display: table; }
      .grey .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper:first-child {
        border-left: 0; }
      .grey .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper:last-child {
        border-right: 0; }
      .grey .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div, .grey .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a {
        display: table-cell;
        vertical-align: middle; }
        .grey .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div div.preview_row, .grey .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a div.preview_row {
          display: table-row;
          height: 100%;
          width: 100%; }
        .grey .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div div.preview_clmn, .grey .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a div.preview_clmn {
          display: table-cell;
          vertical-align: middle; }
        .grey .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div > div, .grey .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div {
          display: inline-table;
          vertical-align: middle;
          height: 100%; }
      .grey .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_icon {
        margin-top: -1px;
        margin-left: 14px;
        margin-right: 14px; }
        .grey .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_icon.HelpOnClick-WithText {
          margin-left: 12px;
          margin-right: 0; }
      .grey .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_caption {
        color: #fff;
        display: table-cell;
        font-weight: 700;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 8px;
        padding-right: 15px;
        vertical-align: middle; }

.grey .toolBarWrapper .Tooltip__Base-sc-19cbsjq-0 {
  width: 200px !important; }

.grey .toolBarWrapper .Tooltip__Left-sc-19cbsjq-3 {
  right: 20px; }

.grey .toolBarWrapper .customDrpDwn.customDrpDwn-small .css-10nd86i {
  width: 141px;
  float: left; }

.grey .toolBarWrapper .customDrpDwn.customDrpDwn-small .customSize .form-control {
  width: 51px;
  height: 37px;
  margin-right: 9px; }

.grey .toolBarWrapper .customDrpDwn.customDrpDwn-small .customSize span {
  color: #7d7d7d;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }

.grey .toolBarWrapper .buttonSettingsWrapper {
  box-shadow: none;
  border-radius: 0;
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content; }
  .grey .toolBarWrapper .buttonSettingsWrapper .buttonsSection {
    margin-left: 7%;
    border-radius: 10px;
    background-color: #f6f6f6;
    padding: 26px 20px 15px; }
    .grey .toolBarWrapper .buttonSettingsWrapper .buttonsSection .editorWrapper .editorToolBar .editorLink {
      display: none; }
  .grey .toolBarWrapper .buttonSettingsWrapper .customDrpDwn .css-15k3avv {
    z-index: 10; }
  .grey .toolBarWrapper .buttonSettingsWrapper textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    background-color: #ffffff;
    padding: 15px;
    color: #000000;
    font-family: 'Segoe UI Semi Bold';
    font-size: 14px;
    font-weight: 400; }

.grey .toolBarWrapper .forIcon .css-10nd86i {
  float: left; }

.grey .toolBarWrapper .forIcon .formBtns {
  padding: 0;
  border-top: none;
  margin: 0;
  float: left; }
  .grey .toolBarWrapper .forIcon .formBtns Button {
    margin-left: 6px;
    padding-left: 9px;
    padding-right: 9px; }

.grey .toolBarWrapper .invitationColorPicker > div {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }
  .grey .toolBarWrapper .invitationColorPicker > div > div.colorSelector {
    width: 256px; }
  .grey .toolBarWrapper .invitationColorPicker > div > div:nth-child(2) {
    width: 256px;
    position: absolute !important;
    z-index: 2;
    left: 15px; }
    .grey .toolBarWrapper .invitationColorPicker > div > div:nth-child(2) .flexbox-fix {
      opacity: 0; }

.grey .toolBarWrapper .previewMargin.customSize {
  width: 187px;
  margin-top: 36px;
  margin: 36px auto 0; }

.grey .HelpOnClick-FontIconsLibrary {
  max-height: 70vh; }

.grey .toolbarInvitationPreview {
  height: 150px;
  width: 320px;
  border: 1px solid #035d85;
  border-radius: 5px;
  background-color: #eeeeee;
  margin: 56px auto; }
  .grey .toolbarInvitationPreview .invitationMessageWrapper {
    height: 60px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 5px;
    margin-top: 23px;
    font-size: 16px; }
  .grey .toolbarInvitationPreview button.close {
    margin-right: 3px; }
  .grey .toolbarInvitationPreview button.btn_chat {
    font-size: 14px;
    width: auto;
    display: block;
    padding: 11px 15px;
    border: 1px solid #7e7c7c;
    border-radius: 5px;
    cursor: pointer;
    float: none;
    margin: 0 auto; }

.grey .imageLibrary img {
  padding: 4px;
  cursor: pointer; }
  .grey .imageLibrary img.selected {
    background-color: #e6eef9; }

.deepSkyBlue .widgetWrapper h4.widgetName {
  color: #3ba8c5;
  margin-right: 22px; }

.deepSkyBlue .widgetWrapper .exit_btn {
  position: absolute;
  right: 37px;
  color: #3ba8c5;
  font-family: "Segoe UI";
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer; }
  .deepSkyBlue .widgetWrapper .exit_btn img {
    margin-right: 8px;
    margin-top: -3px; }

.deepSkyBlue .widgetSelectorIndicator {
  margin: 30px auto 0; }
  .deepSkyBlue .widgetSelectorIndicator p {
    color: #a1a4a8;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    margin-top: 25px; }

.deepSkyBlue .innercontent_wrapper .directLinkWrapper {
  margin-top: 56px; }
  .deepSkyBlue .innercontent_wrapper .directLinkWrapper h4 {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    margin-left: 18px;
    margin-bottom: 34px;
    position: relative; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper h4 span {
      border: 1px solid #3ba8c5;
      background-color: #ffffff;
      border-radius: 50%;
      position: absolute;
      margin-left: 9px;
      width: 24px;
      height: 24px;
      display: inline-block; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper h4 span img {
        position: absolute;
        top: 5px;
        left: 10px; }
  .deepSkyBlue .innercontent_wrapper .directLinkWrapper .with_btn input {
    float: left; }
  .deepSkyBlue .innercontent_wrapper .directLinkWrapper .with_btn .uploadBtn button {
    background-color: #3ba8c5; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .with_btn .uploadBtn button p {
      color: #fff; }
  .deepSkyBlue .innercontent_wrapper .directLinkWrapper .invitationCustomImg {
    margin-bottom: 0.5rem; }
  .deepSkyBlue .innercontent_wrapper .directLinkWrapper .btn_wrapper {
    margin-bottom: 15px; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .btn_wrapper .uploadBtn.iconUpload {
      margin-left: 0;
      margin-right: 5px; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .btn_wrapper .uploadBtn.iconUpload button.uploadIcon input {
        width: 60px; }
  .deepSkyBlue .innercontent_wrapper .directLinkWrapper .box {
    padding: 30px; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:first-child {
      width: 131px;
      margin-right: 10px; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:nth-child(2) {
      width: 117px; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .box .customDrpDwn.customOption .row > div:nth-child(2) .css-10nd86i {
        width: 117px; }
  .deepSkyBlue .innercontent_wrapper .directLinkWrapper .formBtns Button.btn_save {
    width: auto; }
  .deepSkyBlue .innercontent_wrapper .directLinkWrapper .customOption .row > div:nth-child(2) > div:nth-child(2) {
    position: absolute !important;
    z-index: 2;
    top: 44px;
    left: 157px; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .customOption .row > div:nth-child(2) > div:nth-child(2) .flexbox-fix {
      display: none !important; }
  .deepSkyBlue .innercontent_wrapper .directLinkWrapper .colorSelector .css-1wy0on6 {
    background-color: #fff; }
  .deepSkyBlue .innercontent_wrapper .directLinkWrapper .colorSelector input {
    border: none; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .colorSelector input:focus {
      border: none; }
  .deepSkyBlue .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper {
    position: relative; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .directLinkpreviewWrapper p {
      width: 178px;
      float: left;
      padding-top: 30%;
      font-size: 22px;
      text-align: right;
      text-decoration: underline; }
  .deepSkyBlue .innercontent_wrapper .directLinkWrapper .directLinkpreview {
    background-repeat: no-repeat;
    width: 165px;
    height: 114px;
    float: right;
    margin-top: 100px;
    margin-right: 50px;
    margin-left: 24px;
    background-size: cover !important; }
  .deepSkyBlue .innercontent_wrapper .directLinkWrapper .theme {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-top: 7px; }
  .deepSkyBlue .innercontent_wrapper .directLinkWrapper .preview {
    width: 330px;
    height: 101px;
    padding: 12px 0 0 0;
    margin-top: 40px;
    margin-bottom: 47px;
    background-repeat: no-repeat; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .preview .widget-preview {
      width: 166px;
      height: 76px; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .preview .widget-preview div {
        font-family: Arial,Helvetica,sans-serif; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-title {
        padding: 11px 0 0 18px;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        text-align: left; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-text {
        width: 146px;
        overflow: hidden;
        white-space: nowrap;
        padding: 1px 0 0;
        text-align: center;
        color: #A8A8A8;
        font-size: 12px;
        margin-left: 10px; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .preview .widget-preview .widget-preview-copy {
        padding: 8px 13px 0;
        text-align: right;
        font-size: 10px;
        color: #fff;
        opacity: .5;
        -moz-opacity: .5;
        -khtml-opacity: .5;
        filter: alpha(opacity=50); }
  .deepSkyBlue .innercontent_wrapper .directLinkWrapper .widgetPosition {
    width: 330px; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .widgetPosition p {
      width: 236px;
      height: 30px;
      border-radius: 5px;
      border: 1px solid #dedede;
      background-color: #ffffff;
      color: #000000;
      font-family: Segoe UI;
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 37px;
      margin-left: auto;
      margin-right: auto; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .widgetPosition p span {
        width: 50%;
        display: inline-block;
        padding: 6px 0;
        cursor: pointer; }
        .deepSkyBlue .innercontent_wrapper .directLinkWrapper .widgetPosition p span:first-child {
          border-radius: 5px 0 0 5px; }
        .deepSkyBlue .innercontent_wrapper .directLinkWrapper .widgetPosition p span:last-child {
          border-radius: 0 5px 5px 0; }
        .deepSkyBlue .innercontent_wrapper .directLinkWrapper .widgetPosition p span.selected {
          color: #ffffff;
          background-color: #3ba8c5; }
  .deepSkyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper {
    width: 90%;
    margin: 0 auto; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp {
      width: 34px;
      height: 34px;
      border: 1px solid #dedede;
      border-radius: 50%;
      float: left;
      padding: 3px;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp div {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        margin: 0; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:hover, .deepSkyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp.selected {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        transition: -webkit-transform 100ms ease 0s;
        transition: transform 100ms ease 0s;
        transition: transform 100ms ease 0s, -webkit-transform 100ms ease 0s;
        padding: 2px; }
        .deepSkyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:hover div, .deepSkyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp.selected div {
          -webkit-transform: scale(0.9);
                  transform: scale(0.9); }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(1):hover {
        border: 2px solid #0475A8; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(2):hover {
        border: 2px solid #5F8706; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(3):hover {
        border: 2px solid #D6680B; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(4):hover {
        border: 2px solid #B0097D; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(5):hover {
        border: 2px solid #9D0A09; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(6):hover {
        border: 2px solid #424E54; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(7):hover {
        border: 2px solid #037FAF; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(8):hover {
        border: 2px solid #608706; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(9):hover {
        border: 2px solid #D26009; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(10):hover {
        border: 2px solid #AF097D; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(11):hover {
        border: 2px solid #950909; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(12):hover {
        border: 2px solid #3E484E; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(13):hover {
        border: 2px solid #0476A8; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(14):hover {
        border: 2px solid #98B45D; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(15):hover {
        border: 2px solid #ADADAD; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .themeSelectCompWrapper .themeSelectComp:nth-child(16):hover {
        border: 2px solid #98A7AD; }
  .deepSkyBlue .innercontent_wrapper .directLinkWrapper .circle-picker {
    padding: 0px 9px; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span > div > span {
      padding: 4px;
      position: relative;
      border: 1px solid #dedede;
      border-radius: 50%;
      display: inline-block;
      width: 34px;
      height: 34px; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(1) > div:hover span {
      border: 2px solid #0475A8; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(1) > div > span > div {
      box-shadow: #0475A8 0px 0px 0px 17px inset !important; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(2) > div:hover span {
      border: 2px solid #5F8706; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(3) > div:hover span {
      border: 2px solid #D6680B; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(4) > div:hover span {
      border: 2px solid #B0097D; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(5) > div:hover span {
      border: 2px solid #9D0A09; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(6) > div:hover span {
      border: 2px solid #424E54; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(7) > div:hover span {
      border: 2px solid #037FAF; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(8) > div:hover span {
      border: 2px solid #587D06; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(9) > div:hover span {
      border: 2px solid #D26009; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(10) > div:hover span {
      border: 2px solid #AF087C; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(11) > div:hover span {
      border: 2px solid #950909; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(12) > div:hover span {
      border: 2px solid #3E484E; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(13) > div:hover span {
      border: 2px solid #0476A8; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(14) > div:hover span {
      border: 2px solid #98B45D; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(15) > div:hover span {
      border: 2px solid #ADADAD; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .circle-picker > span:nth-child(16) > div:hover span {
      border: 2px solid #98A7AD; }
  .deepSkyBlue .innercontent_wrapper .directLinkWrapper .iconWrapper {
    height: 390px;
    position: relative; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn {
      position: absolute;
      width: 26px;
      height: 27px;
      border: 1px solid #dedede;
      border-radius: 50%;
      top: -27px;
      right: 6px;
      cursor: pointer; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn img {
        margin-top: -2px;
        margin-left: 1px; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .scroll_btn.bottomScroll {
        top: auto;
        bottom: -27px; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow {
      margin-bottom: 13px;
      margin-right: 30px;
      border: 1px solid #dedede;
      border-radius: 10px;
      cursor: pointer; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div {
        width: 151px;
        height: 87px;
        background-color: #ffffff;
        margin-right: 12px;
        padding: 16px;
        border-radius: 10px; }
        .deepSkyBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div.no-image {
          opacity: 0; }
        .deepSkyBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow > div:last-child {
          margin-right: 0; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow:last-child {
        margin-bottom: 0; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow.selected {
        background-color: #dedede; }
        .deepSkyBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .iconRow.selected > div {
          background-color: #dedede; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .rcs-custom-scroll .rcs-inner-handle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #3ba8c5;
      background-color: #3ba8c5;
      margin-left: -8px; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .iconWrapper .rcs-custom-scroll .rcs-custom-scrollbar {
      opacity: 1;
      right: 13px;
      border-left: 2px solid #dedede;
      height: 590px; }
  .deepSkyBlue .innercontent_wrapper .directLinkWrapper .uploadBtn {
    margin-top: 13px; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .uploadBtn.iconUpload {
      margin-top: 5px;
      margin-left: 6px;
      float: left; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper .uploadBtn button {
      width: 218px;
      height: 29px;
      border-radius: 3px;
      border: 1px solid #3ba8c5;
      background-color: #ffffff;
      position: relative;
      padding: 0; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .uploadBtn button.uploadIcon {
        width: auto;
        float: left;
        padding: 0 5px; }
        .deepSkyBlue .innercontent_wrapper .directLinkWrapper .uploadBtn button.uploadIcon input {
          width: auto; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .uploadBtn button p {
        margin: 0;
        color: #3ba8c5;
        font-family: "Segoe UI";
        font-size: 13px;
        font-weight: 700;
        line-height: 30.4px;
        text-transform: uppercase; }
        .deepSkyBlue .innercontent_wrapper .directLinkWrapper .uploadBtn button p img {
          margin-right: 4px;
          margin-top: -4px; }
      .deepSkyBlue .innercontent_wrapper .directLinkWrapper .uploadBtn button input {
        position: absolute;
        top: 0;
        left: 0;
        width: 218px;
        height: 29px;
        opacity: 0;
        cursor: pointer; }
  .deepSkyBlue .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span {
    margin-right: 15px;
    color: #3ba8c5;
    text-decoration: underline;
    cursor: pointer; }
    .deepSkyBlue .innercontent_wrapper .directLinkWrapper.widgetIconWrapper .theme span.selected {
      text-decoration: none;
      color: #000000; }

.deepSkyBlue .carousel {
  padding: 12px 0px; }
  .deepSkyBlue .carousel img.carouselBtn {
    position: absolute;
    cursor: pointer;
    top: 90px; }
    .deepSkyBlue .carousel img.carouselBtn.carousel-left {
      left: 30px; }
  .deepSkyBlue .carousel ul {
    margin-left: 0;
    margin-right: 0;
    transition: all 1s; }
    .deepSkyBlue .carousel ul.widgetMode li {
      opacity: 0.5;
      float: left; }
      .deepSkyBlue .carousel ul.widgetMode li.selected, .deepSkyBlue .carousel ul.widgetMode li:hover {
        opacity: 1;
        border: 3px solid #3ba8c5; }
        .deepSkyBlue .carousel ul.widgetMode li.selected h4, .deepSkyBlue .carousel ul.widgetMode li:hover h4 {
          background-color: #3ba8c5;
          color: #ffffff; }
  .deepSkyBlue .carousel .carousel-cell {
    cursor: pointer;
    width: 210px;
    height: 192px;
    box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
    border-radius: 20px;
    background-color: #ffffff;
    padding: 0px;
    display: inline-block;
    margin-right: 20px;
    float: left; }
    .deepSkyBlue .carousel .carousel-cell.opacity_0 {
      opacity: 0;
      margin-right: 60px;
      transition: opacity 2s; }
    .deepSkyBlue .carousel .carousel-cell:hover {
      border: 3px solid #3ba8c5; }
      .deepSkyBlue .carousel .carousel-cell:hover h4 {
        background-color: #3ba8c5;
        color: #ffffff; }
    .deepSkyBlue .carousel .carousel-cell h4 {
      color: #000000;
      font-family: "Helvetica Neue Cyr Roman";
      font-size: 22px;
      font-weight: 400;
      padding: 22px 0 18px;
      border-bottom: 4px solid #3ba8c5;
      margin: 0;
      border-radius: 15px 15px 0 0; }
    .deepSkyBlue .carousel .carousel-cell .img-wrapper {
      height: 120px;
      display: table-cell;
      vertical-align: middle;
      width: 210px; }

.deepSkyBlue .settingFormWrapper .codeWrapper {
  margin-top: 69px; }
  .deepSkyBlue .settingFormWrapper .codeWrapper p {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400;
    text-align: left; }
  .deepSkyBlue .settingFormWrapper .codeWrapper textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    padding: 13px;
    margin-bottom: 10px; }
  .deepSkyBlue .settingFormWrapper .codeWrapper .formBtns {
    padding: 0;
    margin: 0;
    border: none; }
    .deepSkyBlue .settingFormWrapper .codeWrapper .formBtns Button.btn_clr {
      margin-left: 0;
      margin-right: 10px;
      width: auto;
      padding: 9px 30px; }
      .deepSkyBlue .settingFormWrapper .codeWrapper .formBtns Button.btn_clr p {
        text-align: center;
        color: #3ba8c5; }
  .deepSkyBlue .settingFormWrapper .codeWrapper .advancedSection {
    margin-top: -48px; }
    .deepSkyBlue .settingFormWrapper .codeWrapper .advancedSection .advancedOption p {
      color: #3ba8c5;
      font-family: "Segoe UI Bold";
      font-size: 14px;
      font-weight: 700; }
      .deepSkyBlue .settingFormWrapper .codeWrapper .advancedSection .advancedOption p:first-child {
        width: 27px;
        height: 27px;
        border-radius: 50%;
        border: 1px solid #64a9e0;
        background-color: #64a9e0;
        background-image: url("assets/images/expand.png");
        background-repeat: no-repeat;
        background-position: center;
        padding: 0;
        float: left;
        margin-right: 9px; }
      .deepSkyBlue .settingFormWrapper .codeWrapper .advancedSection .advancedOption p:last-child {
        margin-top: 20px; }
    .deepSkyBlue .settingFormWrapper .codeWrapper .advancedSection .advancedOption.collapsed p:first-child {
      border: 1px solid #dedede;
      background-color: #ffffff;
      background-image: url("assets/images/plus_icons/plus_skyblue.png");
      background-size: 11px; }
    .deepSkyBlue .settingFormWrapper .codeWrapper .advancedSection .advancedOption.dropdown-toggle::after {
      display: none; }

.deepSkyBlue .mailer .modal-dialog textarea {
  border-radius: 5px;
  border: 1px solid #dedede;
  padding: 13px;
  width: 367px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px; }

.deepSkyBlue .mailer .modal-dialog label {
  text-align: left;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.deepSkyBlue .mailer .modal-dialog .form-control {
  width: 256px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.deepSkyBlue .toolBarWrapper .toolTipDesignWrapper {
  border-radius: 10px;
  background-color: #f6f6f6;
  padding: 20px 20px 4px; }

.deepSkyBlue .toolBarWrapper .toolBarMenuWrapper {
  height: 53px;
  margin-bottom: 36px;
  position: relative; }
  .deepSkyBlue .toolBarWrapper .toolBarMenuWrapper .toolBarBoxWrapper {
    position: absolute;
    height: 13px;
    width: 100%;
    top: -14px;
    left: 0;
    background-color: #fff;
    z-index: 7; }
    .deepSkyBlue .toolBarWrapper .toolBarMenuWrapper .toolBarBoxWrapper.bottom {
      top: auto;
      bottom: -13px; }
  .deepSkyBlue .toolBarWrapper .toolBarMenuWrapper .toolBarBox {
    position: absolute;
    border: 1px solid #dedede;
    height: 54px;
    width: 98%;
    border-right: 0;
    top: -1px;
    z-index: 5;
    left: 0;
    pointer-events: none; }
  .deepSkyBlue .toolBarWrapper .toolBarMenuWrapper .menuList {
    height: 54px;
    background-color: #fff;
    cursor: pointer; }
    .deepSkyBlue .toolBarWrapper .toolBarMenuWrapper .menuList.selected {
      background-color: #3ba8c5; }
      .deepSkyBlue .toolBarWrapper .toolBarMenuWrapper .menuList.selected p {
        color: #fff; }
      .deepSkyBlue .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line1 {
        background-color: #fff;
        border-color: #3ba8c5; }
      .deepSkyBlue .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line2 {
        border-color: #3ba8c5; }
        .deepSkyBlue .toolBarWrapper .toolBarMenuWrapper .menuList.selected .lineWrapper .line2.mask {
          opacity: 1;
          background-color: #fff; }
    .deepSkyBlue .toolBarWrapper .toolBarMenuWrapper .menuList p {
      width: 100%;
      float: left;
      color: #293037;
      font-family: "Segoe UI Semi Bold";
      font-size: 13px;
      font-weight: 700; }
    .deepSkyBlue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper {
      position: absolute;
      right: 21px; }
      .deepSkyBlue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line1 {
        width: 35px;
        height: 20px;
        border-bottom: 1px solid #dedede;
        -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
        position: absolute; }
        .deepSkyBlue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line1.selectedNext {
          background-color: #3ba8c5;
          border-color: #fff; }
      .deepSkyBlue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2 {
        width: 33px;
        height: 20px;
        border-bottom: 1px solid #dedede;
        -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
        position: absolute;
        top: 17px;
        left: -16px; }
        .deepSkyBlue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.mask {
          opacity: 0;
          left: -1px;
          width: 37px;
          top: 29px;
          background-color: #fff;
          border: 0; }
          .deepSkyBlue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.mask.selectedNext {
            opacity: 1;
            background-color: #3ba8c5; }
        .deepSkyBlue .toolBarWrapper .toolBarMenuWrapper .menuList .lineWrapper .line2.selectedNext {
          border-color: #fff; }

.deepSkyBlue .toolBarWrapper .form-label.chatWindow {
  padding-top: 3px; }

.deepSkyBlue .toolBarWrapper .chat_windowWrapper .btn-group-toggle {
  display: inline-block; }
  .deepSkyBlue .toolBarWrapper .chat_windowWrapper .btn-group-toggle .custom_radionBtn {
    font-size: 14px;
    font-family: 'Segoe UI Semi Bold'; }
  .deepSkyBlue .toolBarWrapper .chat_windowWrapper .btn-group-toggle > label {
    margin-bottom: 17px;
    text-align: left; }
    .deepSkyBlue .toolBarWrapper .chat_windowWrapper .btn-group-toggle > label:last-child {
      margin-bottom: 14px; }

.deepSkyBlue .toolBarWrapper .settingFormWrapper .customSize .form-label {
  font-size: 13px; }

.deepSkyBlue .toolBarWrapper .settingFormWrapper .customSize .form-control {
  width: 62px;
  height: 29px;
  border-radius: 3px;
  float: left;
  margin-right: 12px;
  font-size: 13px; }

.deepSkyBlue .toolBarWrapper .settingFormWrapper .customSize span {
  float: left;
  margin-top: 6px;
  font-size: 13px; }

.deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper {
  height: 302px;
  width: 418px;
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("assets/images/toolBarPreviewBg.png");
  position: relative; }
  .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .positionIcons {
    position: absolute;
    border: 1px solid #dedede;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    cursor: pointer; }
    .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .positionIcons.selected {
      background-color: #3ba8c5; }
    .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .positionIcons.left {
      left: -11px;
      top: 44%;
      padding-right: 2px;
      padding-top: 1px; }
    .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .positionIcons.right {
      right: -11px;
      top: 44%;
      padding-left: 2px;
      padding-top: 1px; }
    .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .positionIcons.center {
      bottom: -30px;
      left: 50%;
      padding-top: 2px;
      padding-left: 1px; }
    .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .positionIcons.bottomLeft {
      bottom: -28px;
      left: -11px;
      padding-left: 4px; }
    .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .positionIcons.bottomRight {
      bottom: -28px;
      right: -11px;
      padding-right: 4px; }
  .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarWrapper {
    width: 366px;
    height: 250px;
    position: absolute;
    top: 25px;
    left: 25px; }
  .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .ulWrapper {
    position: absolute;
    max-width: 366px;
    max-height: 250px;
    overflow: auto; }
    .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.bar_vertical .btn_wrapper {
      height: 54px; }
      .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.bar_vertical .btn_wrapper .btn_icon.HelpOnClick-WithText {
        margin-left: auto;
        margin-right: auto; }
    .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .ulWrapper.transform {
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%); }
  .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns {
    list-style: none;
    height: 41px;
    padding: 0;
    margin: 0;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
    .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper {
      border-right: 1px solid;
      border-left: 1px solid;
      height: 100%;
      float: left;
      min-width: 30px;
      min-height: 30px;
      margin-bottom: 0;
      display: table; }
      .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper:first-child {
        border-left: 0; }
      .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper:last-child {
        border-right: 0; }
      .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div, .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a {
        display: table-cell;
        vertical-align: middle; }
        .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div div.preview_row, .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a div.preview_row {
          display: table-row;
          height: 100%;
          width: 100%; }
        .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div div.preview_clmn, .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a div.preview_clmn {
          display: table-cell;
          vertical-align: middle; }
        .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div > div, .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper a > div {
          display: inline-table;
          vertical-align: middle;
          height: 100%; }
      .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_icon {
        margin-top: -1px;
        margin-left: 14px;
        margin-right: 14px; }
        .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_icon.HelpOnClick-WithText {
          margin-left: 12px;
          margin-right: 0; }
      .deepSkyBlue .toolBarWrapper .toolBarPreviewWrapper .toolBarBtns .btn_wrapper .btn_caption {
        color: #fff;
        display: table-cell;
        font-weight: 700;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 8px;
        padding-right: 15px;
        vertical-align: middle; }

.deepSkyBlue .toolBarWrapper .Tooltip__Base-sc-19cbsjq-0 {
  width: 200px !important; }

.deepSkyBlue .toolBarWrapper .Tooltip__Left-sc-19cbsjq-3 {
  right: 20px; }

.deepSkyBlue .toolBarWrapper .customDrpDwn.customDrpDwn-small .css-10nd86i {
  width: 141px;
  float: left; }

.deepSkyBlue .toolBarWrapper .customDrpDwn.customDrpDwn-small .customSize .form-control {
  width: 51px;
  height: 37px;
  margin-right: 9px; }

.deepSkyBlue .toolBarWrapper .customDrpDwn.customDrpDwn-small .customSize span {
  color: #7d7d7d;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }

.deepSkyBlue .toolBarWrapper .buttonSettingsWrapper {
  box-shadow: none;
  border-radius: 0;
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content; }
  .deepSkyBlue .toolBarWrapper .buttonSettingsWrapper .buttonsSection {
    margin-left: 7%;
    border-radius: 10px;
    background-color: #f6f6f6;
    padding: 26px 20px 15px; }
    .deepSkyBlue .toolBarWrapper .buttonSettingsWrapper .buttonsSection .editorWrapper .editorToolBar .editorLink {
      display: none; }
  .deepSkyBlue .toolBarWrapper .buttonSettingsWrapper .customDrpDwn .css-15k3avv {
    z-index: 10; }
  .deepSkyBlue .toolBarWrapper .buttonSettingsWrapper textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dedede;
    background-color: #ffffff;
    padding: 15px;
    color: #000000;
    font-family: 'Segoe UI Semi Bold';
    font-size: 14px;
    font-weight: 400; }

.deepSkyBlue .toolBarWrapper .forIcon .css-10nd86i {
  float: left; }

.deepSkyBlue .toolBarWrapper .forIcon .formBtns {
  padding: 0;
  border-top: none;
  margin: 0;
  float: left; }
  .deepSkyBlue .toolBarWrapper .forIcon .formBtns Button {
    margin-left: 6px;
    padding-left: 9px;
    padding-right: 9px; }

.deepSkyBlue .toolBarWrapper .invitationColorPicker > div {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }
  .deepSkyBlue .toolBarWrapper .invitationColorPicker > div > div.colorSelector {
    width: 256px; }
  .deepSkyBlue .toolBarWrapper .invitationColorPicker > div > div:nth-child(2) {
    width: 256px;
    position: absolute !important;
    z-index: 2;
    left: 15px; }
    .deepSkyBlue .toolBarWrapper .invitationColorPicker > div > div:nth-child(2) .flexbox-fix {
      opacity: 0; }

.deepSkyBlue .toolBarWrapper .previewMargin.customSize {
  width: 187px;
  margin-top: 36px;
  margin: 36px auto 0; }

.deepSkyBlue .HelpOnClick-FontIconsLibrary {
  max-height: 70vh; }

.deepSkyBlue .toolbarInvitationPreview {
  height: 150px;
  width: 320px;
  border: 1px solid #035d85;
  border-radius: 5px;
  background-color: #eeeeee;
  margin: 56px auto; }
  .deepSkyBlue .toolbarInvitationPreview .invitationMessageWrapper {
    height: 60px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 5px;
    margin-top: 23px;
    font-size: 16px; }
  .deepSkyBlue .toolbarInvitationPreview button.close {
    margin-right: 3px; }
  .deepSkyBlue .toolbarInvitationPreview button.btn_chat {
    font-size: 14px;
    width: auto;
    display: block;
    padding: 11px 15px;
    border: 1px solid #7e7c7c;
    border-radius: 5px;
    cursor: pointer;
    float: none;
    margin: 0 auto; }

.deepSkyBlue .imageLibrary img {
  padding: 4px;
  cursor: pointer; }
  .deepSkyBlue .imageLibrary img.selected {
    background-color: #e6eef9; }

@media (max-width: 1025px) {
  .optDeptWrapper .operatorSection .operatorsWrapper .box {
    width: 47%;
    margin-bottom: 10px; }
  .optDeptWrapper.section_Dept .panelHeader {
    padding-right: 0; }
    .optDeptWrapper.section_Dept .panelHeader > div {
      padding-left: 0; }
  .optDeptWrapper.section_Dept .panelContent {
    padding-right: 0; }
    .optDeptWrapper.section_Dept .panelContent > div {
      padding-left: 0; }
    .optDeptWrapper.section_Dept .panelContent p {
      font-size: 13px;
      word-break: break-all; }
    .optDeptWrapper.section_Dept .panelContent .editWrapper p {
      font-size: 11px; } }

@media (max-width: 769px) {
  .innerContentWrapper {
    margin-right: 0; }
  .logo_wrapper {
    padding-left: 0; }
  .notification {
    margin: 0 20px; }
  .faq {
    margin-left: 20px; }
  .profileStatus .status p {
    font-size: 13px; }
  .profileStatus .status p.text-active {
    font-size: 12px; }
  .checkout {
    margin-left: 0;
    padding: 22px 18px 22px 18px; }
  #sidebarCollapse {
    display: block; }
  .header_title {
    height: 65px;
    padding-left: 26px; }
  #sidebar > ul.sideMenu {
    margin-left: 15px;
    min-height: 552px; }
  #sidebar {
    position: fixed;
    top: 61px;
    left: -318px;
    height: 100vh;
    min-width: 318px;
    width: 318px;
    z-index: 999;
    transition: all 0.3s;
    overflow-y: auto;
    min-height: 100vh; }
  #sidebar .subMenu {
    min-height: 475px; }
  #sidebar.open, #sidebar.active {
    min-width: 318px;
    max-width: 318px; }
    #sidebar.open.operator_menu, #sidebar.active.operator_menu {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      min-width: 245px;
      max-width: 245px;
      height: -webkit-max-content;
      height: -moz-max-content;
      height: max-content; }
  #sidebar.open {
    left: 0; }
  #sidebar .submenu_wrapper.active {
    margin-left: auto; }
  .settingFormWrapper {
    margin-right: 0; }
  .settingFormWrapper .form-label, .customDrpDwn .css-10nd86i, .image_upload p, .profileImg .img_delete p:last-child {
    font-size: 12px; }
  .profileImg .img_delete p:first-child {
    padding: 0 9px 0 10px; }
  .profileImg .img_delete p:last-child {
    margin-left: -8px; }
  .chatResponseWrapper .response_wrapper label {
    text-align: left; } }

@media (max-width: 767px) {
  .invitationRulesWrapper .editRulesSection .customFormGroup.drpDwnGrp .customDrpDwn:first-child {
    margin-right: 0;
    margin-bottom: 10px; } }

@media (max-width: 576px) {
  .innercontent_wrapper .box {
    padding: 30px 20px; }
  #sidebarCollapse {
    width: 30px;
    height: 30px;
    padding: 1px 3px;
    border: none; }
  .logo_wrapper {
    padding-left: 0;
    margin-left: 10px;
    padding-right: 0; }
  .header {
    margin-bottom: 3px; }
  .innerForm .form-group > div {
    padding-left: 15px; }
  .settings_wrapper .box {
    padding: 20px; }
  .menu-right .row {
    margin-right: 0; }
  .notification {
    margin: 0 10px; }
  .faq {
    margin-left: 10px; }
  .profileStatus {
    margin-right: 5px;
    margin-top: 13px; }
  .profileStatus .avatar {
    margin-right: 0; }
  .formBtns {
    padding-top: 20px;
    margin-top: 20px;
    padding-right: 15px; }
  .formBtns .pullRight {
    float: none; }
  .formBtns Button.btn_clr {
    margin-left: 0; }
  .formBtns Button {
    width: 90px;
    height: 34px; }
  .green_circle, .red_circle {
    width: 35px;
    height: 35px;
    border-width: 1px; }
  .profileStatus .status {
    float: none; }
  .profileStatus .status p {
    font-size: 12px; }
  .cart {
    margin-top: -8px; }
  .profileStatus .status .status-img {
    right: -10px;
    bottom: 7px; }
  .optDeptWrapper .operatorSection .operatorsWrapper .box {
    width: 94%;
    margin-bottom: 10px; }
  .innercontent_wrapper .traffic_wrapper .dropDownWrapper {
    float: left;
    margin-bottom: 15px; }
    .innercontent_wrapper .traffic_wrapper .dropDownWrapper .customDrpDwn > div:nth-child(2) {
      width: 75px; }
    .innercontent_wrapper .traffic_wrapper .dropDownWrapper .customDrpDwn > div:nth-child(3) {
      width: 127px; }
  .traffic_wrapper .formBtns Button.btn_clr {
    width: 79px;
    margin-left: 10px; } }

@media (max-width: 600px) {
  .header_title h4 {
    font-size: 1.1rem; }
  .optDeptWrapper .chatRedirect > div {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; }
    .optDeptWrapper .chatRedirect > div label {
      margin-top: 7px;
      padding-right: 25px; }
  .optDeptWrapper.section_Dept .panelContent .editWrapper p span {
    margin: 0 12px 0 7px; }
  .optDeptWrapper .panelWrapper .panelHeader p {
    font-size: 12px; }
  .textEditorWrapper .sendImgWrappger {
    right: 6px; }
  .innercontent_wrapper .chatWrapper.with-header .box .headerTitle {
    padding: 24px 0 24px 15px; }
  .innercontent_wrapper .chatWrapper.with-header .box .contentWrapper {
    padding: 20px 0px 30px 0px; }
  .innercontent_wrapper .chatWrapper.with-header .box .rcs-custom-scroll .rcs-inner-container > div {
    margin-right: 0 !important; }
  .innercontent_wrapper .chatWrapper.with-header .box .chatSection {
    padding-right: 0; }
  .innercontent_wrapper .chatWrapper.with-header .box .editorWrapper {
    margin-right: 0;
    width: 100%; }
  .innercontent_wrapper .chatWrapper.with-header .box > div:nth-child(2) {
    padding-right: 0; } }

.x-list-selected {
  background-color: #e6eef9; }

.HelpOnClick-RGBblue .HelpOnClick-ToolbarButton.HelpOnClick-WithText .HelpOnClick-Caption {
  display: table-cell;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 19px;
  vertical-align: middle; }

.HelpOnClick-RGBblue .HelpOnClick-ToolbarButton .HelpOnClick-IconContainer {
  display: table-cell;
  vertical-align: middle; }

.HelpOnClick-RGBblue .HelpOnClick-ToolbarButton .HelpOnClick-Icon {
  height: 24px;
  width: 24px;
  display: block;
  margin: auto; }

.HelpOnClick-RGBblue .HelpOnClick-ToolbarButton .HelpOnClick-Icon img {
  height: 24px;
  width: 24px; }

.HelpOnClick-RGBblue .HelpOnClick-ToolbarButton .HelpOnClick-Icon.HelpOnClick-fa {
  width: 24px;
  height: 24px; }

.HelpOnClick-Icon-chat:before {
  content: "\f086";
  margin-top: -2px; }

.HelpOnClick-Icon-email:before {
  content: "\f0e0";
  font-size: 18px;
  margin-top: -1px; }

.HelpOnClick-Icon-call:before {
  content: "\f095"; }

.HelpOnClick-Icon-social-facebook:before {
  content: "\f09a";
  font-size: inherit; }

.HelpOnClick-Icon-social-twitter:before {
  content: "\f099";
  font-size: inherit; }

.HelpOnClick-RGBblue .HelpOnClick-ToolbarButton .HelpOnClick-Icon.HelpOnClick-DefaultIcon.HelpOnClick-Icon-menu:before {
  content: "\f0c9"; }

.HelpOnClick-RGBblue.HelpOnClick-Vertical.HelpOnClick-VerticalTop .dragresize-tm,
.HelpOnClick-RGBblue.HelpOnClick-Vertical.HelpOnClick-VerticalLeft .dragresize-ml {
  display: none !important; }

.HelpOnClick-RGBblue.HelpOnClick-Vertical.HelpOnClick-VerticalTop .dragresize-bm {
  display: block !important;
  cursor: n-resize;
  height: 5px;
  left: 0;
  margin-left: 0;
  position: absolute;
  bottom: -3px;
  width: 100%; }

.HelpOnClick-RGBblue.HelpOnClick-Vertical.HelpOnClick-VerticalLeft .dragresize-mr {
  cursor: w-resize;
  display: block !important;
  left: auto;
  right: -3px;
  margin-top: 0;
  top: 0; }

.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar-wrapper {
  background: #0475a8;
  background: linear-gradient(to right, #058dcb 0%, #047fb7 5%, #046b99 95%, #03648f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#047fb7,endColorstr=#046b99,GradientType=1);
  height: auto;
  border: 1px solid #035d85;
  margin-bottom: auto; }

.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar-wrapper.HelpOnClick-NoGradient {
  background: #0475a8; }

.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar-wrapper li {
  float: none; }

.HelpOnClick-RGBblue.HelpOnClick-Vertical.HelpOnClick-VerticalLeft .HelpOnClick-NoGradient .HelpOnClick-toolbar {
  background: #0475a8; }

.HelpOnClick-RGBblue.HelpOnClick-Vertical.HelpOnClick-VerticalLeft .HelpOnClick-toolbar {
  background: #0475a8;
  background: linear-gradient(to right, #03648f 0%, #046b99 5%, #047fb7 95%, #058dcb 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#046b99,endColorstr=#047fb7,GradientType=1); }

.HelpOnClick-RGBblue.HelpOnClick-Vertical.HelpOnClick-VerticalLeft .HelpOnClick-toolbar.HelpOnClick-NoGradient {
  background: #0475a8; }

.HelpOnClick-RGBblue.HelpOnClick-Vertical.HelpOnClick-VerticalTop .HelpOnClick-toolbar {
  margin-top: 0; }

.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar li {
  width: 100%;
  height: 54px;
  position: relative;
  border-left: none;
  border-right: none;
  border-top: 1px solid #035d85;
  border-bottom: 1px solid #0598da; }

.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar li a {
  padding: 2px 0; }

.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar li.HelpOnClick-Button-First {
  border-top: none; }

.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar li.HelpOnClick-Button-Last {
  border-bottom: none; }

.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-ToolbarButton.HelpOnClick-WithText .HelpOnClick-Caption {
  display: none; }

.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar-menu .HelpOnClick-ToolbarButton.HelpOnClick-WithText .HelpOnClick-Caption {
  display: table-cell; }

.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar .HelpOnClick-ToolbarButton .HelpOnClick-Icon,
.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar .HelpOnClick-ToolbarButton.HelpOnClick-rtl .HelpOnClick-Icon {
  margin-left: auto;
  margin-right: auto; }

.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar .HelpOnClick-ToolbarButton .HelpOnClick-Order-Icon {
  display: inline-table; }

.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar .HelpOnClick-ToolbarButton.HelpOnClick-NoIcon .HelpOnClick-Order-Icon {
  display: none; }

.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar .HelpOnClick-ToolbarButton .HelpOnClick-Order-Caption {
  display: none; }

.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar .HelpOnClick-ToolbarButton.HelpOnClick-WithText .HelpOnClick-Order-Caption {
  display: none; }

.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar .HelpOnClick-toolbar-menu .HelpOnClick-ToolbarButton.HelpOnClick-WithText .HelpOnClick-Order-Caption {
  display: inline-table; }

.HelpOnClick-RGBblue.HelpOnClick-Vertical .HelpOnClick-toolbar .HelpOnClick-Order-Icon {
  width: 100%; }

.HelpOnClick-RGBblue .HelpOnClick-InvitationImage {
  cursor: pointer; }

.HelpOnClick-RGBblue .HelpOnClick-InvitationImage .HelpOnClick-InvitationImage-CloseButton {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuM4zml1AAAADeSURBVDhPrZTBDYMwDAAZgwF45MkIDMEgbMJ4bMASfbi+CCMTkpBHkO6BOV9UqnYQkZQpMyvxch83yqb8lNXNSuDgsnPPvbAdxyHLssh5nl/RFQeXHb3uqAkxNo6jTgYJIdSiMYaDy46PIvAe4mkIRiH6iBns6oU7VcUk2uLo5ON0W2iJgQ9Woy0x8DEjG/WUYvC4cRSjtRi8Bhddg10/ctcvpRhjXnvGrnWaYtdCi6MTkVnp9dObTdw7/DnsPPdijHJaJWbEKK6PQSry4Ctm4ODeMfCCwTvNzXMkrgx/IWsKX5W5c5wAAAAASUVORK5CYII=") !important; }

.HelpOnClick-RGBblue .HelpOnClick-Invitation {
  height: 150px;
  min-width: 365px;
  border: 1px solid #035d85;
  border-radius: 5px;
  background-color: #eeeeee;
  overflow: hidden; }

.HelpOnClick-RGBblue .HelpOnClick-Invitation .HelpOnClick-Invitation-Left {
  width: 150px;
  height: 150px;
  float: left;
  overflow: hidden; }

.HelpOnClick-RGBblue .HelpOnClick-Invitation .HelpOnClick-Invitation-Left img {
  height: 150px;
  width: 150px;
  background-color: #fff; }

.HelpOnClick-RGBblue .HelpOnClick-Invitation .HelpOnClick-Invitation-Right .HelpOnClick-ForBtnMiddle {
  padding: 0 30px 20px 30px;
  text-align: center; }

.HelpOnClick-RGBblue .HelpOnClick-Invitation .HelpOnClick-Invitation-Right .HelpOnClick-CloseButton {
  position: absolute;
  top: 10px;
  right: 10px; }

.HelpOnClick-RGBblue .HelpOnClick-Invitation .HelpOnClick-Invitation-Right .HelpOnClick-CloseButton a {
  display: block;
  width: 16px;
  height: 16px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAMBJREFUeNqkU9ENhCAMBSdxBG8H2AMmO/bgywl0A92Ea72SlFrxcjZpKuG9+igPW0oxT2LgC+/9dEcAzKg2gI0AZYH67pARs1E9wuIR6M8Lw6acc1TIvPkLMOuhAD+QxDYDV6KQE3G+ChgQQYEDIWeFHJsjCKmyiekdbZAIAqRfyGoDivkvH1wMTB3slZFO0+7djmak07SVmTRNqpHQntuNkeTtNEbaocTetIWSWI1kUEFN59zE11pKjH36nD8CDACsIpgfdd3pqwAAAABJRU5ErkJggg==") !important; }

.HelpOnClick-RGBblue .HelpOnClick-Invitation .HelpOnClick-Invitation-Right .HelpOnClick-InvitationMessage-Wrapper {
  height: 95px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 5px; }

.HelpOnClick-RGBblue .HelpOnClick-Invitation .HelpOnClick-Invitation-Right .HelpOnClick-InvitationMessage {
  display: table;
  width: 100%;
  height: 95px;
  font-size: 16px; }

.HelpOnClick-RGBblue .HelpOnClick-Invitation .HelpOnClick-Invitation-Right .HelpOnClick-InvitationMessage > div {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 7px 20px 0 18px;
  line-height: 22px; }

.HelpOnClick-RGBblue .HelpOnClick-Clear {
  clear: both; }

.HelpOnClick-RGBblue .HelpOnClick-NoBorder {
  border: 0 !important; }

.HelpOnClick-RGBblue .HelpOnClick-input,
.HelpOnClick-RGBblue .HelpOnClick-input input,
.HelpOnClick-RGBblue .HelpOnClick-select .HelpOnClick-view,
.HelpOnClick-RGBblue .HelpOnClick-textarea,
.HelpOnClick-RGBblue .HelpOnClick-input select {
  background: #f4f4f4;
  border-radius: 5px; }

.HelpOnClick-RGBblue .HelpOnClick-input input,
.HelpOnClick-RGBblue .HelpOnClick-input select {
  line-height: 20px;
  height: 32px;
  padding: 6px 10px;
  width: 100%;
  box-sizing: border-box; }

.HelpOnClick-RGBblue .HelpOnClick-textarea textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box; }

.HelpOnClick-RGBblue .HelpOnClick-input,
.HelpOnClick-RGBblue .HelpOnClick-textarea {
  border: 1px solid #7e7c7c; }

.HelpOnClick-RGBblue .HelpOnClick-Body {
  -moz-user-select: initial;
  -webkit-user-select: initial;
  -ms-user-select: initial;
      user-select: initial; }

.HelpOnClick-RGBblue .HelpOnClick-BodyRounded {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; }

.HelpOnClick-RGBblue .HelpOnClick-BodyRounded.HelpOnClick-NoHead {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px; }

.HelpOnClick-RGBblue .HelpOnClick-BodyRounded.HelpOnClick-HasFooter .HelpOnClick-BodyBottom {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.HelpOnClick-RGBblue .HelpOnClick-BodyBottom {
  background: #eee;
  padding: 9px;
  border-top: 1px solid #c3c3c3;
  box-sizing: border-box;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px; }

.HelpOnClick-RGBblue .HelpOnClick-Footer {
  background: #eee; }

.HelpOnClick-RGBblue .HelpOnClick-loadingMask,
.HelpOnClick-RGBblue .HelpOnClick-loadingMask div {
  position: absolute;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10; }

.HelpOnClick-RGBblue .HelpOnClick-loadingMask div {
  background: #fff;
  opacity: 0.5;
  filter: alpha(opacity=50); }

.HelpOnClick-RGBblue .HelpOnClick-error {
  color: #ff0000; }

.HelpOnClick-RGBblue .HelpOnClick-Tooltip {
  position: absolute;
  display: none;
  z-index: 14;
  min-width: 100px;
  overflow: hidden;
  border-radius: 0; }

.HelpOnClick-RGBblue .HelpOnClick-Tooltip.HelpOnClick-Tooltip-Rounded {
  border-radius: 5px; }

.HelpOnClick-RGBblue .HelpOnClick-Tooltip .HelpOnClick-Tooltip-Container {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 14px;
  height: 14px;
  text-align: center;
  padding: 13px 15px;
  background: transparent; }

.HelpOnClick-RGBblue .HelpOnClick-Tooltip .HelpOnClick-Tooltip-Container.HelpOnClick-Tooltip-Gradient {
  background: linear-gradient(to bottom, white -200%, transparent); }

.HelpOnClick-RGBblue .HelpOnClick-Tip {
  display: none;
  position: absolute;
  z-index: 14; }

.HelpOnClick-RGBblue .HelpOnClick-Tip,
.HelpOnClick-RGBblue .HelpOnClick-Tip div {
  width: 32px;
  height: 32px;
  overflow: hidden;
  font-size: 0;
  line-height: 0; }

.HelpOnClick-RGBblue .HelpOnClick-Tip div:after {
  content: '';
  position: absolute;
  left: 50%;
  margin-left: -15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: inherit; }

.HelpOnClick-RGBblue .HelpOnClick-Tip .HelpOnClick-horizontal-left-top:after,
.HelpOnClick-RGBblue .HelpOnClick-Tip .HelpOnClick-horizontal-center-top:after,
.HelpOnClick-RGBblue .HelpOnClick-Tip .HelpOnClick-horizontal-right-top:after {
  border-width: 0 15px 17px 15px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent; }

.HelpOnClick-RGBblue .HelpOnClick-Tip .HelpOnClick-horizontal-left-bottom:after,
.HelpOnClick-RGBblue .HelpOnClick-Tip .HelpOnClick-horizontal-center-bottom:after,
.HelpOnClick-RGBblue .HelpOnClick-Tip .HelpOnClick-horizontal-right-bottom:after {
  bottom: 0;
  border-width: 17px 15px 0 15px;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent; }

.HelpOnClick-RGBblue .HelpOnClick-Tip .HelpOnClick-vertical-left-top:after,
.HelpOnClick-RGBblue .HelpOnClick-Tip .HelpOnClick-vertical-left-center:after,
.HelpOnClick-RGBblue .HelpOnClick-Tip .HelpOnClick-vertical-left-bottom:after {
  top: 25%;
  border-width: 8px 16px 8px 0;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-top-color: transparent; }

.HelpOnClick-RGBblue .HelpOnClick-Tip .HelpOnClick-vertical-right-top:after,
.HelpOnClick-RGBblue .HelpOnClick-Tip .HelpOnClick-vertical-right-center:after,
.HelpOnClick-RGBblue .HelpOnClick-Tip .HelpOnClick-vertical-right-bottom:after {
  top: 25%;
  left: 30px;
  border-width: 8px 0 8px 16px;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent; }

.HelpOnClick-RGBblue .HelpOnClick-DialogTip {
  z-index: 11; }

.HelpOnClick-RGBblue .HelpOnClick-DialogTip div {
  position: absolute;
  top: 0;
  left: 0;
  background: none !important;
  width: 0;
  height: 0; }

.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-left-top,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-center-top,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-right-top {
  margin-top: 10px; }

.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-left-top:first-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-center-top:first-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-right-top:first-child {
  top: -2px;
  left: -1px;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  border-bottom: 17px solid #035d85; }

.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-left-top:last-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-center-top:last-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-right-top:last-child {
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #0475a8; }

.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-left-bottom,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-center-bottom,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-right-bottom {
  margin-top: 6px; }

.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-left-bottom:first-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-center-bottom:first-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-right-bottom:first-child {
  left: -1px;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  border-top: 17px solid #035d85; }

.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-left-bottom:last-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-center-bottom:last-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-horizontal-right-bottom:last-child {
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #0475a8; }

.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-left-top,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-left-center,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-left-bottom {
  margin-left: 10px; }

.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-left-top:first-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-left-center:first-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-left-bottom:first-child {
  top: -1px;
  left: -1px;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-right: 17px solid #035d85; }

.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-left-top:last-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-left-center:last-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-left-bottom:last-child {
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-right: 16px solid #0475a8; }

.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-right-top,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-right-center,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-right-bottom {
  margin-left: 6px; }

.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-right-top:first-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-right-center:first-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-right-bottom:first-child {
  top: -1px;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 17px solid #035d85; }

.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-right-top:last-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-right-center:last-child,
.HelpOnClick-RGBblue .HelpOnClick-DialogTip .HelpOnClick-vertical-right-bottom:last-child {
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 16px solid #0475a8; }

.HelpOnClick-fa {
  display: inline-block;
  font: normal normal normal 20px/1 FontAwesome !important;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center; }

.HelpOnClick-fa:before {
  display: inline-block;
  font-size: 20px;
  width: auto;
  margin: 0;
  line-height: 28px; }

.HelpOnClick-fa-lg {
  font-size: 1.3333333333333em;
  line-height: 0.75em;
  vertical-align: -15%; }

.HelpOnClick-fa-2x {
  font-size: 2em; }

.HelpOnClick-fa-3x {
  font-size: 3em; }

.HelpOnClick-fa-4x {
  font-size: 4em; }

.HelpOnClick-fa-5x {
  font-size: 5em; }

.HelpOnClick-fa-fw {
  width: 1.2857142857143em;
  text-align: center; }

.HelpOnClick-fa-ul {
  padding-left: 0;
  margin-left: 2.1428571428571em;
  list-style-type: none; }

.HelpOnClick-fa-ul > li {
  position: relative; }

.HelpOnClick-fa-li {
  position: absolute;
  left: -2.1428571428571em;
  width: 2.1428571428571em;
  top: 0.14285714285714em;
  text-align: center; }

.HelpOnClick-fa-li.HelpOnClick-fa-lg {
  left: -1.8571428571429em; }

.HelpOnClick-fa-border {
  padding: .2em .25em .15em;
  border: solid .08em #eee;
  border-radius: .1em; }

.HelpOnClick-fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear; }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

.HelpOnClick-fa-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.HelpOnClick-fa-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.HelpOnClick-fa-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg); }

.HelpOnClick-fa-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0,mirror=1);
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1); }

.HelpOnClick-fa-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2,mirror=1);
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1); }

:root .HelpOnClick-fa-rotate-90,
:root .HelpOnClick-fa-rotate-180,
:root .HelpOnClick-fa-rotate-270,
:root .HelpOnClick-fa-flip-horizontal,
:root .HelpOnClick-fa-flip-vertical {
  -webkit-filter: none;
          filter: none; }

.HelpOnClick-fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle; }

.HelpOnClick-fa-stack-1x,
.HelpOnClick-fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center; }

.HelpOnClick-fa-stack-1x {
  line-height: inherit; }

.HelpOnClick-fa-stack-2x {
  font-size: 2em; }

.HelpOnClick-fa-inverse {
  color: #fff; }

.HelpOnClick-fa-glass:before {
  content: "\f000"; }

.HelpOnClick-fa-music:before {
  content: "\f001"; }

.HelpOnClick-fa-search:before {
  content: "\f002"; }

.HelpOnClick-fa-envelope-o:before {
  content: "\f003"; }

.HelpOnClick-fa-heart:before {
  content: "\f004"; }

.HelpOnClick-fa-star:before {
  content: "\f005"; }

.HelpOnClick-fa-star-o:before {
  content: "\f006"; }

.HelpOnClick-fa-user:before {
  content: "\f007"; }

.HelpOnClick-fa-film:before {
  content: "\f008"; }

.HelpOnClick-fa-th-large:before {
  content: "\f009"; }

.HelpOnClick-fa-th:before {
  content: "\f00a"; }

.HelpOnClick-fa-th-list:before {
  content: "\f00b"; }

.HelpOnClick-fa-check:before {
  content: "\f00c"; }

.HelpOnClick-fa-remove:before,
.HelpOnClick-fa-close:before,
.HelpOnClick-fa-times:before {
  content: "\f00d"; }

.HelpOnClick-fa-search-plus:before {
  content: "\f00e"; }

.HelpOnClick-fa-search-minus:before {
  content: "\f010"; }

.HelpOnClick-fa-power-off:before {
  content: "\f011"; }

.HelpOnClick-fa-signal:before {
  content: "\f012"; }

.HelpOnClick-fa-gear:before,
.HelpOnClick-fa-cog:before {
  content: "\f013"; }

.HelpOnClick-fa-trash-o:before {
  content: "\f014"; }

.HelpOnClick-fa-home:before {
  content: "\f015"; }

.HelpOnClick-fa-file-o:before {
  content: "\f016"; }

.HelpOnClick-fa-clock-o:before {
  content: "\f017"; }

.HelpOnClick-fa-road:before {
  content: "\f018"; }

.HelpOnClick-fa-download:before {
  content: "\f019"; }

.HelpOnClick-fa-arrow-circle-o-down:before {
  content: "\f01a"; }

.HelpOnClick-fa-arrow-circle-o-up:before {
  content: "\f01b"; }

.HelpOnClick-fa-inbox:before {
  content: "\f01c"; }

.HelpOnClick-fa-play-circle-o:before {
  content: "\f01d"; }

.HelpOnClick-fa-rotate-right:before,
.HelpOnClick-fa-repeat:before {
  content: "\f01e"; }

.HelpOnClick-fa-refresh:before {
  content: "\f021"; }

.HelpOnClick-fa-list-alt:before {
  content: "\f022"; }

.HelpOnClick-fa-lock:before {
  content: "\f023"; }

.HelpOnClick-fa-flag:before {
  content: "\f024"; }

.HelpOnClick-fa-headphones:before {
  content: "\f025"; }

.HelpOnClick-fa-volume-off:before {
  content: "\f026"; }

.HelpOnClick-fa-volume-down:before {
  content: "\f027"; }

.HelpOnClick-fa-volume-up:before {
  content: "\f028"; }

.HelpOnClick-fa-qrcode:before {
  content: "\f029"; }

.HelpOnClick-fa-barcode:before {
  content: "\f02a"; }

.HelpOnClick-fa-tag:before {
  content: "\f02b"; }

.HelpOnClick-fa-tags:before {
  content: "\f02c"; }

.HelpOnClick-fa-book:before {
  content: "\f02d"; }

.HelpOnClick-fa-bookmark:before {
  content: "\f02e"; }

.HelpOnClick-fa-print:before {
  content: "\f02f"; }

.HelpOnClick-fa-camera:before {
  content: "\f030"; }

.HelpOnClick-fa-font:before {
  content: "\f031"; }

.HelpOnClick-fa-bold:before {
  content: "\f032"; }

.HelpOnClick-fa-italic:before {
  content: "\f033"; }

.HelpOnClick-fa-text-height:before {
  content: "\f034"; }

.HelpOnClick-fa-text-width:before {
  content: "\f035"; }

.HelpOnClick-fa-align-left:before {
  content: "\f036"; }

.HelpOnClick-fa-align-center:before {
  content: "\f037"; }

.HelpOnClick-fa-align-right:before {
  content: "\f038"; }

.HelpOnClick-fa-align-justify:before {
  content: "\f039"; }

.HelpOnClick-fa-list:before {
  content: "\f03a"; }

.HelpOnClick-fa-dedent:before,
.HelpOnClick-fa-outdent:before {
  content: "\f03b"; }

.HelpOnClick-fa-indent:before {
  content: "\f03c"; }

.HelpOnClick-fa-video-camera:before {
  content: "\f03d"; }

.HelpOnClick-fa-photo:before,
.HelpOnClick-fa-image:before,
.HelpOnClick-fa-picture-o:before {
  content: "\f03e"; }

.HelpOnClick-fa-pencil:before {
  content: "\f040"; }

.HelpOnClick-fa-map-marker:before {
  content: "\f041"; }

.HelpOnClick-fa-adjust:before {
  content: "\f042"; }

.HelpOnClick-fa-tint:before {
  content: "\f043"; }

.HelpOnClick-fa-edit:before,
.HelpOnClick-fa-pencil-square-o:before {
  content: "\f044"; }

.HelpOnClick-fa-share-square-o:before {
  content: "\f045"; }

.HelpOnClick-fa-check-square-o:before {
  content: "\f046"; }

.HelpOnClick-fa-arrows:before {
  content: "\f047"; }

.HelpOnClick-fa-step-backward:before {
  content: "\f048"; }

.HelpOnClick-fa-fast-backward:before {
  content: "\f049"; }

.HelpOnClick-fa-backward:before {
  content: "\f04a"; }

.HelpOnClick-fa-play:before {
  content: "\f04b"; }

.HelpOnClick-fa-pause:before {
  content: "\f04c"; }

.HelpOnClick-fa-stop:before {
  content: "\f04d"; }

.HelpOnClick-fa-forward:before {
  content: "\f04e"; }

.HelpOnClick-fa-fast-forward:before {
  content: "\f050"; }

.HelpOnClick-fa-step-forward:before {
  content: "\f051"; }

.HelpOnClick-fa-eject:before {
  content: "\f052"; }

.HelpOnClick-fa-chevron-left:before {
  content: "\f053"; }

.HelpOnClick-fa-chevron-right:before {
  content: "\f054"; }

.HelpOnClick-fa-plus-circle:before {
  content: "\f055"; }

.HelpOnClick-fa-minus-circle:before {
  content: "\f056"; }

.HelpOnClick-fa-times-circle:before {
  content: "\f057"; }

.HelpOnClick-fa-check-circle:before {
  content: "\f058"; }

.HelpOnClick-fa-question-circle:before {
  content: "\f059"; }

.HelpOnClick-fa-info-circle:before {
  content: "\f05a"; }

.HelpOnClick-fa-crosshairs:before {
  content: "\f05b"; }

.HelpOnClick-fa-times-circle-o:before {
  content: "\f05c"; }

.HelpOnClick-fa-check-circle-o:before {
  content: "\f05d"; }

.HelpOnClick-fa-ban:before {
  content: "\f05e"; }

.HelpOnClick-fa-arrow-left:before {
  content: "\f060"; }

.HelpOnClick-fa-arrow-right:before {
  content: "\f061"; }

.HelpOnClick-fa-arrow-up:before {
  content: "\f062"; }

.HelpOnClick-fa-arrow-down:before {
  content: "\f063"; }

.HelpOnClick-fa-mail-forward:before,
.HelpOnClick-fa-share:before {
  content: "\f064"; }

.HelpOnClick-fa-expand:before {
  content: "\f065"; }

.HelpOnClick-fa-compress:before {
  content: "\f066"; }

.HelpOnClick-fa-plus:before {
  content: "\f067"; }

.HelpOnClick-fa-minus:before {
  content: "\f068"; }

.HelpOnClick-fa-asterisk:before {
  content: "\f069"; }

.HelpOnClick-fa-exclamation-circle:before {
  content: "\f06a"; }

.HelpOnClick-fa-gift:before {
  content: "\f06b"; }

.HelpOnClick-fa-leaf:before {
  content: "\f06c"; }

.HelpOnClick-fa-fire:before {
  content: "\f06d"; }

.HelpOnClick-fa-eye:before {
  content: "\f06e"; }

.HelpOnClick-fa-eye-slash:before {
  content: "\f070"; }

.HelpOnClick-fa-warning:before,
.HelpOnClick-fa-exclamation-triangle:before {
  content: "\f071"; }

.HelpOnClick-fa-plane:before {
  content: "\f072"; }

.HelpOnClick-fa-calendar:before {
  content: "\f073"; }

.HelpOnClick-fa-random:before {
  content: "\f074"; }

.HelpOnClick-fa-comment:before {
  content: "\f075"; }

.HelpOnClick-fa-magnet:before {
  content: "\f076"; }

.HelpOnClick-fa-chevron-up:before {
  content: "\f077"; }

.HelpOnClick-fa-chevron-down:before {
  content: "\f078"; }

.HelpOnClick-fa-retweet:before {
  content: "\f079"; }

.HelpOnClick-fa-shopping-cart:before {
  content: "\f07a"; }

.HelpOnClick-fa-folder:before {
  content: "\f07b"; }

.HelpOnClick-fa-folder-open:before {
  content: "\f07c"; }

.HelpOnClick-fa-arrows-v:before {
  content: "\f07d"; }

.HelpOnClick-fa-arrows-h:before {
  content: "\f07e"; }

.HelpOnClick-fa-bar-chart-o:before,
.HelpOnClick-fa-bar-chart:before {
  content: "\f080"; }

.HelpOnClick-fa-twitter-square:before {
  content: "\f081"; }

.HelpOnClick-fa-facebook-square:before {
  content: "\f082"; }

.HelpOnClick-fa-camera-retro:before {
  content: "\f083"; }

.HelpOnClick-fa-key:before {
  content: "\f084"; }

.HelpOnClick-fa-gears:before,
.HelpOnClick-fa-cogs:before {
  content: "\f085"; }

.HelpOnClick-fa-comments:before {
  content: "\f086"; }

.HelpOnClick-fa-thumbs-o-up:before {
  content: "\f087"; }

.HelpOnClick-fa-thumbs-o-down:before {
  content: "\f088"; }

.HelpOnClick-fa-star-half:before {
  content: "\f089"; }

.HelpOnClick-fa-heart-o:before {
  content: "\f08a"; }

.HelpOnClick-fa-sign-out:before {
  content: "\f08b"; }

.HelpOnClick-fa-linkedin-square:before {
  content: "\f08c"; }

.HelpOnClick-fa-thumb-tack:before {
  content: "\f08d"; }

.HelpOnClick-fa-external-link:before {
  content: "\f08e"; }

.HelpOnClick-fa-sign-in:before {
  content: "\f090"; }

.HelpOnClick-fa-trophy:before {
  content: "\f091"; }

.HelpOnClick-fa-github-square:before {
  content: "\f092"; }

.HelpOnClick-fa-upload:before {
  content: "\f093"; }

.HelpOnClick-fa-lemon-o:before {
  content: "\f094"; }

.HelpOnClick-fa-phone:before {
  content: "\f095"; }

.HelpOnClick-fa-square-o:before {
  content: "\f096"; }

.HelpOnClick-fa-bookmark-o:before {
  content: "\f097"; }

.HelpOnClick-fa-phone-square:before {
  content: "\f098"; }

.HelpOnClick-fa-twitter:before {
  content: "\f099"; }

.HelpOnClick-fa-facebook:before {
  content: "\f09a"; }

.HelpOnClick-fa-github:before {
  content: "\f09b"; }

.HelpOnClick-fa-unlock:before {
  content: "\f09c"; }

.HelpOnClick-fa-credit-card:before {
  content: "\f09d"; }

.HelpOnClick-fa-rss:before {
  content: "\f09e"; }

.HelpOnClick-fa-hdd-o:before {
  content: "\f0a0"; }

.HelpOnClick-fa-bullhorn:before {
  content: "\f0a1"; }

.HelpOnClick-fa-bell:before {
  content: "\f0f3"; }

.HelpOnClick-fa-certificate:before {
  content: "\f0a3"; }

.HelpOnClick-fa-hand-o-right:before {
  content: "\f0a4"; }

.HelpOnClick-fa-hand-o-left:before {
  content: "\f0a5"; }

.HelpOnClick-fa-hand-o-up:before {
  content: "\f0a6"; }

.HelpOnClick-fa-hand-o-down:before {
  content: "\f0a7"; }

.HelpOnClick-fa-arrow-circle-left:before {
  content: "\f0a8"; }

.HelpOnClick-fa-arrow-circle-right:before {
  content: "\f0a9"; }

.HelpOnClick-fa-arrow-circle-up:before {
  content: "\f0aa"; }

.HelpOnClick-fa-arrow-circle-down:before {
  content: "\f0ab"; }

.HelpOnClick-fa-globe:before {
  content: "\f0ac"; }

.HelpOnClick-fa-wrench:before {
  content: "\f0ad"; }

.HelpOnClick-fa-tasks:before {
  content: "\f0ae"; }

.HelpOnClick-fa-filter:before {
  content: "\f0b0"; }

.HelpOnClick-fa-briefcase:before {
  content: "\f0b1"; }

.HelpOnClick-fa-arrows-alt:before {
  content: "\f0b2"; }

.HelpOnClick-fa-group:before,
.HelpOnClick-fa-users:before {
  content: "\f0c0"; }

.HelpOnClick-fa-chain:before,
.HelpOnClick-fa-link:before {
  content: "\f0c1"; }

.HelpOnClick-fa-cloud:before {
  content: "\f0c2"; }

.HelpOnClick-fa-flask:before {
  content: "\f0c3"; }

.HelpOnClick-fa-cut:before,
.HelpOnClick-fa-scissors:before {
  content: "\f0c4"; }

.HelpOnClick-fa-copy:before,
.HelpOnClick-fa-files-o:before {
  content: "\f0c5"; }

.HelpOnClick-fa-paperclip:before {
  content: "\f0c6"; }

.HelpOnClick-fa-save:before,
.HelpOnClick-fa-floppy-o:before {
  content: "\f0c7"; }

.HelpOnClick-fa-square:before {
  content: "\f0c8"; }

.HelpOnClick-fa-navicon:before,
.HelpOnClick-fa-reorder:before,
.HelpOnClick-fa-bars:before {
  content: "\f0c9"; }

.HelpOnClick-fa-list-ul:before {
  content: "\f0ca"; }

.HelpOnClick-fa-list-ol:before {
  content: "\f0cb"; }

.HelpOnClick-fa-strikethrough:before {
  content: "\f0cc"; }

.HelpOnClick-fa-underline:before {
  content: "\f0cd"; }

.HelpOnClick-fa-table:before {
  content: "\f0ce"; }

.HelpOnClick-fa-magic:before {
  content: "\f0d0"; }

.HelpOnClick-fa-truck:before {
  content: "\f0d1"; }

.HelpOnClick-fa-pinterest:before {
  content: "\f0d2"; }

.HelpOnClick-fa-pinterest-square:before {
  content: "\f0d3"; }

.HelpOnClick-fa-google-plus-square:before {
  content: "\f0d4"; }

.HelpOnClick-fa-google-plus:before {
  content: "\f0d5"; }

.HelpOnClick-fa-money:before {
  content: "\f0d6"; }

.HelpOnClick-fa-caret-down:before {
  content: "\f0d7"; }

.HelpOnClick-fa-caret-up:before {
  content: "\f0d8"; }

.HelpOnClick-fa-caret-left:before {
  content: "\f0d9"; }

.HelpOnClick-fa-caret-right:before {
  content: "\f0da"; }

.HelpOnClick-fa-columns:before {
  content: "\f0db"; }

.HelpOnClick-fa-unsorted:before,
.HelpOnClick-fa-sort:before {
  content: "\f0dc"; }

.HelpOnClick-fa-sort-down:before,
.HelpOnClick-fa-sort-desc:before {
  content: "\f0dd"; }

.HelpOnClick-fa-sort-up:before,
.HelpOnClick-fa-sort-asc:before {
  content: "\f0de"; }

.HelpOnClick-fa-envelope:before {
  content: "\f0e0"; }

.HelpOnClick-fa-linkedin:before {
  content: "\f0e1"; }

.HelpOnClick-fa-rotate-left:before,
.HelpOnClick-fa-undo:before {
  content: "\f0e2"; }

.HelpOnClick-fa-legal:before,
.HelpOnClick-fa-gavel:before {
  content: "\f0e3"; }

.HelpOnClick-fa-dashboard:before,
.HelpOnClick-fa-tachometer:before {
  content: "\f0e4"; }

.HelpOnClick-fa-comment-o:before {
  content: "\f0e5"; }

.HelpOnClick-fa-comments-o:before {
  content: "\f0e6"; }

.HelpOnClick-fa-flash:before,
.HelpOnClick-fa-bolt:before {
  content: "\f0e7"; }

.HelpOnClick-fa-sitemap:before {
  content: "\f0e8"; }

.HelpOnClick-fa-umbrella:before {
  content: "\f0e9"; }

.HelpOnClick-fa-paste:before,
.HelpOnClick-fa-clipboard:before {
  content: "\f0ea"; }

.HelpOnClick-fa-lightbulb-o:before {
  content: "\f0eb"; }

.HelpOnClick-fa-exchange:before {
  content: "\f0ec"; }

.HelpOnClick-fa-cloud-download:before {
  content: "\f0ed"; }

.HelpOnClick-fa-cloud-upload:before {
  content: "\f0ee"; }

.HelpOnClick-fa-user-md:before {
  content: "\f0f0"; }

.HelpOnClick-fa-stethoscope:before {
  content: "\f0f1"; }

.HelpOnClick-fa-suitcase:before {
  content: "\f0f2"; }

.HelpOnClick-fa-bell-o:before {
  content: "\f0a2"; }

.HelpOnClick-fa-coffee:before {
  content: "\f0f4"; }

.HelpOnClick-fa-cutlery:before {
  content: "\f0f5"; }

.HelpOnClick-fa-file-text-o:before {
  content: "\f0f6"; }

.HelpOnClick-fa-building-o:before {
  content: "\f0f7"; }

.HelpOnClick-fa-hospital-o:before {
  content: "\f0f8"; }

.HelpOnClick-fa-ambulance:before {
  content: "\f0f9"; }

.HelpOnClick-fa-medkit:before {
  content: "\f0fa"; }

.HelpOnClick-fa-fighter-jet:before {
  content: "\f0fb"; }

.HelpOnClick-fa-beer:before {
  content: "\f0fc"; }

.HelpOnClick-fa-h-square:before {
  content: "\f0fd"; }

.HelpOnClick-fa-plus-square:before {
  content: "\f0fe"; }

.HelpOnClick-fa-angle-double-left:before {
  content: "\f100"; }

.HelpOnClick-fa-angle-double-right:before {
  content: "\f101"; }

.HelpOnClick-fa-angle-double-up:before {
  content: "\f102"; }

.HelpOnClick-fa-angle-double-down:before {
  content: "\f103"; }

.HelpOnClick-fa-angle-left:before {
  content: "\f104"; }

.HelpOnClick-fa-angle-right:before {
  content: "\f105"; }

.HelpOnClick-fa-angle-up:before {
  content: "\f106"; }

.HelpOnClick-fa-angle-down:before {
  content: "\f107"; }

.HelpOnClick-fa-desktop:before {
  content: "\f108"; }

.HelpOnClick-fa-laptop:before {
  content: "\f109"; }

.HelpOnClick-fa-tablet:before {
  content: "\f10a"; }

.HelpOnClick-fa-mobile-phone:before,
.HelpOnClick-fa-mobile:before {
  content: "\f10b"; }

.HelpOnClick-fa-circle-o:before {
  content: "\f10c"; }

.HelpOnClick-fa-quote-left:before {
  content: "\f10d"; }

.HelpOnClick-fa-quote-right:before {
  content: "\f10e"; }

.HelpOnClick-fa-spinner:before {
  content: "\f110"; }

.HelpOnClick-fa-circle:before {
  content: "\f111"; }

.HelpOnClick-fa-mail-reply:before,
.HelpOnClick-fa-reply:before {
  content: "\f112"; }

.HelpOnClick-fa-github-alt:before {
  content: "\f113"; }

.HelpOnClick-fa-folder-o:before {
  content: "\f114"; }

.HelpOnClick-fa-folder-open-o:before {
  content: "\f115"; }

.HelpOnClick-fa-smile-o:before {
  content: "\f118"; }

.HelpOnClick-fa-frown-o:before {
  content: "\f119"; }

.HelpOnClick-fa-meh-o:before {
  content: "\f11a"; }

.HelpOnClick-fa-gamepad:before {
  content: "\f11b"; }

.HelpOnClick-fa-keyboard-o:before {
  content: "\f11c"; }

.HelpOnClick-fa-flag-o:before {
  content: "\f11d"; }

.HelpOnClick-fa-flag-checkered:before {
  content: "\f11e"; }

.HelpOnClick-fa-terminal:before {
  content: "\f120"; }

.HelpOnClick-fa-code:before {
  content: "\f121"; }

.HelpOnClick-fa-mail-reply-all:before,
.HelpOnClick-fa-reply-all:before {
  content: "\f122"; }

.HelpOnClick-fa-star-half-empty:before,
.HelpOnClick-fa-star-half-full:before,
.HelpOnClick-fa-star-half-o:before {
  content: "\f123"; }

.HelpOnClick-fa-location-arrow:before {
  content: "\f124"; }

.HelpOnClick-fa-crop:before {
  content: "\f125"; }

.HelpOnClick-fa-code-fork:before {
  content: "\f126"; }

.HelpOnClick-fa-unlink:before,
.HelpOnClick-fa-chain-broken:before {
  content: "\f127"; }

.HelpOnClick-fa-question:before {
  content: "\f128"; }

.HelpOnClick-fa-info:before {
  content: "\f129"; }

.HelpOnClick-fa-exclamation:before {
  content: "\f12a"; }

.HelpOnClick-fa-superscript:before {
  content: "\f12b"; }

.HelpOnClick-fa-subscript:before {
  content: "\f12c"; }

.HelpOnClick-fa-eraser:before {
  content: "\f12d"; }

.HelpOnClick-fa-puzzle-piece:before {
  content: "\f12e"; }

.HelpOnClick-fa-microphone:before {
  content: "\f130"; }

.HelpOnClick-fa-microphone-slash:before {
  content: "\f131"; }

.HelpOnClick-fa-shield:before {
  content: "\f132"; }

.HelpOnClick-fa-calendar-o:before {
  content: "\f133"; }

.HelpOnClick-fa-fire-extinguisher:before {
  content: "\f134"; }

.HelpOnClick-fa-rocket:before {
  content: "\f135"; }

.HelpOnClick-fa-maxcdn:before {
  content: "\f136"; }

.HelpOnClick-fa-chevron-circle-left:before {
  content: "\f137"; }

.HelpOnClick-fa-chevron-circle-right:before {
  content: "\f138"; }

.HelpOnClick-fa-chevron-circle-up:before {
  content: "\f139"; }

.HelpOnClick-fa-chevron-circle-down:before {
  content: "\f13a"; }

.HelpOnClick-fa-html5:before {
  content: "\f13b"; }

.HelpOnClick-fa-css3:before {
  content: "\f13c"; }

.HelpOnClick-fa-anchor:before {
  content: "\f13d"; }

.HelpOnClick-fa-unlock-alt:before {
  content: "\f13e"; }

.HelpOnClick-fa-bullseye:before {
  content: "\f140"; }

.HelpOnClick-fa-ellipsis-h:before {
  content: "\f141"; }

.HelpOnClick-fa-ellipsis-v:before {
  content: "\f142"; }

.HelpOnClick-fa-rss-square:before {
  content: "\f143"; }

.HelpOnClick-fa-play-circle:before {
  content: "\f144"; }

.HelpOnClick-fa-ticket:before {
  content: "\f145"; }

.HelpOnClick-fa-minus-square:before {
  content: "\f146"; }

.HelpOnClick-fa-minus-square-o:before {
  content: "\f147"; }

.HelpOnClick-fa-level-up:before {
  content: "\f148"; }

.HelpOnClick-fa-level-down:before {
  content: "\f149"; }

.HelpOnClick-fa-check-square:before {
  content: "\f14a"; }

.HelpOnClick-fa-pencil-square:before {
  content: "\f14b"; }

.HelpOnClick-fa-external-link-square:before {
  content: "\f14c"; }

.HelpOnClick-fa-share-square:before {
  content: "\f14d"; }

.HelpOnClick-fa-compass:before {
  content: "\f14e"; }

.HelpOnClick-fa-toggle-down:before,
.HelpOnClick-fa-caret-square-o-down:before {
  content: "\f150"; }

.HelpOnClick-fa-toggle-up:before,
.HelpOnClick-fa-caret-square-o-up:before {
  content: "\f151"; }

.HelpOnClick-fa-toggle-right:before,
.HelpOnClick-fa-caret-square-o-right:before {
  content: "\f152"; }

.HelpOnClick-fa-euro:before,
.HelpOnClick-fa-eur:before {
  content: "\f153"; }

.HelpOnClick-fa-gbp:before {
  content: "\f154"; }

.HelpOnClick-fa-dollar:before,
.HelpOnClick-fa-usd:before {
  content: "\f155"; }

.HelpOnClick-fa-rupee:before,
.HelpOnClick-fa-inr:before {
  content: "\f156"; }

.HelpOnClick-fa-cny:before,
.HelpOnClick-fa-rmb:before,
.HelpOnClick-fa-yen:before,
.HelpOnClick-fa-jpy:before {
  content: "\f157"; }

.HelpOnClick-fa-ruble:before,
.HelpOnClick-fa-rouble:before,
.HelpOnClick-fa-rub:before {
  content: "\f158"; }

.HelpOnClick-fa-won:before,
.HelpOnClick-fa-krw:before {
  content: "\f159"; }

.HelpOnClick-fa-bitcoin:before,
.HelpOnClick-fa-btc:before {
  content: "\f15a"; }

.HelpOnClick-fa-file:before {
  content: "\f15b"; }

.HelpOnClick-fa-file-text:before {
  content: "\f15c"; }

.HelpOnClick-fa-sort-alpha-asc:before {
  content: "\f15d"; }

.HelpOnClick-fa-sort-alpha-desc:before {
  content: "\f15e"; }

.HelpOnClick-fa-sort-amount-asc:before {
  content: "\f160"; }

.HelpOnClick-fa-sort-amount-desc:before {
  content: "\f161"; }

.HelpOnClick-fa-sort-numeric-asc:before {
  content: "\f162"; }

.HelpOnClick-fa-sort-numeric-desc:before {
  content: "\f163"; }

.HelpOnClick-fa-thumbs-up:before {
  content: "\f164"; }

.HelpOnClick-fa-thumbs-down:before {
  content: "\f165"; }

.HelpOnClick-fa-youtube-square:before {
  content: "\f166"; }

.HelpOnClick-fa-youtube:before {
  content: "\f167"; }

.HelpOnClick-fa-xing:before {
  content: "\f168"; }

.HelpOnClick-fa-xing-square:before {
  content: "\f169"; }

.HelpOnClick-fa-youtube-play:before {
  content: "\f16a"; }

.HelpOnClick-fa-dropbox:before {
  content: "\f16b"; }

.HelpOnClick-fa-stack-overflow:before {
  content: "\f16c"; }

.HelpOnClick-fa-instagram:before {
  content: "\f16d"; }

.HelpOnClick-fa-flickr:before {
  content: "\f16e"; }

.HelpOnClick-fa-adn:before {
  content: "\f170"; }

.HelpOnClick-fa-bitbucket:before {
  content: "\f171"; }

.HelpOnClick-fa-bitbucket-square:before {
  content: "\f172"; }

.HelpOnClick-fa-tumblr:before {
  content: "\f173"; }

.HelpOnClick-fa-tumblr-square:before {
  content: "\f174"; }

.HelpOnClick-fa-long-arrow-down:before {
  content: "\f175"; }

.HelpOnClick-fa-long-arrow-up:before {
  content: "\f176"; }

.HelpOnClick-fa-long-arrow-left:before {
  content: "\f177"; }

.HelpOnClick-fa-long-arrow-right:before {
  content: "\f178"; }

.HelpOnClick-fa-apple:before {
  content: "\f179"; }

.HelpOnClick-fa-windows:before {
  content: "\f17a"; }

.HelpOnClick-fa-android:before {
  content: "\f17b"; }

.HelpOnClick-fa-linux:before {
  content: "\f17c"; }

.HelpOnClick-fa-dribbble:before {
  content: "\f17d"; }

.HelpOnClick-fa-skype:before {
  content: "\f17e"; }

.HelpOnClick-fa-foursquare:before {
  content: "\f180"; }

.HelpOnClick-fa-trello:before {
  content: "\f181"; }

.HelpOnClick-fa-female:before {
  content: "\f182"; }

.HelpOnClick-fa-male:before {
  content: "\f183"; }

.HelpOnClick-fa-gittip:before {
  content: "\f184"; }

.HelpOnClick-fa-sun-o:before {
  content: "\f185"; }

.HelpOnClick-fa-moon-o:before {
  content: "\f186"; }

.HelpOnClick-fa-archive:before {
  content: "\f187"; }

.HelpOnClick-fa-bug:before {
  content: "\f188"; }

.HelpOnClick-fa-vk:before {
  content: "\f189"; }

.HelpOnClick-fa-weibo:before {
  content: "\f18a"; }

.HelpOnClick-fa-renren:before {
  content: "\f18b"; }

.HelpOnClick-fa-pagelines:before {
  content: "\f18c"; }

.HelpOnClick-fa-stack-exchange:before {
  content: "\f18d"; }

.HelpOnClick-fa-arrow-circle-o-right:before {
  content: "\f18e"; }

.HelpOnClick-fa-arrow-circle-o-left:before {
  content: "\f190"; }

.HelpOnClick-fa-toggle-left:before,
.HelpOnClick-fa-caret-square-o-left:before {
  content: "\f191"; }

.HelpOnClick-fa-dot-circle-o:before {
  content: "\f192"; }

.HelpOnClick-fa-wheelchair:before {
  content: "\f193"; }

.HelpOnClick-fa-vimeo-square:before {
  content: "\f194"; }

.HelpOnClick-fa-turkish-lira:before,
.HelpOnClick-fa-try:before {
  content: "\f195"; }

.HelpOnClick-fa-plus-square-o:before {
  content: "\f196"; }

.HelpOnClick-fa-space-shuttle:before {
  content: "\f197"; }

.HelpOnClick-fa-slack:before {
  content: "\f198"; }

.HelpOnClick-fa-envelope-square:before {
  content: "\f199"; }

.HelpOnClick-fa-wordpress:before {
  content: "\f19a"; }

.HelpOnClick-fa-openid:before {
  content: "\f19b"; }

.HelpOnClick-fa-institution:before,
.HelpOnClick-fa-bank:before,
.HelpOnClick-fa-university:before {
  content: "\f19c"; }

.HelpOnClick-fa-mortar-board:before,
.HelpOnClick-fa-graduation-cap:before {
  content: "\f19d"; }

.HelpOnClick-fa-yahoo:before {
  content: "\f19e"; }

.HelpOnClick-fa-google:before {
  content: "\f1a0"; }

.HelpOnClick-fa-reddit:before {
  content: "\f1a1"; }

.HelpOnClick-fa-reddit-square:before {
  content: "\f1a2"; }

.HelpOnClick-fa-stumbleupon-circle:before {
  content: "\f1a3"; }

.HelpOnClick-fa-stumbleupon:before {
  content: "\f1a4"; }

.HelpOnClick-fa-delicious:before {
  content: "\f1a5"; }

.HelpOnClick-fa-digg:before {
  content: "\f1a6"; }

.HelpOnClick-fa-pied-piper:before {
  content: "\f1a7"; }

.HelpOnClick-fa-pied-piper-alt:before {
  content: "\f1a8"; }

.HelpOnClick-fa-drupal:before {
  content: "\f1a9"; }

.HelpOnClick-fa-joomla:before {
  content: "\f1aa"; }

.HelpOnClick-fa-language:before {
  content: "\f1ab"; }

.HelpOnClick-fa-fax:before {
  content: "\f1ac"; }

.HelpOnClick-fa-building:before {
  content: "\f1ad"; }

.HelpOnClick-fa-child:before {
  content: "\f1ae"; }

.HelpOnClick-fa-paw:before {
  content: "\f1b0"; }

.HelpOnClick-fa-spoon:before {
  content: "\f1b1"; }

.HelpOnClick-fa-cube:before {
  content: "\f1b2"; }

.HelpOnClick-fa-cubes:before {
  content: "\f1b3"; }

.HelpOnClick-fa-behance:before {
  content: "\f1b4"; }

.HelpOnClick-fa-behance-square:before {
  content: "\f1b5"; }

.HelpOnClick-fa-steam:before {
  content: "\f1b6"; }

.HelpOnClick-fa-steam-square:before {
  content: "\f1b7"; }

.HelpOnClick-fa-recycle:before {
  content: "\f1b8"; }

.HelpOnClick-fa-automobile:before,
.HelpOnClick-fa-car:before {
  content: "\f1b9"; }

.HelpOnClick-fa-cab:before,
.HelpOnClick-fa-taxi:before {
  content: "\f1ba"; }

.HelpOnClick-fa-tree:before {
  content: "\f1bb"; }

.HelpOnClick-fa-spotify:before {
  content: "\f1bc"; }

.HelpOnClick-fa-deviantart:before {
  content: "\f1bd"; }

.HelpOnClick-fa-soundcloud:before {
  content: "\f1be"; }

.HelpOnClick-fa-database:before {
  content: "\f1c0"; }

.HelpOnClick-fa-file-pdf-o:before {
  content: "\f1c1"; }

.HelpOnClick-fa-file-word-o:before {
  content: "\f1c2"; }

.HelpOnClick-fa-file-excel-o:before {
  content: "\f1c3"; }

.HelpOnClick-fa-file-powerpoint-o:before {
  content: "\f1c4"; }

.HelpOnClick-fa-file-photo-o:before,
.HelpOnClick-fa-file-picture-o:before,
.HelpOnClick-fa-file-image-o:before {
  content: "\f1c5"; }

.HelpOnClick-fa-file-zip-o:before,
.HelpOnClick-fa-file-archive-o:before {
  content: "\f1c6"; }

.HelpOnClick-fa-file-sound-o:before,
.HelpOnClick-fa-file-audio-o:before {
  content: "\f1c7"; }

.HelpOnClick-fa-file-movie-o:before,
.HelpOnClick-fa-file-video-o:before {
  content: "\f1c8"; }

.HelpOnClick-fa-file-code-o:before {
  content: "\f1c9"; }

.HelpOnClick-fa-vine:before {
  content: "\f1ca"; }

.HelpOnClick-fa-codepen:before {
  content: "\f1cb"; }

.HelpOnClick-fa-jsfiddle:before {
  content: "\f1cc"; }

.HelpOnClick-fa-life-bouy:before,
.HelpOnClick-fa-life-buoy:before,
.HelpOnClick-fa-life-saver:before,
.HelpOnClick-fa-support:before,
.HelpOnClick-fa-life-ring:before {
  content: "\f1cd"; }

.HelpOnClick-fa-circle-o-notch:before {
  content: "\f1ce"; }

.HelpOnClick-fa-ra:before,
.HelpOnClick-fa-rebel:before {
  content: "\f1d0"; }

.HelpOnClick-fa-ge:before,
.HelpOnClick-fa-empire:before {
  content: "\f1d1"; }

.HelpOnClick-fa-git-square:before {
  content: "\f1d2"; }

.HelpOnClick-fa-git:before {
  content: "\f1d3"; }

.HelpOnClick-fa-hacker-news:before {
  content: "\f1d4"; }

.HelpOnClick-fa-tencent-weibo:before {
  content: "\f1d5"; }

.HelpOnClick-fa-qq:before {
  content: "\f1d6"; }

.HelpOnClick-fa-wechat:before,
.HelpOnClick-fa-weixin:before {
  content: "\f1d7"; }

.HelpOnClick-fa-send:before,
.HelpOnClick-fa-paper-plane:before {
  content: "\f1d8"; }

.HelpOnClick-fa-send-o:before,
.HelpOnClick-fa-paper-plane-o:before {
  content: "\f1d9"; }

.HelpOnClick-fa-history:before {
  content: "\f1da"; }

.HelpOnClick-fa-circle-thin:before {
  content: "\f1db"; }

.HelpOnClick-fa-header:before {
  content: "\f1dc"; }

.HelpOnClick-fa-paragraph:before {
  content: "\f1dd"; }

.HelpOnClick-fa-sliders:before {
  content: "\f1de"; }

.HelpOnClick-fa-share-alt:before {
  content: "\f1e0"; }

.HelpOnClick-fa-share-alt-square:before {
  content: "\f1e1"; }

.HelpOnClick-fa-bomb:before {
  content: "\f1e2"; }

.HelpOnClick-fa-soccer-ball-o:before,
.HelpOnClick-fa-futbol-o:before {
  content: "\f1e3"; }

.HelpOnClick-fa-tty:before {
  content: "\f1e4"; }

.HelpOnClick-fa-binoculars:before {
  content: "\f1e5"; }

.HelpOnClick-fa-plug:before {
  content: "\f1e6"; }

.HelpOnClick-fa-slideshare:before {
  content: "\f1e7"; }

.HelpOnClick-fa-twitch:before {
  content: "\f1e8"; }

.HelpOnClick-fa-yelp:before {
  content: "\f1e9"; }

.HelpOnClick-fa-newspaper-o:before {
  content: "\f1ea"; }

.HelpOnClick-fa-wifi:before {
  content: "\f1eb"; }

.HelpOnClick-fa-calculator:before {
  content: "\f1ec"; }

.HelpOnClick-fa-paypal:before {
  content: "\f1ed"; }

.HelpOnClick-fa-google-wallet:before {
  content: "\f1ee"; }

.HelpOnClick-fa-cc-visa:before {
  content: "\f1f0"; }

.HelpOnClick-fa-cc-mastercard:before {
  content: "\f1f1"; }

.HelpOnClick-fa-cc-discover:before {
  content: "\f1f2"; }

.HelpOnClick-fa-cc-amex:before {
  content: "\f1f3"; }

.HelpOnClick-fa-cc-paypal:before {
  content: "\f1f4"; }

.HelpOnClick-fa-cc-stripe:before {
  content: "\f1f5"; }

.HelpOnClick-fa-bell-slash:before {
  content: "\f1f6"; }

.HelpOnClick-fa-bell-slash-o:before {
  content: "\f1f7"; }

.HelpOnClick-fa-trash:before {
  content: "\f1f8"; }

.HelpOnClick-fa-copyright:before {
  content: "\f1f9"; }

.HelpOnClick-fa-at:before {
  content: "\f1fa"; }

.HelpOnClick-fa-eyedropper:before {
  content: "\f1fb"; }

.HelpOnClick-fa-paint-brush:before {
  content: "\f1fc"; }

.HelpOnClick-fa-birthday-cake:before {
  content: "\f1fd"; }

.HelpOnClick-fa-area-chart:before {
  content: "\f1fe"; }

.HelpOnClick-fa-pie-chart:before {
  content: "\f200"; }

.HelpOnClick-fa-line-chart:before {
  content: "\f201"; }

.HelpOnClick-fa-lastfm:before {
  content: "\f202"; }

.HelpOnClick-fa-lastfm-square:before {
  content: "\f203"; }

.HelpOnClick-fa-toggle-off:before {
  content: "\f204"; }

.HelpOnClick-fa-toggle-on:before {
  content: "\f205"; }

.HelpOnClick-fa-bicycle:before {
  content: "\f206"; }

.HelpOnClick-fa-bus:before {
  content: "\f207"; }

.HelpOnClick-fa-ioxhost:before {
  content: "\f208"; }

.HelpOnClick-fa-angellist:before {
  content: "\f209"; }

.HelpOnClick-fa-cc:before {
  content: "\f20a"; }

.HelpOnClick-fa-shekel:before,
.HelpOnClick-fa-sheqel:before,
.HelpOnClick-fa-ils:before {
  content: "\f20b"; }

.HelpOnClick-fa-meanpath:before {
  content: "\f20c"; }

.HelpOnClick-fa-chat:before {
  content: "\f086"; }

.HelpOnClick-fa-email:before {
  content: "\f0e0"; }

.HelpOnClick-fa-call:before {
  content: "\f095"; }

.HelpOnClick-FontIconsLibrary .HelpOnClick-fa {
  padding: 5px; }

.HelpOnClick-FontIconsLibrary .HelpOnClick-fa:before {
  width: auto;
  margin: 5px; }

.HelpOnClick-FontIconsLibrary .HelpOnClick-fa:hover {
  background: #eee; }

.default .editorInline > div {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none; }
  .default .editorInline > div img {
    display: none; }
  .default .editorInline > div:first-child {
    background-image: url("assets/images/bold_Icons/bold_steelblue2.png") !important; }
  .default .editorInline > div:nth-child(2) {
    background-image: url("assets/images/Italic_icons/Italic_steelblue.png") !important; }
  .default .editorInline > div:nth-child(3) {
    background-image: url("assets/images/Underline_icons/line_steelblue.png") !important; }

.default .editorEmoji {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/smily_icons/smiling-steelblue.png") !important; }
  .default .editorEmoji img {
    display: none; }

.default .overview ul {
  list-style-image: url("assets/images/list_icon/listicon_steelblue.png") !important; }
  .default .overview ul li {
    padding-left: 8px; }

.default .editorAttachImg {
  margin-left: 5px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/attachment_icon/attachmant_steelblue.png") !important; }

.default .innercontent_wrapper .box .searchText input {
  padding-right: 27px;
  background-repeat: no-repeat;
  background-position: 97%;
  background-image: url("assets/images/search_icon/search_steelblue.png") !important; }

.default .textEditorLink {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: !important; }
  .default .textEditorLink img {
    display: none; }

.default .editorLink {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: !important; }
  .default .editorLink img {
    display: none; }

.default .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1) {
  background-image: url("assets/images/infotab.png"); }
  .default .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1).active {
    background-image: url("assets/images/info_icon/info_steelblue.png") !important; }

.default .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2) {
  background-image: url("assets/images/footstepstab.png"); }
  .default .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2).active {
    background-image: url("assets/images/footstep_icon/footstep_steelblue.png") !important; }

.default .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3) {
  background-image: url("assets/images/historytab.png"); }
  .default .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3).active {
    background-image: url("assets/images/history_Icon/history_steelblue.png") !important; }

.default .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4) {
  border-top-right-radius: 10px;
  background-image: url("assets/images/responsetab.png"); }
  .default .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4).active {
    background-image: url("assets/images/response_chat/steelBlue.png") !important; }

.default .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
  color: #3ba8c5;
  background-color: #fff; }

.default .chatInfoWrapper .tab-content {
  position: relative; }
  .default .chatInfoWrapper .tab-content p.hide_btn {
    color: #3ba8c5;
    font-family: "Segoe UI Bold";
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    width: 100%;
    margin: 0;
    top: -7px; }
    .default .chatInfoWrapper .tab-content p.hide_btn span {
      width: 70px;
      display: inline-block;
      background-color: #fff;
      cursor: pointer; }
      .default .chatInfoWrapper .tab-content p.hide_btn span img {
        margin-right: 8px;
        margin-top: -2px; }

.default .chatInfoWrapper .tabContent {
  text-align: left;
  margin-top: 24px;
  padding-top: 35px;
  border-top: 1px solid #dedede;
  color: #000000;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }
  .default .chatInfoWrapper .tabContent h6 {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    text-align: left;
    margin: 0 20px 20px; }
  .default .chatInfoWrapper .tabContent .data p {
    margin-bottom: 10px;
    margin-top: 0; }
  .default .chatInfoWrapper .tabContent .data .label {
    padding-right: 0; }
    .default .chatInfoWrapper .tabContent .data .label p {
      margin-left: 6px;
      font-family: "Segoe UI Semi Bold";
      font-size: 14px; }
  .default .chatInfoWrapper .tabContent .data .value p {
    margin-right: 6px;
    font-family: "Segoe UI";
    font-size: 14px; }
  .default .chatInfoWrapper .tabContent .data .webInfo {
    padding: 25px 20px;
    background-color: #ebf6f9;
    margin-bottom: 20px;
    margin-top: 15px; }
    .default .chatInfoWrapper .tabContent .data .webInfo > p {
      font-family: "Segoe UI Semi Bold";
      margin-bottom: 20px;
      margin-top: 0;
      font-size: 14px; }
    .default .chatInfoWrapper .tabContent .data .webInfo .row:last-child p {
      margin-bottom: 0; }
    .default .chatInfoWrapper .tabContent .data .webInfo .label p {
      margin-left: 0; }
  .default .chatInfoWrapper .tabContent .data .notes {
    padding: 25px 20px;
    background-color: #ebf6f9;
    border-radius: 0 0 10px 10px;
    margin-top: 12px; }
    .default .chatInfoWrapper .tabContent .data .notes h6 {
      color: #293037;
      font-family: "Segoe UI Bold";
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      margin-left: 0;
      margin-right: 0; }
    .default .chatInfoWrapper .tabContent .data .notes button {
      background-color: transparent; }
      .default .chatInfoWrapper .tabContent .data .notes button:hover {
        color: inherit; }
    .default .chatInfoWrapper .tabContent .data .notes .notesData {
      margin-bottom: 27px; }
      .default .chatInfoWrapper .tabContent .data .notes .notesData p {
        width: 100%; }
  .default .chatInfoWrapper .tabContent .data .rowLayout .value p {
    margin: 0 6px; }
  .default .chatInfoWrapper .tabContent .data .rowLayout .link {
    color: #3ba8c5; }
    .default .chatInfoWrapper .tabContent .data .rowLayout .link img {
      margin-left: 8px; }
  .default .chatInfoWrapper .tabContent .data .rowLayout .disabled {
    color: #adadad; }
  .default .chatInfoWrapper .tabContent .data .withImage img {
    margin-left: 8px;
    margin-top: -3px; }
  .default .chatInfoWrapper .tabContent .footstepsWrapper {
    padding-bottom: 35px; }
    .default .chatInfoWrapper .tabContent .footstepsWrapper .link {
      color: #3ba8c5; }
      .default .chatInfoWrapper .tabContent .footstepsWrapper .link img {
        margin-left: 8px; }
  .default .chatInfoWrapper .tabContent .historyWrapper {
    padding-bottom: 40px; }
    .default .chatInfoWrapper .tabContent .historyWrapper > div > div.label, .default .chatInfoWrapper .tabContent .historyWrapper > div > div.value {
      cursor: pointer; }
    .default .chatInfoWrapper .tabContent .historyWrapper .label p {
      font-size: 12px; }
      .default .chatInfoWrapper .tabContent .historyWrapper .label p span {
        color: #adadad; }
    .default .chatInfoWrapper .tabContent .historyWrapper .value p {
      color: #3ba8c5;
      font-size: 14px; }
    .default .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails {
      padding-top: 25px;
      padding-bottom: 0;
      max-height: 60vh;
      margin-bottom: 20px; }
      .default .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails p {
        font-size: 14px; }
      .default .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .value p {
        color: #000000; }
      .default .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails > img {
        margin-top: -15px;
        margin-right: -9px;
        cursor: pointer; }
      .default .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript h6 {
        color: #293037;
        font-family: "Segoe UI Bold";
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 20px 6px 15px 22px; }
      .default .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p {
        margin-left: 6px; }
        .default .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child {
          font-family: "Segoe UI Semi Bold";
          margin-bottom: 0; }
          .default .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child span {
            color: #adadad; }
      .default .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .rcs-custom-scroll .rcs-inner-container > div {
        padding: 0; }
  .default .chatInfoWrapper .tabContent.responseWrapper .panelWrapper {
    border-top-right-radius: 0;
    border-top-left-radius: 0; }
  .default .chatInfoWrapper .tabContent.responseWrapper .searchForm {
    padding: 0 20px; }
    .default .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input {
      padding-right: 27px;
      background-repeat: no-repeat;
      background-position: 97%;
      background-image: url("assets/images/search_icon/search_steelblue.png") !important; }
      .default .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-webkit-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .default .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-ms-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .default .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
    .default .chatInfoWrapper .tabContent.responseWrapper .searchForm p {
      color: #adadad;
      font-family: "Segoe UI";
      font-size: 14px;
      font-weight: 400; }
      .default .chatInfoWrapper .tabContent.responseWrapper .searchForm p span {
        color: #010101;
        font-family: "Segoe UI Semi Bold";
        font-weight: 600; }
  .default .chatInfoWrapper .tabContent.responseWrapper .ruleComponent {
    padding: 15px 20px; }
    .default .chatInfoWrapper .tabContent.responseWrapper .ruleComponent > div:first-child {
      max-width: -webkit-max-content;
      max-width: -moz-max-content;
      max-width: max-content; }
    .default .chatInfoWrapper .tabContent.responseWrapper .ruleComponent .rule_data p.ques {
      font-family: "Segoe UI Semi Bold";
      font-weight: 600; }

.steelBlue .editorInline > div {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none; }
  .steelBlue .editorInline > div img {
    display: none; }
  .steelBlue .editorInline > div:first-child {
    background-image: url("assets/images/bold_Icons/bold_steelblue2.png") !important; }
  .steelBlue .editorInline > div:nth-child(2) {
    background-image: url("assets/images/Italic_icons/Italic_steelblue.png") !important; }
  .steelBlue .editorInline > div:nth-child(3) {
    background-image: url("assets/images/Underline_icons/line_steelblue.png") !important; }

.steelBlue .editorEmoji {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/smily_icons/smiling-steelblue.png") !important; }
  .steelBlue .editorEmoji img {
    display: none; }

.steelBlue .overview ul {
  list-style-image: url("assets/images/list_icon/listicon_steelblue.png") !important; }
  .steelBlue .overview ul li {
    padding-left: 8px; }

.steelBlue .editorAttachImg {
  margin-left: 5px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/attachment_icon/attachmant_steelblue.png") !important; }

.steelBlue .innercontent_wrapper .box .searchText input {
  padding-right: 27px;
  background-repeat: no-repeat;
  background-position: 97%;
  background-image: url("assets/images/search_icon/search_steelblue.png") !important; }

.steelBlue .textEditorLink {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/link_icons/link_steelblue.png") !important; }
  .steelBlue .textEditorLink img {
    display: none; }

.steelBlue .editorLink {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/link_icons/link_steelblue.png") !important; }
  .steelBlue .editorLink img {
    display: none; }

.steelBlue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1) {
  background-image: url("assets/images/infotab.png"); }
  .steelBlue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1).active {
    background-image: url("assets/images/info_icon/info_steelblue.png") !important; }

.steelBlue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2) {
  background-image: url("assets/images/footstepstab.png"); }
  .steelBlue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2).active {
    background-image: url("assets/images/footstep_icon/footstep_steelblue.png") !important; }

.steelBlue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3) {
  background-image: url("assets/images/historytab.png"); }
  .steelBlue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3).active {
    background-image: url("assets/images/history_Icon/history_steelblue.png") !important; }

.steelBlue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4) {
  border-top-right-radius: 10px;
  background-image: url("assets/images/responsetab.png"); }
  .steelBlue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4).active {
    background-image: url("assets/images/response_chat/steelBlue.png") !important; }

.steelBlue .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
  color: #3ba8c5;
  background-color: #fff; }

.steelBlue .chatInfoWrapper .tab-content {
  position: relative; }
  .steelBlue .chatInfoWrapper .tab-content p.hide_btn {
    color: #3ba8c5;
    font-family: "Segoe UI Bold";
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    width: 100%;
    margin: 0;
    top: -7px; }
    .steelBlue .chatInfoWrapper .tab-content p.hide_btn span {
      width: 70px;
      display: inline-block;
      background-color: #fff;
      cursor: pointer; }
      .steelBlue .chatInfoWrapper .tab-content p.hide_btn span img {
        margin-right: 8px;
        margin-top: -2px; }

.steelBlue .chatInfoWrapper .tabContent {
  text-align: left;
  margin-top: 24px;
  padding-top: 35px;
  border-top: 1px solid #dedede;
  color: #000000;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }
  .steelBlue .chatInfoWrapper .tabContent h6 {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    text-align: left;
    margin: 0 20px 20px; }
  .steelBlue .chatInfoWrapper .tabContent .data p {
    margin-bottom: 10px;
    margin-top: 0; }
  .steelBlue .chatInfoWrapper .tabContent .data .label {
    padding-right: 0; }
    .steelBlue .chatInfoWrapper .tabContent .data .label p {
      margin-left: 6px;
      font-family: "Segoe UI Semi Bold";
      font-size: 14px; }
  .steelBlue .chatInfoWrapper .tabContent .data .value p {
    margin-right: 6px;
    font-family: "Segoe UI";
    font-size: 14px; }
  .steelBlue .chatInfoWrapper .tabContent .data .webInfo {
    padding: 25px 20px;
    background-color: #ebf6f9;
    margin-bottom: 20px;
    margin-top: 15px; }
    .steelBlue .chatInfoWrapper .tabContent .data .webInfo > p {
      font-family: "Segoe UI Semi Bold";
      margin-bottom: 20px;
      margin-top: 0;
      font-size: 14px; }
    .steelBlue .chatInfoWrapper .tabContent .data .webInfo .row:last-child p {
      margin-bottom: 0; }
    .steelBlue .chatInfoWrapper .tabContent .data .webInfo .label p {
      margin-left: 0; }
  .steelBlue .chatInfoWrapper .tabContent .data .notes {
    padding: 25px 20px;
    background-color: #ebf6f9;
    border-radius: 0 0 10px 10px;
    margin-top: 12px; }
    .steelBlue .chatInfoWrapper .tabContent .data .notes h6 {
      color: #293037;
      font-family: "Segoe UI Bold";
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      margin-left: 0;
      margin-right: 0; }
    .steelBlue .chatInfoWrapper .tabContent .data .notes button {
      background-color: transparent; }
      .steelBlue .chatInfoWrapper .tabContent .data .notes button:hover {
        color: inherit; }
    .steelBlue .chatInfoWrapper .tabContent .data .notes .notesData {
      margin-bottom: 27px; }
      .steelBlue .chatInfoWrapper .tabContent .data .notes .notesData p {
        width: 100%; }
  .steelBlue .chatInfoWrapper .tabContent .data .rowLayout .value p {
    margin: 0 6px; }
  .steelBlue .chatInfoWrapper .tabContent .data .rowLayout .link {
    color: #3ba8c5; }
    .steelBlue .chatInfoWrapper .tabContent .data .rowLayout .link img {
      margin-left: 8px; }
  .steelBlue .chatInfoWrapper .tabContent .data .rowLayout .disabled {
    color: #adadad; }
  .steelBlue .chatInfoWrapper .tabContent .data .withImage img {
    margin-left: 8px;
    margin-top: -3px; }
  .steelBlue .chatInfoWrapper .tabContent .footstepsWrapper {
    padding-bottom: 35px; }
    .steelBlue .chatInfoWrapper .tabContent .footstepsWrapper .link {
      color: #3ba8c5; }
      .steelBlue .chatInfoWrapper .tabContent .footstepsWrapper .link img {
        margin-left: 8px; }
  .steelBlue .chatInfoWrapper .tabContent .historyWrapper {
    padding-bottom: 40px; }
    .steelBlue .chatInfoWrapper .tabContent .historyWrapper > div > div.label, .steelBlue .chatInfoWrapper .tabContent .historyWrapper > div > div.value {
      cursor: pointer; }
    .steelBlue .chatInfoWrapper .tabContent .historyWrapper .label p {
      font-size: 12px; }
      .steelBlue .chatInfoWrapper .tabContent .historyWrapper .label p span {
        color: #adadad; }
    .steelBlue .chatInfoWrapper .tabContent .historyWrapper .value p {
      color: #3ba8c5;
      font-size: 14px; }
    .steelBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails {
      padding-top: 25px;
      padding-bottom: 0;
      max-height: 60vh;
      margin-bottom: 20px; }
      .steelBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails p {
        font-size: 14px; }
      .steelBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .value p {
        color: #000000; }
      .steelBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails > img {
        margin-top: -15px;
        margin-right: -9px;
        cursor: pointer; }
      .steelBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript h6 {
        color: #293037;
        font-family: "Segoe UI Bold";
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 20px 6px 15px 22px; }
      .steelBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p {
        margin-left: 6px; }
        .steelBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child {
          font-family: "Segoe UI Semi Bold";
          margin-bottom: 0; }
          .steelBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child span {
            color: #adadad; }
      .steelBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .rcs-custom-scroll .rcs-inner-container > div {
        padding: 0; }
  .steelBlue .chatInfoWrapper .tabContent.responseWrapper .panelWrapper {
    border-top-right-radius: 0;
    border-top-left-radius: 0; }
  .steelBlue .chatInfoWrapper .tabContent.responseWrapper .searchForm {
    padding: 0 20px; }
    .steelBlue .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input {
      padding-right: 27px;
      background-repeat: no-repeat;
      background-position: 97%;
      background-image: url("assets/images/search_icon/search_steelblue.png") !important; }
      .steelBlue .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-webkit-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .steelBlue .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-ms-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .steelBlue .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
    .steelBlue .chatInfoWrapper .tabContent.responseWrapper .searchForm p {
      color: #adadad;
      font-family: "Segoe UI";
      font-size: 14px;
      font-weight: 400; }
      .steelBlue .chatInfoWrapper .tabContent.responseWrapper .searchForm p span {
        color: #010101;
        font-family: "Segoe UI Semi Bold";
        font-weight: 600; }
  .steelBlue .chatInfoWrapper .tabContent.responseWrapper .ruleComponent {
    padding: 15px 20px; }
    .steelBlue .chatInfoWrapper .tabContent.responseWrapper .ruleComponent > div:first-child {
      max-width: -webkit-max-content;
      max-width: -moz-max-content;
      max-width: max-content; }
    .steelBlue .chatInfoWrapper .tabContent.responseWrapper .ruleComponent .rule_data p.ques {
      font-family: "Segoe UI Semi Bold";
      font-weight: 600; }

.lightSeaGreen .editorInline > div {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none; }
  .lightSeaGreen .editorInline > div img {
    display: none; }
  .lightSeaGreen .editorInline > div:first-child {
    background-image: url("assets/images/bold_Icons/bold_seagreen2.png") !important; }
  .lightSeaGreen .editorInline > div:nth-child(2) {
    background-image: url("assets/images/Italic_icons/Italic_seagreen.png") !important; }
  .lightSeaGreen .editorInline > div:nth-child(3) {
    background-image: url("assets/images/Underline_icons/line_seagreen.png") !important; }

.lightSeaGreen .editorEmoji {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/smily_icons/smiling-seagreen.png") !important; }
  .lightSeaGreen .editorEmoji img {
    display: none; }

.lightSeaGreen .overview ul {
  list-style-image: url("assets/images/list_icon/listicon_seagreen.png") !important; }
  .lightSeaGreen .overview ul li {
    padding-left: 8px; }

.lightSeaGreen .editorAttachImg {
  margin-left: 5px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/attachment_icon/attachmant_seagreen.png") !important; }

.lightSeaGreen .innercontent_wrapper .box .searchText input {
  padding-right: 27px;
  background-repeat: no-repeat;
  background-position: 97%;
  background-image: url("assets/images/search_icon/search_seagreen.png") !important; }

.lightSeaGreen .textEditorLink {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/link_icons/link_seagreen.png") !important; }
  .lightSeaGreen .textEditorLink img {
    display: none; }

.lightSeaGreen .editorLink {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/link_icons/link_seagreen.png") !important; }
  .lightSeaGreen .editorLink img {
    display: none; }

.lightSeaGreen .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1) {
  background-image: url("assets/images/infotab.png"); }
  .lightSeaGreen .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1).active {
    background-image: url("assets/images/info_icon/info_seagreen.png") !important; }

.lightSeaGreen .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2) {
  background-image: url("assets/images/footstepstab.png"); }
  .lightSeaGreen .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2).active {
    background-image: url("assets/images/footstep_icon/footstep_seagreen.png") !important; }

.lightSeaGreen .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3) {
  background-image: url("assets/images/historytab.png"); }
  .lightSeaGreen .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3).active {
    background-image: url("assets/images/history_Icon/history_seagreen.png") !important; }

.lightSeaGreen .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4) {
  border-top-right-radius: 10px;
  background-image: url("assets/images/responsetab.png"); }
  .lightSeaGreen .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4).active {
    background-image: url("assets/images/response_chat/lightSeaGreen.png") !important; }

.lightSeaGreen .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
  color: #3ba8c5;
  background-color: #fff; }

.lightSeaGreen .chatInfoWrapper .tab-content {
  position: relative; }
  .lightSeaGreen .chatInfoWrapper .tab-content p.hide_btn {
    color: #3ba8c5;
    font-family: "Segoe UI Bold";
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    width: 100%;
    margin: 0;
    top: -7px; }
    .lightSeaGreen .chatInfoWrapper .tab-content p.hide_btn span {
      width: 70px;
      display: inline-block;
      background-color: #fff;
      cursor: pointer; }
      .lightSeaGreen .chatInfoWrapper .tab-content p.hide_btn span img {
        margin-right: 8px;
        margin-top: -2px; }

.lightSeaGreen .chatInfoWrapper .tabContent {
  text-align: left;
  margin-top: 24px;
  padding-top: 35px;
  border-top: 1px solid #dedede;
  color: #000000;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }
  .lightSeaGreen .chatInfoWrapper .tabContent h6 {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    text-align: left;
    margin: 0 20px 20px; }
  .lightSeaGreen .chatInfoWrapper .tabContent .data p {
    margin-bottom: 10px;
    margin-top: 0; }
  .lightSeaGreen .chatInfoWrapper .tabContent .data .label {
    padding-right: 0; }
    .lightSeaGreen .chatInfoWrapper .tabContent .data .label p {
      margin-left: 6px;
      font-family: "Segoe UI Semi Bold";
      font-size: 14px; }
  .lightSeaGreen .chatInfoWrapper .tabContent .data .value p {
    margin-right: 6px;
    font-family: "Segoe UI";
    font-size: 14px; }
  .lightSeaGreen .chatInfoWrapper .tabContent .data .webInfo {
    padding: 25px 20px;
    background-color: #ebf6f9;
    margin-bottom: 20px;
    margin-top: 15px; }
    .lightSeaGreen .chatInfoWrapper .tabContent .data .webInfo > p {
      font-family: "Segoe UI Semi Bold";
      margin-bottom: 20px;
      margin-top: 0;
      font-size: 14px; }
    .lightSeaGreen .chatInfoWrapper .tabContent .data .webInfo .row:last-child p {
      margin-bottom: 0; }
    .lightSeaGreen .chatInfoWrapper .tabContent .data .webInfo .label p {
      margin-left: 0; }
  .lightSeaGreen .chatInfoWrapper .tabContent .data .notes {
    padding: 25px 20px;
    background-color: #ebf6f9;
    border-radius: 0 0 10px 10px;
    margin-top: 12px; }
    .lightSeaGreen .chatInfoWrapper .tabContent .data .notes h6 {
      color: #293037;
      font-family: "Segoe UI Bold";
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      margin-left: 0;
      margin-right: 0; }
    .lightSeaGreen .chatInfoWrapper .tabContent .data .notes button {
      background-color: transparent; }
      .lightSeaGreen .chatInfoWrapper .tabContent .data .notes button:hover {
        color: inherit; }
    .lightSeaGreen .chatInfoWrapper .tabContent .data .notes .notesData {
      margin-bottom: 27px; }
      .lightSeaGreen .chatInfoWrapper .tabContent .data .notes .notesData p {
        width: 100%; }
  .lightSeaGreen .chatInfoWrapper .tabContent .data .rowLayout .value p {
    margin: 0 6px; }
  .lightSeaGreen .chatInfoWrapper .tabContent .data .rowLayout .link {
    color: #3ba8c5; }
    .lightSeaGreen .chatInfoWrapper .tabContent .data .rowLayout .link img {
      margin-left: 8px; }
  .lightSeaGreen .chatInfoWrapper .tabContent .data .rowLayout .disabled {
    color: #adadad; }
  .lightSeaGreen .chatInfoWrapper .tabContent .data .withImage img {
    margin-left: 8px;
    margin-top: -3px; }
  .lightSeaGreen .chatInfoWrapper .tabContent .footstepsWrapper {
    padding-bottom: 35px; }
    .lightSeaGreen .chatInfoWrapper .tabContent .footstepsWrapper .link {
      color: #3ba8c5; }
      .lightSeaGreen .chatInfoWrapper .tabContent .footstepsWrapper .link img {
        margin-left: 8px; }
  .lightSeaGreen .chatInfoWrapper .tabContent .historyWrapper {
    padding-bottom: 40px; }
    .lightSeaGreen .chatInfoWrapper .tabContent .historyWrapper > div > div.label, .lightSeaGreen .chatInfoWrapper .tabContent .historyWrapper > div > div.value {
      cursor: pointer; }
    .lightSeaGreen .chatInfoWrapper .tabContent .historyWrapper .label p {
      font-size: 12px; }
      .lightSeaGreen .chatInfoWrapper .tabContent .historyWrapper .label p span {
        color: #adadad; }
    .lightSeaGreen .chatInfoWrapper .tabContent .historyWrapper .value p {
      color: #3ba8c5;
      font-size: 14px; }
    .lightSeaGreen .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails {
      padding-top: 25px;
      padding-bottom: 0;
      max-height: 60vh;
      margin-bottom: 20px; }
      .lightSeaGreen .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails p {
        font-size: 14px; }
      .lightSeaGreen .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .value p {
        color: #000000; }
      .lightSeaGreen .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails > img {
        margin-top: -15px;
        margin-right: -9px;
        cursor: pointer; }
      .lightSeaGreen .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript h6 {
        color: #293037;
        font-family: "Segoe UI Bold";
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 20px 6px 15px 22px; }
      .lightSeaGreen .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p {
        margin-left: 6px; }
        .lightSeaGreen .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child {
          font-family: "Segoe UI Semi Bold";
          margin-bottom: 0; }
          .lightSeaGreen .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child span {
            color: #adadad; }
      .lightSeaGreen .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .rcs-custom-scroll .rcs-inner-container > div {
        padding: 0; }
  .lightSeaGreen .chatInfoWrapper .tabContent.responseWrapper .panelWrapper {
    border-top-right-radius: 0;
    border-top-left-radius: 0; }
  .lightSeaGreen .chatInfoWrapper .tabContent.responseWrapper .searchForm {
    padding: 0 20px; }
    .lightSeaGreen .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input {
      padding-right: 27px;
      background-repeat: no-repeat;
      background-position: 97%;
      background-image: url("assets/images/search_icon/search_seagreen.png") !important; }
      .lightSeaGreen .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-webkit-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .lightSeaGreen .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-ms-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .lightSeaGreen .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
    .lightSeaGreen .chatInfoWrapper .tabContent.responseWrapper .searchForm p {
      color: #adadad;
      font-family: "Segoe UI";
      font-size: 14px;
      font-weight: 400; }
      .lightSeaGreen .chatInfoWrapper .tabContent.responseWrapper .searchForm p span {
        color: #010101;
        font-family: "Segoe UI Semi Bold";
        font-weight: 600; }
  .lightSeaGreen .chatInfoWrapper .tabContent.responseWrapper .ruleComponent {
    padding: 15px 20px; }
    .lightSeaGreen .chatInfoWrapper .tabContent.responseWrapper .ruleComponent > div:first-child {
      max-width: -webkit-max-content;
      max-width: -moz-max-content;
      max-width: max-content; }
    .lightSeaGreen .chatInfoWrapper .tabContent.responseWrapper .ruleComponent .rule_data p.ques {
      font-family: "Segoe UI Semi Bold";
      font-weight: 600; }

.deepPink .editorInline > div {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none; }
  .deepPink .editorInline > div img {
    display: none; }
  .deepPink .editorInline > div:first-child {
    background-image: url("assets/images/bold_Icons/bold_pink2.png") !important; }
  .deepPink .editorInline > div:nth-child(2) {
    background-image: url("assets/images/Italic_icons/Italic_pink.png") !important; }
  .deepPink .editorInline > div:nth-child(3) {
    background-image: url("assets/images/Underline_icons/line_pink.png") !important; }

.deepPink .editorEmoji {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/smily_icons/smiling-pink.png") !important; }
  .deepPink .editorEmoji img {
    display: none; }

.deepPink .overview ul {
  list-style-image: url("assets/images/list_icon/listicon_pink.png") !important; }
  .deepPink .overview ul li {
    padding-left: 8px; }

.deepPink .editorAttachImg {
  margin-left: 5px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/attachment_icon/attachmant_pink.png") !important; }

.deepPink .innercontent_wrapper .box .searchText input {
  padding-right: 27px;
  background-repeat: no-repeat;
  background-position: 97%;
  background-image: url("assets/images/search_icon/search_pink.png") !important; }

.deepPink .textEditorLink {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/link_icons/link_pink.png") !important; }
  .deepPink .textEditorLink img {
    display: none; }

.deepPink .editorLink {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/link_icons/link_pink.png") !important; }
  .deepPink .editorLink img {
    display: none; }

.deepPink .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1) {
  background-image: url("assets/images/infotab.png"); }
  .deepPink .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1).active {
    background-image: url("assets/images/info_icon/info_pink.png") !important; }

.deepPink .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2) {
  background-image: url("assets/images/footstepstab.png"); }
  .deepPink .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2).active {
    background-image: url("assets/images/footstep_icon/footstep_pink.png") !important; }

.deepPink .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3) {
  background-image: url("assets/images/historytab.png"); }
  .deepPink .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3).active {
    background-image: url("assets/images/history_Icon/history_pink.png") !important; }

.deepPink .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4) {
  border-top-right-radius: 10px;
  background-image: url("assets/images/responsetab.png"); }
  .deepPink .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4).active {
    background-image: url("assets/images/response_chat/deepPink.png") !important; }

.deepPink .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
  color: #3ba8c5;
  background-color: #fff; }

.deepPink .chatInfoWrapper .tab-content {
  position: relative; }
  .deepPink .chatInfoWrapper .tab-content p.hide_btn {
    color: #3ba8c5;
    font-family: "Segoe UI Bold";
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    width: 100%;
    margin: 0;
    top: -7px; }
    .deepPink .chatInfoWrapper .tab-content p.hide_btn span {
      width: 70px;
      display: inline-block;
      background-color: #fff;
      cursor: pointer; }
      .deepPink .chatInfoWrapper .tab-content p.hide_btn span img {
        margin-right: 8px;
        margin-top: -2px; }

.deepPink .chatInfoWrapper .tabContent {
  text-align: left;
  margin-top: 24px;
  padding-top: 35px;
  border-top: 1px solid #dedede;
  color: #000000;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }
  .deepPink .chatInfoWrapper .tabContent h6 {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    text-align: left;
    margin: 0 20px 20px; }
  .deepPink .chatInfoWrapper .tabContent .data p {
    margin-bottom: 10px;
    margin-top: 0; }
  .deepPink .chatInfoWrapper .tabContent .data .label {
    padding-right: 0; }
    .deepPink .chatInfoWrapper .tabContent .data .label p {
      margin-left: 6px;
      font-family: "Segoe UI Semi Bold";
      font-size: 14px; }
  .deepPink .chatInfoWrapper .tabContent .data .value p {
    margin-right: 6px;
    font-family: "Segoe UI";
    font-size: 14px; }
  .deepPink .chatInfoWrapper .tabContent .data .webInfo {
    padding: 25px 20px;
    background-color: #ebf6f9;
    margin-bottom: 20px;
    margin-top: 15px; }
    .deepPink .chatInfoWrapper .tabContent .data .webInfo > p {
      font-family: "Segoe UI Semi Bold";
      margin-bottom: 20px;
      margin-top: 0;
      font-size: 14px; }
    .deepPink .chatInfoWrapper .tabContent .data .webInfo .row:last-child p {
      margin-bottom: 0; }
    .deepPink .chatInfoWrapper .tabContent .data .webInfo .label p {
      margin-left: 0; }
  .deepPink .chatInfoWrapper .tabContent .data .notes {
    padding: 25px 20px;
    background-color: #ebf6f9;
    border-radius: 0 0 10px 10px;
    margin-top: 12px; }
    .deepPink .chatInfoWrapper .tabContent .data .notes h6 {
      color: #293037;
      font-family: "Segoe UI Bold";
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      margin-left: 0;
      margin-right: 0; }
    .deepPink .chatInfoWrapper .tabContent .data .notes button {
      background-color: transparent; }
      .deepPink .chatInfoWrapper .tabContent .data .notes button:hover {
        color: inherit; }
    .deepPink .chatInfoWrapper .tabContent .data .notes .notesData {
      margin-bottom: 27px; }
      .deepPink .chatInfoWrapper .tabContent .data .notes .notesData p {
        width: 100%; }
  .deepPink .chatInfoWrapper .tabContent .data .rowLayout .value p {
    margin: 0 6px; }
  .deepPink .chatInfoWrapper .tabContent .data .rowLayout .link {
    color: #3ba8c5; }
    .deepPink .chatInfoWrapper .tabContent .data .rowLayout .link img {
      margin-left: 8px; }
  .deepPink .chatInfoWrapper .tabContent .data .rowLayout .disabled {
    color: #adadad; }
  .deepPink .chatInfoWrapper .tabContent .data .withImage img {
    margin-left: 8px;
    margin-top: -3px; }
  .deepPink .chatInfoWrapper .tabContent .footstepsWrapper {
    padding-bottom: 35px; }
    .deepPink .chatInfoWrapper .tabContent .footstepsWrapper .link {
      color: #3ba8c5; }
      .deepPink .chatInfoWrapper .tabContent .footstepsWrapper .link img {
        margin-left: 8px; }
  .deepPink .chatInfoWrapper .tabContent .historyWrapper {
    padding-bottom: 40px; }
    .deepPink .chatInfoWrapper .tabContent .historyWrapper > div > div.label, .deepPink .chatInfoWrapper .tabContent .historyWrapper > div > div.value {
      cursor: pointer; }
    .deepPink .chatInfoWrapper .tabContent .historyWrapper .label p {
      font-size: 12px; }
      .deepPink .chatInfoWrapper .tabContent .historyWrapper .label p span {
        color: #adadad; }
    .deepPink .chatInfoWrapper .tabContent .historyWrapper .value p {
      color: #3ba8c5;
      font-size: 14px; }
    .deepPink .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails {
      padding-top: 25px;
      padding-bottom: 0;
      max-height: 60vh;
      margin-bottom: 20px; }
      .deepPink .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails p {
        font-size: 14px; }
      .deepPink .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .value p {
        color: #000000; }
      .deepPink .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails > img {
        margin-top: -15px;
        margin-right: -9px;
        cursor: pointer; }
      .deepPink .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript h6 {
        color: #293037;
        font-family: "Segoe UI Bold";
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 20px 6px 15px 22px; }
      .deepPink .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p {
        margin-left: 6px; }
        .deepPink .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child {
          font-family: "Segoe UI Semi Bold";
          margin-bottom: 0; }
          .deepPink .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child span {
            color: #adadad; }
      .deepPink .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .rcs-custom-scroll .rcs-inner-container > div {
        padding: 0; }
  .deepPink .chatInfoWrapper .tabContent.responseWrapper .panelWrapper {
    border-top-right-radius: 0;
    border-top-left-radius: 0; }
  .deepPink .chatInfoWrapper .tabContent.responseWrapper .searchForm {
    padding: 0 20px; }
    .deepPink .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input {
      padding-right: 27px;
      background-repeat: no-repeat;
      background-position: 97%;
      background-image: url("assets/images/search_icon/search_pink.png") !important; }
      .deepPink .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-webkit-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .deepPink .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-ms-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .deepPink .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
    .deepPink .chatInfoWrapper .tabContent.responseWrapper .searchForm p {
      color: #adadad;
      font-family: "Segoe UI";
      font-size: 14px;
      font-weight: 400; }
      .deepPink .chatInfoWrapper .tabContent.responseWrapper .searchForm p span {
        color: #010101;
        font-family: "Segoe UI Semi Bold";
        font-weight: 600; }
  .deepPink .chatInfoWrapper .tabContent.responseWrapper .ruleComponent {
    padding: 15px 20px; }
    .deepPink .chatInfoWrapper .tabContent.responseWrapper .ruleComponent > div:first-child {
      max-width: -webkit-max-content;
      max-width: -moz-max-content;
      max-width: max-content; }
    .deepPink .chatInfoWrapper .tabContent.responseWrapper .ruleComponent .rule_data p.ques {
      font-family: "Segoe UI Semi Bold";
      font-weight: 600; }

.brown .editorInline > div {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none; }
  .brown .editorInline > div img {
    display: none; }
  .brown .editorInline > div:first-child {
    background-image: url("assets/images/bold_Icons/bold_brown2.png") !important; }
  .brown .editorInline > div:nth-child(2) {
    background-image: url("assets/images/Italic_icons/Italic_brown.png") !important; }
  .brown .editorInline > div:nth-child(3) {
    background-image: url("assets/images/Underline_icons/line_brown.png") !important; }

.brown .editorEmoji {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/smily_icons/smiling-brown.png") !important; }
  .brown .editorEmoji img {
    display: none; }

.brown .overview ul {
  list-style-image: url("assets/images/list_icon/listicon_brown.png") !important; }
  .brown .overview ul li {
    padding-left: 8px; }

.brown .editorAttachImg {
  margin-left: 5px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/attachment_icon/attachmant_brown.png") !important; }

.brown .innercontent_wrapper .box .searchText input {
  padding-right: 27px;
  background-repeat: no-repeat;
  background-position: 97%;
  background-image: url("assets/images/search_icon/search_brown.png") !important; }

.brown .textEditorLink {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/link_icons/link_brown.png") !important; }
  .brown .textEditorLink img {
    display: none; }

.brown .editorLink {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/link_icons/link_brown.png") !important; }
  .brown .editorLink img {
    display: none; }

.brown .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1) {
  background-image: url("assets/images/infotab.png"); }
  .brown .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1).active {
    background-image: url("assets/images/info_icon/info_brown.png") !important; }

.brown .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2) {
  background-image: url("assets/images/footstepstab.png"); }
  .brown .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2).active {
    background-image: url("assets/images/footstep_icon/footstep_brown.png") !important; }

.brown .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3) {
  background-image: url("assets/images/historytab.png"); }
  .brown .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3).active {
    background-image: url("assets/images/history_Icon/history_brown.png") !important; }

.brown .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4) {
  border-top-right-radius: 10px;
  background-image: url("assets/images/responsetab.png"); }
  .brown .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4).active {
    background-image: url("assets/images/response_chat/brown.png") !important; }

.brown .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
  color: #3ba8c5;
  background-color: #fff; }

.brown .chatInfoWrapper .tab-content {
  position: relative; }
  .brown .chatInfoWrapper .tab-content p.hide_btn {
    color: #3ba8c5;
    font-family: "Segoe UI Bold";
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    width: 100%;
    margin: 0;
    top: -7px; }
    .brown .chatInfoWrapper .tab-content p.hide_btn span {
      width: 70px;
      display: inline-block;
      background-color: #fff;
      cursor: pointer; }
      .brown .chatInfoWrapper .tab-content p.hide_btn span img {
        margin-right: 8px;
        margin-top: -2px; }

.brown .chatInfoWrapper .tabContent {
  text-align: left;
  margin-top: 24px;
  padding-top: 35px;
  border-top: 1px solid #dedede;
  color: #000000;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }
  .brown .chatInfoWrapper .tabContent h6 {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    text-align: left;
    margin: 0 20px 20px; }
  .brown .chatInfoWrapper .tabContent .data p {
    margin-bottom: 10px;
    margin-top: 0; }
  .brown .chatInfoWrapper .tabContent .data .label {
    padding-right: 0; }
    .brown .chatInfoWrapper .tabContent .data .label p {
      margin-left: 6px;
      font-family: "Segoe UI Semi Bold";
      font-size: 14px; }
  .brown .chatInfoWrapper .tabContent .data .value p {
    margin-right: 6px;
    font-family: "Segoe UI";
    font-size: 14px; }
  .brown .chatInfoWrapper .tabContent .data .webInfo {
    padding: 25px 20px;
    background-color: #ebf6f9;
    margin-bottom: 20px;
    margin-top: 15px; }
    .brown .chatInfoWrapper .tabContent .data .webInfo > p {
      font-family: "Segoe UI Semi Bold";
      margin-bottom: 20px;
      margin-top: 0;
      font-size: 14px; }
    .brown .chatInfoWrapper .tabContent .data .webInfo .row:last-child p {
      margin-bottom: 0; }
    .brown .chatInfoWrapper .tabContent .data .webInfo .label p {
      margin-left: 0; }
  .brown .chatInfoWrapper .tabContent .data .notes {
    padding: 25px 20px;
    background-color: #ebf6f9;
    border-radius: 0 0 10px 10px;
    margin-top: 12px; }
    .brown .chatInfoWrapper .tabContent .data .notes h6 {
      color: #293037;
      font-family: "Segoe UI Bold";
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      margin-left: 0;
      margin-right: 0; }
    .brown .chatInfoWrapper .tabContent .data .notes button {
      background-color: transparent; }
      .brown .chatInfoWrapper .tabContent .data .notes button:hover {
        color: inherit; }
    .brown .chatInfoWrapper .tabContent .data .notes .notesData {
      margin-bottom: 27px; }
      .brown .chatInfoWrapper .tabContent .data .notes .notesData p {
        width: 100%; }
  .brown .chatInfoWrapper .tabContent .data .rowLayout .value p {
    margin: 0 6px; }
  .brown .chatInfoWrapper .tabContent .data .rowLayout .link {
    color: #3ba8c5; }
    .brown .chatInfoWrapper .tabContent .data .rowLayout .link img {
      margin-left: 8px; }
  .brown .chatInfoWrapper .tabContent .data .rowLayout .disabled {
    color: #adadad; }
  .brown .chatInfoWrapper .tabContent .data .withImage img {
    margin-left: 8px;
    margin-top: -3px; }
  .brown .chatInfoWrapper .tabContent .footstepsWrapper {
    padding-bottom: 35px; }
    .brown .chatInfoWrapper .tabContent .footstepsWrapper .link {
      color: #3ba8c5; }
      .brown .chatInfoWrapper .tabContent .footstepsWrapper .link img {
        margin-left: 8px; }
  .brown .chatInfoWrapper .tabContent .historyWrapper {
    padding-bottom: 40px; }
    .brown .chatInfoWrapper .tabContent .historyWrapper > div > div.label, .brown .chatInfoWrapper .tabContent .historyWrapper > div > div.value {
      cursor: pointer; }
    .brown .chatInfoWrapper .tabContent .historyWrapper .label p {
      font-size: 12px; }
      .brown .chatInfoWrapper .tabContent .historyWrapper .label p span {
        color: #adadad; }
    .brown .chatInfoWrapper .tabContent .historyWrapper .value p {
      color: #3ba8c5;
      font-size: 14px; }
    .brown .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails {
      padding-top: 25px;
      padding-bottom: 0;
      max-height: 60vh;
      margin-bottom: 20px; }
      .brown .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails p {
        font-size: 14px; }
      .brown .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .value p {
        color: #000000; }
      .brown .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails > img {
        margin-top: -15px;
        margin-right: -9px;
        cursor: pointer; }
      .brown .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript h6 {
        color: #293037;
        font-family: "Segoe UI Bold";
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 20px 6px 15px 22px; }
      .brown .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p {
        margin-left: 6px; }
        .brown .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child {
          font-family: "Segoe UI Semi Bold";
          margin-bottom: 0; }
          .brown .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child span {
            color: #adadad; }
      .brown .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .rcs-custom-scroll .rcs-inner-container > div {
        padding: 0; }
  .brown .chatInfoWrapper .tabContent.responseWrapper .panelWrapper {
    border-top-right-radius: 0;
    border-top-left-radius: 0; }
  .brown .chatInfoWrapper .tabContent.responseWrapper .searchForm {
    padding: 0 20px; }
    .brown .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input {
      padding-right: 27px;
      background-repeat: no-repeat;
      background-position: 97%;
      background-image: url("assets/images/search_icon/search_brown.png") !important; }
      .brown .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-webkit-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .brown .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-ms-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .brown .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
    .brown .chatInfoWrapper .tabContent.responseWrapper .searchForm p {
      color: #adadad;
      font-family: "Segoe UI";
      font-size: 14px;
      font-weight: 400; }
      .brown .chatInfoWrapper .tabContent.responseWrapper .searchForm p span {
        color: #010101;
        font-family: "Segoe UI Semi Bold";
        font-weight: 600; }
  .brown .chatInfoWrapper .tabContent.responseWrapper .ruleComponent {
    padding: 15px 20px; }
    .brown .chatInfoWrapper .tabContent.responseWrapper .ruleComponent > div:first-child {
      max-width: -webkit-max-content;
      max-width: -moz-max-content;
      max-width: max-content; }
    .brown .chatInfoWrapper .tabContent.responseWrapper .ruleComponent .rule_data p.ques {
      font-family: "Segoe UI Semi Bold";
      font-weight: 600; }

.navyBlue .editorInline > div {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none; }
  .navyBlue .editorInline > div img {
    display: none; }
  .navyBlue .editorInline > div:first-child {
    background-image: url("assets/images/bold_Icons/bold_navyblue2.png") !important; }
  .navyBlue .editorInline > div:nth-child(2) {
    background-image: url("assets/images/Italic_icons/Italic_navyblue.png") !important; }
  .navyBlue .editorInline > div:nth-child(3) {
    background-image: url("assets/images/Underline_icons/line_navyblue.png") !important; }

.navyBlue .editorEmoji {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/smily_icons/smiling-navyblue.png") !important; }
  .navyBlue .editorEmoji img {
    display: none; }

.navyBlue .overview ul {
  list-style-image: url("assets/images/list_icon/listicon_navyblue.png") !important; }
  .navyBlue .overview ul li {
    padding-left: 8px; }

.navyBlue .editorAttachImg {
  margin-left: 5px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/attachment_icon/attachmant_navyblue.png") !important; }

.navyBlue .innercontent_wrapper .box .searchText input {
  padding-right: 27px;
  background-repeat: no-repeat;
  background-position: 97%;
  background-image: url("assets/images/search_icon/search_navyblue.png") !important; }

.navyBlue .textEditorLink {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/link_icons/link_navyblue.png") !important; }
  .navyBlue .textEditorLink img {
    display: none; }

.navyBlue .editorLink {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/link_icons/link_navyblue.png") !important; }
  .navyBlue .editorLink img {
    display: none; }

.navyBlue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1) {
  background-image: url("assets/images/infotab.png"); }
  .navyBlue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1).active {
    background-image: url("assets/images/info_icon/info_navyblue.png") !important; }

.navyBlue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2) {
  background-image: url("assets/images/footstepstab.png"); }
  .navyBlue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2).active {
    background-image: url("assets/images/footstep_icon/footstep_navyblue.png") !important; }

.navyBlue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3) {
  background-image: url("assets/images/historytab.png"); }
  .navyBlue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3).active {
    background-image: url("assets/images/history_Icon/history_navyblue.png") !important; }

.navyBlue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4) {
  border-top-right-radius: 10px;
  background-image: url("assets/images/responsetab.png"); }
  .navyBlue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4).active {
    background-image: url("assets/images/response_chat/navyBlue.png") !important; }

.navyBlue .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
  color: #3ba8c5;
  background-color: #fff; }

.navyBlue .chatInfoWrapper .tab-content {
  position: relative; }
  .navyBlue .chatInfoWrapper .tab-content p.hide_btn {
    color: #3ba8c5;
    font-family: "Segoe UI Bold";
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    width: 100%;
    margin: 0;
    top: -7px; }
    .navyBlue .chatInfoWrapper .tab-content p.hide_btn span {
      width: 70px;
      display: inline-block;
      background-color: #fff;
      cursor: pointer; }
      .navyBlue .chatInfoWrapper .tab-content p.hide_btn span img {
        margin-right: 8px;
        margin-top: -2px; }

.navyBlue .chatInfoWrapper .tabContent {
  text-align: left;
  margin-top: 24px;
  padding-top: 35px;
  border-top: 1px solid #dedede;
  color: #000000;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }
  .navyBlue .chatInfoWrapper .tabContent h6 {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    text-align: left;
    margin: 0 20px 20px; }
  .navyBlue .chatInfoWrapper .tabContent .data p {
    margin-bottom: 10px;
    margin-top: 0; }
  .navyBlue .chatInfoWrapper .tabContent .data .label {
    padding-right: 0; }
    .navyBlue .chatInfoWrapper .tabContent .data .label p {
      margin-left: 6px;
      font-family: "Segoe UI Semi Bold";
      font-size: 14px; }
  .navyBlue .chatInfoWrapper .tabContent .data .value p {
    margin-right: 6px;
    font-family: "Segoe UI";
    font-size: 14px; }
  .navyBlue .chatInfoWrapper .tabContent .data .webInfo {
    padding: 25px 20px;
    background-color: #ebf6f9;
    margin-bottom: 20px;
    margin-top: 15px; }
    .navyBlue .chatInfoWrapper .tabContent .data .webInfo > p {
      font-family: "Segoe UI Semi Bold";
      margin-bottom: 20px;
      margin-top: 0;
      font-size: 14px; }
    .navyBlue .chatInfoWrapper .tabContent .data .webInfo .row:last-child p {
      margin-bottom: 0; }
    .navyBlue .chatInfoWrapper .tabContent .data .webInfo .label p {
      margin-left: 0; }
  .navyBlue .chatInfoWrapper .tabContent .data .notes {
    padding: 25px 20px;
    background-color: #ebf6f9;
    border-radius: 0 0 10px 10px;
    margin-top: 12px; }
    .navyBlue .chatInfoWrapper .tabContent .data .notes h6 {
      color: #293037;
      font-family: "Segoe UI Bold";
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      margin-left: 0;
      margin-right: 0; }
    .navyBlue .chatInfoWrapper .tabContent .data .notes button {
      background-color: transparent; }
      .navyBlue .chatInfoWrapper .tabContent .data .notes button:hover {
        color: inherit; }
    .navyBlue .chatInfoWrapper .tabContent .data .notes .notesData {
      margin-bottom: 27px; }
      .navyBlue .chatInfoWrapper .tabContent .data .notes .notesData p {
        width: 100%; }
  .navyBlue .chatInfoWrapper .tabContent .data .rowLayout .value p {
    margin: 0 6px; }
  .navyBlue .chatInfoWrapper .tabContent .data .rowLayout .link {
    color: #3ba8c5; }
    .navyBlue .chatInfoWrapper .tabContent .data .rowLayout .link img {
      margin-left: 8px; }
  .navyBlue .chatInfoWrapper .tabContent .data .rowLayout .disabled {
    color: #adadad; }
  .navyBlue .chatInfoWrapper .tabContent .data .withImage img {
    margin-left: 8px;
    margin-top: -3px; }
  .navyBlue .chatInfoWrapper .tabContent .footstepsWrapper {
    padding-bottom: 35px; }
    .navyBlue .chatInfoWrapper .tabContent .footstepsWrapper .link {
      color: #3ba8c5; }
      .navyBlue .chatInfoWrapper .tabContent .footstepsWrapper .link img {
        margin-left: 8px; }
  .navyBlue .chatInfoWrapper .tabContent .historyWrapper {
    padding-bottom: 40px; }
    .navyBlue .chatInfoWrapper .tabContent .historyWrapper > div > div.label, .navyBlue .chatInfoWrapper .tabContent .historyWrapper > div > div.value {
      cursor: pointer; }
    .navyBlue .chatInfoWrapper .tabContent .historyWrapper .label p {
      font-size: 12px; }
      .navyBlue .chatInfoWrapper .tabContent .historyWrapper .label p span {
        color: #adadad; }
    .navyBlue .chatInfoWrapper .tabContent .historyWrapper .value p {
      color: #3ba8c5;
      font-size: 14px; }
    .navyBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails {
      padding-top: 25px;
      padding-bottom: 0;
      max-height: 60vh;
      margin-bottom: 20px; }
      .navyBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails p {
        font-size: 14px; }
      .navyBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .value p {
        color: #000000; }
      .navyBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails > img {
        margin-top: -15px;
        margin-right: -9px;
        cursor: pointer; }
      .navyBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript h6 {
        color: #293037;
        font-family: "Segoe UI Bold";
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 20px 6px 15px 22px; }
      .navyBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p {
        margin-left: 6px; }
        .navyBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child {
          font-family: "Segoe UI Semi Bold";
          margin-bottom: 0; }
          .navyBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child span {
            color: #adadad; }
      .navyBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .rcs-custom-scroll .rcs-inner-container > div {
        padding: 0; }
  .navyBlue .chatInfoWrapper .tabContent.responseWrapper .panelWrapper {
    border-top-right-radius: 0;
    border-top-left-radius: 0; }
  .navyBlue .chatInfoWrapper .tabContent.responseWrapper .searchForm {
    padding: 0 20px; }
    .navyBlue .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input {
      padding-right: 27px;
      background-repeat: no-repeat;
      background-position: 97%;
      background-image: url("assets/images/search_icon/search_navyblue.png") !important; }
      .navyBlue .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-webkit-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .navyBlue .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-ms-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .navyBlue .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
    .navyBlue .chatInfoWrapper .tabContent.responseWrapper .searchForm p {
      color: #adadad;
      font-family: "Segoe UI";
      font-size: 14px;
      font-weight: 400; }
      .navyBlue .chatInfoWrapper .tabContent.responseWrapper .searchForm p span {
        color: #010101;
        font-family: "Segoe UI Semi Bold";
        font-weight: 600; }
  .navyBlue .chatInfoWrapper .tabContent.responseWrapper .ruleComponent {
    padding: 15px 20px; }
    .navyBlue .chatInfoWrapper .tabContent.responseWrapper .ruleComponent > div:first-child {
      max-width: -webkit-max-content;
      max-width: -moz-max-content;
      max-width: max-content; }
    .navyBlue .chatInfoWrapper .tabContent.responseWrapper .ruleComponent .rule_data p.ques {
      font-family: "Segoe UI Semi Bold";
      font-weight: 600; }

.mustardYellow .editorInline > div {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none; }
  .mustardYellow .editorInline > div img {
    display: none; }
  .mustardYellow .editorInline > div:first-child {
    background-image: url("assets/images/bold_Icons/bold_yellow2.png") !important; }
  .mustardYellow .editorInline > div:nth-child(2) {
    background-image: url("assets/images/Italic_icons/Italic_yellow.png") !important; }
  .mustardYellow .editorInline > div:nth-child(3) {
    background-image: url("assets/images/Underline_icons/line_yellow.png") !important; }

.mustardYellow .editorEmoji {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/smily_icons/smiling-yellow.png") !important; }
  .mustardYellow .editorEmoji img {
    display: none; }

.mustardYellow .overview ul {
  list-style-image: url("assets/images/list_icon/listicon_yellow.png") !important; }
  .mustardYellow .overview ul li {
    padding-left: 8px; }

.mustardYellow .editorAttachImg {
  margin-left: 5px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/attachment_icon/attachmant_yellow.png") !important; }

.mustardYellow .innercontent_wrapper .box .searchText input {
  padding-right: 27px;
  background-repeat: no-repeat;
  background-position: 97%;
  background-image: url("assets/images/search_icon/search_yellow.png") !important; }

.mustardYellow .textEditorLink {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/link_icons/link_yellow.png") !important; }
  .mustardYellow .textEditorLink img {
    display: none; }

.mustardYellow .editorLink {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/link_icons/link_yellow.png") !important; }
  .mustardYellow .editorLink img {
    display: none; }

.mustardYellow .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1) {
  background-image: url("assets/images/infotab.png"); }
  .mustardYellow .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1).active {
    background-image: url("assets/images/info_icon/info_yellow.png") !important; }

.mustardYellow .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2) {
  background-image: url("assets/images/footstepstab.png"); }
  .mustardYellow .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2).active {
    background-image: url("assets/images/footstep_icon/footstep_yellow.png") !important; }

.mustardYellow .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3) {
  background-image: url("assets/images/historytab.png"); }
  .mustardYellow .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3).active {
    background-image: url("assets/images/history_Icon/history_yellow.png") !important; }

.mustardYellow .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4) {
  border-top-right-radius: 10px;
  background-image: url("assets/images/responsetab.png"); }
  .mustardYellow .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4).active {
    background-image: url("assets/images/response_chat/mustardYellow.png") !important; }

.mustardYellow .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
  color: #3ba8c5;
  background-color: #fff; }

.mustardYellow .chatInfoWrapper .tab-content {
  position: relative; }
  .mustardYellow .chatInfoWrapper .tab-content p.hide_btn {
    color: #3ba8c5;
    font-family: "Segoe UI Bold";
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    width: 100%;
    margin: 0;
    top: -7px; }
    .mustardYellow .chatInfoWrapper .tab-content p.hide_btn span {
      width: 70px;
      display: inline-block;
      background-color: #fff;
      cursor: pointer; }
      .mustardYellow .chatInfoWrapper .tab-content p.hide_btn span img {
        margin-right: 8px;
        margin-top: -2px; }

.mustardYellow .chatInfoWrapper .tabContent {
  text-align: left;
  margin-top: 24px;
  padding-top: 35px;
  border-top: 1px solid #dedede;
  color: #000000;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }
  .mustardYellow .chatInfoWrapper .tabContent h6 {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    text-align: left;
    margin: 0 20px 20px; }
  .mustardYellow .chatInfoWrapper .tabContent .data p {
    margin-bottom: 10px;
    margin-top: 0; }
  .mustardYellow .chatInfoWrapper .tabContent .data .label {
    padding-right: 0; }
    .mustardYellow .chatInfoWrapper .tabContent .data .label p {
      margin-left: 6px;
      font-family: "Segoe UI Semi Bold";
      font-size: 14px; }
  .mustardYellow .chatInfoWrapper .tabContent .data .value p {
    margin-right: 6px;
    font-family: "Segoe UI";
    font-size: 14px; }
  .mustardYellow .chatInfoWrapper .tabContent .data .webInfo {
    padding: 25px 20px;
    background-color: #ebf6f9;
    margin-bottom: 20px;
    margin-top: 15px; }
    .mustardYellow .chatInfoWrapper .tabContent .data .webInfo > p {
      font-family: "Segoe UI Semi Bold";
      margin-bottom: 20px;
      margin-top: 0;
      font-size: 14px; }
    .mustardYellow .chatInfoWrapper .tabContent .data .webInfo .row:last-child p {
      margin-bottom: 0; }
    .mustardYellow .chatInfoWrapper .tabContent .data .webInfo .label p {
      margin-left: 0; }
  .mustardYellow .chatInfoWrapper .tabContent .data .notes {
    padding: 25px 20px;
    background-color: #ebf6f9;
    border-radius: 0 0 10px 10px;
    margin-top: 12px; }
    .mustardYellow .chatInfoWrapper .tabContent .data .notes h6 {
      color: #293037;
      font-family: "Segoe UI Bold";
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      margin-left: 0;
      margin-right: 0; }
    .mustardYellow .chatInfoWrapper .tabContent .data .notes button {
      background-color: transparent; }
      .mustardYellow .chatInfoWrapper .tabContent .data .notes button:hover {
        color: inherit; }
    .mustardYellow .chatInfoWrapper .tabContent .data .notes .notesData {
      margin-bottom: 27px; }
      .mustardYellow .chatInfoWrapper .tabContent .data .notes .notesData p {
        width: 100%; }
  .mustardYellow .chatInfoWrapper .tabContent .data .rowLayout .value p {
    margin: 0 6px; }
  .mustardYellow .chatInfoWrapper .tabContent .data .rowLayout .link {
    color: #3ba8c5; }
    .mustardYellow .chatInfoWrapper .tabContent .data .rowLayout .link img {
      margin-left: 8px; }
  .mustardYellow .chatInfoWrapper .tabContent .data .rowLayout .disabled {
    color: #adadad; }
  .mustardYellow .chatInfoWrapper .tabContent .data .withImage img {
    margin-left: 8px;
    margin-top: -3px; }
  .mustardYellow .chatInfoWrapper .tabContent .footstepsWrapper {
    padding-bottom: 35px; }
    .mustardYellow .chatInfoWrapper .tabContent .footstepsWrapper .link {
      color: #3ba8c5; }
      .mustardYellow .chatInfoWrapper .tabContent .footstepsWrapper .link img {
        margin-left: 8px; }
  .mustardYellow .chatInfoWrapper .tabContent .historyWrapper {
    padding-bottom: 40px; }
    .mustardYellow .chatInfoWrapper .tabContent .historyWrapper > div > div.label, .mustardYellow .chatInfoWrapper .tabContent .historyWrapper > div > div.value {
      cursor: pointer; }
    .mustardYellow .chatInfoWrapper .tabContent .historyWrapper .label p {
      font-size: 12px; }
      .mustardYellow .chatInfoWrapper .tabContent .historyWrapper .label p span {
        color: #adadad; }
    .mustardYellow .chatInfoWrapper .tabContent .historyWrapper .value p {
      color: #3ba8c5;
      font-size: 14px; }
    .mustardYellow .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails {
      padding-top: 25px;
      padding-bottom: 0;
      max-height: 60vh;
      margin-bottom: 20px; }
      .mustardYellow .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails p {
        font-size: 14px; }
      .mustardYellow .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .value p {
        color: #000000; }
      .mustardYellow .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails > img {
        margin-top: -15px;
        margin-right: -9px;
        cursor: pointer; }
      .mustardYellow .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript h6 {
        color: #293037;
        font-family: "Segoe UI Bold";
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 20px 6px 15px 22px; }
      .mustardYellow .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p {
        margin-left: 6px; }
        .mustardYellow .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child {
          font-family: "Segoe UI Semi Bold";
          margin-bottom: 0; }
          .mustardYellow .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child span {
            color: #adadad; }
      .mustardYellow .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .rcs-custom-scroll .rcs-inner-container > div {
        padding: 0; }
  .mustardYellow .chatInfoWrapper .tabContent.responseWrapper .panelWrapper {
    border-top-right-radius: 0;
    border-top-left-radius: 0; }
  .mustardYellow .chatInfoWrapper .tabContent.responseWrapper .searchForm {
    padding: 0 20px; }
    .mustardYellow .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input {
      padding-right: 27px;
      background-repeat: no-repeat;
      background-position: 97%;
      background-image: url("assets/images/search_icon/search_yellow.png") !important; }
      .mustardYellow .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-webkit-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .mustardYellow .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-ms-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .mustardYellow .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
    .mustardYellow .chatInfoWrapper .tabContent.responseWrapper .searchForm p {
      color: #adadad;
      font-family: "Segoe UI";
      font-size: 14px;
      font-weight: 400; }
      .mustardYellow .chatInfoWrapper .tabContent.responseWrapper .searchForm p span {
        color: #010101;
        font-family: "Segoe UI Semi Bold";
        font-weight: 600; }
  .mustardYellow .chatInfoWrapper .tabContent.responseWrapper .ruleComponent {
    padding: 15px 20px; }
    .mustardYellow .chatInfoWrapper .tabContent.responseWrapper .ruleComponent > div:first-child {
      max-width: -webkit-max-content;
      max-width: -moz-max-content;
      max-width: max-content; }
    .mustardYellow .chatInfoWrapper .tabContent.responseWrapper .ruleComponent .rule_data p.ques {
      font-family: "Segoe UI Semi Bold";
      font-weight: 600; }

.violet .editorInline > div {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none; }
  .violet .editorInline > div img {
    display: none; }
  .violet .editorInline > div:first-child {
    background-image: url("assets/images/bold_Icons/bold_voilet2.png") !important; }
  .violet .editorInline > div:nth-child(2) {
    background-image: url("assets/images/Italic_icons/Italic_voilet.png") !important; }
  .violet .editorInline > div:nth-child(3) {
    background-image: url("assets/images/Underline_icons/line_voilet.png") !important; }

.violet .editorEmoji {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/smily_icons/smiling-voilet.png") !important; }
  .violet .editorEmoji img {
    display: none; }

.violet .overview ul {
  list-style-image: url("assets/images/list_icon/listicon_voilet.png") !important; }
  .violet .overview ul li {
    padding-left: 8px; }

.violet .editorAttachImg {
  margin-left: 5px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/attachment_icon/attachmant_voilet.png") !important; }

.violet .innercontent_wrapper .box .searchText input {
  padding-right: 27px;
  background-repeat: no-repeat;
  background-position: 97%;
  background-image: url("assets/images/search_icon/search_voilet.png") !important; }

.violet .textEditorLink {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/link_icons/link_voilet.png") !important; }
  .violet .textEditorLink img {
    display: none; }

.violet .editorLink {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/link_icons/link_voilet.png") !important; }
  .violet .editorLink img {
    display: none; }

.violet .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1) {
  background-image: url("assets/images/infotab.png"); }
  .violet .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1).active {
    background-image: url("assets/images/info_icon/info_voilet.png") !important; }

.violet .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2) {
  background-image: url("assets/images/footstepstab.png"); }
  .violet .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2).active {
    background-image: url("assets/images/footstep_icon/footstep_voilet.png") !important; }

.violet .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3) {
  background-image: url("assets/images/historytab.png"); }
  .violet .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3).active {
    background-image: url("assets/images/history_Icon/history_voilet.png") !important; }

.violet .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4) {
  border-top-right-radius: 10px;
  background-image: url("assets/images/responsetab.png"); }
  .violet .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4).active {
    background-image: url("assets/images/response_chat/violet.png") !important; }

.violet .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
  color: #3ba8c5;
  background-color: #fff; }

.violet .chatInfoWrapper .tab-content {
  position: relative; }
  .violet .chatInfoWrapper .tab-content p.hide_btn {
    color: #3ba8c5;
    font-family: "Segoe UI Bold";
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    width: 100%;
    margin: 0;
    top: -7px; }
    .violet .chatInfoWrapper .tab-content p.hide_btn span {
      width: 70px;
      display: inline-block;
      background-color: #fff;
      cursor: pointer; }
      .violet .chatInfoWrapper .tab-content p.hide_btn span img {
        margin-right: 8px;
        margin-top: -2px; }

.violet .chatInfoWrapper .tabContent {
  text-align: left;
  margin-top: 24px;
  padding-top: 35px;
  border-top: 1px solid #dedede;
  color: #000000;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }
  .violet .chatInfoWrapper .tabContent h6 {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    text-align: left;
    margin: 0 20px 20px; }
  .violet .chatInfoWrapper .tabContent .data p {
    margin-bottom: 10px;
    margin-top: 0; }
  .violet .chatInfoWrapper .tabContent .data .label {
    padding-right: 0; }
    .violet .chatInfoWrapper .tabContent .data .label p {
      margin-left: 6px;
      font-family: "Segoe UI Semi Bold";
      font-size: 14px; }
  .violet .chatInfoWrapper .tabContent .data .value p {
    margin-right: 6px;
    font-family: "Segoe UI";
    font-size: 14px; }
  .violet .chatInfoWrapper .tabContent .data .webInfo {
    padding: 25px 20px;
    background-color: #ebf6f9;
    margin-bottom: 20px;
    margin-top: 15px; }
    .violet .chatInfoWrapper .tabContent .data .webInfo > p {
      font-family: "Segoe UI Semi Bold";
      margin-bottom: 20px;
      margin-top: 0;
      font-size: 14px; }
    .violet .chatInfoWrapper .tabContent .data .webInfo .row:last-child p {
      margin-bottom: 0; }
    .violet .chatInfoWrapper .tabContent .data .webInfo .label p {
      margin-left: 0; }
  .violet .chatInfoWrapper .tabContent .data .notes {
    padding: 25px 20px;
    background-color: #ebf6f9;
    border-radius: 0 0 10px 10px;
    margin-top: 12px; }
    .violet .chatInfoWrapper .tabContent .data .notes h6 {
      color: #293037;
      font-family: "Segoe UI Bold";
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      margin-left: 0;
      margin-right: 0; }
    .violet .chatInfoWrapper .tabContent .data .notes button {
      background-color: transparent; }
      .violet .chatInfoWrapper .tabContent .data .notes button:hover {
        color: inherit; }
    .violet .chatInfoWrapper .tabContent .data .notes .notesData {
      margin-bottom: 27px; }
      .violet .chatInfoWrapper .tabContent .data .notes .notesData p {
        width: 100%; }
  .violet .chatInfoWrapper .tabContent .data .rowLayout .value p {
    margin: 0 6px; }
  .violet .chatInfoWrapper .tabContent .data .rowLayout .link {
    color: #3ba8c5; }
    .violet .chatInfoWrapper .tabContent .data .rowLayout .link img {
      margin-left: 8px; }
  .violet .chatInfoWrapper .tabContent .data .rowLayout .disabled {
    color: #adadad; }
  .violet .chatInfoWrapper .tabContent .data .withImage img {
    margin-left: 8px;
    margin-top: -3px; }
  .violet .chatInfoWrapper .tabContent .footstepsWrapper {
    padding-bottom: 35px; }
    .violet .chatInfoWrapper .tabContent .footstepsWrapper .link {
      color: #3ba8c5; }
      .violet .chatInfoWrapper .tabContent .footstepsWrapper .link img {
        margin-left: 8px; }
  .violet .chatInfoWrapper .tabContent .historyWrapper {
    padding-bottom: 40px; }
    .violet .chatInfoWrapper .tabContent .historyWrapper > div > div.label, .violet .chatInfoWrapper .tabContent .historyWrapper > div > div.value {
      cursor: pointer; }
    .violet .chatInfoWrapper .tabContent .historyWrapper .label p {
      font-size: 12px; }
      .violet .chatInfoWrapper .tabContent .historyWrapper .label p span {
        color: #adadad; }
    .violet .chatInfoWrapper .tabContent .historyWrapper .value p {
      color: #3ba8c5;
      font-size: 14px; }
    .violet .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails {
      padding-top: 25px;
      padding-bottom: 0;
      max-height: 60vh;
      margin-bottom: 20px; }
      .violet .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails p {
        font-size: 14px; }
      .violet .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .value p {
        color: #000000; }
      .violet .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails > img {
        margin-top: -15px;
        margin-right: -9px;
        cursor: pointer; }
      .violet .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript h6 {
        color: #293037;
        font-family: "Segoe UI Bold";
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 20px 6px 15px 22px; }
      .violet .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p {
        margin-left: 6px; }
        .violet .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child {
          font-family: "Segoe UI Semi Bold";
          margin-bottom: 0; }
          .violet .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child span {
            color: #adadad; }
      .violet .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .rcs-custom-scroll .rcs-inner-container > div {
        padding: 0; }
  .violet .chatInfoWrapper .tabContent.responseWrapper .panelWrapper {
    border-top-right-radius: 0;
    border-top-left-radius: 0; }
  .violet .chatInfoWrapper .tabContent.responseWrapper .searchForm {
    padding: 0 20px; }
    .violet .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input {
      padding-right: 27px;
      background-repeat: no-repeat;
      background-position: 97%;
      background-image: url("assets/images/search_icon/search_voilet.png") !important; }
      .violet .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-webkit-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .violet .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-ms-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .violet .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
    .violet .chatInfoWrapper .tabContent.responseWrapper .searchForm p {
      color: #adadad;
      font-family: "Segoe UI";
      font-size: 14px;
      font-weight: 400; }
      .violet .chatInfoWrapper .tabContent.responseWrapper .searchForm p span {
        color: #010101;
        font-family: "Segoe UI Semi Bold";
        font-weight: 600; }
  .violet .chatInfoWrapper .tabContent.responseWrapper .ruleComponent {
    padding: 15px 20px; }
    .violet .chatInfoWrapper .tabContent.responseWrapper .ruleComponent > div:first-child {
      max-width: -webkit-max-content;
      max-width: -moz-max-content;
      max-width: max-content; }
    .violet .chatInfoWrapper .tabContent.responseWrapper .ruleComponent .rule_data p.ques {
      font-family: "Segoe UI Semi Bold";
      font-weight: 600; }

.blue .editorInline > div {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none; }
  .blue .editorInline > div img {
    display: none; }
  .blue .editorInline > div:first-child {
    background-image: url("assets/images/bold_Icons/bold_blue2.png") !important; }
  .blue .editorInline > div:nth-child(2) {
    background-image: url("assets/images/Italic_icons/Italic_blue.png") !important; }
  .blue .editorInline > div:nth-child(3) {
    background-image: url("assets/images/Underline_icons/line_blue.png") !important; }

.blue .editorEmoji {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/smily_icons/smiling-blue.png") !important; }
  .blue .editorEmoji img {
    display: none; }

.blue .overview ul {
  list-style-image: url("assets/images/list_icon/listicon_blue.png") !important; }
  .blue .overview ul li {
    padding-left: 8px; }

.blue .editorAttachImg {
  margin-left: 5px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/attachment_icon/attachmant_blue.png") !important; }

.blue .innercontent_wrapper .box .searchText input {
  padding-right: 27px;
  background-repeat: no-repeat;
  background-position: 97%;
  background-image: url("assets/images/search_icon/search_blue.png") !important; }

.blue .textEditorLink {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/link_icons/link_blue.png") !important; }
  .blue .textEditorLink img {
    display: none; }

.blue .editorLink {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/link_icons/link_blue.png") !important; }
  .blue .editorLink img {
    display: none; }

.blue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1) {
  background-image: url("assets/images/infotab.png"); }
  .blue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1).active {
    background-image: url("assets/images/info_icon/info_blue.png") !important; }

.blue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2) {
  background-image: url("assets/images/footstepstab.png"); }
  .blue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2).active {
    background-image: url("assets/images/footstep_icon/footstep_blue.png") !important; }

.blue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3) {
  background-image: url("assets/images/historytab.png"); }
  .blue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3).active {
    background-image: url("assets/images/history_Icon/history_blue.png") !important; }

.blue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4) {
  border-top-right-radius: 10px;
  background-image: url("assets/images/responsetab.png"); }
  .blue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4).active {
    background-image: url("assets/images/response_chat/blue.png") !important; }

.blue .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
  color: #3ba8c5;
  background-color: #fff; }

.blue .chatInfoWrapper .tab-content {
  position: relative; }
  .blue .chatInfoWrapper .tab-content p.hide_btn {
    color: #3ba8c5;
    font-family: "Segoe UI Bold";
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    width: 100%;
    margin: 0;
    top: -7px; }
    .blue .chatInfoWrapper .tab-content p.hide_btn span {
      width: 70px;
      display: inline-block;
      background-color: #fff;
      cursor: pointer; }
      .blue .chatInfoWrapper .tab-content p.hide_btn span img {
        margin-right: 8px;
        margin-top: -2px; }

.blue .chatInfoWrapper .tabContent {
  text-align: left;
  margin-top: 24px;
  padding-top: 35px;
  border-top: 1px solid #dedede;
  color: #000000;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }
  .blue .chatInfoWrapper .tabContent h6 {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    text-align: left;
    margin: 0 20px 20px; }
  .blue .chatInfoWrapper .tabContent .data p {
    margin-bottom: 10px;
    margin-top: 0; }
  .blue .chatInfoWrapper .tabContent .data .label {
    padding-right: 0; }
    .blue .chatInfoWrapper .tabContent .data .label p {
      margin-left: 6px;
      font-family: "Segoe UI Semi Bold";
      font-size: 14px; }
  .blue .chatInfoWrapper .tabContent .data .value p {
    margin-right: 6px;
    font-family: "Segoe UI";
    font-size: 14px; }
  .blue .chatInfoWrapper .tabContent .data .webInfo {
    padding: 25px 20px;
    background-color: #ebf6f9;
    margin-bottom: 20px;
    margin-top: 15px; }
    .blue .chatInfoWrapper .tabContent .data .webInfo > p {
      font-family: "Segoe UI Semi Bold";
      margin-bottom: 20px;
      margin-top: 0;
      font-size: 14px; }
    .blue .chatInfoWrapper .tabContent .data .webInfo .row:last-child p {
      margin-bottom: 0; }
    .blue .chatInfoWrapper .tabContent .data .webInfo .label p {
      margin-left: 0; }
  .blue .chatInfoWrapper .tabContent .data .notes {
    padding: 25px 20px;
    background-color: #ebf6f9;
    border-radius: 0 0 10px 10px;
    margin-top: 12px; }
    .blue .chatInfoWrapper .tabContent .data .notes h6 {
      color: #293037;
      font-family: "Segoe UI Bold";
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      margin-left: 0;
      margin-right: 0; }
    .blue .chatInfoWrapper .tabContent .data .notes button {
      background-color: transparent; }
      .blue .chatInfoWrapper .tabContent .data .notes button:hover {
        color: inherit; }
    .blue .chatInfoWrapper .tabContent .data .notes .notesData {
      margin-bottom: 27px; }
      .blue .chatInfoWrapper .tabContent .data .notes .notesData p {
        width: 100%; }
  .blue .chatInfoWrapper .tabContent .data .rowLayout .value p {
    margin: 0 6px; }
  .blue .chatInfoWrapper .tabContent .data .rowLayout .link {
    color: #3ba8c5; }
    .blue .chatInfoWrapper .tabContent .data .rowLayout .link img {
      margin-left: 8px; }
  .blue .chatInfoWrapper .tabContent .data .rowLayout .disabled {
    color: #adadad; }
  .blue .chatInfoWrapper .tabContent .data .withImage img {
    margin-left: 8px;
    margin-top: -3px; }
  .blue .chatInfoWrapper .tabContent .footstepsWrapper {
    padding-bottom: 35px; }
    .blue .chatInfoWrapper .tabContent .footstepsWrapper .link {
      color: #3ba8c5; }
      .blue .chatInfoWrapper .tabContent .footstepsWrapper .link img {
        margin-left: 8px; }
  .blue .chatInfoWrapper .tabContent .historyWrapper {
    padding-bottom: 40px; }
    .blue .chatInfoWrapper .tabContent .historyWrapper > div > div.label, .blue .chatInfoWrapper .tabContent .historyWrapper > div > div.value {
      cursor: pointer; }
    .blue .chatInfoWrapper .tabContent .historyWrapper .label p {
      font-size: 12px; }
      .blue .chatInfoWrapper .tabContent .historyWrapper .label p span {
        color: #adadad; }
    .blue .chatInfoWrapper .tabContent .historyWrapper .value p {
      color: #3ba8c5;
      font-size: 14px; }
    .blue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails {
      padding-top: 25px;
      padding-bottom: 0;
      max-height: 60vh;
      margin-bottom: 20px; }
      .blue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails p {
        font-size: 14px; }
      .blue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .value p {
        color: #000000; }
      .blue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails > img {
        margin-top: -15px;
        margin-right: -9px;
        cursor: pointer; }
      .blue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript h6 {
        color: #293037;
        font-family: "Segoe UI Bold";
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 20px 6px 15px 22px; }
      .blue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p {
        margin-left: 6px; }
        .blue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child {
          font-family: "Segoe UI Semi Bold";
          margin-bottom: 0; }
          .blue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child span {
            color: #adadad; }
      .blue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .rcs-custom-scroll .rcs-inner-container > div {
        padding: 0; }
  .blue .chatInfoWrapper .tabContent.responseWrapper .panelWrapper {
    border-top-right-radius: 0;
    border-top-left-radius: 0; }
  .blue .chatInfoWrapper .tabContent.responseWrapper .searchForm {
    padding: 0 20px; }
    .blue .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input {
      padding-right: 27px;
      background-repeat: no-repeat;
      background-position: 97%;
      background-image: url("assets/images/search_icon/search_blue.png") !important; }
      .blue .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-webkit-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .blue .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-ms-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .blue .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
    .blue .chatInfoWrapper .tabContent.responseWrapper .searchForm p {
      color: #adadad;
      font-family: "Segoe UI";
      font-size: 14px;
      font-weight: 400; }
      .blue .chatInfoWrapper .tabContent.responseWrapper .searchForm p span {
        color: #010101;
        font-family: "Segoe UI Semi Bold";
        font-weight: 600; }
  .blue .chatInfoWrapper .tabContent.responseWrapper .ruleComponent {
    padding: 15px 20px; }
    .blue .chatInfoWrapper .tabContent.responseWrapper .ruleComponent > div:first-child {
      max-width: -webkit-max-content;
      max-width: -moz-max-content;
      max-width: max-content; }
    .blue .chatInfoWrapper .tabContent.responseWrapper .ruleComponent .rule_data p.ques {
      font-family: "Segoe UI Semi Bold";
      font-weight: 600; }

.darkGreen .editorInline > div {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none; }
  .darkGreen .editorInline > div img {
    display: none; }
  .darkGreen .editorInline > div:first-child {
    background-image: url("assets/images/bold_Icons/bold_darkgreen2.png") !important; }
  .darkGreen .editorInline > div:nth-child(2) {
    background-image: url("assets/images/Italic_icons/Italic_darkgreen.png") !important; }
  .darkGreen .editorInline > div:nth-child(3) {
    background-image: url("assets/images/Underline_icons/line_darkgreen.png") !important; }

.darkGreen .editorEmoji {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/smily_icons/smiling-darkgreen.png") !important; }
  .darkGreen .editorEmoji img {
    display: none; }

.darkGreen .overview ul {
  list-style-image: url("assets/images/list_icon/listicon_darkgreen.png") !important; }
  .darkGreen .overview ul li {
    padding-left: 8px; }

.darkGreen .editorAttachImg {
  margin-left: 5px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/attachment_icon/attachmant_darkgreen.png") !important; }

.darkGreen .innercontent_wrapper .box .searchText input {
  padding-right: 27px;
  background-repeat: no-repeat;
  background-position: 97%;
  background-image: url("assets/images/search_icon/search_darkgreen.png") !important; }

.darkGreen .textEditorLink {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/link_icons/link_darkgreen.png") !important; }
  .darkGreen .textEditorLink img {
    display: none; }

.darkGreen .editorLink {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/link_icons/link_darkgreen.png") !important; }
  .darkGreen .editorLink img {
    display: none; }

.darkGreen .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1) {
  background-image: url("assets/images/infotab.png"); }
  .darkGreen .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1).active {
    background-image: url("assets/images/info_icon/info_darkgreen.png") !important; }

.darkGreen .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2) {
  background-image: url("assets/images/footstepstab.png"); }
  .darkGreen .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2).active {
    background-image: url("assets/images/footstep_icon/footstep_darkgreen.png") !important; }

.darkGreen .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3) {
  background-image: url("assets/images/historytab.png"); }
  .darkGreen .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3).active {
    background-image: url("assets/images/history_Icon/history_darkgreen.png") !important; }

.darkGreen .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4) {
  border-top-right-radius: 10px;
  background-image: url("assets/images/responsetab.png"); }
  .darkGreen .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4).active {
    background-image: url("assets/images/response_chat/darkGreen.png") !important; }

.darkGreen .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
  color: #3ba8c5;
  background-color: #fff; }

.darkGreen .chatInfoWrapper .tab-content {
  position: relative; }
  .darkGreen .chatInfoWrapper .tab-content p.hide_btn {
    color: #3ba8c5;
    font-family: "Segoe UI Bold";
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    width: 100%;
    margin: 0;
    top: -7px; }
    .darkGreen .chatInfoWrapper .tab-content p.hide_btn span {
      width: 70px;
      display: inline-block;
      background-color: #fff;
      cursor: pointer; }
      .darkGreen .chatInfoWrapper .tab-content p.hide_btn span img {
        margin-right: 8px;
        margin-top: -2px; }

.darkGreen .chatInfoWrapper .tabContent {
  text-align: left;
  margin-top: 24px;
  padding-top: 35px;
  border-top: 1px solid #dedede;
  color: #000000;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }
  .darkGreen .chatInfoWrapper .tabContent h6 {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    text-align: left;
    margin: 0 20px 20px; }
  .darkGreen .chatInfoWrapper .tabContent .data p {
    margin-bottom: 10px;
    margin-top: 0; }
  .darkGreen .chatInfoWrapper .tabContent .data .label {
    padding-right: 0; }
    .darkGreen .chatInfoWrapper .tabContent .data .label p {
      margin-left: 6px;
      font-family: "Segoe UI Semi Bold";
      font-size: 14px; }
  .darkGreen .chatInfoWrapper .tabContent .data .value p {
    margin-right: 6px;
    font-family: "Segoe UI";
    font-size: 14px; }
  .darkGreen .chatInfoWrapper .tabContent .data .webInfo {
    padding: 25px 20px;
    background-color: #ebf6f9;
    margin-bottom: 20px;
    margin-top: 15px; }
    .darkGreen .chatInfoWrapper .tabContent .data .webInfo > p {
      font-family: "Segoe UI Semi Bold";
      margin-bottom: 20px;
      margin-top: 0;
      font-size: 14px; }
    .darkGreen .chatInfoWrapper .tabContent .data .webInfo .row:last-child p {
      margin-bottom: 0; }
    .darkGreen .chatInfoWrapper .tabContent .data .webInfo .label p {
      margin-left: 0; }
  .darkGreen .chatInfoWrapper .tabContent .data .notes {
    padding: 25px 20px;
    background-color: #ebf6f9;
    border-radius: 0 0 10px 10px;
    margin-top: 12px; }
    .darkGreen .chatInfoWrapper .tabContent .data .notes h6 {
      color: #293037;
      font-family: "Segoe UI Bold";
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      margin-left: 0;
      margin-right: 0; }
    .darkGreen .chatInfoWrapper .tabContent .data .notes button {
      background-color: transparent; }
      .darkGreen .chatInfoWrapper .tabContent .data .notes button:hover {
        color: inherit; }
    .darkGreen .chatInfoWrapper .tabContent .data .notes .notesData {
      margin-bottom: 27px; }
      .darkGreen .chatInfoWrapper .tabContent .data .notes .notesData p {
        width: 100%; }
  .darkGreen .chatInfoWrapper .tabContent .data .rowLayout .value p {
    margin: 0 6px; }
  .darkGreen .chatInfoWrapper .tabContent .data .rowLayout .link {
    color: #3ba8c5; }
    .darkGreen .chatInfoWrapper .tabContent .data .rowLayout .link img {
      margin-left: 8px; }
  .darkGreen .chatInfoWrapper .tabContent .data .rowLayout .disabled {
    color: #adadad; }
  .darkGreen .chatInfoWrapper .tabContent .data .withImage img {
    margin-left: 8px;
    margin-top: -3px; }
  .darkGreen .chatInfoWrapper .tabContent .footstepsWrapper {
    padding-bottom: 35px; }
    .darkGreen .chatInfoWrapper .tabContent .footstepsWrapper .link {
      color: #3ba8c5; }
      .darkGreen .chatInfoWrapper .tabContent .footstepsWrapper .link img {
        margin-left: 8px; }
  .darkGreen .chatInfoWrapper .tabContent .historyWrapper {
    padding-bottom: 40px; }
    .darkGreen .chatInfoWrapper .tabContent .historyWrapper > div > div.label, .darkGreen .chatInfoWrapper .tabContent .historyWrapper > div > div.value {
      cursor: pointer; }
    .darkGreen .chatInfoWrapper .tabContent .historyWrapper .label p {
      font-size: 12px; }
      .darkGreen .chatInfoWrapper .tabContent .historyWrapper .label p span {
        color: #adadad; }
    .darkGreen .chatInfoWrapper .tabContent .historyWrapper .value p {
      color: #3ba8c5;
      font-size: 14px; }
    .darkGreen .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails {
      padding-top: 25px;
      padding-bottom: 0;
      max-height: 60vh;
      margin-bottom: 20px; }
      .darkGreen .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails p {
        font-size: 14px; }
      .darkGreen .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .value p {
        color: #000000; }
      .darkGreen .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails > img {
        margin-top: -15px;
        margin-right: -9px;
        cursor: pointer; }
      .darkGreen .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript h6 {
        color: #293037;
        font-family: "Segoe UI Bold";
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 20px 6px 15px 22px; }
      .darkGreen .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p {
        margin-left: 6px; }
        .darkGreen .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child {
          font-family: "Segoe UI Semi Bold";
          margin-bottom: 0; }
          .darkGreen .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child span {
            color: #adadad; }
      .darkGreen .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .rcs-custom-scroll .rcs-inner-container > div {
        padding: 0; }
  .darkGreen .chatInfoWrapper .tabContent.responseWrapper .panelWrapper {
    border-top-right-radius: 0;
    border-top-left-radius: 0; }
  .darkGreen .chatInfoWrapper .tabContent.responseWrapper .searchForm {
    padding: 0 20px; }
    .darkGreen .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input {
      padding-right: 27px;
      background-repeat: no-repeat;
      background-position: 97%;
      background-image: url("assets/images/search_icon/search_darkgreen.png") !important; }
      .darkGreen .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-webkit-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .darkGreen .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-ms-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .darkGreen .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
    .darkGreen .chatInfoWrapper .tabContent.responseWrapper .searchForm p {
      color: #adadad;
      font-family: "Segoe UI";
      font-size: 14px;
      font-weight: 400; }
      .darkGreen .chatInfoWrapper .tabContent.responseWrapper .searchForm p span {
        color: #010101;
        font-family: "Segoe UI Semi Bold";
        font-weight: 600; }
  .darkGreen .chatInfoWrapper .tabContent.responseWrapper .ruleComponent {
    padding: 15px 20px; }
    .darkGreen .chatInfoWrapper .tabContent.responseWrapper .ruleComponent > div:first-child {
      max-width: -webkit-max-content;
      max-width: -moz-max-content;
      max-width: max-content; }
    .darkGreen .chatInfoWrapper .tabContent.responseWrapper .ruleComponent .rule_data p.ques {
      font-family: "Segoe UI Semi Bold";
      font-weight: 600; }

.green .editorInline > div {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none; }
  .green .editorInline > div img {
    display: none; }
  .green .editorInline > div:first-child {
    background-image: url("assets/images/bold_Icons/bold_green2.png") !important; }
  .green .editorInline > div:nth-child(2) {
    background-image: url("assets/images/Italic_icons/Italic_green.png") !important; }
  .green .editorInline > div:nth-child(3) {
    background-image: url("assets/images/Underline_icons/line_green.png") !important; }

.green .editorEmoji {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/smily_icons/smiling-green.png") !important; }
  .green .editorEmoji img {
    display: none; }

.green .overview ul {
  list-style-image: url("assets/images/list_icon/listicon_green.png") !important; }
  .green .overview ul li {
    padding-left: 8px; }

.green .editorAttachImg {
  margin-left: 5px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/attachment_icon/attachmant_green.png") !important; }

.green .innercontent_wrapper .box .searchText input {
  padding-right: 27px;
  background-repeat: no-repeat;
  background-position: 97%;
  background-image: url("assets/images/search_icon/search_green.png") !important; }

.green .textEditorLink {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/link_icons/link_green.png") !important; }
  .green .textEditorLink img {
    display: none; }

.green .editorLink {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/link_icons/link_green.png") !important; }
  .green .editorLink img {
    display: none; }

.green .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1) {
  background-image: url("assets/images/infotab.png"); }
  .green .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1).active {
    background-image: url("assets/images/info_icon/info_green.png") !important; }

.green .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2) {
  background-image: url("assets/images/footstepstab.png"); }
  .green .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2).active {
    background-image: url("assets/images/footstep_icon/footstep_green.png") !important; }

.green .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3) {
  background-image: url("assets/images/historytab.png"); }
  .green .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3).active {
    background-image: url("assets/images/history_Icon/history_green.png") !important; }

.green .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4) {
  border-top-right-radius: 10px;
  background-image: url("assets/images/responsetab.png"); }
  .green .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4).active {
    background-image: url("assets/images/response_chat/green.png") !important; }

.green .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
  color: #3ba8c5;
  background-color: #fff; }

.green .chatInfoWrapper .tab-content {
  position: relative; }
  .green .chatInfoWrapper .tab-content p.hide_btn {
    color: #3ba8c5;
    font-family: "Segoe UI Bold";
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    width: 100%;
    margin: 0;
    top: -7px; }
    .green .chatInfoWrapper .tab-content p.hide_btn span {
      width: 70px;
      display: inline-block;
      background-color: #fff;
      cursor: pointer; }
      .green .chatInfoWrapper .tab-content p.hide_btn span img {
        margin-right: 8px;
        margin-top: -2px; }

.green .chatInfoWrapper .tabContent {
  text-align: left;
  margin-top: 24px;
  padding-top: 35px;
  border-top: 1px solid #dedede;
  color: #000000;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }
  .green .chatInfoWrapper .tabContent h6 {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    text-align: left;
    margin: 0 20px 20px; }
  .green .chatInfoWrapper .tabContent .data p {
    margin-bottom: 10px;
    margin-top: 0; }
  .green .chatInfoWrapper .tabContent .data .label {
    padding-right: 0; }
    .green .chatInfoWrapper .tabContent .data .label p {
      margin-left: 6px;
      font-family: "Segoe UI Semi Bold";
      font-size: 14px; }
  .green .chatInfoWrapper .tabContent .data .value p {
    margin-right: 6px;
    font-family: "Segoe UI";
    font-size: 14px; }
  .green .chatInfoWrapper .tabContent .data .webInfo {
    padding: 25px 20px;
    background-color: #ebf6f9;
    margin-bottom: 20px;
    margin-top: 15px; }
    .green .chatInfoWrapper .tabContent .data .webInfo > p {
      font-family: "Segoe UI Semi Bold";
      margin-bottom: 20px;
      margin-top: 0;
      font-size: 14px; }
    .green .chatInfoWrapper .tabContent .data .webInfo .row:last-child p {
      margin-bottom: 0; }
    .green .chatInfoWrapper .tabContent .data .webInfo .label p {
      margin-left: 0; }
  .green .chatInfoWrapper .tabContent .data .notes {
    padding: 25px 20px;
    background-color: #ebf6f9;
    border-radius: 0 0 10px 10px;
    margin-top: 12px; }
    .green .chatInfoWrapper .tabContent .data .notes h6 {
      color: #293037;
      font-family: "Segoe UI Bold";
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      margin-left: 0;
      margin-right: 0; }
    .green .chatInfoWrapper .tabContent .data .notes button {
      background-color: transparent; }
      .green .chatInfoWrapper .tabContent .data .notes button:hover {
        color: inherit; }
    .green .chatInfoWrapper .tabContent .data .notes .notesData {
      margin-bottom: 27px; }
      .green .chatInfoWrapper .tabContent .data .notes .notesData p {
        width: 100%; }
  .green .chatInfoWrapper .tabContent .data .rowLayout .value p {
    margin: 0 6px; }
  .green .chatInfoWrapper .tabContent .data .rowLayout .link {
    color: #3ba8c5; }
    .green .chatInfoWrapper .tabContent .data .rowLayout .link img {
      margin-left: 8px; }
  .green .chatInfoWrapper .tabContent .data .rowLayout .disabled {
    color: #adadad; }
  .green .chatInfoWrapper .tabContent .data .withImage img {
    margin-left: 8px;
    margin-top: -3px; }
  .green .chatInfoWrapper .tabContent .footstepsWrapper {
    padding-bottom: 35px; }
    .green .chatInfoWrapper .tabContent .footstepsWrapper .link {
      color: #3ba8c5; }
      .green .chatInfoWrapper .tabContent .footstepsWrapper .link img {
        margin-left: 8px; }
  .green .chatInfoWrapper .tabContent .historyWrapper {
    padding-bottom: 40px; }
    .green .chatInfoWrapper .tabContent .historyWrapper > div > div.label, .green .chatInfoWrapper .tabContent .historyWrapper > div > div.value {
      cursor: pointer; }
    .green .chatInfoWrapper .tabContent .historyWrapper .label p {
      font-size: 12px; }
      .green .chatInfoWrapper .tabContent .historyWrapper .label p span {
        color: #adadad; }
    .green .chatInfoWrapper .tabContent .historyWrapper .value p {
      color: #3ba8c5;
      font-size: 14px; }
    .green .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails {
      padding-top: 25px;
      padding-bottom: 0;
      max-height: 60vh;
      margin-bottom: 20px; }
      .green .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails p {
        font-size: 14px; }
      .green .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .value p {
        color: #000000; }
      .green .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails > img {
        margin-top: -15px;
        margin-right: -9px;
        cursor: pointer; }
      .green .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript h6 {
        color: #293037;
        font-family: "Segoe UI Bold";
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 20px 6px 15px 22px; }
      .green .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p {
        margin-left: 6px; }
        .green .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child {
          font-family: "Segoe UI Semi Bold";
          margin-bottom: 0; }
          .green .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child span {
            color: #adadad; }
      .green .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .rcs-custom-scroll .rcs-inner-container > div {
        padding: 0; }
  .green .chatInfoWrapper .tabContent.responseWrapper .panelWrapper {
    border-top-right-radius: 0;
    border-top-left-radius: 0; }
  .green .chatInfoWrapper .tabContent.responseWrapper .searchForm {
    padding: 0 20px; }
    .green .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input {
      padding-right: 27px;
      background-repeat: no-repeat;
      background-position: 97%;
      background-image: url("assets/images/search_icon/search_green.png") !important; }
      .green .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-webkit-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .green .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-ms-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .green .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
    .green .chatInfoWrapper .tabContent.responseWrapper .searchForm p {
      color: #adadad;
      font-family: "Segoe UI";
      font-size: 14px;
      font-weight: 400; }
      .green .chatInfoWrapper .tabContent.responseWrapper .searchForm p span {
        color: #010101;
        font-family: "Segoe UI Semi Bold";
        font-weight: 600; }
  .green .chatInfoWrapper .tabContent.responseWrapper .ruleComponent {
    padding: 15px 20px; }
    .green .chatInfoWrapper .tabContent.responseWrapper .ruleComponent > div:first-child {
      max-width: -webkit-max-content;
      max-width: -moz-max-content;
      max-width: max-content; }
    .green .chatInfoWrapper .tabContent.responseWrapper .ruleComponent .rule_data p.ques {
      font-family: "Segoe UI Semi Bold";
      font-weight: 600; }

.grey .editorInline > div {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none; }
  .grey .editorInline > div img {
    display: none; }
  .grey .editorInline > div:first-child {
    background-image: url("assets/images/bold_Icons/bold_grey2.png") !important; }
  .grey .editorInline > div:nth-child(2) {
    background-image: url("assets/images/Italic_icons/Italic_grey.png") !important; }
  .grey .editorInline > div:nth-child(3) {
    background-image: url("assets/images/Underline_icons/line_grey.png") !important; }

.grey .editorEmoji {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/smily_icons/smiling-grey.png") !important; }
  .grey .editorEmoji img {
    display: none; }

.grey .overview ul {
  list-style-image: url("assets/images/list_icon/listicon_grey.png") !important; }
  .grey .overview ul li {
    padding-left: 8px; }

.grey .editorAttachImg {
  margin-left: 5px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/attachment_icon/attachmant_grey.png") !important; }

.grey .innercontent_wrapper .box .searchText input {
  padding-right: 27px;
  background-repeat: no-repeat;
  background-position: 97%;
  background-image: url("assets/images/search_icon/search_grey.png") !important; }

.grey .textEditorLink {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/link_icons/link_grey.png") !important; }
  .grey .textEditorLink img {
    display: none; }

.grey .editorLink {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/link_icons/link_grey.png") !important; }
  .grey .editorLink img {
    display: none; }

.grey .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1) {
  background-image: url("assets/images/infotab.png"); }
  .grey .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1).active {
    background-image: url("assets/images/info_icon/info_grey.png") !important; }

.grey .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2) {
  background-image: url("assets/images/footstepstab.png"); }
  .grey .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2).active {
    background-image: url("assets/images/footstep_icon/footstep_grey.png") !important; }

.grey .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3) {
  background-image: url("assets/images/historytab.png"); }
  .grey .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3).active {
    background-image: url("assets/images/history_Icon/history_grey.png") !important; }

.grey .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4) {
  border-top-right-radius: 10px;
  background-image: url("assets/images/responsetab.png"); }
  .grey .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4).active {
    background-image: url("assets/images/response_chat/grey.png") !important; }

.grey .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
  color: #3ba8c5;
  background-color: #fff; }

.grey .chatInfoWrapper .tab-content {
  position: relative; }
  .grey .chatInfoWrapper .tab-content p.hide_btn {
    color: #3ba8c5;
    font-family: "Segoe UI Bold";
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    width: 100%;
    margin: 0;
    top: -7px; }
    .grey .chatInfoWrapper .tab-content p.hide_btn span {
      width: 70px;
      display: inline-block;
      background-color: #fff;
      cursor: pointer; }
      .grey .chatInfoWrapper .tab-content p.hide_btn span img {
        margin-right: 8px;
        margin-top: -2px; }

.grey .chatInfoWrapper .tabContent {
  text-align: left;
  margin-top: 24px;
  padding-top: 35px;
  border-top: 1px solid #dedede;
  color: #000000;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }
  .grey .chatInfoWrapper .tabContent h6 {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    text-align: left;
    margin: 0 20px 20px; }
  .grey .chatInfoWrapper .tabContent .data p {
    margin-bottom: 10px;
    margin-top: 0; }
  .grey .chatInfoWrapper .tabContent .data .label {
    padding-right: 0; }
    .grey .chatInfoWrapper .tabContent .data .label p {
      margin-left: 6px;
      font-family: "Segoe UI Semi Bold";
      font-size: 14px; }
  .grey .chatInfoWrapper .tabContent .data .value p {
    margin-right: 6px;
    font-family: "Segoe UI";
    font-size: 14px; }
  .grey .chatInfoWrapper .tabContent .data .webInfo {
    padding: 25px 20px;
    background-color: #ebf6f9;
    margin-bottom: 20px;
    margin-top: 15px; }
    .grey .chatInfoWrapper .tabContent .data .webInfo > p {
      font-family: "Segoe UI Semi Bold";
      margin-bottom: 20px;
      margin-top: 0;
      font-size: 14px; }
    .grey .chatInfoWrapper .tabContent .data .webInfo .row:last-child p {
      margin-bottom: 0; }
    .grey .chatInfoWrapper .tabContent .data .webInfo .label p {
      margin-left: 0; }
  .grey .chatInfoWrapper .tabContent .data .notes {
    padding: 25px 20px;
    background-color: #ebf6f9;
    border-radius: 0 0 10px 10px;
    margin-top: 12px; }
    .grey .chatInfoWrapper .tabContent .data .notes h6 {
      color: #293037;
      font-family: "Segoe UI Bold";
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      margin-left: 0;
      margin-right: 0; }
    .grey .chatInfoWrapper .tabContent .data .notes button {
      background-color: transparent; }
      .grey .chatInfoWrapper .tabContent .data .notes button:hover {
        color: inherit; }
    .grey .chatInfoWrapper .tabContent .data .notes .notesData {
      margin-bottom: 27px; }
      .grey .chatInfoWrapper .tabContent .data .notes .notesData p {
        width: 100%; }
  .grey .chatInfoWrapper .tabContent .data .rowLayout .value p {
    margin: 0 6px; }
  .grey .chatInfoWrapper .tabContent .data .rowLayout .link {
    color: #3ba8c5; }
    .grey .chatInfoWrapper .tabContent .data .rowLayout .link img {
      margin-left: 8px; }
  .grey .chatInfoWrapper .tabContent .data .rowLayout .disabled {
    color: #adadad; }
  .grey .chatInfoWrapper .tabContent .data .withImage img {
    margin-left: 8px;
    margin-top: -3px; }
  .grey .chatInfoWrapper .tabContent .footstepsWrapper {
    padding-bottom: 35px; }
    .grey .chatInfoWrapper .tabContent .footstepsWrapper .link {
      color: #3ba8c5; }
      .grey .chatInfoWrapper .tabContent .footstepsWrapper .link img {
        margin-left: 8px; }
  .grey .chatInfoWrapper .tabContent .historyWrapper {
    padding-bottom: 40px; }
    .grey .chatInfoWrapper .tabContent .historyWrapper > div > div.label, .grey .chatInfoWrapper .tabContent .historyWrapper > div > div.value {
      cursor: pointer; }
    .grey .chatInfoWrapper .tabContent .historyWrapper .label p {
      font-size: 12px; }
      .grey .chatInfoWrapper .tabContent .historyWrapper .label p span {
        color: #adadad; }
    .grey .chatInfoWrapper .tabContent .historyWrapper .value p {
      color: #3ba8c5;
      font-size: 14px; }
    .grey .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails {
      padding-top: 25px;
      padding-bottom: 0;
      max-height: 60vh;
      margin-bottom: 20px; }
      .grey .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails p {
        font-size: 14px; }
      .grey .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .value p {
        color: #000000; }
      .grey .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails > img {
        margin-top: -15px;
        margin-right: -9px;
        cursor: pointer; }
      .grey .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript h6 {
        color: #293037;
        font-family: "Segoe UI Bold";
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 20px 6px 15px 22px; }
      .grey .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p {
        margin-left: 6px; }
        .grey .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child {
          font-family: "Segoe UI Semi Bold";
          margin-bottom: 0; }
          .grey .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child span {
            color: #adadad; }
      .grey .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .rcs-custom-scroll .rcs-inner-container > div {
        padding: 0; }
  .grey .chatInfoWrapper .tabContent.responseWrapper .panelWrapper {
    border-top-right-radius: 0;
    border-top-left-radius: 0; }
  .grey .chatInfoWrapper .tabContent.responseWrapper .searchForm {
    padding: 0 20px; }
    .grey .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input {
      padding-right: 27px;
      background-repeat: no-repeat;
      background-position: 97%;
      background-image: url("assets/images/search_icon/search_grey.png") !important; }
      .grey .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-webkit-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .grey .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-ms-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .grey .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
    .grey .chatInfoWrapper .tabContent.responseWrapper .searchForm p {
      color: #adadad;
      font-family: "Segoe UI";
      font-size: 14px;
      font-weight: 400; }
      .grey .chatInfoWrapper .tabContent.responseWrapper .searchForm p span {
        color: #010101;
        font-family: "Segoe UI Semi Bold";
        font-weight: 600; }
  .grey .chatInfoWrapper .tabContent.responseWrapper .ruleComponent {
    padding: 15px 20px; }
    .grey .chatInfoWrapper .tabContent.responseWrapper .ruleComponent > div:first-child {
      max-width: -webkit-max-content;
      max-width: -moz-max-content;
      max-width: max-content; }
    .grey .chatInfoWrapper .tabContent.responseWrapper .ruleComponent .rule_data p.ques {
      font-family: "Segoe UI Semi Bold";
      font-weight: 600; }

.deepSkyBlue .editorInline > div {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none; }
  .deepSkyBlue .editorInline > div img {
    display: none; }
  .deepSkyBlue .editorInline > div:first-child {
    background-image: url("assets/images/bold_Icons/bold_skyblue2.png") !important; }
  .deepSkyBlue .editorInline > div:nth-child(2) {
    background-image: url("assets/images/Italic_icons/Italic_skyblue.png") !important; }
  .deepSkyBlue .editorInline > div:nth-child(3) {
    background-image: url("assets/images/Underline_icons/line_skyblue.png") !important; }

.deepSkyBlue .editorEmoji {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/smily_icons/smiling-skyblue.png") !important; }
  .deepSkyBlue .editorEmoji img {
    display: none; }

.deepSkyBlue .overview ul {
  list-style-image: url("assets/images/list_icon/listicon_skyblue.png") !important; }
  .deepSkyBlue .overview ul li {
    padding-left: 8px; }

.deepSkyBlue .editorAttachImg {
  margin-left: 5px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/attachment_icon/attachmant_skyblue.png") !important; }

.deepSkyBlue .innercontent_wrapper .box .searchText input {
  padding-right: 27px;
  background-repeat: no-repeat;
  background-position: 97%;
  background-image: url("assets/images/search_icon/search_skyblue.png") !important; }

.deepSkyBlue .textEditorLink {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/link_icons/link_skyblue.png") !important; }
  .deepSkyBlue .textEditorLink img {
    display: none; }

.deepSkyBlue .editorLink {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/link_icons/link_skyblue.png") !important; }
  .deepSkyBlue .editorLink img {
    display: none; }

.deepSkyBlue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1) {
  background-image: url("assets/images/infotab.png"); }
  .deepSkyBlue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(1).active {
    background-image: url("assets/images/info_icon/info_skyblue.png") !important; }

.deepSkyBlue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2) {
  background-image: url("assets/images/footstepstab.png"); }
  .deepSkyBlue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(2).active {
    background-image: url("assets/images/footstep_icon/footstep_skyblue.png") !important; }

.deepSkyBlue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3) {
  background-image: url("assets/images/historytab.png"); }
  .deepSkyBlue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(3).active {
    background-image: url("assets/images/history_Icon/history_skyblue.png") !important; }

.deepSkyBlue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4) {
  border-top-right-radius: 10px;
  background-image: url("assets/images/responsetab.png"); }
  .deepSkyBlue .chatInfoWrapper .nav.nav-tabs a.nav-item:nth-child(4).active {
    background-image: url("assets/images/response_chat/deepSkyBlue.png") !important; }

.deepSkyBlue .chatInfoWrapper .nav.nav-tabs a.nav-item.active {
  color: #3ba8c5;
  background-color: #fff; }

.deepSkyBlue .chatInfoWrapper .tab-content {
  position: relative; }
  .deepSkyBlue .chatInfoWrapper .tab-content p.hide_btn {
    color: #3ba8c5;
    font-family: "Segoe UI Bold";
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    width: 100%;
    margin: 0;
    top: -7px; }
    .deepSkyBlue .chatInfoWrapper .tab-content p.hide_btn span {
      width: 70px;
      display: inline-block;
      background-color: #fff;
      cursor: pointer; }
      .deepSkyBlue .chatInfoWrapper .tab-content p.hide_btn span img {
        margin-right: 8px;
        margin-top: -2px; }

.deepSkyBlue .chatInfoWrapper .tabContent {
  text-align: left;
  margin-top: 24px;
  padding-top: 35px;
  border-top: 1px solid #dedede;
  color: #000000;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }
  .deepSkyBlue .chatInfoWrapper .tabContent h6 {
    color: #000000;
    font-family: "Helvetica Neue Cyr Roman";
    font-size: 22px;
    font-weight: 400;
    text-align: left;
    margin: 0 20px 20px; }
  .deepSkyBlue .chatInfoWrapper .tabContent .data p {
    margin-bottom: 10px;
    margin-top: 0; }
  .deepSkyBlue .chatInfoWrapper .tabContent .data .label {
    padding-right: 0; }
    .deepSkyBlue .chatInfoWrapper .tabContent .data .label p {
      margin-left: 6px;
      font-family: "Segoe UI Semi Bold";
      font-size: 14px; }
  .deepSkyBlue .chatInfoWrapper .tabContent .data .value p {
    margin-right: 6px;
    font-family: "Segoe UI";
    font-size: 14px; }
  .deepSkyBlue .chatInfoWrapper .tabContent .data .webInfo {
    padding: 25px 20px;
    background-color: #ebf6f9;
    margin-bottom: 20px;
    margin-top: 15px; }
    .deepSkyBlue .chatInfoWrapper .tabContent .data .webInfo > p {
      font-family: "Segoe UI Semi Bold";
      margin-bottom: 20px;
      margin-top: 0;
      font-size: 14px; }
    .deepSkyBlue .chatInfoWrapper .tabContent .data .webInfo .row:last-child p {
      margin-bottom: 0; }
    .deepSkyBlue .chatInfoWrapper .tabContent .data .webInfo .label p {
      margin-left: 0; }
  .deepSkyBlue .chatInfoWrapper .tabContent .data .notes {
    padding: 25px 20px;
    background-color: #ebf6f9;
    border-radius: 0 0 10px 10px;
    margin-top: 12px; }
    .deepSkyBlue .chatInfoWrapper .tabContent .data .notes h6 {
      color: #293037;
      font-family: "Segoe UI Bold";
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      margin-left: 0;
      margin-right: 0; }
    .deepSkyBlue .chatInfoWrapper .tabContent .data .notes button {
      background-color: transparent; }
      .deepSkyBlue .chatInfoWrapper .tabContent .data .notes button:hover {
        color: inherit; }
    .deepSkyBlue .chatInfoWrapper .tabContent .data .notes .notesData {
      margin-bottom: 27px; }
      .deepSkyBlue .chatInfoWrapper .tabContent .data .notes .notesData p {
        width: 100%; }
  .deepSkyBlue .chatInfoWrapper .tabContent .data .rowLayout .value p {
    margin: 0 6px; }
  .deepSkyBlue .chatInfoWrapper .tabContent .data .rowLayout .link {
    color: #3ba8c5; }
    .deepSkyBlue .chatInfoWrapper .tabContent .data .rowLayout .link img {
      margin-left: 8px; }
  .deepSkyBlue .chatInfoWrapper .tabContent .data .rowLayout .disabled {
    color: #adadad; }
  .deepSkyBlue .chatInfoWrapper .tabContent .data .withImage img {
    margin-left: 8px;
    margin-top: -3px; }
  .deepSkyBlue .chatInfoWrapper .tabContent .footstepsWrapper {
    padding-bottom: 35px; }
    .deepSkyBlue .chatInfoWrapper .tabContent .footstepsWrapper .link {
      color: #3ba8c5; }
      .deepSkyBlue .chatInfoWrapper .tabContent .footstepsWrapper .link img {
        margin-left: 8px; }
  .deepSkyBlue .chatInfoWrapper .tabContent .historyWrapper {
    padding-bottom: 40px; }
    .deepSkyBlue .chatInfoWrapper .tabContent .historyWrapper > div > div.label, .deepSkyBlue .chatInfoWrapper .tabContent .historyWrapper > div > div.value {
      cursor: pointer; }
    .deepSkyBlue .chatInfoWrapper .tabContent .historyWrapper .label p {
      font-size: 12px; }
      .deepSkyBlue .chatInfoWrapper .tabContent .historyWrapper .label p span {
        color: #adadad; }
    .deepSkyBlue .chatInfoWrapper .tabContent .historyWrapper .value p {
      color: #3ba8c5;
      font-size: 14px; }
    .deepSkyBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails {
      padding-top: 25px;
      padding-bottom: 0;
      max-height: 60vh;
      margin-bottom: 20px; }
      .deepSkyBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails p {
        font-size: 14px; }
      .deepSkyBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .value p {
        color: #000000; }
      .deepSkyBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails > img {
        margin-top: -15px;
        margin-right: -9px;
        cursor: pointer; }
      .deepSkyBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript h6 {
        color: #293037;
        font-family: "Segoe UI Bold";
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 20px 6px 15px 22px; }
      .deepSkyBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p {
        margin-left: 6px; }
        .deepSkyBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child {
          font-family: "Segoe UI Semi Bold";
          margin-bottom: 0; }
          .deepSkyBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .transcript > div p:first-child span {
            color: #adadad; }
      .deepSkyBlue .chatInfoWrapper .tabContent .historyWrapper .transcriptDetails .rcs-custom-scroll .rcs-inner-container > div {
        padding: 0; }
  .deepSkyBlue .chatInfoWrapper .tabContent.responseWrapper .panelWrapper {
    border-top-right-radius: 0;
    border-top-left-radius: 0; }
  .deepSkyBlue .chatInfoWrapper .tabContent.responseWrapper .searchForm {
    padding: 0 20px; }
    .deepSkyBlue .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input {
      padding-right: 27px;
      background-repeat: no-repeat;
      background-position: 97%;
      background-image: url("assets/images/search_icon/search_skyblue.png") !important; }
      .deepSkyBlue .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-webkit-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .deepSkyBlue .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::-ms-input-placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
      .deepSkyBlue .chatInfoWrapper .tabContent.responseWrapper .searchForm .searchText input::placeholder {
        color: #adadad;
        font-family: "Segoe UI";
        font-size: 14px;
        font-weight: 400; }
    .deepSkyBlue .chatInfoWrapper .tabContent.responseWrapper .searchForm p {
      color: #adadad;
      font-family: "Segoe UI";
      font-size: 14px;
      font-weight: 400; }
      .deepSkyBlue .chatInfoWrapper .tabContent.responseWrapper .searchForm p span {
        color: #010101;
        font-family: "Segoe UI Semi Bold";
        font-weight: 600; }
  .deepSkyBlue .chatInfoWrapper .tabContent.responseWrapper .ruleComponent {
    padding: 15px 20px; }
    .deepSkyBlue .chatInfoWrapper .tabContent.responseWrapper .ruleComponent > div:first-child {
      max-width: -webkit-max-content;
      max-width: -moz-max-content;
      max-width: max-content; }
    .deepSkyBlue .chatInfoWrapper .tabContent.responseWrapper .ruleComponent .rule_data p.ques {
      font-family: "Segoe UI Semi Bold";
      font-weight: 600; }

.alice-carousel {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  margin: auto; }

.alice-carousel__wrapper {
  position: relative;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  overflow: hidden; }

.alice-carousel__stage {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  box-sizing: border-box; }
  .alice-carousel__stage-item {
    width: 100%;
    height: 100%;
    position: relative;
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    line-height: 0; }
    .alice-carousel__stage-item * {
      line-height: normal;
      line-height: initial; }
    .alice-carousel__stage-item.__cloned {
      opacity: 0;
      visibility: hidden; }
  .alice-carousel__stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  display: inline-block;
  width: 50%;
  padding: 15px 10px;
  box-sizing: border-box; }
  .alice-carousel__prev-btn [data-area]::after,
  .alice-carousel__next-btn [data-area]::after {
    content: attr(data-area);
    position: relative;
    text-transform: capitalize; }

.alice-carousel__prev-btn {
  text-align: right; }

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  display: inline-block;
  color: #465798;
  cursor: pointer; }
  .alice-carousel__prev-btn-item:hover,
  .alice-carousel__next-btn-item:hover {
    color: darkred; }
  .alice-carousel__prev-btn-item.__inactive,
  .alice-carousel__next-btn-item.__inactive {
    opacity: .4; }

.alice-carousel__play-btn {
  display: inline-block;
  position: absolute;
  top: 30px;
  left: 20px; }
  .alice-carousel__play-btn:hover {
    cursor: pointer; }
  .alice-carousel__play-btn-wrapper {
    position: relative;
    background-color: #fff;
    padding: 10px;
    width: 32px;
    height: 32px;
    border-radius: 50%; }

.alice-carousel__play-btn-item {
  cursor: pointer;
  position: absolute;
  background: transparent;
  width: 32px;
  height: 32px;
  outline: none;
  border: 0; }
  .alice-carousel__play-btn-item::before, .alice-carousel__play-btn-item::after {
    content: '';
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    border-style: solid;
    border-color: transparent;
    border-width: 8px 0 8px 15px;
    border-left-color: #465798;
    transition: all 0.3s linear; }
  .alice-carousel__play-btn-item::before {
    height: 14px;
    left: 5px; }
  .alice-carousel__play-btn-item::after {
    left: 18px;
    top: 7px; }
  .alice-carousel__play-btn-item.__pause::before, .alice-carousel__play-btn-item.__pause::after {
    border-width: 0 0 0 10px;
    height: 30px; }
  .alice-carousel__play-btn-item.__pause::after {
    left: 18px;
    top: 0; }

.alice-carousel__dots {
  margin: 30px 0 5px;
  text-align: center;
  list-style: none;
  padding: 0; }
  .alice-carousel__dots-item {
    display: inline-block;
    width: 8px;
    height: 8px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #e0e4fb; }
    .alice-carousel__dots-item:not(:last-child) {
      margin-right: 15px; }
    .alice-carousel__dots-item:hover, .alice-carousel__dots-item.__active {
      background-color: #6e7ebc; }

.alice-carousel__slide-info {
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 5px 10px;
  color: #465798;
  border-radius: 5px;
  background-color: rgba(224, 228, 251, 0.6); }
  .alice-carousel__slide-info-item {
    line-height: 0;
    vertical-align: middle; }

.alice-carousel .animated {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.alice-carousel .animated-out {
  z-index: 1; }

.alice-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    visibility: hidden; } }

.input-container {
  display: flex;
  align-items: center;
  /* Adjust alignment */
  height: 14vh;
  max-width: 100%;
  /* Prevent overflow */ }

.styled-input {
  width: 300px;
  height: 30px;
  border-radius: 25px;
  /* Half-circle border-radius */
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 16px;
  background-color: #f9f9f9;
  color: #333;
  cursor: not-allowed;
  /* Change cursor to indicate it's disabled */ }

.switch {
  text-align: right;
  position: absolute;
  display: inline-block;
  width: 44px;
  height: 22px; }

.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

.slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ccc;
  border-radius: 34px; }

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 0px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transition: .4s; }

input:checked + .slider {
  background-color: #0dc22b; }

input:focus + .slider {
  box-shadow: 0 0 1px #0dc22b; }

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
          transform: translateX(26px); }

.cardbox {
  padding: 26px;
  font-size: small; }

.checkboxalign {
  float: right;
  top: -19px;
  margin-right: 10px; }

.text-wrapper {
  white-space: normal;
  margin-left: -23px; }

.pagebox {
  margin-bottom: 10px; }

.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  /* Light border for separation */ }

.modal-dialog .modal-content {
  width: auto; }

.subcheckboxalign {
  float: right;
  top: -34px;
  margin-right: 10px; }

.message {
  padding: 4px;
  margin: 5px 0;
  border-radius: 8px; }

.message.user {
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5; }

.message.ai {
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5; }

@-webkit-keyframes typing {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 0.3; }
  50% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    opacity: 1; }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 0.3; } }

@keyframes typing {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 0.3; }
  50% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    opacity: 1; }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 0.3; } }

.typing-indicator {
  display: flex;
  align-items: center;
  margin-top: 5px; }

.typing-indicator .dot {
  width: 8px;
  height: 8px;
  margin-right: 4px;
  border-radius: 50%;
  -webkit-animation: typing 1.5s infinite ease-in-out;
          animation: typing 1.5s infinite ease-in-out; }

.typing-indicator .dot:nth-child(2) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

.typing-indicator .dot:nth-child(3) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }

.hover-link {
  position: relative;
  display: inline-block;
  color: blue; }

.edit-icon {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease-in-out; }

.hover-link:hover .edit-icon {
  visibility: visible;
  opacity: 1; }

#questionArea {
  overflow: auto;
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #888 #fff;
  /* For Firefox */ }

/* Custom scrollbar for Webkit browsers (Chrome, Safari, Edge) */
#questionArea::-webkit-scrollbar {
  width: 10px; }

#questionArea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px; }

#questionArea::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 5px; }

#questionArea::-webkit-scrollbar-thumb:hover {
  background: #fff; }

#questionArea {
  scrollbar-width: none; }

#questionArea::-webkit-scrollbar {
  display: none; }

#questionArea:hover {
  scrollbar-width: thin; }

#questionArea:hover::-webkit-scrollbar {
  display: block; }

.operatorChat.supervisorFile .chatText::before {
  border-bottom-color: #f1ea4f; }

.getCodeModel .modal-dialog .modal-content {
  width: 100%; }
  .getCodeModel .modal-dialog .modal-content .websiteWidgetGetCode .codeWrapper textarea {
    width: 100%; }

.geoMapPopup .modal-dialog .modal-content {
  width: 100%; }

.react-joyride__overlay {
  position: unset !important;
  background-color: #fff !important; }
  .react-joyride__overlay .react-joyride__spotlight {
    position: unset !important;
    background-color: #fff !important; }

.customeicon_wrapper {
  width: 100% !important;
  background: transparent !important; }

.foot_Responsesearch {
  width: 100% !important; }

.fc_profile_img {
  display: inline-block;
  margin: 5px 10px 5px 0;
  width: 45px;
  height: 45px;
  cursor: pointer;
  border: 1px solid #bec3c7;
  border-radius: 3px;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  vertical-align: bottom; }

.visitorTypingContent {
  text-align: left; }
  .visitorTypingContent .visitorTyping {
    display: inline;
    margin-left: 5px; }

.visitor_field {
  line-height: 5px; }

.waitingforChat {
  width: 284px;
  padding: 10px 10px 20px 10px;
  border: 1px solid #BFBFBF;
  background-color: white;
  box-shadow: 10px 10px 5px #aaaaaa;
  position: relative;
  left: 25%;
  top: 25%;
  z-index: 999;
  border-radius: 3px;
  font-family: Segoe UI; }

.transferchat ul {
  list-style: none;
  margin-left: -40px; }

.transferchat ul li {
  padding: 10px; }

.transferchat ul li:hover {
  background: #ccc; }

.findchatResponses {
  background: #f7f3f3;
  width: 50%;
  height: 130px;
  overflow-y: scroll;
  box-shadow: 0px 1px 1px 1px #ccc;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-align: left;
  z-index: 1;
  position: absolute;
  top: -119px;
  left: 117px; }

.chat-limt {
  color: #000000;
  font-family: "Helvetica Neue Cyr Roman";
  font-size: 14px;
  font-weight: 400; }

.iscannedEnabled .nav.nav-tabs a.nav-item:nth-child(4) {
  pointer-events: none;
  opacity: 0.3; }

.findchatResponses ul {
  list-style: none;
  margin-left: -40px; }

.findchatResponses ul li {
  padding: 10px; }

.findchatResponses ul li:hover {
  background: #ccc; }

.schedule_zone .day_wrapper {
  margin-bottom: 13px; }
  .schedule_zone .day_wrapper p.day_label {
    color: #000000;
    font-family: 'Segoe UI Semi Bold';
    font-size: 14px;
    font-weight: 400;
    margin: 8px 4px 8px 0;
    text-align: left;
    width: 73px;
    padding-left: 0; }
  .schedule_zone .day_wrapper input {
    width: 67px;
    height: 37px;
    margin-left: 10px; }
  .schedule_zone .day_wrapper .custom_radioBtn .btnText {
    position: absolute;
    top: 3px;
    right: 7px;
    color: #000000;
    font-family: 'Segoe UI';
    font-size: 11px;
    font-weight: 700;
    transition: all 0.3s; }

.testingImg {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: transparent;
  border: none;
  background-image: url("assets/images/linkIcon.png"); }

.opCss {
  font-size: 20px !important; }

.Sms_operators {
  color: #293037;
  font-family: 'Segoe UI Bold' !important;
  font-size: 13px !important;
  font-weight: 324; }

.reportUsageborder {
  border-bottom: 1px solid #d0d0d0; }

.reportUsage {
  margin-top: 27px;
  width: 100%;
  box-shadow: 0 0 5px rgba(13, 16, 21, 0.19);
  border-radius: 10px;
  background-color: #ffffff;
  margin-left: 0;
  padding: 0px 0; }

.DateRangePicker {
  position: absolute !important;
  z-index: 125;
  left: -19px;
  background-color: white; }

.reportschartsborder {
  border-right: 1px solid #d0d0d0; }

.operatorreportsborder {
  color: #293037;
  font-family: 'Segoe UI Bold' !important;
  font-size: 11px !important;
  font-weight: 500; }

.reportschartstext {
  font-family: 'Helvetica Neue Cyr Medium' !important;
  font-size: 30px !important;
  font-weight: 700; }

.reportschartsfontsize {
  color: #293037;
  font-family: 'Segoe UI Bold' !important;
  font-size: 13px !important;
  font-weight: 700; }

.reportcharts {
  margin-top: 27px;
  width: 100%;
  height: 127px;
  box-shadow: 0 0 5px rgba(13, 16, 21, 0.19);
  border-radius: 10px;
  background-color: #ffffff;
  margin-left: 0;
  padding: 0px 0; }

.pie {
  list-style: none;
  font-size: 12px; }

.pie #declined::before {
  content: "\2022";
  color: #ffa500;
  font-weight: bold;
  font-size: 20px;
  display: inline-block;
  width: 1em;
  margin-left: -1em; }

.pie {
  list-style: none;
  font-size: 12px; }

.pie #accept::before {
  content: "\2022";
  color: #7fae72;
  font-weight: bold;
  font-size: 20px;
  display: inline-block;
  width: 1em;
  margin-left: -1em; }

.pie {
  list-style: none;
  font-size: 12px; }

.pie #invitations::before {
  content: "\2022";
  color: #3ba8c5;
  margin: 50 px;
  font-weight: bold;
  font-size: 20px;
  display: inline-block;
  width: 1em;
  margin-left: -1em; }

.pie {
  list-style: none;
  font-size: 12px; }

.pie #manualinvitations::before {
  content: "\2022";
  color: #7fae72;
  font-weight: bold;
  font-size: 20px;
  display: inline-block;
  width: 1em;
  margin-left: -1em; }

.pie {
  list-style: none;
  font-size: 12px; }

.pie #time::before {
  content: "\2022";
  color: #e44e4e;
  font-weight: bold;
  font-size: 20px;
  display: inline-block;
  width: 1em;
  margin-left: -1em; }

.archive_pagination .pagination {
  display: flex;
  flex-direction: row;
  list-style: none;
  border: 0.5 solid #3ba8c5;
  color: #3ba8c5;
  font-family: Segoe UI Bold;
  font-size: 13px;
  padding-top: 17px;
  border-radius: 50%;
  justify-content: center;
  align-items: center; }

.archive_pagination .pagination li {
  cursor: pointer;
  margin-right: 12px; }

.archive_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #3ba8c5  solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.disable-bg {
  background-color: #ccc !important; }

.traffic_pagination .pagination {
  display: flex;
  flex-direction: row;
  list-style: none;
  border: 0.5 solid #3ba8c5;
  color: #3ba8c5;
  font-family: Segoe UI Bold;
  font-size: 13px;
  padding-top: 17px;
  border-radius: 50%; }

.traffic_pagination .pagination li {
  cursor: pointer;
  margin-right: 12px; }

.traffic_pagination .pagination li a {
  border: none;
  outline: none;
  box-shadow: none; }

.traffic_pagination .pagination li.active {
  cursor: pointer;
  margin-right: 12px;
  border: #3ba8c5  solid;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  border-width: thin; }

.deleteCstm {
  cursor: pointer;
  padding-top: -21px;
  margin: -5px -75px 12px 0px;
  margin-top: -24px;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  border: 1px solid #dedede;
  background-color: #ffffff;
  color: #c94040;
  margin-left: -54px !important;
  margin-top: -30px !important; }

.websitePayDollar {
  border: 3px solid #3ba8c5;
  border-radius: 50%;
  padding: 6px !important;
  font-size: 40px !important;
  width: 80px; }

.payDollar {
  border: 3px solid #ec407a;
  border-radius: 50%;
  padding: 14px;
  font-size: 40px !important; }

.pointerhand {
  cursor: pointer; }

.adminloader {
  position: fixed;
  z-Index: 999;
  height: 100%;
  width: 100%;
  overflow: visible;
  margin: auto;
  top: 288px;
  left: 0;
  bottom: 0;
  right: 0;
  background: #f7f3f3 none repeat scroll 0% 0%;
  opacity: 0.6;
  margin-Left: 23%;
  margin-Top: -227px; }

.profileLoader {
  position: fixed;
  z-Index: 999;
  height: 100%;
  width: 100%;
  overflow: visible;
  margin: auto;
  top: 288px;
  left: 0;
  bottom: 0;
  right: 0;
  background: #f7f3f3 none repeat scroll 0% 0%;
  opacity: 0.6;
  margin-Left: 8%;
  margin-Top: -227px; }

.chatSectionLoader {
  position: fixed;
  z-Index: 999;
  height: 100%;
  width: 100%;
  overflow: visible;
  margin: auto;
  top: 288px;
  left: 0;
  bottom: 0;
  right: 0;
  background: #f7f3f3 none repeat scroll 0% 0%;
  opacity: 0.6;
  margin-Left: 17%;
  margin-Top: -227px; }

/* for sounds setting */
.innercontent_wrapper .profile_wrapper .customDrpDwnDisabled .css-1sontr1 {
  width: 230px; }

/* for attached images */
.fileUploaderDiv {
  position: relative;
  display: inline-block;
  margin: 5px 10px 5px 0;
  width: 80px;
  height: 80px;
  border: 1px solid #bec3c7;
  border-radius: 10px;
  background-position: 50%;
  cursor: pointer; }

.fileUploaderDiv:hover {
  background-color: #f6f6f6;
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

.fileUploaderDiv img {
  position: absolute;
  top: 29%;
  left: 32%; }

.notAllowedDiv {
  background-color: #f6f6f6;
  pointer-events: none; }

.maxAllowedMsg {
  font-family: 'Segoe UI Semi';
  color: #c94040; }

.fileUploaderDiv .notAllowed {
  pointer-events: none;
  width: 35%; }

.cannedImages {
  display: inline-block;
  margin: 5px 10px 5px 0;
  width: 80px;
  height: 80px;
  border: 1px solid #bec3c7;
  border-radius: 10px;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  vertical-align: bottom; }

.cannedImages .img_delete {
  float: right;
  margin-right: 5px;
  cursor: pointer; }

.cannedImages .img_download {
  float: left;
  margin-left: 6px; }

.cannedImages .img_download :hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2); }

.cannedImages:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02); }

.cannedImages .img_delete img:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2); }

/* for disable button */
.cursorNotAllowed {
  cursor: not-allowed; }

.cursorNotAllowed .disabledbutton {
  pointer-events: none;
  opacity: 0.4; }

/* for--------login----------*/
.loginWrapper {
  width: 135%; }

.loginWrapper .loginHeader {
  background-color: #3ba8c5;
  border-radius: 10px 10px 0 0;
  padding-right: 15px;
  padding: 8px; }

.loginWrapper .loginHeader h6 {
  font-family: 'Segoe UI Semi Bold';
  font-weight: 400;
  font-size: 18px; }

.loginWrapper .content {
  padding: 14px;
  background: #E5F5FC;
  text-align: left;
  color: #000000;
  font-family: 'Segoe UI Semi';
  font-size: 14px;
  font-weight: 400;
  padding-left: 30px; }

.loginWrapper .content .invalidLogin {
  font-size: 14px;
  background: yellow;
  text-align: center;
  color: red;
  font-family: 'Segoe UI Semi';
  margin-left: -18px; }

.loginWrapper .loginForm {
  padding: 28px; }

.loginWrapper .loginForm .forgot {
  float: left;
  cursor: pointer;
  font-family: 'Segoe UI Semi Bold';
  color: #3ba8c5;
  margin-left: -26px; }

.loginWrapper .loginForm .forgot:hover {
  color: #000000; }

.loginWrapper .loginBtns {
  padding-top: 10px !important;
  margin-top: 0px !important;
  border-top: none !important;
  padding-right: 0; }

.loginWrapper .formBtns Button.restoredpass {
  width: 175px !important; }

.loginWrapper .formBtns Button:hover {
  background-color: #ffff;
  color: #3ba8c5;
  border: 1px solid #3ba8c5; }

.loginWrapper .formBtns Button p {
  text-transform: none; }

.loginWrapper .form-label {
  text-align: left;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.logoutCss .modal-dialog .modal-content {
  width: 80%;
  height: 240px; }

/* for--------archiveChat and PopUp--------------start-----------*/
.exportCss {
  pointer-events: none;
  /* for "disabled" effect */
  opacity: 0.5;
  background: #CCC; }

.table_td table td {
  padding: 0.1rem;
  border-top: none; }

.modal-dialog .modal-content .chatInfoModelHd .close {
  margin-top: -94px; }

.modal-dialog .modal-content .chatInfoModelHd .modal-title {
  color: #000000;
  font-family: "Helvetica Neue Cyr - Roman";
  font-size: 18px;
  font-weight: 400;
  line-height: 36px; }

.chatInfoModelHd {
  padding-bottom: 0px;
  display: block; }

.modal-dialog .modal-content .modal-header button.chatInfoBtn {
  border: 1px solid #3ba8c5;
  background-color: #ffffff;
  color: #3ba8c5;
  font-family: "Segoe UI";
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase; }

.modal-dialog .modal-content .modal-header button.chatInfoBtn:hover {
  color: #ffff;
  background-color: #3ba8c5; }

.table_td .label {
  width: 32%; }
  .table_td .label p {
    padding: 0 15px; }

.table_td .value {
  width: 68%; }
  .table_td .value p {
    padding: 0 15px; }

.infoTranscript .name {
  width: 22%; }
  .infoTranscript .name p {
    padding: 0 15px; }

.infoTranscript .time {
  width: 10%; }

.infoTranscript .msg {
  width: 65%; }
  .infoTranscript .msg p {
    padding: 0 15px; }

.infoTranscript .row .chat_p {
  font-family: "Segoe UI" !important;
  font-weight: 400; }

.infoTranscript .row .chat_p div > p {
  padding-left: 0px; }

.chatInfoBtn {
  margin-top: 5px;
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.1rem 1.022rem;
  font-size: 1rem;
  line-height: 1.5;
  width: 98px;
  height: 29px;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

.modalBodyTitle {
  color: #293037 !important;
  font-family: "Segoe UI Bold" !important;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase; }

.modalBodyTitle:nth-child(1) {
  margin-top: 10px; }

.table_td table td p {
  line-height: 17px; }

.table_td table tr td:nth-child(2) p {
  font-family: "Segoe UI" !important;
  width: 162%; }

.dynamic_firstRow .addBtn {
  margin-left: 0px; }

.infoTranscript .row div:nth-child(2) p {
  font-family: "Segoe UI" !important; }

.infoTranscript .row div:nth-child(3) p {
  font-family: "Segoe UI" !important; }

/* for---------scroll pop up----start------*/
.modal-dialog .modal-content .chatInfoScrollWrapper {
  padding: 0; }
  .modal-dialog .modal-content .chatInfoScrollWrapper .rcs-inner-container > div {
    padding: 1rem 30px; }

.modal-dialog .modal-content .chatInfoScrollWrapper p {
  color: #000000;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }

.modal-dialog .modal-content .chatInfoScrollWrapper .modalBodyTitle:nth-child(1) {
  margin-top: 0px; }

.modal-dialog .modal-content .chatInfoScrollWrapper .modalBodyTitle {
  color: #293037 !important;
  font-family: "Segoe UI Bold" !important;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase; }

/* for---------scroll pop up----end------*/
/* for---------notification pop up start---------*/
.__react_component_tooltip.show {
  opacity: 1; }

#notificationClickme {
  width: 35%; }

/*for arrow of tooltip*/
#notificationClickme::before {
  left: 71%; }

#notificationClickme::after {
  left: 71%; }

.notificationPopup {
  border-radius: 3px !important;
  width: 100%;
  height: 100%; }

@media (max-width: 350px) {
  #notificationClickme {
    width: 64%; } }

@media (max-width: 480px) {
  #notificationClickme {
    width: 65%; }
  #notificationClickme::before {
    left: 81%; }
  #notificationClickme::after {
    left: 81%; } }

@media (max-width: 600px) {
  #notificationClickme {
    width: 48%; }
  #notificationClickme::before {
    left: 85%; }
  #notificationClickme::after {
    left: 85%; } }

@media (max-width: 768px) {
  #notificationClickme {
    width: 48%; }
  #notificationClickme::before {
    left: 76%; }
  #notificationClickme::after {
    left: 76%; } }

@media (max-width: 900px) {
  #notificationClickme {
    width: 48%; }
  #notificationClickme::before {
    left: 71%; }
  #notificationClickme::after {
    left: 71%; } }

@media (max-width: 1024px) {
  #notificationClickme {
    width: 38%; }
  #notificationClickme::before {
    left: 75%; }
  #notificationClickme::after {
    left: 75%; } }

.modal-dialog .modal-content .notiInfoScrollWrapper p {
  color: #000000;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }

.notificationPopup .notificationTitle {
  color: #293037 !important;
  font-family: "Segoe UI" !important;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase; }

.notificationPopup .closeNoti {
  float: right;
  cursor: pointer; }

.notificationPopup .notificationContent .subject {
  color: #293037 !important;
  font-family: Segoe UI !important;
  font-size: 13px;
  font-weight: 700;
  float: none; }

.notiInfoScrollWrapper .back_color_va {
  background: #ebf6f9;
  margin-bottom: 20px;
  text-align: left;
  padding: 32px;
  padding-top: 26px; }

.notificationContent .infoTranscript:nth-child(0) {
  width: 386px; }

.notificationContent .infoTranscript:nth-child(1) {
  width: 436px; }

.notiInfoScrollWrapper .back_color_va p {
  float: none; }

.notiInfoScrollWrapper .back_color_va .va_text {
  padding-left: 16px; }
  .notiInfoScrollWrapper .back_color_va .va_text p {
    font-family: "Segoe UI" !important;
    float: none; }

.modal-dialog .modal-content .notiInfoScrollWrapper {
  padding: 0; }
  .modal-dialog .modal-content .notiInfoScrollWrapper .rcs-inner-container > div {
    padding: 1rem 30px; }

.notiInfoScrollWrapper button.notiInfoBtn {
  border: 1px solid #3ba8c5;
  color: #3ba8c5;
  font-family: "Segoe UI";
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase; }

.notiInfoScrollWrapper button.dismiss {
  width: 98px;
  height: 39px; }

.notiInfoScrollWrapper button.dismiss:hover {
  background-color: #EBF6F9 !important; }

.notiInfoScrollWrapper button.read_more {
  width: 119px;
  height: 39px;
  color: #fff;
  background: #3ba8c5; }

.notiInfoScrollWrapper .back_color {
  padding-top: 10px; }

.notiInfoScrollWrapper .back_color, .notiInfoBtn {
  background: #ebf6f9; }

/*--------------*/
.setup_step ol {
  list-style: none;
  margin-left: -16px; }

.setup_step ol li {
  counter-increment: li;
  margin-bottom: 10px; }

.setup_step ol li a {
  color: #3ba8c5 !important; }

.setup_step ol li::before {
  content: counter(li);
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #3ba8c5;
  color: #3ba8c5;
  font-family: Segoe UI Bold;
  font-size: 13px;
  float: left;
  padding-left: 7px; }

.setup_step ol li p {
  font-family: "Segoe UI" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  padding-left: 30px !important; }

/* for---------notification pop up end---------*/
/* for---------help popup tooltip---------*/
#helpTooltipClick {
  width: 40%; }

.helpPopuptip {
  padding: 10px;
  text-align: justify; }

.helpPopuptip .closeNoti {
  float: right;
  cursor: pointer; }

.helpTipTitle {
  color: #000000;
  font-family: "Helvetica Neue Cyr Roman";
  font-size: 18px;
  font-weight: 400; }

.borderBtm {
  border-bottom: 4px solid #3ba8c5;
  padding: 1px;
  width: 116%;
  margin-top: 10px;
  margin-left: -16px;
  margin-right: -16px; }

.helpTipScrollWrapper {
  padding-top: 20px; }
  .helpTipScrollWrapper p {
    color: #000000;
    font-family: "Segoe UI Semi Bold";
    font-size: 14px;
    font-weight: 400; }

.helpTipScrollWrapper .do_not_check {
  float: none;
  display: inline; }

.helpTipScrollWrapper .floatRight {
  float: right; }

.do_not_check .checkbox_custom {
  margin-bottom: 0px; }

.helpTipScrollWrapper button.helpBtn:hover {
  color: #ffff; }

.helpTipScrollWrapper button.helpBtn {
  border: 1px solid #3ba8c5;
  background-color: #ffffff;
  color: #3ba8c5;
  font-family: "Segoe UI";
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1rem; }

.helpTipScrollWrapper button.helpBtn:nth-child(1) {
  float: right; }

.helpTipScrollWrapper .do_not_check {
  float: left;
  display: inline; }

.modal-dialog .modal-content .helpTipScrollWrapper {
  padding: 0; }
  .modal-dialog .modal-content .helpTipScrollWrapper .rcs-inner-container > div {
    padding: 1rem 30px; }

.modal-dialog .modal-content .chatInfoScrollWrapper p {
  color: #000000;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }

.modal-dialog .modal-content .helpTipScrollWrapper .modalBodyTitle:nth-child(1) {
  margin-top: 0px; }

/* for---------help popup---------*/
.helpPopup .modal-dialog .modal-content {
  border-radius: 0px !important;
  width: 619px;
  height: 300px;
  left: 355px;
  top: -130px; }

.helpPopup .modal-dialog .modal-content::before {
  content: "";
  position: absolute;
  top: -14px;
  left: 145px;
  z-index: 1;
  border: 7px solid transparent;
  border-bottom-color: transparent;
  border-bottom-color: #fff; }

.helpScrollWrapper button.helpBtn:hover {
  color: #ffff;
  background-color: #3ba8c5 !important; }

.helpScrollWrapper button.helpBtn {
  border: 1px solid #3ba8c5;
  background-color: #ffffff;
  color: #3ba8c5;
  font-family: "Segoe UI";
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1rem; }

.helpScrollWrapper button.helpBtn:nth-child(1) {
  float: right; }

.helpScrollWrapper .do_not_check {
  float: left;
  display: inline; }

.modal-dialog .modal-content .helpScrollWrapper {
  padding: 0; }
  .modal-dialog .modal-content .helpScrollWrapper .rcs-inner-container > div {
    padding: 1rem 30px; }

.modal-dialog .modal-content .chatInfoScrollWrapper p {
  color: #000000;
  font-family: "Segoe UI Semi Bold";
  font-size: 14px;
  font-weight: 400; }

.modal-dialog .modal-content .helpScrollWrapper .modalBodyTitle:nth-child(1) {
  margin-top: 0px; }

.help_dropdown .dropdown-menu.show {
  width: 182px;
  min-width: 121px;
  height: 152px;
  box-shadow: 0 0 5px rgba(13, 16, 21, 0.19);
  border-radius: 3px;
  background-color: #ffffff;
  margin-left: -80px;
  top: 16px !important; }

/* Image crop start */
.imgCropModel .modal-dialog .modal-content {
  display: block; }

/* Image crop  end */
/* for---------help popup end---------*/
/* for---------HelpTour Start---------*/
#helptourClickme {
  width: 32%; }

.helpTourAll .closeTour {
  float: right;
  cursor: pointer; }

.helpTourAll .stepContent {
  display: flex;
  margin-top: 34px;
  text-align: justify;
  font-family: Segoe UI;
  font-size: 15px; }

.helpTourAll .stepContent span {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 10px;
  padding-top: 10px;
  background: #67b84b;
  border: 1px solid #67b84b;
  color: #fff;
  text-align: center;
  font: 14px Segoe UI;
  margin-top: -1px;
  margin-bottom: -30px;
  padding-top: 5px;
  margin-right: 10px;
  margin-top: 4px; }

.helpTourAll {
  height: 165px; }

.helpTourAll .formBtns {
  padding-top: 0px;
  margin-top: 20px;
  border-top: none; }
  .helpTourAll .formBtns p {
    float: none; }

.helpTourCommon .modal-dialog .modal-header {
  border-bottom: none !important; }

.helpTourCommon .modal-dialog .modal-content {
  border-radius: 0px !important; }

.helpTourCommon .modal-dialog .modal-content .modal-body .stepContent {
  display: flex; }

.helpTourCommon .modal-dialog .modal-content .modal-body .stepContent span {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 10px;
  padding-top: 10px;
  background: #67b84b;
  border: 1px solid #67b84b;
  color: #fff;
  text-align: center;
  font: 14px Segoe UI;
  margin-top: -1px;
  margin-bottom: -30px;
  padding-top: 5px;
  margin-right: 10px;
  margin-top: 4px; }

.helpTourCommon .modal-dialog .modal-content .modal-footer button {
  padding: 0 25px;
  width: 84px;
  height: 38px; }

.helpTourStep1 .modal-dialog .modal-content {
  width: 389px;
  height: 175px;
  left: 202px;
  top: -190px; }

.helpTourStep1 .modal-dialog .modal-content::before {
  content: "";
  position: absolute;
  top: -14px;
  left: 209px;
  z-index: 1;
  border: 7px solid transparent;
  border-bottom-color: transparent;
  border-bottom-color: transparent;
  border-bottom-color: #fff; }

/*------if buy now button is hidden---*/
.helpTourStepWithoutBuynow1 .modal-dialog .modal-content {
  width: 389px;
  height: 175px;
  left: 300px;
  top: 22px;
  position: absolute; }

.helpTourStepWithoutBuynow1 .modal-dialog .modal-content::before {
  content: "";
  position: absolute;
  top: -14px;
  left: 350px;
  z-index: 1;
  border: 7px solid transparent;
  border-bottom-color: transparent;
  border-bottom-color: transparent;
  border-bottom-color: #fff; }

/*------if buy now button is hidden ends---*/
.helpTourStep2 .modal-dialog .modal-content {
  width: 380px;
  height: 195px;
  transition-duration: 1s; }

.helpTourStep2 .modal-dialog .modal-content::before {
  content: "";
  position: absolute;
  top: 20px;
  left: -7px;
  z-index: 1;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid #fff; }

.helpTourStep3 .modal-dialog .modal-content {
  width: 380px;
  height: 175px;
  left: -120px;
  top: 180px;
  transition-duration: 1s; }

.helpTourStep3 .modal-dialog .modal-content::before {
  content: "";
  position: absolute;
  top: 173px;
  left: 40px;
  z-index: 1;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #fff; }

.helpTourStep4 .modal-dialog .modal-content {
  width: 360px;
  height: 193px;
  left: 100px;
  top: 100px;
  transition-duration: 1s; }

.helpTourStep4 .modal-dialog .modal-content::before {
  content: "";
  position: absolute;
  top: -14px;
  left: 50px;
  z-index: 1;
  border: 7px solid transparent;
  border-bottom-color: transparent;
  border-bottom-color: transparent;
  border-bottom-color: #fff; }

.helpTourStep5 .modal-dialog .modal-content {
  width: 375px;
  height: 195px;
  left: 300px;
  top: 15px;
  transition-duration: 1s; }

.helpTourStep5 .modal-dialog .modal-content::before {
  content: "";
  position: absolute;
  top: 25px;
  left: -7px;
  z-index: 1;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid #fff; }

.helpTourStep6 .modal-dialog .modal-content {
  width: 365px;
  height: 195px;
  left: 370px;
  top: 85px;
  transition-duration: 1s; }

.helpTourStep6 .modal-dialog .modal-content::before {
  content: "";
  position: absolute;
  top: 25px;
  left: -7px;
  z-index: 1;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid #fff; }

.helpTourStep7 .modal-dialog .modal-content {
  width: 365px;
  height: 195px;
  left: 86px;
  top: 224px;
  transition-duration: 1s; }

.helpTourStep7 .modal-dialog .modal-content::before {
  content: "";
  position: absolute;
  top: 150px;
  left: 363px;
  z-index: 1;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #fff; }

.helpTourStep8 .modal-dialog .modal-content {
  width: 400px;
  height: 170px;
  left: 220px;
  top: -7px;
  transition-duration: 1s; }

.helpTourStep8 .modal-dialog .modal-content::before {
  content: "";
  position: absolute;
  top: 35px;
  left: -7px;
  z-index: 1;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid #fff; }

.helpTourStep9 .modal-dialog .modal-content {
  width: 375px;
  height: 170px;
  left: -50px;
  top: 5px;
  transition-duration: 1s; }

.helpTourStep9 .modal-dialog .modal-content::before {
  content: "";
  position: absolute;
  top: 35px;
  left: -7px;
  z-index: 1;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid #fff; }

.helpTourStep10 .modal-dialog .modal-content {
  width: 375px;
  height: 170px;
  left: 100px;
  top: 80px;
  transition-duration: 1s; }

.helpTourStep10 .modal-dialog .modal-content::before {
  content: "";
  position: absolute;
  top: 35px;
  left: -7px;
  z-index: 1;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid #fff; }

.helpTourStep11 .modal-dialog .modal-content {
  width: 420px;
  height: 190px;
  left: 268px;
  top: 11px;
  transition-duration: 1s; }

.helpTourStep11 .modal-dialog .modal-content::before {
  content: "";
  position: absolute;
  top: 65px;
  left: -7px;
  z-index: 1;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid #fff; }

.helpTourStep12 .modal-dialog .modal-content {
  width: 420px;
  height: 170px;
  left: 350px;
  top: 135px;
  transition-duration: 1s; }

.helpTourStep12 .modal-dialog .modal-content::before {
  content: "";
  position: absolute;
  top: 35px;
  left: -7px;
  z-index: 1;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid #fff; }

.helpTourStep13 {
  z-index: 10000; }

.helpTourStep13 .modal-dialog .modal-content {
  width: 210px;
  height: 170px;
  left: 360px;
  top: 0px;
  transition-duration: 1s; }

.helpTourStep13 .modal-dialog .modal-content::before {
  content: "";
  position: absolute;
  top: 35px;
  left: -7px;
  z-index: 1;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid #fff; }

.helpTourStep14 .modal-dialog .modal-content {
  width: 360px;
  height: 170px;
  left: 390px;
  top: -84px;
  transition-duration: 0.3s; }

.helpTourStep14 .modal-dialog .modal-content::before {
  content: "";
  position: absolute;
  top: 168px;
  left: 308px;
  z-index: 1;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #fff; }

.helpTourStep15 .modal-dialog .modal-content {
  width: 400px;
  height: 170px;
  left: 375px;
  top: 200px;
  transition-duration: 0.5s; }

.helpTourStep15 .modal-dialog .modal-content::before {
  content: "";
  position: absolute;
  top: 168px;
  left: 350px;
  z-index: 1;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #fff; }

.helpTourStep16 .modal-dialog .modal-content {
  width: 400px;
  height: 195px;
  left: 70px;
  top: 200px;
  transition-duration: 0.31s; }

.helpTourStep16 .modal-dialog .modal-content::before {
  content: "";
  position: absolute;
  top: 193px;
  left: 100px;
  z-index: 1;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #fff; }

.helpTourStep17 .modal-dialog .modal-content {
  width: 400px;
  height: 175px;
  right: 264px;
  top: -106px;
  transition-duration: 1s; }

.helpTourStep17 .modal-dialog .modal-content::before {
  content: "";
  position: absolute;
  top: 35px;
  left: -7px;
  z-index: 1;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid #fff; }

.helpTourStep18 .modal-dialog .modal-content {
  width: 400px;
  height: 195px;
  left: 350px;
  top: 200px;
  transition-duration: 1s; }

.helpTourStep18 .modal-dialog .modal-content::before {
  content: "";
  position: absolute;
  top: 35px;
  left: -7px;
  z-index: 1;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid #fff; }

.helpTourStep19 .modal-dialog .modal-content {
  width: 420px;
  height: 175px;
  left: 0px;
  top: -147px;
  transition-duration: 1s; }

.helpTourStep19 .modal-dialog .modal-content .modal-footer button {
  padding: 0 25px;
  width: 100px;
  height: 38px; }

/* for---------HelpTour Start- end---------*/
/*widget thumbnail css*/
@media (min-width: 1250px) {
  .mailer .modal-dialog .getcodetextarea {
    width: 630px; }
  .previewsave .widget-preview .widget-preview-text {
    font-size: 10px;
    margin-left: -6px;
    position: absolute;
    top: 40px;
    left: 1px; }
  .previewsave .widget-preview .widget-preview-title {
    font-size: 11px;
    position: absolute;
    top: 4px;
    left: -4px; } }

@media (max-width: 1250px) {
  .mailer .modal-dialog .getcodetextarea {
    width: 630px; }
  .previewsave .widget-preview .widget-preview-text {
    font-size: 10px;
    top: 36px;
    position: absolute;
    left: 1px; }
  .previewsave .widget-preview .widget-preview-title {
    font-size: 11px;
    top: 1px;
    position: absolute;
    left: -4px; } }

@media (max-width: 1150px) {
  .previewsave .widget-preview .widget-preview-title {
    font-size: 11px;
    position: absolute;
    top: -2px;
    left: -4px; }
  .previewsave .widget-preview .widget-preview-text {
    font-size: 9px;
    margin-left: -6px;
    position: absolute;
    top: 30px;
    left: -6px; } }

@media (max-width: 850px) {
  .mailer .modal-dialog .getcodetextarea {
    width: 100%; }
  .websiteWidgetGetCode .codeWrapper .formBtns Button.btn_clr {
    margin-top: 4px; }
  .expire_account {
    display: none !important; } }

@media screen and (min-width: 768px) and (max-width: 810px) {
  .previewsave .widget-preview .widget-preview-title {
    font-size: 8px;
    top: -6px;
    position: absolute;
    left: -4px; }
  .previewsave .widget-preview .widget-preview-text {
    font-size: 7px;
    position: absolute;
    top: 20px;
    left: -22px; } }

@media screen and (min-width: 814px) and (max-width: 945px) {
  .preview-title {
    font-size: 9px;
    top: -4px;
    position: absolute;
    left: -4px; }
  .previewsave .widget-preview .widget-preview-text {
    font-size: 7px;
    top: 26px;
    position: absolute;
    left: -15px; } }

@media screen and (min-width: 708px) and (max-width: 767px) {
  .previewsave .widget-preview .widget-preview-title {
    top: 4px;
    position: absolute; }
  .previewsave .widget-preview .widget-preview-text {
    font-size: 8px;
    margin-left: -5px;
    position: absolute;
    top: 38px;
    left: -9px; } }

@media screen and (min-width: 588px) and (max-width: 708px) {
  .previewsave .widget-preview .widget-preview-title {
    top: 0px;
    left: -4px;
    position: absolute; }
  .previewsave .widget-preview .widget-preview-text {
    top: 30px;
    position: absolute;
    left: -14px; } }

@media (min-width: 800px) {
  .website_drp {
    margin-left: -55px; } }

@media screen and (min-width: 300px) and (max-width: 325px) {
  .infoTranscript .row .chat_p {
    margin-left: -15px !important; } }

@media (max-width: 414px) {
  .dynamic_checkbx {
    margin-left: -17px; }
  .infoTranscript .row .chat_p {
    margin-left: -20px; }
  .top_btn {
    margin-left: 8px; }
  .table_td table tr td:nth-child(2) p {
    font-family: "Segoe UI" !important;
    width: 100%; } }

@media screen and (min-width: 651px) and (max-width: 800px) {
  .settingFormWrapper .website_drp .form-label {
    padding-left: 56px; }
  .top_btn {
    margin-left: 90px; } }

@media (min-width: 1024px) {
  .top_refBtn {
    margin-left: 51px; } }

@media screen and (min-width: 577px) and (max-width: 667px) {
  .customDrpDwn .css-10nd86i {
    width: 100%; } }

@media screen and (min-width: 1000px) and (max-width: 1025px) {
  .website_drp {
    margin-left: -30px; }
  .custom_btn {
    padding: 0px 6px;
    font-size: 12px; }
  .top_refBtn {
    margin-left: 30px; }
  .archiveDropDwn .css-10nd86i {
    width: 200px;
    margin-left: 12px; }
  .archiveFormWrapper .form-control {
    width: 200px !important; } }

/* for--------archiveChat and PopUp--------------end-----------*/
html {
  scroll-behavior: smooth;
  background-color: #f6f6f6; }

.cursor-pointer {
  cursor: pointer; }

.App {
  text-align: center;
  background-color: #f6f6f6; }

.pull-right {
  float: right; }

.pull-left {
  float: left !important; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

.pr-10 {
  padding-right: 10px; }

#sidebarCollapse {
  width: 40px;
  height: 40px;
  background: #f5f5f5;
  cursor: pointer;
  display: none;
  border: none; }

#sidebarCollapse span {
  width: 80%;
  height: 2px;
  margin: 0 auto;
  display: block;
  background: #555;
  transition: all 0.8s cubic-bezier(0.81, -0.33, 0.345, 1.375);
  transition-delay: 0.2s;
  -webkit-transform: none;
          transform: none;
  opacity: 1;
  margin: 5px auto; }

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
  padding-top: 61px; }

#sidebar {
  min-height: calc(100vh - 65px);
  color: #ffffff;
  min-width: 301px;
  max-width: 301px;
  margin-right: 0;
  position: fixed;
  z-index: 999; }

#sidebar.active {
  min-width: 105px;
  max-width: 105px;
  margin-right: 15px; }

#sidebar > ul {
  margin-bottom: 0; }

#sidebar > ul.sideMenu {
  position: relative;
  background-color: #3ba8c5;
  width: 105px;
  height: calc(100vh - 61px);
  z-index: 3; }

#sidebar > ul.sideMenu li {
  padding: 20px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  cursor: pointer; }

#sidebar > ul.sideMenu li.active {
  background-color: #0b91b4; }

#sidebar > ul.sideMenu li p {
  margin: 14px 0 0;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Helvetica Neue Cyr Medium';
  letter-spacing: 0.24px; }

#sidebar .submenu_wrapper {
  background-color: #242831;
  min-width: 195px;
  max-width: 195px;
  transition: all 0.3s;
  z-index: 2; }

#sidebar .subMenu {
  background-color: #363a45;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-height: calc(100vh - 250px); }

#admin_page {
  overflow-y: auto;
  scrollbar-color: #3ba8c5 transparent;
  scrollbar-width: thin; }

#sidebar .submenu_wrapper.active {
  margin-left: -195px;
  transition: none; }

#sidebar .submenu_wrapper .logoIcon {
  padding: 16px 0;
  border-bottom: 1px solid #66696f; }

#sidebar .subMenu li.parent_list {
  border-bottom: 1px solid #66696f; }

#sidebar .subMenu li.parent_list.menu_open {
  border-bottom: none; }

#sidebar .subMenu li.parent_list > a {
  color: #f7f5f5;
  font-family: 'Segoe UI Bold';
  font-size: 14px;
  font-weight: 700; }

#sidebar .subMenu li.parent_list > a:hover {
  text-decoration: none; }

#sidebar .subMenu li.parent_list > a.dropdown-toggle::after {
  display: none; }

#sidebar .subMenu li.parent_list > a.dropdown-toggle p {
  background-image: url("assets/images/openMenu.png");
  background-repeat: no-repeat;
  background-position: right; }

#sidebar .subMenu li.parent_list > a.dropdown-toggle.collapsed p {
  background-image: url("assets/images/closeMenu.png"); }

#sidebar .subMenu li.parent_list > ul.list_menu {
  padding-bottom: 6px; }

#sidebar.operator_menu .subMenu li.parent_list > ul.list_menu.submenu_operator {
  height: 250px;
  margin-right: -15px;
  margin-left: -15px; }
  #sidebar.operator_menu .subMenu li.parent_list > ul.list_menu.submenu_operator .operatorsWrapper {
    padding-bottom: 0; }

#sidebar .subMenu li.parent_list > ul li p {
  background-image: url("assets/images/menuBullet.png");
  background-repeat: no-repeat;
  background-position: left;
  padding-left: 16px;
  margin-bottom: 0;
  text-align: left;
  margin-left: 22px;
  font-family: 'Segoe UI Semi Bold';
  font-size: 13px;
  font-weight: 400;
  color: #a8d3de;
  line-height: 25px; }

#sidebar .subMenu li.parent_list > ul li.active {
  background-color: #0b91b5; }

#sidebar .subMenu li.parent_list > ul li.active p {
  color: #ffffff;
  font-family: 'Segoe UI Semi Bold';
  font-weight: 600; }

#sidebar .subMenu li.parent_list > a > p {
  margin: 21px 18px 20px 17px;
  text-align: left; }

#sidebar .submenu_wrapper .icon_wrapper {
  width: 44px;
  height: 44px;
  border: 0px solid #ffffff;
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  line-height: 37px;
  margin: 0;
  color: #fff; }

#sidebar .submenu_wrapper .icon_wrapper .imgweb {
  width: 44px;
  height: 44px;
  border: 0px solid #ffffff;
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  line-height: 37px;
  margin: 0px 0px -2px;
  color: #fff; }

.submenudropdwnp {
  white-space: nowrap; }

.hideIcon {
  position: fixed;
  bottom: 0;
  left: 105px;
  margin: 0;
  height: 50px;
  padding-left: 22px;
  cursor: pointer;
  width: 195px;
  border-top: 1px solid #363a45;
  /*transition-delay: 0.5s;*/
  transition: all 0.3s;
  background-color: #242831;
  z-index: 4; }

.hideIcon p {
  font-size: 12px;
  padding-left: 10px;
  margin: 0;
  font-family: 'Helvetica Neue Cyr Medium';
  font-weight: 500;
  color: #a8d3de; }

.hideIcon.inactive {
  width: 50px;
  height: 50px;
  background-color: #242831; }

.menu-right > img:nth-child(1) {
  margin-right: 31px; }

.menu-right > div {
  float: left; }

.innercontent_wrapper {
  background-color: #f6f6f6;
  padding-bottom: 50px; }
  .innercontent_wrapper .box {
    box-shadow: 0 0 5px rgba(13, 16, 21, 0.19);
    border-radius: 20px;
    background-color: #ffffff;
    padding: 50px; }
    .innercontent_wrapper .box.overview {
      padding: 28px; }

.settings_wrapper {
  background-color: #f6f6f6;
  padding-bottom: 50px; }

.header_title {
  height: 97px;
  padding-left: 26px; }

.header_title h4 {
  font-family: 'Helvetica Neue Cyr Roman';
  margin-bottom: 0;
  margin-left: 15px;
  font-size: 22px; }

.settings_wrapper .box {
  box-shadow: 0 0 5px rgba(13, 16, 21, 0.19);
  border-radius: 20px;
  background-color: #ffffff;
  padding: 30px; }

.settingFormWrapper p {
  font-family: 'Segoe UI';
  font-size: 13px;
  margin: 16px 0; }

.settingFormWrapper form p {
  font-family: 'Segoe UI Bold';
  font-weight: 700; }

.settingFormWrapper, .innerContentWrapper {
  margin: 0 18px; }

.settingFormWrapper .form-label {
  text-align: left;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.settingFormWrapper .form-control {
  width: 256px;
  color: #000000;
  font-family: 'Segoe UI Semi Bold';
  font-size: 14px;
  font-weight: 400; }

.settingFormWrapper .customInputForm .form-control, .settingFormWrapper .customInputForm .customDrpDwn .css-10nd86i {
  width: 320px; }

.innerForm {
  margin-top: 35px; }

.innerForm .form-group > div {
  padding-left: 0; }

.image_upload button {
  width: 138px;
  height: 29px;
  border-radius: 3px;
  border: 1px solid #3ba8c5;
  background-color: #ffffff;
  float: left; }
  .image_upload button input {
    width: 138px;
    height: 30px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; }

.image_upload p {
  color: #3ba8c5;
  font-family: 'Segoe UI Bold';
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  margin: -2px 0; }

.profileImg {
  padding-top: 14px; }

.profileImg .img_delete p:first-child {
  padding: 0 14px 0 19px;
  color: #e44e4e;
  font-family: 'Segoe UI Bold';
  font-size: 23px;
  font-weight: 400;
  line-height: 25.46px;
  letter-spacing: 0.64px;
  margin-top: -9px; }

.profileImg .img_delete p:last-child {
  color: #3ba8c5;
  font-family: 'Helvetica Neue Cyr Medium';
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.28px;
  margin-left: -13px; }

.profileImg .img_delete p:first-child.rotate {
  transform: rotate(45deg);
  /* Safari */
  -webkit-transform: rotate(45deg);
  /* Firefox */
  -moz-transform: rotate(45deg);
  /* IE */
  -ms-transform: rotate(45deg);
  /* Opera */
  -o-transform: rotate(45deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1); }

.profileImg img, .profileImg p {
  float: left; }

.custom_radioBtn.col {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content; }

.custom_radioBtn .btn {
  width: 61px;
  height: 27px;
  border-radius: 14px;
  border: 1px solid #dedede;
  border-color: #dedede;
  background-color: #e7e7e7;
  margin-top: 6px;
  margin-bottom: 0; }

.custom_radioBtn label {
  position: relative; }

.custom_radioBtn input {
  opacity: 0; }

.custom_radioBtn .btnText {
  position: absolute;
  top: -12px;
  right: 7px;
  color: #000000;
  font-family: 'Segoe UI';
  font-size: 11px;
  font-weight: 700;
  transition: all 0.3s; }

.custom_radioBtn .btnText.active {
  color: #ffffff;
  right: 0;
  left: -21px; }

.custom_radioBtn .btnIcon {
  position: absolute;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #ffffff;
  background-image: linear-gradient(-45deg, #e2e7e7 0%, #ffffff 100%);
  top: 4px;
  left: 6px;
  transition: all 0.3s; }

.custom_radioBtn .btn-primary:focus, .btn-primary.focus {
  box-shadow: none; }

.custom_radioBtn .btnIcon.active {
  left: 37px; }

.custom_radioBtn .btn-primary:not(:disabled):not(.disabled):active, .custom_radioBtn .btn-primary:not(:disabled):not(.disabled).active, .show > .custom_radioBtn .btn-primary.dropdown-toggle {
  background-color: #aac3a1;
  border-color: #dedede; }

.formBtns {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #dfdfdf;
  padding-right: 0; }

.formBtns .pullRight {
  float: right; }

.formBtns Button {
  width: auto;
  min-width: 90px;
  height: 38px;
  margin-left: 10px;
  border-radius: 3px; }

.formBtns Button.btn_clr {
  border: 1px solid #3ba8c5;
  background-color: #ffffff;
  color: #3ba8c5; }

.formBtns Button.btn_save {
  background-color: #3ba8c5;
  border-color: transparent; }

.formBtns Button p {
  font-family: 'Segoe UI Bold';
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  margin: -2px 0; }

.overlay {
  display: none;
  position: fixed;
  /* full screen */
  width: 100vw;
  height: 100vh;
  /* transparent black */
  background: rgba(0, 0, 0, 0.7);
  /* middle layer, i.e. appears below the sidebar */
  z-index: 998;
  opacity: 0;
  /* animate the transition */
  transition: all 0.5s ease-in-out;
  top: 0; }

/* display .overlay when it has the .active class */
.overlay.active {
  display: block;
  opacity: 1; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.menuClose.close {
  color: #a8d3de;
  margin-right: 5px; }

@media (min-width: 574px) and (max-width: 767px) {
  .App .divider {
    display: none !important; }
  .header .logo-img {
    width: 65%; }
  .App .checkout {
    display: none !important; }
  .App .cart {
    display: block !important; } }

@media (max-width: 990px) {
  /*.wrapper {
    display: block;
  }*/
  /*
  .submenu_wrapper {
    height: 100vh;
    position: absolute;
    top: 61px;
    left: 96px;
    z-index: 9999;
}*/
  .logo_wrapper {
    padding-left: 0; }
  .notification {
    margin: 0 20px; }
  .profileStatus .status p {
    font-size: 13px; }
  .profileStatus .status p.text-active {
    font-size: 12px; }
  .checkout {
    margin-left: 0;
    padding: 22px 18px 18px 18px; }
  #sidebarCollapse {
    display: block; }
  .header_title {
    height: 65px;
    padding-left: 26px; }
  #sidebar > ul.sideMenu {
    margin-left: 15px;
    min-height: 552px;
    height: 100vh; }
  #sidebar .hideIcon {
    display: none;
    padding-top: 17px; }
  #sidebar .subMenu {
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    overflow-y: visible;
    overflow-y: initial;
    margin-bottom: 0; }
  #sidebar.open, #sidebar.active {
    min-width: 318px;
    max-width: 318px; }
  #sidebar.open {
    left: 0; }
    #sidebar.open .hideIcon {
      display: block;
      transition: all 0.3s; }
  #sidebar .submenu_wrapper.active {
    margin-left: auto; }
  .main_content {
    margin-left: -15px; }
    .main_content.menu_active {
      margin-left: 0; }
  .settingFormWrapper {
    margin-right: 0; }
  .settingFormWrapper .form-label, .customDrpDwn .css-10nd86i, .image_upload p, .profileImg .img_delete p:last-child {
    font-size: 12px; }
  .profileImg .img_delete p:first-child {
    padding: 0 9px 0 10px; }
  .profileImg .img_delete p:last-child {
    margin-left: -8px; }
  .conditionComponent .row > div:first-child {
    padding-right: 0; }
  .conditionComponent .row > div:nth-child(2) {
    padding-right: 0;
    padding-left: 0; }
  .conditionComponent .row > div:nth-child(3) {
    padding-left: 0;
    padding-right: 0; } }

@media (max-width: 576px) {
  .invitationRulesWrapper .editRulesSection .conditions button {
    margin-top: 10px; }
  .invitationRulesWrapper .editRulesSection .customFormGroup .customInput {
    width: 42px; }
  .invitationRulesWrapper .editRulesSection .customFormGroup .customDrpDwn .css-10nd86i {
    width: 184px; }
  .invitationRulesWrapper .editRulesSection .customFormGroup > span {
    margin-left: 5px; }
    .invitationRulesWrapper .editRulesSection .customFormGroup > span:nth-child(2) {
      margin-right: 5px; }
  .invitationRulesWrapper .editRulesSection .customFormGroup > div:nth-child(3) {
    padding: 0 0 0 8px; }
  .innercontent_wrapper .box {
    padding: 30px 20px;
    margin-right: 0; }
  .overview p, .overview li {
    text-align: justify !important; }
  #sidebarCollapse {
    width: 30px;
    height: 30px;
    padding: 1px 3px;
    border: none; }
  .logo_wrapper {
    padding-left: 0;
    margin-left: 10px;
    padding-right: 0; }
  .header {
    margin-bottom: 3px; }
  .innerForm .form-group > div {
    padding-left: 15px; }
  .settings_wrapper .box {
    padding: 20px; }
  .menu-right .row {
    margin-right: 0; }
  .notification {
    margin: 0 10px; }
  .profileStatus {
    margin-right: 10px;
    margin-top: 11px; }
  .profileStatus .avatar {
    margin-right: 0; }
  .formBtns {
    padding-top: 20px;
    margin-top: 20px;
    padding-right: 15px; }
  .formBtns .pullRight {
    float: none; }
  .formBtns Button.btn_clr {
    margin-left: 0; }
  .formBtns Button {
    width: 71px;
    height: 34px; }
  .bg_circle {
    border-radius: 50%;
    border-color: rgba(255, 255, 255, 0);
    border-width: 1px;
    border-style: solid;
    width: 35px;
    height: 35px; }
  .green_circle, .red_circle {
    width: 35px;
    height: 35px;
    border-width: 1px; }
  .profileStatus .status {
    float: none; }
  .profileStatus .status p {
    font-size: 12px; }
  .cart {
    margin-top: -8px; }
  .profileStatus .status .status-img {
    right: -10px;
    bottom: 7px; }
  .conditionComponent .row > div:first-child {
    padding-right: 15px; }
  .conditionComponent .row > div:nth-child(2) {
    padding-right: 15px;
    padding-left: 15px; }
  .conditionComponent .row > div:nth-child(3) {
    padding-left: 15px;
    padding-right: 0px; } }

@media (max-width: 420px) {
  .custome_company_name {
    width: 100px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .custome_company_name:hover {
    overflow: visible;
    white-space: normal; }
  .innercontent_wrapper .downbanner {
    color: white;
    background-color: red;
    width: 100%;
    margin-left: 5px;
    padding: 0px 0px 0px 0px;
    border-radius: 5px;
    margin-top: 10px; } }

.innercontent_wrapper .downbanner {
  color: white;
  background-color: red;
  width: 55%;
  margin-left: -25px;
  padding: 0px 0px 0px 0px;
  border-radius: 5px;
  margin-top: 10px; }

@media (max-width: 400px) {
  .innercontent_wrapper .downbanner {
    color: white;
    background-color: red;
    width: 100%;
    margin-left: 5px;
    padding: 0px 0px 0px 0px;
    border-radius: 5px;
    margin-top: 10px; } }

@media (max-width: 350px) {
  .innercontent_wrapper .box, .innercontent_wrapper .traffic_wrapper .box, .scriptWrapper.innercontent_wrapper .box, .settings_wrapper .box {
    padding: 20px 15px; }
  .innercontent_wrapper .downbanner {
    color: white;
    background-color: red;
    width: 100%;
    margin-left: 5px;
    padding: 0px 0px 0px 0px;
    border-radius: 5px;
    margin-top: 10px; }
  .innercontent_wrapper .profile_wrapper .available_zone .custom_radioBtn.col {
    padding-right: 6px; }
  .innercontent_wrapper .profile_wrapper .available_zone .day_wrapper p.day_label {
    font-size: 12px;
    width: 62px; }
  .innercontent_wrapper .profile_wrapper .available_zone .day_wrapper input {
    margin-left: 5px; }
  .schedule_zone .day_wrapper p.day_label {
    font-size: 12px;
    width: 62px; }
  .schedule_zone .day_wrapper input {
    margin-left: 5px; } }

@media (max-width: 375px) {
  .innerForm .customDrpDwn .css-10nd86i, .innerForm .form-control {
    width: 100%; }
  .editRulesSection .customDrpDwn {
    padding-right: 0;
    width: 100%; }
    .editRulesSection .customDrpDwn .css-10nd86i {
      width: 100% !important; }
  .settingFormWrapper .form-control {
    width: 100%;
    max-width: 256px; } }

@media (max-width: 780px) {
  .headerbellicon {
    padding-right: 0px !important; }
  .headerspeakericon {
    margin-right: 0px !important; } }

@media (min-width: 1024px) {
  .invitationRulesWrapper .editRulesSection .customFormGroup .customDrpDwn .css-10nd86i {
    width: 235px; }
  .invitationRulesWrapper .editRulesSection .customFormGroup > span {
    margin-left: 8px; }
    .invitationRulesWrapper .editRulesSection .customFormGroup > span:nth-child(2) {
      margin-right: 8px; }
  .invitationRulesWrapper .editRulesSection .customFormGroup > div:nth-child(3) {
    padding: 0 0 0 8px; }
  .innercontent_wrapper .profile_wrapper .customDrpDwn .css-10nd86i {
    width: 230px; }
  .innercontent_wrapper .profile_wrapper .settingFormWrapper .form-control {
    width: 230px; } }

[data-tooltip] {
  position: relative; }
  [data-tooltip]:before, [data-tooltip]:after {
    opacity: 0;
    position: absolute;
    left: 50%;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
  [data-tooltip]:before {
    border-style: solid;
    border-top-width: 4px;
    border-right-width: 4px;
    border-bottom-width: 4px;
    border-left-width: 4px;
    border-bottom-width: 0;
    content: '';
    top: -2px;
    width: 0;
    height: 0;
    -webkit-transform: translate(-50%, calc(-50% - 6px));
            transform: translate(-50%, calc(-50% - 6px));
    transition: opacity 0.1s cubic-bezier(0.73, 0.01, 0, 1) 0s, transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0s, -webkit-transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0s;
    z-index: 110000; }
  [data-tooltip]:after {
    content: attr(data-tooltip);
    text-align: center;
    color: #ecae10 !important;
    padding: 10px 10px 9px;
    font-size: 12px;
    border-radius: 8px;
    transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1), transform 0.3s cubic-bezier(0.73, 0.01, 0, 1), -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1);
    pointer-events: none;
    z-index: 999;
    white-space: nowrap;
    bottom: 100%;
    -webkit-transform: translate(-50%, 12px);
            transform: translate(-50%, 12px);
    max-width: 320px;
    text-overflow: ellipsis;
    overflow: hidden; }

[data-tooltip]:after {
  background: #000; }

[data-tooltip]:before {
  border-top-color: #000;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent; }

[data-tooltip][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: #000;
  border-bottom-color: transparent;
  border-left-color: transparent; }

[data-tooltip][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #000;
  border-left-color: transparent; }

[data-tooltip][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #000; }

[data-tooltip]:focus:before, [data-tooltip]:focus:after, [data-tooltip]:hover:before, [data-tooltip]:hover:after {
  opacity: 1; }

[data-tooltip]:focus:before, [data-tooltip]:hover:before {
  transition: opacity 0.1s cubic-bezier(0.73, 0.01, 0, 1) 0.1s, transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0.1s, -webkit-transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0.1s;
  -webkit-transform: translate(-50%, calc(-50% - 2px));
          transform: translate(-50%, calc(-50% - 2px)); }

[data-tooltip]:focus:after, [data-tooltip]:hover:after {
  -webkit-transform: translate(-50%, -6px);
          transform: translate(-50%, -6px); }

[data-tooltip][data-tooltip-conf*=right]:before {
  border-style: solid;
  border-top-width: 4px;
  border-right-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-left-width: 0;
  left: auto;
  right: -6px;
  top: 50%;
  -webkit-transform: translate(calc(-50% + 7px), -50%);
          transform: translate(calc(-50% + 7px), -50%); }

[data-tooltip][data-tooltip-conf*=right]:after {
  top: 50%;
  left: 100%;
  bottom: auto;
  -webkit-transform: translate(-12px, -50%);
  transform: translate(-12px, -50%); }

[data-tooltip][data-tooltip-conf*=right]:focus:before,
[data-tooltip][data-tooltip-conf*=right]:hover:before {
  -webkit-transform: translate(calc(-50% + 3px), -50%);
  transform: translate(calc(-50% + 3px), -50%); }

[data-tooltip][data-tooltip-conf*=right]:focus:after,
[data-tooltip][data-tooltip-conf*=right]:hover:after {
  -webkit-transform: translate(7px, -50%);
  transform: translate(7px, -50%); }

[data-tooltip][data-tooltip-conf*=bottom]:before {
  border-style: solid;
  border-top-width: 4px;
  border-right-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-top-width: 0;
  top: auto;
  bottom: -6px;
  left: 50%;
  -webkit-transform: translate(-50%, calc(-50% + 6px));
  transform: translate(-50%, calc(-50% + 6px)); }

[data-tooltip][data-tooltip-conf*=bottom]:after {
  top: 100%;
  left: 50%;
  bottom: auto;
  -webkit-transform: translate(-50%, -12px);
  transform: translate(-50%, -12px); }

[data-tooltip][data-tooltip-conf*=bottom]:focus:before,
[data-tooltip][data-tooltip-conf*=bottom]:hover:before {
  -webkit-transform: translate(-50%, calc(-50% + 2px));
  transform: translate(-50%, calc(-50% + 2px)); }

[data-tooltip][data-tooltip-conf*=bottom]:focus:after,
[data-tooltip][data-tooltip-conf*=bottom]:hover:after {
  -webkit-transform: translate(-50%, 6px);
  transform: translate(-50%, 6px); }

[data-tooltip][data-tooltip-conf*=left]:before {
  border-style: solid;
  border-top-width: 4px;
  border-right-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-right-width: 0;
  left: -2px;
  top: 50%;
  width: 0;
  height: 0;
  -webkit-transform: translate(calc(-50% - 8px), -50%);
  transform: translate(calc(-50% - 8px), -50%); }

[data-tooltip][data-tooltip-conf*=left]:after {
  top: 50%;
  right: 100%;
  bottom: auto;
  left: auto;
  -webkit-transform: translate(12px, -50%);
  transform: translate(12px, -50%); }

[data-tooltip][data-tooltip-conf*=left]:focus:after,
[data-tooltip][data-tooltip-conf*=left]:hover:after {
  -webkit-transform: translate(-7px, -50%);
  transform: translate(-7px, -50%); }

[data-tooltip][data-tooltip-conf*=left]:focus:before,
[data-tooltip][data-tooltip-conf*=left]:hover:before {
  -webkit-transform: translate(calc(-50% - 3px), -50%);
  transform: translate(calc(-50% - 3px), -50%); }

[data-tooltip][data-tooltip-conf*=multiline]:after {
  word-break: break-word;
  white-space: normal;
  min-width: 180px;
  text-overflow: clip; }

[data-tooltip][data-tooltip-conf*=delay]:before {
  transition: opacity 0.2s cubic-bezier(0.73, 0.01, 0, 1) 0s, -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s;
  transition: opacity 0.2s cubic-bezier(0.73, 0.01, 0, 1) 0s, transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s;
  transition: opacity 0.2s cubic-bezier(0.73, 0.01, 0, 1) 0s, transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s, -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s; }

[data-tooltip][data-tooltip-conf*=delay]:after {
  transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s, -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s;
  transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s, transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s;
  transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s, transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s, -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s; }

[data-tooltip][data-tooltip-conf*=delay]:focus:before,
[data-tooltip][data-tooltip-conf*=delay]:hover:before {
  transition: opacity 0.2s cubic-bezier(0.73, 0.01, 0, 1) 0.5s, -webkit-transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0.5s;
  transition: opacity 0.2s cubic-bezier(0.73, 0.01, 0, 1) 0.5s, transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0.5s;
  transition: opacity 0.2s cubic-bezier(0.73, 0.01, 0, 1) 0.5s, transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0.5s, -webkit-transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0.5s; }

[data-tooltip][data-tooltip-conf*=delay]:focus:after,
[data-tooltip][data-tooltip-conf*=delay]:hover:after {
  transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s, -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s;
  transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s, transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s;
  transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s, transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s, -webkit-transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s; }

[data-tooltip][data-tooltip-conf*=shadow]:after {
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.1); }

[data-tooltip][data-tooltip-conf*=no-fading]:after,
[data-tooltip][data-tooltip-conf*=no-fading]:before {
  transition: none; }

[data-tooltip][data-tooltip-conf*=no-arrow]:before {
  display: none; }

[data-tooltip][data-tooltip-conf*=square]:after {
  border-radius: 0; }

[data-tooltip][data-tooltip-conf*=invert]:after {
  color: #fff;
  background: #ccc; }

[data-tooltip][data-tooltip-conf*=invert]:before {
  border-top-color: #ccc;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent; }

[data-tooltip][data-tooltip-conf*=invert][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: #ccc;
  border-bottom-color: transparent;
  border-left-color: transparent; }

[data-tooltip][data-tooltip-conf*=invert][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #ccc;
  border-left-color: transparent; }

[data-tooltip][data-tooltip-conf*=invert][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #ccc; }

[data-tooltip][data-tooltip-conf*=success]:after {
  background: #8bc34a; }

[data-tooltip][data-tooltip-conf*=success]:before {
  border-top-color: #8bc34a;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent; }

[data-tooltip][data-tooltip-conf*=success][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: #8bc34a;
  border-bottom-color: transparent;
  border-left-color: transparent; }

[data-tooltip][data-tooltip-conf*=success][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #8bc34a;
  border-left-color: transparent; }

[data-tooltip][data-tooltip-conf*=success][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #8bc34a; }

[data-tooltip][data-tooltip-conf*=info]:after {
  background: #29d2e4; }

[data-tooltip][data-tooltip-conf*=info]:before {
  border-top-color: #29d2e4;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent; }

[data-tooltip][data-tooltip-conf*=info][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: #29d2e4;
  border-bottom-color: transparent;
  border-left-color: transparent; }

[data-tooltip][data-tooltip-conf*=info][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #29d2e4;
  border-left-color: transparent; }

[data-tooltip][data-tooltip-conf*=info][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #29d2e4; }

[data-tooltip][data-tooltip-conf*=warning]:after {
  background: #f87d09; }

[data-tooltip][data-tooltip-conf*=warning]:before {
  border-top-color: #f87d09;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent; }

[data-tooltip][data-tooltip-conf*=warning][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: #f87d09;
  border-bottom-color: transparent;
  border-left-color: transparent; }

[data-tooltip][data-tooltip-conf*=warning][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #f87d09;
  border-left-color: transparent; }

[data-tooltip][data-tooltip-conf*=warning][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #f87d09; }

[data-tooltip][data-tooltip-conf*=danger]:after {
  background: #e91e63; }

[data-tooltip][data-tooltip-conf*=danger]:before {
  border-top-color: #e91e63;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent; }

[data-tooltip][data-tooltip-conf*=danger][data-tooltip-conf*=right]:before {
  border-top-color: transparent;
  border-right-color: #e91e63;
  border-bottom-color: transparent;
  border-left-color: transparent; }

[data-tooltip][data-tooltip-conf*=danger][data-tooltip-conf*=bottom]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #e91e63;
  border-left-color: transparent; }

[data-tooltip][data-tooltip-conf*=danger][data-tooltip-conf*=left]:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #e91e63; }

[data-tooltip][data-tooltip='']:after, [data-tooltip][data-tooltip='']:before {
  display: none; }

/* Discount Ribbon */
.discount-ribbon {
  position: absolute;
  top: 10px;
  left: -20px;
  background: #ff4d4d;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 20px;
  -webkit-transform: rotate(-15deg);
          transform: rotate(-15deg);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px; }

.discount-ribbon span {
  font-size: 16px;
  font-weight: bold;
  color: #fff000; }

/* Price Details */
.price-details {
  text-align: center;
  margin-bottom: 10px; }

/* Original Price */
.original-price {
  font-size: 17px;
  font-weight: bold;
  color: #888;
  margin-bottom: 5px; }

/* Final Price with Proper Alignment */
.final-price {
  font-size: 13px;
  font-weight: bold;
  color: #222;
  line-height: 1.2; }

.per-month {
  font-size: 9px;
  font-weight: normal;
  color: gray;
  margin-top: 5px; }

.features-list {
  text-align: left;
  font-size: 14px;
  color: #333;
  font-family: "Segoe UI Bold"; }

.features-list p {
  margin: 2px 0; }

/* Price tag */
.pricingCard {
  width: 100%;
  padding: 20px;
  text-align: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, -webkit-transform 0.3s ease;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  transition: transform 0.3s ease, box-shadow 0.3s ease, -webkit-transform 0.3s ease; }

.pricingCard:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15); }

.priceTag {
  font-size: 24px;
  font-weight: bold;
  color: #28a745; }

.pricingDetails {
  font-size: 14px;
  color: #555;
  margin-bottom: 15px; }

.silver {
  border-top: 5px solid #c0c0c0; }

.gold {
  border-top: 5px solid #ffd700; }

.platinum {
  border-top: 5px solid #e5e4e2; }

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52vh;
  cursor: pointer; }
