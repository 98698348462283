@mixin padding($values...) {    
    @each $var in $values {
        padding: #{$var};
    }
}
@mixin padding-direction($direction, $value) {    
    padding-#{$direction}: $value;
}
@mixin margin($values...) {    
    @each $var in $values {
        margin: #{$var};
    }
}
@mixin margin-direction($direction, $value) {    
    margin-#{$direction}: $value;
}
@mixin border-radius($radius...) {
    @each $var in $radius {
    -webkit-border-radius: #{$var};
       -moz-border-radius: #{$var};
        -ms-border-radius: #{$var};
            border-radius: #{$var};
    }    
}
@mixin border-direction($direction, $width, $style, $color) {
    border-#{$direction}: $width $style $color;
}
@mixin border($width, $style, $color) {
    border: $width $style $color;
}
@mixin overlay() {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
@mixin linx ($link, $visit, $hover, $active) {
    a {
      color: $link;
      text-decoration: none;
      &:visited {
        color: $visit;
      }
      &:hover {
        color: $hover;   
      }
      &:active {
        color: $active;
      }
    }
}
@mixin text-align($direction) {    
    text-align: $direction;
}
@mixin transition-all($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}
@mixin clear-fix {

	&::after {
		content: '';
		display: table;
		clear: both;
	}
}
%clear-fix {
    *zoom: 1;
    &:before, &:after {
      content: " ";
      display: table;
    }
    &:after {
      clear: both;
    }
  }
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
 }

 @mixin circle($dia,$borderColor,$borderWidth){
    border-radius: 50%;
    border-color: $borderColor;
    border-width: $borderWidth;
    border-style: solid;
    width: $dia;
    height: $dia;
 }

 @mixin grabbing-cursor {
  cursor: url('https://www.google.com/intl/en_ALL/mapfiles/closedhand.cur'), all-scroll;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}
