@import '_mixins';

$themes: (
	default: (
				primary-color: #3ba8c5,
				secondary-color: #0b91b4,
				primary-color-light: #EBF6F9,
				plus-icon: url('assets/images/plus_icons/plus_blue.png')
				
			),
	steelBlue: (
				primary-color: #3ba8c5,
				secondary-color: #0b91b4,
				primary-color-light: #EBF6F9,
				plus-icon: url('assets/images/plus_icons/plus_steelblue.png'),
			),
	lightSeaGreen: (
				primary-color: #4cb6ac,
				secondary-color: #2e968c,
				primary-color-light: #EDF8F7,
				plus-icon: url('assets/images/plus_icons/plus_seagreen.png')
			),
	deepPink: (
				primary-color: #ec407a,
				secondary-color: #d22c64,
				primary-color-light: #FDECF1,
				plus-icon: url('assets/images/plus_icons/plus_pink.png')
			),
	brown: (
				primary-color: #6e4c42,
				secondary-color: #573930,
				primary-color-light: #F0EDEC,
				plus-icon: url('assets/images/plus_icons/plus_brown.png'),
			),
	navyBlue: (
				primary-color: #234e91,
				secondary-color: #0a3a84,
				primary-color-light: #E8EDF4,
				plus-icon: url('assets/images/plus_icons/plus_navyblue.png'),
				
			),
	mustardYellow: (
				primary-color: #e89712,
				secondary-color: #d38608,
				primary-color-light: #FDF4E8,
				plus-icon: url('assets/images/plus_icons/plus_yellow.png')
			),
	violet: (
				primary-color: #b968c7,
				secondary-color: #ab48bf,
				primary-color-light: #F8F0F9,
				plus-icon: url('assets/images/plus_icons/plus_voilet.png')
	
			),
	blue: (
				primary-color: #039be6,
				secondary-color: #1665c1,
				primary-color-light: #E6F5FC,
				plus-icon: url('assets/images/plus_icons/plus_blue.png')
				
			),
	darkGreen: (
				primary-color: #00887a,
				secondary-color: #00695b,
				primary-color-light: #E6F3F1,
				plus-icon: url('assets/images/plus_icons/plus_darkgreen.png'),
			),
	green: (
				primary-color: #4b8f4e,
				secondary-color: #2d7d30,
				primary-color-light: #EDF3ED,
				plus-icon: url('assets/images/plus_icons/plus_green.png')
				
			),
	grey: (
				primary-color: #5f7c8c,
				secondary-color: #506a78,
				primary-color-light: #EFF2F3,
				plus-icon: url('assets/images/plus_icons/plus_grey.png')
			),
	deepSkyBlue: (
				primary-color: #64a9e0,
				secondary-color: #5699ce,
				primary-color-light: #EFF6FC,
				plus-icon: url('assets/images/plus_icons/plus_skyblue.png')
			)
) !default;

@mixin theme($name, $primary-color, $secondary-color, $primary-color-light,$plus-icon) {
	.#{$name} {
.widgetWrapper {
	h4{
	 &.widgetName {
	 	color: #3ba8c5;
	 	margin-right: 22px;
	 }
	}
	.exit_btn {
		position: absolute;
		right: 37px;
		color: #3ba8c5;
		font-family: "Segoe UI";
		font-size: 14px;
		font-weight: 700;
		text-transform: uppercase;
		cursor: pointer;

		img {
			margin-right: 8px;
			margin-top: -3px;
		}
	}
}

.widgetSelectorIndicator{
	margin: 30px auto 0;
	p{
		color: #a1a4a8;
		font-family: "Helvetica Neue Cyr Roman";
		font-size: 22px;
		font-weight: 400;
		margin-top: 25px;
	}
}
.innercontent_wrapper .directLinkWrapper {
	margin-top: 56px;
	h4 {
		color: #000000;
		font-family: "Helvetica Neue Cyr Roman";
		font-size: 22px;
		font-weight: 400;
		margin-left: 18px;
		margin-bottom: 34px;
		position: relative;

		span {
			border: 1px solid #3ba8c5;
			background-color: #ffffff;
			border-radius: 50%;
			position: absolute;
			margin-left: 9px;
			width: 24px;
			height: 24px;
			display: inline-block;
			img {
				position: absolute;
				top: 5px;
				left: 10px;
			}
		}
	}
	.with_btn {
		input{
			float: left;
		}
		.uploadBtn button {
			background-color: #3ba8c5;
			p {
				color: #fff;
			}
		}
	}
	.invitationCustomImg {
		margin-bottom: 0.5rem;
	}
	.btn_wrapper {
		.uploadBtn.iconUpload {
			margin-left: 0;
			margin-right: 5px;
			button.uploadIcon input {
			    width: 60px;
			}
		}
		
		margin-bottom: 15px;
	}
	.box {
		padding: 30px;

		.customDrpDwn{
			&.customOption .row >div {
				&:first-child {
					width: 131px;
					margin-right: 10px;
				}
				&:nth-child(2) {
					width: 117px;
					.css-10nd86i  {
						width: 117px;
					}
				}
			}
		}
	}
	.formBtns {
		Button.btn_save {
			width: auto;
		}
	}
	.customOption {
		.row {
			>div:nth-child(2) >div {
				&:nth-child(2) {
					position: absolute!important;
					z-index: 2;
					top: 44px;
					left: 157px;

					.flexbox-fix {
						display: none!important;
					}
				}
			}
		}
	}
	.colorSelector {
		.css-1wy0on6 {
			background-color: #fff;
		}
		input {
			border: none;
			&:focus {
				border: none;
			}
		}
	}
	.directLinkpreviewWrapper {
    	position: relative;
		p{
				width: 178px;
			    float: left;
			    padding-top: 30%;
			    font-size: 22px;
			    text-align: right;
			    text-decoration: underline;
    	}
	}
	.directLinkpreview {
		background-repeat: no-repeat;
		width: 165px;
    	height: 114px;
    	float: right;
    	margin-top: 100px;
    	margin-right: 50px;
    	margin-left: 24px;
    	background-size: cover!important;

    	
	}
	.theme {
		color: #000000;
		font-family: "Segoe UI Semi Bold";
		font-size: 14px;
		font-weight: 400;
		text-align: left;
		margin-top: 7px;
	}
	.preview {
		width: 330px;
    	height: 101px;
    	padding: 12px 0 0 0;
    	margin-top: 40px;
    	margin-bottom: 47px;
    	background-repeat: no-repeat;
    		.widget-preview {
			    width: 166px;
			    height: 76px;
			    div {
				    font-family: Arial,Helvetica,sans-serif;
				}
				.widget-preview-title {
				    padding: 11px 0 0 18px;
				    color: #fff;
				    font-size: 12px;
				    font-weight: 700;
				    text-align: left;
				}
				.widget-preview-text {
				    width: 146px;
				    overflow: hidden;
				    white-space: nowrap;
				    padding: 1px 0 0;
				    text-align: center;
				    color: #A8A8A8;
				    font-size: 12px;
				    margin-left: 10px;
				}
				.widget-preview-copy {
				    padding: 8px 13px 0;
				    text-align: right;
				    font-size: 10px;
				    color: #fff;
				    opacity: .5;
				    -moz-opacity: .5;
				    -khtml-opacity: .5;
				    filter: alpha(opacity=50);
				}
			}
	}
	.widgetPosition {
		width: 330px;
		p {
			width: 236px;
			height: 30px;
			border-radius: 5px;
			border: 1px solid #dedede;
			background-color: #ffffff;
			color: #000000;
			font-family: Segoe UI;
			font-size: 11px;
			font-weight: 700;
			text-transform: uppercase;
			margin-bottom: 37px;
			margin-left: auto;
    		margin-right: auto;

			span {
				width: 50%;
				display: inline-block;
				padding: 6px 0;
				cursor: pointer;
				&:first-child {
					border-radius: 5px 0 0 5px;
				}
				&:last-child {
					border-radius: 0 5px 5px 0;
				}
				&.selected {
					color: #ffffff;
					background-color: #3ba8c5;
				}
			}
		}
	}
	.themeSelectCompWrapper {
		width: 90%;
		margin: 0 auto;
		.themeSelectComp {
			width: 34px;
			height: 34px;
			border: 1px solid #dedede;
			border-radius: 50%;
			float: left;
			padding: 3px;
			margin-right: 10px;
			margin-bottom: 10px;
			cursor: pointer;
			div {
				width: 26px;
				height: 26px;
				border-radius: 50%;
				margin: 0;
			}
			&:hover, &.selected {
				transform: scale(1.2);
				transition: transform 100ms ease 0s;
				padding: 2px;
				div{
					transform: scale(.9);
				}
			}
			&:nth-child(1):hover {
				border: 2px solid #0475A8;
			}
			&:nth-child(2):hover {
				border: 2px solid #5F8706;
			}
			&:nth-child(3):hover {
				border: 2px solid #D6680B;
			}
			&:nth-child(4):hover {
				border: 2px solid #B0097D;
			}
			&:nth-child(5):hover {
				border: 2px solid #9D0A09;
			}
			&:nth-child(6):hover {
				border: 2px solid #424E54;
			}
			&:nth-child(7):hover {
				border: 2px solid #037FAF;
			}
			&:nth-child(8):hover {
				border: 2px solid #608706;
			}
			&:nth-child(9):hover {
				border: 2px solid #D26009;
			}
			&:nth-child(10):hover {
				border: 2px solid #AF097D;
			}
			&:nth-child(11):hover {
				border: 2px solid #950909;
			}
			&:nth-child(12):hover {
				border: 2px solid #3E484E;
			}
			&:nth-child(13):hover {
				border: 2px solid #0476A8;
			}
			&:nth-child(14):hover {
				border: 2px solid #98B45D;
			}
			&:nth-child(15):hover {
				border: 2px solid #ADADAD;
			}
			&:nth-child(16):hover {
				border: 2px solid #98A7AD;
			}
		}
			
			

	}
	
	.circle-picker {
		padding: 0px 9px;
		>span {
			>div {
				>span {
					    padding: 4px;
					    position: relative;
					    border: 1px solid #dedede;
					    border-radius: 50%;
					    display: inline-block;
					    width: 34px;
					    height: 34px;
						    
				}
				
			}
			&:nth-child(1) {
				>div:hover{
					span {
						border: 2px solid #0475A8;
					}
				}
				>div >span >div {
					box-shadow: #0475A8 0px 0px 0px 17px inset!important;
				}
			}
			&:nth-child(2) {
				>div:hover {
					span {
						border: 2px solid #5F8706;
					}
				}
			}
			&:nth-child(3) {
				>div:hover {
					span {
						border: 2px solid #D6680B;
					}
				}
			}
			&:nth-child(4) {
				>div:hover {
					span {
						border: 2px solid #B0097D;
					}
				}
			}
			&:nth-child(5) {
				>div:hover {
					span {
						border: 2px solid #9D0A09;
					}
				}
			}
			&:nth-child(6) {
				>div:hover {
					span {
						border: 2px solid #424E54;
					}
				}
			}
			&:nth-child(7) {
				>div:hover {
					span {
						border: 2px solid #037FAF;
					}
				}
			}
			&:nth-child(8) {
				>div:hover {
					span {
						border: 2px solid #587D06;
					}
				}
			}
			&:nth-child(9) {
				>div:hover {
					span {
						border: 2px solid #D26009;
					}
				}
			}
			&:nth-child(10) {
				>div:hover {
					span {
						border: 2px solid #AF087C;
					}
				}
			}
			&:nth-child(11) {
				>div:hover {
					span {
						border: 2px solid #950909;
					}
				}
			}
			&:nth-child(12) {
				>div:hover {
					span {
						border: 2px solid #3E484E;
					}
				}
			}
			&:nth-child(13) {
				>div:hover {
					span {
						border: 2px solid #0476A8;
					}
				}
			}
			&:nth-child(14) {
				>div:hover {
					span {
						border: 2px solid #98B45D;
					}
				}
			}
			&:nth-child(15) {
				>div:hover {
					span {
						border: 2px solid #ADADAD;
					}
				}
			}
			&:nth-child(16) {
				>div:hover {
					span {
						border: 2px solid #98A7AD;
					}
				}
			}
		}
	}

	.iconWrapper {
		height: 390px;
		position: relative;
		.scroll_btn {
			position: absolute;
			width: 26px;
			height: 27px;
			border: 1px solid #dedede;
			border-radius: 50%;
			top: -27px;
    		right: 6px;
			cursor: pointer;
			img {
			     margin-top: -2px;
    				margin-left: 1px;
			}
			&.bottomScroll {
				top: auto;
				bottom: -27px;
			}
		}
		.iconRow {
			margin-bottom: 13px;
			margin-right: 30px;
			border: 1px solid #dedede;
			border-radius: 10px;
			cursor: pointer;
			>div {
				width: 151px;
				height: 87px;
				background-color: #ffffff;
				margin-right: 12px;
				padding: 16px;
				border-radius: 10px;
				&.no-image {
					opacity: 0;
				}
				&:last-child {
					margin-right: 0;
				}
			}
			&:last-child {
				margin-bottom: 0;
			}
			&.selected {
				background-color: #dedede;
				>div {
					background-color: #dedede;
				}
			}

		}
		.rcs-custom-scroll .rcs-custom-scroll-handle {
				  
			}
			.rcs-custom-scroll .rcs-inner-handle{
				width: 16px;
				    height: 16px;
				    border-radius: 50%;
				    border: 1px solid #3ba8c5;
				    background-color: #3ba8c5;
				    margin-left: -8px;
			}
			.rcs-custom-scroll .rcs-custom-scrollbar {
				opacity: 1;
    			right: 13px;
    			border-left: 2px solid #dedede;
    			height: 590px;
			}
	}

	.uploadBtn {
		margin-top: 13px;
		&.iconUpload {
			margin-top: 5px;
			margin-left: 6px;
			float: left;
		}
		button {
			width: 218px;
			height: 29px;
			border-radius: 3px;
			border: 1px solid #3ba8c5;
			background-color: #ffffff;
			position: relative;
			padding: 0;	
			&.uploadIcon{
				width: auto;
				float: left;
				padding: 0 5px;
				input{
					width: auto;
				}
			}
			p {
				margin: 0;
				color: #3ba8c5;
			font-family: "Segoe UI";
			font-size: 13px;
			font-weight: 700;
			line-height: 30.4px;
			text-transform: uppercase;
				img {
					margin-right: 4px;
					margin-top: -4px;
				}
			}

			input{
				position: absolute;
				top: 0;
				left: 0;
				width: 218px;
				height: 29px;
				opacity: 0;
				cursor: pointer;
			}
		}
	}

	&.widgetIconWrapper {
		.theme {
			span {
				margin-right: 15px;
				color: #3ba8c5;
				text-decoration: underline;
				cursor: pointer;
				&.selected {
					text-decoration: none;
					color: #000000;
				}
			}
		}
	}
	
}
	.carousel {
		padding: 12px 0px;
		img.carouselBtn {
			position: absolute;
			cursor: pointer;
			top: 90px;
			&.carousel-left {
				left: 30px;
			}
		}
		ul{
			margin-left: 0;
			margin-right: 0;
			transition: all 1s;
			&.widgetMode {
				li {
					opacity: 0.5;
					float: left;

					&.selected, &:hover {
						opacity: 1;
						border: 3px solid #3ba8c5;
						h4 {
							background-color: #3ba8c5;
							color: #ffffff;
						}
					}
				}
			}
		}
		.carousel-cell {
			cursor: pointer;
			width: 210px;
			height: 192px;
			box-shadow: 0 0 15px rgba(13, 16, 21, 0.4);
			border-radius: 20px;
			background-color: #ffffff;
			padding: 0px;
			display: inline-block;
			margin-right: 20px;
			float: left;
			&.opacity_0 {
				opacity: 0;
				margin-right: 60px;
				transition: opacity 2s;
			}
			&:hover {
				border: 3px solid #3ba8c5;
				h4 {
					background-color: #3ba8c5;
					color: #ffffff;
				}
			}
			h4 {
				color: #000000;
				font-family: "Helvetica Neue Cyr Roman";
				font-size: 22px;
				font-weight: 400;
				padding: 22px 0 18px;
				border-bottom: 4px solid #3ba8c5;
				margin: 0;
				border-radius: 15px 15px 0 0;
			}
			.img-wrapper {
				height: 120px;
			    display: table-cell;
			    vertical-align: middle;
			    width: 210px;
			}
			
		}
	}
.settingFormWrapper .codeWrapper {
	margin-top: 69px;
	p {
		color: #000000;
		font-family: "Segoe UI Semi Bold";
		font-size: 14px;
		font-weight: 400;
		text-align: left;
	}

	textarea {
		width: 100%;
		border-radius: 5px;
		border: 1px solid #dedede;
		padding: 13px;
		margin-bottom: 10px;
	}
	.formBtns {
		padding: 0;
		margin: 0;
		border: none;

		Button.btn_clr {
			margin-left: 0;
			margin-right: 10px;
			width: auto;
			padding: 9px 30px;
			p{
				text-align: center;
				color: #3ba8c5;
			}
		}
	}

	.advancedSection {
		margin-top: -48px;
		.advancedOption {
			p {
				color: #3ba8c5;
				font-family: "Segoe UI Bold";
				font-size: 14px;
				font-weight: 700;
				&:first-child {
				        width: 27px;
					    height: 27px;
					    border-radius: 50%;
						//border: 1px solid #3ba8c5;
						border: 1px solid $primary-color;
						//background-color: #3ba8c5;
						background-color: $primary-color; 
					    background-image: url("assets/images/expand.png");
					    background-repeat: no-repeat;
					    background-position: center;
					    padding: 0;
					    float: left;
					    margin-right: 9px;
				}
				&:last-child {
					    margin-top: 20px;
				}
			}
			&.collapsed {
				p:first-child {
					border: 1px solid #dedede;
				    background-color: #ffffff;
					//background-image: url("assets/images/collapsed.png");
					background-image: $plus-icon;
					background-size: 11px;
					
				}
			}
			&.dropdown-toggle::after {
				display: none;
			}
		}
	}
}

.mailer {
	.modal-dialog{
		textarea {
			border-radius: 5px;
			border: 1px solid #dedede;
			padding: 13px;
			width: 367px;
			color: #000000;
		    font-family: 'Segoe UI Semi Bold';
		    font-size: 14px;
		}
		label {
			text-align: left;
		    color: #000000;
		    font-family: 'Segoe UI Semi Bold';
		    font-size: 14px;
		    font-weight: 400;
		}
		.form-control {
		    width: 256px;
		    color: #000000;
		    font-family: 'Segoe UI Semi Bold';
		    font-size: 14px;
		    font-weight: 400;
		}
	}
}

.toolBarWrapper {
	.toolTipDesignWrapper {
		border-radius: 10px;
		background-color: #f6f6f6;
		padding: 20px 20px 4px;
	}
	.toolBarMenuWrapper {
		height: 53px;
		margin-bottom: 36px;
		position: relative;
		.toolBarBoxWrapper {
			    position: absolute;
			    height: 13px;
			    width: 100%;
			    top: -14px;
			    left: 0;
			    background-color: #fff;
			    z-index:7;
			    &.bottom{
			    	top: auto;
			    	bottom: -13px;
			    }
		}
		.toolBarBox {
			    position: absolute;
			    border: 1px solid #dedede;
			    height: 54px;
			    width: 98%;
			    border-right: 0;
			    top: -1px;
			    z-index: 5;
			    left: 0;
			    pointer-events: none;
		}
		.menuList {
			height: 54px;
			background-color: #fff;
			cursor: pointer;
			&.selected {
				background-color: #3ba8c5;
				p {
					color: #fff;
				}
				.lineWrapper {
					.line1 {
						background-color: #fff;
						border-color: #3ba8c5;
					}

					.line2{
						border-color: #3ba8c5;
						&.mask {
							opacity: 1;
						    background-color: #fff;
						}
					}
				}
			}
			p{
				width: 100%;
				float: left;
				color: #293037;
				font-family: "Segoe UI Semi Bold";
				font-size: 13px;
				font-weight: 700;
			}
			.lineWrapper {
				position: absolute;
				right: 21px;
				.line1 {
	            	width: 35px;
				    height: 20px;
				    border-bottom: 1px solid #dedede;
				    -webkit-transform: translateY(-3px) translateX(0px) rotate(53deg);
				    position: absolute;

				    &.selectedNext {
				    	background-color: #3ba8c5;
				    	border-color: #fff;
				    }
				}
	    		.line2 {
	        		width: 33px;
				    height: 20px;
				    border-bottom: 1px solid #dedede;
				    -webkit-transform: translateY(6px) translateX(2px) rotate(-53deg);
				    position: absolute;
				    top: 17px;
				    left: -16px;
				    &.mask {
				    	opacity: 0;
				    	left: -1px;
				    	width: 37px;
					    top: 29px;
					    background-color: #fff;
					    border: 0;
				    	&.selectedNext {
				    		opacity: 1;
				    		background-color: #3ba8c5;
					    }
				    }
				    &.selectedNext {
				    	border-color: #fff;
				    }
				}
			}
			
		}
	}
	.form-label.chatWindow {
		padding-top: 3px;
	}
	.chat_windowWrapper .btn-group-toggle {
		display: inline-block;
		.custom_radionBtn{
			font-size: 14px;
			font-family: 'Segoe UI Semi Bold';
		}
		& > label {
			    margin-bottom: 17px;
			    text-align: left;
			    &:last-child {
			    	margin-bottom: 14px;
			    }
			}
	}
	.settingFormWrapper .customSize {
		.form-label {
			font-size: 13px;
		}
		.form-control {
			width: 62px;
			height: 29px;
			border-radius: 3px;
			float:left;
			margin-right: 12px;
			font-size: 13px;
		}
		span {
			float: left;
			margin-top: 6px;
			font-size: 13px;
		}
	}

	.toolBarPreviewWrapper {
		height: 302px;
		width: 418px;
		background: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url("assets/images/toolBarPreviewBg.png");
		position: relative;

		.positionIcons {
			position: absolute;
			border: 1px solid #dedede;
			border-radius: 50%;
			width: 27px;
			height: 27px;
			cursor: pointer;

			&.selected {
				background-color: #3ba8c5;
			}

			&.left {
				left: -11px;
    			top: 44%;
    			padding-right: 2px;
    			padding-top: 1px;
			}
			&.right {
				right: -11px;
    			top: 44%;
    			padding-left: 2px;
    			padding-top: 1px;
			}
			&.center {
				bottom: -30px;
				left: 50%;
				padding-top: 2px;
				padding-left: 1px;
			}
			&.bottomLeft {
				bottom: -28px;
				left: -11px;
				padding-left: 4px;
			}
			&.bottomRight {
				bottom: -28px;
				right: -11px;
				padding-right: 4px;
			}
		}
		.toolBarWrapper {
			width: 366px;
			height: 250px;
			position: absolute;
			top: 25px;
			left: 25px;
		}
		.ulWrapper {
			position: absolute;
			max-width: 366px;
			max-height: 250px;
			overflow: auto;
			&.bar_vertical {
				.btn_wrapper {
					height: 54px;
					.btn_icon.HelpOnClick-WithText{
						margin-left: auto;
						margin-right: auto;
					} 
				}
				//overflow: hidden;
			}
			&.transform {
				transform: translate(0, -50%);
			}
		}

		.toolBarBtns{
			list-style: none;
			height: 41px;
			padding: 0;
			margin: 0;
			width: max-content;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			.btn_wrapper {
				border-right: 1px solid;
    			border-left: 1px solid;
    			height: 100%;
    			float: left;
    			min-width: 30px;
    			min-height: 30px;
    			margin-bottom: 0;
    			&:first-child {
    				border-left: 0;
    			}
    			&:last-child {
    				border-right: 0;
    			}
    			display: table;
    			
    			a > div, a{
    				display: table-cell;
    				vertical-align: middle;

    				div.preview_row {
    					display: table-row;
    					height: 100%;
    					width: 100%;
    				}
    				div.preview_clmn {
    					display: table-cell;
    					vertical-align: middle;
    				}
    				>div {
    					display: inline-table;
    					vertical-align: middle;
    					height: 100%;
    				}
    			}
    			.btn_icon {
    				    margin-top: -1px;
					    margin-left: 14px;
					    margin-right: 14px;
					    &.HelpOnClick-WithText {
					    	margin-left: 12px;
    						margin-right: 0;
					    }
    			}
    			.btn_caption{
				    color: #fff;
				    display: table-cell;
				    font-weight: 700;
				    font-size: 14px;
				    white-space: nowrap;
				    overflow: hidden;
				    text-overflow: ellipsis;
				    padding-left: 8px;
				    padding-right: 15px;
				    vertical-align: middle;
				}
			}
			
		}
	}
	.Tooltip__Base-sc-19cbsjq-0 {
		width: 200px !important;
		
	}
	.Tooltip__Left-sc-19cbsjq-3 {
		right:20px;
	}
	.customDrpDwn.customDrpDwn-small {
		.css-10nd86i {
			width: 141px;
			float: left;
			.css-vj8t7z {
			}
		}
		.customSize {
			.form-control {
				width: 51px;
				height: 37px;
				margin-right: 9px;
			}
			span {
				color: #7d7d7d;
				font-family: "Segoe UI Semi Bold";
				font-size: 14px;
				font-weight: 400;
			}
		}
	}

	.buttonSettingsWrapper {
		box-shadow: none;
	    border-radius: 0;
	    max-height: max-content;

	    .buttonsSection {
	    	margin-left: 7%;
	    	border-radius: 10px;
			background-color: #f6f6f6;
			padding: 26px 20px 15px;

			.editorWrapper {
				.editorToolBar {
					.editorLink {
						display: none;
					}
					.editorSpellCheck {
						//left: 100px;
					}
				}
			}
	    }
	    .customDrpDwn {
	    	.css-15k3avv {
	    		z-index: 10;
	    	}
	    }
	    textarea {
	    	width: 100%;
	    	border-radius: 5px;
		    border: 1px solid #dedede;
		    background-color: #ffffff;
		    padding: 15px;
		    color: #000000;
		    font-family: 'Segoe UI Semi Bold';
		    font-size: 14px;
		    font-weight: 400;
	    }

	}
	.forIcon {
		.css-10nd86i {
			float: left;
		}
		.formBtns {
		    padding: 0;
		    border-top: none;
		    margin: 0;
		    float: left;
		    
		    Button {
		    	margin-left: 6px;
    			padding-left: 9px;
    			padding-right: 9px;
		    }
		}
	}
	.invitationColorPicker {
		>div {
			width: max-content;
			>div {
				&.colorSelector {
					width: 256px;
				}
				&:nth-child(2) {
                    width: 256px;
                    position: absolute!important;
				    z-index: 2;
				    left: 15px;
                    .flexbox-fix {
                    	opacity: 0;
                    }
				}
			}
		}
	}
.previewMargin {
	&.customSize{
		width: 187px;
		margin-top: 36px;
		margin: 36px auto 0;
	}
}
}

.HelpOnClick-FontIconsLibrary{
	max-height: 70vh;
	//overflow-y: auto;
}

.toolbarInvitationPreview {
	height: 150px;
    width: 320px;
    border: 1px solid #035d85;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background-color: #eeeeee;
    margin: 56px auto;
    .invitationMessageWrapper {
    	height: 60px;
	    overflow-y: auto;
	    overflow-x: hidden;
	    margin-bottom: 5px;
	    margin-top: 23px;
	    font-size: 16px;
    }
    button {
    	&.close {
    		margin-right: 3px;
    	}
    	&.btn_chat {
    		font-size: 14px;
		    width: auto;
    		display: block;
		    padding: 11px 15px;
		    border: 1px solid #7e7c7c;
		    border-radius: 5px;
		    cursor: pointer;
		    float: none;
		    margin: 0 auto;
    	}
    	
    }
}
.imageLibrary {
	img {
		padding: 4px;
		cursor: pointer;
		&.selected {
			background-color: #e6eef9;
		}
	}
}
	}
}
@each $theme, $color in $themes {
	$primaryColor: map-get($color, primary-color);
	$secondaryColor: map-get($color, secondary-color);
	$primary-color-light: map-get($color, primary-color-light);
	$plusIcon:map-get($color,plus-icon);
  	@include theme($theme, $primaryColor, $secondaryColor, $primary-color-light,$plusIcon);
}